import React from 'react';

const TransactionalReport = () => {
 return (
  <div>
   transctional report 
  </div>
 );
};

export default TransactionalReport;