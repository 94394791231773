import React from 'react';
import { Card, CardBody, CardHeader, Col, Media, Row } from 'reactstrap';
import { Chart } from "react-google-charts";
import { Briefcase, Calendar, CreditCard, ShoppingCart } from "react-feather";

const AccountingCardComponent = ({ todaySaleData }) => {

	const LineOptions = {
		hAxis: {
			textPosition: "none",
			baselineColor: "transparent",
			gridlineColor: "transparent",
		},
		vAxis: {
			textPosition: "none",
			baselineColor: "transparent",
			gridlineColor: "transparent",
		},
		colors: ["#ff8084"],
		legend: "none",
	};


	function formatNumber(num) {
		if (num >= 1000000000000) {
			return (num / 1000000000000).toFixed(1).replace('.0', '') + 'T';
		} else if (num >= 1000000000) {
			return (num / 1000000000).toFixed(1).replace('.0', '') + 'Cr';
		} else if (num >= 1000000) {
			return (num / 1000000).toFixed(1).replace('.0', '') + 'L';
		} else if (num >= 1000) {
			return (num / 1000).toFixed(1).replace('.0', '') + 'K';
		} else {
			return num.toString();
		}
	}

	const data = formatNumber(parseFloat(todaySaleData?.current_date_total || 0));



	return (
		<>
			<Col xl="3 xl-50" md="6">
				<Card className=" order-graph sales-carousel">
					<CardHeader>
						<h6>Today Sales</h6>
						<Row>
							<Col className="col-6">
								<div className="small-chartjs">
									<div
										className="flot-chart-placeholder"
										id="simple-line-chart-sparkline-3"
									>
										<Chart
											height={"60px"}
											chartType="LineChart"
											loader={<div>Loading Chart</div>}
											data={[
												["x", "time"],
												[0, 20],
												[1, 5],
												[2, 120],
												[3, 10],
												[4, 140],
												[5, 15],
											]}
											options={LineOptions}
											legend_toggle
										/>
									</div>
								</div>
							</Col>
							<Col className="col-6">
								<div className="value-graph">
									<h3>
										{todaySaleData?.growth_rate_percentage?.toFixed(2) || 0} %
										<span>
											{
												todaySaleData?.growth_rate_percentage < 0 ? <i className="fa fa-angle-down font-primary"></i> : <i className="fa fa-angle-up font-primary"></i>
											}
											
										</span>
									</h3>
								</div>
							</Col>
						</Row>
					</CardHeader>
					<CardBody>
						<Media>
							<Media body>
								<span>Today Sales </span>
								<h2 className="mb-0"> {todaySaleData && (
									data
								)} </h2>
								<p>
								{todaySaleData?.growth_rate_percentage?.toFixed(2) || 0} %
									<span>
									{
												todaySaleData?.growth_rate_percentage < 0 ? <i className="fa fa-angle-down font-primary"></i> : <i className="fa fa-angle-up font-primary"></i>
											}
									</span>
								</p>
								<h5 className="f-w-600 f-16">Today Total Sales</h5>
								<p>
									Todays Current Sales Data
								</p>
							</Media>

						</Media>
					</CardBody>
				</Card>
			</Col>
		</>
	);
};

export default AccountingCardComponent;