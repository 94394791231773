import React, { useEffect, useState } from 'react'
import SalesPaymentTable from './components/SalesPaymentTable'
import Breadcrumb from '../../../components/common/breadcrumb'
import DateRangePickerComponent from './components/DateRangePicker'
import CustomerNameSelectDropdown from './components/CustomerNameSelectDropdown'
import { addDays } from 'date-fns';

import moment from 'moment'
import SuplierNameSelectDropdown from './components/SuplierNameSelectDropdown'
import BranchNameSelectDropdown from '../SupplierItems/components/BranchNameSelectDropdown'

const PurchaseReportsScreen = () => {
    const [queryData, setQueryData] = useState({
        supplier_name: '',
        supplier_id: '',
        brand_name: '',
        category_name: '',
        lot_number: ""
    })
    const [stateM, setStateM] = useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 0),
            key: 'selection',
        },
    ]);

    const [ApiLink, setApiLink] = useState('')
    const startDate = moment(stateM[0].startDate).format().substring(0, 10)
    const endDate = moment(stateM[0].endDate).format().substring(0, 10)

    useEffect(() => {
        if (queryData.supplier_id) {
            setApiLink(`/report/purchase-by-supplier/${queryData.supplier_id}`)
        }
    }, [queryData.supplier_id])

    return (
        <>
            <Breadcrumb title="Supplier Items" parent="reports" />
            <div className='col-12 '>
                {/* <h3 className=''>Please Enter Valid Information</h3> */}
                <div className='d-flex'>
                    {/* <BranchNameSelectDropdown
                        apiLink="/branch/viewbranch" queryData={queryData}
                        setQueryData={setQueryData}
                        InitialDropdownValue='All-Branch'

                    /> */}
                    <SuplierNameSelectDropdown
                        apiLink="/supplier/viewsuppliers" queryData={queryData}
                        setQueryData={setQueryData}
                        InitialDropdownValue='All-Supplier'

                    />
                    {/* <DateRangePickerComponent
                    setStateM={setStateM}
                    stateM={stateM}


                    queryData={queryData}
                    setQueryData={setQueryData}
                /> */}
                </div>

            </div>

            <div className=''>
                {/* <PayamentList /> */}
                <SalesPaymentTable
                    queryLink={ApiLink}
                    wareHouseDropdownValue={queryData.warehouse_name}
                    supplierNameDropdownValue={queryData.supplier_id}
                />

            </div>
        </>
    )
}

export default PurchaseReportsScreen