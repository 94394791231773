import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Card, Col, Form, FormGroup, Label } from 'reactstrap';
import axios from '../../../util/axiosInstance';
import { CustomToast } from '../../../hooks/useCustomToast';
import { useForm } from 'react-hook-form';

const CategoriesListEdit = () => {
 const { id } = useParams();
 const navigate = useNavigate();
 const [categoryList, setCategoryList] = useState({})
 const [isLoading, setIsLoading] = useState(false);
 const [categoryName, setCategoryName] = useState(categoryList?.category_name);
 const [description, setDescription] = useState(categoryList?.description);

 useEffect(() => {
  setIsLoading(true);
  axios.get(`/expensecategory/viewCategory/${id}`).then(res => {
   setCategoryList(res.data)
   setIsLoading(false)
  }).catch(error => {
   setIsLoading(false);  // Stop loading
  });

 }, [])



 // const categorySubmit = (e) => {
 //  e.preventDefault();
 //  axios.put(`/expensecategory/updatecategory/${id}`, { categoryName, description })
 //  .then(res => {
 //   if (res) {
 //    CustomToast('success', "Category Update Successfully");
 //   }
 //  }).catch(error => {
 //   CustomToast('error', "Category Update Successfully");
 //  })

 // }

 const {
  register,
  handleSubmit,
  watch,
  reset,
  formState: { errors },
 } = useForm()

 const onSubmit = (data) => {

  const updatedData = {
   ...data,
  }
  axios.put(`/expensecategory/updatecategory/${id}`, updatedData)
   .then(res => {
    if (res) {
     navigate('/expenses-categories-list');
     CustomToast('success', "Category Update Successfully");
    }
   }).catch(error => {
    CustomToast('error', "Category Not Update");
   })

 }

 return (
  <Fragment>
   <Breadcrumb title="Category List Edit " parent="Expenses" />
   <Card className='p-2'>
    <div className='text-center fw-bold mb-3'>Please Enter Valid Data</div>
    <Form onSubmit={handleSubmit(onSubmit)}>
     <div className="col-md-6 col-sm-12 ">
      <FormGroup className='d-flex justify-content-center align-items-center w-100 '>
       <Label htmlFor='categoryName' className='fw-bold  text-center' style={{ width: '200px' }}>
        Category Name <span className="text-danger">*</span>
       </Label>

       <div className='d-flex justify-content-center  align-items-center w-100'>
        <input
         defaultValue={categoryList.category_name}
         {...register("category_name", { required: true })}
         className='form-control'
         style={{ marginRight: '3px' }}
        />

        {errors.category_name && <span className="text-danger">required</span>}
       </div>

      </FormGroup>
      <FormGroup className='w-100 '>
       <div className="col-md-12 d-flex justify-content-center align-items-center gap-3">
        <label className="fw-bold" htmlFor="exampleFormControlTextarea1" style={{ marginLeft: '20px' }}> Description </label>
        <textarea
         defaultValue={categoryList?.description}
         {...register("description", { required: true })}
         type="textarea"
         className="form-control w-100"
        />

       {errors.description && <span className="text-danger text-center"> required</span>}
       </div>
      </FormGroup>

      <div className="col-md-12 d-flex justify-content-center align-items-center gap-5 mt-3">
       <input type="Submit" style={{ backgroundColor: '#00A65A', border: 'none', padding: '4px 30px', borderRadius: '5px', color: 'white' }} />
       <Link to={'/dashboard'} style={{ backgroundColor: '#F39C12', textDecoration: 'none', padding: '4px 30px', borderRadius: '5px', color: 'white' }}>Close</Link>
      </div>

     </div>
    </Form>
   </Card>
  </Fragment>
 );
};

export default CategoriesListEdit;