import axios from 'axios';

const token = localStorage.getItem('access-token');
const axiosInstance = axios.create({
  // baseURL: 'http://localhost:3000',
  // baseURL: 'https://shaan.apodigi.com',
  // baseURL: "https://revmatch.apodigi.com",
  baseURL: 'https://fancymalabis.apodigi.com',
});

export default axiosInstance;
