import { Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { TbCurrencyTaka } from "react-icons/tb";
import { FaBarcode } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { useRef } from "react";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import Breadcrumb from "../../../components/common/breadcrumb";
import useNumberFormatter from "../../../hooks/useNumberFormatter";
import axios from "../../../util/axiosInstance";
import useGetData from "../../../hooks/useGetData";
import BarcodeDropdownSection from "../../PurchaseManagement/BarcodeDropdownSection";
import SalesReturnTable from "./SalesReturnTable";
import useClickOutside from "../../../hooks/useClickOutside";
import { CustomToast } from "../../../hooks/useCustomToast";
import moment from "moment";
import SalesListTableSection from "../SalesList/SalesListTableSection";

const SalesReturnHome = ({ salesReturnProduct }) => {
  //   get data from backend by props
  const {
    sales_items,
    warehouse_name,
    init_code,
    sales_code,
    customer_name,
    created_date,
    sales_id,
    warehouse_id,
    customer_id,
    sales_payments,
  } = salesReturnProduct || {};
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const paymentTable = [
    {
      id: 1,
      name: "SL",
    },
    {
      id: 2,
      name: "Date",
    },
    {
      id: 3,
      name: "Payment Type",
    },
    {
      id: 4,
      name: "Account",
    },
    {
      id: 5,
      name: "Payment Note",
    },
    {
      id: 6,
      name: "Payment",
    },
  ];
  const statusData = [
    {
      id: 1,
      name: "Return By Payment",
    },
    {
      id: 2,
      name: "Exchange By Product",
    },
  ];
  const numericFormatter = useNumberFormatter();

  function generateRandomSPNumber() {
    const randomNumber = Math.floor(Math.random() * 10000); // Generate a random number between 0 and 9999
    const formattedNumber = `SP${randomNumber.toString().padStart(4, "0")}`; // Format with "SP" prefix and leading zeros if needed
    return formattedNumber;
  }
  const randomSPNumber = generateRandomSPNumber();
  const [error, setError] = useState("");

  const selectedDropdownMenu = useRef(null);
  useClickOutside(selectedDropdownMenu, () => setIsSelectedItemDivOpen(false));
  const [isSelectedItemDivOpen, setIsSelectedItemDivOpen] = useState(false);
  const [accountTypeData, setAccountTypeData] = useState([]);
  const [selectedItemData, setSelectedItemData] = useState([]);
  const [salesPrice, setSalesPrice] = useState();
  const [reference, setReference] = useState("");
  const [paymentNote, setPaymentNote] = useState("");
  const [payingAmount, setPayingAmount] = useState(0);
  const [deliveryChargeInputValue, setDeliveryChargeInputValue] = useState(0);
  const [branchData, setBranchData] = useState([]);
  const [totalSubTotal, setTotalSubTotal] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const grandTotal = totalSubTotal + deliveryChargeInputValue || 0;
  const [totalDueAmount, setTotalDueAmount] = useState(0);
  const [changeReturnData, setChangeReturnData] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState("Return By Payment");
  const [salesItems, setSalesItems] = useState([]);
  const [updatedReturnProducts, setUpdatedReturnProducts] = useState([]);

  const productsData = updatedReturnProducts?.map((p) => ({
    return_status: selectedStatus,
    item_id: p?.id,
    price_per_unit: parseFloat(p?.sales_price) || 0,
    purchase_price: parseFloat(p?.sales_price) || 0,
    return_qty: parseFloat(p?.itemQuantity || p?.sales_qty),
    unit_total_cost: parseFloat(p?.subtotal || p?.item_total_cost),
    total_cost: parseFloat(p?.subtotal || p?.item_total_cost),
  }));

  // calculation change return multiple payment
  const changeReturnCalculation = () => {
    if (payingAmount > totalSubTotal) {
      const changeReturn = (payingAmount || 0) - parseFloat(totalSubTotal || 0);
      setChangeReturnData(changeReturn);
    } else {
      setChangeReturnData(0);
    }
    if (payingAmount < totalSubTotal) {
      const totalDueAmount =
        parseFloat(totalSubTotal || 0) - (payingAmount || 0);
      setTotalDueAmount(totalDueAmount || 0);
    } else {
      setTotalDueAmount(0);
    }
  };

  useEffect(() => {
    changeReturnCalculation();
  }, [payingAmount]);

  const [startDate, setStartDate] = useState(new Date());
  const date = startDate;
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0 indexed
  const day = date.getDate().toString().padStart(2, "0");
  const year = date.getFullYear();
  const purchaseDate = `${month}/${day}/${year}`;
  const [paymentMethod, setPaymentMethod] = useState("Select Payment Type");
  const [accountMethodId, setAccountMethodId] = useState("");
  const [accountMethodName, setAccountMethodName] = useState("Select Account");
  const [searchBarcodeInput, setSearchBarcodeInput] = useState("");
  const [barCodeProducts, setBarCodeProducts] = useState([]);
  const [returnProductsDataWithId, setReturnProductsDataWithId] = useState([]);

  // for sales return
  useEffect(() => {
    if (sales_items?.length > 0) {
      const returnProductDataWithId = sales_items?.map((item) => {
        return {
          ...item,
          id: item?.item_id,
          sales_price: item?.price_per_unit,
        };
      });
      setReturnProductsDataWithId(returnProductDataWithId);
      setSelectedItemData(returnProductDataWithId);
    }
  }, [sales_items]);

  // set selected item data
  const handleItemClick = (item) => {
    // Check if the item is already in the selectedItemData array
    const isDuplicate = selectedItemData.some(
      (selectedItem) => selectedItem.id === item.id
    );

    if (!isDuplicate) {
      setSelectedItemData([...selectedItemData, item]);
      setIsSelectedItemDivOpen(false);
    } else {
      setIsSelectedItemDivOpen(false);
      Swal.fire({
        title: "Oops...",
        text: "Product is already added. Please update the quantity!",
        timer: 1000,
      });
    }
  };

  // barcode products get from backend
  useEffect(() => {
    if (searchBarcodeInput.length >= 3) {
      setBarCodeProducts(returnProductsDataWithId);

      if (
        returnProductsDataWithId.some(
          (x) => x.custom_barcode === searchBarcodeInput
        )
      ) {
        reset();
        const item = returnProductsDataWithId.find(
          (x) => x.custom_barcode === searchBarcodeInput
        );

        return handleItemClick(item);
      }
      if (returnProductsDataWithId.length > 0) {
        reset();
        setSearchBarcodeInput("");
        setIsSelectedItemDivOpen(true);
      }
    }
  }, [searchBarcodeInput, returnProductsDataWithId]);

  useEffect(() => {
    axios
      .get("/branch/viewbranch")
      .then((res) => setBranchData(res.data))
      .catch((error) => {});
  }, []);

  const [supplierTypeData] = useGetData(
    "/supplier/viewsuppliers",
    "supplierdata"
  );

  const [paymentTypeData] = useGetData(
    "/paymenttype/viewpaymenttypes/",
    "paymenttype"
  );

  const currentDateTime = moment().format("YYYYMMDDHHmmss");

  useEffect(() => {
    axios
      .get("/accounts/viewaccounts/")
      .then((res) => setAccountTypeData(res.data));
  }, []);

  //   handle return items data

  const returnPaymentsData = {
    sales_id: sales_id,
    warehouse_id: warehouse_id,
    return_code: `RL${randomSPNumber}`,
    reference_no: reference,
    return_date: currentDateTime,
    customer_id: customer_id,
    grand_total: numericFormatter(totalSubTotal) || 0,
    payment_status:
      payingAmount >= totalSubTotal
        ? "Paid"
        : payingAmount > 0
        ? "Partially Paid"
        : "Due",
    paid_amount: numericFormatter(payingAmount) || 0,
    account_id: accountMethodId,
    created_date: currentDateTime,
    created_time: currentDateTime,
    itemsReturn: productsData,
    paymentsReturn: [
      {
        payment_code: `PAY${currentDateTime} `,
        payment_date: accountMethodId,
        payment_type: paymentMethod,
        payment: numericFormatter(payingAmount) || 0,
        change_return: numericFormatter(changeReturnData) || 0,
        created_time: currentDateTime,
        created_date: currentDateTime,
        account_id: accountMethodId,
      },
    ],
  };

  // hook form data
  const onSubmit = () => {
    if (updatedReturnProducts?.length > 0) {
      axios
        .post("/salesreturn/create-sales-return", returnPaymentsData)
        .then((response) => {
          if (response) {
            navigate("/sales-management/sales-return-list");
            CustomToast("success", "Return Successfully");
          }
        })
        .catch((err) => {
          if (err) {
            CustomToast("error", "Something went wrong");
          }
        });
      reset();
    } else {
      CustomToast("error", "Please add product");
    }
  };

  return (
    <>
      <Fragment>
        <Breadcrumb title="Sales Return " parent="Sales  Management" />
        <Container fluid={true}>
          <Card>
            <CardBody>
              <form onSubmit={handleSubmit(onSubmit)}>
                <section className="d-flex justify-content-between align-items-center">
                  {/* child-1 ----------------------------------*/}
                  <div
                    className="col-6 d-flex flex-column gap-3 "
                    style={{ width: "45%" }}
                  >
                    {/* child-1-1 */}
                    <div className="border rounded-1" style={{ width: "96%" }}>
                      <span
                        style={{ width: "20%" }}
                        className="fw-bold text-center border-end d-inline-block p-2"
                      >
                        Warehouse
                      </span>{" "}
                      <span className="ps-2 fw-bold">{warehouse_name}</span>
                    </div>
                    {/* child-1-2 */}
                    <div className="border rounded-1" style={{ width: "96%" }}>
                      <span
                        style={{ width: "20%" }}
                        className="fw-bold text-center  border-end d-inline-block p-2"
                      >
                        Invoice
                      </span>{" "}
                      <span className="ps-2">
                        {init_code} {sales_code}
                      </span>
                    </div>

                    {/* child-1-3 */}
                    <div
                      className="border rounded-1 d-flex "
                      style={{ width: "96%" }}
                    >
                      <span
                        style={{ width: "20%" }}
                        className="fw-bold text-center border-end d-inline-block p-2"
                      >
                        Status
                      </span>{" "}
                      <Dropdown style={{ width: "73%" }}>
                        <Dropdown.Toggle id="dropdown-basic">
                          {selectedStatus}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          style={{
                            maxHeight: "25vh",
                            boxShadow: "0px 0px 30px 10px rgb(197, 194, 194)",
                            width: "80%",
                          }}
                          className="overflow-auto rounded-2 h-auto d-flex mt-1 flex-column p-2 gap-1"
                        >
                          {statusData.map((status) => (
                            <Dropdown.Item
                              key={status.id}
                              className="w-100 text-center text-dark border rounded-1 bg-black-hover p-0"
                              onClick={() => setSelectedStatus(status.name)}
                            >
                              {status.name}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  {/* child-2---------------------------------------------- */}
                  <div
                    className="col-6 d-flex flex-column gap-3 "
                    style={{ width: "45%" }}
                  >
                    {/* child-2-1 */}
                    <div className="border rounded-1" style={{ width: "96%" }}>
                      <span
                        style={{ width: "25%" }}
                        className="fw-bold text-center border-end d-inline-block p-2"
                      >
                        Customer Name
                      </span>{" "}
                      <span className="ps-2 fw-bold">{customer_name}</span>
                    </div>
                    {/* child-2-2 */}
                    <div className="border rounded-1" style={{ width: "96%" }}>
                      <span
                        style={{ width: "25%" }}
                        className="fw-bold text-center border-end d-inline-block p-2"
                      >
                        Sales Date
                      </span>{" "}
                      <span className="ps-2">{created_date?.slice(0, 10)}</span>
                    </div>
                    {/* child-2-3 */}
                    <div
                      className="border rounded-1 d-flex justify-content-between"
                      style={{ width: "96%" }}
                    >
                      <span
                        style={{ width: "25%" }}
                        className="fw-bold text-center border-end d-inline-block p-2"
                      >
                        Reference No
                      </span>{" "}
                      <input
                        style={{ width: "72%" }}
                        type="text"
                        className=" m-2 border-0"
                        placeholder="Enter Reference No"
                        name=""
                        id=""
                        onChange={(e) => setReference(e.target.value)}
                      />
                    </div>
                  </div>
                </section>
                {/* barcode start  */}
                <div className="col-md-12 d-flex justify-content-center align-items-center my-5">
                  <div
                    style={{ width: "60%" }}
                    className="d-flex border justify-content-center rounded-1 align-items-center"
                  >
                    <span className="border-end  p-2">
                      <FaBarcode></FaBarcode>
                    </span>
                    <div className="w-100 px-1 position-relative">
                      <input
                        type="text"
                        className="w-100 border-0"
                        placeholder="Item Name/Barcode/Item Code"
                        defaultValue={""}
                        title="Item Name/Barcode/Item Code"
                        name="barcodeInput"
                        {...register("barcodeInput")}
                        onChange={(e) => {
                          setSearchBarcodeInput(e.target.value);
                        }}
                      />
                      {isSelectedItemDivOpen && (
                        <BarcodeDropdownSection
                          selectedDropdownMenu={selectedDropdownMenu}
                          barCodeProducts={barCodeProducts}
                          setSelectedItemData={setSelectedItemData}
                          selectedItemData={selectedItemData}
                          setIsSelectedItemDivOpen={setIsSelectedItemDivOpen}
                          handleItemClick={handleItemClick}
                        />
                      )}
                    </div>
                  </div>
                </div>

                {/* return  table  */}
                <section
                  className=" px-2 border rounded-2 bg-body-secondary "
                  style={{
                    maxHeight: "437px",
                    overflowY: "scroll",
                    overflowX: "hidden",
                  }}
                >
                  <Table className="my-2 rounded-2">
                    <thead>
                      <tr className="text-center">
                        <th className="text-start">Item Name</th>
                        <th style={{ display: "none" }}>Unit Per Cost</th>
                        <th>Purchase Price/unit cost</th>
                        <th>Quantity</th>
                        <th>
                          {" "}
                          Subtotal(
                          <TbCurrencyTaka />)
                        </th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody className="text-center">
                      {selectedItemData?.map((product, index) => (
                        <SalesReturnTable
                          key={index}
                          product={product}
                          products={selectedItemData}
                          setTotalSubTotal={setTotalSubTotal}
                          totalSubTotal={totalSubTotal}
                          grandTotal={grandTotal}
                          setSelectedItemData={setSelectedItemData}
                          setTotalQuantity={setTotalQuantity}
                          setSalesPrice={setSalesPrice}
                          salesPrice={salesPrice}
                          salesItems={salesItems}
                          setSalesItems={setSalesItems}
                          selectedStatus={selectedStatus}
                          setUpdatedReturnProducts={setUpdatedReturnProducts}
                        ></SalesReturnTable>
                      ))}
                    </tbody>
                  </Table>
                </section>
                {/* calculation section */}
                <div className=" mt-5">
                  <section className="d-flex justify-content-between col-10 my-5 mx-auto">
                    {/* child-1 */}

                    <h4 style={{ color: "black", fontWeight: "bold" }}>
                      {" "}
                      Return Quantity:{" "}
                      <span
                        style={{ fontSize: "15px", fontWeight: "bolder" }}
                      ></span>
                      {numericFormatter(totalQuantity) || 0}
                    </h4>
                    {/* child-2 */}
                    <div className="d-flex flex-column gap-2 col-5 align-items-start">
                      <h4 style={{ color: "black", fontWeight: "bold" }}>
                        {" "}
                        Return Total Amount:{" "}
                        <span
                          style={{ fontSize: "15px", fontWeight: "bolder" }}
                        >
                          {" "}
                          ৳{" "}
                        </span>
                        {numericFormatter(totalSubTotal) || 0}
                      </h4>
                      <h4 style={{ color: "black", fontWeight: "bold" }}>
                        {" "}
                        Paying Amount:{" "}
                        <span
                          style={{ fontSize: "15px", fontWeight: "bolder" }}
                        >
                          {" "}
                          ৳{" "}
                        </span>
                        {(payingAmount && numericFormatter(payingAmount)) || 0}
                      </h4>
                      <h4 style={{ color: "black", fontWeight: "bold" }}>
                        {" "}
                        Due Amount:{" "}
                        <span
                          style={{ fontSize: "15px", fontWeight: "bolder" }}
                        >
                          {" "}
                          ৳{" "}
                        </span>
                        {numericFormatter(totalDueAmount) || 0}
                      </h4>
                      <h4 style={{ color: "black", fontWeight: "bold" }}>
                        {" "}
                        Change Return Amount:{" "}
                        <span
                          style={{ fontSize: "15px", fontWeight: "bolder" }}
                        >
                          {" "}
                          ৳{" "}
                        </span>
                        {numericFormatter(changeReturnData) || 0}
                      </h4>
                    </div>
                  </section>
                  {/* main section-1 */}
                  <div className="col-12">
                    <h4 className="fw-bold mb-2">
                      Previous Payments Information :
                    </h4>
                    <section className="bg-body-secondary rounded-2 p-2">
                      <SalesListTableSection
                        tableHeader={paymentTable}
                        tableData={sales_payments}
                        showTable={"viewSalesPayment"}
                      ></SalesListTableSection>
                    </section>
                  </div>

                  {/* paying amount section */}
                  <section className="mt-5">
                    <h4 className="fw-bold mb-2">Payments Information :</h4>

                    <div
                      style={{
                        backgroundColor: "#F2F2F4",
                        padding: "10px",
                        borderRadius: "5px",
                      }}
                    >
                      <div className="row">
                        <div className="form-group col-md-4">
                          <label for="amount" className="fw-bold mb-1">
                            Amount
                          </label>
                          <input
                            name="amount"
                            type="number"
                            step={"any"}
                            className="text-input cs-text-input border"
                            id="amount"
                            {...register("amount", { required: true })}
                            aria-describedby="amountHelp"
                            placeholder="Amount"
                            onChange={(e) =>
                              setPayingAmount(parseFloat(e.target.value))
                            }
                          />
                          {error.amount && (
                            <p className="text-center text-danger">
                              Amount is required
                            </p>
                          )}
                        </div>
                        {/* payment type */}

                        <div className=" form-group col-md-4">
                          <label for="paymentMethod" className="fw-bold mb-1">
                            {" "}
                            Payment Type{" "}
                          </label>
                          <UncontrolledDropdown className="white w-100">
                            <DropdownToggle
                              className="w-100"
                              style={{ backgroundColor: "#FFFFFF" }}
                              caret
                              color="white"
                            >
                              {paymentMethod}
                            </DropdownToggle>
                            <DropdownMenu
                              className="text-center w-100 overflow-auto h-auto p-2"
                              style={{
                                maxHeight: "150px",
                                boxShadow:
                                  "0px 0px 30px 10px rgb(197, 194, 194)",
                              }}
                            >
                              {paymentTypeData?.map((paymentData) => (
                                <DropdownItem
                                  className="w-100 text-center text-dark mt-1 border rounded-1 bg-black-hover p-0"
                                  key={paymentData.id}
                                  onClick={(e) =>
                                    setPaymentMethod(paymentData.payment_type)
                                  }
                                >
                                  {paymentData?.payment_type}
                                </DropdownItem>
                              ))}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                        {/* account method */}
                        <div className=" form-group col-md-4">
                          <label for="amount" className="fw-bold mb-1">
                            {" "}
                            Account{" "}
                          </label>
                          <UncontrolledDropdown className=" w-100">
                            <DropdownToggle
                              className="border w-100"
                              caret
                              color="white"
                              style={{ backgroundColor: "#FFFFFF" }}
                            >
                              {accountMethodName}
                            </DropdownToggle>
                            <DropdownMenu
                              className="text-center w-100 overflow-auto h-auto p-2"
                              style={{
                                maxHeight: "150px",
                                boxShadow:
                                  "0px 0px 30px 10px rgb(197, 194, 194)",
                              }}
                            >
                              {accountTypeData?.map((accountType) => (
                                <DropdownItem
                                  className="w-100 text-center text-dark mt-1 border rounded-1 bg-black-hover p-0"
                                  key={accountType.id}
                                  onClick={(e) => {
                                    setAccountMethodName(
                                      accountType.account_name
                                    );
                                    setAccountMethodId(accountType.id);
                                  }}
                                >
                                  {accountType.account_name}
                                </DropdownItem>
                              ))}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                      </div>
                      <div className="form-group col-md-12">
                        <label
                          className="fw-bold mb-1"
                          for="exampleFormControlTextarea1"
                        >
                          Payment Note
                        </label>
                        <textarea
                          className="text-input cs-text-input border"
                          onChange={(e) => setPaymentNote(e.target.value)}
                          id="exampleFormControlTextarea1"
                          rows="3"
                        ></textarea>
                      </div>
                    </div>
                  </section>
                  <div className="col-md-12 d-flex justify-content-center align-items-center gap-5 mt-3">
                    <Button
                      style={{ backgroundColor: "green" }}
                      color="primary"
                      type="submit"
                      className="btn-add"
                    >
                      Save
                    </Button>
                    <Link to={"/dashboard"}>
                      <Button color="secondary" type="reset">
                        Cancel
                      </Button>
                    </Link>
                  </div>
                </div>
              </form>
            </CardBody>
          </Card>
        </Container>
      </Fragment>
    </>
  );
};

export default SalesReturnHome;
