import {
	Card,
	CardBody,
	Col,
	Media,
} from "reactstrap";
import {
	Navigation,
	Box,
	MessageSquare,
	Users,
	Briefcase,
	CreditCard,
	ShoppingCart,
	Calendar,
	DollarSign,
} from "react-feather";
import CountUp from "react-countup";
import { TbTruckDelivery } from "react-icons/tb";
import { AiOutlineFilePdf } from "react-icons/ai";
import useGetData from "../../../hooks/useGetData";
import useNumberFormatter from "../../../hooks/useNumberFormatter";
import { useEffect, useState } from "react";
import axios from "../../../util/axiosInstance";

const DashboardCard = () => {

	const [totalPurchaseDue, setTotalPurchaseDue] = useState([]);
	const [totalSalesDue, setTotalSalesDue] = useState([]);
	const [totalSales, setTotalSales] = useState([]);
	const [totalExpenses, setTotalExpenses] = useState([]);
	const [totalCustomers, setTotalCustomers] = useState([]);
	const [suppliers, setSuppliers] = useState([]);
	const [purchases, setPurchases] = useState([]);
	const [invoices, setInvoices] = useState([])

	const [isCountingFinished, setIsCountingFinished] = useState(false);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true);

	const numericFormatter = useNumberFormatter();

	// const [totalPurchaseDue, totalPurchaseDueRefetch] = useGetData('/dashboard/total-purchase-due?startDate=2023-09-27&endDate=2023-09-28', 'totalPurchaseDue');

	// TOTAL PURCHASE DUE
	useEffect(() => {
		// Define the async function
		const fetchData = async () => {
			try {
				const response = await axios.get('/dashboard/total-purchase-due');
				setTotalPurchaseDue(response.data);
				setLoading(false);
			} catch (error) {
				setError(error);
				setLoading(false);
			}
		}
		// Invoke the async function
		fetchData();
	}, []);  // The empty dependency array means this useEffect will run once when the component mounts


	// TOTAL SALES DUE
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get('/dashboard/total-sales-due');
				setTotalSalesDue(response.data);
				setLoading(false);
			} catch (error) {
				setError(error);
				setLoading(false);
			}
		}
		fetchData();
	}, []);

	// TOTAL SALES 
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get('/dashboard/total-sales-grand-total');
				setTotalSales(response.data);
				setLoading(false);
			} catch (error) {
				setError(error);
				setLoading(false);
			}
		}
		fetchData();
	}, []);

	// TOTAL EXPENSE
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get('/dashboard/total-expense');
				setTotalExpenses(response.data);
				setLoading(false);
			} catch (error) {
				setError(error);
				setLoading(false);
			}
		}
		fetchData();
	}, []);

	// customers 
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get('/dashboard/count-customers');
				setTotalCustomers(response.data);
				setLoading(false);
			} catch (error) {
				setError(error);
				setLoading(false);
			}
		}
		fetchData();
	}, []);

	// Suppliers 
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get('/dashboard/count-suppliers');
				setSuppliers(response.data);
				setLoading(false);
			} catch (error) {
				setError(error);
				setLoading(false);
			}
		}
		fetchData();
	}, []);

	// purchases 
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get('/dashboard/total-purchase');
				setPurchases(response.data);
				setLoading(false);
			} catch (error) {
				setError(error);
				setLoading(false);
			}
		}
		fetchData();
	}, []);

	// INVOICES 
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get('/dashboard/count-sales/');
				setInvoices(response.data);
				setLoading(false);
			} catch (error) {
				setError(error);
				setLoading(false);
			}
		}
		fetchData();
	}, []);


	const handleCountingEnd = () => {
		setIsCountingFinished(true);
	};

	//TOTAL PURCHASE DUE
	function cardOne(num) {
		if (num >= 1000000000000) {
			return (num / 1000000000000).toFixed(1).replace('.0', '') + 'T';
		} else if (num >= 1000000000) {
			return (num / 1000000000).toFixed(1).replace('.0', '') + 'Cr';
		} else if (num >= 1000000) {
			return (num / 1000000).toFixed(1).replace('.0', '') + 'L';
		} else if (num >= 1000) {
			return (num / 1000).toFixed(1).replace('.0', '') + 'K';
		} else {
			return num.toString();
		}
	}
	const cardOneValue = cardOne(parseFloat(totalPurchaseDue?.total_purchase_due || 0));


	// TOTAL SALES DUE
	function cardTwo(num) {
		if (num >= 1000000000000) {
			return (num / 1000000000000).toFixed(1).replace('.0', '') + 'T';
		} else if (num >= 1000000000) {
			return (num / 1000000000).toFixed(1).replace('.0', '') + 'Cr';
		} else if (num >= 1000000) {
			return (num / 1000000).toFixed(1).replace('.0', '') + 'L';
		} else if (num >= 1000) {
			return (num / 1000).toFixed(1).replace('.0', '') + 'K';
		} else {
			return num.toString();
		}
	}
	const cardTwoValue = cardTwo(parseFloat(totalSalesDue?.total_sales_due || 0));


	// TOTAL SALES
	function cardThree(num) {
		if (num >= 1000000000000) {
			return (num / 1000000000000).toFixed(1).replace('.0', '') + 'T';
		} else if (num >= 1000000000) {
			return (num / 1000000000).toFixed(1).replace('.0', '') + 'Cr';
		} else if (num >= 1000000) {
			return (num / 1000000).toFixed(1).replace('.0', '') + 'L';
		} else if (num >= 1000) {
			return (num / 1000).toFixed(1).replace('.0', '') + 'K';
		} else {
			return num.toString();
		}
	}
	const cardThreeValue = cardThree(parseFloat(totalSales?.total_grand_total || 0));


	// TOTAL EXPENSE
	function cardFour(num) {
		if (num >= 1000000000000) {
			return (num / 1000000000000).toFixed(1).replace('.0', '') + 'T';
		} else if (num >= 1000000000) {
			return (num / 1000000000).toFixed(1).replace('.0', '') + 'Cr';
		} else if (num >= 1000000) {
			return (num / 1000000).toFixed(1).replace('.0', '') + 'L';
		} else if (num >= 1000) {
			return (num / 1000).toFixed(1).replace('.0', '') + 'K';
		} else {
			return num.toString();
		}
	}
	const cardFourValue = cardFour(parseFloat(totalExpenses.total_expense || 0));

	// CUSTOMER 
	function cardFive(num) {
		if (num >= 1000000000000) {
			return (num / 1000000000000).toFixed(1).replace('.0', '') + 'T';
		} else if (num >= 1000000000) {
			return (num / 1000000000).toFixed(1).replace('.0', '') + 'Cr';
		} else if (num >= 1000000) {
			return (num / 1000000).toFixed(1).replace('.0', '') + 'L';
		} else if (num >= 1000) {
			return (num / 1000).toFixed(1).replace('.0', '') + 'K';
		} else {
			return num.toString();
		}
	}
	const cardFiveValue = cardFive(parseFloat(totalCustomers?.customer_count || 0));

	// SUPPLIERS
	function cardSix(num) {
		if (num >= 1000000000000) {
			return (num / 1000000000000).toFixed(1).replace('.0', '') + 'T';
		} else if (num >= 1000000000) {
			return (num / 1000000000).toFixed(1).replace('.0', '') + 'Cr';
		} else if (num >= 1000000) {
			return (num / 1000000).toFixed(1).replace('.0', '') + 'L';
		} else if (num >= 1000) {
			return (num / 1000).toFixed(1).replace('.0', '') + 'K';
		} else {
			return num.toString();
		}
	}
	const cardSixValue = cardSix(parseFloat(suppliers?.suppliers_count || 0));

	// PURCHASES
	function cardSeven(num) {
		if (num >= 1000000000000) {
			return (num / 1000000000000).toFixed(1).replace('.0', '') + 'T';
		} else if (num >= 1000000000) {
			return (num / 1000000000).toFixed(1).replace('.0', '') + 'Cr';
		} else if (num >= 1000000) {
			return (num / 1000000).toFixed(1).replace('.0', '') + 'L';
		} else if (num >= 1000) {
			return (num / 1000).toFixed(1).replace('.0', '') + 'K';
		} else {
			return num.toString();
		}
	}
	const cardSevenValue = cardSeven(parseFloat(purchases?.total_purchase || 0));

	// INVOICES
	function cardEight(num) {
		if (num >= 1000000000000) {
			return (num / 1000000000000).toFixed(1).replace('.0', '') + 'T';
		} else if (num >= 1000000000) {
			return (num / 1000000000).toFixed(1).replace('.0', '') + 'Cr';
		} else if (num >= 1000000) {
			return (num / 1000000).toFixed(1).replace('.0', '') + 'L';
		} else if (num >= 1000) {
			return (num / 1000).toFixed(1).replace('.0', '') + 'K';
		} else {
			return num.toString();
		}
	}
	const cardEightValue = cardEight(parseFloat(invoices?.invoice_count || 0));


	return (
		<>
			<Col md="4 md-50" xl="3" sm="6">
				<Card className=" o-hidden widget-cards" style={{ backgroundColor: '#6571FF' }}>
					<CardBody className=" p-3">
						<Media className="static-top-widget row">
							<div className="icons-widgets col-3 p-1">
								<div className=" text-center" style={{ height: '45px', width: '45px', marginLeft: '7px' }}>
									<span style={{ fontSize: '23px', fontWeight: '900', color: "#071952", }}>৳</span>
								</div>
							</div>
							<Media body className="col-4" style={{ marginLeft: '-10px' }}>
								<span className="text-white" > TOTAL PURCHASE DUE</span>
								<h3 className="mb-0">
									৳
									{
										isCountingFinished ? <span> {cardOneValue}</span> : <CountUp className="counter" end={totalPurchaseDue?.total_purchase_due} formattingFn={cardOne} onEnd={handleCountingEnd} />
									}
								</h3>
							</Media>
						</Media>
					</CardBody>
				</Card>
			</Col>

			<Col md="4 md-50" xl="3" sm="6">
				<Card className=" o-hidden  widget-cards">
					<CardBody className="bg-secondary p-3">
						<Media className="static-top-widget row">
							<div className="icons-widgets col-3 p-1">
								<div className="align-self-center text-center" style={{ height: '45px', width: '45px', marginLeft: '7px' }}>
									{/* <DollarSign className="font-secondary" /> */}
									<span style={{ fontSize: '23px', fontWeight: '900', color: "#071952" }}>৳</span>
								</div>
							</div>
							<Media body className="col-4" style={{ marginLeft: '-10px' }}>

								<span> TOTAL SALES DUE</span>

								<h3 className="mb-0">
									{/* ৳ <CountUp className="counter" end={numericFormatter(totalSalesDue?.total_purchase_due || 0)} /> */}
							
									৳
									{
										isCountingFinished ? <span> {cardTwoValue}</span> : <CountUp className="counter" end={totalSalesDue?.total_sales_due} formattingFn={cardTwo} onEnd={handleCountingEnd} />
									}

								</h3>
							</Media>
						</Media>
					</CardBody>
				</Card>
			</Col>

			<Col md="4 md-50" xl="3" sm="6">
				<Card className="o-hidden widget-cards" style={{ backgroundColor: '#0099FB' }}>
					<CardBody className="p-3">
						<Media className="static-top-widget row">
							<div className="icons-widgets col-3 p-1">
								<div className="align-self-center text-center" style={{ height: '45px', width: '45px', marginLeft: '7px' }}>
									<span style={{ fontSize: '23px', fontWeight: '900', color: "#071952" }}>৳</span>
								</div>
							</div>
							<Media body className="col-4">
								<span className="text-white"> TOTAL SALES </span>

								<h3 className="mb-0">
									{/* ৳ <CountUp className="counter" end={numericFormatter(totalSales?.total_grand_total || 0)} /> */}
									{/* ৳	{cardThreeValue} */}
									{/* <CountUp className="counter" end={cardTwoValue} /> */}

									৳
									{
										isCountingFinished ? <span> {cardThreeValue}</span> : <CountUp className="counter" end={totalSales?.total_grand_total} formattingFn={cardThree} onEnd={handleCountingEnd} />
									}

								</h3>
							</Media>
						</Media>
					</CardBody>
				</Card>
			</Col>

			<Col md="4 md-50" xl="3" sm="6">
				<Card className=" o-hidden widget-cards" style={{ backgroundColor: '#FFB821' }}>
					<CardBody className="p-3 ">
						<Media className="static-top-widget row">
							<div className="icons-widgets col-3 p-1">
								<div className="align-self-center text-center" style={{ height: '45px', width: '45px', marginLeft: '7px' }}>
									<span style={{ fontSize: '23px', fontWeight: '900', color: "#071952" }}>৳</span>
								</div>
							</div>
							<Media body className="col-6">
								<span className="text-white"> TOTAL EXPENSE</span>

								<h3 className="mb-0">
									{/* ৳ <CountUp className="counter" end={numericFormatter(totalExpenses.total_expense || 0)} /> */}
									{/* ৳ {cardFourValue} */}

									৳
									{
										isCountingFinished ? <span> {cardFourValue}</span> : <CountUp className="counter" end={totalExpenses?.total_expense} formattingFn={cardFour} onEnd={handleCountingEnd} />
									}

								</h3>
							</Media>
						</Media>
					</CardBody>
				</Card>
			</Col>

			<Col md="4 md-50" xl="3" sm="6">
				<Card className=" o-hidden widget-cards" style={{ backgroundColor: '#6F42C1' }}>
					<CardBody className="p-3">
						<Media className="static-top-widget row">
							<div className="icons-widgets col-3 p-1">
								<div className="align-self-center text-center" style={{ height: '45px', width: '45px', marginLeft: '7px' }}>
									<Users style={{ fontSize: '23px', fontWeight: '900', color: "#071952" }} />
								</div>
							</div>
							<Media body className="col-6">
								<span className="text-white">CUSTOMERS </span>

								<h3 className="mb-0">
									{/* <CountUp className="counter" end={numericFormatter(totalCustomers?.customer_count || 0)} /> */}
									{/* {cardFiveValue || 0} */}
									
									{
										isCountingFinished ? <span> {cardFiveValue}</span> : <CountUp className="counter" end={totalCustomers?.customer_count} formattingFn={cardFive} onEnd={handleCountingEnd} />
									}
								</h3>
							</Media>
						</Media>
					</CardBody>
				</Card>
			</Col>

			<Col md="4 md-50" xl="3" sm="6">
				<Card className=" o-hidden widget-cards" style={{ backgroundColor: '#E83E8C' }}>
					<CardBody className=" p-3">
						<Media className="static-top-widget row">
							<div className="icons-widgets col-3 p-1">
								<div className="align-self-center text-center" style={{ height: '45px', width: '45px', marginLeft: '7px' }}>
									<TbTruckDelivery style={{ fontSize: '23px', fontWeight: '900', color: "#071952" }} />
								</div>
							</div>
							<Media body className="col-6">
								<span className="text-white">SUPPLIERS </span>
								<h3 className="mb-0">
									{/* <CountUp className="counter" end={numericFormatter(suppliers?.suppliers_count || 0)} /> */}
									{/* {cardSixValue || 0} */}
									{
										isCountingFinished ? <span> {cardSixValue}</span> : <CountUp className="counter" end={suppliers?.suppliers_count} formattingFn={cardSix} onEnd={handleCountingEnd} />
									}
								</h3>
							</Media>
						</Media>
					</CardBody>
				</Card>
			</Col>

			<Col md="4 md-50" xl="3" sm="6">
				<Card className=" o-hidden widget-cards">
					<CardBody className="p-3 " style={{ backgroundColor: '#00C6FF' }}>
						<Media className="static-top-widget row">
							<div className="icons-widgets col-3 p-1">
								<div className="align-self-center text-center" style={{ height: '45px', width: '45px', marginLeft: '7px' }}>

									<span style={{ fontSize: '23px', fontWeight: '900', color: "#071952" }}>৳</span>
								</div>
							</div>
							<Media body className="col-6">
								<span className="text-white">PURCHASES </span>
								<h3 className="mb-0">
									{/* <CountUp className="counter" end={numericFormatter(purchases?.total_purchase || 0)} /> */}
									{/* {cardSevenValue || 0} */}

									৳
									{
										isCountingFinished ? <span> {cardSevenValue}</span> : <CountUp className="counter" end={purchases?.total_purchase} formattingFn={cardSeven} onEnd={handleCountingEnd} />
									}
								</h3>
							</Media>
						</Media>
					</CardBody>
				</Card>
			</Col>

			<Col md="4 md-50" xl="3" sm="6">
				<Card className=" o-hidden widget-cards " style={{ backgroundColor: '#FF0000' }}>
					<CardBody className="p-3 ">
						<Media className="static-top-widget row">
							<div className="icons-widgets col-3 p-1">
								<div className="align-self-center text-center" style={{ height: '45px', width: '45px', marginLeft: '7px' }}>
									<AiOutlineFilePdf style={{ fontSize: '23px', fontWeight: '900', color: "#071952" }} />
								</div>
							</div>
							<Media body className="col-6">
								<span className="text-white">INVOICES</span>
								<h3 className="mb-0">
									{/* <CountUp className="counter" end={45631} /> */}
									{/* {cardEightValue || 0} */}

									{
										isCountingFinished ? <span> {cardEightValue}</span> : <CountUp className="counter" end={invoices?.invoice_count} formattingFn={cardEight} onEnd={handleCountingEnd} />
									}

									{/* <small>Today Total Expense </small> */}
								</h3>
							</Media>
						</Media>
					</CardBody>
				</Card>
			</Col>
		</>
	);
};

export default DashboardCard;