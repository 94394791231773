import React from 'react';

const SortSection = ({
    setSelectedValue,
    setSearchValue,
    searchValue
}) => {
    return (
        <>
             <div className="col-md-6 d-flex align-items-center">
            <span>Show</span>
            <select
              onChange={(e) => setSelectedValue(e.target.value)}
              className="mx-1"
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={500}>500</option>
              <option value={"allData"}>AllData</option>
            </select>
            <span>entries</span>
          </div>
          <div className="col-md-6 d-flex align-items-center justify-content-end">
            <div className="d-flex align-items-center gap-2 gap-2 w-50">
              <label htmlFor="search" className="fw-bold">
                Search:
              </label>
              <input
                type="text"
                id="search"
                className="cs-text-input border text-input"
                placeholder="Enter Customer Name"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
          </div>
        </>
    );
};

export default SortSection;