import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Table } from 'reactstrap';
import axios from '../../util/axiosInstance';
import { AiFillEdit, AiTwotoneDelete } from 'react-icons/ai';
import TableLoading from '../common/loading/TableLoading';

const AccountListTable = () => {
  const [accounts, setAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const btnStyle = {
    height: '40px',
    width: '40px',
    borderRadius: '50%',
    padding: '8px',
    background: 'transparent',
  };

  const token = localStorage.getItem('access-token');
  useEffect(() => {
    axios
      .get('/accounts/viewaccounts', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setAccounts(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        // localStorage.removeItem('access-token');
        navigate('/');
      });
  }, [token, navigate]);
  const deleteHandler = (id) => {
    axios
      .delete(`/accounts/deleteaccount/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const newData = accounts.filter((account) => account.id === id);

        setAccounts(newData);
      })
      .catch((error) => {
        // localStorage.removeItem('access-token');
        navigate('/');
      });
  };
  return (
    <div>
      <Table
        bordered
        hover
        striped>
        <thead className='text-center'>
          <tr>
            <th>#</th>
            <th>Account Name</th>
            <th>Account code</th>
            <th>note</th>
            <th>Balance</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className='text-center'>
          {accounts.length === 0 && isLoading ? (
            <TableLoading
              noRow={7}
              noTd={6}
            />
          ) : (
            accounts?.map((acc, i) => (
              <tr>
                <th scope='row'>{i + 1}</th>
                <td>{acc.account_name}</td>
                <td>{acc.account_code}</td>
                <td>{acc.note}</td>
                <td>{acc.balance}</td>

                <td>
                  <Link
                    className='fs-4 text-decoration-none'
                    to={`/accounts/edit/${acc.id}`}>
                    {' '}
                    <AiFillEdit />{' '}
                  </Link>
                  <button
                    className='border-0 bg-transparent fs-4'
                    onClick={() => deleteHandler(acc.id)}>
                    <AiTwotoneDelete />{' '}
                  </button>
                </td>
              </tr>
            ))
          )}

          {/* <TableLoading /> */}
        </tbody>
      </Table>

      {/* <div className='sc-feUZmu jzrolw '>
        <div className='sc-hzhJZQ gmlwXk'>
          <div
            className='sc-aXZVg jNIkof rdt_Table'
            role='table'>
            <div
              className='sc-gEvEer hQPXLq rdt_TableHead'
              role='rowgroup'>
              <div
                className='sc-eqUAAy gocYik rdt_TableHeadRow'
                role='row'>
                <div
                  data-column-id={1}
                  className='sc-fqkvVR sc-dcJsrY sc-dAlyuH cygFkb ikexHN kjeJsv rdt_TableCol'>
                  <div
                    data-column-id={1}
                    data-sort-id={1}
                    role='columnheader'
                    tabIndex={0}
                    className='sc-jlZhew blGEez rdt_TableCol_Sortable'
                    disabled>
                    <b>Id</b>
                  </div>
                </div>

                <div
                  data-column-id={3}
                  className='sc-fqkvVR sc-dcJsrY sc-dAlyuH cygFkb ikexHN kjeJsv rdt_TableCol'>
                  <div
                    data-column-id={3}
                    data-sort-id={3}
                    role='columnheader'
                    tabIndex={0}
                    className='sc-jlZhew blGEez rdt_TableCol_Sortable'
                    disabled>
                    <b>Account_name</b>
                  </div>
                </div>
                <div
                  data-column-id={4}
                  className='sc-fqkvVR sc-dcJsrY sc-dAlyuH cygFkb ikexHN kjeJsv rdt_TableCol'>
                  <div
                    data-column-id={4}
                    data-sort-id={4}
                    role='columnheader'
                    tabIndex={0}
                    className='sc-jlZhew blGEez rdt_TableCol_Sortable'
                    disabled>
                    <b>Account_code</b>
                  </div>
                </div>
                <div
                  data-column-id={5}
                  className='sc-fqkvVR sc-dcJsrY sc-dAlyuH cygFkb ikexHN kjeJsv rdt_TableCol'>
                  <div
                    data-column-id={5}
                    data-sort-id={5}
                    role='columnheader'
                    tabIndex={0}
                    className='sc-jlZhew blGEez rdt_TableCol_Sortable'
                    disabled>
                    <b>Balance</b>
                  </div>
                </div>
                <div
                  data-column-id={6}
                  className='sc-fqkvVR sc-dcJsrY sc-dAlyuH cygFkb ikexHN kjeJsv rdt_TableCol'>
                  <div
                    data-column-id={6}
                    data-sort-id={6}
                    role='columnheader'
                    tabIndex={0}
                    className='sc-jlZhew blGEez rdt_TableCol_Sortable'
                    disabled>
                    <b>Note</b>
                  </div>
                </div>
                <div
                  data-column-id={7}
                  className='sc-fqkvVR sc-dcJsrY sc-dAlyuH cygFkb ikexHN kjeJsv rdt_TableCol'>
                  <div
                    data-column-id={7}
                    data-sort-id={7}
                    role='columnheader'
                    tabIndex={0}
                    className='sc-jlZhew blGEez rdt_TableCol_Sortable'
                    disabled>
                    <b>Created_by</b>
                  </div>
                </div>
                <div
                  data-column-id={8}
                  className='sc-fqkvVR sc-dcJsrY sc-dAlyuH cygFkb ikexHN kjeJsv rdt_TableCol'>
                  <div
                    data-column-id={8}
                    data-sort-id={8}
                    role='columnheader'
                    tabIndex={0}
                    className='sc-jlZhew blGEez rdt_TableCol_Sortable'
                    disabled>
                    <b>Created_date</b>
                  </div>
                </div>
                <div
                  data-column-id={9}
                  className='sc-fqkvVR sc-dcJsrY sc-dAlyuH cygFkb ikexHN kjeJsv rdt_TableCol'>
                  <div
                    data-column-id={9}
                    data-sort-id={9}
                    role='columnheader'
                    tabIndex={0}
                    className='sc-jlZhew blGEez rdt_TableCol_Sortable'
                    disabled>
                    <b>Created_time</b>
                  </div>
                </div>
                <div
                  data-column-id='delete'
                  className='sc-fqkvVR sc-dcJsrY sc-dAlyuH cygFkb ikexHN kjeJsv rdt_TableCol'>
                  <div
                    data-column-id='delete'
                    data-sort-id='delete'
                    role='columnheader'
                    tabIndex={0}
                    className='sc-jlZhew blGEez rdt_TableCol_Sortable'
                    disabled>
                    <button className='btn btn-danger btn-sm btn-delete mb-0 b-r-4'>
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='sc-dAbbOL kdgCYE rdt_TableBody'
              role='rowgroup'>
              <div
                id='row-16'
                role='row'
                className='sc-dhKdcB ijprYX rdt_TableRow'>
                <div
                  id='cell-1-16'
                  data-column-id={1}
                  role='cell'
                  className='sc-fqkvVR sc-dcJsrY sc-iGgWBj dKzMcW ikexHN gDVmoD rdt_TableCell'
                  data-tag='allowRowEvents'>
                  <div data-tag='allowRowEvents'>16</div>
                </div>

                <div
                  id='cell-3-16'
                  data-column-id={3}
                  role='cell'
                  className='sc-fqkvVR sc-dcJsrY sc-iGgWBj dKzMcW ikexHN gDVmoD rdt_TableCell'
                  data-tag='allowRowEvents'>
                  <div data-tag='allowRowEvents'>testcv</div>
                </div>
                <div
                  id='cell-4-16'
                  data-column-id={4}
                  role='cell'
                  className='sc-fqkvVR sc-dcJsrY sc-iGgWBj dKzMcW ikexHN gDVmoD rdt_TableCell'
                  data-tag='allowRowEvents'>
                  <div data-tag='allowRowEvents'>jig-1235</div>
                </div>
                <div
                  id='cell-5-16'
                  data-column-id={5}
                  role='cell'
                  className='sc-fqkvVR sc-dcJsrY sc-iGgWBj dKzMcW ikexHN gDVmoD rdt_TableCell'
                  data-tag='allowRowEvents'>
                  <div data-tag='allowRowEvents'>10</div>
                </div>
                <div
                  id='cell-6-16'
                  data-column-id={6}
                  role='cell'
                  className='sc-fqkvVR sc-dcJsrY sc-iGgWBj dKzMcW ikexHN gDVmoD rdt_TableCell'
                  data-tag='allowRowEvents'>
                  <div data-tag='allowRowEvents'>hgdfds</div>
                </div>
                <div
                  id='cell-7-16'
                  data-column-id={7}
                  role='cell'
                  className='sc-fqkvVR sc-dcJsrY sc-iGgWBj dKzMcW ikexHN gDVmoD rdt_TableCell'
                  data-tag='allowRowEvents'>
                  <div data-tag='allowRowEvents' />
                </div>
                <div
                  id='cell-8-16'
                  data-column-id={8}
                  role='cell'
                  className='sc-fqkvVR sc-dcJsrY sc-iGgWBj dKzMcW ikexHN gDVmoD rdt_TableCell'
                  data-tag='allowRowEvents'>
                  <div data-tag='allowRowEvents'>2023-09-16T04:00:00.000Z</div>
                </div>
                <div
                  id='cell-9-16'
                  data-column-id={9}
                  role='cell'
                  className='sc-fqkvVR sc-dcJsrY sc-iGgWBj dKzMcW ikexHN gDVmoD rdt_TableCell'
                  data-tag='allowRowEvents'>
                  <div data-tag='allowRowEvents'>06:33:40 PM</div>
                </div>
                <div
                  id='cell-delete-16'
                  data-column-id='delete'
                  role='cell'
                  className='sc-fqkvVR sc-dcJsrY sc-iGgWBj dKzMcW ikexHN vlWCQ rdt_TableCell'
                  data-tag='allowRowEvents'>
                  <div>
                    <span>
                      <input
                        type='checkbox'
                        name={16}
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div
                id='row-17'
                role='row'
                className='sc-dhKdcB hbgCLW rdt_TableRow'>
                <div
                  id='cell-1-17'
                  data-column-id={1}
                  role='cell'
                  className='sc-fqkvVR sc-dcJsrY sc-iGgWBj dKzMcW ikexHN gDVmoD rdt_TableCell'
                  data-tag='allowRowEvents'>
                  <div data-tag='allowRowEvents'>17</div>
                </div>
                <div
                  id='cell-2-17'
                  data-column-id={2}
                  role='cell'
                  className='sc-fqkvVR sc-dcJsrY sc-iGgWBj dKzMcW ikexHN gDVmoD rdt_TableCell'
                  data-tag='allowRowEvents'>
                  <div data-tag='allowRowEvents' />
                </div>
                <div
                  id='cell-3-17'
                  data-column-id={3}
                  role='cell'
                  className='sc-fqkvVR sc-dcJsrY sc-iGgWBj dKzMcW ikexHN gDVmoD rdt_TableCell'
                  data-tag='allowRowEvents'>
                  <div data-tag='allowRowEvents'>tester</div>
                </div>
                <div
                  id='cell-4-17'
                  data-column-id={4}
                  role='cell'
                  className='sc-fqkvVR sc-dcJsrY sc-iGgWBj dKzMcW ikexHN gDVmoD rdt_TableCell'
                  data-tag='allowRowEvents'>
                  <div data-tag='allowRowEvents'>code-gs1254</div>
                </div>
                <div
                  id='cell-5-17'
                  data-column-id={5}
                  role='cell'
                  className='sc-fqkvVR sc-dcJsrY sc-iGgWBj dKzMcW ikexHN gDVmoD rdt_TableCell'
                  data-tag='allowRowEvents'>
                  <div data-tag='allowRowEvents'>390</div>
                </div>
                <div
                  id='cell-6-17'
                  data-column-id={6}
                  role='cell'
                  className='sc-fqkvVR sc-dcJsrY sc-iGgWBj dKzMcW ikexHN gDVmoD rdt_TableCell'
                  data-tag='allowRowEvents'>
                  <div data-tag='allowRowEvents'>ha ha ha</div>
                </div>
                <div
                  id='cell-7-17'
                  data-column-id={7}
                  role='cell'
                  className='sc-fqkvVR sc-dcJsrY sc-iGgWBj dKzMcW ikexHN gDVmoD rdt_TableCell'
                  data-tag='allowRowEvents'>
                  <div data-tag='allowRowEvents' />
                </div>
                <div
                  id='cell-8-17'
                  data-column-id={8}
                  role='cell'
                  className='sc-fqkvVR sc-dcJsrY sc-iGgWBj dKzMcW ikexHN gDVmoD rdt_TableCell'
                  data-tag='allowRowEvents'>
                  <div data-tag='allowRowEvents'>2023-09-16T04:00:00.000Z</div>
                </div>
                <div
                  id='cell-9-17'
                  data-column-id={9}
                  role='cell'
                  className='sc-fqkvVR sc-dcJsrY sc-iGgWBj dKzMcW ikexHN gDVmoD rdt_TableCell'
                  data-tag='allowRowEvents'>
                  <div data-tag='allowRowEvents'>06:34:11 PM</div>
                </div>
                <div
                  id='cell-delete-17'
                  data-column-id='delete'
                  role='cell'
                  className='sc-fqkvVR sc-dcJsrY sc-iGgWBj dKzMcW ikexHN vlWCQ rdt_TableCell'
                  data-tag='allowRowEvents'>
                  <div>
                    <span>
                      <input
                        type='checkbox'
                        name={17}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div>
        <nav className=' d-flex justify-content-end align-items-center px-2 col-12 text-black-50 fs-6 bg-white border-top-1'>
          <span className=' mx-2 my-0 flex-shrink-1 user-select-none'>
            Rows per page:
          </span>
          <div className=' position-relative flex-shrink-0 mt-1 d-flex '>
            <select
              aria-label='Rows per page:'
              className='sc-bXCLTC eVrcSZ cs-cursor-pointer user-select-none ps-2 pe-4  '
              style={{
                height: '25px',
                maxWidth: '100%',
                color: '#000000',
                fontSize: 'inherit',
                border: 'none',
                background: 'transparent',
                appearance: 'none',
                direction: 'ltr',
              }}>
              <option
                value={10}
                selected>
                10
              </option>
              <option value={15}>15</option>
              <option value={20}>20</option>
              <option value={25}>25</option>
              <option value={30}>30</option>
            </select>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width={24}
              height={24}
              viewBox='0 0 24 24'
              className='position-absolute top-0'
              style={{ right: 0 }}>
              <path d='M7 10l5 5 5-5z' />
              <path
                d='M0 0h24v24H0z'
                fill='none'
              />
            </svg>
          </div>
          <span className='mx-4 user-select-none flex-shrink-1'>1-2 of 2</span>
          <div className='sc-bmzYkS fimDOL d-flex align-items-center rounded-1 white-space-none'>
            <button
              id='pagination-first-page'
              type='button'
              aria-label='First Page'
              aria-disabled='true'
              disabled
              className='position-relative d-block user-select-none border-0 rounded-1 cs-cursor-pointer'
              style={btnStyle}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width={24}
                height={24}
                viewBox='0 0 24 24'
                aria-hidden='true'
                role='presentation'>
                <path d='M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z' />
                <path
                  fill='none'
                  d='M24 24H0V0h24v24z'
                />
              </svg>
            </button>
            <button
              id='pagination-previous-page'
              type='button'
              aria-label='Previous Page'
              aria-disabled='true'
              disabled
              className='position-relative d-block user-select-none border-0 rounded-1 cs-cursor-pointer'
              style={btnStyle}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width={24}
                height={24}
                viewBox='0 0 24 24'
                aria-hidden='true'
                role='presentation'>
                <path d='M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z' />
                <path
                  d='M0 0h24v24H0z'
                  fill='none'
                />
              </svg>
            </button>
            <button
              id='pagination-next-page'
              type='button'
              aria-label='Next Page'
              aria-disabled='true'
              disabled
              className='position-relative d-block user-select-none border-0 rounded-1 cs-cursor-pointer'
              style={btnStyle}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width={24}
                height={24}
                viewBox='0 0 24 24'
                aria-hidden='true'
                role='presentation'>
                <path d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z' />
                <path
                  d='M0 0h24v24H0z'
                  fill='none'
                />
              </svg>
            </button>
            <button
              id='pagination-last-page'
              type='button'
              aria-label='Last Page'
              aria-disabled='true'
              disabled
              className='position-relative d-block user-select-none border-0 rounded-1 cs-cursor-pointer'
              style={btnStyle}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width={24}
                height={24}
                viewBox='0 0 24 24'
                aria-hidden='true'
                role='presentation'>
                <path d='M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z' />
                <path
                  fill='none'
                  d='M0 0h24v24H0V0z'
                />
              </svg>
            </button>
          </div>
        </nav>
      </div>

      <div className='Toastify' />
    </div>
  );
};

export default AccountListTable;