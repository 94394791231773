import React from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Fragment } from 'react';
import { Card, FormGroup } from 'reactstrap';
import { Link, useNavigate, } from 'react-router-dom';
import { useState } from 'react';
import axios from '../../../util/axiosInstance';
import { CustomToast } from '../../../hooks/useCustomToast';


const CategoriesListAdd = () => {
 const navigate = useNavigate();
 const [categoryName, setCategoryName] = useState('');
 const [description, setDescription] = useState('');
 const randomNumber = Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000;


 const categorySubmit = (e) => {
  e.preventDefault();
  const data = {
   "category_code": randomNumber,
   "category_name": categoryName,
   "description": description
  }
  if (categoryName && description) {
   axios.post('/expensecategory/addcategory', data).then(res => {
    if (res) {
     CustomToast("success", "Category Add Successfully")
     navigate('/expenses-categories-list');
    }
   }).catch(error => {
   })
  }else{
   CustomToast("error", "FillUp The Form")
  }

 }

 return (
  <Fragment>
   <Breadcrumb title="Expense Category  " parent="Expenses" />
   <Card className='p-2'>
    <div className='text-center fw-bold mb-3'>Please Enter Valid Data</div>
    <div className="col-md-6 col-sm-12 ">
     <FormGroup className='d-flex justify-content-center align-items-center w-100 '>
      <label htmlFor='categoryName' className='fw-bold  text-center' style={{ width: '200px' }}>
       Category Name <span className="text-danger">*</span>
      </label>
      <input
       onChange={(e) => setCategoryName(e.target.value)}
       className='form-control'
      />
     </FormGroup>
     <FormGroup className='w-100 '>
      <div className="col-md-12 d-flex justify-content-center align-items-center gap-5">
       <label className="fw-bold" htmlFor="exampleFormControlTextarea1" style={{ marginLeft: '20px' }}> Description </label>
       <textarea className="form-control"
        onChange={(e) => setDescription(e.target.value)}
        id="exampleFormControlTextarea1" rows="3"></textarea>
      </div>
     </FormGroup>

     <div className="col-md-12 d-flex justify-content-center align-items-center gap-5 mt-3">
      <input onClick={categorySubmit} type="Submit" style={{ backgroundColor: '#00A65A', border: 'none', padding: '4px 30px', borderRadius: '5px', color: 'white' }} />
      <Link to={'/dashboard'} style={{ backgroundColor: '#F39C12', textDecoration: 'none', padding: '4px 30px', borderRadius: '5px', color: 'white' }}>Close</Link>
     </div>

    </div>
   </Card>
  </Fragment>
 );
};

export default CategoriesListAdd;


