import { DateRangePicker } from 'react-date-range';
import { useState } from 'react';

const DateRangePickerComponent = ({ setStateM, stateM }) => {


  return (
    <>

      <DateRangePicker
        onChange={(item) => setStateM([item.selection])}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={2}
        ranges={stateM}
        //   showMonthArrow={false}
        direction='horizontal'
      />
    </>
  );
};

export default DateRangePickerComponent;
