import React from "react";
import useNumberFormatter from "../../../hooks/useNumberFormatter";

const ViewSalesReturnTable = ({ product, sl }) => {
  const numericFormatter = useNumberFormatter();

  const {
    id,
    item_name,
    purchase_price,
    return_qty,
    return_status,
    unit_total_cost,
  } = product || {};
  return (
    <>
      <tr>
        <td>{sl + 1}</td>
        <td>{item_name || "-"}</td>
        <td>{return_status || "-"}</td>
        <td>{purchase_price || "-"}</td>
        <td>{numericFormatter(return_qty) || 0}</td>
        <td>{numericFormatter(unit_total_cost) || 0}</td>
      </tr>
    </>
  );
};

export default ViewSalesReturnTable;
