import React from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import { useParams } from "react-router-dom";
import useGetData from "../../../hooks/useGetData";
import ViewPurchaseInvoice from "./ViewPurchaseInvoice";
import ViewPurchaseInvoiceInformationTableSection from "./ViewPurchaseInvoiceInformationTableSection";

const ViewPurchase = () => {
  const { id } = useParams();
  const [purchaseProduct, purchaseProductRefetch, isPurchaseProductLoading] =
    useGetData(`/purchase/purchase-view/${id}`, "purchaseProduct");
    console.log({purchaseProduct});
  return (
    <>
      <Breadcrumb title="View Purchase" parent="Purchase Management" />
      <main className=" w-100 p-3 bg-white rounded-2">
        <ViewPurchaseInvoice data={purchaseProduct}></ViewPurchaseInvoice>
        <ViewPurchaseInvoiceInformationTableSection
          id={id}
          data={purchaseProduct}
          isLoading={isPurchaseProductLoading}
        ></ViewPurchaseInvoiceInformationTableSection>
      </main>
    </>
  );
};

export default ViewPurchase;
