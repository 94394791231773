
import React from 'react';
import { Button, Col, Form, FormGroup, Label, Modal } from 'reactstrap';


const ItemGroupModal = ({ handleItemClick, itemModal, setItemModal, handleItemSubmit }) => {

    
   

    return (
        <div>

            <Modal isOpen={setItemModal} fade={false} handleItemClick={handleItemClick}>
                <div className='text-center border p-4 fw-bold'>Item Add </div>

                <div className="row p-2">
                    <div className="col-md-12">
                        <Form  onSubmit={handleItemSubmit}>

                        <FormGroup>
                                <Label for="exampleName">
                                    Item Name
                                </Label>
                                <input required className="form-control"  name='item_name' />
                                
                            </FormGroup>

                            <FormGroup row>
                                <Label
                                    for="exampleText"
                                >
                                    Description
                                </Label>
                                <Col>

                                    <textarea
                                        name='description'
                                        type="textarea"
                                        className="form-control"
                                    />
                                </Col>
                               
                            </FormGroup>

                            <Button className='w-100' color="primary" type='submit' >
                                Save
                            </Button>
                           
                        </Form>
                    </div>
                </div>

                <div className=' p-2'>
                    <Button className='w-100' onClick={handleItemClick}>
                        Close
                    </Button>
                </div>

            </Modal>
        </div>
    );
};

export default ItemGroupModal;