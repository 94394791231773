import React from "react";
import useNumberFormatter from "../../../hooks/useNumberFormatter";

const SalesReturnPaymentsTable = ({ product, sl }) => {
  const numericFormatter = useNumberFormatter();
  const {
    payment_code,
    payment_date,
    customer_name,
    payment,
    payment_type,
    sales_code,
    return_code,
    payment_status,
  } = product || {};
  return (
    <>
      <tr>
        <td>{sl + 1}</td>
        <td>{payment_date?.slice(0, 10) || "-"}</td>
        <td>{sales_code || "-"}</td>
        <td>{return_code || "-"}</td>
        <td>{payment_code || "-"}</td>
        <td>{customer_name || "-"}</td>
        <td>{numericFormatter(payment) || 0}</td>
        <td>{payment_type || "-"}</td>
        <td>{payment_status || "-"}</td>
      </tr>
    </>
  );
};

export default SalesReturnPaymentsTable;
