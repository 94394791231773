import React, { Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';

import Datatable from '../common/datatable';
import { Button, Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import AccountListTable from './AccountListTable';
import { Link } from 'react-router-dom';

const ListAccounts = () => {
  return (
    <Fragment>
      <Breadcrumb
        title='List Accounts'
        parent='Accounts'
      />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Card>
              <CardHeader className='d-flex justify-content-between'>

                <h5>All List Accounts</h5>
                <Link to='/accounts/add' className='bg-info py-2 text-decoration-none me-4 px-4 rounded-1'>+ add new account </Link>
              </CardHeader>
              <CardBody>
                <div className='category-table order-table coupon-list-delete'>
                  <AccountListTable />


                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ListAccounts;
