import React from 'react';
import { Card, CardBody, } from 'reactstrap';
import { HiInformationCircle } from "react-icons/hi";
import { useState } from 'react';
import useGetData from '../../../hooks/useGetData';
import TableLoading from '../../common/loading/TableLoading';
const AccountBalanceTable = () => {
    const [isTooltipVisible, setTooltipVisible] = useState(false);

    const [accountData, refetch, isLoading ] = useGetData(
        '/accounts/viewaccounts/',
        '/viewaccounts/'
    );
    
    return (
      <Card>
        <div className='d-flex align-items-center justify-content-center gap-1 position-relative'>
          <div className='text-center  mt-2 r'>Account/s Balance </div>
          <HiInformationCircle
            style={{ cursor: 'pointer' }}
            onMouseEnter={() => setTooltipVisible(true)}
            onMouseLeave={() => setTooltipVisible(false)}
            className='mt-2 fs-5 ms-1 text-danger informationHover'
          />

          <div
            style={{
              width: '320px',
              // boxShadow: "1px 1px 8px 5px rgba(165, 165, 165, 0.548)",
            }}
            className={`text-white border overflow-hidden  text-center  position-absolute bottom-100 rounded-3  ${
              isTooltipVisible ? 'opacity-100' : 'opacity-0'
            }`}>
            <div className='bg-body rounded-bottom py-2 '>
              <p className='m-0 text-dark'>
                You are seeing every account balance at a glance.
              </p>
            </div>
          </div>
        </div>
        <CardBody>
          <div className=''>
            <table style={{ width: '100%', borderRadius: '5px' }}>
              <thead style={{ backgroundColor: '#54A0FF', color: 'white' }}>
                <tr>
                  <th
                    style={{ padding: '6px' }}
                    scope='col'>
                    No
                  </th>
                  <th
                    style={{ padding: '6px' }}
                    scope='col'>
                    Account Name
                  </th>

                  <th style={{ padding: '6px' }}>Balance</th>
                </tr>
              </thead>
              <tbody>
                {isLoading && (
                  <TableLoading
                    noTd={3}
                    noRow={5}
                  />
                )}
                {accountData
                  ?.slice(0, 5)
                  .sort((a, b) => b.id - a.id)
                  .map((account, index) => (
                    <tr
                      className='border'
                      key={account.id}>
                      <td
                        className='border'
                        style={{ borderRadius: '5px', paddingLeft: '5px' }}>
                        {index + 1}
                      </td>
                      <td
                        className='border'
                        style={{ borderRadius: '5px', paddingLeft: '5px' }}>
                        {account.account_name}
                      </td>
                      <td
                        className='border'
                        title='apogee pos'>
                        {' '}
                        <span
                          style={{
                            fontSize: '15px',
                            fontWeight: '800',
                            marginLeft: '10px',
                          }}>
                          ৳
                        </span>{' '}
                        <span>{account.balance.toFixed(2)}</span>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    );
};

export default AccountBalanceTable;