import React, { useEffect, useMemo, useState } from "react";
import { AiOutlinePlus, AiOutlineMinus, AiFillDelete } from "react-icons/ai";
import Swal from "sweetalert2";
import useNumberFormatter from "../../../hooks/useNumberFormatter";
import PosDiscountModal from "../Modal/PosDiscountModal";
import useAxiosSecure from "../../../hooks/useAxiosSecure";
import Dropdown from "react-bootstrap/Dropdown";
import useGetData from "../../../hooks/useGetData";
import { CustomToast } from "../../../hooks/useCustomToast";

const PosTable = ({
  product,
  products,
  setSelectedItemData,
  setTotalSubTotal,
  setTotalQuantity,
  setSalesItems,
  salesItems,
  wareHouseDropdownId,
}) => {
  // const [axios] = useAxiosSecure();

  const { item_name, stock, sales_price, id, available_stock
  } = product || {};

  const [salingPrice, setSalingPrice] = useState(sales_price);
  const [updatedStock, setUpdatedStock] = useState();
  const numericFormatter = useNumberFormatter();
  const [count, setCount] = useState(1);
  const [price, setPrice] = useState(0);
  const [discount, setDiscount] = useState("");
  const [increaseDiscount, setIncreaseDiscount] = useState(false);
  const [discountInputValue, setDiscountInputValue] = useState("");
  const [discountDropdownValue, setDiscountDropdownValue] =
    useState("Percentage(%)");
  const [modalOpen, setModalOpen] = useState(false);
  const priceByQuantity = parseFloat(price) * parseFloat(count);
  const subTotal = priceByQuantity - (discount ? discount : 0);
  const disCountInTaka = priceByQuantity - subTotal;
  const [totalProductSubtotal, setTotalProductSubtotal] = useState(0);

  // modal function
  const toggleModal = () => {
    if (price !== "" && count !== 0 && price >= 0 && updatedStock > 0) {
      setModalOpen(!modalOpen);
      setIncreaseDiscount(false);
    } else if (price === "" && count === 0) {
      CustomToast(
        "error",
        "Please Enter the Price & Count Must be greater than 0 "
      );
    } else if (price < 0) {
      CustomToast("error", "Price Must be greater than 0");
    } else if (price === "") {
      CustomToast("error", "Price is empty!! Please Enter the Price ");
    } else if (count === 0) {
      CustomToast("error", `Count Must be greater than 0`);
    } else if (updatedStock <= 0) {
      CustomToast("error", `Stock is empty!! Please Select another lot`);
    }
  };

  // stock update
  useEffect(() => {

    setUpdatedStock(parseFloat(available_stock) - parseFloat(count ? count : 0));
  }, [stock, count, available_stock]);

  // sales update
  useEffect(() => {
    setPrice(salingPrice ? salingPrice : 0);
  }, [salingPrice]);

  // quantity increment decrement
  const handleIncrement = () => {
    console.log(count);
    
      setCount(parseFloat(count) + 1);
      setUpdatedStock(parseFloat(updatedStock) - 1);
   
  };

  const handleDecrement = () => {   
      setCount(parseFloat(count) - 1);
      setUpdatedStock(parseFloat(updatedStock) + 1);
   
  };

  // handle discount calculation
  const handleSubmitDiscount = (e) => {
    e.preventDefault();
    handleDiscount();
    setModalOpen(!modalOpen);
    setIncreaseDiscount(true);
  };

  useEffect(() => {
    if (increaseDiscount) {
      handleDiscount();
    }
  }, [
    discountInputValue,
    discountDropdownValue,
    priceByQuantity,
    increaseDiscount,
  ]);

  // handle discount calculation
  const handleDiscount = () => {
    let discount;
    if (discountInputValue !== "") {
      if (discountDropdownValue === "Percentage(%)") {
        discount =
          (parseFloat(priceByQuantity) * parseFloat(discountInputValue)) / 100;
      } else if (discountDropdownValue === "Fixed(৳)") {
        const fixedValue =
          parseFloat(priceByQuantity) - parseFloat(discountInputValue);
        discount = priceByQuantity - fixedValue;
      }
    }
    setDiscount(discount);
  };

  // Remove the current product from the products array
  const handleDeleteProduct = () => {
    const updatedProducts = products.filter((p) => p.id !== product.id);
    setSelectedItemData(updatedProducts);

    // Calculate new total and subtotal
    const newTotalSubTotal = updatedProducts.reduce(
      (acc, product) =>
        acc + parseFloat(product.subtotal ? product.subtotal : 0),
      0
    );
    const totalQuantity = updatedProducts.reduce(
      (acc, product) =>
        acc + parseFloat(product.itemQuantity ? product.itemQuantity : 0),
      0
    );
    // removed from item list
    const updatedSalesItems = salesItems.filter(
      (item) => item.item_id !== productsData.item_id
    );

    // Update state with the new values
    setSelectedItemData(updatedProducts);
    setTotalSubTotal(newTotalSubTotal);
    setTotalQuantity(totalQuantity);
    setSalesItems([...updatedSalesItems]);
  };

  // handle total and subtotal calculation
  const handleTotalSubTotal = () => {
    let productSubTotal = priceByQuantity - (discount ? discount : 0);

    const updatedProducts = products.map((p) => {
      if (p.id === id) {
        return { ...p, subtotal: productSubTotal, itemQuantity: count };
      }
      return p;
    });

    const newTotalSubTotal = updatedProducts.reduce(
      (acc, product) =>
        acc + parseFloat(product.subtotal ? product.subtotal : 0),
      0
    );
    const totalQuantity = updatedProducts.reduce(
      (acc, product) =>
        acc + parseFloat(product.itemQuantity ? product.itemQuantity : 0),
      0
    );

    setTotalQuantity(totalQuantity);
    setSelectedItemData(updatedProducts);
    setTotalSubTotal(newTotalSubTotal);
    setTotalProductSubtotal(newTotalSubTotal);
  };
  useEffect(() => {
    handleTotalSubTotal(); // This calls the handleDiscount function
  }, [
    count,
    price,
    discount,
    discountInputValue,
    discountDropdownValue,
    increaseDiscount,
  ]);

  // lot dropdown data
  const [lotDropdownData, setLotDropdownData] = useState([]);
  const [lotDropdownValue, setLotDropdownValue] = useState(null);

  const productsData = {
    item_id: id,
    sales_qty: numericFormatter(count || 0),
    price_per_unit: numericFormatter(price || 0),
    discount_type: numericFormatter(discountDropdownValue || 0),
    discount_input: numericFormatter(discountInputValue || 0),
    discount_amt: numericFormatter(disCountInTaka || 0),
    total_cost: numericFormatter(subTotal || 0),
    lot_number: lotDropdownValue,
  };
  const handleAddMemo = () => {
    const nnnn = salesItems.filter(
      (item) => item.item_id !== productsData.item_id
    );

    setSalesItems([...nnnn, productsData]);
  };

  useEffect(() => {
    handleAddMemo();
  }, [
    count,
    price,
    discountInputValue,
    discountDropdownValue,
    disCountInTaka,
    totalProductSubtotal,
  ]);

  // handle lot price update
  const handleUpdateLotPrice = () => {
    const selectedLot = lotDropdownData.find(
      (lot) => lot.lot_number === lotDropdownValue
    );
    if (selectedLot) {
      setPrice(selectedLot.sales_price);
    }
  };

  useEffect(() => {
    handleUpdateLotPrice();
  }, [lotDropdownValue, lotDropdownData]);

  // lot data get form backend
  const [lotGetDAta] = useGetData(
    `/lot/viewlots/${id}/${wareHouseDropdownId}`,
    "lotGetDAta"
  );
  useEffect(() => {
    if (lotGetDAta) {
      setLotDropdownData(lotGetDAta);
    }
  }, [lotGetDAta]);
  return (
    <>
      <tr>
        <td className="text-start ">{item_name || "-"}</td>
        {/* lot number */}
        <td className="position-relative ">
          <Dropdown className=" ">
            <Dropdown.Toggle
              style={{ backgroundColor: "#ffffff" }}
              value={lotDropdownValue}
              className="fw-normal"
            >
              {lotDropdownValue ? lotDropdownValue : "Select-Lot"}
            </Dropdown.Toggle>

            <Dropdown.Menu
              style={{
                maxHeight: "15vh",
                boxShadow: "0px 0px 6px 1px rgb(197, 194, 194)",
              }}
              className="overflow-auto rounded-2 h-auto d-flex flex-column pt-1 p-2 gap-1"
            >
            

              {lotDropdownData.length > 0 &&
                lotDropdownData?.map((item, index) => (
                  <Dropdown.Item
                    key={index}
                    className="w-100 text-center text-dark border rounded-1 bg-black-hover p-0"
                    onClick={() => {
                      setUpdatedStock(item.stock)
                      setSalingPrice(item.sales_price)
                      setLotDropdownValue(
                        item.lot_number ? item.lot_number : "No Lot"
                      );
                    }}
                  >
                    <option
                      className="color-white-hover "
                      style={{ fontSize: "12px" }}
                      value={item.lot_number ? item.lot_number : "No Lot"}
                    >
                      {item.lot_number ? item.lot_number : "No Lot"}
                    </option>
                  </Dropdown.Item>
                ))}
            </Dropdown.Menu>
          </Dropdown>
        </td>
        {/* stock */}
        <td>{numericFormatter(stock)}</td>
         
        {/* <td>{numericFormatter(updatedStock || 0)}</td> */}
        {/* price */}
        <td style={{ width: "50px" }}>
          <span className="">
            <input
              type="number"
              step={"any"}
              placeholder="Enter Amount "
              value={price >= 0 ? price : 0}
              onChange={(e) => {
                const inputValue = parseFloat(e.target.value);
                if (!isNaN(inputValue) && inputValue >= 0) {
                  setPrice(inputValue);
                } else {
                  setPrice(0);
                }
              }}
              className="border rounded-1 p-2 bg-body-secondary text-input text-center"
            />
          </span>
        </td>
        {/* quantity */}
        <td>
          <div
            style={{ width: "150px" }}
            className="border mx-auto rounded-1 d-flex justify-content-between align-items-center"
          >
            <button
            disabled={count === 0}
              className="d-flex justify-content-center align-items-center border-end border-0 col-2   p-2"
              onClick={handleDecrement}
            >
              <span>
                <AiOutlineMinus className="text-danger fw-bold" />
              </span>
            </button>
            <span className=" text-center px-2 border-0 d-inline-block col-8">
              <input
                type="number"
                step={"any"}
                placeholder="Quantity"
                value={count >= 0 && count}
                onChange={(e) => {
                  const inputValue = parseFloat(e.target.value);
                  if (!isNaN(inputValue) && inputValue >= 0) {
                    setCount(inputValue);
                  } else {
                    setCount(0);
                  }
                }}
                className="border-0  text-center  text-center w-100"
              />
            </span>
            <button
            disabled={count ===Number(numericFormatter(stock || 0))}

              className="d-flex justify-content-center align-items-center border-start border-0 p-2 col-2"
              onClick={handleIncrement}
            >
              <span>
                <AiOutlinePlus className="text-success fw-bold" />
              </span>
            </button>
          </div>
        </td>
        {/* discount */}
        <td className="px-5">
          <span
            style={{ width: "40px", cursor: "pointer" }}
            className="border rounded-1 bg-body-secondary w-100 py-2 d-block"
            onClick={toggleModal}
          >
            {disCountInTaka
              ? numericFormatter(disCountInTaka)
              : "Enter Discount"}
          </span>
        </td>
        {/* subtotal */}
        <td>
          <span
            style={{ width: "40px" }}
            className="border rounded-1 bg-body-secondary w-100 py-2 d-block"
          >
            {subTotal ? numericFormatter(subTotal) : 0}
          </span>
        </td>
        <td>
          <span className="d-flex justify-content-center align-items-center  py-2">
            <AiFillDelete
              onClick={handleDeleteProduct}
              style={{ cursor: "pointer" }}
              className="text-danger fs-5"
            ></AiFillDelete>
          </span>
        </td>
      </tr>

      {/* modal open  */}
      <PosDiscountModal
        isOpen={modalOpen}
        toggle={toggleModal}
        setDiscountInputValue={setDiscountInputValue}
        discountDropdownValue={discountDropdownValue}
        setDiscountDropdownValue={setDiscountDropdownValue}
        handleSubmitDiscount={handleSubmitDiscount}
        setIncreaseDiscount={setIncreaseDiscount}
      ></PosDiscountModal>
    </>
  );
};

export default PosTable;
