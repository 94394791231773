


import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import axios from "../../../../util/axiosInstance";

const TransferDepositetDropdownSectionForDebit = ({
    borderEnd,
    inputType,
    setInputValue,
    InitialDropdownValue,
    setQueryData,
    queryData,
    titleData,
    placeHolderData,

    apiLink
}) => {

    const [dropdownData, setDropdownData] = useState([]);

    const [dropdownValue, setDropdownValue] = useState(InitialDropdownValue);




    const [dropdownValueCustomers, setDropdownValueCustomers] =
        useState(InitialDropdownValue);
    const [dropdownValueThree, setDropdownValueThree] =
        useState(InitialDropdownValue);
    const [searchValue, setSearchValue] = useState("");
    const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Track dropdown state
    const token = localStorage.getItem('access-token');


    useEffect(() => {
        // handleFilter();
    }, [searchValue]);

    const handleFilter = (id, name) => {
        setQueryData({ ...queryData, account_name: id })
        setDropdownValue(name)
    }


    useEffect(() => {
        axios.get(apiLink, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        }).then(
            (response) => {

                setDropdownData(response.data)

            }
        ).finally(err => {
        })

    }, [apiLink, token])
    return (
        <>
            <Dropdown
                className={`w-100 my-2  ${borderEnd &&
                    ((borderEnd === "category" && "border-start") ||
                        (borderEnd === "customerCall" && "border-end border-start"))
                    } `}
                show={isDropdownOpen} // Show/hide the dropdown based on state
                onToggle={(isOpen) => setIsDropdownOpen(isOpen)} // Update dropdown state whenever it's toggled
            >
                <Dropdown.Toggle
                    title={titleData && titleData}
                    id="dropdown-basic"
                    className="border-0 w-100 text-start fw-normal"
                    //   style={{ backgroundColor: "#ffffff" }}
                    value={dropdownValue}
                >
                    {dropdownValue}
                </Dropdown.Toggle>

                <Dropdown.Menu
                    style={{
                        maxHeight: "25vh",
                        boxShadow: "0px 0px 30px 10px rgb(197, 194, 194)",
                        width: "100%",
                    }}
                    className=" overflow-auto rounded-2 h-auto  d-flex mt-1  flex-column  pt-1 p-2 gap-1 "
                >


                    {(dropdownData)
                        ?.map((item, index) => <Dropdown.Item
                            key={index}
                            className=" w-100 text-center text-dark border rounded-1 bg-black-hover p-0"
                            onClick={() => {
                                handleFilter(item.id, item.account_name)
                            }}
                        >
                            <option
                                className="color-white-hover "
                                style={{ fontSize: "15px" }}
                                value={item.id}
                            >
                                {item.account_name}
                            </option>
                        </Dropdown.Item>
                        )}
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
};

export default TransferDepositetDropdownSectionForDebit;