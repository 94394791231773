


import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";


import axios from "../../../util/axiosInstance";
import useAxiosSecure from "../../../hooks/useAxiosSecure";



const StockReportDropdownSection = ({
  borderEnd,
  inputType,
  setInputValue,
  InitialDropdownValue,
  queryData, setQueryData,

  titleData,
  placeHolderData,



  apiLink
}) => {

  const [axiosInstance] = useAxiosSecure()
  const [dropdownData, setDropdownData] = useState([]);
  const [dropdownValue, setDropdownValue] = useState(InitialDropdownValue);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Track dropdown state



  const handleFilter = (name) => {
    setDropdownValue(name)
    setQueryData({ ...queryData, warehouse_name: name })

  }


  useEffect(() => {
    axiosInstance.get(apiLink).then(
      (response) => {
        setDropdownData(response.data)
      }
    )
  }, [apiLink])
  return (
    <>
      <Dropdown
        className={`w-100 my-2  ${borderEnd &&
          ((borderEnd === "warehouse" && "border-start") ||
            (borderEnd === "customerCall" && "border-end border-start"))
          } `}
        show={isDropdownOpen} // Show/hide the dropdown based on state
        onToggle={(isOpen) => setIsDropdownOpen(isOpen)} // Update dropdown state whenever it's toggled
      >
        <Dropdown.Toggle
          title={titleData && titleData}
          id="dropdown-basic"
          className="border-0 w-100 text-start fw-normal"
          //   style={{ backgroundColor: "#ffffff" }}
          value={dropdownValue}
        >
          {dropdownValue}
        </Dropdown.Toggle>

        <Dropdown.Menu
          style={{
            maxHeight: "25vh",
            boxShadow: "0px 0px 30px 10px rgb(197, 194, 194)",
            width: `${inputType === "number" ? "100%" : "60%"}`,
          }}
          className=" overflow-auto rounded-2 h-auto  d-flex mt-1  flex-column  pt-1 p-2 gap-1 "
        >


          {/* Todo: error */}
          {inputType === "search" && dropdownData.length === 0 && (
            <p className="text-center text-danger fw-bold">No Data Found</p>
          )}
          {inputType &&
            (

              (inputType === "search" && dropdownData)
            )?.map((item, index) => (
              <Dropdown.Item
                key={index}
                className=" w-100 text-center text-dark border rounded-1 bg-black-hover p-0"
                onClick={() => {
                  handleFilter(item.warehouse_name)
                }}
              >
                <option
                  className="color-white-hover "
                  style={{ fontSize: "15px" }}
                  value={
                    item.warehouse_name
                  }
                >
                  {item.warehouse_name}
                </option>
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default StockReportDropdownSection;