import React from "react";
import useGetData from "../hooks/useGetData";
import TableLoading from "./common/loading/TableLoading";

const AlertQty = () => {
  const [data, refetch, isLoading] = useGetData(
    "/item/items-with-notifications"
  );

  return (
    <table className='table table-striped text-center mb-0'>
      <thead>
        <tr>
          <th scope='col'>Item Name</th>
          <th scope='col'>Cateogry</th>
          <th scope='col'>Brand</th>
          <th scope='col'>Unit</th>
          <th scope='col'>ALERT QUANTITY</th>
          <th scope='col'>STOCK</th>
        </tr>
      </thead>
      <tbody>
        {isLoading ? (
          <TableLoading
            noRow={5}
            noTd={6}
          />
        ) : (
          <>
            {data.length > 0 &&
              data?.map((item) => (
                <tr key={item?.id}>
                  <td>{item?.item_name}</td>
                  <td className='digits'>{item?.category_name}</td>
                  <td className='digits'>{item?.brand_name}</td>
                  <td>{item?.unit_name}</td>
                  <td>{item?.alert_qty}</td>
                  <td>{item?.stock}</td>
                </tr>
              ))}
          </>
        )}
      </tbody>
    </table>
  );
};

export default AlertQty;
