import React from 'react';

const ImportServices = () => {
 return (
  <div>
   import services
  </div>
 );
};

export default ImportServices;