import React, { useState } from "react";

const Card = ({ cardData, isSelected, onSelect }) => {
  const handleCheckboxChange = () => {
    onSelect(cardData);
  };

  const [isSelectedItem, setIsSelectedItem] = useState(false);

  return (
    <div
      className={`p-3 rounded-2 ${isSelected ? "bg-black" : "card-bg-color "}`}
      style={{ width: "15.5%" }}
    >
      <h5
        className={`fw-bold  ${
          isSelected ? "text-white" : "card-text-color"
        } m-0`}
      >
        {cardData?.item_name}
      </h5>
      <div className="d-flex justify-content-between align-items-center">
        <p className={` ${isSelected ? "text-white" : "card-text-color"} m-0`}>
          {cardData?.sales_price}/{cardData?.unit_name}
        </p>
        <input
          className={`form-check-input`}
          style={{ width: "15px", height: "15px", border: "1px solid gray " }}
          type="checkbox"
          checked={isSelected}
          onChange={handleCheckboxChange}
          onClick={() => setIsSelectedItem(!isSelectedItem)}
        />
      </div>
    </div>
  );
};

export default Card;
