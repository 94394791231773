import React from "react";
import PosDropdownSection from "../../Pos/PosDropdownSection";
import { useState } from "react";
import { useEffect } from "react";
import useNumberFormatter from "../../../../hooks/useNumberFormatter";
import City from "./City";
import axios from "axios";
import { useForm } from "react-hook-form";
import { CustomToast } from "../../../../hooks/useCustomToast";
import { useNavigate } from "react-router-dom";

const MakeDelivery = ({ toggle, data }) => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  // pathao store data token
  const accessToken = localStorage.getItem("pathaoAccess_Token");
  // const refreshToken = localStorage.getItem("pathaoRefresh_Token");
  const numericFormatter = useNumberFormatter();
  const productType = [
    {
      id: 1,
      name: "Parcel",
      value: 2,
    },
    {
      id: 2,
      name: "Document",
      value: 1,
    },
  ];
  const deliveryType = [
    {
      id: 1,
      name: "Normal Delivery",
      value: 48,
    },
    {
      id: 2,
      name: "On Demand Delivery",
      value: 12,
    },
  ];
  // data fromm pathao modal
  const {
    customer_name,
    grand_total,
    address,
    customer_id,
    phone,
    sales_items,
  } = data || {};

  // get store from backend
  const [pathaoStore, setPathaoStore] = useState([]);
  // get store from pathao store
  useEffect(() => {
    fetch("https://fancymalabis.apodigi.com/pathao/getStores", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setPathaoStore(data?.data?.data);
      })
      .catch(() => {});
  }, [accessToken]);

  // const handleSubmitDetails = (e) => {
  //   e.preventDefault();
  // };
  const totalQuantity = sales_items?.reduce((acc, item) => {
    return acc + item?.sales_qty;
  }, 0);
  const [customerName, setCustomerName] = useState(customer_name);
  const [customerMobile, setCustomerMobile] = useState(phone);
  const [customerAddress, setCustomerAddress] = useState(address);
  const [cityAddressId, setCityAddressId] = useState(null);
  const [zoneAddressId, setZoneAddressId] = useState(null);
  const [areaAddressId, setAreaAddressId] = useState(null);
  const [storeId, setStoreId] = useState(null);
  const [productTypeId, setProductTypeId] = useState(null);
  const [deliveryTypeId, setDeliveryTypeId] = useState(null);
  const [specialInstructions, setSpecialInstructions] = useState("");
  const [totalWeight, setTotalWeight] = useState(0);

  const firstStoreId = pathaoStore && pathaoStore[0]?.store_id;
  const firstProductTypeId = productType && productType[0]?.value;
  const firstDeliveryTypeId = deliveryType && deliveryType[0]?.value;
  const itemDescription = sales_items
    ?.map((item) => {
      return `${item?.item_name} ${item?.sales_qty} Unit  ${numericFormatter(
        item?.item_total_cost
      )} Taka.`;
    })
    .join("");

  // set default value

  useEffect(() => {
    setStoreId(firstStoreId);
    setProductTypeId(firstProductTypeId);
    setDeliveryTypeId(firstDeliveryTypeId);
  }, [firstStoreId, firstProductTypeId, firstDeliveryTypeId, sales_items]);

  const orderInfo = {
    store_id: storeId,
    merchant_order_id: "",
    recipient_name: customerName,
    recipient_phone: customerMobile,
    recipient_address: customerAddress,
    recipient_city: cityAddressId,
    recipient_zone: zoneAddressId,
    recipient_area: areaAddressId,
    delivery_type: deliveryTypeId,
    item_type: productTypeId,
    special_instruction: specialInstructions,
    item_quantity: parseInt(totalQuantity) || 0.5,
    item_weight: parseFloat(totalWeight) || 0,
    amount_to_collect: parseFloat(grand_total) || 0,
    item_description: itemDescription,
  };
  // make delivery
  const handlePathawoDelivery = () => {
    axios
      .post("https://fancymalabis.apodigi.com/pathao/createOrder", orderInfo, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        CustomToast("success", "Delivery Created Successfully");
        navigate("/delivery-management/delivery-list");
      })
      .catch((error) => {
        console.error(error);
        CustomToast("error", "Delivery Created Failed");
      });
  };
  // submit form
  const onSubmit = () => {
    handlePathawoDelivery();
  };

  return (
    <>
      {/* <Breadcrumb title="Make Delivery" parent="Sales Management" /> */}
      <form
        className="bg-white w-100 rounded-2  px-3"
        onSubmit={handleSubmit(onSubmit)}
      >
        {/* section-1 */}
        <section className="d-flex justify-content-between align-items-center">
          {/* child-1 */}
          <div style={{ width: "32%" }}>
            <label className="text-start  fs-6 mb-1">Store</label>
            <div
              style={{ width: "100%" }}
              className=" d-flex justify-content-between align-items-center border border rounded-1 "
              title="Warehouse"
            >
              {/* dropdown data */}
              {pathaoStore && (
                <PosDropdownSection
                  inputType={"search"}
                  labelText={"warehouse"}
                  InitialDropdownValue={pathaoStore[0]?.store_name}
                  setStoreId={setStoreId}
                  data={pathaoStore || []}
                  titleData={"Store"}
                  placeHolderData={"Search Store Name"}
                ></PosDropdownSection>
              )}
              {!pathaoStore && (
                <PosDropdownSection
                  inputType={"search"}
                  labelText={"warehouse"}
                  InitialDropdownValue={"NO Store"}
                  setStoreId={null}
                  data={[]}
                  titleData={"Store"}
                  placeHolderData={"Search Store Name"}
                ></PosDropdownSection>
              )}
            </div>
          </div>
          {/* child-2 */}
          <div style={{ width: "32%" }}>
            <label className="text-start  fs-6 mb-1">Product Type</label>
            <div
              style={{ width: "100%" }}
              className="  d-flex justify-content-between align-items-center border rounded-1"
              title="Customer"
            >
              {productType && (
                <PosDropdownSection
                  labelText={"customer"}
                  inputType={"search"}
                  InitialDropdownValue={productType[0]?.name}
                  setProductTypeId={setProductTypeId}
                  data={productType}
                  titleData={"Product Type"}
                  placeHolderData={"Search Product Type"}
                ></PosDropdownSection>
              )}
            </div>
          </div>
          {/* child-3 */}
          <div style={{ width: "32%" }}>
            <label for="Merchant_Order_ID" className="text-start  fs-6 mb-1">
              Merchant Order ID
            </label>
            <div
              style={{ width: "100%" }}
              className="  d-flex justify-content-between align-items-center border-0 rounded-1"
              title="Customer"
            >
              <input
                id="Merchant_Order_ID"
                type="text"
                className="cs-text-input text-input border"
                placeholder="Enter Merchant Order ID"
              />
            </div>
          </div>
        </section>
        {/* section-2 */}
        <section className="d-flex justify-content-between align-items-start w-100 mt-4">
          {/* section child-1 */}
          <section className="" style={{ width: "48%" }}>
            <h5 className="fs-5 fw-bold">Recipient Details</h5>
            {/* child-1 Recipient’s Phone */}
            <div className="w-100 my-3">
              <label for="Recipient_Phone" className="text-start  fs-6 mb-1">
                Recipient’s Phone
              </label>
              <div
                style={{ width: "100%" }}
                className="  d-flex justify-content-between align-items-center border-0 rounded-1"
                title="Customer"
              >
                <input
                  id="Recipient_Phone"
                  type="text"
                  className="cs-text-input text-input border "
                  placeholder="Enter Phone Number"
                  {...register("phone", { required: true })}
                  onChange={(e) => {
                    setCustomerMobile(e.target.value);
                  }}
                  value={
                    customerMobile.length === 11
                      ? customerMobile
                      : CustomToast("error", "Invalid Phone Number")
                  }
                />
              </div>
            </div>
            {/* child-2 Recipient’s Name */}
            <div className="w-100 my-3">
              <label for="Recipient_Name" className="text-start  fs-6 mb-1">
                Recipient’s Name
              </label>
              <div
                style={{ width: "100%" }}
                className="  d-flex justify-content-between align-items-center border-0 rounded-1"
                title="Customer"
              >
                <input
                  id="Recipient_Name"
                  type="text"
                  className="cs-text-input text-input border"
                  placeholder="Type Recipient’s Name"
                  onChange={(e) => {
                    setCustomerName(e.target.value);
                  }}
                  value={customerName}
                />
              </div>
            </div>
            {/* child-3 */}
            <div className="w-100 my-3">
              <label for="Recipient_Address" className="text-start  fs-6 mb-1">
                Recipient’s Address
              </label>
              <div
                style={{ width: "100%" }}
                className="  d-flex justify-content-between align-items-center border-0 rounded-1"
                title="Customer"
              >
                <textarea
                  className="cs-text-input text-input border"
                  name=""
                  id="Recipient_Address"
                  cols="30"
                  rows="3"
                  placeholder="Type Recipient’s Address"
                  value={customerAddress}
                  onChange={(e) => {
                    setCustomerAddress(e.target.value);
                  }}
                ></textarea>
              </div>
            </div>
            {/* child-4 */}
            <div className="w-100 my-3">
              <label for=" Delivery_Area" className="text-start  fs-6 mb-1">
                Delivery Area
              </label>
              <div
                style={{
                  width: "100%",
                  borderRadius: ".30rem",
                  paddingLeft: ".75rem",
                  paddingRight: ".75rem",
                  paddingTop: ".375rem",
                  paddingBottom: ".375rem",
                }}
                className=" border position-relative"
                title="Delivery Area"
              >
                <City
                  setCityAddressId={setCityAddressId}
                  setZoneAddressId={setZoneAddressId}
                  setAreaAddressId={setAreaAddressId}
                ></City>
              </div>
            </div>
          </section>
          {/* section child-2 */}
          <section className=" " style={{ width: "48%" }}>
            <h5 className="fs-5 fw-bold">Delivery Details</h5>
            {/* child-1 */}
            <div className="w-100 my-3">
              <label className="text-start  fs-6 mb-1">Delivery Type</label>
              <div
                style={{ width: "100%" }}
                className="  d-flex justify-content-between align-items-center border rounded-1"
                title="Customer"
              >
                {deliveryType && (
                  <PosDropdownSection
                    labelText={"customer"}
                    inputType={"search"}
                    InitialDropdownValue={deliveryType[0]?.name}
                    data={deliveryType}
                    setDeliveryTypeId={setDeliveryTypeId}
                    titleData={"Delivery Type"}
                    placeHolderData={"Search Delivery Type"}
                  ></PosDropdownSection>
                )}
              </div>
            </div>
            {/* child-2 */}
            <div className="w-100 my-3 d-flex justify-content-between align-items-start">
              {/* div-child-1 */}
              <div className="" style={{ width: "48%" }}>
                <label for="Total_Weight" className="text-start  fs-6 mb-1">
                  Total Weight
                </label>
                <div
                  style={{ width: "100%" }}
                  className="  d-flex justify-content-between align-items-center border-0 rounded-1"
                  title="Customer"
                >
                  <input
                    id="Total_Weight"
                    type="number"
                    step={"any"}
                    className="cs-text-input text-input border"
                    placeholder="Type Total Weight"
                    min="0.5"
                    max="10"
                    {...register("weight", { required: true })}
                    onChange={(e) => {
                      const value = parseFloat(e.target.value);
                      // Check if the value is within the allowed range
                      if (!isNaN(value) && value >= 0.5 && value <= 10) {
                        setTotalWeight(value);
                      } else {
                        CustomToast(
                          "error",
                          "Weight must be between 0.5 to 10"
                        );
                      }
                    }}
                  />
                  {errors.weight &&
                    CustomToast("error", "Total Weight is required")}
                </div>
              </div>
              {/* div-child-2 */}
              <div className="" style={{ width: "48%" }}>
                <label for="Quantity" className="text-start  fs-6 mb-1">
                  Quantity
                </label>
                <div
                  style={{ width: "100%" }}
                  className="  d-flex justify-content-between align-items-center border-0 rounded-1"
                  title="Customer"
                >
                  <input
                    id="Quantity"
                    type="text"
                    className="cs-text-input text-input border"
                    placeholder="Enter Quantity"
                    value={totalQuantity}
                  />
                </div>
              </div>
            </div>
            {/* child-3 */}
            <div className="w-100 my-3">
              <label for=" Amount_to_Collect" className="text-start  fs-6 mb-1">
                Amount to Collect
              </label>
              <div
                style={{ width: "100%" }}
                className="  d-flex justify-content-between align-items-center border-0 rounded-1"
                title="Customer"
              >
                <input
                  id="Amount_to_Collect"
                  type="number"
                  className="cs-text-input text-input border"
                  placeholder="Enter Amount to Including Delivery Charge"
                  value={numericFormatter(grand_total || 0)}
                />
              </div>
            </div>
            {/* child-4 Item Description & Price*/}
            <div className="w-100 my-3">
              <label
                for=" Item_Description_Price"
                className="text-start  fs-6 mb-1"
              >
                Item Description & Price
              </label>
              <div
                style={{ width: "100%" }}
                className="  d-flex justify-content-between align-items-center border-0 rounded-1"
                title="Item Description & Price"
              >
                <textarea
                  className="cs-text-input text-input border"
                  name=""
                  id="Item_Description_Price"
                  cols="30"
                  rows="3"
                  placeholder="Type Item Description & Price"
                  value={sales_items?.map((item) => {
                    return `${item?.item_name} ${
                      item?.sales_qty
                    } Unit  ${numericFormatter(item?.item_total_cost)} Taka.\n`;
                  })}
                ></textarea>
              </div>
            </div>
          </section>
        </section>
        {/* section-3 */}
        <div className="w-100 mb-3">
          <label for=" Special_Instructions" className="text-start  fs-6 mb-1">
            Special Instructions
          </label>
          <div
            style={{ width: "100%" }}
            className="  d-flex justify-content-between align-items-center border-0 rounded-1"
            title="Special Instructions "
          >
            <textarea
              className="cs-text-input text-input border"
              name=""
              id="Special_Instructions"
              cols="30"
              rows="3"
              placeholder="Type Special Instructions"
              onChange={(e) => {
                setSpecialInstructions(e.target.value);
              }}
            ></textarea>
          </div>
        </div>
        {/* section-4 */}
        <div className="">
          <button type="submit" className="btn btn-outline-success me-4">
            Save
          </button>
          <button
            type="reset"
            className="btn btn-outline-danger"
            onClick={toggle}
          >
            Cancel
          </button>
        </div>
      </form>
    </>
  );
};

export default MakeDelivery;
