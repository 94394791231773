import React from 'react';
import ShadowDiv from './ShadowDiv';

const TableLoadingRow = ({ noTd }) => {
  return (
    <tr>
      {/* <th scope='row'></th> */}
      {Array.from({ length: noTd }, (_, index) => (
        <td key={index}>
          <ShadowDiv />
        </td>
      ))}
    </tr>
  );
};

export default TableLoadingRow;
