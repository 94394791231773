import React, { useEffect, useState } from 'react';

import DepositeListTable from '../../../components/accounts/DepositeListTable';
import { Fragment } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Card, CardBody, CardHeader, Container } from 'reactstrap';

const MoneyDepositeScreen = () => {
    return (
        <Fragment>
            <Breadcrumb
                title='Deposit List'
                parent='Account'
            />
            <Container fluid={true}>
                <Card>
                    <CardHeader>
                        <h5>Deposit List</h5>
                    </CardHeader>
                    <CardBody>

                        <DepositeListTable />

                    </CardBody>
                </Card>
            </Container>
        </Fragment>
    );
};

export default MoneyDepositeScreen;

