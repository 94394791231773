
import { useEffect, useState } from 'react';
import { FormGroup } from 'reactstrap';
const BranchInputField = ({
  labelText,
  type,
  fieldId,
  requiredField,
  setWareHouseName,
  setWareHouseType,
  setAddress,
  setMobile,
  setEmail,
  setImage,

  // editable value pass 
  defaultWarehouseName,
  setDefaultWarehouseType,
  setDefaultAddress,
  setDefaultMobile,
  setDefaultEmail,
  InitialValue,
  data,
}) => {
  const [dropdownValue, setDropdownValue] = useState(InitialValue);


  useEffect(() => {
    setDropdownValue(InitialValue)
  }, [InitialValue,dropdownValue])


  return (
    <div className="col-md-6 col-sm-12 ">
      <FormGroup className='d-flex justify-content-center align-items-center w-100 '>
        <label htmlFor={fieldId} className='fw-bold  text-center' style={{ width: '200px' }}>
          {labelText}<span className="text-danger">*</span>
        </label>
        <input
          defaultValue={(defaultWarehouseName && defaultWarehouseName) ||
            (setDefaultWarehouseType && setDefaultWarehouseType) ||
            (setDefaultAddress && setDefaultAddress) || 
            (setDefaultMobile && setDefaultMobile) || dropdownValue || 
            (setDefaultEmail && setDefaultEmail) || dropdownValue
          }
          

          className='form-control'
          // id="warehouseName"
          onChange={(e) => {
            setDropdownValue(e.target.value) || 
            ((fieldId === 'warehouseName') && setWareHouseName(e.target.value || defaultWarehouseName)) ||
              ((fieldId === 'warehouseType') && setWareHouseType(e.target.value)) ||
              ((fieldId === 'address') && setAddress(e.target.value)) ||
              ((fieldId === 'mobile') && setMobile(e.target.value)) ||
              ((fieldId === 'email') && setEmail(e.target.value)) ||
              ((fieldId === 'image') && setImage(e.target.files[0]))
          }}
          type={type && type}
          required={requiredField && requiredField}
        />
      </FormGroup>
    </div>
  );
};

export default BranchInputField;