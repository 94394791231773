import React, { Fragment } from "react";
import SalesListTableSection from "../../SalesManagement/SalesList/SalesListTableSection";
import PosDropdownSection from "../../SalesManagement/Pos/PosDropdownSection";
import { FaBuilding, FaCalendarAlt } from "react-icons/fa";
import useGetData from "../../../hooks/useGetData";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { BiPlusMedical } from "react-icons/bi";
import InputLoadingTwo from "../../../components/Loading/InputLoadingTwo";
import { useSelector } from "react-redux";

const PurchaseListInformationTableSection = ({
  purchaseData,
  isLoadingParches,
  refetchParches,
}) => {

 

  const salesTable = [
    {
      id: 10,
      name: "SL",
    },
    {
      id: 11,
      name: "Purchase Date",
    },
    {
      id: 12,
      name: "Purchase Code",
    },
    {
      id: 13,
      name: "Purchase Status",
    },
    {
      id: 14,
      name: "Supplier Name",
    },
    {
      id: 15,
      name: "Total",
    },
    {
      id: 16,
      name: "Paid Payment",
    },
    {
      id: 17,
      name: "Payment Status",
    },
    {
      id: 18,
      name: "Action",
    },
  ];

  const [data, setData] = useState([]);

  // get data from backend
  const [branchData, branchRefetch, isLoading] = useGetData(
    "/branch/viewbranch",
    "branch"
  );
  const [wareHouseDropdownValue, setWarehouseDropdownValue] =
    useState("All-Branch");
  const [wareHouseDropdownId, setWarehouseDropdownId] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [selectedValue, setSelectedValue] = useState(10);
  const [updatedBranchData, setUpdatedBranchData] = useState([]);
  const [formDateValue, setFormDateValue] = useState("");
  const [toDateValue, setToDateValue] = useState("");

  const customBranchData = {
    id: null,
    warehouse_type: null,
    warehouse_name: "All-Branch",
    mobile: null,
    email: null,
    status: null,
    created_date: null,
  };
  useEffect(() => {
    if (branchData) {
      setUpdatedBranchData([customBranchData, ...branchData]);
    }
  }, [branchData]);
  // apply filters
  const applyFilters = () => {
    if (purchaseData) {
      let filteredArray = [...purchaseData];

      // Apply all branch filter
      if (wareHouseDropdownValue !== "All-Branch") {
        filteredArray = filteredArray.filter(
          (item) => item?.warehouse_id === wareHouseDropdownId
        );
      }
      // Apply date range filter
      if (formDateValue || toDateValue) {
        filteredArray = filteredArray.filter((item) => {
          const itemDate = new Date(item?.purchase_date);
          return (
            itemDate >= new Date(formDateValue) ||
            itemDate <= new Date(toDateValue)
          );
        });
      }

      // Apply search filter
      if (searchValue.trim() !== "") {
        filteredArray = filteredArray.filter((item) =>
          item?.supplier_name
            ?.toLowerCase()
            .includes(searchValue?.toLowerCase())
        );
      }
      setData(filteredArray);
    }
  };

  useEffect(() => {
    applyFilters();
  }, [
    wareHouseDropdownId,
    purchaseData,
    searchValue,
    formDateValue,
    toDateValue,
  ]);

  const userPermissions = useSelector(state => state.user.userAuth?.permissions )
  return (
    <>
      {!branchData && isLoading ? (
        <InputLoadingTwo numSections={2} />
      ) : (
        <>
          {/* section-1 */}
          <section className="d-flex justify-content-between align-items-center p-4 pb-0">
            <div
              style={{ width: "48%" }}
              className=" d-flex justify-content-between align-items-center border border rounded-1 "
              title="Warehouse"
            >
              <span className=" px-2">
                <FaBuilding className="text-danger"></FaBuilding>
              </span>
              {/* dropdown data */}
              {branchData && (
                <PosDropdownSection
                  borderEnd={"warehouse"}
                  inputType={"search"}
                  InitialDropdownValue={"All-Branches"}
                  setWarehouseDropdownValue={setWarehouseDropdownValue}
                  data={updatedBranchData}
                  titleData={"Warehouse"}
                  placeHolderData={"Enter Branch Name"}
                  dataField={"warehouse_name"}
                  setWarehouseDropdownId={setWarehouseDropdownId}
                ></PosDropdownSection>
              )}
            </div>

            {/* div-2 */}
            {             
                userPermissions?.some(permission => permission === 'Purchase_Add') ? 
                <div
              style={{ width: "48%" }}
              className="d-flex  justify-content-end align-items-center "
            >
              <Link
                style={{
                  textDecoration: "none",
                  backgroundColor: "#093d86",
                }}
                to={"/purchase-management/new-purchase"}
                className="border rounded-2 text-white py-2 px-3 d-flex justify-content-center gap-1 align-items-center  fw-bold c-pos-button6  "
              >
                <BiPlusMedical className=""></BiPlusMedical> New Purchase
              </Link>
            </div> : null
              }          
           
          </section>

          <section className="d-flex justify-content-between align-items-center p-4 pb-0">
            <div style={{ width: "48%" }}>
              <h5 className="text-start ">From Date</h5>
              <div
                style={{ width: "100%" }}
                className="d-flex border justify-content-between rounded-1 align-items-center"
              >
                <span className="border-end  p-2">
                  <FaCalendarAlt />
                </span>
                <div className="w-100 px-1 position-relative">
                  <input
                    type="date"
                    className="w-100 border-0"
                    name="fromDateInput"
                    onChange={(e) => {
                      setFormDateValue(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div style={{ width: "48%" }}>
              <h5 className="text-start ">To Date</h5>
              <div
                style={{ width: "100%" }}
                className="d-flex border justify-content-between rounded-1 align-items-center"
              >
                <span className="border-end  p-2">
                  <FaCalendarAlt />
                </span>
                <div className="w-100 px-1 position-relative">
                  <input
                    type="date"
                    className="w-100 border-0"
                    name="toDateInput"
                    onChange={(e) => {
                      setToDateValue(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </section>
          {/* section-2 */}
          <section className="mt-5 mb-4 px-4">
            <div className="row">
              <div className="col-md-6 d-flex align-items-center">
                <span>Show</span>
                <select
                  onChange={(e) => setSelectedValue(e.target.value)}
                  className="mx-1"
                >
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                  <option value={500}>500</option>
                  <option value={"allData"}>AllData</option>
                </select>
                <span>entries</span>
              </div>
              <div className="col-md-6 d-flex align-items-center justify-content-end">
                <div className="d-flex align-items-center gap-2 gap-2 w-50">
                  <label htmlFor="search" className="fw-bold">
                    Search:
                  </label>
                  <input
                    type="text"
                    id="search"
                    className="cs-text-input border text-input"
                    placeholder="Enter Supplier Name"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </section>
        </>
      )}

      {/* section-3 ------------------------------------*/}
      <section
        className="mx-4 p-2 border rounded-2 bg-body-secondary "
        style={{ height: "437px", overflowY: "scroll", overflowX: "hidden" }}
      >
        <SalesListTableSection
          tableHeader={salesTable}
          isLoading={isLoading}
          showTable={"newPurchaseList"}
          tableData={
            selectedValue !== "allData" ? data?.slice(0, selectedValue) : data
          }
        ></SalesListTableSection>
      </section>
    </>
  );
};

export default PurchaseListInformationTableSection;
