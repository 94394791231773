import moment from "moment/moment";
import React from "react";
import { GiWorld } from "react-icons/gi";
const SalesReturnInvoice = ({ data }) => {
  const {
    warehouse_name,
    warehouse_address,
    mobile,
    customer_name,
    phone,
    address,
    created_date,
    reference_no,
    return_code,
  } = data || {};
  return (
    <>
      <section className="d-flex justify-content-between align-items-center border-bottom py-2">
        <div className="d-flex gap-1 align-items-center fs-4">
          <GiWorld></GiWorld>
          Sales Return Invoice
        </div>
        <div>
          <span className="fw-700">Date:</span>{" "}
          {moment(created_date).format("YYYY-MM-DD hh:mm:ss A")}
        </div>
      </section>
      <section className="my-4">
        {/* branch information */}
        <h4
          style={{ color: "rgb(9, 61, 134)" }}
          className="fw-700 cs-text-decoration-underline"
        >
          FROM
        </h4>
        <div className="d-flex justify-content-between align-items-start">
          <div className="col-6">
            <p className="m-0 text-black fw-800 fs-6">{warehouse_name}</p>
            <p className="m-0 text-black fw-500 fs-6">
              {warehouse_address || "Address"}
            </p>
            <p className="m-0 text-black fw-500 fs-6"> {mobile}</p>
          </div>
          <div className="col-6">
            <p className="m-0 text-black fw-500 fs-6">Invoice: {return_code}</p>
            <p className="m-0 text-black fw-500 fs-6">
              Reference No: {reference_no}
            </p>
            <p className="m-0 text-black fw-500 fs-6">
              Created Date: {created_date?.slice(0, 10)}
            </p>
          </div>
        </div>
      </section>
      <section className="">
        {/* customer information */}
        <div className="d-flex justify-content-between align-items-start">
          <div className="col-6 ">
            <h4
              style={{ color: "rgb(9, 61, 134)" }}
              className="fw-700 cs-text-decoration-underline"
            >
              CUSTOMER ADDRESS
            </h4>
            <p className="m-0 text-black fw-800 fs-6">{customer_name}</p>
            <p className="m-0 text-black fw-500 fs-6">Address: {address}</p>
            <p className="m-0 text-black fw-500 fs-6">Phone: {phone}</p>
          </div>
        </div>
      </section>
    </>
  );
};

export default SalesReturnInvoice;
