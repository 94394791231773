import React from "react";
import { Maximize2 } from "react-feather";
import { Link } from "react-router-dom";
import UserMenu from "../header_components/user-menu";
import logo from "../../../assets/images/dashboard/logo.png";
import { FaListUl, FaHandPaper, FaUser, FaShoppingCart } from "react-icons/fa";
import { BsPeopleFill, BsCalculator } from "react-icons/bs";
import { TfiLayoutListThumbAlt } from "react-icons/tfi";
import { AiFillDashboard } from "react-icons/ai";
import { Button } from "reactstrap";

const PublicHeaderCompont = ({ setCartOpen, mySelectedCart }) => {
  const goFull = () => {
    if (
      (document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  };
  return (
    <div className="d-flex justify-content-between align-items-center col-11 mx-auto py-3  ">
      <div className=" col-7 d-flex justify-content-between align-items-center">
        <div className="col-3">
          <Link to={"/"}>
            <img className="img-fluid" src={logo} alt="" />
          </Link>
        </div>
        <div className=" col-6 d-md-flex justify-content-between d-none "></div>
      </div>
      <div className=" col-5 col-xl-4 d-flex justify-content-between align-items-center">
        <div className="fw-bolder text-dark d-none  d-md-block ">
          <Button
            onClick={() => setCartOpen((x) => !x)}
            className="fw-bolder text-white"
            style={{ textDecoration: "none" }}
          >
            <FaShoppingCart className="me-1" />
            My Cart({`${mySelectedCart?.length}`})
          </Button>
        </div>
        <div className=" d-none  d-md-block ">
          <a onClick={goFull} className="text-dark" href="#javaScript">
            <Maximize2 />
          </a>
        </div>
        <div>
          <Link
            className="fw-bolder text-dark"
            to={"/dashboard"}
            style={{ textDecoration: "none" }}
          >
            <AiFillDashboard className="me-1" />
            Dashboard
          </Link>
        </div>
        <div>
          <Link
            className="fw-bolder text-dark"
            to={"/Login"}
            style={{ textDecoration: "none" }}
          >
            <FaUser className="me-1" />
            Login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PublicHeaderCompont;
