
import { Fragment,useState } from "react";
import { Card, CardBody, Container, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, } from "reactstrap";
import Breadcrumb from "../../components/common/breadcrumb";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CustomToast } from "../../hooks/useCustomToast";
import DatePicker from "react-datepicker";
// import WarehouseDropdownSection from "../PurchaseManagement/WarehouseDropdownSection";

import useGetData from "../../hooks/useGetData";
import axios from "../../util/axiosInstance";
import { useEffect } from "react";

const ExpensesListEdit = () => {
 const {id} = useParams();
  const navigate = useNavigate();
 const [defaultData, setDefaultData] = useState({});
  const [reference, setReference] = useState('');
  const [expense, setExpense] = useState('');
  const [amount, setAmount] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState(defaultData?.payment_type);
  const [accountMethodName, setAccountMethodName] = useState(defaultData?.account_name);
  const [accountMethodId, setAccountMethodId] = useState('');
  const [paymentNote, setPaymentNote] = useState('')
  const [categoryMethodName, setCategoryMethodName] = useState(defaultData.category_name);
  const [categoryMethodId, setCategoryMethodId] = useState('');

  const [startDate, setStartDate] = useState(new Date());
  const date = startDate;
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0 indexed
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();
  const purchaseDate = `${month}/${day}/${year}`

  

  function generateRandomSPNumber() {
    const randomNumber = Math.floor(Math.random() * 10000); // Generate a random number between 0 and 9999
    const formattedNumber = `SP${randomNumber.toString().padStart(4, "0")}`; // Format with "SP" prefix and leading zeros if needed
    return formattedNumber;
  }
  const randomSPNumber = generateRandomSPNumber();
 
  const [paymentTypeData] = useGetData(
    '/paymenttype/viewpaymenttypes/',
    'paymenttype'
  );

  const [accountTypeData] = useGetData(
    '/accounts/viewaccounts/',
    'accountTypeData'
  );

  const [categoryTypeData] = useGetData(
    '/expensecategory/viewCategories',
    'categoryTypeData'
  );

  useEffect(() => {
   axios.get(`/expense/view-expense/${id}`).then(res => setDefaultData(res.data))
}, [])


  const expenseSubmit = (e) => {
    e.preventDefault();
    const data = {
      "expense_code": randomSPNumber || defaultData?.expense_code,
      "category_id": categoryMethodId || defaultData?.category_id,
      "reference_no": reference || defaultData?.reference_no,
      "expense_for": expense || defaultData?.expense_for,
      "expense_amt": amount || defaultData?.expense_amt,
      "payment_type": paymentMethod || defaultData?.payment_type,
      "account_id": accountMethodId || defaultData?.account_id,
      "note": paymentNote || defaultData?.note,
      "expense_date": purchaseDate || defaultData.created_date,
     
    }

    axios.put(`/expense/update-expense/${id}`, data)
      .then((response) => {
        if (response) {
          navigate('/expenses-list')
          CustomToast('success', 'Expenses List Updated')
        }
      }).catch(err => {
        CustomToast('error', 'Something Wrong')
      });
  
  }

  return (
    <Fragment>
      <Breadcrumb title="Expense Update " parent="Expense" />
      <Container fluid={true}>
        <Card>
          <CardBody>
            {/* <form> */}
            <div className="row">
              <div className="text-center fw-bold mb-3">Please Enter Valid Data</div>
              <div className="col-md-6 ">
                <div
                  style={{ width: "100%" }}
                  className=" d-flex align-items-center  rounded-1 "
                >
                  <span className="fw-bold px-2">
                    Expenses Date <span className="text-danger">*</span>
                  </span>
                  <DatePicker
                    showIcon
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className=" d-flex border rounded-2">
                  <span className="fw-bold col-2 d-flex justify-content-center align-items-center border-end">Reference No</span>
                  <input
                  defaultValue={defaultData?.reference_no}
                    onChange={(e) => setReference(e.target.value)}
                    name="reference"
                    type="text" className="form-control border-0" />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 mt-3">
                <div
                  style={{ width: "100%" }}
                  className=" d-flex justify-content-between align-items-center rounded-1 "
                  title="Warehouse"
                >
                  
                </div>
              </div>

              <div className="col-md-6 mt-3">
                <div className=" d-flex border rounded-2">
                  <span className="fw-bold col-2 d-flex justify-content-center align-items-center border-end">Expense for</span>
                  <input
                  defaultValue={defaultData?.expense_for}
                    onChange={(e) => setExpense(e.target.value)}
                    name="reference"
                    type="text" className="form-control border-0" />
                </div>
              </div>
            </div>




            <div className="row">
              <div className="mt-3 form-group col-md-6">
                <label for="account" className="fw-bold"> Payment Type   </label>
                <UncontrolledDropdown className=" w-100">
                  <DropdownToggle
                    className="border w-100"
                    caret
                    color="white"
                    style={{ backgroundColor: '#FFFFFF' }}
                  >
                    {paymentMethod || defaultData?.payment_type}
                  </DropdownToggle>
                  <DropdownMenu
                    className="text-center w-100">
                    {
                      paymentTypeData?.map(paymentData => (
                        <DropdownItem

                          key={paymentData.id}
                          onClick={(e) => setPaymentMethod(paymentData.payment_type)}
                        >
                          {paymentData?.payment_type}
                        </DropdownItem>
                      ))
                    }
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>

              <div className="col-md-6" style={{marginTop:'35px'}}>
                <div className=" d-flex border rounded-2">
                  <span className="fw-bold col-2 d-flex justify-content-center align-items-center border-end">Amount <span className="text-danger"> *</span></span>
                  <input
                  defaultValue={defaultData?.expense_amt}
                    required
                    step={'any'}
                    onChange={(e) => setAmount(parseFloat(e.target.value))}
                    name="amount"
                    type="number" className="form-control border-0" />
                </div>



              </div>

            </div>

            <div className="row">
              <div className=" form-group col-md-6">
                <label for="account" className="fw-bold"> Account  </label>
                <UncontrolledDropdown className=" w-100">
                  <DropdownToggle
                    className="border w-100"
                    caret
                    color="white"
                    style={{ backgroundColor: '#FFFFFF' }}
                  >
                    {accountMethodName || defaultData?.account_name}
                  </DropdownToggle>
                  <DropdownMenu
                    className="text-center w-100">
                    {
                      accountTypeData?.map(accountType => (
                        <DropdownItem
                          key={accountType.id}
                          onClick={(e) => {
                            setAccountMethodName(accountType.account_name);
                            setAccountMethodId(accountType.id);
                          }}>
                          {accountType.account_name}
                        </DropdownItem>
                      ))
                    }
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>

              <div className=" form-group col-md-6">
                <label for="account" className="fw-bold"> Category  </label>
                <UncontrolledDropdown className=" w-100">
                  <DropdownToggle
                    className="border w-100"
                    caret
                    color="white"
                    style={{ backgroundColor: '#FFFFFF' }}
                  >
                    {categoryMethodName || defaultData.category_name}
                  </DropdownToggle>
                  <DropdownMenu
                    className="text-center w-100">
                    {
                      categoryTypeData?.map(categoryType => (
                        <DropdownItem
                          key={categoryType?.id}
                          onClick={(e) => {
                            setCategoryMethodName(categoryType?.category_name);
                            setCategoryMethodId(categoryType?.id);
                          }}>
                          <span style={{textTransform:'capitalize'}}>{categoryType.category_name}</span>
                        </DropdownItem>
                      ))
                    }
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>

            <div class="form-group col-md-12">
              <label className="fw-bold" for="exampleFormControlTextarea1">Payment Note</label>
              <textarea class="form-control"
              defaultValue={defaultData?.note}
                onChange={(e) => setPaymentNote(e.target.value)}
                id="exampleFormControlTextarea1" rows="3"></textarea>
            </div>

            <div className="col-md-12 d-flex justify-content-center align-items-center gap-5 mt-3">
              <input onClick={expenseSubmit} type="Submit" style={{ backgroundColor: '#00A65A', border: 'none', padding: '4px 30px', borderRadius: '5px', color: 'white' }} />
              <Link to={'/dashboard'} style={{ backgroundColor: '#F39C12', textDecoration: 'none', padding: '4px 30px', borderRadius: '5px', color: 'white' }}>Close</Link>
            </div>


            {/* </form> */}
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
};

export default ExpensesListEdit;
