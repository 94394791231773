import React, { useEffect, useState } from "react";
import { BiSolidChevronRight } from "react-icons/bi";
import { HiXMark } from "react-icons/hi2";
import { HiOutlineSearch } from "react-icons/hi";

const City = ({ setAreaAddressId, setCityAddressId, setZoneAddressId }) => {
  // pathao store data token
  const accessToken = localStorage.getItem("pathaoAccess_Token");
  // const refreshToken = localStorage.getItem("pathaoRefresh_Token");
  const [cities, setCities] = useState([]);
  const [zones, setZones] = useState([]);
  const [areas, setAreas] = useState([]);
  // get city from pathao store
  useEffect(() => {
    fetch('https://fancymalabis.apodigi.com/pathao/getCityList/', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setCities(data?.data?.data);
      })
      .catch(() => {});
  }, [accessToken]);

  // get zone from pathao store

  const [filterZonesData, setFilterZonesData] = useState([]);
  const [filterAreasData, setFilterAreasData] = useState([]);
  const [filterCities, setFilterCities] = useState([]);
  const [citySearchValue, setCitySearchValue] = useState('');
  const [zoneSearchValue, setZoneSearchValue] = useState('');
  const [areaSearchValue, setAreaSearchValue] = useState('');
  const [isShowCitySearch, setIsShowCitySearch] = useState(true);
  const [isShowZoneSearch, setIsShowZoneSearch] = useState(false);
  const [isShowAreaSearch, setIsShowAreaSearch] = useState(false);
  const [isShowCity, setIsShowCity] = useState(false);
  const [isShowZone, setIsShowZone] = useState(false);
  const [isShowArea, setIsShowArea] = useState(false);
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedZone, setSelectedZone] = useState('');
  const [address, setAddress] = useState([]);
  const [cityId, setCityId] = useState(null);
  const [zoneId, setZoneId] = useState(null);
  // get zone from pathao store
  useEffect(() => {
    fetch(`https://fancymalabis.apodigi.com/pathao/getZoneList/${cityId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setZones(data?.data?.data);
      })
      .catch(() => {});
  }, [accessToken, cityId]);
  // get areas from pathao store
  useEffect(() => {
    fetch(`https://fancymalabis.apodigi.com/pathao/getAreaList/${zoneId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setAreas(data?.data?.data);
      })
      .catch(() => {});
  }, [accessToken, zoneId]);

  // handle filter city by search value
  const handleCitySearch = () => {
    if (cities) {
      let filterCities = [...cities];
      if (citySearchValue !== "") {
        filterCities = cities.filter((city) =>
          city?.city_name?.toLowerCase().includes(citySearchValue.toLowerCase())
        );
      }
      setFilterCities(filterCities);
    }
  };

  // handle filter zone by search value
  const handleZoneSearch = () => {
    if (zones) {
      let filterZoneSearch = [...zones];
      if (zoneSearchValue !== "") {
        filterZoneSearch = zones?.filter((zone) =>
          zone?.zone_name?.toLowerCase().includes(zoneSearchValue.toLowerCase())
        );
      }
      setFilterZonesData(filterZoneSearch);
    }
  };
  // handle filter area by search value
  const handleAreaSearch = () => {
    if (areas) {
      let filterAreasSearch = [...areas];
      if (areaSearchValue !== "") {
        filterAreasSearch = areas?.filter((area) =>
          area?.area_name?.toLowerCase().includes(areaSearchValue.toLowerCase())
        );
      }
      setFilterAreasData(filterAreasSearch);
    }
  };

  useEffect(() => {
    handleCitySearch();
    handleZoneSearch();
    handleAreaSearch();
  }, [citySearchValue, zoneSearchValue, areaSearchValue, areas, zones, cities]);

  return (
    <main className="d-flex justify-content-between align-items-center">
      <div className="w-100">
        {address.length > 0 &&
          address.map((city) => {
            return (
              <span className="text-black fw-500  m-0 p-0 ">
                {city} {city !== address[address.length - 1] ? " > " : ""}
              </span>
            );
          })}
        {/* search functionality---------------------- */}
        {/* handle city search */}
        {isShowCitySearch && (
          <input
            type="text"
            className=" border-0 w-100"
            placeholder="Search City"
            onClick={() => {
              setIsShowCity(true);
            }}
            onChange={(e) => {
              setCitySearchValue(e.target.value);
            }}
          />
        )}
        {/* handle zone search */}
        {isShowZoneSearch && (
          <input
            type="text"
            placeholder="Search Zone"
            className=" border-0 "
            onChange={(e) => {
              setZoneSearchValue(e.target.value);
            }}
          />
        )}
        {/* handle area search */}
        {isShowAreaSearch && (
          <input
            type="text"
            placeholder="Search Area"
            className=" border-0 "
            onChange={(e) => {
              setAreaSearchValue(e.target.value);
            }}
          />
        )}
        {/* map functionality------------------------------------------- */}
        {/* show city */}
        {isShowCity && (
          <div
            className="overflow-auto h-auto bg-white  rounded-2 py-1 w-100  position-absolute "
            style={{ maxHeight: "150px", top: "36px", left: "0px" }}
          >
            {filterCities.map((city) => {
              return (
                <p
                  title={city?.city_name}
                  className="text-black m-0 py-0 my-1 px-2 border-0 cs-cursor-pointer d-flex justify-content-between align-items-center border rounded-2 bg-black-hover"
                  style={{ fontSize: "14px" }}
                  onClick={() => {
                    setCityId(city?.city_id);
                    setCityAddressId(city?.city_id);
                    setIsShowCity(false);
                    setIsShowZone(true);
                    setIsShowCitySearch(false);
                    setIsShowZoneSearch(true);
                    setSelectedCity(city?.city_name);
                    setAddress([city?.city_name]);
                  }}
                >
                  {city.city_name} <BiSolidChevronRight />
                </p>
              );
            })}
          </div>
        )}
        {/* show zone */}
        {isShowZone && (
          <div
            className="overflow-auto h-auto bg-white rounded-2 py-1 w-100  position-absolute "
            style={{ maxHeight: "150px", top: "36px", left: "0px" }}
          >
            {filterZonesData.map((zone) => {
              return (
                <p
                  title={zone?.zone_name}
                  className="text-black m-0 py-0 px-2 border-0 cs-cursor-pointer d-flex justify-content-between align-items-center border rounded-2 bg-black-hover"
                  style={{ fontSize: "14px" }}
                  onClick={() => {
                    setZoneId(zone?.zone_id);
                    setZoneAddressId(zone?.zone_id);
                    setIsShowCitySearch(false);
                    setIsShowZoneSearch(false);
                    setIsShowAreaSearch(true);
                    setIsShowZone(false);
                    setIsShowArea(true);
                    setSelectedZone(zone?.zone_name);
                    setAddress([selectedCity, zone?.zone_name]);
                  }}
                >
                  {zone?.zone_name} <BiSolidChevronRight />
                </p>
              );
            })}
          </div>
        )}
        {/* show area */}
        {isShowArea && (
          <div
            className="overflow-auto h-auto bg-white rounded-2 py-1 w-100  position-absolute "
            style={{ maxHeight: "150px", top: "36px", left: "0px" }}
          >
            {filterAreasData.map((area) => {
              return (
                <p
                  title={area?.area_name}
                  className="text-black m-0 py-0 px-2 border-0 cs-cursor-pointer d-flex justify-content-between align-items-center border rounded-2 bg-black-hover"
                  style={{ fontSize: "14px" }}
                  onClick={() => {
                    setIsShowAreaSearch(false);
                    setIsShowArea(false);
                    setAddress([selectedCity, selectedZone, area?.area_name]);
                    setAreaAddressId(area?.area_id);
                  }}
                >
                  {area?.area_name} <BiSolidChevronRight />
                </p>
              );
            })}
          </div>
        )}
      </div>
      <div>
        {address.length > 0 ? (
          <>
            <HiXMark
              title="Clear Address"
              className="cs-cursor-pointer"
              onClick={() => {
                setAddress([]);
                setIsShowCitySearch(true);
                setIsShowZoneSearch(false);
                setIsShowAreaSearch(false);
                setIsShowCity(true);
                setIsShowZone(false);
                setIsShowArea(false);
                setCitySearchValue("");
                setZoneSearchValue("");
                setAreaSearchValue("");
              }}
            ></HiXMark>
          </>
        ) : (
          <>
            <HiOutlineSearch></HiOutlineSearch>
          </>
        )}
      </div>
    </main>
  );
};

export default City;
