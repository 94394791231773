import React from 'react';
import { Fragment } from 'react';
import { Card, Container, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, UncontrolledDropdown } from 'reactstrap';
import Breadcrumb from '../../../components/common/breadcrumb';
import useGetData from '../../../hooks/useGetData';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CustomToast } from '../../../hooks/useCustomToast';
import axios from '../../../util/axiosInstance';

const AddUserList = () => {
 const navigate = useNavigate();
 const [brachMethod, setBrachMethod] = useState('Not Selected');
 const [branchId, setBranchId] = useState();
 const [roleMethod, setRoleMethod] = useState('Not Selected');
 const [roleId, setRoleId] = useState();

 const [userName, setUserName] = useState();
 const [firstName, setFirstName] = useState('');
 const [lastName, setLastName] = useState('');
 const [mobile, setMobile] = useState('');
 const [email, setEmail] = useState('');
 const [password, setPassword] = useState('');
 const [confirmPassword, setConfirmPassword] = useState('');


 const userData = {
 
  "username": userName,
  "first_name": firstName,
  "last_name": lastName,
  "password": password,
  "mobile": mobile,
  "email": email,
  // "address": "123 Main Street",
  "role_name": roleMethod,
  "role_id": roleId,
  "warehouse_id": branchId
 }


 const [branchData] = useGetData(
  '/branch/viewbranch/',
  'branchData'
 );

 const [roleData] = useGetData(
  '/role/view-roles',
  'roleData'
 );




 const validations = [
  { field: userName, name: 'User Name' },
  { field: firstName, name: 'First Name' },
  { field: lastName, name: 'Last Name' },
  { field: mobile, name: 'Mobile' },
  { field: email, name: 'Email' },
  { field: roleMethod, name: 'Role Name', defaultValue:'Not Selected' },
  { field: password, name: 'Password' },
  { field: confirmPassword, name: 'Confirm Password' },
  { field: brachMethod, name: 'Branch Name', defaultValue:'Not Selected'},
 ];

 const userSubmit = (e) => {

  for (const validation of validations) {
   if (!validation.field || validation.field === validation.defaultValue) {
    CustomToast('error', `${validation.name} is required`);
    return;
   }
  }

  if (password !== confirmPassword) {
   CustomToast('error', 'Password and Confirm Password do not match');
   return;
  } else {
   // Send a POST request
   axios.post('/users/create-user', userData)
    .then(response => {
     if(response){
       navigate('/users-list');
       CustomToast('success', 'Data Add successfully');
     }
    })
    .catch(error => {
     CustomToast('error', 'Error saving data.');
    });
  }

 };


 return (
  <>
   <Fragment>
    <Breadcrumb title="Add User " parent="Users" />
    <Container fluid={true}>
     <Card className='p-2'>

      <div className="col-md-6 col-sm-12">
       <FormGroup className='d-flex justify-content-center align-items-center w-100 '>
        <label htmlFor='firstName' className='fw-bold  text-center' style={{ width: '200px' }}>
         User Name<span className="text-danger">*</span>
        </label>
        <input
         type='text'
         onChange={(e) => setUserName(e.target.value)}
         className='form-control'
        />
       </FormGroup>
      </div>
      <div className="col-md-6 col-sm-12">
       <FormGroup className='d-flex justify-content-center align-items-center w-100 '>
        <label htmlFor='firstName' className='fw-bold  text-center' style={{ width: '200px' }}>
         First Name<span className="text-danger">*</span>
        </label>
        <input
         type='text'
         onChange={(e) => setFirstName(e.target.value)}
         className='form-control'
        />
       </FormGroup>
      </div>

      <div className="col-md-6 col-sm-12 ">
       <FormGroup className='d-flex justify-content-center align-items-center w-100 '>
        <label htmlFor='firstName' className='fw-bold  text-center' style={{ width: '200px' }}>
         Last Name<span className="text-danger">*</span>
        </label>
        <input
         type='text'
         onChange={(e) => setLastName(e.target.value)}
         className='form-control'
        />
       </FormGroup>
      </div>

      <div className="col-md-6 col-sm-12 ">
       <FormGroup className='d-flex justify-content-center align-items-center w-100 '>
        <label htmlFor='firstName' className='fw-bold  text-center' style={{ width: '200px' }}>
         Mobile
        </label>
        <input
         type='number'
         step={'any'}
         onChange={(e) => setMobile(e.target.value)}
         className='form-control'
        />
       </FormGroup>
      </div>

      <div className="col-md-6 col-sm-12 ">
       <FormGroup className='d-flex justify-content-center align-items-center w-100 '>
        <label htmlFor='firstName' className='fw-bold  text-center' style={{ width: '200px' }}>
         Email<span className="text-danger">*</span>
        </label>
        <input
         type='email'
         onChange={(e) => setEmail(e.target.value)}
         className='form-control'
        />
       </FormGroup>
      </div>

      <div className="col-md-6 col-sm-12 ">
       <FormGroup className='d-flex justify-content-center align-items-center w-100 '>
        <label htmlFor='firstName' className='fw-bold  text-center' style={{ width: '200px' }}>
         Role<span className="text-danger">*</span>
        </label>
        
        <UncontrolledDropdown className=" w-100">
         <DropdownToggle
          className="border w-100"
          caret
          color="white"
          style={{ backgroundColor: '#FFFFFF' }}
         >
          {roleMethod}
         </DropdownToggle>
         <DropdownMenu
          className="text-center w-100">
          {
           roleData?.map(data => (
            <DropdownItem
             key={data.id}
             onClick={(e) => {
              setRoleMethod(data.role_name);
              setRoleId(data.id);
             }}
            >
             {data?.role_name}
            </DropdownItem>
           ))
          }
         </DropdownMenu>
        </UncontrolledDropdown>

       </FormGroup>
      </div>

      <div className="col-md-6 col-sm-12 ">
       <FormGroup className='d-flex justify-content-center align-items-center w-100 '>
        <label htmlFor='firstName' className='fw-bold  text-center' style={{ width: '200px' }}>
         Password<span className="text-danger">*</span>
        </label>
        <input
         type='password'
         onChange={(e) => setPassword(e.target.value)}
         className='form-control'
        />
       </FormGroup>
      </div>

      <div className="col-md-6 col-sm-12 ">
       <FormGroup className='d-flex justify-content-center align-items-center w-100 '>
        <label htmlFor='firstName' className='fw-bold  text-center' style={{ width: '200px' }}>
         Confirm Password<span className="text-danger">*</span>
        </label>
        <input
         type='password'
         onChange={(e) => setConfirmPassword(e.target.value)}
         className='form-control'
        />
       </FormGroup>
      </div>

      <div className="col-md-6 col-sm-12 ">
       <FormGroup className='d-flex justify-content-center align-items-center w-100 '>
        <label htmlFor='firstName' className='fw-bold  text-center' style={{ width: '200px' }}>
         Branch <span className="text-danger">*</span>
        </label>
        <UncontrolledDropdown className=" w-100">
         <DropdownToggle
          className="border w-100"
          caret
          color="white"
          style={{ backgroundColor: '#FFFFFF' }}
         >
          {brachMethod}
         </DropdownToggle>
         <DropdownMenu
          className="text-center w-100">
          {
           branchData?.map(data => (
            <DropdownItem
             key={data.id}
             onClick={(e) => {
              setBrachMethod(data.warehouse_name);
              setBranchId(data.id);
             }}
            >
             {data?.warehouse_name}
            </DropdownItem>
           ))
          }
         </DropdownMenu>
        </UncontrolledDropdown>

       </FormGroup>
      </div>

      <div className="col-md-12 d-flex justify-content-center align-items-center gap-5 mt-3">
       <input onClick={userSubmit} type="Submit" style={{ backgroundColor: '#00A65A', border: 'none', padding: '4px 30px', borderRadius: '5px', color: 'white' }} />
       <Link to={'/dashboard'} style={{ backgroundColor: '#F39C12', textDecoration: 'none', padding: '4px 30px', borderRadius: '5px', color: 'white' }}>Close</Link>
      </div>

     </Card>
    </Container>
   </Fragment>
  </>
 );
};

export default AddUserList;














