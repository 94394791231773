import React from "react";
import { Home } from "react-feather";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

const PublicBreadcrumb = ({ title, parent, shopName }) => {
  return (
    <Container fluid={true}>
      <div className="page-header">
        <Row>
          <Col lg="6 ">
            <div className="page-header-left">
              <h3 className="text-start">
                <p>Apogee POS Software | Zenith V2.0 </p>
                <p className="text-dark my-2 h4 fw-bold">{shopName}</p>

                <p className="text-dark my-2 h3 fw-bold">{title}</p>
              </h3>
            </div>
          </Col>
          <Col lg="6 ">
            <ol className="breadcrumb pull-right">
              <li className="breadcrumb-item">
                <Link to="/dashboard">
                  <Home />
                </Link>
              </li>
              <li className="breadcrumb-item">{parent}</li>
              <li className="breadcrumb-item active">{title}</li>
            </ol>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default PublicBreadcrumb;
