
import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Card, FormGroup } from 'reactstrap';
import { CustomToast } from '../../../hooks/useCustomToast';
import axios from '../../../util/axiosInstance';
import useGetData from '../../../hooks/useGetData';

const CustomerListEdit = () => {
 const { id } = useParams();
 const navigate = useNavigate();

 const [customerName, setCustomerName] = useState('');
 const [mobile, setMobile] = useState('');
 const [email, setEmail] = useState('');
 const [phone, setPhone] = useState('');
 const [address, setAddress] = useState('');

 // Watch for changes to the checkbox and address1

 const [customerDatafromData, customerDataRefetch] = useGetData(`/customer/viewcustomer/${id}`, 'customerDatafromData');


 const handleWarehouseSubmit = (e) => {
  e.preventDefault();
  const form = e.target;

  const data = {
   "customer_name": customerName || customerDatafromData?.customer_name,
   "mobile": mobile || customerDatafromData?.mobile,
   "phone": phone || customerDatafromData?.phone,
   "email": email || customerDatafromData?.email,
   "sales_due": 2000.00,
   "sales_due_return": 100.00,
   "address": address || customerDatafromData?.address,
   "status": "Active",
   "customer_code": customerDatafromData.customer_code

   // locationLink,
   // shippingAddress,
   // shippingLocation,
  }

  axios.put(`/customer/editcustomer/${id}`, data)
   .then(response => {
    if (response) {
     CustomToast('success', 'Customer Update Successfully')
     navigate('/customer-list');
    }
    form.reset();
   })
   .then(error => {
    if (error) {
     CustomToast('error', 'Something Wrong')
    }
   })
 }
 return (
  <Fragment>
   <Breadcrumb title="Add Customers " parent="Contact Management" />
   <Card className='p-2'>
    <form onSubmit={(handleWarehouseSubmit)}>


     <div className='d-flex justify-content-center align-items-center gap-3 px-3'>

      <div className="col-md-6 col-sm-12 ">
       <FormGroup className='d-flex justify-content-center align-items-center w-100 '>
        <label htmlFor='customerName' className='fw-bold  text-center' style={{ width: '200px' }}>
         Customer Name<span className="text-danger">*</span>
        </label>
        <input
         onChange={(e) => setCustomerName(e.target.value)}
         className='form-control'
         defaultValue={customerDatafromData?.customer_name}
        />
       </FormGroup>
      </div>

      <div className="col-md-6 col-sm-12 ">
       <FormGroup className='d-flex justify-content-center align-items-center w-100 '>
        <label htmlFor='mobile' className='fw-bold  text-center' style={{ width: '200px' }}>
         Phone <span className="text-danger">*</span>
        </label>
        <input
         onChange={(e) => setPhone(e.target.value)}
         className='form-control'
         defaultValue={customerDatafromData?.phone}
        />
       </FormGroup>
      </div>

     </div>

      <div className="col-md-6 col-sm-12 ">
       <FormGroup className='d-flex justify-content-center align-items-center w-100 '>
        <label htmlFor='mobile' className='fw-bold  text-center' style={{ width: '200px' }}>
         Address <span className="text-danger">*</span>
        </label>
        <input
         onChange={(e) => setAddress(e.target.value)}
         className='form-control'
         defaultValue={customerDatafromData?.address}
        />
       </FormGroup>
      </div>
      
     <div className="row">
      <div className=' d-flex align-items-center justify-content-center mt-5 gap-5'>
       <input type="submit" style={{ backgroundColor: '#00A65A', border: 'none', color: 'white', padding: '6px 28px', borderRadius: '5px' }} />
       <Link to={'/dashboard'} style={{ backgroundColor: '#F39C12', border: 'none', color: 'white', padding: '6px 28px', borderRadius: '5px', textDecoration: 'none' }}>Close</Link>
      </div>
     </div>
    </form>
   </Card>
  </Fragment>
 );
};

export default CustomerListEdit;
