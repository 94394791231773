import React, { useEffect, useRef, useState } from "react";
import { Table } from "reactstrap";
import PosTable from "./PosTable";
import { TfiMenuAlt } from "react-icons/tfi";
import { TbCurrencyTaka } from "react-icons/tb";
import { AiFillFileText } from "react-icons/ai";
import {
  FaUserAlt,
  FaBuilding,
  FaListAlt,
  FaHandPaper,
  FaCreditCard,
} from "react-icons/fa";
import { MdPersonAddAlt1 } from "react-icons/md";
import { FaBarcode, FaMoneyCheckDollar, FaSackDollar } from "react-icons/fa6";
import { HiInformationCircle } from "react-icons/hi";
import PosButton from "./PosButton";
import PosDropdownSection from "./PosDropdownSection";
import useNumberFormatter from "../../../hooks/useNumberFormatter";
import useGetData from "../../../hooks/useGetData";
import InputLoading from "../../../components/Loading/InputLoading";
import useAxiosSecure from "../../../hooks/useAxiosSecure";
import PosItemNameDropdownSection from "./PosItemNameDropdownSection";
import useClickOutside from "../../../hooks/useClickOutside";
import moment from "moment/moment";
import { useForm } from "react-hook-form";
import PosAddCustomerModal from "../Modal/PosAddCustomerModal";
import PosHoldModal from "../Modal/PosHoldModal";
import PosMultipleModal from "../Modal/PosMultipleModal";
import PosCashModal from "../Modal/PosCashModal";
import PosPayAllModal from "../Modal/PosPayAllModal";
import PosTermsModal from "../Modal/PosTermsModal";
import PosMultiplePaymentSlip from "./PosMultiplePaymentSlip";
import { useReactToPrint } from "react-to-print";
import { CustomToast } from "../../../hooks/useCustomToast";
const Pos = ({ updated, updatedData }) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const numericFormatter = useNumberFormatter();
  // random number
  function generateRandomSPNumber() {
    const randomNumber = Math.floor(Math.random() * 10000);
    const formattedNumber = `SP${randomNumber.toString().padStart(4, "0")}`;
    return formattedNumber;
  }

  // Usage example to generate and log a random SP number:
  const randomSPNumber = generateRandomSPNumber();
  const [axios] = useAxiosSecure();
  const [salesItems, setSalesItems] = useState([]);

  // get data from backend
  const [branchData, branchDataRefetch, branchDataLoading] = useGetData(
    "/branch/viewbranch",
    "branch"
  );
  const firstBranch = branchData && branchData[0]?.warehouse_name;
  const fistBranchId = branchData && branchData[0]?.id;
  const [wareHouseDropdownValue, setWarehouseDropdownValue] = useState("");
  const [wareHouseDropdownId, setWarehouseDropdownId] = useState("");
  const [searchProductInput, setSearchProductInput] = useState("");
  useEffect(() => {
    setWarehouseDropdownValue(firstBranch);
    setWarehouseDropdownId(fistBranchId);
  }, [firstBranch, fistBranchId]);

  // for customer dropdown
  const [customersData, customerRefetch, customerDataLoading] = useGetData(
    "/customer/viewcustomers2",
    "customer"
  );
  const reversedCustomersData = customersData?.slice().reverse();
  const [customerDropdownSalesDue, setCustomerDropdownSalesDue] = useState("");
  const [firstCustomer, setFirstCustomer] = useState("Select Customer");
  const firstCustomerId = reversedCustomersData && reversedCustomersData[0]?.id;

  useEffect(() => {
    customerRefetch();
  }, [customerRefetch, reversedCustomersData, firstCustomer]);

  useEffect(() => {
    setFirstCustomer(
      reversedCustomersData &&
        ` ${reversedCustomersData[0]?.customer_name} (${reversedCustomersData[0]?.phone})`
    );
  }, [firstCustomerId, reversedCustomersData, firstCustomer]);
  // selected customer id
  const [selectedCustomerId, setSelectedCustomerId] = useState("");
  // selected customer data
  const selectedCustomer = customersData?.find(
    (customer) => customer.id === selectedCustomerId
  );
  const { sales_due_limit, customer_name, sales_due, service_duration_year } =
    selectedCustomer || {};
  // calculate due limit
  const salesDue = sales_due || 0
  const customerDueLimit = parseFloat(sales_due_limit) - Number(salesDue);

  const [totalSubTotal, setTotalSubTotal] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [holdModalOpen, setHoldModalOpen] = useState(false);
  const [cashModalOpen, setCashModalOpen] = useState(false);
  const [multipleModalOpen, setMultipleModalOpen] = useState(false);
  const [payAllModalOpen, setPayAllModalOpen] = useState(false);
  const [termsModalOpen, setTermsModalOpen] = useState(false);
  const [wareHouseSearch, setWareHouseSearch] = useState("");
  const [customerSearch, setCustomerSearch] = useState("");
  const [changeReturnData, setChangeReturnData] = useState(0);
  const [changeReturnDataTwo, setChangeReturnDataTwo] = useState(0);
  const [paidValue, setPaidValue] = useState(0);
  const [checked, setChecked] = useState(false);
  const [
    totalPayingAmountMultipleSection,
    setTotalPayingAmountMultipleSection,
  ] = useState(0);
  const [totalDueAmountMultipleSection, setTotalDueAmountMultipleSection] =
    useState(0);

  const [barCodeProducts, setBarCodeProducts] = useState([]);
  const [selectedItemData, setSelectedItemData] = useState([]);
  const [discountInputValue, setDiscountInputValue] = useState("");
  const [discountToAllType, setDiscountToAllType] = useState("Percentage(%)");

  // set edit from sales list
  useEffect(() => {
    if (updated) {
      setSelectedItemData(updatedData);
      setIsSelectedItemDivOpen(false);
    }
  }, [updated, updatedData]);

  // set selected item data
  const handleItemClick = (item) => {
    // Check if the item is already in the selectedItemData array
    const isDuplicate = selectedItemData.some(
      (selectedItem) => selectedItem.id === item.id
    );

    if (!isDuplicate) {
      setSelectedItemData([...selectedItemData, item]);
      setIsSelectedItemDivOpen(false);
    } else {
      setIsSelectedItemDivOpen(false);
      CustomToast(
        "error",
        "Product is already added. Please update the quantity!"
      );
    }
  };

  // selected dropdown menu open/close
  const selectedDropdownMenu = useRef(null);
  const [isSelectedItemDivOpen, setIsSelectedItemDivOpen] = useState(false);
  useClickOutside(selectedDropdownMenu, () => setIsSelectedItemDivOpen(false));
  // barcode products get from backend
  useEffect(() => {
    if (searchProductInput.length >= 3) {
      axios
        .get(
          `/pos/search?warehouse_name=${wareHouseDropdownValue}&search=${searchProductInput}`
        )
        .then((response) => {
          setBarCodeProducts(response.data);
          if (
            response.data.some((x) => x.custom_barcode === searchProductInput)
          ) {
            reset();
            const item = response.data.find(
              (x) => x.custom_barcode === searchProductInput
            );

            return handleItemClick(item);
          }
          if (response.data.length > 0) {
            reset();
            setSearchProductInput("");
            setIsSelectedItemDivOpen(true);
          }
        });
    }
  }, [wareHouseDropdownValue, searchProductInput, axios]);

  const grandTotal =
    (parseFloat(totalSubTotal) || 0) - (parseFloat(discount) || 0);
  // payment section id
  const [accountPaymentIdDropdown, setAccountPaymentIdDropdown] = useState("");
  const [
    multipleAccountPaymentIdDropdown,
    setMultipleAccountPaymentIdDropdown,
  ] = useState("");
  const [cashDueBalanceData, setCashDueBalanceData] = useState(0);
  const [payingBalanceData, setPayingBalanceData] = useState(0);
  const [multiplePayingBalanceData, setMultiplePayingBalanceData] = useState(0);

  // multiple payment second section data
  const [multiplePayingBalanceDataTwo, setMultiplePayingBalanceDataTwo] =
    useState(0);
  const [paymentDropdownValueTwo, setPaymentDropdownValueTwo] = useState("");
  const [accountDropdownValueTwoId, setAccountDropdownValueTwo] = useState("");

  // multiple payment calculation data object
  const multiplePaymentCalculationData = {
    totalPayingAmountMultipleSection,
    changeReturnDataTwo,
    totalDueAmountMultipleSection,
    setTotalDueAmountMultipleSection,
    setTotalPayingAmountMultipleSection,
    setChangeReturnDataTwo,
  };

  //   modal functionality
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };
  const holdToggleModal = () => {
    setHoldModalOpen(!holdModalOpen);
  };
  const cashToggleModal = () => {
    if (selectedItemData.length > 0) {
      setCashModalOpen(!cashModalOpen);
    } else {
      CustomToast("error", "Please add product first");
    }
  };
  const multipleToggleModal = () => {
    if (selectedItemData.length > 0) {
      setMultipleModalOpen(!multipleModalOpen);
    } else {
      CustomToast("error", "Please add product first");
    }
  };
  const payAllToggleModal = () => {
    setPayAllModalOpen(!payAllModalOpen);
  };
  const termsAndConditionToggleModal = () => {
    setTermsModalOpen(!termsModalOpen);
  };

  // calculation change return multiple payment
  const changeReturnCalculation = () => {
    const totalPayingAmount =
      parseFloat(multiplePayingBalanceData || 0) +
      parseFloat(multiplePayingBalanceDataTwo || 0);
    if (totalPayingAmount > grandTotal) {
      const changeReturn = totalPayingAmount - parseFloat(grandTotal || 0);
      setChangeReturnDataTwo(changeReturn);
    } else {
      setChangeReturnDataTwo(0);
    }
    if (totalPayingAmount < grandTotal) {
      const totalDueAmount =
        parseFloat(grandTotal || 0) - (totalPayingAmount || 0);
      setTotalDueAmountMultipleSection(totalDueAmount || 0);
    } else {
      setTotalDueAmountMultipleSection(0);
    }
    setTotalPayingAmountMultipleSection(totalPayingAmount || 0);
  };
  useEffect(() => {
    changeReturnCalculation();
  }, [multiplePayingBalanceData, multiplePayingBalanceDataTwo, grandTotal]);
  // current date
  const currentDateTime = moment().format("YYYYMMDDHHmmss");

  // sales item data for cash payment
  const cashSalesPaymentData = {
    payment_code: randomSPNumber,
    payment: numericFormatter(payingBalanceData|| 0),
    account_id: accountPaymentIdDropdown,
    customer_id: selectedCustomerId,
    change_return: numericFormatter(changeReturnData|| 0),
    payment_type: "Cash",
    payment_note: "Paid by Cash",
    payment_date: currentDateTime,
  };

  // sales item data for multiple payment
  const [multiplePaymentType, setMultiplePaymentType] = useState("");
  // object-1 multiple payment
  const multipleSalesPaymentDataOne = {
    payment_code: randomSPNumber,
    payment: numericFormatter(multiplePayingBalanceData|| 0),
    customer_id: selectedCustomerId,
    account_id: multipleAccountPaymentIdDropdown,
    change_return: 0,
    payment_type: multiplePaymentType,
    payment_note: `Paid By ${multiplePaymentType}`,
    payment_date: currentDateTime,
  };
  // object-2 multiple payment
  const multipleSalesPaymentDataTwo = {
    payment_code: randomSPNumber,
    payment: numericFormatter(multiplePayingBalanceDataTwo|| 0),
    customer_id: selectedCustomerId,
    account_id: accountDropdownValueTwoId,
    change_return: numericFormatter(changeReturnDataTwo || 0),
    payment_type: paymentDropdownValueTwo,
    payment_note: `Paid By ${paymentDropdownValueTwo}`,
    payment_date: currentDateTime,
  };

  // for cash payment data to backend
  let cashPaymentPostData = {
    warehouse_id: wareHouseDropdownId,
    init_code: "SL",
    sales_code: currentDateTime,
    customer_id: selectedCustomerId,
    discount_to_all_input: discount ? discount : 0,
    discount_to_all_type: discountToAllType,
    grand_total: Number(numericFormatter(grandTotal|| 0)),
    payment_status:
      cashDueBalanceData <= 0
        ? "Paid"
        : payingBalanceData > 0
        ? "Partially Paid"
        : "Due",
    paid_amount: Number(numericFormatter(payingBalanceData|| 0)),
    invoice_terms: "terms ekhono  banano hoy nai",
    sales_due: Number(numericFormatter(cashDueBalanceData|| 0)),
    sales_status: "Paid",
    sales_items: salesItems,
    sales_payments: [cashSalesPaymentData],
  };

  // for multiple payment data to backend
  let multiplePaymentPostData = {
    warehouse_id: wareHouseDropdownId,
    init_code: "SL",
    sales_code: currentDateTime,
    customer_id: selectedCustomerId,
    discount_to_all_input: discount ? discount : 0,
    discount_to_all_type: discountToAllType,
    grand_total: Number(numericFormatter(grandTotal|| 0)),
    payment_status:
      totalDueAmountMultipleSection <= 0
        ? "Paid"
        : totalPayingAmountMultipleSection > 0
        ? "Partially Paid"
        : "Due",
    paid_amount: Number(numericFormatter(totalPayingAmountMultipleSection|| 0)),
    invoice_terms: "terms ekhono  banano hoy nai",
    sales_due: Number(numericFormatter(totalDueAmountMultipleSection|| 0)),
    sales_status: "delivered",
    sales_items: salesItems,
    sales_payments: [multipleSalesPaymentDataOne, multipleSalesPaymentDataTwo],
  };

  // handle reset all data
  const handleResetAllData = () => {
    setSelectedItemData([]);
    setSalesItems([]);
    setTotalQuantity(0);
    setTotalSubTotal(0);
    setDiscount(0);
    setChangeReturnData(0);
    setChangeReturnDataTwo(0);
    setTotalPayingAmountMultipleSection(0);
    setTotalDueAmountMultipleSection(0);
    setCashDueBalanceData(0);
    setPayingBalanceData(0);
    setMultiplePayingBalanceData(0);
    setMultiplePayingBalanceDataTwo(0);
    setMultipleAccountPaymentIdDropdown("");
    setMultiplePaymentType("");
    setPaymentDropdownValueTwo("");
    setAccountDropdownValueTwo("");
    setDiscountToAllType("Percentage(%)");
    setAccountPaymentIdDropdown("");
    setWareHouseSearch("");
    setCustomerSearch("");
    setSearchProductInput("");
    setBarCodeProducts([]);
    setDiscountInputValue("");
    setPaidValue(0);
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  // sales id get from back end
  const [salesId, setSalesId] = useState("");
  useEffect(() => {
    setSalesId(salesId);
  }, [salesId]);

  // handle multiple payment data get from backend
  const [salesSlipData, setSalesSlipData] = useState("");

  useEffect(() => {
    handleMultiplePaymentDataGet();
  }, [salesId]);

  const handleMultiplePaymentDataGet = () => {
    if (salesId) {
      axios
        .get(`/sales/view-sales/${salesId}`)
        .then((res) => {
          setSalesSlipData(res.data);
        })
        .catch((err) => {});
    }
  };
  // handle pos cash payment data submit
  const handleSalesPostDataSubmit = (postData, isPrint) => {
    axios
      .post("/sales/add-sales-with-items-and-payments-main", postData)
      .then((res) => {
        if (res.status === 200 || res.status === 201 || res.status === 202) {
          CustomToast("success", "Payment Successful");
          handleResetAllData();
          if (isPrint) {
            setSalesId(res.data.sales_id);
            if (salesSlipData) {
              handlePrint();
              setSalesSlipData(null);
            }
          }
        }
      })
      .catch(() => {
        CustomToast("error", "Payment Failed");
      });
  };
  useEffect(() => {
    if (salesSlipData) {
      handlePrint();
    }
  }, [salesSlipData]);
  // handle multiple payment pos data submit
  // save button
  const handleMultiplePaymentDataSubmitSave = () => {
    handleSalesPostDataSubmit(multiplePaymentPostData, false);
    setSalesSlipData(null);
  };
  // save & print button
  const handleMultiplePaymentDataSubmit = () => {
    handleSalesPostDataSubmit(multiplePaymentPostData, true);
  };
  // cash payment data submit
  // save button
  const handleCashPaymentDataSubmitSave = () => {
    handleSalesPostDataSubmit(cashPaymentPostData, false);
    setSalesSlipData(null);
  };
  // save & print button
  const handleCashPaymentDataSubmit = () => {
    handleSalesPostDataSubmit(cashPaymentPostData, true);
  };
  // hook form data
  const onSubmit = (data) => {};

  return (
    <>
      <div className="shadow rounded-1 m-4 bg-white pt-1 pb-5">
        {branchDataLoading || customerDataLoading ? (
          <InputLoading></InputLoading>
        ) : (
          <form className="px-4  pt-3 pb-2  " onSubmit={handleSubmit(onSubmit)}>
            {/* section-1 */}
            <section className="d-flex justify-content-between align-items-center">
              <div
                style={{ width: "48%" }}
                className=" d-flex justify-content-between align-items-center border border rounded-1 "
                title="Warehouse"
              >
                <span className=" px-2">
                  <FaBuilding className="text-danger"></FaBuilding>
                </span>
                {/* dropdown data */}
                {firstBranch && (
                  <PosDropdownSection
                    borderEnd={"warehouse"}
                    inputType={"search"}
                    setInputValue={setWareHouseSearch}
                    InitialDropdownValue={firstBranch || "Select Brancha"}
                    setWarehouseDropdownValue={setWarehouseDropdownValue}
                    data={branchData || []}
                    titleData={"Branch Name"}
                    placeHolderData={"Enter Branch Name"}
                    dataField={"warehouse_name"}
                    setSelectedItemData={setSelectedItemData}
                    setWarehouseDropdownId={setWarehouseDropdownId}
                  ></PosDropdownSection>
                )}
              </div>
              <div
                style={{ width: "48%" }}
                className=" d-flex justify-content-between align-items-center"
              >
                <div
                  className="col-7 border d-flex justify-content-between align-items-center rounded-1 "
                  title="Invoice Initial Code"
                >
                  <span className="border-end  p-2">
                    <TfiMenuAlt></TfiMenuAlt>
                  </span>
                  <div className="w-100 px-1">
                    <input
                      type="text"
                      className="w-100 border-0"
                      placeholder="Invoice Initial Code"
                    />
                  </div>
                </div>
                <div className="col-4">
                  <button
                    style={{
                      width: "100%",
                      textDecoration: "none",
                      backgroundColor: "#093d86",
                    }}
                    to={"/purchase-management/new-purchase"}
                    className="border rounded-2 text-white py-2 px-3 d-flex justify-content-center gap-1 align-items-center  fw-bold c-pos-button6  "
                  >
                    <AiFillFileText className=""></AiFillFileText> Print Last
                    Invoice
                  </button>
                </div>
              </div>
            </section>
            {/* section-2 */}
            <section className="d-flex justify-content-between align-items-center mt-3">
              <div
                style={{ width: "48%" }}
                className="  d-flex justify-content-between align-items-center border rounded-1"
                title="Customer"
              >
                <span className="px-2">
                  <FaUserAlt></FaUserAlt>
                </span>
                {customersData && (
                  <PosDropdownSection
                    borderEnd={"customerCall"}
                    inputType={"search"}
                    setInputValue={setCustomerSearch}
                    InitialDropdownValue={firstCustomer}
                    data={reversedCustomersData || []}
                    titleData={"Customer Name(Customer Id)"}
                    placeHolderData={"Search by Customer Name"}
                    dataField={"customer_name"}
                    setCustomerDropdownSalesDue={setCustomerDropdownSalesDue}
                    initialCustomerId={firstCustomerId}
                    setSelectedCustomerId={setSelectedCustomerId}
                  ></PosDropdownSection>
                )}
                <span className="px-2 cs-cursor-pointer" onClick={toggleModal}>
                  <MdPersonAddAlt1
                    className="fs-5"
                    style={{ color: "#0d6efd" }}
                  ></MdPersonAddAlt1>
                </span>
              </div>

              <div
                style={{ width: "48%" }}
                className="d-flex border justify-content-between rounded-1 align-items-center"
              >
                <span className="border-end  p-2">
                  <FaBarcode></FaBarcode>
                </span>
                <div className="w-100 px-1 position-relative">
                  <input
                    type="text"
                    className="w-100 border-0"
                    placeholder="Item Name/Barcode/Item Code"
                    title="Item Name/Barcode/Item Code"
                    name="barcodeInput"
                    {...register("barcodeInput")}
                    onChange={(e) => {
                      setSearchProductInput(e.target.value);
                    }}
                  />
                  {isSelectedItemDivOpen && (
                    <PosItemNameDropdownSection
                      selectedDropdownMenu={selectedDropdownMenu}
                      barCodeProducts={barCodeProducts}
                      handleItemClick={handleItemClick}
                    />
                  )}
                </div>
              </div>
            </section>
          </form>
        )}

        {/* section-row */}
        <section className="px-4 mt-1">
          {selectedCustomer && (
            <div className="d-flex justify-content-between">
              <div className="mb-2 fw-bold text-center fs-6 text-uppercase">
                <span className=" text-black">
                  {customer_name} can be due only{" "}
                </span>
                <span className="text-danger">
                  {numericFormatter(customerDueLimit || 0)}
                </span>
                {" Taka "}
                <span className="text-black">
                  {` (Job Duration : ${service_duration_year})`}
                </span>
              </div>
              {/* child-2 */}
              <div className="mb-2 fw-bold text-center fs-6 text-uppercase">
                <span className=" text-black">
                  {customer_name || "Customer Name"} Previous Due{" "}
                </span>
                <span className="text-danger">
                  {numericFormatter(salesDue || 0)}
                </span>
                {" Taka "}
              </div>
            </div>
          )}

          <div
            className=" rounded-2 p-2 overflow-auto  bg-body-secondary"
            style={{
              height: "40vh",
              // backgroundColor: "#eff1f1",
            }}
          >
            <Table
              className=" rounded-2 table align-middle "
              style={{ verticalAlign: "middle" }}
            >
              <thead>
                <tr className="text-center">
                  <th className="text-start">Item Name</th>
                  <th>Lot Number</th>
                  <th>Total Stock</th>
          {/*<th>Stock Available </th>*/}
                  <th>Price</th>
                  <th>Quantity</th>
                  <th>
                    {" "}
                    Discount(
                    <TbCurrencyTaka />)
                  </th>
                  <th>
                    {" "}
                    Subtotal(
                    <TbCurrencyTaka />)
                  </th>
                  <th>Action</th>
                </tr>
              </thead>
              {barCodeProducts?.length > 0 && (
                <tbody className="text-center align-middle">
                  {selectedItemData?.map((product, index) => (
                    <PosTable
                      key={index}
                      product={product}
                      products={selectedItemData}
                      setTotalSubTotal={setTotalSubTotal}
                      setSelectedItemData={setSelectedItemData}
                      setTotalQuantity={setTotalQuantity}
                      setSalesItems={setSalesItems}
                      salesItems={salesItems}
                      wareHouseDropdownId={wareHouseDropdownId}
                    ></PosTable>
                  ))}
                </tbody>
              )}
            </Table>
          </div>
        </section>
        {/* section-3 */}
        <section className="px-4 mt-2 d-flex justify-content-between align-items-center">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-between align-items-center position-relative ">
              <input
                type="checkbox"
                id="exampleCustomCheckbox"
                className="custom-control-lg cs-cursor-pointer"
                style={{ backgroundColor: "blue" }}
                checked={checked} 
                onChange={(e)=>setChecked(e.target.checked)}
              />
              <label
                className="custom-control-label"
                htmlFor="exampleCustomCheckbox"
              >
                <span className="ms-2 cs-cursor-pointer">
                  Send Message to Customer
                </span>
              </label>
              <HiInformationCircle
                style={{ cursor: "pointer" }}
                onMouseEnter={() => setTooltipVisible(true)}
                onMouseLeave={() => setTooltipVisible(false)}
                className="fs-5 ms-1 text-danger informationHover"
              ></HiInformationCircle>
              {/* Tooltip */}
              <div
                style={{
                  width: "320px",
                  boxShadow: "0px 0px 10px 5px rgba(165, 165, 165, 0.548)",
                }}
                className={`text-white border overflow-hidden  text-center  position-absolute bottom-100 rounded-1  ${
                  isTooltipVisible ? "opacity-100" : "opacity-0"
                }`}
              >
                <p className="bg-body-secondary text-dark border-bottom m-0 py-2 ">
                  Do you Wants to Send Message?
                </p>
                <div className="bg-body rounded-bottom py-2">
                  <p className="m-0 text-dark">
                    If checkbox is Disabled! You need to enabled it from SMS{" "}
                    {"->"} SMS Api
                  </p>
                  <p className="m-0 fw-bold fst-italic text-dark">
                    Note:Walk-in Customer will not receive SMS!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            onClick={termsAndConditionToggleModal}
            style={{ cursor: "pointer" }}
            className="d-flex justify-content-center  align-items-center"
          >
            <FaListAlt className="fs-5 text-primary"></FaListAlt>
            <span className="text-primary">Terms & Conditions</span>
          </div>
        </section>
        {/* section-4 */}
        <section className=" mt-4 px-4 d-flex flex-wrap justify-content-between align-items-center d-md-none">
          <h4 className="text-center">
            you can't sall any product or goods from mobile web virson, Please
            use desktop or mobile app
          </h4>
        </section>
        <section className=" mt-4 px-4 d-md-flex flex-wrap justify-content-between align-items-center d-none">
          {/* row-1 */}
          {/* hold button */}
          <PosButton
          totalQuantity= {totalQuantity}
            modalFunction={holdToggleModal}
            text1={"Quantity"}
            text1Value={totalQuantity}
            text2={"Hold"}
            styleHover={"c-pos-button1"}
            bgStyle={"#ff005d"}
            icon={<FaHandPaper></FaHandPaper>}
          ></PosButton>
          {/* multiple payment button */}
          <PosButton
          totalQuantity= {totalQuantity}

            modalFunction={multipleToggleModal}
            text1={"Total Amount"}
            text1Value={totalSubTotal ? numericFormatter(totalSubTotal) : 0}
            text2={"Multiple Pay"}
            styleHover={"c-pos-button2"}
            bgStyle={"#3c8dbc"}
            icon={<FaCreditCard></FaCreditCard>}
          ></PosButton>
          {/* cash payment button */}
          <PosButton
          totalQuantity= {totalQuantity}

            modalFunction={cashToggleModal}
            totalSubTotal={totalSubTotal ? totalSubTotal : 0}
            text1={"Total Discount"}
            text1Value={discount ? numericFormatter(discount) : 0}
            setDiscount={setDiscount}
            text2={"Payment"}
            styleHover={"c-pos-button3"}
            bgStyle={"#008d4c"}
            setDiscountToAllType={setDiscountToAllType}
            discountInputValue={discountInputValue}
            setDiscountInputValue={setDiscountInputValue}
            icon={<FaMoneyCheckDollar></FaMoneyCheckDollar>}
          ></PosButton>
          {/* pay all button */}
          <PosButton
          totalQuantity= {totalQuantity}

            modalFunction={payAllToggleModal}
            text1={"Grand Total"}
            text1Value={grandTotal ? numericFormatter(grandTotal) : 0}
            text2={"Pay All"}
            styleHover={"c-pos-button4"}
            bgStyle={"#605ca8"}
            icon={<FaSackDollar></FaSackDollar>}
          ></PosButton>
        </section>
      </div>
      {/* modal open  */}
      <PosAddCustomerModal
        customerRefetch={customerRefetch}
        isOpen={modalOpen}
        toggle={toggleModal}
      ></PosAddCustomerModal>
      {/* hold modal open  */}
      <PosHoldModal
        isOpen={holdModalOpen}
        toggle={holdToggleModal}
      ></PosHoldModal>
      {/* multiple modal open  */}
      {

      }
      <PosMultipleModal
      customerDueLimit = {customerDueLimit}
        checked={checked}
        setChecked={setChecked}
        setCashDueBalanceData={setCashDueBalanceData}
        setAccountPaymentIdDropdown={setAccountPaymentIdDropdown}
        isOpen={multipleModalOpen}
        toggle={multipleToggleModal}
        setCashModalOpen={setCashModalOpen}
        setMultipleModalOpen={setMultipleModalOpen}
        totalItems={totalQuantity}
        total={totalSubTotal}
        discount={discount}
        totalPayable={grandTotal}
        setMultiplePayingBalanceData={setMultiplePayingBalanceData}
        setMultipleAccountPaymentIdDropdown={
          setMultipleAccountPaymentIdDropdown
        }
        setMultiplePaymentType={setMultiplePaymentType}
        setPaymentDropdownValueTwo={setPaymentDropdownValueTwo}
        setAccountDropdownValueTwo={setAccountDropdownValueTwo}
        setMultiplePayingBalanceDataTwo={setMultiplePayingBalanceDataTwo}
        multiplePaymentCalculationData={multiplePaymentCalculationData}
        handleMultiplePaymentDataSubmitSave={
          handleMultiplePaymentDataSubmitSave
        }
        handleMultiplePaymentDataSubmit={handleMultiplePaymentDataSubmit}
        handleResetAllData={handleResetAllData}
        cashData={cashPaymentPostData}
        multipleData={multiplePaymentPostData}
      ></PosMultipleModal>
      {/* cash modal open  */}
      <PosCashModal
      customerDueLimit = {customerDueLimit}

        checked={checked}
        setChecked={setChecked}
        isOpen={cashModalOpen}
        toggle={cashToggleModal}
        totalItems={totalQuantity}
        total={totalSubTotal}
        discount={discount}
        totalPayable={grandTotal}
        setCashDueBalanceData={setCashDueBalanceData}
        setPayingBalanceData={setPayingBalanceData}
        setChangeReturnData={setChangeReturnData}
        accountPaymentIdDropdown={accountPaymentIdDropdown}
        setAccountPaymentIdDropdown={setAccountPaymentIdDropdown}
        handleCashPaymentDataSubmit={handleCashPaymentDataSubmit}
        handleCashPaymentDataSubmitSave={handleCashPaymentDataSubmitSave}
        handleResetAllData={handleResetAllData}
        cashData={cashPaymentPostData}
        multipleData={multiplePaymentPostData}
        paidValue={paidValue}
        setPaidValue={setPaidValue}
      ></PosCashModal>
      {/* pay All modal open  */}
      <PosPayAllModal
        isOpen={payAllModalOpen}
        toggle={payAllToggleModal}
      ></PosPayAllModal>
      {/* terms modal open  */}
      <PosTermsModal
        isOpen={termsModalOpen}
        toggle={termsAndConditionToggleModal}
      ></PosTermsModal>

      {/* handlePrint */}
      <div className="d-none">
        <PosMultiplePaymentSlip
          ref={componentRef}
          salesSlipData={salesSlipData}
        />
      </div>
    </>
  );
};

export default Pos;
