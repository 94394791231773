import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

import MakeDelivery from "./MakeDelivery";

const PathaoDeliveryModal = ({ isOpen, toggle, data }) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      style={{ maxWidth: "70%" }}
    >
      <ModalHeader
        className="d-flex justify-content-center  align-items-center"
        style={{
          backgroundImage: "linear-gradient(to right, #4b0ab3, #090080)", // Gradient background
        }}
      >
        <p className="text-white w-100 m-0 fw-bold fs-5">Make Delivery</p>
      </ModalHeader>
      <ModalBody>
        <MakeDelivery toggle={toggle} data={data}></MakeDelivery>
      </ModalBody>
    </Modal>
  );
};

export default PathaoDeliveryModal;
