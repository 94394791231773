


import React from "react";
import useNumberFormatter from "../../../hooks/useNumberFormatter";
// import SaleListTable from "../../Screen/SalesManagement/SalesList/SaleListTable";
// import ViewListTable from "../../Screen/SalesManagement/SalesList/ViewListTable";
// import ViewSalesPaymentTable from "../../Screen/SalesManagement/SalesList/ViewSalesPaymentTable";

const AdjustmentTableComponents = ({ product, sl, }) => {
  const numericFormatter = useNumberFormatter();

  const {
    id,
    price_per_unit,
    item_unit_total_cost,
    item_discount_type,
    adjustment_qty,
    item_discount_amt,
    item_name,
    item_total_cost,
    item_discount_input,
  } = product || {};
  return (
    <>
      <tr>
        <td>{sl + 1}</td>
        <td>{item_name}</td>
        <td>{numericFormatter(adjustment_qty) || 0}</td>
      
      </tr>
    </>
  );
};

export default AdjustmentTableComponents;