import React, { useEffect, useState } from 'react'
import SalesPaymentTable from './components/SalesPaymentTable'
import Breadcrumb from '../../../components/common/breadcrumb'
import DateRangePickerComponent from './components/DateRangePicker'
import { addDays } from 'date-fns';

import { Button } from 'reactstrap'
import moment from 'moment'
import BranchNameSelectDropdown from '../SupplierItems/components/BranchNameSelectDropdown'
import CategoryNameSelectDropdown from './components/CategoryNameSelectDropdown';

const ExpenseReportScreen = () => {
    const [queryData, setQueryData] = useState({
        user_name: '',
        warehouse_name: '',
        brand_name: '',
        category_name: '',
        lot_number: ""
    })
    const [stateM, setStateM] = useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 0),
            key: 'selection',
        },
    ]);

    const startDate = moment(stateM[0].startDate).format().substring(0, 10)
    const endDate = moment(stateM[0].endDate).format().substring(0, 10)

    // useEffect(() => {
    //     '/report/expenses-report';
    //     setApiLink('/report/expenses-report')
    // }, [queryData.user_name, endDate, startDate])

    return (
        <>
            <Breadcrumb title="expense report" parent="reports" />
            <div className='col-12 '>
                <div className='d-flex'>

                    <CategoryNameSelectDropdown
                        apiLink="/expense/view-expenses/" queryData={queryData}
                        setQueryData={setQueryData}
                        InitialDropdownValue='select category'


                    />
                    {/* <DateRangePickerComponent
                        setStateM={setStateM}
                        stateM={stateM}

                        queryData={queryData}
                        setQueryData={setQueryData}
                    /> */}
                </div>

            </div>

            <div className=''>
                {/* <PayamentList /> */}
                <SalesPaymentTable queryLink={'/report/expenses-report'} categoryDropdownValue={queryData.category_name} />


            </div>
        </>
    )
}

export default ExpenseReportScreen