import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  allCategoryList: [],
    singleCategory: {},
};

const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    getAllCategoryList: (state, action) => {
      state.allCategoryList = action.payload;
    },

    getACategory: (state, action) => {
      state.singleCategory = action.payload;
    },
    deleteCategory: (state, action) => {
        state.allCategoryList =  state.allCategoryList.filter(item => item.id !== action.payload)
    },
  },
});

export const { getAllCategoryList, getACategory, deleteCategory } = categorySlice.actions;
export default categorySlice.reducer;