import React from "react";

const PosPaymentsItems = ({ title, value, bgColor }) => {
  return (
    <>
      <p
        style={{ backgroundColor: bgColor && bgColor }}
        className={`d-flex justify-content-between align-items-center py-1 my-0  
        }`}
      >
        <span className={`fw-bold text-white fs-6 ms-3 `}>{title}:</span>{" "}
        <span className="fw-bold text-white fs-5 me-3 ">{value}</span>
      </p>
    </>
  );
};

export default PosPaymentsItems;
