import React from "react";
import PurchaseInvoiceTableBody from "./PurchaseInvoiceTableBody";
import useNumberFormatter from "../../../hooks/useNumberFormatter";

const PurchaseInvoiceTable = ({ data }) => {
  const { purchase_items, grand_total } = data || {};
  const numericFormatter = useNumberFormatter();
  // calculate total
  const totalAmount = purchase_items?.reduce(
    (acc, item) => acc + item?.total_cost,
    0
  );
  const totalQuantity = purchase_items?.reduce(
    (acc, item) => acc + item?.purchase_qty,
    0
  );
  const deliveryCharge =
    parseFloat(grand_total || 0) - parseFloat(totalAmount || 0);

  return (
    <>
      <table className="text-center table-bordered border-black w-100 mt-3 mb-2">
        <thead>
          <tr className="">
            <th>SL</th>
            <th>Item Name</th>
            <th>Description</th>
            <th>Brand/Model/Origin</th>
            <th>UoM</th>
            <th>Quantity</th>
            <th>Disc.</th>
            <th>Tax(%)</th>
            <th>Vat(%)</th>
            <th>Unit Price</th>
            <th>Amount(BDT)</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            purchase_items?.map((item, index) => (
              <PurchaseInvoiceTableBody key={index} item={item} index={index} />
            ))}
        </tbody>
        <tfoot className="text-end fw-bold border border-dark">
          <tr className="border-0 ">
            {/* row-1 */}
            <td colSpan="5" className="pe-5 pt-2 pb-1  border-0">
              Total :
            </td>
            <td className="text-center pt-2 pb-1  border-0">
              {numericFormatter(totalQuantity || 0)}
            </td>
            <td colSpan="4" className="pe-5 pt-2 pb-1  border-0">
              Total :
            </td>
            <td className="text-center pt-2 pb-1  border-0">
              {numericFormatter(totalAmount || 0)}
            </td>
          </tr>
          {/* row-2 */}
          {/* <tr className="border-0">
            <td colSpan="10" className="pe-5 py-1 border-0">
              (-) Discount Amount :
            </td>
            <td className="text-center py-1 border-0">0</td>
          </tr> */}
          {/* row-3 */}
          {/* <tr className="border-0">
            <td colSpan="10" className="pe-5 py-1 border-0">
              Vat Amount :
            </td>
            <td className="text-center py-1 border-0">0</td>
          </tr> */}
          {/* row-4 */}
          {/* <tr className="border-0">
            <td colSpan="10" className="pe-5 py-1 border-0">
              Tax Amount :
            </td>
            <td className="text-center py-1 border-0">0</td>
          </tr> */}
          {/* row-5 */}
          <tr className="border-0">
            <td colSpan="10" className="pe-5 py-1 border-0">
              Supplier Payable :
            </td>
            <td className="text-center py-1 border-0">0</td>
          </tr>
          {/* row-6 */}
          <tr className="border-0">
            <td colSpan="10" className="pe-5 py-1 border-0">
              Delivery Charge :
            </td>
            <td className="text-center py-1 border-0">
              {numericFormatter(deliveryCharge || 0)}
            </td>
          </tr>
          {/* row-7 */}
          <tr className="border-0">
            <td colSpan="10" className="pe-5 py-1 border-0">
              Grand Total :
            </td>
            <td className="text-center py-1 border-0">
              {numericFormatter(grand_total || 0)}
            </td>
          </tr>
          {/* row-8 */}
          <tr className="border-0">
            <td colSpan="10" className="pe-5 pt-1 pb-3 border-0">
              Advance :
            </td>
            <td className="text-center pt-1 pb-3 border-0">0</td>
          </tr>
        </tfoot>
      </table>
      <section></section>
    </>
  );
};

export default PurchaseInvoiceTable;
