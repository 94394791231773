import React from "react";
import { useParams } from "react-router-dom";
import useGetData from "../../../hooks/useGetData";
import Breadcrumb from "../../../components/common/breadcrumb";
import SalesReturnInvoice from "./SalesReturnInvoice";
import ViewSalesReturnInformationTableSection from "./ViewSalesReturnInformationTableSection";

const SalesReturnView = () => {
  const { id } = useParams();
  const [salesReturnProduct, salesReturnProductRefetch] = useGetData(
    `/salesreturn/view-sales-return/${id}`,
    "salesReturnProduct"
  );
  return (
    <>
      <Breadcrumb title="View Sales Return" parent="Sales Management" />
      <main className=" w-100 p-3 bg-white rounded-2">
        <SalesReturnInvoice data={salesReturnProduct}></SalesReturnInvoice>
        <ViewSalesReturnInformationTableSection
          data={salesReturnProduct}
        ></ViewSalesReturnInformationTableSection>
      </main>
    </>
  );
};

export default SalesReturnView;
