import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  input,
} from "reactstrap";
import usePostData from "../../../hooks/usePostData";

const PosAddCustomerModal = ({ isOpen, toggle, customerRefetch }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const { mutate: postData } = usePostData(
    "/customer/addcustomer",
    "Customer Added Successfully",
    customerRefetch
  );
  const onSubmit = (data) => {
    const customerData = {
      customer_name: data?.customerName,
      phone: data?.phoneNumber,
      address: data?.address,
    };
    postData(customerData);
    reset();
    toggle();
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      style={{ maxWidth: "50%" }}
    >
      <ModalHeader
        className="d-flex justify-content-center  align-items-center"
        style={{
          backgroundImage: "linear-gradient(to right, #4b0ab3, #090080)", // Gradient background
        }}
      >
        <p className="text-white w-100 m-0 fw-bold fs-5">Add Customer</p>
      </ModalHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <div className=" d-flex justify-content-between align-items-center">
            <FormGroup style={{ width: "48%" }}>
              <Label for="customerName" className="mb-1">
                Customer Name
              </Label>
              <input
                type="text"
                name="name"
                className="text-input cs-text-input border"
                id="customerName"
                placeholder="Enter customer name"
                {...register("customerName", { required: true })}
              />
              {errors.customerName && (
                <span style={{ color: "red" }}>
                  Customer Name field is required
                </span>
              )}
            </FormGroup>
            <FormGroup style={{ width: "48%" }}>
              <Label for="phoneNumber" className="mb-1">
                Phone Number
              </Label>
              <input
                type="text"
                name="phoneNumber"
                id="phoneNumber"
                className="text-input cs-text-input border"
                placeholder="Enter phone number"
                {...register("phoneNumber", { required: true })}
              />
              {errors.phoneNumber && (
                <span style={{ color: "red" }}>
                  Phone Number field is required
                </span>
              )}
            </FormGroup>
          </div>
          <FormGroup>
            <Label for="address" className="mb-1">
              Address
            </Label>
            <input
              type="text"
              name="address"
              id="address"
              className="text-input cs-text-input border"
              placeholder="Enter address"
            />
          </FormGroup>
        </ModalBody>
        <div className="modal-footer border-0">
          <Button
            className="text-white"
            style={{ backgroundColor: "red" }}
            color="red"
            type="reset"
            onClick={toggle}
          >
            Cancel
          </Button>
          <Button
            style={{ backgroundColor: "green" }}
            color="green"
            type="submit"
            className="text-white"
          >
            Save
          </Button>{" "}
        </div>
      </form>
    </Modal>
  );
};

export default PosAddCustomerModal;
