import React, { useEffect, useState } from "react";
import { AiOutlinePlus, AiOutlineMinus, AiFillDelete } from "react-icons/ai";
// import posDiscountModal from "../ReportsManagement/Modal/PosDiscountModal";
import useNumberFormatter from "../../../hooks/useNumberFormatter";

const SalesReturnTable = ({
  product,
  products,
  setSelectedItemData,
  setTotalSubTotal,
  setTotalQuantity,
  setUpdatedReturnProducts,
}) => {
  const numericFormatter = useNumberFormatter();
  const { item_name, stock, sales_price, id, sales_qty, item_total_cost } =
    product || {};
  const [updatedStock, setUpdatedStock] = useState();
  const [count, setCount] = useState(1);

  const [price, setPrice] = useState(0);
  const priceByQuantity = parseFloat(price) * parseFloat(count);

  const [newAllProductTotalSubTotal, setNewAllProductTotalSubTotal] =
    useState(0);

  useEffect(() => {
    setCount(sales_qty);
  }, [sales_qty]);
  // stock update
  useEffect(() => {
    setUpdatedStock(parseFloat(stock) - parseFloat(count ? count : 0));
  }, [stock, count]);

  // sales update
  useEffect(() => {
    setPrice(sales_price ? sales_price : 0);
  }, [sales_price]);

  // quantity increment decrement
  const handleIncrement = (e) => {
    e.preventDefault();
    if (parseFloat(count) < sales_qty) {
      setCount(parseFloat(count) + 1);
      setUpdatedStock(parseFloat(updatedStock) - 1);
    }
  };

  const handleDecrement = (e) => {
    e.preventDefault();
    if (parseFloat(count) > 1) {
      setCount(parseFloat(count) - 1);
      setUpdatedStock(parseFloat(updatedStock) + 1);
    }
  };

  // Remove the current product from the products array-----------
  const handleDeleteProduct = () => {
    // Filter out the product from products
    const updatedProducts = products?.filter((p) => p?.id !== product?.id);

    // Calculate new total and subtotal based on updatedProducts
    const newTotalSubTotal = updatedProducts.reduce(
      (acc, product) =>
        acc + parseFloat(product.subtotal || product.item_total_cost),
      0
    );

    // Calculate new quantity and subQuantity based on updatedProducts
    const totalQuantity = updatedProducts.reduce(
      (acc, product) =>
        acc + parseFloat(product.itemQuantity || product.sales_qty),
      0
    );
    // Update state with the new values
    setSelectedItemData(updatedProducts);
    setTotalSubTotal(newTotalSubTotal);
    setNewAllProductTotalSubTotal(newTotalSubTotal);
    setTotalQuantity(totalQuantity);
    setUpdatedReturnProducts(updatedProducts);
  };

  // handle total and subtotal calculation---------------------
  const handleTotalSubTotal = () => {
    const updatedProducts = products.map((p) => {
      if (p?.id === id) {
        if (count === 0) {
          // If count is 0, remove the product
          return null;
        }
        return { ...p, subtotal: priceByQuantity, itemQuantity: count || 0 };
      }
      return p;
    });

    // Filter out null values (products with count 0)
    const updatedProductsFiltered = updatedProducts.filter((p) => p !== null);

    // Calculate new total and subtotal
    const newTotalSubTotal = updatedProductsFiltered.reduce(
      (acc, product) =>
        acc + parseFloat(product.subtotal || product.item_total_cost),
      0
    );

    // Calculate new quantity and subQuantity
    const totalQuantity = updatedProductsFiltered.reduce(
      (acc, product) =>
        acc + parseFloat(product.itemQuantity || product.sales_qty),
      0
    );
    setTotalQuantity(totalQuantity);
    setSelectedItemData(updatedProductsFiltered);
    setTotalSubTotal(newTotalSubTotal);
    setUpdatedReturnProducts(updatedProducts);
  };

  useEffect(() => {
    handleTotalSubTotal(); // This calls the handleDiscount function
  }, [count, price]);
  return (
    <tr>
      <td className="text-start ">{item_name}</td>
      {/* purchase price  */}
      <td >
        <span className="">
          <input
            type="number"
            j
            step={"any"}
            placeholder="Enter Amount "
            value={price >= 0 ? price : 0}
            onChange={(e) => {
              const inputValue = parseFloat(e.target.value);
              if (!isNaN(inputValue) && inputValue >= 0) {
                setPrice(inputValue);
              } else {
                setPrice(0);
              }
            }}
            className="border rounded-1 py-2 bg-body-secondary text-input text-center"
          />
        </span>
      </td>
      {/* quantity increment  decrement  */}
      <td>
        <span
          style={{ width: "150px" }}
          className="border rounded-1 mx-auto d-flex justify-content-between align-items-center"
        >
          <button
            className="d-flex justify-content-center align-items-center border-end border-0 col-2   p-2"
            onClick={handleDecrement}
          >
            <span>
              <AiOutlineMinus className="text-danger fw-bold" />
            </span>
          </button>
          <span className=" text-center  border-0 d-inline-block col-8">
            <input
              type="number"
              step={"any"}
              placeholder="Quantity"
              value={count >= 1 && count}
              onChange={(e) => {
                const inputValue = parseFloat(e.target.value);
                if (!isNaN(inputValue) && inputValue >= 1) {
                  setCount(inputValue);
                } else {
                  setCount(0);
                }
              }}
              className="border-0  text-center py-2 text-center w-100"
            />
          </span>
          <button
            className="d-flex justify-content-center align-items-center border-start border-0 py-2 col-2"
            onClick={handleIncrement}
          >
            <span>
              <AiOutlinePlus className="text-success fw-bold" />
            </span>
          </button>
        </span>
      </td>
      {/* subtotal */}
      <td className="">
        <span
          style={{ width: "40px" }}
          className="border rounded-1 bg-body-secondary w-100 py-2 d-block"
        >
          {priceByQuantity ? numericFormatter(priceByQuantity) : 0}
        </span>
      </td>
      {/* delete */}
      <td>
        <span className="d-flex justify-content-center align-items-center py-2">
          <AiFillDelete
            onClick={handleDeleteProduct}
            style={{ cursor: "pointer" }}
            className="text-danger fs-5"
          ></AiFillDelete>
        </span>
      </td>
    </tr>
  );
};

export default SalesReturnTable;
