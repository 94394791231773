import React, { useEffect, useState } from 'react'
import SalesPaymentTable from './components/SalesPaymentTable'
import Breadcrumb from '../../../components/common/breadcrumb'
import DateRangePickerComponent from './components/DateRangePicker'
import CustomerNameSelectDropdown from './components/CustomerNameSelectDropdown'
import { addDays } from 'date-fns';

import { Button } from 'reactstrap'
import moment from 'moment'
import BranchNameSelectDropdown from '../SupplierItems/components/BranchNameSelectDropdown'

const SalesPaymentsReportsScreen = () => {
    const [queryData, setQueryData] = useState({
        user_name: '',
        warehouse_name: '',
        brand_name: '',
        category_name: '',
        lot_number: ""
    })
    const [stateM, setStateM] = useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 0),
            key: 'selection',
        },
    ]);

    const [ApiLink, setApiLink] = useState('/report/sales-report')
    const startDate = moment(stateM[0].startDate).format().substring(0, 10)
    const endDate = moment(stateM[0].endDate).format().substring(0, 10)



    return (
        <>
            <Breadcrumb title="Sales Payments" parent="reports Payments" />
            <div className='col-12 '>
                <h3 className=''>Please Enter Valid Information</h3>
                <div className='d-flex'>
                    <BranchNameSelectDropdown
                        apiLink="/branch/viewbranch" queryData={queryData}
                        setQueryData={setQueryData}
                        InitialDropdownValue='All-Branch'

                    />
                    <CustomerNameSelectDropdown
                        apiLink="/customer/viewcustomers" queryData={queryData}
                        setQueryData={setQueryData}
                        InitialDropdownValue='select user'


                    />
                    {/* <DateRangePickerComponent
                        setStateM={setStateM}
                        stateM={stateM}

                        queryData={queryData}
                        setQueryData={setQueryData}
                    /> */}
                </div>

            </div>

            <div className=''>
                {/* <PayamentList /> */}
                <SalesPaymentTable queryLink={'/sales/sales-payments'} wareHouseDropdownValue={queryData.warehouse_name} />


            </div>
        </>
    )
}

export default SalesPaymentsReportsScreen