



import { HiInformationCircle } from "react-icons/hi";
import { AiFillEdit, AiTwotoneDelete } from 'react-icons/ai';
import { Fragment, useEffect, useState, } from "react";
import { Card, CardBody, CardHeader, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Pagination, Table, UncontrolledDropdown } from "reactstrap";

import Breadcrumb from "../../../components/common/breadcrumb";

// import ItemListTable from "./ItemListTable";
import { BiPlusMedical } from "react-icons/bi";
import axios from "../../../util/axiosInstance";
import { Link, } from "react-router-dom";
import TableLoading from '../../../components/common/loading/TableLoading';
import { CustomToast } from '../../../hooks/useCustomToast';
import Swal from "sweetalert2";
import ItemsDataSearchSection from "./ItemsDataSearchSection/ItemsDataSearchSection";
import UnitDataSearchSection from "./UnitDataSearchSection/UnitDataSearchSection";
import BrandDataSearchSection from "./BrandDataSearchSection/BrandDataSearchSection";
import NoDataFoundTr from "../../../components/common/NoDataFoundTr";
import { OverlayTrigger, Tooltip } from "react-bootstrap";


const ItemsList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(100)

  const [pageNumber, setPageNumber] = useState(1)
  const [numberOfItem, setNumberOfItem] = useState(0)

  const [brandList, setItemsList] = useState([])//main data
  const showAllData = brandList.length;

  const [brandListSecondary, setBrandListSecodary] = useState([])//rendering data


  const [searchTerm, setSearchTerm] = useState('');

  const [error, setError] = useState(null);

  const [categoryData, setCategoryData] = useState([]);
  const [categoryDropdownValue, setCategoryDropdownValue] = useState('');


  const [unitData, setUnitData] = useState([]);
  const [unitDropdownValue, setUnitDropdownValue] = useState('');

  const [brandData, setBrandData] = useState([])//main brand list data
  const [brandDropdownValue, setBrandDropdownValue] = useState('');




  const [filterData, setFilterData] = useState('');


  const [handleItemClickId, setHandleItemClickId] = useState(null)

  const [itemDataCollapse, setItemDataCollapse] = useState(false);
  const handleItemClick = () => {
    setItemDataCollapse(!itemDataCollapse)
  }

  console.log(itemDataCollapse);
  console.log(handleItemClickId);

  const [dropdownValue, setDropdownValue] = useState(10);



  const totalPageNumber = Math.ceil(numberOfItem / dropdownValue)



  const [isTooltipVisible, setTooltipVisible] = useState(false);





  const btnStyle = {
    height: '40px',
    width: '40px',
    borderRadius: '50%',
    padding: '8px',
    background: 'transparent',
  };







  // console.log(brandList);

  useEffect(() => {

    setIsLoading(true);
    // axios('/item/viewitems/')
    axios(`/item/viewitems?page=${pageNumber}&limit=${dropdownValue}&brand_name=${brandDropdownValue}&category_name=${categoryDropdownValue}&unit_name=${unitDropdownValue}`)
      .then(data => {
        setItemsList(data?.data?.items);
        setNumberOfItem(data?.data?.total_count)
        setIsLoading(false);  // Stop loading
      })
      .catch(error => {
        setError(error);
        setIsLoading(false);  // Stop loading
      });

  }, [brandDropdownValue, categoryDropdownValue, unitDropdownValue, dropdownValue, pageNumber])

  useEffect(() => {
    handleFilter();
  }, [searchTerm, brandList,])





  // handle  search filter function

  const handleFilter = () => {
    let filterData = [...brandList]
    if (searchTerm !== "") {
      filterData = brandList?.filter(item =>
        item?.item_name?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
        item?.custom_barcode?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
        item?.category_name?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
        item?.brand_name?.toLowerCase()?.includes(searchTerm.toLowerCase())
      )
    }
    setBrandListSecodary(filterData);
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
  };

  const handleItemsDelete = async (id) => {

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // Assuming your API endpoint looks like /api/items/:id
          const response = await axios.delete(`/item/deleteitem/${id}`);

          if (response) {
            // Filter out the deleted item based on its ID
            const updatedItems = brandList?.filter(item => item?.id !== id);
            setItemsList(updatedItems);
            CustomToast('success', 'Delete Successfully')
          } else {
            console.error("Failed to delete item:", response.data);
          }
        } catch (error) {
          console.error("Error deleting item:", error);
        }


        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        )
      }
    })



  }


  useEffect(() => {
    axios
      .get("/category/viewcategories/")
      .then((res) => setCategoryData(res?.data))
      .then(error => {
        // console.log(error);
      })
  }, []);

  useEffect(() => {
    axios.get("/unit/viewunits/")
      .then((res) => setUnitData(res?.data))
      .then(error => {
        // console.log(error);
      })
  }, []);

  useEffect(() => {
    axios.get("/brand/viewbrands/")
      .then((res) => setBrandData(res?.data))
      .then(error => {
        // console.log(error);
      })
  }, []);



  return (
    <Fragment>
      <Breadcrumb
        title='Items List'
        parent='Product Management'
      />
      <Container fluid={true}>
        <Card>


          <CardBody>

            <div className="row  " style={{ padding: '0px, 10px' }}>
              <div className="col-md-3 ">
                <div className="text-center text-black fw-bold mb-1">Category</div>
                <div className="border">
                  <ItemsDataSearchSection
                    categoryDropdownValue={categoryDropdownValue}
                    setCategoryDropdownValue={setCategoryDropdownValue}
                    borderEnd={"category"}
                    inputType={"search"}
                    InitialDropdownValue={"Select Category"}
                    data={categoryData}
                    titleData={"Category"}
                    placeHolderData={"Enter Category Name"}
                    dataField={"category_name"}
                    setPageNumber={setPageNumber}
                  />
                </div>

              </div>
              {/* // unit search  */}
              <div className="col-md-3 ">
                <div className="text-center text-black fw-bold mb-1">Unit</div>
                <div className="border">
                  <UnitDataSearchSection
                    unitDropdownValue={unitDropdownValue}
                    setUnitDropdownValue={setUnitDropdownValue}
                    borderEnd={"unit"}
                    inputType={"search"}
                    InitialDropdownValue={"Select Unit"}
                    data={unitData}
                    titleData={"Category"}
                    placeHolderData={"Enter Unit Name"}
                    dataField={"unit_name"}
                    setPageNumber={setPageNumber}

                  />
                </div>
              </div>

              {/* brand search  */}
              <div className="col-md-3  ">
                <div className="text-center text-black fw-bold mb-1">Brand</div>
                <div className="border">
                  <BrandDataSearchSection
                    brandDropdownValue={brandDropdownValue}
                    setBrandDropdownValue={setBrandDropdownValue}
                    borderEnd={"brand"}
                    inputType={"search"}
                    InitialDropdownValue={"Select Brand"}
                    data={brandData}
                    titleData={"Brand"}
                    placeHolderData={"Enter Brand Name"}
                    dataField={"brand_name"}
                    setPageNumber={setPageNumber}

                  />
                </div>
              </div>


              <div className="col-md-3">
                <Link className="border d-flex mt-4  align-items-center justify-content-center gap-2" style={{ backgroundColor: '#00C0EF', textDecoration: 'none', color: 'white', padding: '10px 15px', borderRadius: '5px', direction: 'rtl' }} to={'/product-management/add-item'}><BiPlusMedical /> Add Item</Link>
              </div>


            </div>

            <div className='row mt-5'>
              <div className='col-md-4 d-flex align-items-center '>

              </div>
              <div className='col-md-4 d-flex align-items-center justify-content-end'>
                {/* <span style={{ backgroundColor: '#39CCCC', color: 'white', padding: '8px', borderRadius: '5px', borderRight: '1px solid white', cursor: 'pointer' }}>Copy</span> */}

              </div>
              <div className='col-md-4 d-flex align-items-center gap-2'>
                <span>Search </span>

                <input
                  type='text'
                  placeholder='Item Name, Barcode, Category, Brand....'
                  value={searchTerm}
                  onChange={handleSearchChange}
                  className='form-control'
                />
              </div>
            </div>

            <div
              style={{
                backgroundColor: '#F2F2F2',
                marginTop: '3px',
                borderRadius: '5px',
                padding: '10px',
              }}>
              <Table
                bordered
                hover
                striped>
                <thead className='text-center'>
                  <tr>
                    <th>ID</th>
                    <th>Item Name</th>
                    <th>Barcode</th>
                    <th>Category</th>
                    <th>Brand</th>
                    <th>Unit</th>
                    <th>Stock</th>
                    <th>Purchase Price</th>
                    <th>Sales Price</th>
                    <th >Action</th>
                  </tr>
                </thead>
                <tbody className='text-center'>
                  {!isLoading && brandList?.length === 0 && <NoDataFoundTr colSpanNumber={10} />}
                  {isLoading && (
                    <>
                      <TableLoading
                        noTd={10}
                        noRow={10}
                      />
                    </>
                  )}

                  {!isLoading &&
                    brandListSecondary
                      ?.map((singleData, index) => (
                        <>
                          <tr style={{cursor:'pointer'}} onClick={() => {
                            handleItemClick();
                            setHandleItemClickId(singleData.id);
                          }} key={singleData?.id}>
                            <th scope='row'>{index + 1 + (dropdownValue * (pageNumber - 1))}</th>
                            <td className="fw-bold" style={{ color: "#174978" }}>{singleData?.item_name}</td>
                            <td>{singleData?.custom_barcode}</td>
                            <td>{singleData?.category_name}</td>
                            <td>{singleData?.brand_name}</td>
                            <td>{singleData?.unit_name}</td>
                            <td>{
                              itemDataCollapse ?  singleData?.stock: ( singleData?.stock + singleData?.lots.reduce((x, y) => x + y.stock, 0)) }</td>

                            {/* <td > <span>Total Lots: </span>{singleData?.lots?.length} <span>Qty: </span> {singleData?.lots.reduce((x, y) => x + y.purchase_qty, 0)}</td> */}

                            <td>
                              {singleData?.purchase_price ? (
                                <>
                                  {' '}
                                  <span> {singleData?.purchase_price} </span>
                                </>
                              ) : (
                                <>
                                  <HiInformationCircle
                                    style={{ cursor: 'pointer' }}
                                    onMouseEnter={() => setTooltipVisible(true)}
                                    onMouseLeave={() =>
                                      setTooltipVisible(false)
                                    }
                                    className='fs-5 ms-1 text-danger informationHover'></HiInformationCircle>

                                  <div
                                    style={{
                                      width: '220px',
                                      // boxShadow: "1px 1px 8px 5px rgba(165, 165, 165, 0.548)",
                                    }}
                                    className={`text-white border overflow-hidden  text-center  position-absolute -bottom-20 rounded-3  ${isTooltipVisible
                                      ? 'opacity-100'
                                      : 'opacity-0'
                                      }`}>
                                    <div className='bg-body rounded-bottom py-2 '>
                                      <p className='m-0 text-dark'>
                                        select the value
                                      </p>
                                    </div>
                                  </div>
                                </>
                              )}
                            </td>
                            <td>{singleData?.sales_price}</td>

                            <td>
                              <div className='d-flex justify-content-center align-items-center gap-3'>
                                <Link
                                  className='fs-4 text-decoration-none'
                                  to={`/product-management/item-edit/${singleData.id}`}>
                                  {' '}
                                  <AiFillEdit />{' '}
                                </Link>
                                <span
                                  onClick={() =>
                                    handleItemsDelete(singleData.id)
                                  }
                                  className='border-0 bg-transparent fs-4'
                                  style={{ cursor: 'pointer' }}>
                                  {' '}
                                  <AiTwotoneDelete />{' '}
                                </span>
                              </div>
                            </td>

                          </tr>
                          {/* handle click to show itemDataCollapse data  */}
                          {
                            handleItemClickId === singleData?.id && itemDataCollapse && (
                              singleData?.lots?.map((item, index) => (
                                <tr key={index}>
                                  <td colSpan={6}>
                                    <span className="fw-bold text-black">Lot Number: </span > <span> {item?.lot_number}</span>
                                  </td>
                                  <td>{item?.purchase_qty - item.sales_qty}</td>
                                  <td>
                                    {item?.lot_purchase_price || <span className="text-danger">Purchase price is not updated </span>}
                                  </td>
                                  <td>
                                    {item?.lot_sales_price || <span className="text-danger">Sales price is not updated </span>}
                                  </td>
                                </tr>
                              ))
                            )
                          }

                        </>
                      ))}
                </tbody>

              </Table>
            </div>


            <div>
              <nav className=' d-flex justify-content-end align-items-center px-2 col-12 text-black-50 fs-6 bg-white border-top-1'>
                <span className=' mx-2 my-0 flex-shrink-1 user-select-none'>
                  Rows per page:
                </span>
                <div className=' position-relative flex-shrink-0 mt-1 d-flex '>
                  <select
                    onClick={(e) => setDropdownValue(e.target.value)}
                    aria-label='Rows per page:'
                    className='sc-bXCLTC eVrcSZ cs-cursor-pointer user-select-none ps-2 pe-4  '
                    style={{
                      height: '25px',
                      maxWidth: '100%',
                      color: '#000000',
                      fontSize: 'inherit',
                      border: 'none',
                      background: 'transparent',
                      appearance: 'none',
                      direction: 'ltr',
                    }}>
                    <option
                      value={10}
                      selected>
                      10
                    </option>
                    <option value={50}>50</option>
                    <option value={75}>75</option>
                    <option value={100}>100</option>
                    <option value={150}>150</option>
                    <option value={500}>200</option>
                    <option value={numberOfItem}>All </option>
                  </select>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    className='position-absolute top-0'
                    style={{ right: 0 }}>
                    <path d='M7 10l5 5 5-5z' />
                    <path
                      d='M0 0h24v24H0z'
                      fill='none'
                    />
                  </svg>
                </div>
                <span className='mx-4 user-select-none flex-shrink-1'>{pageNumber}-{totalPageNumber} of {totalPageNumber}</span>
                <div className='sc-bmzYkS fimDOL d-flex align-items-center rounded-1 white-space-none'>
                  {/* 0 page */}
                  <button
                    onClick={() => setPageNumber(1)}

                    id='pagination-first-page'
                    type='button'
                    aria-label='First Page'
                    aria-disabled='true'

                    className='position-relative d-block user-select-none border-0 rounded-1 cs-cursor-pointer'
                    style={btnStyle}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width={24}
                      height={24}
                      viewBox='0 0 24 24'
                      aria-hidden='true'
                      role='presentation'>
                      <path d='M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z' />
                      <path
                        fill='none'
                        d='M24 24H0V0h24v24z'
                      />
                    </svg>
                  </button>
                  {/* prev */}
                  <button
                    onClick={(e) => setPageNumber((x) => {
                      return x - 1
                    })}
                    disabled={pageNumber === 1}

                    id='pagination-previous-page'
                    type='button'
                    aria-label='Previous Page'
                    aria-disabled='true'

                    className='position-relative d-block user-select-none border-0 rounded-1 cs-cursor-pointer'
                    style={btnStyle}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width={24}
                      height={24}
                      viewBox='0 0 24 24'
                      aria-hidden='true'
                      role='presentation'>
                      <path d='M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z' />
                      <path
                        d='M0 0h24v24H0z'
                        fill='none'
                      />
                    </svg>
                  </button>
                  {/* next page */}
                  <button
                    onClick={(e) => setPageNumber((x) => x + 1)}
                    id='pagination-next-page'
                    type='button'
                    aria-label='Next Page'
                    aria-disabled='true'
                    disabled={pageNumber === totalPageNumber}
                    className='position-relative d-block user-select-none border-0 rounded-1 cs-cursor-pointer'
                    style={btnStyle}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width={24}
                      height={24}
                      viewBox='0 0 24 24'
                      aria-hidden='true'
                      role='presentation'>
                      <path d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z' />
                      <path
                        d='M0 0h24v24H0z'
                        fill='none'
                      />
                    </svg>
                  </button>
                  {/* last page */}
                  <button
                    onClick={() => setPageNumber(totalPageNumber)}
                    id='pagination-last-page'
                    type='button'
                    aria-label='Last Page'
                    aria-disabled='true'

                    className='position-relative d-block user-select-none border-0 rounded-1 cs-cursor-pointer'
                    style={btnStyle}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width={24}
                      height={24}
                      viewBox='0 0 24 24'
                      aria-hidden='true'
                      role='presentation'>
                      <path d='M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z' />
                      <path
                        fill='none'
                        d='M0 0h24v24H0V0z'
                      />
                    </svg>
                  </button>
                </div>
              </nav>
            </div>

          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
};

export default ItemsList;


{/* <tbody className='text-center'>
                  <tr>
                    <td>1</td>
                    <td>pen</td>
                    <td>barcode</td>
                    <td>cat1</td>
                    <td>brand1</td>
                    <td>unit1</td>
                    <td>stock</td>
                    <td>purcha 10</td>
                    <td>sal 10</td>
                    <td style={{backgroundColor:'red'}}  className="align-self-center" rowSpan={3}>edit del </td>
                  </tr>
                  <tr>
                    <td colSpan={6}>lotNumber</td>
                    <td >stock</td>
                    <td>purchase price </td>
                    <td>sales price </td>
                  </tr>
                </tbody> */}

