import React from "react";
import { Button } from 'reactstrap';
import { swalWithBootstrapButtons } from '../../../../util/swalWithBootstrapButtons';
import axiosInstance from '../../../../util/axiosInstance';

const DeliveryListTable = ({ product, sl }) => {
  const {
    order_consignment_id,
    merchant_order_id,
    store_name,
    recipient_name,
    recipient_address,
    recipient_phone,
    order_status,
    order_status_updated_at,
    order_amount,
    total_fee,
    discount,
    billing_status,
    order_description,
  } = product || {};

  const accessToken = localStorage.getItem('pathaoAccess_Token');
  // data get from backend

  const courseDenied = (course, refetch) => {
    swalWithBootstrapButtons
      .fire({
        title: 'Admin feedback',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off',
        },
        showCancelButton: true,
        confirmButtonText: 'feedback',
        showLoaderOnConfirm: true,
        //   confirmButtonColor: '#3085d6',
        //   cancelButtonColor: '#d33',
      })
      .then((result) => {

        if (result.isConfirmed) {
          axiosInstance
            .patch(
              `https://fancymalabis.apodigi.com/pathao/cancelOrder/${order_consignment_id}`,
              {},

              {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              }
            )

            .then((data) => {
              if (data.data.deletedCount > 0) {
                refetch();
                swalWithBootstrapButtons.fire(
                  'Deleted!',
                  `${course.name} has been deleted.`,
                  'success'
                );
              }
            });
        }
      });
  };

  const cancelOrder = () => {
    fetch(
      `https://fancymalabis.apodigi.com/pathao/cancelOrder/${order_consignment_id}`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        // setDeliveryListData(data?.data);
        // window.location.reload();
      })
      .catch(() => {});
  };
  return (
    <>
      <tr>
        <td>{sl + 1}</td>
        <td>{order_consignment_id}</td>
        <td>{merchant_order_id}</td>
        <td>{store_name}</td>
        <td className='d-flex flex-column'>
          {recipient_name}
          <span>{recipient_address}</span>
          <span>{recipient_phone}</span>
        </td>
        <td>
          <div className=''>
            <span
              style={{
                backgroundColor: '#15a5f9',
                color: 'white',
                width: '50%',
                padding: '3px',
                borderRadius: '3px',
                textAlign: 'center',
              }}>
              {order_status}
            </span>
            <br />
            <span>
              {order_status_updated_at.slice(0, 10)} -{' '}
              {order_status_updated_at.slice(11, 19)}
            </span>
          </div>
        </td>
        <td>{order_description}</td>

        <td>
          <div className='d-flex flex-column'>
            <span>
              <span style={{ fontWeight: 'bold' }}>COD: </span>
              {order_amount}
            </span>
            <span>
              <span style={{ fontWeight: 'bold' }}>Total Fee: </span>{' '}
              {total_fee}
            </span>
            <span>
              <span style={{ fontWeight: 'bold' }}>Discount: </span> {discount}
            </span>
          </div>
        </td>
        <td>
          {' '}
          <span
            style={{
              backgroundColor: '#291096',
              color: 'white',
              width: '50%',
              padding: '3px',
              borderRadius: '3px',
              textAlign: 'center',
            }}>
            {billing_status}
          </span>
        </td>
        <td>
          <Button onClick={cancelOrder}>cancel</Button>
        </td>

        {/* <td>{item_name}</td>
        <td>{numericFormatter(price_per_unit) || 0}</td>
        <td>{numericFormatter(sales_qty) || 0}</td>
        <td>{numericFormatter(item_total_cost) || 0}</td>
        <td>
          {numericFormatter(item_discount_input) || 0}{' '}
          {item_discount_type === 'Percentage(%)' ? '%' : 'Fixed(৳)'}
        </td>
        <td>{numericFormatter(item_discount_amt) || 0}</td>
        <td>{numericFormatter(item_unit_total_cost) || 0}</td>
        <td>{numericFormatter(item_total_cost) || 0}</td> */}
      </tr>
    </>
  );
};

export default DeliveryListTable;
