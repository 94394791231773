import React, { useEffect, useState } from "react";
import Card from "./Card";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductAction } from "../../redux/features/products/productsSlice";

const CardList = ({ myCart, setMySelectedCart }) => {
  const dispatch = useDispatch();

  const [selectedCards, setSelectedCards] = useState([]);
  const [cart, setCart] = useState([]);
  const products = useSelector((state) => state.products);
  const {
    loading: productLoading,
    productList,
    appErr: ProductAppErr,
    serverErr: ProductServerErr,
  } = products || {};

  const handleCardSelect = (cardData) => {
    const isSelected = selectedCards.includes(cardData);
    let updatedSelection = [];

    if (isSelected) {
      updatedSelection = selectedCards.filter((card) => card !== cardData);
    } else {
      updatedSelection = [...selectedCards, cardData];
    }

    setSelectedCards(updatedSelection);
  };

  useEffect(() => {
    dispatch(fetchProductAction());
  }, [dispatch]);

  const addToCart = () => {
    const items = [...cart, ...selectedCards];

    // Filtering unique items based on their 'id'
    const uniqueItems = items.reduce((unique, currentItem) => {
      const isAlreadyExist = unique.some((item) => item.id === currentItem.id);
      if (!isAlreadyExist) {
        unique.push(currentItem);
      }
      return unique;
    }, []);

    setCart(uniqueItems);
    setMySelectedCart(uniqueItems);
    setSelectedCards([]);
  };

  const renderCards = () => {
    return productList.map((card) => (
      <Card
        key={card?.id}
        cardData={card}
        isSelected={selectedCards.includes(card)}
        onSelect={handleCardSelect}
      />
    ));
  };

  return (
    <div>
      {myCart ? (
        <div className=" " style={{ height: "50vh" }}>
          <h2 className="fs-5 fw-bold text-center">
            My Cart List({`${cart?.length}`})
          </h2>
          <div className="d-flex flex-wrap justify-content-center overflow-y-auto mt-3 gap-3 ">
            {cart?.map((item, index) => (
              <div
                key={index}
                className={`p-3 rounded-2 card-bg-color `}
                style={{ width: "15.5%" }}
              >
                <h5 className={`fw-bold card-text-color`}>{item?.item_name}</h5>
                <p className={` card-text-color m-0`}>
                  {item?.sales_price}/{item?.unit_name}
                </p>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-between mx-2 ">
            <h3 className="text-end col-6 fw-bold text-black my-2 ">
              All Products({`${productList?.length}`})
            </h3>
            <button className="btn bg-navy text-light" onClick={addToCart}>
              Add Selected to Cart({`${selectedCards?.length}`})
            </button>
          </div>
          <div
            className="d-flex flex-wrap justify-content-center overflow-y-auto mt-3 gap-3"
            style={{ height: "50vh" }}
          >
            {renderCards()}
          </div>
        </>
      )}
    </div>
  );
};

export default CardList;
