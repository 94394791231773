import React from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import pathaoLogo from "../../../src/assets/images/dashboard/pathao.png";
import redxLogo from "../../../src/assets/images/dashboard/redx.png";
import { useState } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

const AddDelivery = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Function to open the modal
    const openModal = () => {
        setIsModalOpen(true);
    };

    // Function to close the modal
    const closeModal = () => {
        setIsModalOpen(false);
    };
    useEffect(() => {
        openModal()

    }, []);

    return (
        <div>
            <Breadcrumb
                title='Chose Delivery partner'
                parent='Setting'
            />
            <div>
                {/* <div className='btn btn-dark ms-10' style={{
                    marginLeft:"400px",
                    marginTop:"200px"
            }} onClick={openModal}>
                    Chose Your Delivery Method
                </div> 
            </div> */}
                <section style={{
                    marginTop: "70px",
                }
                }>
                    <h4 className="text-black fs-3 text-center fw-bold">
                        Select Your Delivery Method
                    </h4>
                    <div className="d-flex justify-content-center gap-3 align-items-start mt-5">
                        <div
                            className="border rounded-2 px-4 py-2 c-pos-button8"
                        >
                            <Link to={'/AddDeliveryPartner/PathaoDelivery'}>
                                <img
                                    src={pathaoLogo}
                                    style={{ width: "115px", height: "40px", objectFit: "cover" }}
                                    alt=""
                                />
                            </Link>
                        </div>
                        <div className="border rounded-2 px-4 py-2">
                            <img
                                src={redxLogo}
                                className="disabled"
                                style={{ width: "110px", height: "40px", objectFit: "cover" }}
                                alt=""
                            />
                        </div>
                    </div>
                </section>
            </div>


        </div>
    )
}

export default AddDelivery;

/**
 <Modal
                isOpen={isModalOpen}
                size="lg"
                style={{ maxWidth: '50%' }}
            >
                <ModalHeader
                    className="d-flex justify-content-center align-items-center"
                    style={{
                        backgroundImage: 'linear-gradient(to right, #4b0ab3, #090080)',
                    }}
                >
                    <p className="text-white w-100 m-0 fw-bold fs-5">Make Delivery</p>
                </ModalHeader>
                <ModalBody>
                    <section>
                        <h4 className="text-black fs-3 text-center fw-bold">
                            Select Your Delivery Method
                        </h4>
                        <div className="d-flex justify-content-center gap-3 align-items-start mt-5">
                            <div
                                className="border rounded-2 px-4 py-2 c-pos-button8"
                            >
                                <img
                                    src={pathaoLogo}
                                    style={{ width: "115px", height: "40px", objectFit: "cover" }}
                                    alt=""
                                />
                            </div>
                            <div className="border rounded-2 px-4 py-2">
                                <img
                                    src={redxLogo}
                                    className="disabled"
                                    style={{ width: "110px", height: "40px", objectFit: "cover" }}
                                    alt=""
                                />
                            </div>
                        </div>
                    </section>
                </ModalBody>
                <div className="modal-footer border-0">
                    <Button color="secondary" onClick={closeModal}>
                        Close
                    </Button>
                </div>
            </Modal>

 */