import React, { useState } from 'react'
import { Calendar, DateRangePicker } from 'react-date-range'
import { Button } from 'reactstrap';
import { BsCalendar3 } from 'react-icons/bs';
import moment from 'moment';


const SingleCalender = ({ isCalenderOpen, date, setCalenderOpen, setDate }) => {
    const dateSelect = (item) => {
        setDate(item)
        setCalenderOpen(false)

    }

    return (
        <div className='col-12'>

            <Button className='col-12 borrder-2 d-flex justify-content-around  ' onClick={() => setCalenderOpen(x => !x)} > <span className='border-end pe-3'><BsCalendar3 /></span>
                <span className=''>
                    {!date ? moment().format('DD/MMMM/YYYY') : moment(date, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').format('DD/MMMM/YYYY')}
                </span>
            </Button>
            {
                isCalenderOpen && <Calendar date={date} onChange={item => dateSelect(item)} className="position-absolute top-1 z-3" />
            }
        </div>

    )
}

export default SingleCalender