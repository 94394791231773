import React, { useEffect } from 'react';
import { useState } from 'react';
import SalesListTableSection from '../../../SalesManagement/SalesList/SalesListTableSection';

const DeliveriesLIstTableSection = () => {
  const salesTable = [
    {
      id: 10,
      name: 'SL',
    },
    {
      id: 11,
      name: 'Cons. ID',
    },
    {
      id: 12,
      name: 'Order ID',
    },
    {
      id: 13,
      name: 'Store',
    },
    {
      id: 14,
      name: 'Recipient Info',
    },
    {
      id: 15,
      name: 'Delivery Status',
    },
    {
      id: 16,
      name: 'Item Description',
    },
    {
      id: 17,
      name: 'Amount',
    },
    {
      id: 18,
      name: 'Payment',
    },
    {
      id: 19,
      name: 'Action',
    },
  ];

  // pathao store data token
  const [deliveryListData, setDeliveryListData] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const accessToken = localStorage.getItem('pathaoAccess_Token');
  // data get from backend
  // get store from pathao store

  useEffect(() => {
    setLoading(true);
    fetch('https://fancymalabis.apodigi.com/pathao/fetchOrders', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setDeliveryListData(data?.data?.data);
        setLoading(false);
      })
      .catch(() => {});
  }, [accessToken]);


  return (
    <>
      {/* section-3 */}
      <section
        className='mx-4 p-2 border rounded-2 bg-body-secondary '
        style={{ height: '437px', overflowY: 'scroll', overflowX: 'hidden' }}>
        <SalesListTableSection
          showTable='deliveryList'
          tableHeader={salesTable}
          tableData={deliveryListData}
          isLoading={isLoading}

          //   tableData={
          //     selectedValue !== "allData" ? data?.slice(0, selectedValue) : data
          //   }
        ></SalesListTableSection>
      </section>
    </>
  );
};

export default DeliveriesLIstTableSection;
