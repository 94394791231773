




import { FaBuilding } from "react-icons/fa";
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Card, Container, FormGroup, FormText, Label, Row, Table } from 'reactstrap';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Link } from 'react-router-dom';
import TableLoading from '../../../components/common/loading/TableLoading';
import Papa from 'papaparse';
import axios from '../../../util/axiosInstance';
import PosDropdownSection from "../../SalesManagement/Pos/PosDropdownSection";
import useGetData from "../../../hooks/useGetData";
import { CustomToast } from "../../../hooks/useCustomToast";



const AttendanceSheet = () => {


 // get data from backend
 // const [attendanceData, setAttendanceData] = useState([])

 const [attendanceData, attendanceDataRefetch, isLoading] = useGetData(
  "/customer/allattendance/",
  "branch"
 );


 // useEffect(() => {
 //  axios.get('/branch/viewbranch')
 //   .then(result => {
 //    setAttendanceData(result.data)
 //   })
 // }, [])

 // const date = attendanceData.length > 0 ? attendanceData[0].created_date : '';


 return (
  <Fragment>
   <Breadcrumb
    title='Attendance Sheet'
    parent='Contact Management'
   />
   <Container fluid={true}>
     <Card className='p-2'>

      <div class="container mt-5">
       <h3 class="text-center fw-bold" style={{color:'#0D0D44'}}>Monthly Attendance Sheet</h3>
       <div
        style={{
         backgroundColor: '#F2F2F2',
         marginTop: '15px',
         borderRadius: '5px',
         padding: '15px',
         maxHeight: '400px', overflowY: 'auto', maxWidth: '1300px', overflowX: 'auto' }}
       >
        <Table
         Table
         bordered
         hover
         striped
        >
         <thead className='text-center'>
          <tr>
           <th scope="col">Employee Name</th>
           <th scope="col" colspan="7">Week 1</th>
           <th scope="col" colspan="7">Week 2</th>
           <th scope="col" colspan="7">Week 3</th>
           <th scope="col" colspan="7">Week 4</th>
          </tr>
          <tr>
           <th></th>
           {/* <!-- Days of Week for Week 1 --> */}
           <th>Day 1</th>
           <th>Day 2</th>
           <th>Day 3</th>
           <th>Day 4</th>
           <th>Day 5</th>
           <th>Day 6</th>
           <th>Day 7</th>
           {/* <!-- Days of Week for Week 2 --> */}
           <th>Day 1</th>
           <th>Day 2</th>
           <th>Day 3</th>
           <th>Day 4</th>
           <th>Day 5</th>
           <th>Day 6</th>
           <th>Day 7</th>
           {/* <!-- Days of Week for Week 3 --> */}
           <th>Day 1</th>
           <th>Day 2</th>
           <th>Day 3</th>
           <th>Day 4</th>
           <th>Day 5</th>
           <th>Day 6</th>
           <th>Day 7</th>
           {/* <!-- Days of Week for Week 4 --> */}
           <th>Day 1</th>
           <th>Day 2</th>
           <th>Day 3</th>
           <th>Day 4</th>
           <th>Day 5</th>
           <th>Day 6</th>
           <th>Day 7</th>
          </tr>
         </thead>
         <tbody className='text-center' style={{ maxHeight: '400px', overflowY: 'auto', overflowX: 'auto' }}>
          <tr>
           <td>John Doe</td>
           <td class="present">Attend</td>
           <td class="absent">Not Attend</td>
           <td class="present">Attend</td>
           <td class="present">Attend</td>
           {/* <!-- Add more days for Week 1 --> */}
           <td class="absent">Not Attend</td>
           <td class="absent">Not Attend</td>
           <td class="present">Attend</td>
           <td class="present">Attend</td>
           <td class="present">Attend</td>
           {/* <!-- Add more days for Week 2, Week 3, and Week 4 --> */}
          </tr>
          <tr>
           <td>Jane Smith</td>
           {/* <!-- Attendance data for Jane Smith --> */}
           {/* <!-- Add more days for Week 1, Week 2, Week 3, and Week 4 --> */}
          </tr>
          {/* <!-- Add more employees and their attendance data as needed -->7 */}
         </tbody>
        </Table>
       </div>
      </div>

     </Card>
    </Container>
  </Fragment>
 );
};

export default AttendanceSheet;