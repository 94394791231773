import React, { useEffect, useState } from 'react';

import ListAccounts from '../../../components/accounts/ListAccounts';

const AccountsList = () => {
  return (
    <div>
      <ListAccounts />
    </div>
  );
};

export default AccountsList;
