import React, { Fragment } from 'react';
import { Card, CardBody, CardHeader, Container, Row } from 'reactstrap';
import Breadcrumb from '../../components/common/breadcrumb';


const FinancialLayout = () => {

 return (
  <Fragment>
   <Breadcrumb title="Financial Layout" parent="Financial " />
   <Container fluid={true}>
    <Row>
     <Card>
      <CardHeader>
      FINANCIAL 
      </CardHeader>
      <CardBody>
       FinancialLayout bdoy
      </CardBody>
     </Card>

    </Row>
   </Container>

  </Fragment>
 );
};

export default FinancialLayout;