import { configureStore } from "@reduxjs/toolkit";
import categoriesSlice from '../features/category/categorySlice';
import userReducer from '../features/auth/userSlices';
import customerSlices from '../features/customer/customerSlice';
import productsSlice from '../features/products/productsSlice';
import salesSlice from '../features/sales/salessSlice';

/* Slices*/

import { apiSlice } from '../features/api/apiSlice';

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    categories: categoriesSlice,
    user: userReducer,
    customers: customerSlices,
    products: productsSlice,
    sales: salesSlice,
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddlewares) =>
    getDefaultMiddlewares().concat(apiSlice.middleware),
});
