


import React from 'react';
import { Fragment } from 'react';
import { Card, Container, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, UncontrolledDropdown } from 'reactstrap';
import Breadcrumb from '../../../components/common/breadcrumb';
import useGetData from '../../../hooks/useGetData';
import { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { CustomToast } from '../../../hooks/useCustomToast';
import axios from '../../../util/axiosInstance';
import { useEffect } from 'react';

const EditUserList = () => {
 const navigate = useNavigate();
 const { id } = useParams();
 const [getDataById, setGetDataById] = useState({})
const [isSubmitting, setIsSubmitting] = useState(false);

 useEffect(() => {
  axios.get(`/users/user-view/${id}`).then(res => setGetDataById(res.data))
}, [])

 const [brachMethod, setBrachMethod] = useState(getDataById?.warehouse_name);
 const [branchId, setBranchId] = useState();
 const [roleMethod, setRoleMethod] = useState(getDataById?.role_name);
 const [roleId, setRoleId] = useState();
 const [userName, setUserName] = useState();
 const [firstName, setFirstName] = useState('');
 const [lastName, setLastName] = useState('');
 const [mobile, setMobile] = useState('');
 const [email, setEmail] = useState('');
 const [password, setPassword] = useState('');
 const [confirmPassword, setConfirmPassword] = useState('');

 
 const [branchData] = useGetData(
  '/branch/viewbranch/',
  'branchData'
 );

 const [roleData] = useGetData(
  '/role/view-roles',
  'roleData'
 );



 const userSubmit = (e) => {
  e.preventDefault();
  const userData = {
   "username": userName || getDataById?.username,
   "first_name": firstName || getDataById?.first_name,
   "last_name": lastName || getDataById?.last_name,
   "password": confirmPassword || getDataById?.password,
   "mobile": mobile || getDataById?.mobile,
   "email": email || getDataById?.email,
   // "address": "123 Main Street",
   "role_name": roleMethod || getDataById?.role_name,
   "role_id": roleId || getDataById?.role_id,
   "warehouse_id": branchId || getDataById?.warehouse_id,
  }

  // Send a put request
  axios.put(`/users/update-user/${id}`, userData, )
   .then(response => {
    navigate('/users-list')
    CustomToast('success', 'Data Update successfully');
    
   })
   .catch(error => {
    CustomToast('error', 'Error saving data.');
   });


 };


 return (
  <>
   <Fragment>
    <Breadcrumb title="Edit User " parent="Users" />
    <Container fluid={true}>
     <Card className='p-2'>

      <div className="col-md-6 col-sm-12">
       <FormGroup className='d-flex justify-content-center align-items-center w-100 '>
        <label htmlFor='firstName' className='fw-bold  text-center' style={{ width: '200px' }}>
         User Name<span className="text-danger">*</span>
        </label>
        <input
         defaultValue={getDataById?.username}
         type='text'
         onChange={(e) => setUserName(e.target.value)}
         className='form-control'
        />
       </FormGroup>
      </div>
      <div className="col-md-6 col-sm-12">
       <FormGroup className='d-flex justify-content-center align-items-center w-100 '>
        <label htmlFor='firstName' className='fw-bold  text-center' style={{ width: '200px' }}>
         First Name<span className="text-danger">*</span>
        </label>
        <input
         defaultValue={getDataById?.first_name}
         type='text'
         onChange={(e) => setFirstName(e.target.value)}
         className='form-control'
        />
       </FormGroup>
      </div>

      <div className="col-md-6 col-sm-12 ">
       <FormGroup className='d-flex justify-content-center align-items-center w-100 '>
        <label htmlFor='firstName' className='fw-bold  text-center' style={{ width: '200px' }}>
         Last Name<span className="text-danger">*</span>
        </label>
        <input
         defaultValue={getDataById?.last_name}
         type='text'
         onChange={(e) => setLastName(e.target.value)}
         className='form-control'
        />
       </FormGroup>
      </div>

      <div className="col-md-6 col-sm-12 ">
       <FormGroup className='d-flex justify-content-center align-items-center w-100 '>
        <label htmlFor='firstName' className='fw-bold  text-center' style={{ width: '200px' }}>
         Mobile
        </label>
        <input
         type='number'
         step={'any'}
         defaultValue={getDataById?.mobile}
         onChange={(e) => setMobile(e.target.value)}
         className='form-control'
        />
       </FormGroup>
      </div>

      <div className="col-md-6 col-sm-12 ">
       <FormGroup className='d-flex justify-content-center align-items-center w-100 '>
        <label htmlFor='firstName' className='fw-bold  text-center' style={{ width: '200px' }}>
         Email<span className="text-danger">*</span>
        </label>
        <input
         defaultValue={getDataById?.email}
         type='email'
         onChange={(e) => setEmail(e.target.value)}
         className='form-control'
        />
       </FormGroup>
      </div>

      <div className="col-md-6 col-sm-12 ">
       <FormGroup className='d-flex justify-content-center align-items-center w-100 '>
        <label htmlFor='firstName' className='fw-bold  text-center' style={{ width: '200px' }}>
         Role<span className="text-danger">*</span>
        </label>

        <UncontrolledDropdown className=" w-100">
         <DropdownToggle
          className="border w-100"
          caret
          color="white"
          style={{ backgroundColor: '#FFFFFF' }}
         >
         
          {/* {getDataById?.role_name} */}
          {roleMethod || getDataById?.role_name}
         </DropdownToggle>
         <DropdownMenu
          className="text-center w-100">
          {
           roleData?.map(data => (
            <DropdownItem
             key={data.id}
             onClick={(e) => {
              setRoleMethod(data.role_name);
              setRoleId(data.id);
             }}
            >
             {data?.role_name}
            </DropdownItem>
           ))
          }
         </DropdownMenu>
        </UncontrolledDropdown>

       </FormGroup>
      </div>

      {/* <div className="col-md-6 col-sm-12 ">
       <FormGroup className='d-flex justify-content-center align-items-center w-100 '>
        <label htmlFor='firstName' className='fw-bold  text-center' style={{ width: '200px' }}>
         Old Password<span className="text-danger">*</span>
        </label>
        <input
         defaultValue={(getDataById?.password)}
         type='text'
         onChange={(e) => setPassword(e.target.value)}
         className='form-control'
        />
       </FormGroup>
      </div> */}

      <div className="col-md-6 col-sm-12 ">
       <FormGroup className='d-flex justify-content-center align-items-center w-100 '>
        <label htmlFor='firstName' className='fw-bold  text-center' style={{ width: '200px' }}>
         New Password<span className="text-danger">*</span>
        </label>
        <input
         type='password'
         onChange={(e) => setConfirmPassword(e.target.value)}
         className='form-control'
        />
       </FormGroup>
      </div>

      <div className="col-md-6 col-sm-12 ">
       <FormGroup className='d-flex justify-content-center align-items-center w-100 '>
        <label htmlFor='firstName' className='fw-bold  text-center' style={{ width: '200px' }}>
         Branch <span className="text-danger">*</span>
        </label>
        <UncontrolledDropdown className=" w-100">
         <DropdownToggle
          className="border w-100"
          caret
          color="white"
          style={{ backgroundColor: '#FFFFFF' }}
         >
          {brachMethod || getDataById?.warehouse_name}
         </DropdownToggle>
         <DropdownMenu
          className="text-center w-100">
          {
           branchData?.map(data => (
            <DropdownItem
             key={data.id}
             onClick={(e) => {
              setBrachMethod(data.warehouse_name);
              setBranchId(data.id);
             }}
            >
             {data?.warehouse_name}
            </DropdownItem>
           ))
          }
         </DropdownMenu>
        </UncontrolledDropdown>
       </FormGroup>
      </div>

      <div className="col-md-12 d-flex justify-content-center align-items-center gap-5 mt-3">
       <input disabled={isSubmitting} onClick={userSubmit} type="Submit" value={'Update'} style={{ backgroundColor: '#00A65A', border: 'none', padding: '4px 30px', borderRadius: '5px', color: 'white' }} />
       <Link to={'/dashboard'} style={{ backgroundColor: '#F39C12', textDecoration: 'none', padding: '4px 30px', borderRadius: '5px', color: 'white' }}>Close</Link>
      </div>

     </Card>
    </Container>
   </Fragment>
  </>
 );
};

export default EditUserList;