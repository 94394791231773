import moment from "moment/moment";
import React from "react";
import { GiWorld } from "react-icons/gi";
const ViewPurchaseInvoice = ({ data }) => {
  const {
    warehouse_name,
    warehouse_address,
    mobile,
    email,
    supplier_name,
    address,
    phone,
    created_date,
    purchase_code,
    reference_no,
    purchase_date,
  } = data || {};

  console.log({
    warehouse_name,
    warehouse_address,
    mobile,
    email,
    supplier_name,
    address,
    phone,
    created_date,
    purchase_code,
    reference_no,
    purchase_date,
  });
  return (
    <>
      <section className="d-flex justify-content-between align-items-center border-bottom py-2">
        <div className="d-flex gap-1 align-items-center fs-4">
          <GiWorld></GiWorld>
          Purchase Invoice
        </div>
        <div>
          <span className="fw-700">Date:</span>{" "}
          {moment(created_date).format("YYYY-MM-DD hh:mm:ss A")}
        </div>
      </section>
      <section className="my-4">
        {/* branch information */}
        <h4
          style={{ color: "rgb(9, 61, 134)" }}
          className="fw-700 cs-text-decoration-underline"
        >
          BRANCH INFORMATION
        </h4>
        <div className="d-flex justify-content-between align-items-start">
          <div className="col-6">
            <p className="m-0 text-black fw-800 fs-6">{warehouse_name}</p>
            <p className="m-0 text-black fw-500 fs-6">
              {warehouse_address || "Address"}
            </p>
            <p className="m-0 text-black fw-500 fs-6"> {mobile}</p>
            <p className="m-0 text-black fw-500 fs-6">Email: {email}</p>
          </div>
          <div className="col-6">
            <p className="m-0 text-black fw-500 fs-6">
              Invoice:
              {purchase_code}
            </p>
            <p className="m-0 text-black fw-500 fs-6">
              Reference No: {reference_no}
            </p>
            <p className="m-0 text-black fw-500 fs-6">
              Purchase Date: {purchase_date?.slice(0, 10) || "-"}
            </p>
          </div>
        </div>
      </section>
      <section className="">
        {/* customer information */}
        <div className="d-flex justify-content-between align-items-start">
          <div className="col-6 ">
            <h4
              style={{ color: "rgb(9, 61, 134)" }}
              className="fw-700 cs-text-decoration-underline"
            >
              SUPPLIER INFORMATION
            </h4>
            <p className="m-0 text-black fw-800 fs-6">{supplier_name}</p>
            <p className="m-0 text-black fw-500 fs-6">Address: {address}</p>
            <p className="m-0 text-black fw-500 fs-6">Phone: {phone}</p>
          </div>
        </div>
      </section>
    </>
  );
};

export default ViewPurchaseInvoice;
