
import LoadingBar from 'react-top-loading-bar'
import { Fragment, useEffect, useState } from "react";
import { Card, CardBody, Col, Container, FormGroup, Input, Label, } from "reactstrap";

import Breadcrumb from "../../../components/common/breadcrumb";
import { BsPlus } from "react-icons/bs";
import BrandModal from "./Modal/BrandModal";
import CategoryModal from "./Modal/CategoryModal";
import axios from '../../../util/axiosInstance';
import { Link, useNavigate } from 'react-router-dom';

import ItemGroupModal from './Modal/ItemGroupModal';
import UnitModal from './Modal/UnitModal';
import { ToastContainer,  } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useGetData from '../../../hooks/useGetData';
import { CustomToast } from '../../../hooks/useCustomToast';



const AddItem = () => {
const vats = [
    {
        id: 1,
        vat_name: '5'
    },
    {
        id: 2,
        vat_name: '10'
    },
    {
        id: 3,
        vat_name: '20'
    }
]

    const navigate = useNavigate();

    const [brandList, setBrandList] = useState([])//main brand list data
    const [categoryList, setCategoryList] = useState([]) //main category lista data 
    const [unitList, setUnitList] = useState([]) //main unit lista data 
    const [branchList, setBranchList] = useState([]) //main branch lista data 
    const [vatList , setVatList] = useState([]) // main vat list
    const [isChecked, setIsChecked] = useState(false);  // State to track checkbox


    const [defaultBrandValue, setDefaultBrandValue] = useState('');

    const [brandDropdown, setBrandDropdown] = useState('')
    const [categoryDropdown, setCategoryDropdown] = useState('')
    const [unitDropdown, setUnitDropdown] = useState('')

    const [branchDropdown, setBranchDropdown] = useState('')
    const [branchDropdownId, setBranchDropdownId] = useState('')
    const [vatDropdownId, setVatDropdownId] = useState('')
    
    console.log(vatDropdownId);

    const branchHandler = (id, name) => {

        setBranchDropdown(name);
        setBranchDropdownId(id);
    }


    const [progress, setProgress] = useState(100)
    const [isLoading, setIsLoading] = useState(false);

    // brand list effect 
    useEffect(() => {
        setIsLoading(true);
        axios.get('/brand/viewbrands/')
            .then(data => {
                setBrandList(data.data);
                setIsLoading(false);  // Stop loading
            })
            .catch(error => {
                // setError(error);
                setIsLoading(false);  // Stop loading
            });

    }, [brandDropdown, defaultBrandValue])

    // category list effect 
    useEffect(() => {
        setIsLoading(true);

        axios.get('/category/viewcategories/')
            .then(data => {
                setCategoryList(data.data);
                setIsLoading(false);  // Stop loading
            })
            .catch(error => {
                // setError(error);
                setIsLoading(false);  // Stop loading
            });

    }, [categoryDropdown])

    // unit list effect 
    useEffect(() => {
        setIsLoading(true);

        axios.get('/unit/viewunits/')
            .then(data => {
                setUnitList(data.data);
                setIsLoading(false);  // Stop loading
            })
            .catch(error => {
                // setError(error);
                setIsLoading(false);  // Stop loading
            });

    }, [unitDropdown])

    // vat list effect 
    // useEffect(() => {
    //     setIsLoading(true);

    //     axios.get(vats)
    //         .then(data => {
    //             setVatList(data?.data);
    //             setIsLoading(false);  // Stop loading
    //         })
    //         .catch(error => {
    //             // setError(error);
    //             setIsLoading(false);  // Stop loading
    //         });

    // }, [vatDropdownId])

    // Branch list effect 
    useEffect(() => {
        setIsLoading(true);

        axios.get('/branch/viewbranch')
            .then(data => {
                setBranchList(data.data);
                setIsLoading(false);  // Stop loading
            })
            .catch(error => {
                // setError(error);
                setIsLoading(false);  // Stop loading
            });

    }, [branchDropdown,])


    const [branchDatafromData, branchDataRefetch] = useGetData('/branch/viewbranch', 'branchDatafromData');




    // brand modal 
    const [brandModal, setBrandModal] = useState(false);
    const handleBrandClick = () => {
        setBrandModal(!brandModal)
    }

    const handleBrandSubmit = (e) => {
        e.preventDefault();
        const form = e.target;
        const brand_name = form.brand_name.value;
        const description = form.description.value;
        const status = form.status.value;

        const data = { brand_name, description, status }
        setDefaultBrandValue(brand_name);

        axios.post('/brand/addbrand', data)
            .then(data => {
                if (data.status === 201) {

                }

                // setCategoryDropdown(data.data)

            })
            .catch(err => {
            })

        handleBrandClick()
    }

    // category modal start 
    const [categoryModal, setCategoryModal] = useState(false);
    const handleCategoryClick = () => {
        setCategoryModal(!categoryModal)


    }

    const handleCategorySubmit = (e) => {
        e.preventDefault();
        const form = e.target;
        const category_name = form.category_name.value;
        const description = form.description.value;
        const status = form.status.value;
        const data = { category_name, description, status }

        axios.post('/category/addcategory', data)
            .then(data => {

                setCategoryDropdown(data.data)

            })
            .catch(err => {
            })

        handleCategoryClick()
    }

    // item group modal start 

    const [itemModal, setItemModal] = useState(false);
    const handleItemClick = () => {
        setItemModal(!itemModal)

    }

    const handleItemSubmit = (e) => {
        e.preventDefault();
        const form = e.target;
        const item_name = form.item_name.value;
        const description = form.description.value;
        const data = { item_name, description }


        handleItemClick()
    }

    // unit modal start 
    const [unitModal, setUnitModal] = useState(false);
    const handleUnitClick = () => {
        setUnitModal(!unitModal)

    }

    const handleUnitSubmit = (e) => {
        e.preventDefault();
        const form = e.target;
        const unit_name = form.unit_name.value;
        const description = form.description.value;
        const status = form.status.value;
        const data = { unit_name, description, status }

        axios.post('/unit/addunit', data)
            .then(data => {

                setUnitDropdown(data.data)

            })
            .catch(err => {
            })

        handleUnitClick()
    }

    function generateRandomNumber(length) {
        let result = '';
        const characters = '0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charactersLength);
            result += characters.charAt(randomIndex);
        }
        return result;
    }

    const randomBarcode = generateRandomNumber(13);
    

    const [item_name, setItemName] = useState('');
    const [sku, setSku] = useState();
    const [date, setDate] = useState('');
    const [alertQuantity, setAlertQuantity] = useState();
    const [barcode, setBarcode] = useState();
    const [description, setDescription] = useState();
    const [imageFiles, setImageFiles] = useState([]);
    const [stock, setStock] = useState()
    const [purchasePrice, setPurchasePrice] = useState('')
    const [salesPrice, setSalesPrice] = useState('')

    const validateFields = () => {
        const fields = [
            { name: "Item name", value: item_name },
            { name: "Brand name", value: brandDropdown },
            { name: "Category name", value: categoryDropdown },
            { name: "Unit name", value: unitDropdown },
            // { name: "SKU", value: sku },
            // { name: "Date", value: date },
            // { name: "Alert Quantity", value: alertQuantity },
            // { name: "Barcode", value: barcode },
            // { name: "Description", value: description },
            { name: "Branch name", value: branchDropdownId },

        ];
        const fieldsChecked = [
            { name: "Stock", value: stock },
            { name: "Purchase Price", value: purchasePrice },
            { name: "Sales Price", value: salesPrice },
            // { name: "Warehouse ID", value: branchDropdown },
        ]

        for (let field of fields) {
            if (!field.value || field.value === '') {

                // alert(`Please enter a value for "${field.name}"`);
                CustomToast('error', `${field.name}`);
                return false;
            }
        }
        for (let field of fieldsChecked) {
            if (isChecked) {
                if (!field.value || field.value === '') {

                    // alert(`Please enter a value for "${field.name}"`);
                    CustomToast('error', `${field.name}`);
                    return false;
                }
            }
        }
        return true;
    };

    const handleAddItemSubmit = (e) => {
        const form = e.target;
        e.preventDefault();
        if (!validateFields()) {
            return;
        }
        const formData = new FormData();

        formData.append('item_name', item_name);
        formData.append('brand_name', brandDropdown);
        formData.append('category_name', categoryDropdown);
        formData.append('unit_name', unitDropdown);
        formData.append('sku', sku);
        formData.append('expire_date', date);
        formData.append('alert_qty', alertQuantity);
        formData.append('description', description)
        formData.append('stock', stock);
        formData.append('custom_barcode', barcode ? barcode : randomBarcode);
        formData.append('warehouse_id', branchDropdown); // warehouse id
        formData.append('warehouse_name', branchDropdownId);  // warehouse name
        formData.append('purchase_price', purchasePrice);
        formData.append('sales_price', salesPrice);


        axios.post('/item/additem2/', formData, {
            headers: { "Content-type": "application/json" },
        })  // TODO : change api endpoint
            .then(res => {
                if (res.status === 201 || res.status === 200 || res.status === 202) {
                    navigate('/product-management/items-list')
                    CustomToast('success', 'Item Add Successfully');
                }
            })
            .catch(error => {
                // toast.error("Something Wrong")
                CustomToast('error', 'Something Wrong');
            })
    }

    return (
        <>
            {/* loader  */}
            {
                isLoading && (
                    <LoadingBar color="red" style={{}}
                        progress={progress}
                        onLoaderFinished={() => setProgress(0)}
                    />
                )
            }
            <Fragment>
                <Breadcrumb title="Add Item " parent="Product Management" />
                <Container fluid={true}>
                    <Card>
                        <CardBody>
                            <div className="d-flex gap-2">
                                <div className="col-md-4 col-sm-12">
                                    <FormGroup>
                                        <Label for="exampleEmail" className='fw-bold'>
                                            Item Name <span className="text-danger">*</span>
                                        </Label>
                                        <input
                                            className='form-control'
                                            id="exampleEmail"
                                            onChange={(e) => setItemName(e.target.value)}
                                            type="text"
                                            required
                                        />
                                    </FormGroup>
                                </div>

                                <div className="col-md-4 col-sm-12">
                                    <FormGroup>
                                        <Label for="exampleEmail" className='fw-bold'>
                                            Brand
                                        </Label>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <select className="p-2 w-100 rounded-2 border" onClick={(e) => setBrandDropdown(e.target.value)}>
                                                <option>Select Brand </option>
                                                {
                                                    defaultBrandValue && (
                                                        <option value={defaultBrandValue}>{defaultBrandValue}</option>
                                                    )
                                                }
                                                {
                                                    brandList?.slice().sort((a, b) => a?.brand_name?.localeCompare(b?.brand_name))?.map(brand => (
                                                        <option key={brand?.id} value={brand?.brand_name}>{brand?.brand_name}</option>
                                                    ))
                                                }
                                            </select>

                                            <BsPlus onClick={handleBrandClick} size={35} className="border border-start-0 " style={{ marginLeft: '-4px', padding: '3px', height: '38px', cursor: 'pointer' }} />
                                        </div>

                                    </FormGroup>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <FormGroup>
                                        <Label for="exampleEmail" className='fw-bold'>
                                            Category
                                        </Label>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <select className="p-2 w-100 rounded-2 border" onClick={(e) => setCategoryDropdown(e.target.value)}>
                                                <option>Select Category </option>
                                                {
                                                    categoryList?.slice().sort((a, b) => a?.category_name?.localeCompare(b.category_name))?.map(category => (
                                                        <option key={category.id} value={category.category_name}>{category.category_name}</option>
                                                    ))
                                                }
                                            </select>

                                            <BsPlus onClick={handleCategoryClick} size={35} className="border border-start-0 " style={{ marginLeft: '-4px', padding: '3px', height: '38px', cursor: 'pointer' }} />
                                        </div>
                                    </FormGroup>
                                </div>
                            </div>

                            <div className="row">
                                {/* <div className="col-md-4 col-sm-12">
                                        <FormGroup>
                                            <Label for="exampleEmail" className='fw-bold'>
                                                Item Group
                                            </Label>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <select className="p-2 w-100 rounded-2 border" >
                                                    <option value="">Group Item 1</option>
                                                    <option value="">Group Item 1</option>
                                                </select>

                                                <BsPlus onClick={handleItemClick} size={35} className="border border-start-0 " style={{ marginLeft: '-4px', padding: '3px', height: '38px', cursor: 'pointer' }} />
                                            </div>

                                        </FormGroup>
                                    </div> */}

                                <div className="col-md-4 col-sm-12">
                                    <FormGroup>
                                        <Label for="exampleEmail" className='fw-bold'>
                                            Unit
                                        </Label>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <select className="p-2 w-100 rounded-2 border" onClick={(e) => setUnitDropdown(e.target.value)}>
                                                <option>Select Unit </option>
                                                {
                                                    unitList?.slice().sort((a, b) => a?.unit_name?.localeCompare(b.unit_name))?.map(unit => (
                                                        <option key={unit.id} value={unit.unit_name}>{unit.unit_name}</option>
                                                    ))
                                                }
                                            </select>

                                            <BsPlus onClick={handleUnitClick} size={35} className="border border-start-0 " style={{ marginLeft: '-4px', padding: '3px', height: '38px', cursor: 'pointer' }} />
                                        </div>

                                    </FormGroup>
                                </div>

                                <div className="col-md-4 col-sm-12">
                                    <FormGroup>
                                        <Label for="exampleEmail" className='fw-bold'>
                                            SKU <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            id="exampleEmail"
                                            name="email"
                                            onChange={(e) => setSku(e.target.value)}
                                            type="text"
                                        />
                                    </FormGroup>
                                </div>

                                <div className="col-md-4 col-sm-12">
                                    <FormGroup>
                                        <Label for="exampleEmail" className='fw-bold'>
                                            Expire Date <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            id="exampleEmail"
                                            name="date"
                                            onChange={(e) => setDate(e.target.value)}
                                            type="date"
                                        />
                                    </FormGroup>
                                </div>

                                <div className="col-md-4 col-sm-12">
                                    <FormGroup>
                                        <Label for="exampleEmail" className='fw-bold'>
                                            Alert Quantity
                                        </Label>
                                        <Input
                                            id="exampleEmail"
                                            name="alertQuantity"
                                            onChange={(e) => setAlertQuantity(e.target.value)}
                                            type="number"
                                        />
                                    </FormGroup>
                                </div>

                                <div className="col-md-4 col-sm-12">
                                    <FormGroup>
                                        <Label for="exampleEmail" className='fw-bold'>
                                            Barcode
                                        </Label>
                                        <Input
                                            defaultValue={randomBarcode}
                                            id="exampleEmail"
                                            name="barcode"
                                            onChange={(e) => setBarcode(e.target.value)}
                                            type="text"
                                        />
                                    </FormGroup>
                                </div>

                                <div className="col-md-4 col-sm-12">
                                    <FormGroup row>
                                        <Label
                                            for="exampleText" className='fw-bold'
                                        >
                                            Description
                                        </Label>
                                        <Col>

                                            <textarea
                                                name='description'
                                                type="textarea"
                                                onChange={(e) => setDescription(e.target.value)}
                                                className="form-control"
                                            />
                                        </Col>

                                    </FormGroup>
                                </div>

                                {/* // vat section  */}

                                <div className="col-md-4 col-sm-12">
                                    <FormGroup>
                                        <Label for="exampleEmail" className='fw-bold'>
                                            Vat 
                                        </Label>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <select className="p-2 w-100 rounded-2 border" onClick={(e) => setVatDropdownId(e.target.value)}>
                                                <option>Select Vat </option>
                                                {
                                                    vats?.slice().sort((a, b) => a?.vat_name?.localeCompare(b.vat_name))?.map(vat => (
                                                        <option key={vat.id} value={vat.vat_name}>{vat.vat_name}</option>
                                                    ))
                                                }
                                            </select>

                                           
                                        </div>

                                    </FormGroup>
                                </div>


                                {/* <div className="col-md-4 col-sm-12">

                                        <FormGroup>
                                            <Label for="exampleName" className='fw-bold'>
                                                Select Image
                                            </Label>
                                            <input required className="form-control"
                                                type='file'
                                                onChange={(e) => setImageFiles(e.target.files)}
                                            />
                                            <FormText >
                                                <span className='text-danger'>Max Width/Height: 1000px * 1000px & Size: 1MB</span>
                                            </FormText>
                                        </FormGroup>

                                    </div> */}

                                <hr />
                            </div>

                            <div className="row">
                                <div className="col-md-3 col-sm-12">
                                    <FormGroup>
                                        <Label for="exampleName" className="fw-bold d-flex gap-2 align-content-center">
                                            <div>Branch <span className="text-danger">*</span></div>
                                            <div className="form-check form-switch">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="customSwitch1"
                                                    checked={isChecked}
                                                    onChange={() => setIsChecked(!isChecked)}
                                                />
                                                {
                                                    !isChecked && (
                                                        <label className="form-check-label ms-2 fw-light" htmlFor="customSwitch1">
                                                            Do you want to add opening stock
                                                        </label>)
                                                }
                                            </div>
                                        </Label>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <select
                                                className="form-select p-2 w-100 rounded-2 border"
                                                onChange={(e) => branchHandler(e.target.value, e.target[e.target.selectedIndex].id)}
                                            >
                                                <option>Select Branch </option>
                                                {
                                                    branchList && branchList?.slice()
                                                        .sort((a, b) => a?.warehouse_name?.localeCompare(b.warehouse_name))
                                                        .map(branch => (
                                                            <option id={branch.id} key={branch.id} value={branch.warehouse_name}>
                                                                {branch.warehouse_name}
                                                            </option>
                                                        ))
                                                }
                                            </select>
                                        </div>
                                    </FormGroup>
                                </div>

                                <div className="col-md-3 mt-2 col-sm-12">
                                    {
                                        isChecked && (
                                            <FormGroup>
                                                <Label for="exampleName" className='fw-bold'>
                                                    Opening Stock <span className='text-danger'>*</span>
                                                </Label>
                                                <input className="form-control" type='number' step={'any'} defaultValue={0}
                                                    onChange={(e) => setStock(e.target.value)}
                                                />

                                            </FormGroup>
                                        )
                                    }
                                </div>
                                <div className="col-md-3 mt-2 col-sm-12">
                                    {
                                        isChecked && (
                                            <FormGroup>
                                                <Label for="exampleName" className='fw-bold'>
                                                    Purchase Price <span className='text-danger'>*</span>
                                                </Label>
                                                <input className="form-control" type='number' step={'any'} defaultValue={0}
                                                    onChange={(e) => setPurchasePrice(e.target.value)}
                                                />

                                            </FormGroup>
                                        )
                                    }
                                </div>
                                <div className="col-md-3 mt-2 col-sm-12">
                                    {
                                        isChecked && (
                                            <FormGroup>
                                                <Label for="exampleName" className='fw-bold'>
                                                    Sales Price <span className='text-danger'>*</span>
                                                </Label>
                                                <input className="form-control" type='number' step={'any'} defaultValue={0}
                                                    onChange={(e) => setSalesPrice(e.target.value)}
                                                />

                                            </FormGroup>
                                        )
                                    }
                                </div>
                                <hr />
                            </div>

                            <div className="row">
                                <div className='col-md-12 d-flex align-items-center justify-content-center mt-5 gap-5'>
                                    <input onClick={handleAddItemSubmit} type="submit" style={{ backgroundColor: '#00A65A', border: 'none', color: 'white', padding: '6px 28px', borderRadius: '5px' }} />
                                    <Link to={'/dashboard'} style={{ backgroundColor: '#F39C12', border: 'none', color: 'white', padding: '6px 28px', borderRadius: '5px', textDecoration: 'none' }}>Close</Link>
                                </div>
                            </div>

                        </CardBody>
                    </Card>
                </Container>
                <ToastContainer />
            </Fragment>
            {/* modal here  */}
            {
                brandModal && (
                    <BrandModal setBrandDropdown={setBrandDropdown} handleBrandClick={handleBrandClick} BrandModal={BrandModal} setBrandModal={setBrandModal} handleBrandSubmit={handleBrandSubmit} />
                )
            }
            {/* category modal  */}
            {
                categoryModal && (
                    <CategoryModal handleCategoryClick={handleCategoryClick} categoryModal={categoryModal} setCategoryModal={setCategoryModal} handleCategorySubmit={handleCategorySubmit} />
                )
            }
            {
                itemModal && (
                    <ItemGroupModal handleItemClick={handleItemClick} itemModal={itemModal} setItemModal={setItemModal} handleItemSubmit={handleItemSubmit} />
                )
            }
            {
                unitModal && (
                    <UnitModal handleUnitClick={handleUnitClick} unitModal={unitModal} setUnitModal={setUnitModal} handleUnitSubmit={handleUnitSubmit} />
                )
            }
        </>
    );
};

export default AddItem;
