
import { Fragment, useEffect, useState, } from "react";
import { Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Label } from "reactstrap";
import Breadcrumb from "../../../components/common/breadcrumb";
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from "../../../util/axiosInstance";
import useGetData from "../../../hooks/useGetData";

const ItemListEdit = () => {
    const { id } = useParams();

    // Item Edit start here 
    const [editableData, setEditableData] = useState({})

    const dateObj = new Date(editableData.expire_data);

    // Extract date components
    const year = dateObj.getFullYear();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed in JS
    const datee = dateObj.getDate().toString().padStart(2, '0');

    // Construct the desired formatted string
    const formattedDate = `${month}/${datee}/${year}`;


    // Item Edit end here 
    const navigate = useNavigate();

    const [brandList, setBrandList] = useState([editableData.brand_name])//main brand list data
    const [categoryList, setCategoryList] = useState([]) //main category lista data 
    const [unitList, setUnitList] = useState([]) //main unit lista data 
    const [branchList, setBranchList] = useState([]) //main branch lista data 


    const [warehouseId, setWarehouseId] = useState(null);
    const [defaultBrandValue, setDefaultBrandValue] = useState('');

    const [brandDropdown, setBrandDropdown] = useState('')

    const [categoryDropdown, setCategoryDropdown] = useState()
    const [unitDropdown, setUnitDropdown] = useState('')

    const [branchDropdown, setBranchDropdown] = useState()


    const [progress, setProgress] = useState(100)
    const [isLoading, setIsLoading] = useState(false);


    // single item view editable  value start here 
    const [item_name, setItemName] = useState('');
    const [sku, setSku] = useState();
    const [date, setDate] = useState('');
    const [alertQuantity, setAlertQuantity] = useState();
    const [barcode, setBarcode] = useState();
    const [description, setDescription] = useState();
    const [purchasePrice, setPurchasePrice] = useState('')

    const [salesPrice, setSalesPrice] = useState('')
    // const [imageFiles, setImageFiles] = useState([]);

    // const [warehouse, setWarehouse] = useState();

    const [stock, setStock] = useState()

    // TODO need to set warehouse id 
    useEffect(() => {
        axios.get(`/item/viewitem/${id}`)
            .then(data => {
                setEditableData(data?.data);
                setItemName(data?.data?.item_name);
                setSku(data?.data?.sku);
                setAlertQuantity(data?.data?.alert_qty);
                setBarcode(data?.data?.custom_barcode);
                setDescription(data?.data?.description);
                setStock(data?.data?.stock);
                setBrandDropdown(data?.data?.brand_name);
                setCategoryDropdown(data?.data?.category_name)
                setUnitDropdown(data?.data?.unit_name)
                setBranchDropdown(data?.data?.warehouse_name);
                setPurchasePrice(data?.data?.purchase_price);
                setSalesPrice(data?.data?.sales_price);

                setWarehouseId(data?.data?.warehouse_id);
            }).catch(error => { })
    }, [])



    // brand list effect 
    useEffect(() => {
        setIsLoading(true);
        axios.get('/brand/viewbrands/')
            .then(data => {
                setBrandList(data.data);
                setIsLoading(false);  // Stop loading
            })
            .catch(error => {
                // setError(error);
                setIsLoading(false);  // Stop loading
            });

    }, [brandDropdown, defaultBrandValue])

    // category list effect 
    useEffect(() => {
        setIsLoading(true);

        axios.get('/category/viewcategories/')
            .then(data => {
                setCategoryList(data?.data);
                setIsLoading(false);  // Stop loading
            })
            .catch(error => {
                // setError(error);
                setIsLoading(false);  // Stop loading
            });

    }, [categoryDropdown])

    // unit list effect 
    useEffect(() => {
        setIsLoading(true);

        axios.get('/unit/viewunits/')
            .then(data => {
                setUnitList(data.data);
                setIsLoading(false);  // Stop loading
            })
            .catch(error => {
                // setError(error);
                setIsLoading(false);  // Stop loading
            });

    }, [unitDropdown])

    // Branch list effect 
    useEffect(() => {
        setIsLoading(true);

        axios.get('/branch/viewbranch')
            .then(data => {
                setBranchList(data?.data);
                setIsLoading(false);  // Stop loading

            })
            .catch(error => {
                // setError(error);
                setIsLoading(false);  // Stop loading
            });

    }, [])

    const [branchDatafromData, branchDataRefetch] = useGetData('/branch/viewbranch', 'branchDatafromData');


    const handleAddItemSubmit = (e) => {
        const form = e.target;
        // const item_name = form.item_name.value;

        e.preventDefault();
        const formData = new FormData();

        formData.append('item_name', item_name);
        formData.append('brand_name', brandDropdown);
        formData.append('category_name', categoryDropdown);
        formData.append('unit_name', unitDropdown);
        formData.append('warehouse_name', branchDropdown);
        formData.append('sku', sku);
        // formData.append('expire_date', date);
        formData.append('alert_qty', alertQuantity);
        formData.append('description', description)
        formData.append('stock', stock);
        formData.append('custom_barcode', barcode);
        formData.append('warehouse_id', warehouseId);
        formData.append('purchase_price', purchasePrice);
        formData.append('sales_price', salesPrice);


        axios.put(`/item/updateitem/${id}`, formData, {
            headers: { "Content-type": "application/json" },
        })
            .then(res => {

                navigate('/product-management/items-list')

            })
            .catch(error => {
            })

    }

    return (
        <Fragment>
            <Breadcrumb title="Edit Item" parent="Product Management" />
            <Container fluid={true}>
                <Card>
                    <CardHeader>
                        <div>
                            <h5 className=""> Edit Item List </h5>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <CardBody className="order-datatable">
                            {editableData &&
                                <>
                                    <div className="d-flex gap-2">
                                        <div className="col-md-4 col-sm-12">
                                            <FormGroup>
                                                <Label for="exampleEmail" className='fw-bold'>
                                                    Item Name <span className="text-danger">*</span>
                                                </Label>
                                                <input
                                                    className='form-control'
                                                    defaultValue={item_name}

                                                    id="exampleEmail"

                                                    onChange={(e) => setItemName(e.target.value)}
                                                    type="text"
                                                    required
                                                />
                                            </FormGroup>
                                        </div>

                                        <div className="col-md-4 col-sm-12">
                                            <FormGroup>
                                                <Label for="exampleEmail" className='fw-bold'>
                                                    Brand
                                                </Label>
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <select className="p-2 w-100 rounded-2 border" onClick={(e) => setBrandDropdown(e.target.value)}>
                                                        <option value={brandDropdown}>{brandDropdown}</option>
                                                        {
                                                            brandList?.slice().sort((a, b) => a?.brand_name?.localeCompare(b?.brand_name))?.map(brand => (
                                                                <option key={brand?.id} value={brand?.brand_name}>{brand?.brand_name}</option>

                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </FormGroup>
                                        </div>

                                        <div className="col-md-4 col-sm-12">
                                            <FormGroup>
                                                <Label for="exampleEmail" className='fw-bold'>
                                                    Category
                                                </Label>
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <select className="p-2 w-100 rounded-2 border" onClick={(e) => setCategoryDropdown(e.target.value)}>
                                                        <option value={categoryDropdown}>{categoryDropdown}</option>
                                                        {
                                                            categoryList?.slice().sort((a, b) => a?.category_name?.localeCompare(b.category_name))?.map(category => (
                                                                <option key={category.id} defaultValue={editableData?.category_name} value={category.category_name}>{category.category_name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </FormGroup>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-4 col-sm-12">
                                            <FormGroup>
                                                <Label for="exampleEmail" className='fw-bold'>
                                                    Unit
                                                </Label>
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <select className="p-2 w-100 rounded-2 border" onClick={(e) => setUnitDropdown(e.target.value)}>
                                                        <option value={unitDropdown}>{unitDropdown}</option>
                                                        {
                                                            unitList?.slice().sort((a, b) => a?.unit_name?.localeCompare(b.unit_name))?.map(unit => (
                                                                <option key={unit.id} value={unit.unit_name}>{unit.unit_name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </FormGroup>
                                        </div>

                                        <div className="col-md-4 col-sm-12">
                                            <FormGroup>
                                                <Label for="exampleEmail" className='fw-bold'>
                                                    SKU <span className="text-danger">*</span>
                                                </Label>
                                                <Input
                                                    defaultValue={sku ? sku : ' '}
                                                    id="exampleEmail"
                                                    name="sku"
                                                    onChange={(e) => setSku(e.target.value)}
                                                    type="text"
                                                />
                                            </FormGroup>
                                        </div>

                                        <div className="col-md-4 col-sm-12">
                                            {/* <FormGroup>
                                                <Label for="exampleEmail" className='fw-bold'>
                                                    Expire Date <span className="text-danger">*</span>
                                                </Label>
                                                <input
                                                className="form-control"
                                                    defaultValue={formattedDate}
                                                    id="exampleEmail"

                                                    onChange={(e) => setDate(e.target.value)}
                                                    type="date"
                                                />
                                            </FormGroup> */}
                                        </div>

                                        <div className="col-md-4 col-sm-12">
                                            <FormGroup>
                                                <Label for="exampleEmail" className='fw-bold'>
                                                    Alert Quantity
                                                </Label>
                                                <Input
                                                    id="exampleEmail"
                                                    name="alertQuantity"
                                                    defaultValue={alertQuantity}
                                                    onChange={(e) => setAlertQuantity(e.target.value)}
                                                    type="number"
                                                />
                                            </FormGroup>
                                        </div>

                                        <div className="col-md-4 col-sm-12">
                                            <FormGroup>
                                                <Label for="exampleEmail" className='fw-bold'>
                                                    Barcode
                                                </Label>
                                                <Input
                                                    id="exampleEmail"
                                                    name="barcode"
                                                    defaultValue={barcode}
                                                    onChange={(e) => setBarcode(e.target.value)}
                                                    type="text"
                                                />
                                            </FormGroup>
                                        </div>

                                        <div className="col-md-4 col-sm-12">
                                            <FormGroup row>
                                                <Label
                                                    for="exampleText" className='fw-bold'
                                                >
                                                    Description
                                                </Label>
                                                <Col>
                                                    <textarea
                                                        defaultValue={description ? description : ' '}
                                                        name='description'
                                                        type="textarea"
                                                        onChange={(e) => setDescription(e.target.value)}
                                                        className="form-control"
                                                    />
                                                </Col>

                                            </FormGroup>
                                        </div>

                                        {/* <div className="col-md-4 col-sm-12 position-relative">

                                            <FormGroup>
                                                <Label for="exampleName" className='fw-bold'>
                                                    Select Image
                                                </Label>
                                                {
                                                    editableData.item_image ? <> <img style={{ height: '50px', width: '50px', position: 'absolute', right: '-92px', border: '1px solid black', borderRadius: '50%' }} src={`https://shaan.apodigi.com/images/${editableData.item_image}`} alt="" />  </> : <> <span style={{ position: 'absolute', right: '-150px', top:'27px'  }}> </span> </>
                                                }
                                                <input required className="form-control"
                                                    type='file'
                                                    
                                                    onChange={(e) => setImageFiles(e.target.files)}
                                                />
                                                <FormText >
                                                    <span className='text-danger'>Max Width/Height: 1000px * 1000px & Size: 1MB</span>
                                                </FormText>
                                            </FormGroup>

                                        </div> */}
                                        <hr />
                                    </div>

                                    <div className="row">
                                        <div className="col-md-4 col-sm-12">
                                            <FormGroup>
                                                <Label for="exampleName" className='fw-bold'>
                                                    Branch <span className='text-danger'>*</span>
                                                </Label>
                                                <div className="d-flex align-items-center justify-content-center">

                                                    <select className="p-2 w-100 rounded-2 border" onClick={(e) => {
                                                        setWarehouseId(e.target[e.target.selectedIndex].id);
                                                        setBranchDropdown(e.target.value)
                                                    }}>
                                                        {/* <option >{branchDropdown}</option> */}
                                                        {
                                                            branchList?.slice().sort((a, b) => a?.warehouse_name?.localeCompare(b.warehouse_name))?.map(branch => (
                                                                <option key={branch.id} id={branch.id} defaultValue={branch?.warehouse_name} value={branch.warehouse_name}>{branch.warehouse_name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </FormGroup>
                                        </div>

                                        <div className="col-md-4 col-sm-12">
                                            <FormGroup>
                                                <Label for="exampleName" className='fw-bold'>
                                                    Opening Stock <span className='text-danger'>*</span>
                                                </Label>
                                                <input disabled className="form-control" type='number' step={'any'} defaultValue={stock}
                                                    onChange={(e) => setStock(stock)}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-md-4 col-sm-12">
                                            <FormGroup>
                                                <Label for="exampleName" className='fw-bold'>
                                                    Purchase Price  <span className='text-danger'>*</span>
                                                </Label>
                                                <input className="form-control" type='number' step={'any'} defaultValue={purchasePrice}
                                                    onChange={(e) => setPurchasePrice(e.target.value)}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-md-4 col-sm-12">
                                            <FormGroup>
                                                <Label for="exampleName" className='fw-bold'>
                                                    Sales Price  <span className='text-danger'>*</span>
                                                </Label>
                                                <input className="form-control" type='number' step={'any'} defaultValue={salesPrice}
                                                    onChange={(e) => setSalesPrice(e.target.value)}
                                                />
                                            </FormGroup>
                                        </div>
                                        <hr />
                                    </div>

                                    <div className="row">
                                        <div className='col-md-12 d-flex align-items-center justify-content-center mt-5 gap-5'>
                                            <input onClick={handleAddItemSubmit} type="submit" style={{ backgroundColor: '#00A65A', border: 'none', color: 'white', padding: '6px 28px', borderRadius: '5px' }} />
                                            <Link to={'/dashboard'} style={{ backgroundColor: '#F39C12', border: 'none', color: 'white', padding: '6px 28px', borderRadius: '5px', textDecoration: 'none' }}>Close</Link>
                                        </div>
                                    </div>
                                </>
                            }
                        </CardBody>
                    </CardBody>
                </Card>
            </Container>
        </Fragment>
    );
};

export default ItemListEdit;
