import { Card, CardBody, Table, } from 'reactstrap';
import { HiInformationCircle } from "react-icons/hi";
import { useState } from 'react';
import useGetData from '../../../hooks/useGetData';
import TableLoading from '../../common/loading/TableLoading';
const RecentCustomerAndDue = () => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  const [dueCustomerData,  refetch, isLoading] = useGetData(
    '/customer/viewcustomers/',
    '/viewcustomers/'
  );


  return (
    <Card>
      <div className='d-flex align-items-center justify-content-center gap-1 position-relative'>
        <div className='text-center mt-2 r'>Recent Customers and Due </div>
        <HiInformationCircle
          style={{ cursor: 'pointer' }}
          onMouseEnter={() => setTooltipVisible(true)}
          onMouseLeave={() => setTooltipVisible(false)}
          className='mt-2 fs-5 ms-1 text-danger informationHover'
        />

        <div
          style={{
            width: '320px',
            // boxShadow: "1px 1px 8px 5px rgba(165, 165, 165, 0.548)",
          }}
          className={`text-white border overflow-hidden  text-center  position-absolute bottom-100 rounded-3  ${
            isTooltipVisible ? 'opacity-100' : 'opacity-0'
          }`}>
          <div className='bg-body rounded-bottom py-2 '>
            <p className='m-0 text-dark'>
              You can see all customers and their due amount.
            </p>
          </div>
        </div>
      </div>
      <CardBody>
        <div className=''>
          <table style={{ width: '100%', borderRadius: '5px' }}>
            <thead style={{ backgroundColor: '#FF3B6A', color: 'white' }}>
              <tr className='bg-secondary'>
                <th
                  style={{ padding: '6px' }}
                  scope='col'>
                  No
                </th>
                <th
                  style={{ padding: '6px' }}
                  scope='col'>
                  {' '}
                  Name
                </th>
                <th style={{ padding: '6px' }}>Mobile</th>
                <th style={{ padding: '6px' }}>Due Amount</th>
              </tr>
            </thead>
            <tbody>
              {isLoading && (
                <TableLoading
                  noTd={4}
                  noRow={5}
                />
              )}
              {dueCustomerData
                ?.slice(0, 5)
                .sort((a, b) => b.id - a.id)
                .map((customer, index) => (
                  <tr key={customer.id}>
                    <td
                      className='border'
                      style={{ borderRadius: '5px', paddingLeft: '5px' }}>
                      {index + 1}
                    </td>
                    <td
                      className='border'
                      style={{ borderRadius: '5px', paddingLeft: '5px' }}>
                      {' '}
                      {customer.customer_name}
                    </td>
                    <td
                      className='border'
                      title='apogee pos'>
                      {' '}
                      {customer.mobile}{' '}
                    </td>
                    <td
                      className='border'
                      title='apogee pos'>
                      {' '}
                      <span
                        style={{
                          fontSize: '15px',
                          fontWeight: '800',
                          marginLeft: '10px',
                        }}>
                        ৳
                      </span>{' '}
                      {customer.sales_due || 0}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  );
};

export default RecentCustomerAndDue;