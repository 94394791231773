import { useMutation } from "@tanstack/react-query";
import useAxiosSecure from "./useAxiosSecure";
import { CustomToast } from "./useCustomToast";

const usePostData = (apiLink, successTitle, refetch) => {
  const [axios] = useAxiosSecure();
  const postMutation = useMutation(
    async (postData) => {
      const res = await axios.post(apiLink, postData);
      return res.data;
    },
    {
      onSuccess: () => {
        CustomToast("success", successTitle);
        // window.location.reload();
      },
      onError: () => {
        CustomToast("error", "Something went wrong!");
      },
    }
  );
  return postMutation;
};

export default usePostData;
