import React from "react";
import useNumberFormatter from "../../../hooks/useNumberFormatter";

const ViewListTable = ({ product, sl }) => {
  const numericFormatter = useNumberFormatter();

  const {
    id,
    price_per_unit,
    item_unit_total_cost,
    item_discount_type,
    sales_qty,
    item_discount_amt,
    item_name,
    item_total_cost,
    item_discount_input,
  } = product || {};
  return (
    <>
      <tr>
        <td>{sl + 1}</td>
        <td>{item_name}</td>
        <td>{numericFormatter(price_per_unit) || 0}</td>
        <td>{numericFormatter(sales_qty) || 0}</td>
        <td>{numericFormatter(item_total_cost) || 0}</td>
        <td>
          {numericFormatter(item_discount_input) || 0}{" "}
          {item_discount_type === "Percentage(%)" ? "%" : "Fixed(৳)"}
        </td>
        <td>{numericFormatter(item_discount_amt) || 0}</td>
        <td>{numericFormatter(item_unit_total_cost) || 0}</td>
        <td>{numericFormatter(item_total_cost) || 0}</td>
      </tr>
    </>
  );
};

export default ViewListTable;
