import { DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';
import { useState } from 'react';

const DateRangePickerComponent = () => {
  const [stateM, setStateM] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection',
    },
  ]);

  const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  };
  const handleSelect = (ranges) => {
    // {
    //   selection: {
    //     startDate: [native Date Object],
    //     endDate: [native Date Object],
    //   }
    // }
  };
  return (
    <>
    
      <DateRangePicker
        onChange={(item) => setStateM([item.selection])}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={2}
        ranges={stateM}
        //   showMonthArrow={false}
        direction='horizontal'
      />
    </>
  );
};

export default DateRangePickerComponent;
