import React, { Fragment, useState } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Card, Container, } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import BranchInputField from './BranchInputField';
import axios from '../../util/axiosInstance';
import { CustomToast } from './../../hooks/useCustomToast';


const AddBranch = () => {
    const navigate = useNavigate();
    const [wareHouseName, setWareHouseName] = useState('');
    const [wareHouseType, setWareHouseType] = useState('');
    const [address, setAddress] = useState('');

    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [image, setImage] = useState(null);


    const date = new Date(Date.UTC(2023, 8, 15, 4, 0, 0)); // Months are 0-indexed in JavaScript
    const dateString = date.toISOString();


    const handleWarehouseSubmit = (e) => {
        e.preventDefault();
        const form = e.target;

        const data = {
            "warehouse_name": wareHouseName,
            "warehouse_type": wareHouseType,
            "warehouse_address": address,
            "mobile": mobile,
            "email": email,
            "status": '1',
            "created_date": dateString
        }
        
        axios.post('/branch/addbranch', data)
            .then(response => {
                if (response.status === 201 || response.status === 200 || response.status === 202) {
                    CustomToast('success', 'Branch Create Successfully');
                    navigate('/branch-list');
                }
                form.reset();
            })
            .then(error => {
                if (error) {
                    CustomToast('error', 'Something Wrong')
                }
            })
    }

    return (
        <Fragment>
            <Breadcrumb title="Add Branch " parent="Branch" />
            <Container fluid={true}>
                <Card className='p-2'>
                    <form onSubmit={(handleWarehouseSubmit)}>
                        <BranchInputField labelText={'Warehouse Name'} setWareHouseName={setWareHouseName} type={'text'} fieldId={'warehouseName'} requiredField={true}></BranchInputField>

                        <BranchInputField labelText={'Warehouse Type'} type={'text'} fieldId={'warehouseType'} setWareHouseType={setWareHouseType} requiredField={true}></BranchInputField>

                        <BranchInputField requiredField={true} labelText={'Address'} type={'text'} fieldId={'address'} setAddress={setAddress}></BranchInputField>

                        <BranchInputField requiredField={true} labelText={'Mobile'} type={'number'} fieldId={'mobile'} setMobile={setMobile}></BranchInputField>
                        <BranchInputField requiredField={true} labelText={'Email'} type={'email'} fieldId={'email'} setEmail={setEmail}></BranchInputField>
                        {/* <BranchInputField labelText={'Image'} type={'file'} fieldId={'image'} setImage={setImage}></BranchInputField> */}

                        <div className="row">
                            <div className=' d-flex align-items-center justify-content-center mt-5 gap-5'>
                                <input type="submit" style={{ backgroundColor: '#00A65A', border: 'none', color: 'white', padding: '6px 28px', borderRadius: '5px' }} />
                                <Link to={'/dashboard'} style={{ backgroundColor: '#F39C12', border: 'none', color: 'white', padding: '6px 28px', borderRadius: '5px', textDecoration: 'none' }}>Close</Link>
                            </div>
                        </div>
                    </form>
                </Card>
            </Container>
        </Fragment>
    );
};

export default AddBranch;


