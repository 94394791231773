import React, { Fragment } from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import { Card, CardBody, CardHeader, Container } from "reactstrap";
import AlertQtytable from "./../../../components/AlertQty";

function AlertQty() {
  return (
    <Fragment>
      <Breadcrumb title="Alert Quantity" parent="Product Management" />
      <Container fluid={true}>
        <Card>
          <CardHeader>
            <div>
              <h5 className=""> View All Alter Quantity </h5>
            </div>
          </CardHeader>
          <CardBody>
            <AlertQtytable />
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
}

export default AlertQty;
