
import { Fragment, useEffect, useState } from "react";
import { Card, CardBody, Container, } from "reactstrap";
import { BiPlusMedical } from "react-icons/bi";
import Breadcrumb from "../../../components/common/breadcrumb";
import { Table } from 'reactstrap';
import { Link } from "react-router-dom";
import { AiFillEdit, AiTwotoneDelete } from 'react-icons/ai';
import LoadingBar from 'react-top-loading-bar'
import axios from "../../../util/axiosInstance";
import useGetData from "../../../hooks/useGetData";
import TableLoading from "../../../components/common/loading/TableLoading";
import TransferListPagination from "./TransferListPagination";

const TransferList = () => {

 const [data, setData] = useState([]);
 const [progress, setProgress] = useState(100)
 let allData = data?.length;

 const [searchValue, setSearchValue] = useState("");
 const [selectedValue, setSelectedValue] = useState(10);
 const [category, setCategory] = useState([]);



 const [isLoading, setIsLoading] = useState(false);

 const [categoryDatafromData, categoryDataRefetch] = useGetData('/category/viewcategories/', 'categoryDatafromData');



 useEffect(() => {
  setIsLoading(true);
  axios('/category/viewcategories/')
   .then(data => {
    setData(data.data);
    setIsLoading(false);  // Stop loading
   })
   .catch(error => {
    // setError(error);
    setIsLoading(false);  // Stop loading
   });

 }, [])



 const handleDelete = (id) => {
  axios.delete(`/category/deletecategory/${id}`)
   .then(response => {
    window.location.reload();
   })
   .catch(error => console.error("Error deleting data:", error));
 }


 useEffect(() => {
  handleFilter()
 }, [searchValue, data,]);

 // searc 
 const handleFilter = () => {
  if (data) {
   let filterCategory = [...data]
   if (searchValue !== '') {
    filterCategory = data?.filter((category) =>
     category.category_name.toLowerCase().includes(searchValue.toLowerCase())
    );
   }
   setCategory(filterCategory);
  }

 }
 const handleInputChange = (e) => {
  setSearchValue(e.target.value);

 };


 return (
   <Fragment>
     <Breadcrumb
       title='Stock Transfer List'
       parent='Stock Management'
     />
     <Container fluid={true}>
       <Card>
         <div
           className='d-flex justify-content-between '
           style={{ marginTop: '15px', marginRight: '32px' }}>
           <h5 className='text-center'> </h5>
           <Link
             className='d-flex align-items-center gap-2'
             style={{
               backgroundColor: '#00C0EF',
               textDecoration: 'none',
               color: 'white',
               padding: '6px',
               borderRadius: '5px',
             }}
             to={'/stock-management-stock-transfer'}>
             {' '}
             <BiPlusMedical /> New Transfer
           </Link>
         </div>

         <CardBody>
           <div className='row'>
             <div className='col-md-4 d-flex align-items-center '>
               <span>Show</span>
               <select
                 onChange={(e) => setSelectedValue(e.target.value)}
                 className='mx-1'>
                 <option value={10}>10</option>
                 <option value={25}>25</option>
                 <option value={50}>50</option>
                 <option value={100}>100</option>
                 <option value={500}>500</option>
                 <option value={allData}>AllData</option>
               </select>
               <span>entries</span>
             </div>
             <div className='col-md-4 d-flex align-items-center justify-content-end'></div>
             <div className='col-md-4 d-flex align-items-center gap-2'>
               <span>Search </span>{' '}
               <input
                 type='text'
                 className='form-control'
                 value={searchValue}
                 onChange={handleInputChange}
               />
             </div>
           </div>
           <div
             style={{
               backgroundColor: '#F2F2F2',
               padding: '10px',
               marginTop: '2px',
             }}>
             <Table
               bordered
               hover
               striped>
               <thead className='text-center'>
                 <tr>
                   <th>Transfer Date </th>
                   <th>From Warehouse</th>
                   <th>To Warehouse </th>
                   <th>Details</th>
                   <th>Note</th>
                   <th>Action</th>
                 </tr>
               </thead>

               <tbody className='text-center'>
                 {isLoading && (
                   <TableLoading
                     noRow={10}
                     noTd={6}
                   />
                 )}

                 {!isLoading && (
                   <>
                     {category
                       ?.slice()
                       .sort((a, b) => b.id - a.id)
                       .slice(0, selectedValue)
                       .map((category, index) => (
                         <tr key={category.id}>
                           <th scope='row'>{index + 1}</th>
                           <td>{category.category_name}</td>
                           <td>{category.description}</td>
                           <td>{category.description}</td>
                           <td>{category.description}</td>
                           <td>
                             <Link
                               className='fs-4 text-decoration-none'
                               to={`/stock-management/transfer-list/edit/${category.id}`}>
                               {' '}
                               <AiFillEdit />{' '}
                             </Link>
                             <Link
                               className='border-0 bg-transparent fs-4'
                               onClick={() => handleDelete(category.id)}>
                               {' '}
                               <AiTwotoneDelete />{' '}
                             </Link>
                           </td>
                         </tr>
                       ))}
                   </>
                 )}
               </tbody>
             </Table>
           </div>
           <TransferListPagination />
         </CardBody>
       </Card>
     </Container>
   </Fragment>
 );
};

export default TransferList;
