import React from "react";
import useNumberFormatter from "../../../hooks/useNumberFormatter";

const ViewPurchaseListTable = ({ product, sl }) => {
  const numericFormatter = useNumberFormatter();
  const {
    id,
    purchase_status,
    item_name,
    purchase_qty,
    price_per_unit,
    unit_total_cost,
    total_cost,
  } = product || {};
  return (
    <>
      <tr>
        <td>{sl + 1}</td>
        <td>{item_name || "-"}</td>
        <td>{purchase_status || "-"}</td>
        <td>{numericFormatter(purchase_qty) || 0}</td>
        <td>{numericFormatter(price_per_unit) || 0}</td>
        <td>{numericFormatter(unit_total_cost) || 0}</td>
        <td>{numericFormatter(total_cost) || 0}</td>
      </tr>
    </>
  );
};

export default ViewPurchaseListTable;
