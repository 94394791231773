import { Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import Breadcrumb from "../../components/common/breadcrumb";
import WarehouseDropdownSection from "./WarehouseDropdownSection";
import { TbCurrencyTaka } from "react-icons/tb";

import { FaBarcode } from "react-icons/fa6";

import axios from "../../util/axiosInstance";

import ReactDatePicker from "react-datepicker";
import SupplierDropdownSection from "./SupplierDropdownSection";
import PurchaseTable from "./PurchaseTable";

import { Link, useNavigate } from "react-router-dom";
import useGetData from "../../hooks/useGetData";
import BarcodeDropdownSection from "./BarcodeDropdownSection";
import useClickOutside from "../../hooks/useClickOutside";
import { useRef } from "react";
import useNumberFormatter from "../../hooks/useNumberFormatter";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { CustomToast } from "../../hooks/useCustomToast";
import moment from "moment";

const NewPurchase = () => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const paymentStatusData = [
    {
      id: "1",
      name: "received",
    },
    {
      id: "2",
      name: "Pending",
    },
    {
      id: "3",
      name: "Order",
    },
  ];
  const numericFormatter = useNumberFormatter();

  function generateRandomSPNumber() {
    const randomNumber = Math.floor(Math.random() * 10000); // Generate a random number between 0 and 9999
    const formattedNumber = `SP${randomNumber.toString().padStart(4, "0")}`; // Format with "SP" prefix and leading zeros if needed
    return formattedNumber;
  }
  const randomSPNumber = generateRandomSPNumber();
  const [error, setError] = useState("");

  const selectedDropdownMenu = useRef(null);
  useClickOutside(selectedDropdownMenu, () => setIsSelectedItemDivOpen(false));
  const [isSelectedItemDivOpen, setIsSelectedItemDivOpen] = useState(false);
  const [accountTypeData, setAccountTypeData] = useState([]);
  const [selectedItemData, setSelectedItemData] = useState([]);
  const [salesItems, setSalesItems] = useState([]);

  const { lot_number, item_id, custom_barcode } = selectedItemData || {};
  const [customBarcode, setCustomBarcode] = useState(custom_barcode);
  const [salesPrice, setSalesPrice] = useState();
  const [reference, setReference] = useState("");
  const [paymentNote, setPaymentNote] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("Select Payment");
  const [payingAmount, setPayingAmount] = useState(0);
  const [deliveryChargeInputValue, setDeliveryChargeInputValue] = useState(0);
  const [branchData, setBranchData] = useState([]);
  const [totalSubTotal, setTotalSubTotal] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const grandTotal = totalSubTotal + deliveryChargeInputValue || 0;
  const [totalDueAmount, setTotalDueAmount] = useState(0);
  const [changeReturnData, setChangeReturnData] = useState(0);

  // calculation change return multiple payment
  const changeReturnCalculation = () => {
    if (payingAmount > grandTotal) {
      const changeReturn = (payingAmount || 0) - parseFloat(grandTotal || 0);
      setChangeReturnData(changeReturn);
    } else {
      setChangeReturnData(0);
    }
    if (payingAmount < grandTotal) {
      const totalDueAmount = parseFloat(grandTotal || 0) - (payingAmount || 0);
      setTotalDueAmount(totalDueAmount || 0);
    } else {
      setTotalDueAmount(0);
    }
  };

  useEffect(() => {
    changeReturnCalculation();
  }, [payingAmount]);

  const [startDate, setStartDate] = useState(new Date());
  const date = startDate;
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0 indexed
  const day = date.getDate().toString().padStart(2, "0");
  const year = date.getFullYear();
  const purchaseDate = `${month}/${day}/${year}`;
  const [warehouseDropdownId, setWarehouseDropdownId] = useState("");
  const [supplierDropdownId, setSupplierDropdownId] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("Select Payment Type");
  const [accountMethodId, setAccountMethodId] = useState("");
  const [accountMethodName, setAccountMethodName] = useState("Select Account");
  const [searchBarcodeInput, setSearchBarcodeInput] = useState("");
  const [barCodeProducts, setBarCodeProducts] = useState([]);

  // set selected item data
  const handleItemClick = (item) => {
    // Check if the item is already in the selectedItemData array
    const isDuplicate = selectedItemData.some(
      (selectedItem) => selectedItem.id === item.id
    );

    if (!isDuplicate) {
      setSelectedItemData([...selectedItemData, item]);
      setIsSelectedItemDivOpen(false);
    } else {
      setIsSelectedItemDivOpen(false);
      Swal.fire({
        title: "Oops...",
        text: "Product is already added. Please update the quantity!",
        timer: 1000,
      });
    }
  };

  // barcode products get from backend
  useEffect(() => {
    if (searchBarcodeInput.length >= 3) {
      axios
        .get(`/purchase/searchitem?query=${searchBarcodeInput}`)
        .then((response) => {
          setBarCodeProducts(response.data);

          if (
            response.data.some((x) => x.custom_barcode === searchBarcodeInput)
          ) {
            reset();
            const item = response.data.find(
              (x) => x.custom_barcode === searchBarcodeInput
            );

            return handleItemClick(item);
          }
          if (response.data.length > 0) {
            reset();
            setSearchBarcodeInput("");
            setIsSelectedItemDivOpen(true);
          }
        });
    }
  }, [searchBarcodeInput, axios]);

  useEffect(() => {
    axios
      .get("/branch/viewbranch")
      .then((res) => setBranchData(res.data))
      .catch((error) => {
      });
  }, []);

  const [supplierTypeData] = useGetData(
    "/supplier/viewsuppliers",
    "supplierdata"
  );

  const [paymentTypeData] = useGetData(
    "/paymenttype/viewpaymenttypes/",
    "paymenttype"
  );

  useEffect(() => {
    axios
      .get("/accounts/viewaccounts/")
      .then((res) => setAccountTypeData(res.data));
  }, []);
  // purchase code
  const currentDateTime = moment().format("YYYYMMDDHHmmss");

  const cashPaymentPostData = {
    warehouse_id: warehouseDropdownId,
    purchase_code: currentDateTime,
    supplier_id: supplierDropdownId,
    discount_to_all_input: "not update",
    discount_to_all_type: "not update",
    grand_total: grandTotal,
    purchase_status: paymentStatus,
    payment_status:
      payingAmount >= grandTotal
        ? "Paid"
        : payingAmount > 0
        ? "Partially Paid"
        : "Due",
    paid_amount: payingAmount,
    purchase_due: totalDueAmount,
    purchase_items: salesItems,
    purchase_payments: [
      {
        payment_code: randomSPNumber,
        payment_type: paymentMethod,
        payment: payingAmount,
        payment_note: paymentNote,
        account_id: accountMethodId,
        supplier_id: supplierDropdownId,
      },
    ],
  };

  const pay = (e) => {
    // const form = e.target;
    axios
      .post(
        "/purchase/add-purchase-with-items-and-payments",
        cashPaymentPostData
      )
      .then((response) => {
        if (response) {
          CustomToast("success", "Add Successfully");
          navigate("/purchase-management/purchase-list");
        }
      })
      .catch((err) => {
        if (err) {
          CustomToast("error", "Something Wrong");
        }
      });
    // form.reset();
  };

  // hook form data
  const onSubmit = (data) => {
    pay();
  };

  return (
    <>
      <Fragment>
        <Breadcrumb title="Purchase " parent="Purchase  Management" />
        <Container fluid={true}>
          <Card>
            <CardBody>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className=" row">
                  {error && (
                    <h3 className="fw-bold text-capitalize text-center mb-3 text-danger">
                      {" "}
                      {error}
                    </h3>
                  )}
                  <div className="col-md-12">
                    <div
                      style={{ width: "48%" }}
                      className=" d-flex justify-content-between align-items-center border border rounded-1 "
                      title="Warehouse"
                    >
                      <span className="fw-bold px-2">
                        {/* <FaBuilding className="text-danger"></FaBuilding> */}
                        Warehouse
                      </span>
                      {/* dropdown data */}
                      {/* {!firstBranch && <InputLoading></InputLoading>} */}

                      {/* {firstBranch && ( */}

                      <WarehouseDropdownSection
                        setWarehouseDropdownId={setWarehouseDropdownId}
                        borderEnd={"warehouse"}
                        inputType={"search"}
                        // setInputValue={setWareHouseSearch}
                        InitialDropdownValue={"select warehouse"}
                        data={branchData}
                        titleData={"Warehouse"}
                        placeHolderData={"Enter Warehouse Name"}
                        dataField={"warehouse_name"}
                      ></WarehouseDropdownSection>
                      {/* )} */}
                    </div>
                  </div>
                </div>

                <div className="row mt-3 ">
                  <div className="col-md-6 position-relative">
                    <div
                      style={{ width: "98%" }}
                      className=" d-flex  align-items-center  border rounded-1 "
                      title="Warehouse"
                    >
                      <span className="fw-bold px-2" style={{ width: "20%" }}>
                        Supplier
                      </span>
                      {/* dropdown data */}
                      {/* {!firstBranch && <InputLoading></InputLoading>} */}

                      {/* {firstBranch && ( */}

                      <SupplierDropdownSection
                        setSupplierDropdownId={setSupplierDropdownId}
                        borderEnd={"warehouse"}
                        inputType={"search"}
                        // setInputValue={setWareHouseSearch}
                        // InitialDropdownValue={firstBranch}
                        InitialDropdownValue={"Select Supplier"}
                        data={supplierTypeData || []}
                        titleData={"Supplier"}
                        placeHolderData={"Enter Supplier Name"}
                        dataField={"supplier_name"}
                      ></SupplierDropdownSection>
                      {/* )} */}
                    </div>
                  </div>

                  <div className="col-md-6 gap-4">
                    <div
                      style={{ width: "100%" }}
                      className=" d-flex align-items-center  rounded-1 "
                    >
                      <span className="fw-bold px-2">
                        Purchase Date <span className="text-danger">*</span>
                      </span>
                      <ReactDatePicker
                        showIcon
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                      />
                    </div>
                  </div>

                  <div className="col-md-6  mt-3">
                    <div className=" form-group w-100 col-md-4">
                      <label for="amount" className="fw-bold">
                        {" "}
                        Purchase Status{" "}
                      </label>
                      <UncontrolledDropdown className=" w-100">
                        <DropdownToggle
                          className="border w-100"
                          caret
                          color="white"
                          style={{ backgroundColor: "#FFFFFF" }}
                        >
                         <span className="text-uppercase">{paymentStatus} </span> 
                        </DropdownToggle>
                        <DropdownMenu className="text-center w-100 overflow-auto h-auto p-2"
                        style={{
                          maxHeight: "150px",
                          boxShadow:
                            "0px 0px 30px 10px rgb(197, 194, 194)",
                        }}
                        >
                          {paymentStatusData?.map((payment) => (
                            <DropdownItem
                            className="w-100 text-center text-dark mt-1 border rounded-1 bg-black-hover p-0"
                              key={payment.id}
                              onClick={(e) => setPaymentStatus(payment.name)}
                            >
                             <span className="text-uppercase"> {payment?.name}</span> 
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>

                  <div className="col-md-6 mt-3 gap-4">
                    <div className="mt-3 d-flex border rounded-2">
                      <span className="fw-bold col-2 d-flex justify-content-center align-items-center border-end">
                        Reference No
                      </span>
                      <input
                        onChange={(e) => setReference(e.target.value)}
                        name="reference"
                        type="text"
                        className="form-control border-0"
                      />
                    </div>
                  </div>
                </div>

                {/* barcode start  */}
                <div className="col-md-12 d-flex justify-content-center align-items-center mt-3">
                  <div
                    style={{ width: "60%" }}
                    className="d-flex border justify-content-center rounded-1 align-items-center"
                  >
                    <span className="border-end  p-2">
                      <FaBarcode></FaBarcode>
                    </span>
                    <div className="w-100 px-1 position-relative">
                      <input
                        type="text"
                        className="w-100 border-0"
                        placeholder="Item Name/Barcode/Item Code"
                        defaultValue={""}
                        title="Item Name/Barcode/Item Code"
                        name="barcodeInput"
                        {...register("barcodeInput")}
                        onChange={(e) => {
                          setSearchBarcodeInput(e.target.value);
                        }}
                      />
                      {isSelectedItemDivOpen && (
                        <BarcodeDropdownSection
                          selectedDropdownMenu={selectedDropdownMenu}
                          barCodeProducts={barCodeProducts}
                          setSelectedItemData={setSelectedItemData}
                          selectedItemData={selectedItemData}
                          setIsSelectedItemDivOpen={setIsSelectedItemDivOpen}
                          handleItemClick={handleItemClick}
                        />
                      )}
                    </div>
                  </div>
                </div>

                {/* pos table  */}
                <div className=" mt-5">
                  <section className="">
                    <div
                      className=" px-2 border rounded-2 bg-body-secondary "
                      style={{
                        maxHeight: "437px",
                        overflowY: "scroll",
                        overflowX: "hidden",
                      }}
                    >
                      <Table className="my-3 rounded-2">
                        <thead>
                          <tr className="text-center">
                            <th className="text-start">Item Name</th>
                            <th className="">Lot Number</th>
                            <th className="">Custom Barcode</th>
                            <th className="">Stock</th>
                            <th>Sales Price</th>
                            <th style={{ display: "none" }}>Unit Per Cost</th>
                            <th>Purchase Price/unit cost</th>
                            <th>Quantity</th>
                            <th>
                              {" "}
                              Subtotal(
                              <TbCurrencyTaka />)
                            </th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody className="text-center">
                          {selectedItemData?.map((product, index) => (
                            <PurchaseTable
                              key={index}
                              product={product}
                              products={selectedItemData}
                              setTotalSubTotal={setTotalSubTotal}
                              totalSubTotal={totalSubTotal}
                              grandTotal={grandTotal}
                              setSelectedItemData={setSelectedItemData}
                              setTotalQuantity={setTotalQuantity}
                              setSalesPrice={setSalesPrice}
                              salesPrice={salesPrice}
                              // setLotNumber={setLotNumber}
                              setCustomBarcode={setCustomBarcode}
                              customBarcode={customBarcode}
                              salesItems={salesItems}
                              setSalesItems={setSalesItems}
                            ></PurchaseTable>
                          ))}
                        </tbody>
                      </Table>
                    </div>

                    <div
                      className="row gap-5"
                      style={{ marginTop: "30px", display: "flex" }}
                    >
                      <div className="col-md-6" style={{ width: "38%" }}>
                        <div className="form-group d-flex align-items-center justify-content-evenly gap-2">
                          <span
                            style={{ width: "170px", fontWeight: "bold" }}
                            for="deliveryCharge"
                          >
                            Delivery Charge:
                          </span>
                          <input
                            onChange={(e) =>
                              setDeliveryChargeInputValue(
                                parseFloat(e.target.value)
                              )
                            }
                            name="delivery_charge"
                            type="number"
                            step={"any"}
                            className="form-control"
                            id="deliveryCharge"
                            aria-describedby="deliveryCharge"
                            placeholder="Delivery Charge"
                          />
                        </div>
                      </div>
                      <div
                        className="col-md-6  text-center"
                        style={{ width: "48%", marginLeft: "100px" }}
                      >
                        <div
                          className="d-flex  flex-column align-items-baseline justify-content-end"
                          style={{ textAlign: "left" }}
                        >
                          <h4 style={{ color: "black", fontWeight: "bold" }}>
                            {" "}
                            Total:{" "}
                            <span
                              style={{ fontSize: "15px", fontWeight: "bolder" }}
                            >
                              {" "}
                              ৳{" "}
                            </span>
                            {numericFormatter(totalSubTotal) || 0}
                          </h4>
                          <h4 style={{ color: "black", fontWeight: "bold" }}>
                            Delivery Charge:{" "}
                            <span
                              style={{ fontSize: "15px", fontWeight: "bolder" }}
                            >
                              {" "}
                              ৳{" "}
                            </span>
                            {(deliveryChargeInputValue &&
                              numericFormatter(deliveryChargeInputValue)) ||
                              0}
                          </h4>
                          <h4 style={{ color: "black", fontWeight: "bold" }}>
                            Grand Total:
                            <span
                              style={{ fontSize: "15px", fontWeight: "bolder" }}
                            >
                              {" "}
                              ৳
                            </span>{" "}
                            {numericFormatter(grandTotal) || 0}
                          </h4>
                          <h4 style={{ color: "black", fontWeight: "bold" }}>
                            Total Paying:{" "}
                            <span
                              style={{ fontSize: "15px", fontWeight: "bolder" }}
                            >
                              {" "}
                              ৳
                            </span>{" "}
                            {(payingAmount && numericFormatter(payingAmount)) ||
                              0}
                          </h4>
                          <h4 style={{ color: "black", fontWeight: "bold" }}>
                            Due:{" "}
                            <span
                              style={{ fontSize: "15px", fontWeight: "bolder" }}
                            >
                              {" "}
                              ৳
                            </span>{" "}
                            {numericFormatter(totalDueAmount) || 0}
                          </h4>
                          <h4 style={{ color: "black", fontWeight: "bold" }}>
                            Return:{" "}
                            <span
                              style={{ fontSize: "15px", fontWeight: "bolder" }}
                            >
                              {" "}
                              ৳{" "}
                            </span>
                            {numericFormatter(changeReturnData) || 0}{" "}
                          </h4>
                        </div>
                      </div>
                    </div>

                    <section className="mt-5">
                      <h4 className="fw-bold mb-2">Payments Information :</h4>
                      <div
                        style={{
                          backgroundColor: "#F2F2F4",
                          padding: "10px",
                          borderRadius: "5px",
                        }}
                      >
                        <div className="row">
                          <div className="form-group col-md-4">
                            <label for="amount" className="fw-bold mb-1">
                              Amount
                            </label>
                            <input
                              name="amount"
                              type="number"
                              step={"any"}
                              className="text-input cs-text-input border"
                              id="amount"
                              aria-describedby="amountHelp"
                              placeholder="Amount"
                              onChange={(e) =>
                                setPayingAmount(parseFloat(e.target.value))
                              }
                            />
                          </div>

                          <div className=" form-group col-md-4">
                            <label for="paymentMethod" className="fw-bold mb-1">
                              {" "}
                              Payment Type{" "}
                            </label>
                            <UncontrolledDropdown className="white w-100">
                              <DropdownToggle
                                className="w-100"
                                style={{ backgroundColor: "#FFFFFF" }}
                                caret
                                color="white"
                              >
                                {paymentMethod}
                              </DropdownToggle>
                              <DropdownMenu
                                className="text-center w-100 overflow-auto h-auto p-2"
                                style={{
                                  maxHeight: "150px",
                                  boxShadow:
                                    "0px 0px 30px 10px rgb(197, 194, 194)",
                                }}
                              >
                                {paymentTypeData?.map((paymentData) => (
                                  <DropdownItem
                                    className="w-100 text-center text-dark mt-1 border rounded-1 bg-black-hover p-0"
                                    key={paymentData.id}
                                    onClick={(e) =>
                                      setPaymentMethod(paymentData.payment_type)
                                    }
                                  >
                                    {paymentData?.payment_type}
                                  </DropdownItem>
                                ))}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>

                          <div className=" form-group col-md-4">
                            <label for="amount" className="fw-bold mb-1">
                              {" "}
                              Account{" "}
                            </label>
                            <UncontrolledDropdown className=" w-100">
                              <DropdownToggle
                                className="border w-100"
                                caret
                                color="white"
                                style={{ backgroundColor: "#FFFFFF" }}
                              >
                                {accountMethodName}
                              </DropdownToggle>
                              <DropdownMenu
                                className="text-center w-100 overflow-auto h-auto p-2"
                                style={{
                                  maxHeight: "150px",
                                  boxShadow:
                                    "0px 0px 30px 10px rgb(197, 194, 194)",
                                }}
                              >
                                {accountTypeData?.map((accountType) => (
                                  <DropdownItem
                                    className="w-100 text-center text-dark mt-1 border rounded-1 bg-black-hover p-0"
                                    key={accountType.id}
                                    onClick={(e) => {
                                      setAccountMethodName(
                                        accountType.account_name
                                      );
                                      setAccountMethodId(accountType.id);
                                    }}
                                  >
                                    {accountType.account_name}
                                  </DropdownItem>
                                ))}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                        </div>
                        <div className="form-group col-md-12">
                          <label
                            className="fw-bold mb-1"
                            for="exampleFormControlTextarea1"
                          >
                            Payment Note
                          </label>
                          <textarea
                            className="text-input cs-text-input border"
                            onChange={(e) => setPaymentNote(e.target.value)}
                            id="exampleFormControlTextarea1"
                            rows="3"
                          ></textarea>
                        </div>
                      </div>
                    </section>
                    {/* button section */}
                    <div className="col-md-12 d-flex justify-content-center align-items-center gap-5 mt-3">
                      <Button
                        style={{ backgroundColor: "green" }}
                        color="primary"
                        type="submit"
                        className="btn-add"
                      >
                        Save
                      </Button>
                      <Link to={"/dashboard"}>
                        <Button color="secondary" type="reset">
                          Cancel
                        </Button>
                      </Link>
                    </div>
                  </section>
                </div>
              </form>
            </CardBody>
          </Card>
        </Container>
      </Fragment>
    </>
  );
};

export default NewPurchase;
