import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { FormGroup, Label } from "reactstrap";
import { AiFillCloseCircle } from "react-icons/ai";
import useGetData from "../../../hooks/useGetData";
import useClickOutside from "../../../hooks/useClickOutside";
import useNumberFormatter from "../../../hooks/useNumberFormatter";
import Swal from "sweetalert2";
import { CustomToast } from "../../../hooks/useCustomToast";
import axiosInstance from "../../../util/axiosInstance";

const PosMultipleCashPayment = ({
  multiplePayments,
  cashPayment,
  sectionsId,
  closeButton,
  setAnotherPaymentSections,
  anotherPaymentSections,
  paymentDropdownValue,
  register,
  setPaymentDropdownValue,
  accountDropdownValue,
  setAccountDropdownValue,
  setPaidValue,
  totalPayable,
  setPayingBalanceData,
  setAccountPaymentIdDropdown,
  setMultiplePayingBalanceData,
  setMultipleAccountPaymentIdDropdown,
  setMultiplePaymentType,
  setCashModalOpen,
  setMultipleModalOpen,
}) => {
  const numericFormatter = useNumberFormatter();

  // get data from backend
  const [paymentTypeData] = useGetData(
    "/paymenttype/viewpaymenttypes/",
    "paymenttype"
  );
  // const [accountDropDownData] = useGetData(
  //   "/accounts/viewaccounts/",
  //   "accounts"
  // );
  const [accountDropDownData, setAccountDropDownData] = useState([]);
  useEffect(()=>{
    console.log('object');
    try {
      axiosInstance.get("/accounts/viewaccounts/").then((response)=>{
        const cashAccount = response?.data.find(item => item.account_name === "CASH");
        if (cashAccount) {
          setAccountPaymentIdDropdown(cashAccount.id);
          setAccountDropdownValue(cashAccount.account_name);
        }
        setAccountDropDownData(response?.data)   
        
      })
    }
    catch (error) {
      console.log('err');        
    }
  
  },[])
  const [isPaymentDropdownOpen, setIsPaymentDropdownOpen] = useState(false);
  const [isAccountDropdownOpen, setIsAccountDropdownOpen] = useState(false);
  const dropdownMenuOne = useRef(null);
  const dropdownMenuTwo = useRef(null);

  useClickOutside(dropdownMenuOne, () => setIsPaymentDropdownOpen(false));
  useClickOutside(dropdownMenuTwo, () => setIsAccountDropdownOpen(false));

  // Function to remove a payment section by index
  const removePaymentSection = (e) => {
    e.preventDefault();
    const newPaymentSections = [...anotherPaymentSections];
    newPaymentSections.splice(sectionsId - 101, 1);
    setAnotherPaymentSections(newPaymentSections);
  };

  const [payableAmount, setPayableAmount] = useState();
  useEffect(() => {
    setPayableAmount(totalPayable);
  }, [totalPayable]);

  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e) => {
    const newInputValue = parseFloat(e.target.value);

    if (newInputValue >= numericFormatter(payableAmount || 0)) {
      // Display the alert
      CustomToast("error", `Please make a single payment in Cash Method!`);
      setInputValue("");
      setMultipleModalOpen(false);
      setCashModalOpen(true);
    } else {
      // Update the input value
      setInputValue(newInputValue);

      // Perform other actions as needed
      setPaidValue && setPaidValue(newInputValue);
      setMultiplePayingBalanceData &&
        setMultiplePayingBalanceData(newInputValue);
    }
  };
  return (
    <>
      <div
        style={{
          width: "100%",
          boxShadow: "0px 0px 30px 5px rgb(187, 186, 190)",
        }}
        className=" p-3 rounded-2 position-relative"
      >
        {closeButton && (
          <AiFillCloseCircle
            onClick={removePaymentSection}
            className="position-absolute cs-cursor-pointer top-0 mt-1 me-2 end-0 fs-4 text-danger"
          />
        )}
        {/* for multiple cash */}
        {multiplePayments && (
          <div className=" d-flex justify-content-between align-items-start ">
            <FormGroup className="" style={{ width: "48%" }}>
              <Label for="amount" className="mb-1 ">
                Amount
              </Label>
              <input
                type="number"
                step={"any"}
                name="amount"
                className="text-input cs-text-input border"
                id="amount"
                placeholder="Enter Amount"
                {...register("amount", { required: true })}
                value={inputValue}
                onChange={handleInputChange}
              />
            </FormGroup>
            {/* payment method */}
            <div className=" " style={{ width: "48%" }}>
              <Label for="payment" className="mb-1 text-dark">
                Payment Method
              </Label>
              <div className=" d-flex justify-content-between align-items-center border rounded-1 ">
                <Dropdown
                  ref={dropdownMenuOne}
                  className="w-100"
                  show={isPaymentDropdownOpen}
                  onToggle={() => setIsPaymentDropdownOpen((x) => !x)}
                >
                  <Dropdown.Toggle
                    variant=""
                    name="paymentMethod"
                    id="payment"
                    className="border-0 w-100 text-start fw-normal"
                    value={paymentDropdownValue}
                  >
                    {paymentDropdownValue}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    style={{
                      maxHeight: "25vh",
                      boxShadow: "0px 0px 30px 10px rgb(197, 194, 194)",
                    }}
                    className=" overflow-auto rounded-2 h-auto  d-flex   flex-column p-1 gap-1 "
                  >
                    {paymentTypeData?.map((item, index) => (
                      <Dropdown.Item
                        key={index}
                        className=" w-100 text-center text-dark border rounded-1 bg-black-hover p-0"
                        onClick={() => {
                          setPaymentDropdownValue(item.payment_type);
                          setMultiplePaymentType &&
                            setMultiplePaymentType(item.payment_type);
                        }}
                      >
                        <option
                          className="color-white-hover "
                          style={{ fontSize: "15px" }}
                          value={item.payment_type}
                        >
                          {item.payment_type}
                        </option>
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        )}
        {/* for cash payment  */}
        {cashPayment && (
          <FormGroup className="" style={{ width: "100%" }}>
            <Label for="paymentAmount" className="mb-1 ">
              Amount
            </Label>
            <input
              type="number"
              step={"any"}
              name="paymentAmount"
              className="text-input cs-text-input border"
              id="paymentAmount"
              placeholder={`Enter Amount ${payableAmount}৳`}
              {...register("paymentAmount", { required: true })}
              onChange={(e) => {
                setPaidValue && setPaidValue(e.target.value);
                // setPayingAmount(e.target.value);
                setPayingBalanceData &&
                  setPayingBalanceData(parseFloat(e.target.value));
              }}
            />
          </FormGroup>
        )}
        {/* for cash payment  */}
        {/* account method */}
        <div className=" " style={{ width: "100%" }}>
          <Label className="mb-1 text-dark">Account</Label>
          <div className=" d-flex justify-content-between align-items-center border rounded-1 ">
            <Dropdown
              ref={dropdownMenuTwo}
              className="w-100"
              show={isAccountDropdownOpen}
              onToggle={() => setIsAccountDropdownOpen((x) => !x)}
            >
              <Dropdown.Toggle
                variant=""
                name="accountMethod"
                id="account"
                className="border-0 w-100 text-start fw-normal"
                value={accountDropdownValue}
              >
                {accountDropdownValue}
              </Dropdown.Toggle>

              <Dropdown.Menu
                style={{
                  maxHeight: "20vh",
                  boxShadow: "0px 0px 30px 10px rgb(197, 194, 194)",
                }}
                className="w-50 overflow-auto rounded-2 h-auto  d-flex  flex-column  p-1 gap-1 "
              >
                {accountDropDownData?.map((item, index) => (
                  <Dropdown.Item
                    key={index}
                    className=" w-100 text-center text-dark border rounded-1 bg-black-hover p-0"
                    onClick={() => {
                      
                      setAccountDropdownValue(item.account_name);
                      setAccountPaymentIdDropdown &&
                        setAccountPaymentIdDropdown(item.id);
                      setMultipleAccountPaymentIdDropdown &&
                        setMultipleAccountPaymentIdDropdown(item.id);
                    }}
                  >
                    <option
                      className="color-white-hover "
                      style={{ fontSize: "15px" }}
                      value={item.account_name}
                    >
                      {item.account_name}
                    </option>
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        {/* payment note */}
        <div className=" mt-3" style={{ width: "100%" }}>
          <FormGroup>
            <Label for="paymentNote" className="mb-1 ">
              Payment Note
            </Label>
            <textarea
              id="paymentNote"
              rows={2}
              placeholder="Enter Payment Note"
              className="cs-text-input  text-input"
            ></textarea>
          </FormGroup>
        </div>
      </div>
    </>
  );
};

export default PosMultipleCashPayment;
