import React from "react";

import { BsFillBagCheckFill } from "react-icons/bs";
import { FaMoneyBillAlt } from "react-icons/fa";
import { FaHandHoldingDollar, FaSackDollar } from "react-icons/fa6";
import TotalCalculation from "../../../components/DashboardComponents/TotalCalculation";

const PurchaseReturnAmountSection = () => {
  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <TotalCalculation
          icons={
            <BsFillBagCheckFill
              style={{ color: "#3053d3" }}
            ></BsFillBagCheckFill>
          }
          amount={100}
          iconBackground={"#ffffff"}
          bgColor={"#5856d6"}
          title={"TOTAL INVOICES"}
        ></TotalCalculation>
        <TotalCalculation
          icons={<FaSackDollar style={{ color: "#278b8b" }}></FaSackDollar>}
          amount={0}
          bgColor={"#13c9ca"}
          iconBackground={"#ffffff"}
          title={"TOTAL INVOICES AMOUNT"}
        ></TotalCalculation>
        <TotalCalculation
          icons={<FaMoneyBillAlt style={{ color: "#cc7e11" }}></FaMoneyBillAlt>}
          bgColor={"rgb(232, 62, 140)"}
          amount={0}
          iconBackground={"#ffffff"}
          title={"TOTAL PAID AMOUNT"}
        ></TotalCalculation>
        <TotalCalculation
          icons={
            <FaHandHoldingDollar
              style={{ color: "#10c528" }}
            ></FaHandHoldingDollar>
          }
          amount={0}
          bgColor={"rgb(0, 109, 251)"}
          iconBackground={"#ffffff"}
          title={"TOTAL PURCHASE DUE"}
        ></TotalCalculation>
      </div>
    </>
  );
};

export default PurchaseReturnAmountSection;
