import React, { Fragment, useState } from 'react'
import Breadcrumb from '../../../components/common/breadcrumb'
import { Button, Card, CardBody, CardHeader, Col } from 'reactstrap'
import { Link } from 'react-feather'

import AccountListTable from '../../../components/accounts/AccountListTable'
import SingleCalender from './components/SingleCalender'
import TransferAccounts from '../MoneyTransfer/components/TransferAccounts'
import axios from '../../../util/axiosInstance'
import { useNavigate } from 'react-router-dom'
import DepositeListTable from '../../../components/accounts/DepositeListTable'
import moment from 'moment'
import TransferDeposit from './components/TransferDeposit'
const token = localStorage.getItem('access-token');
const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
}


const NewMoneyDepositeScreen = () => {
    const [isCalenderOpen, setCalenderOpen] = useState(false)
    const [date, setDate] = useState(false)

    const [note, setNote] = useState(null);
    const [amount, setAmount] = useState(null);
    const [referenceCode, setReferenceCode] = useState('');
    const [DebitAcc, setDebitAcc] = useState(null);
    const [creditAcc, setCreditAcc] = useState(null);
    const navigate = useNavigate()






    const newMoneyDepositeHandler = () => {
        const gdd = {
            "transfer_date": moment(date).format().substring(0, 10),
            "reference_no": referenceCode,
            "debit_account_id": DebitAcc.account_name,
            "credit_account_id": creditAcc.account_name,
            "amount": amount,
            "note": note
        }

        axios.post('/accounts/money-deposits', gdd, { headers }).then(res => {
            (res.status === 201 || res.status === 200) && navigate('/dashboard')

        })
    }


    return (
        <Fragment>
            <Breadcrumb
                title='Add Money deposite'
                parent='Account'
            />
            <Col sm='12'>
                <Card>
                    <CardHeader className='d-flex justify-content-between'>

                        <h5>New money deposite</h5>
                        {/* <Link to='/accounts/money_transfer/add' className='bg-info py-2 text-decoration-none me-4 px-4 rounded-1'>+ New money Transfer </Link> */}
                    </CardHeader>


                    <CardBody>
                        <div>
                            <div className='d-flex col-12 flex-column flex-md-row p-2 rounded-2 ' style={{ backgroundColor: "#f6f6f6" }}>
                                <div className='d-flex py-2 col-6 col-md-3 pe-2'>
                                    <SingleCalender setCalenderOpen={setCalenderOpen} isCalenderOpen={isCalenderOpen} setDate={setDate} date={date} />
                                </div>
                                <div className='  col-12 col-md-9 pe-2' >
                                    <div className='d-flex justify-content-around py-3 '>
                                        <input type='text' className='col-5 me-2 rounded-2 bg-transperent p-2 ' defaultValue={amount} onChange={(e) => setAmount(e.target.value)} placeholder='Transfer Amount * ' />
                                        <input type='text' className='col-5 p-2 rounded-2 bg-transperent ' placeholder='reference number * ' defaultValue={referenceCode} onChange={(e) => setReferenceCode(e.target.value)} />
                                    </div>

                                    <div className='d-flex justify-content-around py-3 '>
                                        <TransferDeposit setDebitAcc={setDebitAcc} setCreditAcc={setCreditAcc} DebitAcc={DebitAcc} creditAcc={creditAcc} />
                                    </div>

                                    <div class="form-floating col-12 py-3 px-4 ">
                                        <textarea class="form-control " placeholder="Leave a comment here" id="floatingTextarea2" onChange={(e) => setNote(e.target.value)} ></textarea>
                                        <label className='px-5' for="floatingTextarea2">Note</label>
                                    </div>
                                    {/* <textarea type='text' rows={6} className='col-11 mx-auto rounded-2' placeholder="note"  /> */}

                                </div>
                            </div>
                            <div className='d-flex col-10 mx-auto justify-content-center my-3'>
                                <Button className='btn-info mx-3 text-white' onClick={newMoneyDepositeHandler}>save</Button>
                                <Button >cancel</Button>
                            </div>
                        </div>
                        {/* <div className='d-flex col-11'> */}
                        {/* <TransferAccounts setQueryData={setQueryData} />
                            <DateRangePickerSingle dateSelect={dateSelect} date={date} setCalenderOpen={setCalenderOpen} /> */}
                        {/* </div> */}

                        <div className='category-table order-table coupon-list-delete mt-4'>
                            {/* <DepositeListTable /> */}


                        </div>
                    </CardBody>
                </Card>
            </Col>

        </Fragment>
    )
}

export default NewMoneyDepositeScreen