import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

const PosHoldModal = ({ isOpen, toggle }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const onSubmit = (data) => {
    reset();
    toggle();
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      style={{ maxWidth: "35%" }}
    >
      <ModalHeader
        className="d-flex justify-content-center  align-items-center"
        style={{
          backgroundImage: "linear-gradient(to right, #4b0ab3, #090080)", // Gradient background
        }}
      >
        <p className="text-white w-100 m-0 fw-bold fs-5">Hold</p>
      </ModalHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <h3 className="fs-3 text-black text-center">
            Hold Invoice ? Same Reference will replace the old list if exist!!
          </h3>
          <FormGroup className="mt-4">
            <input
              type="text"
              className="cs-text-input border text-input"
              name="referenceNumber"
              placeholder="Please Enter Reference Number"
              {...register("referenceNumber", { required: true })}
            />
          </FormGroup>
        </ModalBody>
        <div className="modal-footer border-0">
          <Button
            className="text-white"
            style={{ backgroundColor: "red" }}
            color="red"
            type="reset"
            onClick={toggle}
          >
            Cancel
          </Button>
          <Button
            style={{ backgroundColor: "green" }}
            color="green"
            type="submit"
            className="text-white"
          >
            Save
          </Button>{" "}
        </div>
      </form>
    </Modal>
  );
};

export default PosHoldModal;
