import React, { Fragment, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import AccountListTable from '../../components/accounts/AccountListTable';
import Breadcrumb from '../../components/common/breadcrumb';
import DateRangePickerComponent from '../SalesManagement/DateRangePicker';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import { useRef } from 'react';
import useGetData from '../../hooks/useGetData';
import { useEffect } from 'react';
import { addDays } from 'date-fns';
import BranchNameSelectDropdown from './BranchNameSelectDropdown';
import axiosInstance from '../../util/axiosInstance';
import TableLoading from '../../components/common/loading/TableLoading';



const ProfitAndLossReport = () => {
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [openingStockData,  setOpeningStockData] = useState([]);
  const [SalesGrandTotal,   setSalesGrandTotal] =useState([]);
  const [getPurchaseData,   setGetPurchaseData] =useState([]);
  const [getSalesReturnData,   setGetSalesReturnData] =useState([]);
  const [getPurchaseReturnData,   setGetPurchaseReturnData] =useState([]);
  const [getExpenseData,   setGetExpenseData] =useState([]);
  const [getPurchaseTotal,   setGetPurchaseTotal] =useState(0);
  const [getSalesReturnTotal,   setGetSalesReturnTotal] =useState(0);
  const [getPurchaseReturnTotal,   setGetPurchaseReturnTotal] =useState(0);
  const [getExpenseTotal,   setGetExpenseTotal] =useState(0);
  const [salesGrandTotalNumber,   setSalesGrandTotalNumber] =useState(0);
  const [grandTotalItemCost,   setGrandTotalItemCost] =useState(0)
  const [openingStockTotal,   setOpeningStockTotal] =useState(0);





  const [queryData, setQueryData] = useState({
    warehouse_name: "All-Branch",
    supplierName_name: 'All-Supplier',
    category_name: '',
    lot_number: "",
    mobile: "",
    warehouse_address: ''


})

const [ApiLink, setApiLink] = useState('')
 

  // time and date
  const now = moment();
  const formattedDate = now.format("DD-MM-YYYY");
  const formattedTime = now.format("hh:mm A");

  
  const showDataHandler = async () => {
    try {
      setOpen(true);
      setLoading(true)
  
      const openingStockResponse = await axiosInstance.get('/report/get-all-item-stock');
      const salesDataResponse = await axiosInstance.get(`/report/get-sales-data?${ApiLink}`);
      const purchaseDataResponse = await axiosInstance.get(`/report/get-purchase-data?${ApiLink}`);
      const salesReturnResponse = await axiosInstance.get(`/report/get-sales-return-data?${ApiLink}`);
      const purchaseReturnResponse = await axiosInstance.get(`/report/get-purchase-return-data?${ApiLink}`);
      const expenseDataResponse = await axiosInstance.get(`/report/get-expense-data?${ApiLink}`);
  
      setOpeningStockData(openingStockResponse?.data);
      setSalesGrandTotal(salesDataResponse?.data);
      setGetPurchaseData(purchaseDataResponse?.data);
      setGetSalesReturnData(salesReturnResponse?.data);
      setGetPurchaseReturnData(purchaseReturnResponse?.data);
      setGetExpenseData(expenseDataResponse?.data);
  
      setGetPurchaseTotal(purchaseDataResponse?.data?.reduce((acc, item) => acc + item?.grand_total, 0));
      setGetSalesReturnTotal(salesReturnResponse?.data?.reduce((acc, item) => acc + item?.grand_total, 0));
      setGetPurchaseReturnTotal(purchaseReturnResponse?.data?.reduce((acc, item) => acc + item?.grand_total, 0));
      setGetExpenseTotal(expenseDataResponse?.data?.reduce((acc, item) => acc + item?.expense_amt, 0));
      setSalesGrandTotalNumber(salesDataResponse?.data?.reduce((acc, item) => acc + item?.grand_total, 0));
      setOpeningStockTotal(openingStockResponse?.data?.reduce((acc, item) => acc + item?.total_value, 0));
      
      setGrandTotalItemCost(salesDataResponse?.data?.reduce((acc, item) => acc + item?.purchase_price * item?.sales_qty, 0));
      
      setLoading(false)


  
    } catch (error) {
      console.error('Error fetching data', error);
      // Handle the error as required, maybe show a notification or error message to the user
    }
  }
  

  const [stateM, setStateM] = useState([
    {
        startDate: new Date(),
        endDate: addDays(new Date(), 0),
        key: 'selection',
    },
]);
const startDate = moment(stateM[0].startDate).format().substring(0, 10)
const endDate = moment(stateM[0].endDate).format().substring(0, 10)

useEffect(() => {
  // Api link Here
    if (queryData.warehouse_name) {
        setApiLink(`warehouse_name=${queryData.warehouse_name}&start_date=${startDate}&end_date=${endDate}`)        
    }
}, [queryData.warehouse_name, endDate, startDate])


  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });


  const TheStyle = {background: '#007cff', color: "#fff"}

  return (
    <Fragment>
      <Breadcrumb
        title='Profit & Loss Report'
        parent='reports'
      />

      {/* <DateRangePickerComponent /> */}
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Card>
              <CardHeader>
                <h5>Profit & Loss Report </h5>

                <div className='d-flex gap-2'>
                  <div className=' col-md-3 '>
                    <BranchNameSelectDropdown
                      apiLink='/branch/viewbranch'
                      queryData={queryData}
                      setQueryData={setQueryData}
                      InitialDropdownValue='All-Branch'
                    />
                  </div>
                  <div className='col-md-8'>
                    <DateRangePickerComponent
                      setStateM={setStateM}
                      stateM={stateM}
                      queryData={queryData}
                      setQueryData={setQueryData}
                    />
                  </div>
                </div>
              </CardHeader>
              <Button
                onClick={showDataHandler}
                className='col-xl-2 col-md-3 col-sm-6 col-9 mx-auto'>
                Show{' '}
              </Button>
              <CardBody>
                {/* <div className='category-table order-table coupon-list-delete'> */}
                {/* <AccountListTable /> */}
                {/* <Datatable
                    multiSelectOption={true}
                    myData={data}
                    pageSize={10}
                    pagination={true}
                    class='-striped -highlight'
                  /> */}
                {/* </div> */}

                {queryData.warehouse_name !== 'All-Branch' &&
                  queryData.warehouse_name &&
                  isOpen && (
                    <>
                      <div
                        ref={componentRef}
                        className='container px-2 bg-white category-table order-table coupon-list-delete mt-4'>
                        <div>
                          <h3 className='text-left'>Profit and Loss</h3>
                          <p className='text-left'>
                            {' '}
                            This report is generated by branch and date wise{' '}
                          </p>
                          {/* <img
                        src='https://shaangents.apodigi.com/theme/images/shaan.png'
                        alt='logo'
                        height='100'
                        width='100'
                        style={{ filter: 'brightness(0%)' }}
                      /> */}
                          <div className='text-center'>
                            <h3 className='fs-4 text-dark fw-700 m-0'>
                              {queryData.warehouse_name}
                            </h3>
                            <p className='text-black m-0'>
                              {queryData.warehouse_address}
                            </p>
                            <p className='text-black m-0'>
                              Phone: {queryData.mobile}
                            </p>
                            {/* Render other store details here */}
                          </div>
                        </div>
                        {/* <div
                      className='d-flex justify-content-between align-items-start py-2 mt-2'
                      style={{
                        borderBottom: '2px dashed black',
                        borderTop: '2px dashed black',
                      }}>
                      <div className=' d-flex flex-column'>
                        <p className='p-0 m-0 text-dark fw-bold'>Invoice:</p>
                        <p className='p-0 m-0 text-dark fw-bold'>Name:</p>
                        <p className='p-0 m-0 text-dark fw-bold'>Date:</p>
                        <p className='p-0 m-0 text-dark fw-bold'>Time:</p>
                      </div>
                      <div className='d-flex flex-column '>
                        <p className='p-0 m-0 text-dark  fw-bold'>

                        </p>
                        <p className='p-0 m-0 text-dark fw-bold'>
                          invoice one
                        </p>

                        <p className='p-0 m-0 text-dark fw-bold'>
                          yasin
                        </p>
                        <p className='p-0 m-0 text-dark fw-bold'>
                          {formattedDate}
                        </p>
                        <p className='p-0 m-0 text-dark fw-bold'>
                          {formattedTime}
                        </p>

                      </div>
                    </div> */}
                        <table className='text-center  table-bordered col-md-10  mx-auto mt-3 mb-2 profitnloss'>
                          <thead>
                            <tr>
                              <th
                                className='d-none'
                                scope='col'>
                                sl
                              </th>
                              <th scope='col'>Particulars</th>
                              <th scope='col'>Current Period</th>
                            </tr>
                          </thead>

                          <tbody>
                            {isLoading ? (
                              <TableLoading
                                noRow={7}
                                noTd={2}
                              />
                            ) : (
                              <>
                                {/* part one  */}
                                <tr>
                                  <td
                                    rowSpan='2'
                                    className='d-flex '>
                                    <span className='me-2 text-right d-block'>
                                      {' '}
                                      Sales{' '}
                                    </span>
                                  </td>
                                  <td>
                                    {salesGrandTotalNumber?.toLocaleString() ||
                                      0}{' '}
                                  </td>
                                </tr>

                                <tr>
                                  <td
                                    rowspan='2'
                                    className='d-flex justify-content-between '>
                                    <span className='me-2 '>Sales return</span>
                                  </td>
                                  <td>
                                    {getSalesReturnTotal?.toLocaleString() || 0}
                                  </td>
                                </tr>

                                <tr
                                  className='netsale'
                                  style={TheStyle}>
                                  <td className='fw-bold'>Net Sales (A)</td>
                                  <td>
                                    {(
                                      salesGrandTotalNumber -
                                      getSalesReturnTotal
                                    ).toLocaleString()}
                                  </td>
                                </tr>

                                <tr>
                                  <td className='d-flex justify-content-between'>
                                    <span>Opening Stock </span>
                                  </td>
                                  <td>
                                    {' '}
                                    {openingStockTotal?.toLocaleString() || 0}
                                  </td>
                                </tr>

                                <tr>
                                  <td className='d-flex justify-content-between'>
                                    <span>add purchase during period </span>
                                  </td>
                                  <td>
                                    {getPurchaseTotal?.toLocaleString() || 0}
                                  </td>
                                </tr>
                                <tr>
                                  <td className='d-flex justify-content-between'>
                                    <span>Purchase Return </span>
                                  </td>
                                  <td>
                                    {getPurchaseReturnTotal?.toLocaleString() ||
                                      0}
                                  </td>
                                </tr>

                                {/* part two  */}

                                <tr
                                  style={{
                                    background: '#ff8100',
                                    color: '#fff',
                                    fontWeight: 900,
                                  }}>
                                  <td className='fw-bold'>
                                    Cost Of Goods Sold (B){' '}
                                  </td>
                                  <td>
                                    {' '}
                                    {grandTotalItemCost?.toLocaleString()}
                                  </td>
                                </tr>

                                <tr
                                  style={{
                                    background: '#0aa8a3',
                                    color: '#fff',
                                    fontWeight: 900,
                                  }}>
                                  <td className='fw-bold'>
                                    Gross Profit C=(A-B){' '}
                                  </td>
                                  <td>
                                    {(
                                      salesGrandTotalNumber -
                                      getSalesReturnTotal -
                                      grandTotalItemCost
                                    ).toLocaleString()}
                                  </td>
                                </tr>

                                <tr
                                  style={{
                                    background: '#ff0357',
                                    color: '#fff',
                                    fontWeight: 900,
                                  }}>
                                  <td className='fw-bold'>
                                    Operating Expenses (D){' '}
                                  </td>
                                  <td>
                                    {getExpenseTotal?.toLocaleString() || 0}{' '}
                                  </td>
                                </tr>
                                {getExpenseData?.map((item) => (
                                  <tr key={item.id}>
                                    <td
                                      rowspan='2'
                                      className='d-flex justify-content-between '>
                                      <span>{item.category_name}</span>
                                    </td>
                                    <td>
                                      {item?.expense_amt?.toLocaleString()}
                                    </td>
                                  </tr>
                                ))}

                                <tr
                                  style={{
                                    background: '#0d0d44',
                                    color: '#fff',
                                    fontWeight: 900,
                                  }}>
                                  <td className='fw-bold'>
                                    Net Profit (C - D){' '}
                                  </td>
                                  <td className='fw-bold'>
                                    {(
                                      salesGrandTotalNumber -
                                      getSalesReturnTotal -
                                      grandTotalItemCost -
                                      getExpenseTotal
                                    )?.toLocaleString() || 0}
                                  </td>
                                </tr>

                                {/* part two  */}
                              </>
                            )}
                          </tbody>
                        </table>

                        <div className='text-center mt-1'>
                          {!isLoading && (
                            <p className='text-dark h4 my-3'>
                              {salesGrandTotalNumber -
                                getSalesReturnTotal -
                                grandTotalItemCost -
                                getExpenseTotal >
                              0 ? (
                                <span
                                  className='px-3 py-1 rounded'
                                  style={{
                                    background: '#0aa8a3',
                                    color: '#fff',
                                    fontWeight: 900,
                                  }}>
                                  WOW! you are doing well. Congrats, You're
                                  Profitable
                                </span>
                              ) : (
                                <span
                                  className='px-3 py-1 rounded'
                                  style={{
                                    background: '#ff0357',
                                    color: '#fff',
                                  }}>
                                  OPS! You're in Loss
                                </span>
                              )}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className='d-flex align-items-center justify-content-center mt-5'>
                        <Button onClick={handlePrint}>Print</Button>
                      </div>
                    </>
                  )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ProfitAndLossReport;
