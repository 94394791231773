import React from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import SalesReturnPaymentInformationTableSection from "./SalesReturnPaymentInformationTableSection";
import useGetData from "../../../hooks/useGetData";

const SalesReturnPayments = () => {
  const [
    salesReturnPaymentListData,
    salesReturnPaymentListDataRefetch,
    salesReturnPaymentListDataIsLoading,
  ] = useGetData(
    `/salesreturn/get-sales-payments-return`,
    "salesReturnPaymentListData"
  );
  return (
    <>
      <Breadcrumb
        title="Sales Return Payments List"
        parent="Sales Management"
      />
      <main className=" w-100 px-2">
        <section
          className="bg-white rounded-2 pb-4"
          style={{ boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)" }}
        >
          <SalesReturnPaymentInformationTableSection
            salesReturnPaymentListData={salesReturnPaymentListData}
            salesReturnPaymentListDataRefetch={
              salesReturnPaymentListDataRefetch
            }
            salesReturnPaymentListDataIsLoading={
              salesReturnPaymentListDataIsLoading
            }
          ></SalesReturnPaymentInformationTableSection>
        </section>
      </main>
    </>
  );
};

export default SalesReturnPayments;
