import React from "react";

const InputLoading = () => {
  return (
    <section className="p-4 w-100">
      {/* section-1 */}
      <div className="d-flex justify-content-between">
        {/* one */}
        <div
          className=" bg-body-secondary rounded-1 px-5"
          style={{ width: "48%", height: "35px" }}
        ></div>
        {/* two */}
        <div
          className="d-flex justify-content-between"
          style={{ width: "48%", height: "35px" }}
        >
          <div className="bg-body-secondary col-7 rounded-1"></div>
          <div className="bg-body-secondary col-4 rounded-1"></div>
        </div>
      </div>
      {/* section-2 */}
      <div className="w-100 mt-3 d-flex justify-content-between">
        {/* one */}
        <div
          className="bg-body-secondary rounded-1 px-5"
          style={{ width: "48%", height: "35px" }}
        >
          <div className="bg-body-secondary"></div>
        </div>
        {/* two */}
        <div
          className="bg-body-secondary rounded-1 px-5"
          style={{ width: "48%", height: "35px" }}
        >
          <div className="bg-body-secondary"></div>
        </div>
      </div>
    </section>
  );
};

export default InputLoading;
