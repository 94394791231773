import React from "react";
import useNumberFormatter from "../../../hooks/useNumberFormatter";
var converter = require("number-to-words");

const PurchaseInvoiceFooter = ({ data }) => {
  const numericFormatter = useNumberFormatter();

  const { grand_total } = data || {};

  return (
    <main className="mt-3">
      <section className=" text-black">
        <div className="border border-black px-2 py-1 fs-6 fw-500 d-flex gap-1">
          <span>In Words : </span>
          <span className="fw-700"> (BDT) </span>
          <span className="text-uppercase">
            {converter
              .toWords(numericFormatter(grand_total || 0))
              .replace(/,/g, " ")}{" "}
            Taka
          </span>
        </div>
        <div className="border border-dark px-2 py-3 mt-2 fs-6 fw-500">
          {/* <span>In Words : </span> */}
        </div>
      </section>
      {/* sign section */}
      <section className="border border-black border-top-0 mx-4 p-4 ">
        {/* child-1-1 */}
        <div className="col-9 mx-auto text-end">
          <span className="fs-6 text-black fw-700 border-bottom border-black border-2">
            For Shangu Group
          </span>
        </div>
        {/* child-1-2 */}
        <div className="d-flex justify-content-between my-4">
          {/* child-2-1 */}
          <div className="col-5">
            <h5 className="fw-700 mb-4">Prepared By</h5>
            <div
              style={{ fontStyle: "italic", margin: "0px 0px 0px 30px" }}
              className=" fw-500  d-flex align-items-start"
            >
              Signature :{" "}
              <span
                style={{ height: "30px" }}
                className="border-bottom border-black d-inline-block ms-2 col-7"
              >
                <span className="d-none">name</span>
              </span>
            </div>
          </div>
          {/* child-2-2 */}
          <div className="col-5">
            <h5 className="fw-700 mb-4">Approved By</h5>
            <div
              style={{ fontStyle: "italic", margin: "0px 0px 0px 35px" }}
              className="fw-500  d-flex align-items-start"
            >
              Signature :{" "}
              <span
                style={{ height: "30px" }}
                className="border-bottom border-black d-inline-block ms-2 col-7"
              >
                <span className="d-none">name</span>
              </span>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default PurchaseInvoiceFooter;
