import React from 'react';
// import ShadowDiv from './ShadowDiv';
import TableLoadingRow from './TableLoadingRow';

const TableLoading = ({ noRow, noTd }) => {
  return (
    <>
      {Array.from({ length: noRow }, (_, index) => (
        <TableLoadingRow
          key={index}
          noTd={noTd}
        />
      ))}
    </>
  );
};

export default TableLoading;