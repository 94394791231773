import React from "react";
import SalesListInformationSection from "./SalesListInformationSection";
import SalesListTableSection from "./SalesListTableSection";
import { useState } from "react";
import { useEffect } from "react";
import SortSection from "../../../components/DashboardComponents/SortSection";

const SalesListInformationTableSection = ({
  salesListData,
  salesDataRefetch,
  salesListIsLoading,
}) => {
  const salesTable = [
    {
      id: 10,
      name: "SL",
    },
    {
      id: 11,
      name: "Sales Date",
    },
    {
      id: 12,
      name: "Sales Code",
    },
    {
      id: 13,
      name: "Customer Name",
    },
    {
      id: 14,
      name: "Customer Id",
    },
    {
      id: 15,
      name: "Total",
    },
    {
      id: 16,
      name: "Paid Payment",
    },
    {
      id: 17,
      name: "Payment Status",
    },
    {
      id: 18,
      name: "Action",
    },
  ];

  const [data, setData] = useState([]);
  const [wareHouseDropdownValue, setWarehouseDropdownValue] =
    useState("All-Branch");
  const [wareHouseDropdownId, setWarehouseDropdownId] = useState("");
  const [customerDropdownValue, setCustomerDropdownValue] =
    useState("All-Customers");
  const [customerDropdownId, setCustomerDropdownId] = useState("");
  const [formDateValue, setFormDateValue] = useState("");
  const [toDateValue, setToDateValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const propsData = {
    setCustomerDropdownValue,
    setWarehouseDropdownValue,
    setWarehouseDropdownId,
    setFormDateValue,
    setToDateValue,
    setCustomerDropdownId,
  };

  // apply filters
  const applyFilters = () => {
    if (salesListData) {
      let filteredArray = [...salesListData];
      // Apply all branch filter
      if (wareHouseDropdownValue !== "All-Branch") {
        filteredArray = filteredArray.filter(
          (item) => item?.warehouse_id === wareHouseDropdownId
        );
      }

      // Apply customer name filter
      if (customerDropdownValue !== "All-Customers") {
        filteredArray = filteredArray.filter(
          (item) => item?.customer_id === customerDropdownId
        );
      }

      // Apply date range filter
      if (formDateValue || toDateValue) {
        filteredArray = filteredArray.filter((item) => {
          const itemDate = new Date(item.sales_date);
          return (
            itemDate >= new Date(formDateValue) ||
            itemDate <= new Date(toDateValue)
          );
        });
      }

      // Apply search filter
      if (searchValue.trim() !== "") {
        filteredArray = filteredArray.filter((item) =>
          item?.customer_name
            ?.toLowerCase()
            .includes(searchValue?.toLowerCase())
        );
      }
      setData(filteredArray);
    }
  };

  useEffect(() => {
    applyFilters();
  }, [
    wareHouseDropdownId,
    customerDropdownId,
    salesListData,
    searchValue,
    formDateValue,
    toDateValue,
  ]);
  const [selectedValue, setSelectedValue] = useState(10);
  return (
    <>
      <SalesListInformationSection
        propsData={propsData}
      ></SalesListInformationSection>
      <section className="my-4 px-4">
        <div className="row">
          <SortSection
            setSelectedValue={setSelectedValue}
            setSearchValue={setSearchValue}
            searchValue={searchValue}
          ></SortSection>
        </div>
      </section>
      {/* section-3 */}
      <section
        className="mx-4 p-2 border rounded-2 bg-body-secondary "
        style={{ height: "437px", overflowY: "scroll", overflowX: "hidden" }}
      >
        <SalesListTableSection
          showTable={"saleList"}
          tableHeader={salesTable}
          isLoading={salesListIsLoading}
          tableData={
            selectedValue !== "allData" ? data?.slice(0, selectedValue) : data
          }
        ></SalesListTableSection>
      </section>
    </>
  );
};

export default SalesListInformationTableSection;
