import React, { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';
import useGetData from '../../../hooks/useGetData';

const BarChart = () => {
    //const labels = ["January", "February", "March", "April", "May", "June", "July",];  // Simulating Utils.months({count: 7});

    const [salesMonthlyBarData, salesMonthlyBarDataRefetch] = useGetData('/dashboard/sales-monthly-data', 'salesMonthlyBarData');

    useEffect(() => {

    }, [salesMonthlyBarData])

    // const data = {
    //     labels: labels,
    //     datasets: [{
    //         label: 'My First Dataset',
    //         data: [65, 59, 80, 81, 56, 55, 40],
    //         backgroundColor: [
    //             'rgba(255, 99, 132, 0.2)',
    //             'rgba(255, 159, 64, 0.2)',
    //             'rgba(255, 205, 86, 0.2)',
    //             'rgba(75, 192, 192, 0.2)',
    //             'rgba(54, 162, 235, 0.2)',
    //             'rgba(153, 102, 255, 0.2)',
    //             'rgba(201, 203, 207, 0.2)'
    //         ],
    //         backgroundColor: [
    //             'rgb(255, 99, 132)',
    //             'rgb(255, 159, 64)',
    //             'rgb(255, 205, 86)',
    //             'rgb(75, 192, 192)',
    //             'rgb(54, 162, 235)',
    //             'rgb(153, 102, 255)',
    //             'rgb(201, 203, 207)'
    //         ],
    //         borderWidth: 1
    //     }]
    // };


    const data = {
        labels: salesMonthlyBarData?.map(item => item?.month_name || 'Unknown'),
        datasets: [
            {
                label: 'Total Sales',
                data: salesMonthlyBarData?.map(item => item?.total_grand_total),
                backgroundColor: [
                    'rgb(255, 159, 64)',
                    'rgb(255, 99, 132)',
                    'rgb(255, 205, 86)',
                    'rgb(75, 192, 192)',
                    'rgb(54, 162, 235)',
                    'rgb(153, 102, 255)',
                    'rgb(201, 203, 207)'
                ],
                borderColor: 'rgba(75, 192, 192, 0.2)',
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,  // add this line
        scales: {
            y: {
                beginAtZero: true
            }
        }
    };

    return (
        <Col xl="6 xl-100" md='12' sm='12'>
            <Card >
                <CardHeader>
                    <h5> Month Wise Sales </h5>
                </CardHeader>
                <CardBody >
                    <div className="market-chart" >
                        <Bar 
                            data={data} options={options}
                        />
                    </div>
                </CardBody>
            </Card>
        </Col>
    );
};

export default BarChart;