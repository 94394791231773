
import React from 'react';
import { Button, Col, Form, FormGroup, Label, Modal } from 'reactstrap';


const UnitModal = ({ handleUnitClick, unitModal, setUnitModal, handleUnitSubmit }) => {



    return (
        <div>

            <Modal isOpen={setUnitModal} fade={false} handleUnitClick={handleUnitClick}>
                <div className='text-center border p-4 fw-bold'>Unit Add </div>

                <div className="row p-2">
                    <div className="col-md-12">
                        <Form onSubmit={handleUnitSubmit}>

                            <FormGroup>
                                <Label for="exampleName">
                                    Unit Name
                                </Label>
                                <input required className="form-control" name='unit_name' />

                            </FormGroup>
                            
                            <FormGroup className='d-none'>
                                <Label for="exampleName">
                                    status
                                </Label>
                                <input required defaultValue={1} className="form-control" name='status' />

                            </FormGroup>

                            <FormGroup row>
                                <Label
                                    for="exampleText"
                                >
                                    Description
                                </Label>
                                <Col>

                                    <textarea
                                        name='description'
                                        type="textarea"
                                        className="form-control"
                                    />
                                </Col>

                            </FormGroup>

                            <Button className='w-100' color="primary" type='submit' >
                                Save
                            </Button>

                        </Form>
                    </div>
                </div>

                <div className=' p-2'>
                    <Button className='w-100' onClick={handleUnitClick}>
                        Close
                    </Button>
                </div>

            </Modal>
        </div>
    );
};

export default UnitModal;