import React from "react";
import useNumberFormatter from "../../../hooks/useNumberFormatter";

const ViewPurchasePaymentsDetails = ({ data }) => {
  const numericFormatter = useNumberFormatter();
  const {
    supplier_name,
    purchase_code,
    purchase_date,
    grand_total,
    paid_amount,
  } = data || {};
  const totalDue = (grand_total || 0) - (paid_amount || 0);
  const changeReturn = (paid_amount || 0) - (grand_total || 0);
  return (
    <section className="d-flex justify-content-between align-items-start mb-4">
      <div>
        <h6 className="mb-0 text-black fs-6 fw-800">Supplier Details</h6>
        <p className="m-0 text-dark fw-bold">{supplier_name || "-"}</p>
      </div>
      <div>
        <h6 className="mb-0 text-black fs-6 fw-800">Payment Details</h6>
        <p className="m-0 text-dark fw-bold">
          Grand total: {numericFormatter(grand_total) || "-"}
        </p>
        <p className="m-0 text-dark fw-bold">
          Paid Amount: {numericFormatter(paid_amount) || "-"}
        </p>
        {grand_total >= paid_amount && (
          <p className="m-0 text-dark fw-bold">
            Due: {numericFormatter(totalDue) || "-"}
          </p>
        )}
        {paid_amount > grand_total && (
          <p className="m-0 text-dark fw-bold">
            Change Return: {numericFormatter(changeReturn) || "-"}
          </p>
        )}
      </div>
      <div>
        <h6 className="mb-0 text-black fs-6 fw-800">Sales Details</h6>
        <p className="m-0 text-dark fw-bold">Invoice:#{purchase_code || "-"}</p>
        <p className="m-0 text-dark fw-bold">
          Date:{purchase_date?.slice(0, 10) || "-"}
        </p>
      </div>
    </section>
  );
};

export default ViewPurchasePaymentsDetails;
