import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Link, useNavigate } from 'react-router-dom';
import { Card } from 'reactstrap';
import CustomerInputField from './AddCustomer/CustomerInputField';
import { CustomToast } from '../../hooks/useCustomToast';
import axios from '../../util/axiosInstance';

const AddCustomers = () => {
    const navigate = useNavigate();

    const [customerName, setCustomerName] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');

    const [locationLink, setLocationLink] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [shippingAddress, setShippingAddress] = useState('');
    const [shippingLocation, setShippingLocation] = useState('');
   

    function generateRandomString(length = 6) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
      }
      
      const randomNumber = generateRandomString();
      
const token = localStorage.getItem('access-token')

    const handleWarehouseSubmit = (e) => {
        e.preventDefault();
        const form = e.target;

        const data = {
            "customer_name": customerName,
            // "mobile": mobile,
            "address": address,
            "phone": phone,
            "customer_code": randomNumber
            // "sales_due": 2000.00,
            // "sales_due_return": 100.00,
            // "status": "Active",
          
            // locationLink,
            // shippingAddress,
            // shippingLocation,
        }

        axios.post('/customer/addcustomer', data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            if (response.status === 201 || response.status === 200 || response.status === 202) {
                CustomToast('success', 'Customer Create Successfully')
                navigate('/customer-list');
            }
            form.reset();
        })
        .catch(error => {
            if (error) {
                CustomToast('error', 'Something Wrong')
            }
        });
    }
    return (
        <Fragment>
            <Breadcrumb title="Add Customers " parent="Contact Management" />
            <Card className='p-2'>
                <form onSubmit={(handleWarehouseSubmit)}>
                    <div className='d-flex justify-content-center align-items-center  px-3'>
                        <CustomerInputField labelText={'Customer Name'} setCustomerName={setCustomerName} type={'text'} fieldId={'customerName'} requiredField={true}></CustomerInputField>

                        <CustomerInputField labelText={'phone'} setPhone={setPhone} type={'number'} customPlaceholder={'+8801******'} fieldId={'phone'} requiredField={true}></CustomerInputField>
                    </div>

                   
                        <CustomerInputField  labelText={'Address'} setAddress={setAddress} type={'text'} fieldId={'address'} requiredField={true}></CustomerInputField>

                       

                    <div className="row">
                        <div className=' d-flex align-items-center justify-content-center mt-5 gap-5'>
                            <input type="submit" style={{ backgroundColor: '#00A65A', border: 'none', color: 'white', padding: '6px 28px', borderRadius: '5px' }} />
                            <Link to={'/dashboard'} style={{ backgroundColor: '#F39C12', border: 'none', color: 'white', padding: '6px 28px', borderRadius: '5px', textDecoration: 'none' }}>Close</Link>
                        </div>
                    </div>
                </form>
            </Card>
        </Fragment>
    );
};

export default AddCustomers;