import React, { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { AiFillDelete, AiFillEye } from "react-icons/ai";
import { BsCash } from "react-icons/bs";
import { MdPayment, MdOutlineAssignmentReturn } from "react-icons/md";
import { FaFileAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import useNumberFormatter from "../../../hooks/useNumberFormatter";
import ViewPaymentsModal from "./modal/ViewPaymentsModal";
import { useState } from "react";
import ReceivePaymentsModal from "./modal/ReceivePaymentsModal";
import useGetData from "../../../hooks/useGetData";
import MakeDeliveryModal from "./modal/MakeDeliveryModal";
import Swal from "sweetalert2";
import { CustomToast } from "../../../hooks/useCustomToast";
import axiosInstance from "../../../util/axiosInstance";
import PosMultiplePaymentSlip from "../Pos/PosMultiplePaymentSlip";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";

const SaleListTable = ({ product, sl }) => {
  const refetchInterval = 10000;
  const {
    id,
    paid_amount,
    grand_total,
    sales_code,
    sales_date,
    customer_name,
    payment_status,
    emp_id,
  } = product || {};
  const numericFormatter = useNumberFormatter();
  const [viewPaymentsModalOpen, setViewPaymentsModalOpen] = useState(false);
  const [receivePaymentsModalOpen, setReceivePaymentsModalOpen] =
    useState(false);
  const [makeDeliveryModalOpen, setMakeDeliveryModalOpen] = useState(false);
  // modal functionality
  const viewPaymentsToggleModal = () => {
    setViewPaymentsModalOpen(!viewPaymentsModalOpen);
  };
  const receivePaymentsToggleModal = () => {
    setReceivePaymentsModalOpen(!receivePaymentsModalOpen);
  };
  const makeDeliveryToggleModal = () => {
    setMakeDeliveryModalOpen(!makeDeliveryModalOpen);
  };
  // modal data get from backend
  const [salesAllData, salesAllDataRefetch] = useGetData(
    `/sales/view-sales`,
    "salesAllData"
  );
  useEffect(() => {
    // Function to refetch the data
    const refetchData = () => {
      salesAllDataRefetch();
    };
    const intervalId = setInterval(refetchData, refetchInterval);
    return () => {
      clearInterval(intervalId);
    };
  }, [salesAllDataRefetch]);

  const [viewPaymentsData, setViewPaymentsData] = useState({});

  // handle view payments by id
  const handleViewPayments = (_id) => {
    const viewPayments = salesAllData?.find((item) => item?.sales_id === _id);
    setViewPaymentsData(viewPayments);
  };
  // Handle Delete By Id
  const handleDeleteSalesItem = async (_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axiosInstance.delete(
            `/sales/delete-sales/${_id}`
          );

          if (response) {
            // const updatedItems = brandList.filter(item => item.id !== id);
            CustomToast("success", "Delete Successfully");
          } else {
            console.error("Failed to delete item:", response.data);
          }
        } catch (error) {
          console.error("Error deleting item:", error);
        }

        CustomToast("success", "Your file has been deleted.");
      }
    });
  };

  // pos invoice data
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <tr>
        <td>{sl + 1}</td>
        <td>{sales_date?.slice(0, 10) || "-"}</td>
        <td>{sales_code || "-"}</td>
        <td>{customer_name || "-"}</td>
        <td>{emp_id || "-"}</td>
        <td>{numericFormatter(grand_total) || 0}</td>
        <td>{numericFormatter(paid_amount) || 0}</td>
        <td>{payment_status || "-"}</td>
        <td
          className="position-relative "
          onClick={() => handleViewPayments(id)}
          style={{ width: "110px" }}
        >
          <Dropdown
            className="w-100 position-absolute start-0 "
            style={{ top: "5px" }}
          >
            <Dropdown.Toggle
              variant=""
              name="paymentMethod"
              id="payment"
              className="border-0 py-1 px-2 text-start fw-normal text-white c-pos-button7 border"
              style={{ fontSize: "10px", backgroundColor: "#093d86" }}
            >
              Action
            </Dropdown.Toggle>
            <Dropdown.Menu
              style={{
                maxHeight: "230px",
                boxShadow: "0px 0px 3px 2px rgb(197, 194, 194)",
              }}
              className=" overflow-auto rounded-2 h-auto  d-flex   flex-column px-1 py-2 gap-1 "
            >
              {/* View Sales------------------------------------------ */}
              <Link
                to={`/sales-management/view-sales/${id}`}
                className=" w-100 text-start text-dark  rounded-1 bg-black-hover p-0 ps-2 d-flex  align-items-center gap-1 cs-text-decoration-none"
              >
                <AiFillEye></AiFillEye>
                <option
                  style={{ fontSize: "14px" }}
                  className="color-white-hover "
                >
                  View Sales
                </option>
              </Link>
              {/* View Payments------------------------------------ */}
              <Dropdown.Item
                className=" w-100 text-start text-dark  rounded-1 bg-black-hover p-0 ps-2 d-flex  align-items-center gap-1 "
                onClick={() => {
                  viewPaymentsToggleModal();
                }}
              >
                <BsCash></BsCash>
                <option
                  style={{ fontSize: "14px" }}
                  className="color-white-hover "
                >
                  View Payments
                </option>
              </Dropdown.Item>

              {/* Receive Payments ------------------------------------*/}

              <Dropdown.Item
                className=" w-100 text-start text-dark  rounded-1 bg-black-hover p-0 ps-2 d-flex  align-items-center gap-1 "
                onClick={receivePaymentsToggleModal}
              >
                <MdPayment></MdPayment>
                <option
                  style={{ fontSize: "14px" }}
                  className="color-white-hover"
                >
                  Receive Payments
                </option>
              </Dropdown.Item>
              {/* POS Invoice ------------------------------------*/}
              <Dropdown.Item
                className=" w-100 text-start text-dark  rounded-1 bg-black-hover p-0 ps-2 d-flex  align-items-center gap-1 "
                onClick={() => {
                  handlePrint();
                }}
              >
                <FaFileAlt></FaFileAlt>
                <option
                  style={{ fontSize: "14px" }}
                  className="color-white-hover"
                >
                  POS Invoice
                </option>
              </Dropdown.Item>
              {/* sales return ------------------------------------*/}
              <Link
                to={`/sales-management/sales-return-item/${id}`}
                className=" w-100 text-start text-dark cs-text-decoration-none  rounded-1 bg-black-hover p-0 ps-2 d-flex  align-items-center gap-1 "
              >
                <MdOutlineAssignmentReturn></MdOutlineAssignmentReturn>
                <option
                  style={{ fontSize: "14px" }}
                  className="color-white-hover  "
                >
                  Sales Return
                </option>
              </Link>
              {/* Make Delivery--------------------------------- */}
              <Dropdown.Item
                className=" w-100 text-start text-dark  rounded-1 bg-black-hover p-0 ps-2 d-flex  align-items-center gap-1 "
                onClick={makeDeliveryToggleModal}
              >
                <MdOutlineAssignmentReturn></MdOutlineAssignmentReturn>
                <option
                  style={{ fontSize: "14px" }}
                  className="color-white-hover  cs-text-decoration-none"
                >
                  Make Delivery
                </option>
              </Dropdown.Item>
              {/* Delete------------------------------------------ */}
              <Dropdown.Item
                onClick={() => handleDeleteSalesItem(id)}
                className=" w-100 text-start text-dark  rounded-1 bg-red-hover p-0 ps-2 d-flex  align-items-center gap-1 "
              >
                <AiFillDelete></AiFillDelete>
                <option
                  style={{ fontSize: "14px" }}
                  className="color-white-hover"
                >
                  Delete
                </option>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
      {/* modal */}
      {/* view payments modal */}
      <ViewPaymentsModal
        isOpen={viewPaymentsModalOpen}
        toggle={viewPaymentsToggleModal}
        data={viewPaymentsData}
      ></ViewPaymentsModal>
      {/* receive payments modal */}
      <ReceivePaymentsModal
        isOpen={receivePaymentsModalOpen}
        toggle={receivePaymentsToggleModal}
        data={viewPaymentsData}
      ></ReceivePaymentsModal>
      {/* make delivery modal */}
      <MakeDeliveryModal
        isOpen={makeDeliveryModalOpen}
        toggle={makeDeliveryToggleModal}
        data={viewPaymentsData}
      ></MakeDeliveryModal>
      {/* handlePrint */}
      <div className="d-none">
        <PosMultiplePaymentSlip
          ref={componentRef}
          salesSlipData={viewPaymentsData}
        />
      </div>
    </>
  );
};

export default SaleListTable;
