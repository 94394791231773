
import { Fragment,useState } from "react";
import { Card, CardBody, Container, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, } from "reactstrap";
import Breadcrumb from "../../components/common/breadcrumb";
import { Link, useNavigate } from "react-router-dom";
import { CustomToast } from "../../hooks/useCustomToast";
import DatePicker from "react-datepicker";
// import WarehouseDropdownSection from "../PurchaseManagement/WarehouseDropdownSection";

import useGetData from "../../hooks/useGetData";
import axios from "../../util/axiosInstance";

const ExpensesListAdd = () => {
  const navigate = useNavigate();

  const [reference, setReference] = useState('');
  const [expense, setExpense] = useState('');
  const [amount, setAmount] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState('Select Payment Type');
  const [accountMethodName, setAccountMethodName] = useState('Select Account');
  const [accountMethodId, setAccountMethodId] = useState('');
  const [paymentNote, setPaymentNote] = useState('')
  const [categoryMethodName, setCategoryMethodName] = useState('Select Category');
  const [categoryMethodId, setCategoryMethodId] = useState('');

  const [startDate, setStartDate] = useState(new Date());
  const date = startDate;
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0 indexed
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();
  const purchaseDate = `${year}-${month}-${day}`

  function generateRandomSPNumber() {
    const randomNumber = Math.floor(Math.random() * 10000); // Generate a random number between 0 and 9999
    const formattedNumber = `SP${randomNumber.toString().padStart(4, "0")}`; // Format with "SP" prefix and leading zeros if needed
    return formattedNumber;
  }
  const randomSPNumber = generateRandomSPNumber();
 
  const [paymentTypeData] = useGetData(
    '/paymenttype/viewpaymenttypes/',
    'paymenttype'
  );

  const [accountTypeData] = useGetData(
    '/accounts/viewaccounts/',
    'accountTypeData'
  );

  const [categoryTypeData] = useGetData(
    '/expensecategory/viewCategories',
    'categoryTypeData'
  );

  const expenseSubmit = (e) => {
    e.preventDefault();
    const data = {
      "expense_code": randomSPNumber,
      "category_id": categoryMethodId,
      "reference_no": reference,
      "expense_for": expense,
      "expense_amt": amount,
      "payment_type": paymentMethod,
      "account_id": accountMethodId,
      "note": paymentNote,
      "expense_date": purchaseDate,
      
    }

    axios.post("/expense/add-expense", data)
      .then((response) => {
        if (response) {
          navigate('/expenses-list')
          CustomToast('success', 'Expenses List Added')
        }
      }).catch(err => {
        CustomToast('error', 'Something Wrong')
      });
  
  }

  return (
    <Fragment>
      <Breadcrumb title="Expense Add " parent="Expense" />
      <Container fluid={true}>
        <Card>
          <CardBody>
            {/* <form> */}
            <div className="row">
              <div className="text-center fw-bold mb-3">Please Enter Valid Data</div>
              <div className="col-md-6 ">
                <div
                  style={{ width: "100%" }}
                  className=" d-flex align-items-center  rounded-1 "
                >
                  <span className="fw-bold px-2">
                    Expenses Date <span className="text-danger">*</span>
                  </span>
                  <DatePicker
                    showIcon
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className=" d-flex border rounded-2">
                  <span className="fw-bold col-2 d-flex justify-content-center align-items-center border-end">Reference No</span>
                  <input
                    onChange={(e) => setReference(e.target.value)}
                    name="reference"
                    type="text" className="form-control border-0" />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 mt-3">
                <div
                  style={{ width: "100%" }}
                  className=" d-flex justify-content-between align-items-center rounded-1 "
                  title="Warehouse"
                >
                  {/* <span className="fw-bold px-2">
                    {/* <FaBuilding className="text-danger"></FaBuilding> */}
                    {/* Category */}
                  {/* </span>  */}
                  {/* dropdown data */}


                  {/* {firstBranch && ( */}

                  {/* <WarehouseDropdownSection
                    setWarehouseDropdownId={setWarehouseDropdownId}
                    borderEnd={"warehouse"}
                    inputType={"search"}
                    // setInputValue={setWareHouseSearch}
                    InitialDropdownValue={'select warehouse'}
                    data={branchData}
                    titleData={"Warehouse"}
                    placeHolderData={"Enter Warehouse Name"}
                    dataField={"warehouse_name"}
                  ></WarehouseDropdownSection> */}
                  {/* )} */}
                </div>
              </div>

              <div className="col-md-6 mt-3">
                <div className=" d-flex border rounded-2">
                  <span className="fw-bold col-2 d-flex justify-content-center align-items-center border-end">Expense for</span>
                  <input
                    onChange={(e) => setExpense(e.target.value)}
                    name="reference"
                    type="text" className="form-control border-0" />
                </div>
              </div>
            </div>




            <div className="row">
              <div className="mt-3 form-group col-md-6">
                <label for="account" className="fw-bold"> Payment Type   </label>
                <UncontrolledDropdown className=" w-100">
                  <DropdownToggle
                    className="border w-100"
                    caret
                    color="white"
                    style={{ backgroundColor: '#FFFFFF' }}
                  >
                    {paymentMethod}
                  </DropdownToggle>
                  <DropdownMenu
                    className="text-center w-100">
                    {
                      paymentTypeData?.map(paymentData => (
                        <DropdownItem
                          key={paymentData.id}
                          onClick={(e) => setPaymentMethod(paymentData.payment_type)}
                        >
                          {paymentData?.payment_type}
                        </DropdownItem>
                      ))
                    }
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>

              <div className="col-md-6" style={{marginTop:'35px'}}>
                <div className=" d-flex border rounded-2">
                  <span className="fw-bold col-2 d-flex justify-content-center align-items-center border-end">Amount <span className="text-danger"> *</span></span>
                  <input
                    required
                    step={'any'}
                    onChange={(e) => setAmount(parseFloat(e.target.value))}
                    name="amount"
                    type="number" className="form-control border-0" />
                </div>



              </div>

            </div>

            <div className="row">
              <div className=" form-group col-md-6">
                <label for="account" className="fw-bold"> Account  </label>
                <UncontrolledDropdown className=" w-100">
                  <DropdownToggle
                    className="border w-100"
                    caret
                    color="white"
                    style={{ backgroundColor: '#FFFFFF' }}
                  >
                    {accountMethodName}
                  </DropdownToggle>
                  <DropdownMenu
                    className="text-center w-100">
                    {
                      accountTypeData?.map(accountType => (
                        <DropdownItem
                          key={accountType.id}
                          onClick={(e) => {
                            setAccountMethodName(accountType.account_name);
                            setAccountMethodId(accountType.id);
                          }}>
                          {accountType.account_name}
                        </DropdownItem>
                      ))
                    }
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>

              <div className=" form-group col-md-6">
                <label for="account" className="fw-bold"> Category  </label>
                <UncontrolledDropdown className=" w-100">
                  <DropdownToggle
                    className="border w-100"
                    caret
                    color="white"
                    style={{ backgroundColor: '#FFFFFF' }}
                  >
                    {categoryMethodName}
                  </DropdownToggle>
                  <DropdownMenu
                    className="text-center w-100">
                    {
                      categoryTypeData?.map(categoryType => (
                        <DropdownItem
                          key={categoryType?.id}
                          onClick={(e) => {
                            setCategoryMethodName(categoryType?.category_name);
                            setCategoryMethodId(categoryType?.id);
                          }}>
                          <span style={{textTransform:'capitalize'}}>{categoryType.category_name}</span>
                        </DropdownItem>
                      ))
                    }
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>

            <div class="form-group col-md-12">
              <label className="fw-bold" for="exampleFormControlTextarea1">Payment Note</label>
              <textarea class="form-control"
                onChange={(e) => setPaymentNote(e.target.value)}
                id="exampleFormControlTextarea1" rows="3"></textarea>
            </div>

            <div className="col-md-12 d-flex justify-content-center align-items-center gap-5 mt-3">
              <input onClick={expenseSubmit} type="Submit" style={{ backgroundColor: '#00A65A', border: 'none', padding: '4px 30px', borderRadius: '5px', color: 'white' }} />
              <Link to={'/dashboard'} style={{ backgroundColor: '#F39C12', textDecoration: 'none', padding: '4px 30px', borderRadius: '5px', color: 'white' }}>Close</Link>
            </div>


            {/* </form> */}
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
};

export default ExpensesListAdd;
