import React, { useRef, useState } from "react";
import useNumberFormatter from "../../../hooks/useNumberFormatter";
import { Dropdown } from "react-bootstrap";
import { AiFillDelete, AiFillEye } from "react-icons/ai";
import { MdPayment } from "react-icons/md";
import { BsCash } from "react-icons/bs";
import { Link } from "react-router-dom";
import { FaEdit, FaFileAlt } from "react-icons/fa";
import Swal from "sweetalert2";
import { CustomToast } from "../../../hooks/useCustomToast";
import axiosInstance from "../../../util/axiosInstance";
import PurchasePayNowModal from "../Modal/PurchasePayNowModal";
import PurchaseVewPaymentsModal from "../Modal/PurchaseVewPaymentsModal";
import { useReactToPrint } from "react-to-print";
import PurchaseInvoice from "../Invoice/PurchaseInvoice";
import useGetData from "../../../hooks/useGetData";
import { useSelector } from "react-redux";

const PurchaseListTable = ({ product, sl }) => {
  const [viewPaymentsModalOpen, setViewPaymentsModalOpen] = useState(false);

  const numericFormatter = useNumberFormatter();
  const [payNowModalOpen, setPayNowModalOpen] = useState(false);
  // modal functionality
  const viewPaymentsToggleModal = () => {
    setViewPaymentsModalOpen(!viewPaymentsModalOpen);
  };
  const payNowToggleModal = () => {
    setPayNowModalOpen(!payNowModalOpen);
  };
  // modal data get from backend
  const [purchaseAllData, purchaseAllDataRefetch] = useGetData(
    `/purchase/purchase-view-all`,
    "purchaseAllData"
  );

  const [viewPaymentsData, setViewPaymentsData] = useState({});

  // handle view payments by id
  const handleViewPayments = (_id) => {
    const viewPayments = purchaseAllData?.find(
      (item) => item?.purchase_id === _id
    );
    setViewPaymentsData(viewPayments);
  };

  const {
    id,
    purchase_date,
    purchase_code,
    purchase_status,
    grand_total,
    paid_amount,
    payment_status,
    supplier_name,
  } = product || {};
  // Handle Delete By Id
  const handleDeletePurchaseItem = async (_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axiosInstance.delete(
            `/purchase/delete-purchase/${_id}`
          );

          if (response) {
            // const updatedItems = brandList.filter(item => item.id !== id);
            CustomToast("success", "Delete Successfully");
          } else {
            console.error("Failed to delete item:", response.data);
          }
        } catch (error) {
          console.error("Error deleting item:", error);
        }

        CustomToast("success", "Your file has been deleted.");
      }
    });
  };

  // purchase invoice print functionality
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const userPermissions = useSelector(state => state.user.userAuth?.permissions )
  return (
    <>
      <tr>
        <td>{sl + 1}</td>
        <td>{purchase_date?.slice(0, 10) || "-"}</td>
        <td>{purchase_code || "-"}</td>
        <td>{purchase_status || "-"}</td>
        <td>{supplier_name || "-"} </td>
        <td>{numericFormatter(grand_total) || 0}</td>
        <td>{numericFormatter(paid_amount) || 0}</td>
        <td>{payment_status || "-"}</td>
        <td
          className="position-relative "
          onClick={() => handleViewPayments(id)}
          style={{ width: "110px" }}
        >
          <Dropdown
            className="w-100 position-absolute start-0 "
            style={{ top: "5px" }}
          >
            <Dropdown.Toggle
              variant=""
              name="paymentMethod"
              id="payment"
              className="border-0 py-1 px-2 text-start fw-normal text-white c-pos-button7 border"
              style={{ fontSize: "10px", backgroundColor: "#093d86" }}
            >
              Action
            </Dropdown.Toggle>
            <Dropdown.Menu
              style={{
                maxHeight: "230px",
                boxShadow: "0px 0px 3px 2px rgb(197, 194, 194)",
              }}
              className=" overflow-auto rounded-2 h-auto  d-flex   flex-column px-1 py-2 gap-1 "
            >
              {/* View purchase------------------------------------------ */}
              {
                
                userPermissions.some(permission => permission === "Purchase_Show all users Purchase Invoices") ?  <Link
                to={`/purchase-management/view-purchase/${id}`}
                className=" w-100 text-start text-dark  rounded-1 bg-black-hover p-0 ps-2 d-flex  align-items-center gap-1 cs-text-decoration-none"
              >
                <AiFillEye></AiFillEye>
                <option
                  style={{ fontSize: "14px" }}
                  className="color-white-hover "
                >
                  View Purchase
                </option>
              </Link> : null
              }
             
              {/* Edit--------------------------------- */}
              {/* <Link
                to={"/purchase-management/new-purchase"}
                className=" w-100 text-start text-dark  rounded-1 bg-black-hover p-0 ps-2 d-flex  align-items-center gap-1 cs-text-decoration-none"
                // onClick={makeDeliveryToggleModal}
              >
                <FaEdit></FaEdit>
                <option
                  style={{ fontSize: "14px" }}
                  className="color-white-hover  cs-text-decoration-none"
                >
                  Edit
                </option>
              </Link> */}

              {/* Print Invoice ------------------------------------*/}
              {/* <Dropdown.Item
                className=" w-100 text-start text-dark  rounded-1 bg-black-hover p-0 ps-2 d-flex  align-items-center gap-1 "
                onClick={() => {
                  handlePrint();
                }}
              >
                <FaFileAlt></FaFileAlt>
                <option
                  style={{ fontSize: "14px" }}
                  className="color-white-hover"
                >
                  Print Invoice
                </option>
              </Dropdown.Item> */}
              {/* Make Payments ------------------------------------*/}

              {
               
                
                userPermissions.some(permission =>  permission === "Purchase_Purchase_Payments_Add") ?  <Dropdown.Item
                className=" w-100 text-start text-dark  rounded-1 bg-black-hover p-0 ps-2 d-flex  align-items-center gap-1 "
                onClick={payNowToggleModal}
              >
                <MdPayment></MdPayment>
                <option
                  style={{ fontSize: "14px" }}
                  className="color-white-hover"
                >
                  Pay Now
                </option>
              </Dropdown.Item> : null
              
            }
              {/* View Payments------------------------------------ */}
              
              {
               
                
                userPermissions.some(permission =>  permission === "Purchase_Purchase_Payments_View") ?   <Dropdown.Item
                className=" w-100 text-start text-dark  rounded-1 bg-black-hover p-0 ps-2 d-flex  align-items-center gap-1 "
                onClick={() => {
                  viewPaymentsToggleModal();
                }}
              >
                <BsCash></BsCash>
                <option
                  style={{ fontSize: "14px" }}
                  className="color-white-hover "
                >
                  View Payments
                </option>
              </Dropdown.Item> : null
              
            }



             
              {/* Delete------------------------------------------ */}
              {
                
                  userPermissions.some(permission => permission === "Purchase_Delete") ? 
                  <Dropdown.Item
                  onClick={() => handleDeletePurchaseItem(id)}
                  className=" w-100 text-start text-dark  rounded-1 bg-red-hover p-0 ps-2 d-flex  align-items-center gap-1 "
                >
                  <AiFillDelete></AiFillDelete>
                  <option
                    style={{ fontSize: "14px" }}
                    className="color-white-hover"
                  >
                    Delete
                  </option>
                </Dropdown.Item>  : null
            
              }
             
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
      {/* modal functionality */}
      {/* view payments modal */}
      <PurchaseVewPaymentsModal
        isOpen={viewPaymentsModalOpen}
        toggle={viewPaymentsToggleModal}
        data={viewPaymentsData}
      ></PurchaseVewPaymentsModal>
      {/* pay now modal */}
      <PurchasePayNowModal
        isOpen={payNowModalOpen}
        toggle={payNowToggleModal}
        data={viewPaymentsData}
      ></PurchasePayNowModal>
      {/* handlePrint */}
      <div className="d-none">
        <PurchaseInvoice
          ref={componentRef}
          purchaseSlipData={viewPaymentsData}
        />
      </div>
    </>
  );
};

export default PurchaseListTable;
