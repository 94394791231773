import React from "react";
import PosMultipleCash from "../../SalesManagement/Pos/PosMultipleCash";

const PosMultipleModal = ({
  customerDueLimit,
  isOpen,
  toggle,
  totalItems,
  setCashDueBalanceData,
  total,
  discount,
  totalPayable,
  setMultiplePayingBalanceData,
  setMultipleAccountPaymentIdDropdown,
  setMultiplePaymentType,
  setPaymentDropdownValueTwo,
  setAccountDropdownValueTwo,
  setMultiplePayingBalanceDataTwo,
  multiplePaymentCalculationData,
  setCashModalOpen,
  setMultipleModalOpen,
  handleMultiplePaymentDataSubmit,
  handleMultiplePaymentDataSubmitSave,
  handleResetAllData,
  multipleData,
  cashData,
  setAccountPaymentIdDropdown
}) => {
  const multiplePayments = true,
    cashPayment = false;
  return (
    <>
      <PosMultipleCash
      customerDueLimit = {customerDueLimit}
      setAccountPaymentIdDropdown = {setAccountPaymentIdDropdown}
      setCashDueBalanceData={setCashDueBalanceData}
        isOpen={isOpen}
        toggle={toggle}
        setCashModalOpen={setCashModalOpen}
        setMultipleModalOpen={setMultipleModalOpen}
        multiplePayments={multiplePayments}
        cashPayment={cashPayment}
        totalItems={totalItems}
        total={total}
        discount={discount}
        totalPayable={totalPayable}
        setMultiplePayingBalanceData={setMultiplePayingBalanceData}
        setMultipleAccountPaymentIdDropdown={
          setMultipleAccountPaymentIdDropdown
        }
        setMultiplePaymentType={setMultiplePaymentType}
        setPaymentDropdownValueTwo={setPaymentDropdownValueTwo}
        setAccountDropdownValueTwo={setAccountDropdownValueTwo}
        setMultiplePayingBalanceDataTwo={setMultiplePayingBalanceDataTwo}
        multiplePaymentCalculationData={multiplePaymentCalculationData}
        handleMultiplePaymentDataSubmitSave={
          handleMultiplePaymentDataSubmitSave
        }
        handleMultiplePaymentDataSubmit={handleMultiplePaymentDataSubmit}
        handleResetAllData={handleResetAllData}
        multipleData={multipleData}
        cashData={cashData}
      ></PosMultipleCash>
    </>
  );
};

export default PosMultipleModal;
