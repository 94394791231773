import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Table } from 'reactstrap';

import { AiFillEdit, AiTwotoneDelete } from 'react-icons/ai';



import axios from '../../../../../util/axiosInstance';
import TableLoading from '../../../../../components/common/loading/TableLoading';


const StockListTable = ({ dataToRander, isLoading }) => {



  const navigate = useNavigate();
  const btnStyle = {
    height: '40px',
    width: '40px',
    borderRadius: '50%',
    padding: '8px',
    background: 'transparent',
  };

  /**
   *
   */





  return (
    <div>
      <Table
        bordered
        hover
        striped>
        <thead className='text-center'>
          <tr>
            <th>#</th>

            <th>Item Name</th>
            {/* <th>Brand</th> */}
            <th>Unit Price(৳ )</th>
            <th>Sales Price(৳ )</th>
            <th>Current Stock</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody className='text-center'>
          {dataToRander.length === 0 && isLoading ? (
            <TableLoading
              noRow={9}
              noTd={5}
            />
          ) : (
            dataToRander?.map((acc, i) => (
              <tr>
                <th scope='row'>{i + 1}</th>
                <td>{acc.item_name
                }</td>
                <td>{acc.purchase_price
                }</td>
                <td>{acc.sales_price
                }</td>
                <td>{acc.purchase_qty ? acc.purchase_qty : acc.stock
                }</td>
                <th>{acc.purchase_qty ? acc.purchase_price * acc.purchase_qty : acc.purchase_price * acc.stock}</th>


              </tr>
            ))
          )}
          {
            dataToRander.length > 0 && (
              <tr>
                <th scope='row' colSpan={5}>Total </th>
                <th>{dataToRander.reduce((x, y) => {
                  return y.purchase_qty ? x + y.purchase_price * (y.purchase_qty) : x + y.purchase_price * (y.stock)
                }, 0)} </th>
              </tr>

            )
          }

          {/* <TableLoading /> */}
        </tbody>
      </Table>



      <div className='Toastify' />
    </div>
  );
};

export default StockListTable;
