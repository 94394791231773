import React from "react";
import SaleListTable from "../../Screen/SalesManagement/SalesList/SaleListTable";
import ViewListTable from "../../Screen/SalesManagement/SalesList/ViewListTable";
import ViewSalesPaymentTable from "../../Screen/SalesManagement/SalesList/ViewSalesPaymentTable";
import SalesReturnListTable from "../../Screen/SalesManagement/SalesReturnList/SalesReturnListTable";
import ViewPaymentsTable from "../../Screen/SalesManagement/SalesList/modal/ViewPaymentsTable";
import PurchaseListTable from "../../Screen/PurchaseManagement/PurchaseList/PurchaseListTable";
import DeliveryListTable from "../../Screen/DeliveryManagement/Pathao/DeliveriesList/DeliveryListTable";
import SalesPaymentListTable from "../../Screen/SalesManagement/SalesPayment/SalesPaymentListTable";
import ViewSalesReturnTable from "../../Screen/SalesManagement/SalesReturnList/ViewSalesReturnTable";
import SalesReturnPaymentsTable from "../../Screen/SalesManagement/SalesReturnPayments/SalesReturnPaymentsTable";
import ViewPurchaseListTable from "../../Screen/PurchaseManagement/PurchaseList/ViewPurchaseListTable";

const TableComponents = ({ product, sl, showTable }) => {
  return (
    <>
      {showTable === "saleList" && (
        <SaleListTable product={product} sl={sl}></SaleListTable>
      )}
      {showTable === "salesReturnList" && (
        <SalesReturnListTable product={product} sl={sl}></SalesReturnListTable>
      )}
      {showTable === "salesPaymentList" && (
        <SalesPaymentListTable
          product={product}
          sl={sl}
        ></SalesPaymentListTable>
      )}
      {showTable === "salesReturnPaymentList" && (
        <SalesReturnPaymentsTable
          product={product}
          sl={sl}
        ></SalesReturnPaymentsTable>
      )}
      {showTable === "viewSales" && (
        <ViewListTable product={product} sl={sl}></ViewListTable>
      )}
      {showTable === "viewPayment" && (
        <ViewPaymentsTable product={product} sl={sl}></ViewPaymentsTable>
      )}
      {showTable === "viewSalesPayment" && (
        <ViewSalesPaymentTable
          product={product}
          sl={sl}
        ></ViewSalesPaymentTable>
      )}
      {showTable === "viewReturnSalesView" && (
        <ViewSalesReturnTable product={product} sl={sl}></ViewSalesReturnTable>
      )}
      {showTable === "newPurchaseList" && (
        <PurchaseListTable product={product} sl={sl}></PurchaseListTable>
      )}
      {showTable === "viewPurchase" && (
        <ViewPurchaseListTable
          product={product}
          sl={sl}
        ></ViewPurchaseListTable>
      )}
      {showTable === "deliveryList" && (
        <DeliveryListTable product={product} sl={sl}></DeliveryListTable>
      )}
    </>
  );
};

export default TableComponents;
