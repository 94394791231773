import React, { Fragment } from 'react'
import Breadcrumb from '../../../components/common/breadcrumb'
import DateRangePickerSingle from './DateRangePickerSingle'
import { Button, Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import AccountListTable from '../../../components/accounts/AccountListTable';
import TransferAccounts from './components/TransferAccounts';
import { useState } from 'react';


const MoneyTransfer = () => {
    const [isCalenderOpen, setCalenderOpen] = useState(false)
    const [queryData, setQueryData] = useState(null);
    const [date, setDate] = useState(null);

    const dateSelect = (item) => {
        setDate(item)
        setCalenderOpen(false)

    }



    return (
        <Fragment>
            <Breadcrumb
                title='Money Transfer List'
                parent='Account'
            />
            <Col sm='12'>
                <Card>
                    <CardHeader className='d-flex justify-content-between'>

                        <h5>All List Accounts</h5>
                        <Link to='/accounts/money_transfer/add' className='bg-info py-2 text-decoration-none me-4 px-4 rounded-1'>+ New money Transfer </Link>
                    </CardHeader>

                    <CardBody>
                        {/* <div className='d-flex col-11'>
                            <TransferAccounts setQueryData={setQueryData} />
                            <DateRangePickerSingle dateSelect={dateSelect} date={date} setCalenderOpen={setCalenderOpen} />
                        </div> */}

                        <div className='category-table order-table coupon-list-delete mt-4'>
                            <AccountListTable />


                        </div>
                    </CardBody>
                </Card>
            </Col>

        </Fragment>
    )
}

export default MoneyTransfer