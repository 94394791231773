
import { apiSlice } from "../api/apiSlice";
import { deleteCategory, getAllCategoryList } from "./categorySlice";

export const categoryListApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAllCategoryList: builder.query({
            query: () => ({
                url: `/category/viewcategories/`,
            }),
            async onQueryStarted(arg, { queryFulfilled, dispatch }) {
                try {
                  const result = await queryFulfilled;
                  // cookie.set('token', result.data.token, {
                  //     expires: 1, // 1 day
                  // });
        
        
                  dispatch(
                    getAllCategoryList(result?.data)
                   
                  );
                } catch (err) {
                  // do nothing
                }
        }
    }),
        addNewCategoryList: builder.query({
            query: ({newData}) => ({
                method:'POST',
                url: `/category/addcategory/`,
                body: newData
            }),
        }),
        getSingleCategory: builder.query({
            query: ({id }) => ({
                url: `/category/viewcategories/${id}`,
            }),
        }),
        updateACategoryById: builder.mutation({
            query: ({ id,  updatedData }) => ({
                method: "PUT",
                url: `/category/editcategory/${id}`,
                body: updatedData
            }),
        }),
        deleteACategoryById: builder.mutation({
            query: ( id ) => ({
                method: "DELETE",
                url: `/category/deletecategory/${id}`,
            }),

          

        }),
        statusACategoryById: builder.mutation({
            query: ({ id }) => ({
                method: "PUT",
                url: `category/togglestatus/${id}`,
            }),
        }),
        
        
    }),
});

export const {
    useGetAllCategoryListQuery,
    useAddNewCategoryListQuery,
    useGetSingleCategoryQuery,
    useUpdateACategoryByIdMutation,
    useDeleteACategoryByIdMutation,
    useStatusACategoryByIdMutation,
} = categoryListApi;