import React, { useEffect, useState } from "react";
import { FaXmark } from "react-icons/fa6";
import { FaSearch } from "react-icons/fa";

const UserPanel = ({handleInputChange,clearInput,searchValue }) => {

  return (
    <div className=" d-flex align-items-center  py-2 border rounded-3" style={{backgroundColor:'white'}}>
      <div className="d-flex align-items-center justify-content-between px-2 position-relative">
        <FaSearch className=" text-secondary"/>
        <input
        style={{border:'none !important'}}
          type="text"
          className="border-0 ms-1"
          placeholder="Search..."
          aria-label="Search..."
          value={searchValue}
          onChange={handleInputChange}
        />
        {searchValue && (
          <FaXmark
            className="position-absolute end-0 text-secondary"
            onClick={clearInput}
            style={{ cursor: "pointer" }}
          />
        )}
      </div>
    </div>
  );
};

export default UserPanel;
