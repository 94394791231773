import React, { Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

import {

  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import axios from '../../util/axiosInstance';

import Tabset from '../../components/coupons/tabset';
import Breadcrumb from '../../components/common/breadcrumb';

const AddAccounts = () => {
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const token = localStorage.getItem('access-token');
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    axios
      .post('/accounts/addaccount', data, { headers })
      .then((response) => {
        navigate("/accounts/list")

      })
      .catch((err) => {
      });
  };
  return (
    <Fragment>
      <Breadcrumb
        title='Create Account'
        parent='Account'
      />
      <Container fluid={true}>
        <Card>
          <CardHeader>
            <h5>Create Account</h5>
          </CardHeader>
          <CardBody>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <div className='col-6'>
                  <FormGroup>
                    <input
                      placeholder='account_namer'
                      type='text'
                      className='form-control'
                      {...register('account_name', { required: true })}
                    />
                    {/* errors will return when field validation fails  */}
                    {errors?.phone && (
                      <span className='pt-1 text-danger'>
                        This field is required
                      </span>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <input
                      placeholder='aaccount_code'
                      type='text'
                      className='form-control'
                      {...register('account_code', { required: true })}
                    />
                    {/* errors will return when field validation fails  */}
                    {errors?.phone && (
                      <span className='pt-1 text-danger'>
                        This field is required
                      </span>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <input
                      placeholder='balance'
                      type='text'
                      className='form-control'
                      {...register('balance', { required: true })}
                    />
                    {/* errors will return when field validation fails  */}
                    {errors?.phone && (
                      <span className='pt-1 text-danger'>
                        This field is required
                      </span>
                    )}
                  </FormGroup>
                </div>
                <div className='col-6'>
                  <FormGroup>
                    <textarea
                      placeholder='note'
                      type='text'
                      className='form-control'
                      {...register('note', { required: true })}
                    />
                    {/* errors will return when field validation fails  */}
                    {errors?.phone && (
                      <span className='pt-1 text-danger'>
                        This field is required
                      </span>
                    )}
                  </FormGroup>
                </div>
              </Row>
              <div className='form-button'>
                <Button
                  color='primary'
                  type='submit'>
                  create account
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
};

export default AddAccounts;
