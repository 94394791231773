import React from "react";
import PosDropdownSection from "../Pos/PosDropdownSection";
import useGetData from "../../../hooks/useGetData";
import { FaCalendarAlt } from "react-icons/fa";
import { useEffect } from "react";
import { useState } from "react";
import InputLoadingTwo from "../../../components/Loading/InputLoadingTwo";

const SalesListInformationSection = ({ propsData }) => {
  // get data from backend
  const [branchData, refetch, isLoading] = useGetData(
    "/branch/viewbranch",
    "branch"
  );
  const [customersData, refetchCustomers, isLoadingCustomers] = useGetData(
    "/customer/viewcustomers",
    "customer"
  );
  const [updatedCustomersData, setUpdatedCustomersData] = useState([]);
  const [updatedBranchData, setUpdatedBranchData] = useState([]);

  // Define the custom customer data
  const customCustomerData = {
    id: null,
    customer_code: null,
    customer_name: "All-Customers",
    mobile: null,
    phone: null,
    sales_due: null,
    sales_return_due: null,
    address: null,
  };
  const customBranchData = {
    id: null,
    warehouse_type: null,
    warehouse_name: "All-Branch",
    mobile: null,
    email: null,
    status: null,
    created_date: null,
  };

  useEffect(() => {
    if (customersData) {
      setUpdatedCustomersData([customCustomerData, ...customersData]);
    }
    if (branchData) {
      setUpdatedBranchData([customBranchData, ...branchData]);
    }
  }, [customersData, branchData]);

  const {
    setCustomerDropdownValue,
    setWarehouseDropdownValue,
    setWarehouseDropdownId,
    setFormDateValue,
    setToDateValue,
    setCustomerDropdownId,
  } = propsData || {};

  return (
    <>
      {!branchData && isLoading ? (
        <>
          <InputLoadingTwo numSections={2} />
        </>
      ) : (
        <form className="px-4  pt-3 pb-2  ">
          {/* section-1 */}
          <section className="d-flex justify-content-between align-items-center">
            <div style={{ width: "48%" }}>
              <h5 className="text-start ">Branch</h5>
              <div
                style={{ width: "100%" }}
                className=" d-flex justify-content-between align-items-center border border rounded-1 "
                title="Warehouse"
              >
                {/* dropdown data */}
                {branchData && (
                  <PosDropdownSection
                    inputType={"search"}
                    labelText={"warehouse"}
                    InitialDropdownValue={"All-Branches"}
                    setWarehouseDropdownValue={setWarehouseDropdownValue}
                    setWarehouseDropdownId={setWarehouseDropdownId}
                    data={updatedBranchData}
                    titleData={"Warehouse"}
                    placeHolderData={"Enter Warehouse Name"}
                  ></PosDropdownSection>
                )}
              </div>
            </div>

            <div style={{ width: "48%" }}>
              <h5 className="text-start ">Customers</h5>
              <div
                style={{ width: "100%" }}
                className="  d-flex justify-content-between align-items-center border rounded-1"
                title="Customer"
              >
                {customersData && (
                  <PosDropdownSection
                    labelText={"customer"}
                    inputType={"search"}
                    InitialDropdownValue={"All-Customers"}
                    data={updatedCustomersData}
                    titleData={"Customer Name(Customer Id)"}
                    placeHolderData={"Search by Customer Name"}
                    setCustomerDropdownValue={setCustomerDropdownValue}
                    setFirstCustomerDropdownId={setCustomerDropdownId}
                  ></PosDropdownSection>
                )}
              </div>
            </div>
          </section>
          {/* section-2 */}

          <section className="d-flex justify-content-between align-items-center mt-3">
            <div style={{ width: "48%" }}>
              <h5 className="text-start ">From Date</h5>
              <div
                style={{ width: "100%" }}
                className="d-flex border justify-content-between rounded-1 align-items-center"
              >
                <span className="border-end  p-2">
                  <FaCalendarAlt />
                </span>
                <div className="w-100 px-1 position-relative">
                  <input
                    type="date"
                    className="w-100 border-0"
                    name="fromDateInput"
                    onChange={(e) => {
                      setFormDateValue(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div style={{ width: "48%" }}>
              <h5 className="text-start ">To Date</h5>
              <div
                style={{ width: "100%" }}
                className="d-flex border justify-content-between rounded-1 align-items-center"
              >
                <span className="border-end  p-2">
                  <FaCalendarAlt />
                </span>
                <div className="w-100 px-1 position-relative">
                  <input
                    type="date"
                    className="w-100 border-0"
                    name="toDateInput"
                    onChange={(e) => {
                      setToDateValue(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </section>
        </form>
      )}
    </>
  );
};

export default SalesListInformationSection;
