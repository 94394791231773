import React from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import SalesListTableSection from "../../SalesManagement/SalesList/SalesListTableSection";
import ViewPurchasePaymentsDetails from "./ViewPurchasePaymentsDetails";

const PurchaseVewPaymentsModal = ({ isOpen, toggle, data }) => {
  const viewPaymentTable = [
    {
      id: 1,
      name: "SL",
    },
    {
      id: 2,
      name: "Payment Date",
    },
    {
      id: 3,
      name: "Payment",
    },
    {
      id: 4,
      name: "Payment Type",
    },
    {
      id: 5,
      name: "Account",
    },
    {
      id: 6,
      name: "Payment Note",
    },
    {
      id: 7,
      name: "Action",
    },
  ];
  const { purchase_payments } = data || {};
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      style={{ maxWidth: "50%" }}
    >
      <ModalHeader
        className="d-flex justify-content-center  align-items-center"
        style={{
          backgroundImage: "linear-gradient(to right, #4b0ab3, #090080)", // Gradient background
        }}
      >
        <p className="text-white w-100 m-0 fw-bold fs-5">View Payments</p>
      </ModalHeader>
      <ModalBody>
        <ViewPurchasePaymentsDetails data={data}></ViewPurchasePaymentsDetails>
        <section
          className="bg-body-secondary p-2 rounded-2  "
          style={{ maxHeight: "225px", overflowY: "scroll" }}
        >
          <SalesListTableSection
            showTable={"viewPayment"}
            tableHeader={viewPaymentTable}
            tableData={purchase_payments}
          ></SalesListTableSection>
        </section>
      </ModalBody>
      <div className="modal-footer border-0">
        <Button color="secondary" onClick={toggle}>
          close
        </Button>
      </div>
    </Modal>
  );
};

export default PurchaseVewPaymentsModal;
