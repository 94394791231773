import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
} from "reactstrap";
import PosDropdownSection from "./PosDropdownSection";

const PosDiscountItems = ({
  discountIndividual,
  totalDiscount,
  isOpen,
  toggle,
  setDiscountInputValue,
  discountDropdownValue,
  setDiscountDropdownValue,
  handleSubmitDiscount,
  setIncreaseDiscount,
  setDiscountToAllType,
}) => {
  const [discountInput, setDiscountInput] = useState(0);

  const discountData = [
    {
      id: 1,
      name: "Percentage(%)",
      value: "Percentage(%)",
    },
    {
      id: 2,
      name: "Fixed(৳)",
      value: "Fixed(৳)",
    },
  ];

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        toggle();
        setIncreaseDiscount && setIncreaseDiscount(false);
      }}
      size="lg"
      style={{ maxWidth: "50%" }}
    >
      <form onSubmit={handleSubmitDiscount}>
        <ModalHeader
          className="d-flex justify-content-center  align-items-center"
          style={{
            backgroundImage: "linear-gradient(to right, #4b0ab3, #090080)", // Gradient background
          }}
        >
          {discountIndividual && (
            <h5 className="text-white w-100  fw-bold fs-5">
              Manage Sales Item
            </h5>
          )}
          {totalDiscount && (
            <h5 className="text-white w-100  fw-bold fs-5">Set Discount</h5>
          )}
        </ModalHeader>
        <ModalBody className="py-5 my-5">
          <div>
            <h5 className="fw-bold fs-5"> Item Name: {"name"}</h5>
            <div className=" d-flex justify-content-between  py-2 mt-2">
              {/* input-1 */}
              <div style={{ width: "48%" }}>
                <Label for="phoneNumber" className="mb-1 text-dark">
                  Discount Type
                </Label>
                <div
                  className=" d-flex justify-content-between align-items-center border rounded-1 "
                  title="Warehouse"
                >
                  <PosDropdownSection
                    inputType={"number"}
                    setValueDropdown={setDiscountDropdownValue}
                    setInputValue={setDiscountInputValue}
                    setInputValueTwo={setDiscountInput}
                    InitialDropdownValue={"Percentage(%)"}
                    data={discountData}
                    titleData={"Discount Type"}
                    placeHolderData={"Enter discount amount"}
                    setDiscountToAllType={setDiscountToAllType}
                  ></PosDropdownSection>
                </div>
              </div>
              {/* input-2 */}
              <FormGroup style={{ width: "48%" }}>
                <Label for="discount" className="mb-1 text-dark">
                  Discount
                </Label>
                <input
                  name="discount"
                  id="discount"
                  className="w-100 border py-2 text-dark  rounded-1  text-input py-1 px-2 bg-body-secondary text-input "
                  value={
                    discountInput
                      ? discountDropdownValue === "Percentage(%)"
                        ? `${discountInput} %`
                        : `${discountInput} ৳`
                      : "0.00"
                  }
                />
              </FormGroup>
            </div>
          </div>
        </ModalBody>
        <div className="modal-footer border-0">
          <Button style={{ backgroundColor: "green" }} color="primary">
            Save
          </Button>{" "}
          <Button
            color="secondary"
            type="reset"
            onClick={() => {
              setIncreaseDiscount && setIncreaseDiscount(false);
              setDiscountInput && setDiscountInput("0.00");
            }}
          >
            Reset
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default PosDiscountItems;
