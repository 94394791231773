import React, { useState } from "react";
import { BiEdit } from "react-icons/bi";
import { AiFillFileText } from "react-icons/ai";
import { MdOutlineAssignmentReturn } from "react-icons/md";
import { BiSolidFilePdf } from "react-icons/bi";
import useNumberFormatter from "../../../hooks/useNumberFormatter";
import SalesListTableSection from "../SalesList/SalesListTableSection";

const ViewSalesReturnInformationTableSection = ({ data }) => {
  const numericFormatter = useNumberFormatter();

  const salesTable = [
    {
      id: 10,
      name: "SL",
    },
    {
      id: 11,
      name: "Item Name",
    },
    {
      id: 12,
      name: "Return Status",
    },
    {
      id: 13,
      name: "Unit Price",
    },
    {
      id: 14,
      name: "Return Quantity",
    },
    {
      id: 15,
      name: "Total Amount",
    },
  ];

  const paymentTable = [
    {
      id: 1,
      name: "SL",
    },
    {
      id: 2,
      name: "Date",
    },
    {
      id: 3,
      name: "Payment Type",
    },
    {
      id: 4,
      name: "Account",
    },
    {
      id: 5,
      name: "Payment Note",
    },
    {
      id: 6,
      name: "Payment",
    },
  ];
  const { itemsReturn, paymentsReturn } = data || {};
  const grandTotal = itemsReturn?.reduce(
    (total, product) => total + product?.unit_total_cost,
    0
  );
  return (
    <>
      <section className="bg-body-secondary rounded-2 mt-4 p-2 ">
        <SalesListTableSection
          tableHeader={salesTable}
          tableData={itemsReturn}
          showTable={"viewReturnSalesView"}
        ></SalesListTableSection>
      </section>
      <section className="mt-4 ">
        <h4 style={{ color: "rgb(9, 61, 134)" }} className="fw-700 ">
          Payments Information:
        </h4>
        <div className="d-flex mt-1  justify-content-between align-items-center ">
          {/* main section-1 */}
          <div className="col-7">
            <section className="bg-body-secondary rounded-2 p-2">
              <SalesListTableSection
                tableHeader={paymentTable}
                tableData={paymentsReturn}
                showTable={"viewSalesPayment"}
              ></SalesListTableSection>
            </section>
          </div>
          {/* main section-2 */}
          <div className="col-3 d-flex align-items-start justify-content-between  px-3">
            <div className=" text-end  ">
              <p className="m-0 text-black fw-700 fs-7">Grand Total:</p>
            </div>
            <div className="text-end">
              <p className="m-0 text-black fw-700 fs-7">{grandTotal || 0}</p>
            </div>
          </div>
        </div>
      </section>
      {/* btn section */}
      {/* <section className="d-flex justify-content-between align-items-start mt-4">
        <div className="d-flex gap-2">
          <button className="btn  bg-green-hover gap-1 d-flex justify-content-center align-items-center">
            <BiEdit className="fs-5"></BiEdit> Edit
          </button>
          <button className="btn  bg-blue-hover gap-1 d-flex justify-content-center align-items-center">
            <AiFillFileText className="fs-5"></AiFillFileText> POS Invoice
          </button>
          <button className="btn  bg-orange-hover gap-1 d-flex justify-content-center align-items-center">
            <MdOutlineAssignmentReturn className="fs-5"></MdOutlineAssignmentReturn>{" "}
            Sales Return
          </button>
        </div>
        <div>
          <button className="btn  bg-blue-hover gap-1 d-flex justify-content-center align-items-center">
            <BiSolidFilePdf className="fs-5"></BiSolidFilePdf> PDF
          </button>
        </div>
      </section> */}
    </>
  );
};

export default ViewSalesReturnInformationTableSection;
