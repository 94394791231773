import React, { useEffect, useState } from 'react'
import { Button, Table } from 'reactstrap';
import TableLoading from '../../../../components/common/loading/TableLoading';
import { useNavigate } from 'react-router-dom';
import axios from '../../../../util/axiosInstance';
import moment from 'moment';
import NoDataFoundTr from '../../../../components/common/NoDataFoundTr';

const token = localStorage.getItem('access-token');
const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${token}`,
};

const SalesPaymentTable = ({ queryLink }) => {
  const [isLoading, setLoading] = useState(false);
  const [dataToRander, setDataToRander] = useState([]);

  const navigate = useNavigate();
  const btnStyle = {
    height: '40px',
    width: '40px',
    borderRadius: '50%',
    padding: '8px',
    background: 'transparent',
  };

  // useEffect(() => {
  // axios.get('/report/sales-by-customer/79?startDate=2023-01-01&endDate=2023-12-31')
  // /report/sales-by-customer/75?startDate=2023-10-07&endDate=2023-10-07

  // }, [queryLink])

  const fatchQuery = () => {
    setLoading(true);
    axios
      .get(queryLink)
      .then((response) => {
        setDataToRander(response?.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <div>
      <div className='d-flex col-10 mx-auto justify-content-center my-3'>
        <Button
          className='btn-info mx-3 text-white'
          onClick={fatchQuery}>
          Show
        </Button>
        {/* <Button onClick={navigate('/')}  >cancel</Button> */}
      </div>
      <Table
        bordered
        hover
        striped>
        <thead className='text-center'>
          <tr>
            <th>#</th>

            <th>Customer Name</th>
            <th>Order Date</th>
            <th>Order ID</th>
            <th>Last Order(in Days)</th>

            {/* <th>Receive(৳ )</th> */}
          </tr>
        </thead>
        <tbody className='text-center'>
          {dataToRander?.length === 0 && isLoading ? (
            <TableLoading
              noRow={9}
              noTd={5}
            />
          ) : dataToRander?.length === 0 && !isLoading ? (
            <NoDataFoundTr colSpanNumber={5} />
          ) : (
            dataToRander?.map((item, i) => {
              return (
                <tr>
                  <th scope='row'>{i + 1}</th>
                  <th>{item.customer_name}e</th>

                  <td>{moment(item?.created_date).format('L')}</td>
                  {/* <td>{item.sales_code}</td> */}
                  <td>{item.sales_code || '-'}</td>
                  <td>{moment(item?.created_date).fromNow()}</td>
                </tr>
              );
            })
          )}
        </tbody>
      </Table>

      <div className='Toastify' />
    </div>
  );
};

export default SalesPaymentTable
