import React from "react";
import Breadcrumb from "../../../../components/common/breadcrumb";
import DeliveriesLIstTableSection from "./DeliveriesLIstTableSection";
import { useEffect } from 'react';
import axiosInstance from '../../../../util/axiosInstance';

const DeliveriesLIst = () => {
  useEffect(() => {
    axiosInstance
      .post('/pathao/getAccessToken')
      .then((response) => {
        const data = response.data;
        localStorage.setItem('pathaoAccess_Token', data.access_token);
        localStorage.setItem('pathaoRefresh_Token', data.refresh_token);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div>
      <Breadcrumb
        title='Delivery List'
        parent='Delivery Management'
      />
      <DeliveriesLIstTableSection></DeliveriesLIstTableSection>
    </div>
  );
};

export default DeliveriesLIst;
