




import { Fragment, useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Container, } from "reactstrap";
import { BiPlusMedical } from "react-icons/bi";
import Breadcrumb from "../../../components/common/breadcrumb";
import { AiFillEdit, AiTwotoneDelete } from 'react-icons/ai';
import { Table } from 'reactstrap';
import { Link, useNavigate } from "react-router-dom";
import React, { useRef } from 'react'
import LoadingBar from 'react-top-loading-bar'

import axios from "../../../util/axiosInstance";
import TableLoading from "../../../components/common/loading/TableLoading";
import { CustomToast } from "../../../hooks/useCustomToast";
import Swal from "sweetalert2";



const RolesList = () => {
    
    const [defaultStatus, setDefaultStatus] = useState()

    const navigate = useNavigate();

    const [dropdownValue, setDropdownValue] = useState(10);

    const [progress, setProgress] = useState(100)

    const [brandList, setBrandList] = useState([])//main data
    const showAllData = brandList.length;

    const [brandListSecondary, setBrandListSecodary] = useState([])//rendering data
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const [error, setError] = useState(null);


    useEffect(() => {
        setIsLoading(true);
        axios('/role/view-roles/')
            .then(data => {
                setBrandList(data.data);
                setIsLoading(false);  // Stop loading
            })
            .catch(error => {
                setError(error);
                setIsLoading(false);  // Stop loading
            });
    }, [])

    useEffect(() => {
        handleFilter();
    }, [searchTerm, brandList,])


    // handle  search filter function
    const handleFilter = () => {
        let filterData = [...brandList]
        if (searchTerm !== "") {
            filterData = brandList?.filter(item =>
                item?.role_name?.toLowerCase().includes(searchTerm.toLowerCase())
            )
        }
        setBrandListSecodary(filterData);
    }

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value)
    };

    const handleDelete = async (id) => {

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    // Assuming your API endpoint looks like /api/items/:id
                    const response = await axios.delete(`/brand/deletebrand/${id}`);
                    if (response) {
                        // Filter out the deleted item based on its ID
                        const updatedItems = brandList.filter(item => item.id !== id);
                        setBrandList(updatedItems);
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        )
                    } else {
                        console.error("Failed to delete item:", response.data);
                    }
                } catch (error) {
                    console.error("Error deleting item:", error);
                }
            }
        })
    }

    const handleChangeActiveStatus = async (id) => {
        // Find the item and its current status
        const item = brandList.find(d => d.id === id);
        if (!item) return;

        const newStatus = item.status === 1 ? 0 : 1;

        // Optimistically update state
        const updatedData = brandList.map(d =>
            d.id === id ? { ...d, status: newStatus } : d
        );
        setBrandList(updatedData);

        try {
            // Assuming your API endpoint looks like `/api/items/:id`
            const response = await axios.put(`/brand/togglestatus/${id}`, { status: newStatus });

            if (response) {
                CustomToast('success', 'Update Successfully')
            }
        } catch (error) {
            // console.error("Error updating status:", error);
            // Revert back to original data if error occurred
            const revertedData = brandList.map(d =>
                d.id === id ? { ...d, status: item.status } : d
            );
            setBrandList(revertedData);
            // alert('Failed to update the status. Please try again.');
        }
    };


    return (
        <Fragment>
            <Breadcrumb title="Roles List" parent="Roles Management" />
            <Container fluid={true}>
                <Card>
                    <CardHeader>
                        <div className="d-flex justify-content-between">
                            <h5 className="text-center"> User List </h5>
                            <Link className="d-flex align-items-center gap-2" style={{ backgroundColor: '#00C0EF', textDecoration: 'none', color: 'white', padding: '6px', borderRadius: '5px' }} to={'/roles-list-add'}> <BiPlusMedical />New Role</Link>
                        </div>
                    </CardHeader>
                    <CardBody>

                        <div className="row">
                            <div className="col-md-4 d-flex align-items-center ">
                                <span >Show</span>
                                <select className="mx-1" onClick={(e) => setDropdownValue(e.target.value)}>
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                    <option value={500}>500</option>
                                    <option value={showAllData}>Show All</option>

                                </select>
                                <span>entries</span>
                            </div>
                            <div className="col-md-4 d-flex align-items-center justify-content-end">
                                {/* <span style={{ backgroundColor: '#39CCCC', color: 'white', padding: '8px', borderRadius: '5px', borderRight: '1px solid white', cursor: 'pointer' }}>Copy</span> */}

                            </div>
                            <div className="col-md-4 d-flex align-items-center gap-2">
                                <span>Search </span>

                                <input type="text" placeholder="By Role Name"
                                    value={searchTerm}
                                    onChange={handleSearchChange} className="form-control" />

                            </div>
                        </div>

                        <div style={{ backgroundColor: '#F2F2F2', marginTop: "3px", borderRadius: '5px', padding: '10px' }}>
                            <Table bordered
                                hover
                                striped>
                                <thead className='text-center'>
                                    <tr>
                                        <th>ID </th>
                                        <th>Role Name</th>
                                        <th>Description </th>
                                        {/* <th>Status</th> */}
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody className='text-center' >

                                    {
                                        isLoading && (<>
                                            {/* <LoadingBar color="red" style={{ marginTop: '-15px' }}
                                                progress={progress}
                                                onLoaderFinished={() => setProgress(0)}
                                            /> */}

                                            <TableLoading noTd={4} noRow={10} />
                                        </>
                                        )
                                    }
                                    
                                    {

                                        !isLoading && (
                                            brandListSecondary?.slice().sort((a, b) => b.id - a.id).slice(0, dropdownValue).map((brand, index) => (
                                                <tr key={brand.id}>

                                                    <th scope="row">{index + 1}</th>
                                                    <td>{brand?.role_name}</td>
                                                    <td>{brand?.description}</td>
                                                    {/* <td>

                                                        {
                                                            brand?.status === 1 && (
                                                                <> <div className="form-check form-switch d-flex align-items-center justify-content-center" >
                                                                    <input className="form-check-input" style={{ cursor: 'pointer' }} type="checkbox" id="flexSwitchCheckChecked" onClick={() => handleChangeActiveStatus(brand.id)} checked={brand.status} />

                                                                </div>

                                                                </>)
                                                        }

                                                        {
                                                            brand?.status === 0 && (<>
                                                                <div className="form-check form-switch d-flex align-items-center justify-content-center" >
                                                                    <input style={{ cursor: 'pointer' }} className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" onClick={() => handleChangeActiveStatus(brand.id)} checked={brand.status} />
                                                                </div>
                                                            </>
                                                            )
                                                        }
                                                    </td> */}

                                                    <td>
                                                        <Link className='fs-4 text-decoration-none' to={`/role-list-edit/${brand.id}`} > <AiFillEdit /></Link>

                                                        <button className='border-0 bg-transparent fs-4' onClick={() => handleDelete(brand.id)} > <AiTwotoneDelete /></button>
                                                    </td>
                                                </tr>
                                            ))
                                        )
                                    }

                                </tbody>

                            </Table>
                        </div>

                        


                    </CardBody>
                </Card>
            </Container>
        </Fragment>
    );
};

export default RolesList;
