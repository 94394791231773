

import moment from "moment/moment";
import React from "react";
import { GiWorld } from "react-icons/gi";
const AdjustmentInvoice = ({ data }) => {
  const {
    warehouse_name,
    warehouse_address,
    warehouse_id,
    mobile,
    email,
    customer_name,
    phone,
    address,
    created_date,
    due_date,
    reference_no,
    init_code,
    sales_code,
  } = data || {};
  return (
    <>
      <section className="d-flex justify-content-between align-items-center border-bottom py-2">
        <div className="d-flex gap-1 align-items-center fs-4">
          <GiWorld></GiWorld>
          Stock Adjustment Details
        </div>
        <div>
          <span className="fw-700">Date:</span>{" "}
          {moment(created_date).format("YYYY-MM-DD hh:mm:ss A")}
        </div>
      </section>
      <section className="my-4">
        {/* branch information */}
       
        <div className="d-flex justify-content-between align-items-start">
          <div className="col-6">
            <p className="m-0 text-black fw-600 fs-6">Name: {warehouse_id || 'No Name'}</p>
            {/* <p className="m-0 text-black fw-500 fs-6"> Address:
              {warehouse_address || " No Address Available"}
            </p>
            <p className="m-0 text-black fw-500 fs-6">Phone: {mobile }</p>
            <p className="m-0 text-black fw-500 fs-6">Email: {email}</p> */}
          </div>
          <div className="col-6">
       
            <p className="m-0 text-black fw-500 fs-6">
              Reference No: {reference_no || ' No Reference Available'}
            </p>
            {/* <p className="m-0 text-black fw-500 fs-6">
              Warehouse id: {warehouse_id}
            </p> */}
          </div>
        </div>
      </section>
    
    </>
  );
};

export default AdjustmentInvoice;