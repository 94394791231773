import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import pathaoLogo from "../../../../assets/images/dashboard/pathao.png";
import redxLogo from "../../../../assets/images/dashboard/redx.png";
import PathaoDeliveryModal from "../MakeDelivery/PathaoDeliveryModal";
import { useEffect } from "react";
import axios from "axios";

const MakeDeliveryModal = ({ isOpen, toggle, data }) => {
  const [pathaoDeliveryModalOpen, setPathaowDeliveryModalOpen] =
    useState(false);
  // modal functionality
  const handlePathaoDeliveryToggleModal = () => {
    setPathaowDeliveryModalOpen(!pathaoDeliveryModalOpen);
  };

  const handlePathawoTokens = () => {
    axios
      .post('https://fancymalabis.apodigi.com/pathao/getAccessToken')
      .then((response) => {
        const data = response.data;
        localStorage.setItem('pathaoAccess_Token', data.access_token);
        localStorage.setItem('pathaoRefresh_Token', data.refresh_token);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        style={{ maxWidth: "50%" }}
      >
        <ModalHeader
          className="d-flex justify-content-center  align-items-center"
          style={{
            backgroundImage: "linear-gradient(to right, #4b0ab3, #090080)", // Gradient background
          }}
        >
          <p className="text-white w-100 m-0 fw-bold fs-5">Make Delivery</p>
        </ModalHeader>
        <ModalBody>
          <section>
            <h4 className="text-black fs-3 text-center fw-bold">
              Select Your Delivery Method
            </h4>
            <div className="d-flex justify-content-center gap-3 align-items-start mt-5">
              <div
                onClick={() => {
                  handlePathaoDeliveryToggleModal();
                  toggle();
                  handlePathawoTokens();
                }}
                className="border rounded-2 px-4 py-2 c-pos-button8"
              >
                <img
                  src={pathaoLogo}
                  style={{ width: "115px", height: "40px", objectFit: "cover" }}
                  alt=""
                />
              </div>
              <div className="border rounded-2 px-4 py-2">
                <img
                  src={redxLogo}
                  className="disabled"
                  style={{ width: "110px", height: "40px", objectFit: "cover" }}
                  alt=""
                />
              </div>
            </div>
          </section>
        </ModalBody>
        <div className="modal-footer border-0">
          <Button color="secondary" onClick={toggle}>
            close
          </Button>
        </div>
      </Modal>
      {/* modal functionality */}
      {/* pathao modal */}
      <PathaoDeliveryModal
        isOpen={pathaoDeliveryModalOpen}
        toggle={handlePathaoDeliveryToggleModal}
        data={data}
      ></PathaoDeliveryModal>
    </>
  );
};

export default MakeDeliveryModal;
