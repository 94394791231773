
import { Fragment, } from "react";
import { Card, CardBody, CardHeader, Col, Container, Form, FormFeedback, FormGroup, Input, Label } from "reactstrap";

import Breadcrumb from "../../../components/common/breadcrumb";


import { Link, useNavigate } from "react-router-dom";

import { useForm } from "react-hook-form"
import { useAddNewCategoryListQuery } from "../../../redux/features/category/categoryApi";

import axios from "../../../util/axiosInstance";

const CategoryAdd = () => {
    const navigate = useNavigate()


    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors },
    } = useForm()

    const onSubmit = (data) => {

        axios.post('/category/addcategory', data)
            .then(data => {
                reset();
                navigate('/product-management/categoryList');
            })
            .catch(error => {
            });
    }

    return (
        <Fragment>
            <Breadcrumb title="Category Add" parent="Product Management" />
            <Container fluid={true}>
                <Card>
                    <CardHeader>
                        <div>
                            <h5 className="text-center"> Please Enter Valid Data</h5>
                        </div>
                    </CardHeader>
                    <CardBody>

                        <Form onSubmit={handleSubmit(onSubmit)}>

                            <FormGroup>
                                <Label for="exampleName">
                                    Category Name
                                </Label>
                                <input className="form-control"  {...register("category_name", { required: true })} />
                                {errors.category_name && <span className="text-danger">This field is required</span>}
                            </FormGroup>
                            <FormGroup className="d-none">
                                <Label for="exampleName">
                                    Category status
                                </Label>
                                <input className="form-control" defaultValue={1} {...register("status", { required: true })} />
                                {errors.status && <span className="text-danger">This field is required</span>}
                            </FormGroup>
                            <FormGroup row>
                                <Label
                                    for="exampleText"
                                >
                                    Description
                                </Label>
                                <Col>

                                    <textarea
                                        {...register("description", { required: false })}
                                        type="textarea"
                                        className="form-control"
                                    />
                                </Col>
                                {errors.description && <span className="text-danger">This field is required</span>}
                            </FormGroup>
                            <div className="d-flex gap-5">
                                <input type="submit" style={{ backgroundColor: 'green', border: 'none', padding: '12px 20px', color: 'white', borderRadius: '5px' }} />
                                <Link to={'/dashboard'} style={{ backgroundColor: 'red', textDecoration: 'none', border: 'none', padding: '12px 20px', color: 'white', borderRadius: '5px' }}> Close </Link>
                            </div>
                        </Form>

                    </CardBody>
                </Card>
            </Container>
        </Fragment>
    );
};

export default CategoryAdd;
