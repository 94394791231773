


import React from "react";
import { Table } from "reactstrap";
import AdjustmentTableComponents from "./AdjustmentTableComponents";
import useNumberFormatter from "../../../hooks/useNumberFormatter";

const AdjustmentListTableSection = ({
  tableHeader,
  tableData,
}) => {
  const numericFormatter = useNumberFormatter();

  // for sales table
  
  const totalQuantity = tableData?.reduce(
    (total, product) => total + product.adjustment_qty,
    0
  );
 
  
 

  
  
  return (
    <>
      <Table striped bordered className=" rounded-2 m-0">
        <thead>
          <tr>
            {tableHeader?.map((item, index) => (
              <th key={index} className="text-center">
                {item.name}
              </th>
            ))}
          </tr>
        </thead>
        {tableData && (
          <tbody className="text-center">
            {tableData?.map((product, index) => (
              <AdjustmentTableComponents
                key={index}
                product={product}
                sl={index}
              ></AdjustmentTableComponents>
            ))}
          </tbody>
        )}
        {tableData  && (
          <tfoot className="fw-700 text-center">
            <tr>
              <td colSpan={2}>Total</td>
              <td>{numericFormatter(totalQuantity) || 0}</td>
            
            </tr>
          </tfoot>
        )}
       
      </Table>
    </>
  );
};

export default AdjustmentListTableSection;