import React from "react";

const TotalCalculation = ({
  icons,
  iconBackground,
  bgColor,
  amount,
  title,
}) => {
  return (
    <div className='col-12 col-sm-6 col-md-3 p-3'>
      <div
        className=' px-4 d-flex justify-content-between align-items-center'
        style={{
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
          backgroundColor: bgColor,
          borderRadius: '14px',
          height: '120px',
        }}>
        <div
          className='fs-3 text-center rounded-circle p-2'
          style={{
            backgroundColor: iconBackground,
            // width: "50px",
            // height: "50px",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          {icons}
        </div>
        <div className='col-10 text-end'>
          <div className='text-white fs-3 d-flex justify-content-end align-items-center'>
            <span className='fw-800 text-white me-1 fs-5'> </span>
            <span className='fs-4 fw-bold '>{amount}</span>
          </div>
          <h5 className='text-black fs-  m-0 text-white fw-bold'>{title}</h5>
        </div>
      </div>
    </div>
  );
};

export default TotalCalculation;
