import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

const PosTermsModal = ({ isOpen, toggle }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const onSubmit = (data) => {
    reset();
    toggle();
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      style={{ maxWidth: "50%" }}
    >
      <ModalHeader
        className="d-flex justify-content-center  align-items-center"
        style={{
          backgroundImage: "linear-gradient(to right, #4b0ab3, #090080)", // Gradient background
        }}
      >
        <p className="text-white w-100 m-0 fw-bold fs-5">
          Enter Terms & Condition
        </p>
      </ModalHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <FormGroup>
            <textarea
              rows={3}
              placeholder="Enter Invoice Terms & Condition"
              className="cs-text-input  text-input"
              {...register("termsAndCondition", { required: true })}
            ></textarea>
          </FormGroup>
        </ModalBody>
        <div className="modal-footer border-0">
          <Button
            className="text-white"
            style={{ backgroundColor: "red" }}
            color="red"
            type="reset"
            onClick={toggle}
          >
            Cancel
          </Button>
          <Button
            style={{ backgroundColor: "green" }}
            color="green"
            type="submit"
            className="text-white"
          >
            Save
          </Button>{" "}
        </div>
      </form>
    </Modal>
  );
};

export default PosTermsModal;
