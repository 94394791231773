import React from 'react';
import { FormGroup } from 'reactstrap';

const CustomerInputField = ({
  fieldId,
  labelText,
  type,
  requiredField,
  customPlaceholder,
  setCustomerName,
  setMobile,
  setEmail,
  setPhone,
  setAddress,
  setLocationLink,
  setShippingAddress,
  setShippingLocation,
  originValue,
}) => {
 return (
  <div className="col-md-6 col-sm-12 ">
   <FormGroup className='d-flex justify-content-center align-items-center w-100 '>
    <label htmlFor={fieldId} className='fw-bold  text-center' style={{ width: '200px' }}>
     {labelText}<span className="text-danger">*</span>
    </label>
    <input
    
    placeholder={
      ((fieldId === 'mobile') && customPlaceholder)
    }
     className='form-control'
     onChange={(e) => {
       ((fieldId === 'customerName') && setCustomerName(e.target.value )) ||
       ((fieldId === 'mobile') && setMobile(e.target.value)) ||
       ((fieldId === 'email') && setEmail(e.target.value)) ||
       ((fieldId === 'phone') && setPhone(e.target.value)) ||
       ((fieldId === 'address') && setAddress(e.target.value)) ||
       ((fieldId === 'locationLink') && setLocationLink(e.target.value)) ||

       ((fieldId === 'shippingAddress') && setShippingAddress(e.target.value)) ||
       ((fieldId === 'shippingLocationLink') && setShippingLocation(e.target.value))

     }}
     type={type && type}
     required={requiredField && requiredField}
    />
   </FormGroup>
  </div>
 );
};

export default CustomerInputField;