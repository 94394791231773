import { useEffect } from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import useGetData from "../../../hooks/useGetData";
import SalesListAmountSection from "./SalesListAmountSection";
import SalesListInformationTableSection from "./SalesLIstInformationTableSection";

const SalesList = () => {
  const refetchInterval = 10000;
  const [salesListData, salesDataRefetch, salesListIsLoading] = useGetData(
    `/sales/sales_list`,
    "salesListData"
  );
  useEffect(() => {
    // Function to refetch the data
    const refetchData = () => {
      salesDataRefetch();
    };
    const intervalId = setInterval(refetchData, refetchInterval);
    return () => {
      clearInterval(intervalId);
    };
  }, [salesDataRefetch]);
  return (
    <>
      <Breadcrumb title="Sales List" parent="Sales Management" />
      <main className=" col-12 px-2">
        <SalesListAmountSection
          salesListData={salesListData}
          salesDataRefetch={salesDataRefetch}
          salesListIsLoading={salesListIsLoading}
        ></SalesListAmountSection>
        <section
          className="bg-white rounded-2 mt-5 pb-5"
          style={{ boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)" }}
        >
          <SalesListInformationTableSection
            salesListData={salesListData}
            salesDataRefetch={salesDataRefetch}
            salesListIsLoading={salesListIsLoading}
          ></SalesListInformationTableSection>
        </section>
      </main>
    </>
  );
};

export default SalesList;
