import React, { useEffect, useState } from "react";
import PublicHeaderCompont from "../../components/common/PublicHeaderComponts/PublicHeaderCompont";
import PublicBreadcrumb from "../../components/common/PublicBreadcrumb";
import { FaUserAlt } from "react-icons/fa";
import PosDropdownSection from "../SalesManagement/Pos/PosDropdownSection";
import { useDispatch, useSelector } from "react-redux";
import { fetchCustomerAction } from "../../redux/features/customer/customerSlice";

import CardList from "./CardListComponent";
import InputLoadingTwo from "../../components/Loading/InputLoadingTwo";

const OrderPlatform = () => {
  const dispatch = useDispatch();
  const customers = useSelector((state) => state.customers);

  const {
    loading,
    customersList: updatedCustomersData,
    appErr,
    serverErr,
  } = customers || {};

  const [customerDropdownValue, setCustomerDropdownValue] = useState("");
  const [customerDropdownId, setCustomerDropdownId] = useState("");
  const [myCart, setCartOpen] = useState(false);
  const [mySelectedCart, setMySelectedCart] = useState([]);

  useEffect(() => {
    dispatch(fetchCustomerAction());
  }, [dispatch]);

  return (
    <main>
      {" "}
      <div className="bg-white">
        <PublicHeaderCompont
          setCartOpen={setCartOpen}
          mySelectedCart={mySelectedCart}
        />
      </div>
      {/* section-------------- */}
      <section className="px-5 py-3 ">
        <PublicBreadcrumb
          title="Order Platform"
          parent="Zenith V2.0"
          shopName="Amar Shop by Sangu Group"
        />
        {loading ? (
          <InputLoadingTwo numSections={1}></InputLoadingTwo>
        ) : (
          <div className="border bg-body-secondary rounded-2 mt-2 py-3">
            <div className="col-5 mx-auto ">
              <h5 className="text-capitalize text-start">
                Please Select customer
              </h5>
              <div
                className=" col-12 bg-white  d-flex justify-content-between align-items-center border rounded-1"
                title="Customer"
              >
                <span className="px-2 ">
                  <FaUserAlt />
                </span>
                {/*  */}
                {appErr || serverErr ? (
                  <div className="text-red-400">
                    {serverErr} {appErr}{" "}
                  </div>
                ) : null}

                <PosDropdownSection
                  borderEnd={"warehouse"}
                  labelText={"customer"}
                  inputType={"search"}
                  titleData={"Walk-in-Customer(0178675333)"}
                  placeHolderData={"Walk-in-Customer(0178675333)"}
                  data={updatedCustomersData}
                  InitialDropdownValue={"Select Customer"}
                  setFirstCustomerDropdownId={setCustomerDropdownId}
                  setCustomerDropdownValue={setCustomerDropdownValue}
                ></PosDropdownSection>
              </div>
            </div>
            <div className="mt-3 rounded-2  px-2">
              {customerDropdownValue.length !== 0 ? (
                <CardList
                  myCart={myCart}
                  setMySelectedCart={setMySelectedCart}
                />
              ) : null}
              {/* <CardList /> */}
            </div>
          </div>
        )}
      </section>
    </main>
  );
};

export default OrderPlatform;
