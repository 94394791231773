import React, { Fragment, useEffect, useRef, useState } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Button, Card, CardBody, Container, Row, Table } from 'reactstrap';
import { FaBuilding } from "react-icons/fa";
import PosDropdownSection from '../../SalesManagement/Pos/PosDropdownSection';
import useGetData from '../../../hooks/useGetData';
import ReactDatePicker from "react-datepicker";
import TableLoading from '../../../components/common/loading/TableLoading';
import { useReactToPrint } from 'react-to-print';
import sanguImg from '../../../assets/images/dashboard/amr-shop.jpg'

const BalanceSheet = () => {

  const [branchData, branchRefetch,] = useGetData(
    "/branch/viewbranch",
    "branch"
  );

  const [balanceSheetData, balanceSheetRefetch, isLoading] = useGetData(
    "/accounts/all-data",
    "balanceSheetData"
  );



  const [wareHouseDropdownValue, setWarehouseDropdownValue] = useState("");
  const [selectedItemData, setSelectedItemData] = useState([]);
  const [wareHouseDropdownId, setWarehouseDropdownId] = useState("");

  const [warehouseAddress, setWarehouseAddress] = useState()

  useEffect(()=>{
    if(branchData){
      const selectAddress = branchData.find((item)=>(
        item?.id === wareHouseDropdownId
      ))
      setWarehouseAddress(selectAddress)
    }
  },[branchData, wareHouseDropdownId])
 

  const [startDate, setStartDate] = useState(new Date());
  const date = startDate;
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0 indexed
  const day = date.getDate().toString().padStart(2, "0");
  const year = date.getFullYear();
  const purchaseDate = `${month}/${day}/${year}`;


  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });


  return (
    <Fragment>
      <Breadcrumb title='Balance Sheet' parent='Accounts ' />
      <Container>
        <Card>
          <CardBody >
            <Row>
              <div
                className='col-md-6 d-flex justify-content-between align-items-center border border rounded-1 '
                title='Warehouse'>
                <span className=' px-2'>
                  <FaBuilding className='text-danger'></FaBuilding>
                </span>
                {/* dropdown data */}
                {branchData &&
                  <PosDropdownSection
                    borderEnd={'warehouse'}
                    inputType={'search'}
                    InitialDropdownValue={'All Branches'}
                    setWarehouseDropdownValue={setWarehouseDropdownValue}
                    data={branchData}
                    titleData={'Warehouse'}
                    placeHolderData={'Enter Branch Name'}
                    dataField={'warehouse_name'}
                    setSelectedItemData={setSelectedItemData}
                    setWarehouseDropdownId={
                      setWarehouseDropdownId
                    }></PosDropdownSection>
                }
              </div>

              <div className="col-md-6 gap-4">
                <div
                  style={{ width: "100%" }}
                  className=" d-flex align-items-center  rounded-1 "
                >
                  <span className="fw-bold px-2">
                    Filter by date:
                  </span>
                  <ReactDatePicker
                    showIcon
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                  />
                </div>
              </div>
            </Row>

            <div className='p-5' ref={componentRef}>

              <section
                className="d-flex justify-content-between border-bottom pb-3 border-black className
    "
              >
                {/* child-1 */}
                <div className="d-flex justify-content-between align-items-center col-6  gap-3">
                  <div className=" col-4">
                    <img src={sanguImg} alt="" className="img-fluid" />
                  </div>
                  <div className="col-8">
                    <h3 className="m-0 text-black fw-bold"> {wareHouseDropdownValue || "Please Select Your Branch"}  </h3>
                    <p
                      className="m-0 text-black fw-bold
          "
                    >
                      {warehouseAddress?.warehouse_address}
                    </p>
                    <p
                      className="m-0 text-black fw-bold
          "
                    >
                      {warehouseAddress?.mobile}
                    </p>
                  </div>
                </div>
                {/* child-2 */}
                <div className="col-6 d-flex justify-content-end align-items-start">
                  <button className=" px-3 py-1 border-0 fw-3 fw-bolder">
                    Balance Sheet
                  </button>
                </div>
              </section>

              <div className='my-2 text-center fw-bold fw-5'> Branch Name: {wareHouseDropdownValue}  Date:{purchaseDate}</div>
              <div className='my-3 d-flex  px-5'>
                <div className='w-50'>
                  <Table
                    bordered
                    hover
                    striped>
                    <thead className='text-center '>
                      <tr>
                        <th>Liability </th>
                        <th> Amount </th>

                      </tr>
                    </thead>

                    <tbody className='text-center'>
                      {isLoading && (
                        <TableLoading
                          noRow={10}
                          noTd={2}
                        />
                      )}

                      {!isLoading && (

                        <>
                          <tr >
                            <th scope='row'> Account Payable</th>
                            <td>{balanceSheetData?.totalPurchaseDue || 0}</td>
                          </tr>
                        </>
                      )}
                      <tr className={`${isLoading ? 'd-none': ' '}`}>
                        <td style={{padding:'18.5px 0px'}}> </td>
                        <td> </td>
                      </tr>
                      

                    </tbody>
                    <tfoot className='text-center'>
                      <tr>
                        <th>Total Liability</th>
                        <th>{balanceSheetData?.totalPurchaseDue || 0}</th>
                      </tr>
                    </tfoot>
                  </Table>
                </div>

                <div className='w-50'>
                  <Table
                    bordered
                    hover
                    striped>
                    <thead className='text-center '>
                      <tr>
                        <th>Assets </th>
                        <th> Amount </th>

                      </tr>
                    </thead>

                    <tbody className='text-center'>
                      {isLoading && (
                        <TableLoading
                          noRow={10}
                          noTd={2}
                        />
                      )}

                      {!isLoading && (
                        <>
                          <tr>
                            <th > Cash </th>
                            <td>{balanceSheetData?.total_value || 0}</td>
                          </tr>

                          <tr>
                            <th scope='row'> Account Receivable </th>
                            <td>{balanceSheetData?.totalSalesDue || 0}</td>
                          </tr>

                        </>
                      )}
                    </tbody>
                    <tfoot className='text-center'>
                      <tr>
                        <th>Total Assets:</th>
                        <th> {balanceSheetData?.total_value+balanceSheetData?.totalSalesDue || 0} </th>
                      </tr>
                    </tfoot>
                  </Table>

                  {/* <div className="container">
                    <table style={{ width: '100%', borderRadius: '5px' }}>
                      <thead style={{ backgroundColor: 'green', color: 'white' }}>
                        <tr >
                          <th>Assets</th>
                          <th>This Year</th>
                          <th>Last Year</th>
                        </tr>
                      </thead>
                      <tbody>

                        <tr>
                          <td>1. Current Assets</td>
                          <td>[This Year]</td>
                          <td>[Last Year]</td>
                        </tr>
                        <tr>
                          <td>Cash</td>
                          <td>[Amount]</td>
                          <td>[Amount]</td>
                        </tr>
                        <tr>
                          <td>Accounts Receivable</td>
                          <td>[Amount]</td>
                          <td>[Amount]</td>
                        </tr>
                        <tr>
                          <td>Inventory</td>
                          <td>[Amount]</td>
                          <td>[Amount]</td>
                        </tr>
                        <tr>
                          <td>Prepaid Expenses</td>
                          <td>[Amount]</td>
                          <td>[Amount]</td>
                        </tr>
                        <tr>
                          <td>Total Current Assets</td>
                          <td>[Total]</td>
                          <td>[Total]</td>
                        </tr>
                        <tr>
                          <td>2. Non-current Assets</td>
                          <td>[This Year]</td>
                          <td>[Last Year]</td>
                        </tr>
                        <tr>
                          <td>Property, Plant, and Equipment</td>
                          <td>[Amount]</td>
                          <td>[Amount]</td>
                        </tr>
                        <tr>
                          <td>Investments</td>
                          <td>[Amount]</td>
                          <td>[Amount]</td>
                        </tr>
                        <tr>
                          <td>Intangible Assets</td>
                          <td>[Amount]</td>
                          <td>[Amount]</td>
                        </tr>
                        <tr>
                          <td>Total Non-current Assets</td>
                          <td>[Total]</td>
                          <td>[Total]</td>
                        </tr>
                        <tr>
                          <td>3. Other Assets</td>
                          <td>[Amount]</td>
                          <td>[Amount]</td>
                        </tr>
                        <tr>
                          <td>Total Assets</td>
                          <td>[Total]</td>
                          <td>[Total]</td>
                        </tr>
                      </tbody>
                    </table>
                  </div> */}

                </div>
              </div>
            </div>

            <div className='d-flex flex-wrap align-items-center justify-content-center mt-1'>
              <Button onClick={handlePrint}>Print</Button>
            </div>

          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
};

export default BalanceSheet;