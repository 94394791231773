
import { Fragment, useEffect, useRef, useState } from "react";
import { Card, CardBody, CardHeader, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown, } from "reactstrap";
import Breadcrumb from "../../../components/common/breadcrumb";
import { Table } from 'reactstrap';
import axios from "../../../util/axiosInstance";
import useGetData from "../../../hooks/useGetData";
import TableLoading from "../../../components/common/loading/TableLoading";
import { addDays } from 'date-fns';
import { FaBuilding } from "react-icons/fa";
import PosDropdownSection from "../../SalesManagement/Pos/PosDropdownSection";
import DateRangePickerComponent from "../../SalesManagement/DateRangePicker";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import { usePDF } from "react-to-pdf";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import Papa from 'papaparse';

const CashFlow = () => {

  const [branchData, branchRefetch, isLoading] = useGetData(
    "/branch/viewbranch",
    "branch"
  );

  const [accountTypeData, accountRefetch] = useGetData(
    "/accounts/viewaccounts/",
    "accountTypeData"
  );

  const [paymentTypeData] = useGetData(
    "/paymenttype/viewpaymenttypes/",
    "paymenttype"
  );

  const [wareHouseDropdownValue, setWarehouseDropdownValue] = useState("");
  const [selectedItemData, setSelectedItemData] = useState([]);
  const [wareHouseDropdownId, setWarehouseDropdownId] = useState("");
  const [accountMethodName, setAccountMethodName] = useState("Select Account"); const [accountMethodId, setAccountMethodId] = useState("");

  const [transactionMethod, setTransactionMethod] = useState("Transaction Type");

  const btnStyle = {
    height: '40px',
    width: '40px',
    borderRadius: '50%',
    padding: '8px',
    background: 'transparent',
  };

  // const { data, isError, } = useGetAllCategoryListQuery();
  const [data, setData] = useState([]);
  // const [progress, setProgress] = useState(100)
  let allData = data?.length;

  const [searchValue, setSearchValue] = useState("");
  const [selectedValue, setSelectedValue] = useState(10);
  const [category, setCategory] = useState([]);



  useEffect(() => {

    axios('/category/viewcategories/')
      .then(data => {
        setData(data.data);
      })
      .catch(error => {
        // setError(error);
      });

  }, [])


  useEffect(() => {
    handleFilter()
  }, [searchValue, data,]);

  // search
  const handleFilter = () => {
    if (data) {
      let filterCategory = [...data]
      if (searchValue !== '') {
        filterCategory = data?.filter((category) =>
          category.category_name.toLowerCase().includes(searchValue.toLowerCase())
        );
      }
      setCategory(filterCategory);
    }

  }
  const handleInputChange = (e) => {
    setSearchValue(e.target.value);

  };

  const [stateM, setStateM] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: 'selection',
    },
  ]);
  const startDate = moment(stateM[0].startDate).format().substring(0, 10)
  const endDate = moment(stateM[0].endDate).format().substring(0, 10)

  // print
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,

  });

  // pdf download
  const { toPDF, targetRef } = usePDF({ filename: 'page.pdf' });

// excel download
  const _export = useRef(null);
  const exportExport = () => {
    if (_export.current !== null) {
      _export.current.save(category);
    }
  };


  // csv download 
  const handleDownload = () => {
    const csvString = Papa.unparse(category.sort((a, b) => b.id - a.id));
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'download.csv');
    link.click();

    URL.revokeObjectURL(url);
  };

  return (
    <Fragment>
      <Breadcrumb
        title='Cash Flow'
        parent='Accounts '
      />
      <Container fluid={true}>
        <Card>
          <CardHeader>

            <Row>

              <div
                className='col-md-4 d-flex justify-content-between align-items-center p-0 border rounded-1 '
                title='Warehouse'>
                <span className=' px-2'>
                  <FaBuilding className='text-danger'></FaBuilding>
                </span>
                {/* dropdown data */}
                {branchData &&
                  <PosDropdownSection
                    borderEnd={'warehouse'}
                    inputType={'search'}
                    InitialDropdownValue={'All Branches'}
                    setWarehouseDropdownValue={setWarehouseDropdownValue}
                    data={branchData}
                    titleData={'Warehouse'}
                    placeHolderData={'Enter Branch Name'}
                    dataField={'warehouse_name'}
                    setSelectedItemData={setSelectedItemData}
                    setWarehouseDropdownId={
                      setWarehouseDropdownId
                    }></PosDropdownSection>
                }
              </div>



              <div className="col-md-4">
                <UncontrolledDropdown className=" w-100">
                  <DropdownToggle
                    className="border w-100"
                    caret
                    color="white"
                    style={{ backgroundColor: "#FFFFFF" }}
                  >
                    {accountMethodName}
                  </DropdownToggle>
                  <DropdownMenu 
                   className="text-center w-100 overflow-auto h-auto p-2"
                   style={{
                     maxHeight: "150px",
                     boxShadow:
                       "0px 0px 30px 10px rgb(197, 194, 194)",
                   }}
                  >
                    {accountTypeData?.map((accountType) => (
                      <DropdownItem
                      className="w-100 text-center text-dark mt-1 border rounded-1 bg-black-hover p-0"
                        key={accountType.id}
                        onClick={(e) => {
                          setAccountMethodName(
                            accountType.account_name
                          );
                          setAccountMethodId(accountType.id);
                        }}
                      >
                        {accountType.account_name}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>


              <div className="  col-md-4">
                {/* <label for="transactionMethod" className="fw-bold">
         Transaction Type:
        </label> */}
                <UncontrolledDropdown className=" w-100">
                  <DropdownToggle
                    className="w-100 border"
                    style={{ backgroundColor: "#FFFFFF" }}
                    caret
                    color="white"
                  >
                    {transactionMethod}
                  </DropdownToggle>
                  <DropdownMenu 
                  className="text-center w-100 overflow-auto h-auto p-2"
                  style={{
                    maxHeight: "150px",
                    boxShadow:
                      "0px 0px 30px 10px rgb(197, 194, 194)",
                  }}
                  >
                    {paymentTypeData?.map((transactionData) => (
                      <DropdownItem
                      className="w-100 text-center text-dark mt-1 border rounded-1 bg-black-hover p-0"
                        key={transactionData.id}
                        onClick={(e) =>
                          setTransactionMethod(transactionData.payment_type)
                        }
                      >
                        {transactionData?.payment_type}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>

            </Row>

            {/* <Row className="d-flex align-items-center justify-content-center">
              
            </Row> */}

            <Row className="mt-2">

              <div className='col-md-6'>
                <DateRangePickerComponent
                  setStateM={setStateM}
                  stateM={stateM}
                // queryData={queryData}
                // setQueryData={setQueryData}
                />
              </div>

            </Row>
          </CardHeader>
          <CardBody>
            <div className='row'>
              <div className='col-md-4 d-flex align-items-center '>
                <span>Show</span>
                <select
                  onChange={(e) => setSelectedValue(e.target.value)}
                  className='mx-1'>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                  <option value={500}>500</option>
                  <option value={allData}>AllData</option>
                </select>
                <span>entries</span>
              </div>
              <div className='col-md-4 d-flex align-items-center justify-content-end'>

                <span style={{ backgroundColor: '#39CCCC', color: 'white', padding: '8px', borderRadius: '5px', borderRight: '1px solid white', cursor: 'pointer' }} onClick={handleDownload} >Csv </span>
                <span style={{ backgroundColor: '#39CCCC', color: 'white', padding: '8px', borderRadius: '5px', borderRight: '1px solid white', cursor: 'pointer' }} onClick={exportExport} >Excel </span>
                <span style={{ backgroundColor: '#39CCCC', color: 'white', padding: '8px', borderRadius: '5px', borderRight: '1px solid white', cursor: 'pointer' }} onClick={handlePrint}>Print </span>
                <span style={{ backgroundColor: '#39CCCC', color: 'white', padding: '8px', borderRadius: '5px', borderRight: '1px solid white', cursor: 'pointer' }} onClick={() => toPDF()}>PDF </span>
              </div>
              <div className='col-md-4 d-flex align-items-center gap-2'>
                <span>Search </span>{' '}
                <input
                  type='text'
                  placeholder='By '
                  className='form-control'
                  value={searchValue}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <ExcelExport ref={_export}>
              <div
                ref={componentRef}
              >
                <div

                  ref={targetRef}
                  style={{
                    backgroundColor: '#F2F2F2',
                    padding: '10px',
                    marginTop: '2px',
                  }}>
                  <Table

                    bordered
                    hover
                    striped>
                    <thead className='text-center'>
                      <tr>
                        <th>Date </th>
                        <th>Account</th>
                        <th>Description </th>
                        <th>Payment Method</th>
                        <th>Payment Details</th>
                        <th>Debit</th>
                        <th>Credit</th>
                        <th>Account Balance</th>
                        <th>Total Balance</th>
                      </tr>
                    </thead>

                    <tbody category={category} className='text-center'>
                      {isLoading && (
                        <TableLoading
                          noRow={10}
                          noTd={9}
                        />
                      )}

                      {!isLoading && (
                        <>
                          {category
                            ?.slice()
                            .sort((a, b) => b.id - a.id)
                            .slice(0, selectedValue)
                            .map((category, index) => (
                              <tr key={category.id}>
                                <th scope='row'>{index + 1}</th>
                                <td>{category.category_name}</td>
                                <td>{category.description}</td>

                              </tr>
                            ))}
                        </>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>

            </ExcelExport>

            {/* <div>
							<nav className=' d-flex justify-content-end align-items-center px-2 col-12 text-black-50 fs-6 bg-white border-top-1'>
								<span className=' mx-2 my-0 flex-shrink-1 user-select-none'>
									Rows per page:
								</span>
								<div className=' position-relative flex-shrink-0 mt-1 d-flex '>
									<select
										aria-label='Rows per page:'
										className='sc-bXCLTC eVrcSZ cs-cursor-pointer user-select-none ps-2 pe-4  '
										style={{
											height: '25px',
											maxWidth: '100%',
											color: '#000000',
											fontSize: 'inherit',
											border: 'none',
											background: 'transparent',
											appearance: 'none',
											direction: 'ltr',
										}}>
										<option
											value={10}
											selected>
											10
										</option>
										<option value={15}>15</option>
										<option value={20}>20</option>
										<option value={25}>25</option>
										<option value={30}>30</option>
									</select>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width={24}
										height={24}
										viewBox='0 0 24 24'
										className='position-absolute top-0'
										style={{ right: 0 }}>
										<path d='M7 10l5 5 5-5z' />
										<path
											d='M0 0h24v24H0z'
											fill='none'
										/>
									</svg>
								</div>
								<span className='mx-4 user-select-none flex-shrink-1'>1-2 of 2</span>
								<div className='sc-bmzYkS fimDOL d-flex align-items-center rounded-1 white-space-none'>
									<button
										id='pagination-first-page'
										type='button'
										aria-label='First Page'
										aria-disabled='true'
										disabled
										className='position-relative d-block user-select-none border-0 rounded-1 cs-cursor-pointer'
										style={btnStyle}>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width={24}
											height={24}
											viewBox='0 0 24 24'
											aria-hidden='true'
											role='presentation'>
											<path d='M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z' />
											<path
												fill='none'
												d='M24 24H0V0h24v24z'
											/>
										</svg>
									</button>
									<button
										id='pagination-previous-page'
										type='button'
										aria-label='Previous Page'
										aria-disabled='true'
										disabled
										className='position-relative d-block user-select-none border-0 rounded-1 cs-cursor-pointer'
										style={btnStyle}>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width={24}
											height={24}
											viewBox='0 0 24 24'
											aria-hidden='true'
											role='presentation'>
											<path d='M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z' />
											<path
												d='M0 0h24v24H0z'
												fill='none'
											/>
										</svg>
									</button>
									<button
										id='pagination-next-page'
										type='button'
										aria-label='Next Page'
										aria-disabled='true'
										disabled
										className='position-relative d-block user-select-none border-0 rounded-1 cs-cursor-pointer'
										style={btnStyle}>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width={24}
											height={24}
											viewBox='0 0 24 24'
											aria-hidden='true'
											role='presentation'>
											<path d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z' />
											<path
												d='M0 0h24v24H0z'
												fill='none'
											/>
										</svg>
									</button>
									<button
										id='pagination-last-page'
										type='button'
										aria-label='Last Page'
										aria-disabled='true'
										disabled
										className='position-relative d-block user-select-none border-0 rounded-1 cs-cursor-pointer'
										style={btnStyle}>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width={24}
											height={24}
											viewBox='0 0 24 24'
											aria-hidden='true'
											role='presentation'>
											<path d='M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z' />
											<path
												fill='none'
												d='M0 0h24v24H0V0z'
											/>
										</svg>
									</button>
								</div>
							</nav>
						</div> */}
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
};

export default CashFlow;
