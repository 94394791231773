
import React, { Fragment, } from 'react';
import { Card, Container, } from 'reactstrap';
import Breadcrumb from '../common/breadcrumb';
import sanguImg from '../../assets/images/dashboard/sangu.jpg'

const PaymentSlip = () => {

 return (
  <Fragment>
   <Breadcrumb
    title='Payment Slip'
    parent='Purchase Management'
   />
   <Container fluid={true}>
    <Card className='p-2'>
     <div>
      <div className="d-flex justify-content-between align-items-center col-6  gap-3">
       <div className=" col-4">
        <img src={sanguImg} alt="" className="img-fluid" />
       </div>
       <div className="col-8">
        <h3 className="m-0 text-black fw-bold">Shangu Group</h3>
        <p
         className="m-0 text-black fw-bold"
        >
         Mirpur 60 fit
        </p>
        <p
         className="m-0 text-black fw-bold
          "
        >
         Dhaka
        </p>
       </div>
      </div>

      <div className="container">
       <div className="row">
        <div className="col-12 col-sm-4" style={{ backgroundColor: '#0D0D44', height: '50px' }}>Left</div>
        <div className="col-12 col-sm-4 d-flex align-items-md-center justify-content-center fw-bold" style={{ fontSize: '30px' }}>Payment Receipt</div>
        <div className="col-12 col-sm-4" style={{ backgroundColor: '#0D0D44', height: '50px' }}>Right</div>
       </div>
      </div>


      {/* <!-- First Section (4 columns) --> */}
      <div className='container'>
       <div className="row p-3  px-sm-5">

        <table>

         <tbody>

          <tr>
           <td className='col-5'>Address 1:</td>
           <td className='col-6'>Dhaka, Bangladesh</td>
          </tr>
          <tr>
           <td className='col-5'>Address 2:</td>
           <td className='col-6'>Mirpur 10, Pirerbug 60 Feet Mirpur 10, Pirerbug 60 Feet Mirpur 10, Pirerbug 60 Feet</td>
          </tr>
         </tbody>
        </table>
        {/* <div className="col-6 p-3 col-md-6">

        <div className='border p-x5 d-flex align-items-center justify-content-between'>
         <p style={{ fontSize: '20px', fontWeight: '500' }}>Name:</p>
         <p style={{ fontSize: '15px', fontWeight: '400' }}>Lantine Moseley</p>
        </div>
        <div className='border d-flex align-items-center justify-content-between'>
        <p className="" style={{ fontSize: '20px', fontWeight: '500' }}>Address:</p>
        <p style={{ fontSize: '15px', fontWeight: '400' }}>Dhaka Mirpur dsfasfdasfda;lkjsdflkajsdlfkja;lsdjkfl;akjsd;lfkjas;ldjkf;laskjdf;laksjd </p>
        </div>

        <div className='border d-flex align-items-center justify-content-between'>
        <p className="" style={{ fontSize: '20px', fontWeight: '500' }}>Date:</p>
        <p style={{ fontSize: '15px', fontWeight: '400' }}>Thursday, March16, 1967 </p>

        </div>

        <div className='border d-flex align-items-center justify-content-between'>
        <p className="" style={{ fontSize: '20px', fontWeight: '500' }}>Payment Method:</p>

        <p style={{ fontSize: '15px', fontWeight: '400' }}>Option2 </p>
        </div>

       </div> */}

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
         <div className='my-3' style={{ border: '1px dotted', width: '80%' }}></div>
        </div>

       </div>

      </div>
      <div className="container">
       <div className='row'>

        <table className="table border">
         <thead>
          <tr>
           <th className="bg-primary text-white">First Name</th>
           <th className="bg-primary text-white">Quantity</th>
           <th className="bg-primary text-white">Unit Price</th>
           <th className="bg-primary text-white">Amount</th>
          </tr>
         </thead>
         <tbody>
          <tr>
           <td>Shampoo</td>
           <td>2</td>
           <td>12tk</td>
           <td>500</td>
          </tr>
         </tbody>
        </table>
       </div>
      </div>


      {/* <div className="container">
        <div className="col-md-4">s</div>
        <div className="col-md-4">s</div>
        <div className="col-md-4">
         <div style={{ backgroundColor: '#0D0D44', padding: '10px' }}>
          <span className="" style={{ fontSize: '30px', color: 'white' }}>Total </span>
          <span className="" style={{ fontSize: '30px', color: 'white' }}>123500000000000</span>
         </div>
        </div>
       </div> */}

      <div className="container">
       <div className="row">
        <div className="col-12 col-md-4"></div>
        <div className="col-12 col-md-4"></div>
        <div className="col-12 col-md-4">
         <div className="p-3">
          <div style={{ backgroundColor: '#0D0D44', padding: '10px', borderRadius:'5px' }}>
           <span className="" style={{ fontSize: '30px', color: 'white' }}>Total </span>
           <span className="" style={{ fontSize: '30px', color: 'white' }}>123500000000000</span>
          </div>
         </div>
        </div>
       </div>
      </div>

     </div>
    </Card>
   </Container>
  </Fragment>
 );
};

export default PaymentSlip;