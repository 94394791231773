import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { FormGroup, Label } from "reactstrap";
import { AiFillCloseCircle } from "react-icons/ai";
import useGetData from "../../../hooks/useGetData";
import useClickOutside from "../../../hooks/useClickOutside";

const PosMultipleAddPayment = ({
  multiplePayments,
  sectionsId,
  closeButton,
  setAnotherPaymentSections,
  anotherPaymentSections,
  register,
  setPaymentDropdownValueTwo,
  setAccountDropdownValueTwo,
  dueBalance,
  setShowButton,
  setMultiplePayingBalanceDataTwo,
}) => {
  // get data from backend
  const [paymentTypeData] = useGetData(
    "/paymenttype/viewpaymenttypes/",
    "paymenttype"
  );
  const [accountDropDownData] = useGetData(
    "/accounts/viewaccounts/",
    "accounts"
  );

  const [initialPaymentType, setInitialPaymentType] =
    useState("Payment Method");
  const [initialAccountType, setInitialAccountType] =
    useState("Account Method");

  const [isPaymentDropdownOpen, setIsPaymentDropdownOpen] = useState(false);

  const [isAccountDropdownOpen, setIsAccountDropdownOpen] = useState(false);
  const dropdownMenuOne = useRef(null);
  const dropdownMenuTwo = useRef(null);

  useClickOutside(dropdownMenuOne, () => setIsPaymentDropdownOpen(false));
  useClickOutside(dropdownMenuTwo, () => setIsAccountDropdownOpen(false));

  // Function to remove a payment section by index
  const removePaymentSection = (e) => {
    setShowButton(true);
    e.preventDefault();
    const newPaymentSections = [...anotherPaymentSections];
    newPaymentSections.splice(sectionsId - 101, 1);
    setAnotherPaymentSections(newPaymentSections);
  };

  const [payingAmount, setPayingAmount] = useState();
  useEffect(() => {
    setPayingAmount(dueBalance);
  }, [dueBalance]);

  return (
    <>
      <div
        style={{
          width: "100%",
          boxShadow: "0px 0px 30px 5px rgb(187, 186, 190)",
        }}
        className=" p-3 rounded-2 position-relative"
      >
        {closeButton && (
          <AiFillCloseCircle
            onClick={removePaymentSection}
            className="position-absolute cs-cursor-pointer top-0 mt-1 me-2 end-0 fs-4 text-danger"
          />
        )}
        {/* for multiple cash */}
        {multiplePayments && (
          <div className=" d-flex justify-content-between align-items-start ">
            <FormGroup className="" style={{ width: "48%" }}>
              <Label for="amount2" className="mb-1 ">
                Amount
              </Label> 
              <input
                type="number"
                step={"any"}
                name="amount2"
                placeholder={`Enter Remaining Amount ${payingAmount}৳`}
                className="text-input cs-text-input border"
                id="amount"
                {...register("amount2", { required: true })}
                onChange={(e) => {
                  setPayingAmount(e.target.value);
                  setMultiplePayingBalanceDataTwo &&
                    setMultiplePayingBalanceDataTwo(parseFloat(e.target.value));
                }}
              />
            </FormGroup>
            {/* payment method */}
            <div className=" " style={{ width: "48%" }}>
              <Label className="mb-1 text-dark">Payment Method</Label>
              <div className=" d-flex justify-content-between align-items-center border rounded-1 ">
                <Dropdown
                  ref={dropdownMenuOne}
                  className="w-100"
                  show={isPaymentDropdownOpen}
                  onToggle={() => setIsPaymentDropdownOpen((x) => !x)}
                >
                  <Dropdown.Toggle
                    variant=""
                    className="border-0 w-100 text-start fw-normal"
                    value={initialPaymentType}
                  >
                    {initialPaymentType}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    style={{
                      maxHeight: "25vh",
                      boxShadow: "0px 0px 30px 10px rgb(197, 194, 194)",
                    }}
                    className=" overflow-auto rounded-2 h-auto  d-flex   flex-column p-1 gap-1 "
                  >
                    {paymentTypeData?.map((item, index) => (
                      <Dropdown.Item
                        key={index}
                        className=" w-100 text-center text-dark border rounded-1 bg-black-hover p-0"
                        onClick={() => {
                          setInitialPaymentType(item.payment_type);
                          setPaymentDropdownValueTwo &&
                            setPaymentDropdownValueTwo(item.payment_type);
                        }}
                      >
                        <option
                          className="color-white-hover "
                          style={{ fontSize: "15px" }}
                          value={item.payment_type}
                        >
                          {item.payment_type}
                        </option>
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        )}

        {/* account method */}
        <div className=" " style={{ width: "100%" }}>
          <Label className="mb-1 text-dark">Account</Label>
          <div className=" d-flex justify-content-between align-items-center border rounded-1 ">
            <Dropdown
              ref={dropdownMenuTwo}
              className="w-100"
              show={isAccountDropdownOpen}
              onToggle={() => setIsAccountDropdownOpen((x) => !x)}
            >
              <Dropdown.Toggle
                variant=""
                className="border-0 w-100 text-start fw-normal"
                value={initialAccountType}
              >
                {initialAccountType}
              </Dropdown.Toggle>

              <Dropdown.Menu
                style={{
                  maxHeight: "20vh",
                  boxShadow: "0px 0px 30px 10px rgb(197, 194, 194)",
                }}
                className="w-50 overflow-auto rounded-2 h-auto  d-flex  flex-column  p-1 gap-1 "
              >
                {accountDropDownData?.map((item, index) => (
                  <Dropdown.Item
                    key={index}
                    className=" w-100 text-center text-dark border rounded-1 bg-black-hover p-0"
                    onClick={() => {
                      setInitialAccountType(item.account_name);
                      setAccountDropdownValueTwo &&
                        setAccountDropdownValueTwo(item.id);
                    }}
                  >
                    <option
                      className="color-white-hover "
                      style={{ fontSize: "15px" }}
                      value={item.account_name}
                    >
                      {item.account_name}
                    </option>
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        {/* payment note */}
        <div className=" mt-3" style={{ width: "100%" }}>
          <FormGroup>
            <Label for="paymentNoteTwo" className="mb-1 ">
              Payment Note
            </Label>
            <textarea
              id="paymentNoteTwo"
              rows={2}
              placeholder="Enter Payment Note"
              className="cs-text-input  text-input"
            ></textarea>
          </FormGroup>
        </div>
      </div>
    </>
  );
};

export default PosMultipleAddPayment;
