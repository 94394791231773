
import React, { Fragment, useState } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Link, useNavigate } from 'react-router-dom';
import { Card, FormGroup } from 'reactstrap';
import { CustomToast } from '../../../hooks/useCustomToast';
import axios from '../../../util/axiosInstance';

const AddSupplier = () => {
 const navigate = useNavigate();

 const [supplierName, setSupplierName] = useState('');
 const [mobile, setMobile] = useState('');
 const [email, setEmail] = useState('');
 const [phone, setPhone] = useState('');
 const [address, setAddress] = useState('');

 const [locationLink, setLocationLink] = useState('');
 const [isChecked, setIsChecked] = useState(false);
 const [shippingAddress, setShippingAddress] = useState('');
 const [shippingLocation, setShippingLocation] = useState('');

 function generateRandomString(length = 6) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

const randomNumber = generateRandomString();

 const token = localStorage.getItem('access-token')

 const handleWarehouseSubmit = (e) => {
  e.preventDefault();
  const form = e.target;

  const data = {
   "supplier_name": supplierName,
   // "mobile": mobile,
   "address": address,
   "phone": phone,
   "supplier_code": randomNumber
   // "sales_due": 2000.00,
   // "sales_due_return": 100.00,
   // "status": "Active",

   // locationLink,
   // shippingAddress,
   // shippingLocation,
  }

  axios.post('/supplier/addsupplier', data, {
   headers: {
    'Authorization': `Bearer ${token}`
   }
  })
   .then(response => {
    if (response.status === 201 || response.status === 200 || response.status === 202) {
     CustomToast('success', 'Customer Create Successfully')
     navigate('/supplier-list');
    }
    form.reset();
   })
   .catch(error => {
    if (error) {
     CustomToast('error', 'Something Wrong')
    }
   });
 }

 return (
  <Fragment>
   <Breadcrumb title="Add Supplier " parent="Contact Management" />
   <Card className='p-2'>
    <form onSubmit={(handleWarehouseSubmit)}>
     <div className='d-flex justify-content-center align-items-center gap-3 px-3'>

      <div className="col-md-6 col-sm-12 ">
       <FormGroup className='d-flex justify-content-center align-items-center w-100 '>
        <label htmlFor='customerName' className='fw-bold  text-center' style={{ width: '200px' }}>
         Supplier Name<span className="text-danger">*</span>
        </label>
        <input
        required
         onChange={(e) => setSupplierName(e.target.value)}
         className='form-control'

        />
       </FormGroup>
      </div>

      <div className="col-md-6 col-sm-12 ">
       <FormGroup className='d-flex justify-content-center align-items-center w-100 '>
        <label htmlFor='mobile' className='fw-bold  text-center' style={{ width: '200px' }}>
         Phone <span className="text-danger">*</span>
        </label>
        <input
        required
        type='number'
        step={'any'}
         onChange={(e) => setPhone(e.target.value)}
         className='form-control'

        />
       </FormGroup>
      </div>

     </div>

     <div className="col-md-6 col-sm-12 ">
      <FormGroup className='d-flex justify-content-center align-items-center w-100 '>
       <label htmlFor='mobile' className='fw-bold  text-center' style={{ width: '200px' }}>
        Address <span className="text-danger">*</span>
       </label>
       <input
       required
        onChange={(e) => setAddress(e.target.value)}
        className='form-control'

       />
      </FormGroup>
     </div>


     <div className="row">
      <div className=' d-flex align-items-center justify-content-center mt-5 gap-5'>
       <input type="submit" style={{ backgroundColor: '#00A65A', border: 'none', color: 'white', padding: '6px 28px', borderRadius: '5px' }} />
       <Link to={'/dashboard'} style={{ backgroundColor: '#F39C12', border: 'none', color: 'white', padding: '6px 28px', borderRadius: '5px', textDecoration: 'none' }}>Close</Link>
      </div>
     </div>
    </form>
   </Card>
  </Fragment>
 );
};

export default AddSupplier;