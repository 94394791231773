import React, { Fragment } from 'react';
import { Route, Routes } from 'react-router-dom';
import App from '../components/app';
import Datatable from '../components/common/datatable';
import Createcoupons from '../components/coupons/create-coupons';
import ListCoupons from '../components/coupons/list-coupons';
import Dashboard from '../components/dashboard';
import Invoice from '../components/invoice';
import Rates from '../components/localization/rates';
import Taxes from '../components/localization/taxes';
import Translations from '../components/localization/translations';
import Media from '../components/media/media';
import Createmenu from '../components/menus/create-menu';
import Listmenu from '../components/menus/list-menu';
import Createpage from '../components/pages/create-page';
import ListPages from '../components/pages/list-page';
import Digitaladdpro from '../components/products/digital/digital-add-pro';
import Digitalcategory from '../components/products/digital/digital-category';
import Digitalprolist from '../components/products/digital/digital-pro-list';
import Digitalsubcategory from '../components/products/digital/digital-sub-category';
import Addproduct from '../components/products/physical/add-product';
import Category from '../components/products/physical/category';
import Productdetail from '../components/products/physical/product-detail';
import Productlist from '../components/products/physical/product-list';
import Subcategory from '../components/products/physical/sub-category';
import Reports from '../components/reports/report';
import Orders from '../components/sales/orders';
import Transactionsales from '../components/sales/transactions-sales';
import Profile from '../components/settings/profile';
import Createuser from '../components/users/create-user';
import Listuser from '../components/users/list-user';
import Createvendors from '../components/vendors/create.vendors';
import Listvendors from '../components/vendors/list-vendors';
import CategoryList from '../Screen/ProductManagement/CategoryList/CategoryList';
import CategoryAdd from '../Screen/ProductManagement/CategoryList/CategoryAdd';
import CategoryEdit from '../Screen/ProductManagement/CategoryList/CategoryEdit';
import BrandList from '../Screen/ProductManagement/BrandList/BrandList';
import BrandEdit from '../Screen/ProductManagement/BrandList/BrandEdit';
import BrandAdd from '../Screen/ProductManagement/BrandList/BrandAdd';
import UnitsList from '../Screen/ProductManagement/UnitsList/UnitsList';
import AddAccounts from '../Screen/Accounts/AddAccounts';
import AccountsList from '../Screen/Accounts/AccountsList/AccountsList';
import Pos from '../Screen/SalesManagement/Pos/Pos';
import UnitsAdd from '../Screen/ProductManagement/UnitsList/UtilsAdd';
import UnitsEdit from '../Screen/ProductManagement/UnitsList/UtilsEdit';
import AddItem from '../Screen/ProductManagement/AddItem/AddItem';
import ItemsList from '../Screen/ProductManagement/ItemsList/ItemsList';
import ItemListEdit from '../Screen/ProductManagement/ItemsList/ItemListEdit';
import PosMultiplePaymentSlip from '../Screen/SalesManagement/Pos/PosMultiplePaymentSlip';
import PurchaseList from '../Screen/PurchaseManagement/PurchaseList/PurchaseList';
import SalesList from '../Screen/SalesManagement/SalesList/SalesList';
import PurchaseReturnList from '../Screen/PurchaseManagement/PurchaseReturnList/PurchaseReturnList';
import SalesReturnList from '../Screen/SalesManagement/SalesReturnList/SalesReturnList';
import SalesPayment from '../Screen/SalesManagement/SalesPayment/SalesPayment';
import FinancialLayout from '../Screen/FinancialLayout/FinancialLayout';
import EditAccounts from '../Screen/Accounts/editAccounts/EditAccounts';
import ProfitAndLossReport from '../Screen/reportsManagement/ProfitAndLossReport';
import StockReport from '../Screen/SalesManagement/StockReport';
import ViewSales from '../Screen/SalesManagement/SalesList/ViewSales';
import MoneyTransfer from '../Screen/Accounts/MoneyTransfer/MoneyTransfer';
import SalesPaymentScreen from '../Screen/reportsManagement/SalesPayment/SalesPaymentScreen';
import CustomerOrdersScreen from '../Screen/reportsManagement/CustomerOrders/CustomerOrdersScreen';
import NewMoneyDepositeScreen from '../Screen/Accounts/AddMoneyDeposite/NewMoneyDepositeScreen';
import NewMoneytransferScreen from '../Screen/Accounts/NewMoneytransfer/NewMoneytransferScreen';
import MoneyDepositeScreen from '../Screen/Accounts/MoneyDeposite/MoneyDepositeScreen';
// import EditMoneyDepositeScreen from "../Screen/Accounts/EditMoneyDeposite/EditMoneyDepositeScreen01";
import EditMoneyDepositeScreen from '../Screen/Accounts/EditMoneyDeposite/EditMoneyDepositeScreen01';
import CashTransactions from '../Screen/Accounts/CashTransactions/CashTransactions';
import SupplierItemsScreen from '../Screen/reportsManagement/SupplierItems/SupplierItemsScreen';
import SalesReportScreen from '../Screen/reportsManagement/SalesReport/SalesReportScreen';
import CategoryNameSelectDropdown from '../Screen/reportsManagement/ExpenseReport/components/CategoryNameSelectDropdown';
import ExpenseReportScreen from '../Screen/reportsManagement/ExpenseReport/ExpenseReportScreen';
import PurchaseReportsScreen from '../Screen/reportsManagement/PurchaseReport/PurchaseReportsScreen';
import SalesPaymentsReportsScreen from '../Screen/reportsManagement/SalesPayments/SalesPaymentsReportsScreen';
import LabelsScreen from '../Screen/ProductManagement/Labels/LabelsScreen';

// import AdjustmentList from '../Screen/StockManagement/AdjustmentList/AdjustmentList';
import AdjustmentListView from '../Screen/StockManagement/AdjustmentList/AdjustmentListView';
import AdjustmentListEdit from '../Screen/StockManagement/AdjustmentList/AdjustmentListEdit/AdjustmentListEdit';
import AddStokeAdjustmentWarehouse from '../Screen/StockManagement/AdjustmentList/AddStokeAdjustmentWarehouse';
import TransferList from '../Screen/StockManagement/TransferList/TransferList';
import UpdateTransferList from '../Screen/StockManagement/TransferList/UpdateTransferList';
import StockList from '../Screen/StockManagement/StockList/StockList';
import AddBranch from '../Screen/Branch/AddBranch';
import BranchList from '../Screen/Branch/BranchList';
import BranchListEdit from '../Screen/Branch/BranchListEdit';
import ExpensesList from '../Screen/Expenses/ExpensesList';
import ExpensesListAdd from '../Screen/Expenses/ExpensesListAdd';
import ExpensesListEdit from '../Screen/Expenses/ExpensesListEdit';
import CategoriesList from '../Screen/Expenses/CategoriesList/CategoriesList';
import CategoriesListAdd from '../Screen/Expenses/CategoriesList/CategoriesListAdd';
import CategoriesListEdit from '../Screen/Expenses/CategoriesList/CategoriesListEdit';
import AddCustomers from '../Screen/ContactManagement/AddCustomers';
import CustomerList from '../Screen/ContactManagement/CustomerList/CustomerList';
import CustomerListEdit from '../Screen/ContactManagement/CustomerList/CustomerListEdit';
import SupplierList from '../Screen/ContactManagement/SupplierList/SupplierList';
import SupplierListEdit from '../Screen/ContactManagement/SupplierList/SupplierListEdit';
import AddSupplier from '../Screen/ContactManagement/SupplierList/AddSupplier';
import SalesItemUpdated from '../Screen/SalesManagement/SalesList/SalesItemUpdated';
import BranchInformation from '../Screen/SalesManagement/SalesList/MakeDelivery/BranchInformation';
import AdjustmentList from '../Screen/StockManagement/AdjustmentList/AdjustmentList';
// import AdjustmentListView from '../Screen/StockManagement/AdjustmentList/AdjustmentListView';
// import AdjustmentListEdit from '../Screen/StockManagement/AdjustmentList/AdjustmentListEdit/AdjustmentListEdit';
// import AddStokeAdjustmentWarehouse from '../Screen/StockManagement/AdjustmentList/AddStokeAdjustmentWarehouse';
// import TransferList from '../Screen/StockManagement/TransferList/TransferList';
// import UpdateTransferList from '../Screen/StockManagement/TransferList/UpdateTransferList';
// import StockList from '../Screen/StockManagement/StockList/StockList';
// import AddBranch from '../Screen/Branch/AddBranch';
// import BranchList from '../Screen/Branch/BranchList';
// import BranchListEdit from '../Screen/Branch/BranchListEdit';
// import ExpensesList from '../Screen/Expenses/ExpensesList';
// import ExpensesListAdd from '../Screen/Expenses/ExpensesListAdd';
// import MakeDelivery from '../Screen/SalesManagement/SalesList/MakeDelivery/MakeDelivery';
// import SalesItemUpdated from '../Screen/SalesManagement/SalesList/SalesItemUpdated';
// import BranchInformation from '../Screen/SalesManagement/SalesList/MakeDelivery/BranchInformation';
import DeliveriesLIst from '../Screen/DeliveryManagement/Pathao/DeliveriesList/DeliveriesLIst';
import ImportCustomers from '../Screen/ContactManagement/ImportCustomers/ImportCustomers';
import AlertQty from '../Screen/ProductManagement/AlertQty/AlertQty';
import EditPurchase from '../Screen/PurchaseManagement/PurchaseReturnList/EditPurchase';
import SalesReturnProducts from '../Screen/SalesManagement/SalesReturnList/SalesReturnProducts';
import NewPurchaseMain from '../Screen/PurchaseManagement/NewPurchaseMain';
import TransactionalReport from '../Screen/reportsManagement/TransactionalReport/TransactionalReport';
import PurchaseItemReport from '../Screen/reportsManagement/PurchaseItemReport/PurchaseItemReport';
import RolesList from '../Screen/Users/RolesList/RolesList';
import UsersList from '../Screen/Users/UsersList/UsersList';
import AddUserList from '../Screen/Users/UsersList/AddUserList';
import EditUserList from '../Screen/Users/UsersList/EditUserList';
import AddRoles from '../Screen/Users/RolesList/AddRoles';
import EditRoleList from '../Screen/Users/RolesList/EditRoleList';
import SalesReturnView from '../Screen/SalesManagement/SalesReturnList/SalesReturnView';
import SalesReturnPayments from '../Screen/SalesManagement/SalesReturnPayments/SalesReturnPayments';
import AddDelivery from '../components/AddDeliveryPartner/AddDelivery';
import PathaoDelivery from '../components/AddDeliveryPartner/PathaoDelivery';
import ErrorPage from '../ErrorPage/ErrorPage';
import ImportItems from '../Screen/ProductManagement/ImportItems/ImportItems';
import ImportServices from '../Screen/ProductManagement/ImportServices/ImportServices';
import PurchaseInvoice from '../Screen/PurchaseManagement/Invoice/PurchaseInvoice';
import ViewPurchase from '../Screen/PurchaseManagement/PurchaseList/ViewPurchase';
import BalanceSheet from '../Screen/Accounts/BalanceSheet/BalanceSheet';
import TrialBalance from '../Screen/Accounts/TrialBalance/TrialBalance';
import CashFlow from '../Screen/Accounts/CashFlow/CashFlow';
import GeneralLedger from '../Screen/Accounts/GeneralLedger/GeneralLedger';
import ImportSuppliers from '../Screen/ContactManagement/ImportSuppliers/ImportSuppliers';
import AttendanceSheet from '../Screen/ContactManagement/AttendanceSheet/AttendanceSheet';
import PaymentSlip from '../components/PaymentSlip/PaymentSlip';
import CustomerLedger from '../Screen/reportsManagement/CustomerLedger/CustomerLedgerScreen';
import PermissionRoute from './PermissionRoute';
import ReportsManagementIndex from '../Screen/reportsManagement/ReportsManagementIndex/ReportsManagementIndex';

const LayoutRoutes = () => {
  return (
    <Fragment>
      <Routes>
        <Route
          element={<App />}
          ErrorBoundary={<ErrorPage />}>
          {/* Error Page */}
          <Route
            path='*'
            element={<ErrorPage />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/dashboard`}
            element={<Dashboard />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/financial-layout`}
            element={<PermissionRoute permissions={['Dashboard_Financial Report box']} >  <FinancialLayout /></PermissionRoute>}
          />
          {/*  purchase management  */}
          <Route
            path={`${process.env.PUBLIC_URL}/purchase-management/new-purchase`}
            element={<PermissionRoute permissions={[
            'Purchase_Add',
          ]} > <NewPurchaseMain /></PermissionRoute> }
          />
          <Route
            path={`${process.env.PUBLIC_URL}/purchase-management/purchase-list`}
            element={ <PermissionRoute permissions={[              
              'Purchase_View',              
            ]} >  <PurchaseList /> </PermissionRoute> }
          />
          <Route
            path={`${process.env.PUBLIC_URL}/purchase-management/purchase-return-list`}
            element={<PermissionRoute permissions={[              
              'Purchase Return_View',              
            ]} >  <PurchaseReturnList /> </PermissionRoute>}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/purchase-management/purchase-invoice/:id`}
            element={<PermissionRoute permissions={['Purchase_Show all users Purchase Invoices']} ><PurchaseInvoice /> </PermissionRoute>  }
          />
          <Route
            path={`${process.env.PUBLIC_URL}/purchase-management/view-purchase/:id`}
            element={<PermissionRoute permissions={['Purchase_View', 'Purchase_Show all users Purchase Invoices' ]} ><ViewPurchase /></PermissionRoute> }
          />

          {/* Category List route  */}
         
          
          <Route
            path={`${process.env.PUBLIC_URL}/product-management/categoryList`}
            element={<PermissionRoute permissions={["Items_Category View" ]} >
            <CategoryList /></PermissionRoute>}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/product-management/categoryList/add`}
            element={<PermissionRoute permissions={["Items_Category Add" ]} ><CategoryAdd /></PermissionRoute>}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/product-management/categoryList/edit/:id`}
            element={<PermissionRoute permissions={["Items_Category Edit" ]} ><CategoryEdit /></PermissionRoute>}
          />       

          {/* brand List route  */}
          <Route
            path={`${process.env.PUBLIC_URL}/product-management/brand-list`}
            element={<PermissionRoute permissions={["Brand_View" ]} ><BrandList /></PermissionRoute>}
          />
          {/* brand Add route  */}
          <Route
            path={`${process.env.PUBLIC_URL}/product-management/brand-list/add`}
            element={<PermissionRoute permissions={["Brand_Add" ]} ><BrandAdd /></PermissionRoute> }
          />
          {/* brand Edit route  */}
          <Route
            path={`${process.env.PUBLIC_URL}/product-management/brand-list/edit/:id`}
            element={<PermissionRoute permissions={["Brand_Edit" ]} ><BrandEdit /></PermissionRoute> }
          />

       

          <Route
            path={`${process.env.PUBLIC_URL}/product-management/units-list`}
            element={<PermissionRoute permissions={["Units_view" ]} ><UnitsList /></PermissionRoute>}
          />

          <Route
            path={`${process.env.PUBLIC_URL}/product-management/units-list/add`}
            element={<PermissionRoute permissions={["Units_Add" ]} ><UnitsAdd /></PermissionRoute>}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/product-management/units-list/edit/:id`}
            element={<PermissionRoute permissions={["Units_Edit" ]} ><UnitsEdit /></PermissionRoute>}
          />

          {/* product management add item  */}
         

          <Route
            path={`${process.env.PUBLIC_URL}/product-management/add-item`}
            element={<PermissionRoute permissions={["Items_Add"]} ><AddItem /></PermissionRoute>}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/product-management/labels`}
            element={<PermissionRoute permissions={["Items_Print Labels"]} ><LabelsScreen /></PermissionRoute>}
          />

          {/* Product management Items List  */}

          <Route
            path={`${process.env.PUBLIC_URL}/product-management/items-list`}
            element={<PermissionRoute permissions={["Items_View" ]} ><ItemsList /></PermissionRoute>}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/product-management/alert-qty`}
            element={<PermissionRoute permissions={["Dashboard_Stock Alert List" ]} ><AlertQty /></PermissionRoute>}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/product-management/item-edit/:id`}
            element={<PermissionRoute permissions={[ "Items_Edit" ]} ><ItemListEdit /></PermissionRoute>}
          />

          {/* Product-management > Units list   */}
        

          <Route
            path={`${process.env.PUBLIC_URL}/product-management/units-list`}
            element={<PermissionRoute permissions={["Units_view"]} ><UnitsList /></PermissionRoute>}
          />

          <Route
            path={`${process.env.PUBLIC_URL}/products/physical/category`}
            element={<PermissionRoute permissions={[  "Items_Category View"]} ><Category /></PermissionRoute>}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/products/physical/sub-category`}
            element={<PermissionRoute permissions={[  "Items_Category View"]} ><Subcategory /></PermissionRoute>}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/products/physical/product-list`}
            element={<PermissionRoute permissions={[  "Items_View"]} ><Productlist /></PermissionRoute>}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/products/physical/product-detail`}
            element={<PermissionRoute permissions={[  "Items_View"]} ><Productdetail /></PermissionRoute>}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/products/physical/add-product`}
            element={<PermissionRoute permissions={[  "Items_Add",]} ><Addproduct /></PermissionRoute>}
          />

           

          <Route
            path={`${process.env.PUBLIC_URL}/products/digital/digital-category`}
            element={<PermissionRoute permissions={[  "Items_Category View"]} ><Digitalcategory /></PermissionRoute> }
          />
          <Route
            path={`${process.env.PUBLIC_URL}/products/digital/digital-sub-category`}
            element={<PermissionRoute permissions={[  "Items_Category View"]} ><Digitalsubcategory /></PermissionRoute>}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/products/digital/digital-product-list`}
            element={<PermissionRoute permissions={[  "Items_Category View"]} ><Digitalprolist /></PermissionRoute>}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/products/digital/digital-add-product`}
            element={<PermissionRoute permissions={[  "Items_Category add"]} ><Digitaladdpro /></PermissionRoute>}
          />

          <Route
            path={`${process.env.PUBLIC_URL}/sales/orders`}
            element={<PermissionRoute permissions={["Reports_Customer_Orders_Report",]} ><Orders /></PermissionRoute> }
          />
          <Route
            path={`${process.env.PUBLIC_URL}/sales/transactions`}
            element={<PermissionRoute permissions={[  "Accounts_Cash Transactions", "Sales (Include POS)_View",]} ><Transactionsales /></PermissionRoute>}
          />

          <Route
            path={`${process.env.PUBLIC_URL}/coupons/list-coupons`}
            element={<PermissionRoute permissions={[  "Icoupons_view"]} ><ListCoupons /></PermissionRoute>}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/coupons/create-coupons`}
            element={<PermissionRoute permissions={[  "Icoupons_add"]} ><Createcoupons /></PermissionRoute>}
          />

          <Route
            path={`${process.env.PUBLIC_URL}/pages/list-page`}
            element={<PermissionRoute permissions={[  "pages_view"]} ><ListPages /></PermissionRoute>}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/pages/create-page`}
            element={<PermissionRoute permissions={[  "pages_add"]} ><Createpage /></PermissionRoute>}
          />

          <Route
            path={`${process.env.PUBLIC_URL}/media`}
            element={<PermissionRoute permissions={[  "media"]} ><Media /></PermissionRoute>}
          />

          <Route
            path={`${process.env.PUBLIC_URL}/menus/list-menu`}
            element={<PermissionRoute permissions={[  'menus_view']} ><Listmenu /></PermissionRoute>}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/menus/create-menu`}
            element={<PermissionRoute permissions={[  'menus_add']} ><Createmenu /></PermissionRoute>}
          />
      
          <Route
            path={`${process.env.PUBLIC_URL}/users/list-user`}
            element={<PermissionRoute permissions={[  "Users_view"]} ><Listuser /></PermissionRoute>}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/users/create-user`}
            element={<PermissionRoute permissions={[ "Users_Add"]} ><Createuser /></PermissionRoute>}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/accounts/add`}
            element={<PermissionRoute permissions={[ "Accounts_Add"]} ><AddAccounts /></PermissionRoute>}
          />
          {/*

        
        
        */}
          <Route
            path={`${process.env.PUBLIC_URL}/accounts/list`}
            element={<PermissionRoute permissions={["Accounts_View"]} ><AccountsList /></PermissionRoute>}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/accounts/edit/:id`}
            element={<PermissionRoute permissions={["Accounts_Edit"]} ><EditAccounts /></PermissionRoute>}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/accounts/money_transfer`}
            element={<PermissionRoute permissions={["Accounts_View Money Transfer"]} ><MoneyTransfer /></PermissionRoute>}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/accounts/money_transfer/add`}
            element={<PermissionRoute permissions={["Accounts_Add Money Transfer"]} ><NewMoneytransferScreen /></PermissionRoute>}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/accounts/money_deposit/add`}
            element={<PermissionRoute permissions={["Accounts_Add Money Deposit"]} ><NewMoneyDepositeScreen /></PermissionRoute>}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/accounts/money_deposit`}
            element={<PermissionRoute permissions={["Accounts_View Money Deposit"]} ><MoneyDepositeScreen /></PermissionRoute>}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/accounts/money_deposit/edit/:id`}
            element={<PermissionRoute permissions={["Accounts_Edit Money Deposit"]} ><EditMoneyDepositeScreen /></PermissionRoute>}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/accounts/cash_transactions`}
            element={<PermissionRoute permissions={["Accounts_Edit Money Deposit"]} > <CashTransactions /></PermissionRoute>}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/vendors/list_vendors`}
            element={<PermissionRoute permissions={["Suppliers_View"]} > <Listvendors /></PermissionRoute> }
          />
          <Route
            path={`${process.env.PUBLIC_URL}/vendors/create-vendors`}
            element={<PermissionRoute permissions={[ "Suppliers_Add",]} > <Createvendors /></PermissionRoute> }
          />

          <Route
            path={`${process.env.PUBLIC_URL}/localization/transactions`}
            element={<Translations />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/localization/currency-rates`}
            element={<Rates />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/localization/taxes`}
            element={<Taxes />}
          />

          <Route
            path={`${process.env.PUBLIC_URL}/reports/report`}
            element={<Reports />}
          />

          <Route
            path={`${process.env.PUBLIC_URL}/settings/profile`}
            element={<Profile />}
          />

          <Route
            path={`${process.env.PUBLIC_URL}/invoice`}
            element={<Invoice />}
          />

          <Route
            path={`${process.env.PUBLIC_URL}/data-table`}
            element={<Datatable />}
          />
          {/* sales management*/}

          <Route
            path={`${process.env.PUBLIC_URL}/sales-management/pos`}
            element={<PermissionRoute permissions={[ "Sales (Include POS)_Add"]} > <Pos /></PermissionRoute>}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/sales-management/sales-return-item/:id`}
            element={<PermissionRoute permissions={[ "Sales Return_View"]} ><SalesReturnProducts /></PermissionRoute>}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/sales-management/sales-list`}
            element={<PermissionRoute permissions={["Sales (Include POS)_View",]} ><SalesList/></PermissionRoute>}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/sales-management/sales-payment`}
            element={<PermissionRoute permissions={[ "Sales (Include POS)_Sales_Payments_View"]} ><SalesPayment /></PermissionRoute>}
          />
          {/* sales returns */}
          <Route
            path={`${process.env.PUBLIC_URL}/sales-management/sales-return-list`}
            element={<PermissionRoute permissions={[ "Sales Return_View"]} ><SalesReturnList /></PermissionRoute>}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/sales-management/view-sales-return/:id`}
            element={<PermissionRoute permissions={[ "Sales Return_View"]} ><SalesReturnView /></PermissionRoute>}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/sales-management/sales-return-payment`}
            element={<PermissionRoute permissions={[ "Sales Return_Sales_Return_Payments_View"]} ><SalesReturnPayments /></PermissionRoute>}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/sales-management/view-sales/:id`}
            element={<PermissionRoute permissions={[ "Sales (Include POS)_View"]} ><ViewSales /></PermissionRoute>}
          />

          <Route
            path={`${process.env.PUBLIC_URL}/sales-management/print-multiple-payment-slip`}
            element={<PermissionRoute permissions={[ "Sales (Include POS)_Sales_Payments_View"]} ><PosMultiplePaymentSlip /></PermissionRoute>}
          />
          {/* delivery management */}
          <Route
            path={`${process.env.PUBLIC_URL}/delivery-management/delivery-list`}
            element={<DeliveriesLIst />}
          />
          {/* reports management*/}
          <Route
            path={`${process.env.PUBLIC_URL}/reports/profit-loss`}
            element={<ProfitAndLossReport />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/reports/stock`}
            element={<StockReport />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/reports/sales-and-payment`}
            element={<SalesPaymentScreen />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/reports/customer-ledger`}
            element={<CustomerLedger />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/reports/customer-orders`}
            element={<CustomerOrdersScreen />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/stock-management/adjustment-list`}
            element={<AdjustmentList />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/stock-management/stoke-adjustment-add`}
            element={<AddStokeAdjustmentWarehouse />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/stock-management/adjustment-list-view/:id`}
            element={<AdjustmentListView />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/stock-management/adjustment-list-edit/:id`}
            element={<AdjustmentListEdit />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/stock-management-transfer-list`}
            element={<TransferList />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/stock-management-stock-transfer`}
            element={<UpdateTransferList />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/stock-management/stock-list`}
            element={<StockList />}
          />

          <Route
            path={`${process.env.PUBLIC_URL}/branch-add`}
            element={<AddBranch />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/branch-list`}
            element={<BranchList />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/branch-list-edit/:id`}
            element={<BranchListEdit />}
          />
          {/*  Expense  */}
          <Route
            path={`${process.env.PUBLIC_URL}/expenses-list`}
            element={<ExpensesList />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/expenses-list-add`}
            element={<ExpensesListAdd />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/city`}
            element={<BranchInformation />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/expenses-list-edit/:id`}
            element={<ExpensesListEdit />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/expenses-categories-list`}
            element={<CategoriesList />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/expenses-categories-add`}
            element={<CategoriesListAdd />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/categories-list-edit/:id`}
            element={<CategoriesListEdit />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/add-customers`}
            element={<AddCustomers />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/customer-list`}
            element={<CustomerList />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/customer-list-edit/:id`}
            element={<CustomerListEdit />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/supplier-list`}
            element={<SupplierList />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/supplier-list-edit/:id`}
            element={<SupplierListEdit />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/add-supplier`}
            element={<PermissionRoute permissions={[ "Suppliers_Add"]} > <AddSupplier /> </PermissionRoute>}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/reports/supplier-items`}
            element={<PermissionRoute permissions={[ "Reports_Supplier_Items_Report"]} > <SupplierItemsScreen /> </PermissionRoute>}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/reports/sales`}
            element={<PermissionRoute permissions={[ "Reports_Sales_Item Report",]} > <SalesReportScreen /></PermissionRoute> }
          />
          <Route
            path={`${process.env.PUBLIC_URL}/reports/expense`}
            element={<PermissionRoute permissions={[  "Reports_Expense_Report"]} > <ExpenseReportScreen /></PermissionRoute>}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/reports/purchase`}
            element={<PermissionRoute permissions={[ "Reports_Purchase_Report",]} > <PurchaseReportsScreen /> </PermissionRoute>}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/reports/sales-items`}
            element={<PermissionRoute permissions={[ "Reports_Sales_Item Report",]} > <SalesReportScreen /></PermissionRoute>}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/reports/sales-payment`}
            element={<PermissionRoute permissions={["Reports_Sales_Payments_Report",]} ><SalesPaymentsReportsScreen /></PermissionRoute>}
          />
          
          <Route
            path={`${process.env.PUBLIC_URL}/import-customers`}
            element={<PermissionRoute permissions={["Customers_Add"]} ><ImportCustomers /></PermissionRoute>}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/reports/transactional-report`}
            element={<PermissionRoute permissions={["Reports_Sales_Payments_Report", ]} ><TransactionalReport /></PermissionRoute>}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/reports/purchase-item-report`}
            element={<PermissionRoute permissions={[ "Reports_Purchase_Report"]} ><PurchaseItemReport /></PermissionRoute>}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/users-list`}
            element={<PermissionRoute permissions={["Users_view"]} ><UsersList /></PermissionRoute>}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/users-list-add`}
            element={<PermissionRoute permissions={[ "Users_Add"]} > <AddUserList /></PermissionRoute> }
          />
          <Route
            path={`${process.env.PUBLIC_URL}/users-list-edit/:id`}
            element={<PermissionRoute permissions={[ "Users_Edit"]} >  <EditUserList /> </PermissionRoute> }
          />
          <Route
            path={`${process.env.PUBLIC_URL}/roles-list`}
            element={<PermissionRoute permissions={[ "Roles_view"]} > <RolesList /></PermissionRoute> }
          />
          <Route
            path={`${process.env.PUBLIC_URL}/roles-list-add`}
            element={<PermissionRoute permissions={[ "Roles_Add"]} > <AddRoles /></PermissionRoute> }
          />
          <Route
            path={`${process.env.PUBLIC_URL}/role-list-edit/:id`}
            element={<PermissionRoute permissions={[ "Roles_Edit"]} > <EditRoleList /></PermissionRoute> }
          />
          {/* setting */}
          <Route
            path={`${process.env.PUBLIC_URL}/AddDeliveryPartner/AddDelivery`}
            element={<PermissionRoute permissions={[  "Accounts_Add"]} ><AddDelivery /></PermissionRoute>}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/AddDeliveryPartner/PathaoDelivery`}
            element={<PathaoDelivery />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/product-management/import-items`}
            element={<PermissionRoute permissions={[ "Items_Add"]} >  <ImportItems /> </PermissionRoute> }
          />
          <Route
            path={`${process.env.PUBLIC_URL}/product-management/import-services`}
            element={<PermissionRoute permissions={[ "Items_Add"]} > <ImportServices /> </PermissionRoute> }
          />
          <Route
            path={`${process.env.PUBLIC_URL}/accounts/balance-sheet`}
            element={<PermissionRoute permissions={[  "Accounts_View"]} > <BalanceSheet /></PermissionRoute> }
          />
          <Route
            path={`${process.env.PUBLIC_URL}/accounts/trial-balance`}
            element={<PermissionRoute permissions={[ "Accounts_View",]} > <TrialBalance /></PermissionRoute> }
          />
          <Route
            path={`${process.env.PUBLIC_URL}/accounts/cash-flow`}
            element={<PermissionRoute permissions={[  'Accounts_Cash Transactions']} > <CashFlow /></PermissionRoute> }
          />
          <Route
            path={`${process.env.PUBLIC_URL}/accounts/general-ledger`}
            element={<PermissionRoute permissions={[  "Accounts_View"]} > <GeneralLedger /></PermissionRoute> }
          />
          <Route
            path={`${process.env.PUBLIC_URL}/import-suppliers`}
            element={<PermissionRoute permissions={[ "Suppliers_Add"]} > <ImportSuppliers /></PermissionRoute> }
          />
          <Route
            path={`${process.env.PUBLIC_URL}/attendance-sheet`}
            element={<PermissionRoute permissions={['Customers_View']} > <AttendanceSheet /></PermissionRoute> }
          />
          <Route
            path={`${process.env.PUBLIC_URL}/purchase-management/payment-slip`}
            element={<PermissionRoute permissions={["Purchase_Show all users Purchase Invoices"]} > <PaymentSlip /></PermissionRoute>}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/reports-management`}
            element={<ReportsManagementIndex />}
          />
        </Route>
      </Routes>
    </Fragment>
  );
};

export default LayoutRoutes;
