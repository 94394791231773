import React, { useEffect, useState } from 'react'
import { Button, Table } from 'reactstrap';
import TableLoading from '../../../../components/common/loading/TableLoading';
import { useNavigate } from 'react-router-dom';
import axios from '../../../../util/axiosInstance';
import moment from 'moment';

const token = localStorage.getItem('access-token');
const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
};


const SalesPaymentTable = ({ queryLink, wareHouseDropdownValue, supplierNameDropdownValue }) => {
    const [isLoading, setLoading] = useState(false)
    const [dataToRander, setDataToRander] = useState([])




    const navigate = useNavigate();
    const btnStyle = {
        height: '40px',
        width: '40px',
        borderRadius: '50%',
        padding: '8px',
        background: 'transparent',
    };

    useEffect(() => {
        if (queryLink) axios.get(queryLink).then(response => {
            setDataToRander(response?.data)
            setLoading(false)
        })
            .catch(err => {
                setLoading(false)
            })
        // /report/sales-by-customer/75?startDate=2023-10-07&endDate=2023-10-07

    }, [queryLink])

    const fatchQuery = () => {
        setLoading(true)
        axios.get(queryLink)
            .then(response => {
                setDataToRander(response?.data)
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
            })
    }





    return (
        <div>
            <div className='d-flex col-10 mx-auto justify-content-center my-3'>
                <Button className='btn-info mx-3 text-white' onClick={fatchQuery}>Show</Button>
                {/* <Button onClick={navigate('/')}  >cancel</Button> */}
            </div>
            <Table
                bordered
                hover
                striped>
                <thead className='text-center'>
                    <tr>
                        <th>#</th>
                        <th>Warehouse Name </th>
                        <th>Invoice No.</th>
                        <th>Purchase Date</th>
                        <th>Supplier Name</th>
                        <th>Items Name</th>
                        <th>Purchase Price</th>

                        {/* <th>Last Order(in Days)</th> */}

                    </tr>
                </thead>
                <tbody className='text-center'>
                    {dataToRander?.length === 0 && isLoading ? (
                        <TableLoading
                            noRow={9}
                            noTd={7}
                        />
                    ) : (
                        dataToRander

                            ?.map((item, i) => {

                                return (
                                    <tr key={item.purchase?.id}>
                                        <th scope='row'>{i + 1}</th>
                                        <th>{item.purchase?.warehouse_id}</th>
                                        <th>{item.purchase?.purchase_code}</th>
                                        <td>{moment(item?.created_date).format('L')}</td>


                                        <th>{item.purchase?.supplier_id}</th>
                                        <th>{
                                            item?.purchaseItems?.map((x) => (<p>{x.item_name}({x.purchase_qty})</p>))
                                        }</th>

                                        <th>{
                                            item?.purchaseItems?.reduce((x, y) => x + y.purchase_qty * y.price_per_unit, 0)
                                        }</th>


                                    </tr>
                                )
                            })
                    )}

                </tbody>
            </Table>


            <div className='Toastify' />
        </div>
    )
}

export default SalesPaymentTable
