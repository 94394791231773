


import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";

import axios from "../../../util/axiosInstance";



const StockReportDropdownSectionForBrand = ({
  borderEnd,
  setInputValueTwo,
  inputType,
  setInputValue,
  InitialDropdownValue,

  titleData,
  placeHolderData,
  queryData, setQueryData,
  apiLink
}) => {

  const [dropdownData, setDropdownData] = useState([]);

  const [dropdownValue, setDropdownValue] = useState(InitialDropdownValue);





  const [searchValue, setSearchValue] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Track dropdown state

  useEffect(() => {
    // handleFilter();
  }, [searchValue]);
  const handleFilter = (name) => {
    setQueryData({ ...queryData, brand_name: name })
    setDropdownValue(name)

  }


  useEffect(() => {
    axios.get(apiLink).then(
      (response) => {

        setDropdownData(response.data)

      }
    )

  }, [apiLink])
  return (
    <>
      <Dropdown
        className={`w-100 my-2  ${borderEnd &&
          ((borderEnd === "brand" && "border-start") ||
            (borderEnd === "customerCall" && "border-end border-start"))
          } `}
        show={isDropdownOpen} // Show/hide the dropdown based on state
        onToggle={(isOpen) => setIsDropdownOpen(isOpen)} // Update dropdown state whenever it's toggled
      >
        <Dropdown.Toggle
          title={titleData && titleData}
          id="dropdown-basic"
          className="border-0 w-100 text-start fw-normal"
          //   style={{ backgroundColor: "#ffffff" }}
          value={dropdownValue}
        >
          {dropdownValue}
        </Dropdown.Toggle>

        <Dropdown.Menu
          style={{
            maxHeight: "25vh",
            boxShadow: "0px 0px 30px 10px rgb(197, 194, 194)",
            width: `${inputType === "number" ? "100%" : "60%"}`,
          }}
          className=" overflow-auto rounded-2 h-auto  d-flex mt-1  flex-column  pt-1 p-2 gap-1 "
        >
          <Dropdown.Item className="w-100 p-0"></Dropdown.Item>
          {inputType === "search" && (
            <input
              type="text"
              style={{ fontSize: "15px" }}

              className="w-100 border text-dark  rounded-1 mb-1  text-input  px-2 bg-body-secondary text-input"
              placeholder={placeHolderData && placeHolderData}
              onChange={(e) => {
                setSearchValue && setSearchValue(e.target.value);
                setInputValue && setInputValue(e.target.value);
              }}
            />
          )}

          {/* Todo: error */}
          {inputType === "search" && dropdownData.length === 0 && (
            <p className="text-center text-danger fw-bold">No Data Found</p>
          )}
          {(dropdownData)
            ?.map((item, index) => <Dropdown.Item
              key={index}
              className=" w-100 text-center text-dark border rounded-1 bg-black-hover p-0"
              onClick={() => {
                handleFilter(item.brand_name)
              }}
            >
              <option
                className="color-white-hover "
                style={{ fontSize: "15px" }}
                value={item.brand_name}
              >
                {item.brand_name}
              </option>
            </Dropdown.Item>
            )}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default StockReportDropdownSectionForBrand;