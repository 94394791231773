
import React, { useEffect, useState } from "react";
import { AiOutlinePlus, AiOutlineMinus, AiFillDelete } from "react-icons/ai";

import Swal from "sweetalert2";
import useNumberFormatter from "../../../../hooks/useNumberFormatter";




const EditPreviousAdjustmentPurchaseTable = ({
 product,
 products,
 setSelectedItemData,
 setTotalSubTotal,
 setTotalQuantity,
 selectedItemData,
 totalQuantity,
 setCustomBarcode,
 customBarcode,
 salesItems,
 setSalesItems,
}) => {
 const { item_name, stock, adjustment_qty, item_id, sales_price, id } = product || {};

 const [updatedStock, setUpdatedStock] = useState();
 const numericFormatter = useNumberFormatter();
 const [count, setCount] = useState(adjustment_qty || 1);

 const [price, setPrice] = useState(0);


 const [discount, setDiscount] = useState("");

 const [itemName, setItemName] = useState()



 // const [lotNumber, setLotNumber] = useState(lot_number);
 const priceByQuantity = parseFloat(price) * parseFloat(count);



 // stock update
 useEffect(() => {
  setUpdatedStock(parseFloat(stock) - parseFloat(count ? count : 0));
 }, [stock, count]);

 // sales update
 useEffect(() => {
  setPrice(sales_price ? sales_price : 0);
 }, [sales_price]);

 // quantity increment decrement
 const handleIncrement = (e) => {
  e.preventDefault();
  // if (updatedStock) {
  //  if (updatedStock ) {
  setCount(parseFloat(count) + 1);
  // setUpdatedStock(parseFloat(updatedStock) - 1);
  // }
 
 // else {
 //  setCount(parseFloat(count) + 1);
 // setUpdatedStock(parseFloat(updatedStock) - 1);

 // }
};

const handleDecrement = (e) => {
 e.preventDefault();
 // if (parseFloat(count)  ) {
 setCount(parseFloat(count) - 1);
 // setUpdatedStock(parseFloat(updatedStock) + 1);
 // }
};





// Remove the current product from the products array
const handleDeleteProduct = () => {
 const updatedProducts = products.filter((p) => p.id !== product.id);
 setSelectedItemData(updatedProducts);

 // Calculate new total and subtotal
 const newTotalSubTotal = updatedProducts.reduce(
  (acc, product) =>
   acc + parseFloat(product.subtotal ? product.subtotal : 0),
  0
 );
 const totalQuantity = updatedProducts.reduce(
  (acc, product) =>
   acc + parseFloat(product.itemQuantity ? product.itemQuantity : 0),
  0
 );

 // removed from item list
 const updatedSalesItems = salesItems.filter(
  (item) => item.item_id !== productsData.item_id
 );

 // Update state with the new values
 setSelectedItemData(updatedProducts);
 setTotalSubTotal(newTotalSubTotal);

 setTotalQuantity(totalQuantity);
 setSalesItems([...updatedSalesItems])
};

// handle total and subtotal calculation
// const handleTotalSubTotal = () => {
//  let productSubTotal = priceByQuantity - (discount ? discount : 0);

//  const updatedProducts = products.map((p) => {
//   if (p?.id === id) {
//    return { ...p, subtotal: productSubTotal, itemQuantity: count };
//   }
//   return p;
//  });

//  const newTotalSubTotal = updatedProducts.reduce(
//   (acc, product) =>
//    acc + parseFloat(product.subtotal ? product.subtotal : 0),
//   0
//  );
//  const totalQuantity = updatedProducts.reduce(
//   (acc, product) =>
//    acc + parseFloat(product.itemQuantity ? product.itemQuantity : 0),
//   0
//  );



//  setTotalQuantity(totalQuantity);
//  setSelectedItemData(updatedProducts);
//  setTotalSubTotal(newTotalSubTotal);
// };
useEffect(() => {
 // handleTotalSubTotal(); // This calls the handleDiscount function
}, [
 count,]);

const productsData = {
 // item_id: id,
 // sales_qty: numericFormatter(count),



 item_id: id,
 purchase_qty: count,
 price_per_unit: price,
 item_name: itemName || item_name,

 // custom_barcode: customBarcode,
};
const handleAddMemo = () => {
 const nnnn = salesItems.filter(
  (item) => item.item_id !== productsData.item_id
 );

 setSalesItems([...nnnn, productsData]);
};

useEffect(() => {
 handleAddMemo();
}, [
 count,
]);

return (

 <tr>
  <td className="text-start ">
   {item_name || 'No Name'}
   {/* <input type="text" Value={item_name} onChange={(e) => { setItemName(e.target.value.trim() || item_name) }} style={{ width: '40%' }} className="form-control" /> */}
  </td>


  {/* quantity increment  decrement  */}

  <td className="d-flex  justify-content-center align-items-center ">
   <span
    style={{ width: "150px" }}
    className="border rounded-1 d-flex justify-content-between align-items-center"
   >
    <button
     className="d-flex justify-content-center align-items-center border-end border-0 col-2   p-2"
     onClick={handleDecrement}
    >
     <span>
      <AiOutlineMinus className="text-danger fw-bold" />
     </span>
    </button>
    <span className=" text-center px-2 border-0 d-inline-block col-8">
     <input
      type="number"
      step={"any"}
      placeholder="Quantity"
      value={count}
      onChange={(e) => {setCount(parseFloat(e.target.value)) }}
      className="border-0  text-center  text-center w-100"
     />
    </span>
    <button
     className="d-flex justify-content-center align-items-center border-start border-0 p-2 col-2"
     onClick={handleIncrement}
    >
     <span>
      <AiOutlinePlus className="text-success fw-bold" />
     </span>
    </button>
   </span>
  </td>

  <td>
   <span className="d-flex justify-content-center align-items-center  py-2">
    <AiFillDelete
     onClick={handleDeleteProduct}
     style={{ cursor: "pointer" }}
     className="text-danger fs-5"
    ></AiFillDelete>
   </span>
  </td>
 </tr>

);
};

export default EditPreviousAdjustmentPurchaseTable;





