import React from 'react'


import TransferDepositDropdownSectionForCredit from './TransferDepositDropdownSectionForCredit'
import TransferDepositetDropdownSectionForDebit from './TransferDepositetDropdownSectionForDebit'

const TransferDeposit = ({ setDebitAcc, setCreditAcc, DebitAcc, creditAcc, }) => {
    return (
        <div className="d-flex col-12 justify-content-around" >
            <div className="col-5 me-2 zn-1">

                <TransferDepositetDropdownSectionForDebit
                    setQueryData={setDebitAcc}

                    borderEnd={"brand"}
                    inputType={"search"}
                    // setInputValue={setWareHouseSearch}
                    InitialDropdownValue={'select debit account'}

                    titleData={"Select debit account"}
                    placeHolderData={"Select debit account"}
                    dataField={"brand_name"}
                    apiLink='/accounts/viewaccounts'
                />
            </div>


            <div className="col-5 ms-2 ">
                <TransferDepositDropdownSectionForCredit
                    setQueryData={setCreditAcc}

                    borderEnd={"category"}
                    inputType={"search"}
                    // setInputValue={setWareHouseSearch}
                    InitialDropdownValue={'select account credit'}


                    titleData={"category"}

                    placeHolderData={"Enter category Name"}
                    dataField={"category_name"}
                    apiLink='/accounts/viewaccounts'
                />


            </div>

        </div>

    )
}

export default TransferDeposit