import React from "react";
import useNumberFormatter from "../../../hooks/useNumberFormatter";

const PurchaseInvoiceBanner = ({ data }) => {
  const numericFormatter = useNumberFormatter();
  const {
    supplier_name,
    address,
    phone,
    purchase_date,
    purchase_code,
    paid_amount,
    purchase_payments,
  } = data || {};
  return (
    <>
      <section className="d-flex justify-content-between   col-12 my-3">
        {/* table-1 */}
        <div
          className="text-center border border-black w-100 d-flex flex-column 
        align-items-start"
        >
          {/* child-1-table-1 */}
          <table>
            <thead>
              <tr className="d-none">
                <th>Item Name</th>
                <th>Item Name</th>
              </tr>
            </thead>
            <tbody className="">
              {/* child-1-1 */}
              <tr className="">
                <td className="px-2 pt-2 fw-500 text-end col-4  ">
                  Purchase Date :
                </td>
                <td className="pt-2 text-start ">
                  {purchase_date?.slice(0, 10)}
                </td>
              </tr>
              {/* child-1-2 */}
              <tr className="">
                <td className="px-2 fw-500 text-end col-4  ">Paid Amount :</td>
                <td className="text-start ">
                  {numericFormatter(paid_amount || 0)}
                </td>
              </tr>
              {/* child-1-4 */}
              <tr className="">
                <td className="px-2 fw-500 text-end col-4  ">
                  Purchase Code :
                </td>
                <td className="text-start ">{purchase_code}</td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* table-2 */}
        <div className="text-center border border-start-0 border-black d-flex flex-column align-items-start w-100">
          <table>
            <thead>
              <tr className="d-none">
                <th>Item Name</th>
                <th>Item Name</th>
              </tr>
            </thead>
            <tbody className="">
              {/* child-1-2 */}
              <tr className="">
                <td className="px-2 pt-2 fw-500 text-end  col-5 ">
                  Supplier Name :
                </td>
                <td className="pt-2 text-start ">{supplier_name}</td>
              </tr>

              {/* child-1-3 */}
              <tr className="">
                <td className="px-2 fw-500 text-end col-5  ">
                  Supplier Address :
                </td>
                <td className="text-start ">{address}</td>
              </tr>
              {/* child-1-4*/}
              <tr className="">
                <td className="px-2 pb-2 fw-500 text-end col-5  ">
                  Supplier Phone :
                </td>
                <td className="text-start pb-2">{phone}</td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* TODO:when get api check yasin7 branch to get style then complete this */}
      </section>
      <section className="my-3">
        <table className="text-center table-bordered border-black w-100">
          <thead>
            <tr>
              <th>SL</th>
              <th>Date</th>
              <th>Account Name</th>
              <th>Payment Code</th>
              <th>Payment Type</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {purchase_payments?.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item?.payment_date?.slice(0, 10) || "-"}</td>
                <td>{item?.account_name || "-"}</td>
                <td>{item?.payment_code || "-"}</td>
                <td>{item?.payment_type || "-"}</td>
                <td>{numericFormatter(item?.payment || 0)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
    </>
  );
};

export default PurchaseInvoiceBanner;
