import { useQuery } from "@tanstack/react-query";
import useAxiosSecure from "./useAxiosSecure";

const useGetData = (apiLink, query) => {
  const [axios] = useAxiosSecure();
  const { data, refetch, isLoading } = useQuery({
    queryKey: [query || ""],
    queryFn: async () => {
      const res = await axios.get(apiLink);
      return res.data;
    },
  });
  return [data, refetch, isLoading];
};

export default useGetData;
