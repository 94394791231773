
import { Dropdown } from "react-bootstrap";
import { AiFillDelete, AiFillEye } from "react-icons/ai";
import { FaBuilding } from "react-icons/fa";
import { BiSolidEdit } from "react-icons/bi";
import { BiPlusMedical } from "react-icons/bi";
import { Fragment, useEffect, useState } from "react";
import { Card, CardBody, Container, } from "reactstrap";
import Breadcrumb from "../../../components/common/breadcrumb";
import { Table } from 'reactstrap';
import { Link } from "react-router-dom";

import axios from "../../../util/axiosInstance";
import useGetData from "../../../hooks/useGetData";
import TableLoading from "../../../components/common/loading/TableLoading";

import PosDropdownSection from "../../SalesManagement/Pos/PosDropdownSection";
import { CustomToast } from './../../../hooks/useCustomToast';


const AdjustmentList = () => {

  const customBranchData = {
    id: null,
    warehouse_type: null,
    warehouse_name: "All-Branch",
    mobile: null,
    email: null,
    status: null,
    created_date: null,
  };

  const [branchData] = useGetData("/branch/viewbranch", "branch");
  const [updatedBranchData, setUpdatedBranchData] = useState([]);
  const [wareHouseDropdownValue, setWarehouseDropdownValue] = useState("All-Branch");
  const [warehouseDropdownId, setWarehouseDropdownId] = useState('')



  useEffect(() => {

    if (branchData) {
      setUpdatedBranchData([customBranchData, ...branchData]);
    }
  }, [branchData]);




  // const [branchData, setBranchData] = useState([]);
  // const [warehouseDropdownId, setWarehouseDropdownId] = useState('');

  const btnStyle = {
    height: '40px',
    width: '40px',
    borderRadius: '50%',
    padding: '8px',
    background: 'transparent',
  };


  const [data, setData] = useState([]);

  let allData = data?.length;

  const [searchValue, setSearchValue] = useState("");
  const [selectedValue, setSelectedValue] = useState(10);
  const [adjustment, setAdjustment] = useState([]);



  const [isLoading, setIsLoading] = useState(false);

  // const [adjustmentDataData, adjustmentDataRefetch] = useGetData('/adjustment/stock-adjustments/', 'adjustmentDataData');


 

  useEffect(() => {
    setIsLoading(true);
    axios('/adjustment/stock-adjustments/')
      .then(data => {
        setData(data.data);
        setIsLoading(false);  // Stop loading
      })
      .catch(error => {
        // setError(error);
        setIsLoading(false);  // Stop loading
      });

  }, [])

  const handleDelete = (id) => {
    axios.delete(`/category/deletecategory/${id}`)
      .then(response => {
        window.location.reload();
      })
      .catch(error => {
        CustomToast('error', 'data not deleted')
      });
  }

  useEffect(() => {
    handleFilter()
  }, [searchValue, data, warehouseDropdownId, wareHouseDropdownValue]);

  // search
  const handleFilter = () => {
    if (data) {
      let filterAdjustment = [...data]

      // Apply all branch filter
      if (wareHouseDropdownValue !== "All-Branch") {
        filterAdjustment = filterAdjustment?.filter((item) =>
        item?.warehouse_id=== warehouseDropdownId
       );
      }

      // search filter 
      if (searchValue !== '') {
        filterAdjustment = filterAdjustment?.filter((adjustment) =>
        adjustment?.reference_no?.toLowerCase().includes(searchValue.toLocaleLowerCase())
        );
      }
      setAdjustment(filterAdjustment);
    }

  }



  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
  };



  return (
    <>
      <Breadcrumb
        title='Adjustment List'
        parent='Stock Management'
      />
      <Container fluid={true}>
        <Card>
          <div
            className='d-flex justify-content-between mt-3'
            style={{ margin: '0px 30px' }}>
            {/* warehouse dropdown section  */}

            <div className='col-md-6'>
              <div
                style={{ width: '100%' }}
                className=' d-flex justify-content-between align-items-center border border rounded-1 '
                title='Warehouse'>
                <span className='fw-bold px-2'>
                  <FaBuilding className='text-danger fs-5'></FaBuilding>
                </span>
                {/* dropdown data */}
                {branchData && (
                  <PosDropdownSection
                    inputType={'search'}
                    labelText={'warehouse'}
                    setWarehouseDropdownId={setWarehouseDropdownId}
                    InitialDropdownValue={'All-Branch'}
                    setWarehouseDropdownValue={setWarehouseDropdownValue}
                    data={updatedBranchData}
                    titleData={'Warehouse'}
                    placeHolderData={
                      'Enter Warehouse Name'
                    }></PosDropdownSection>
                )}
              </div>
            </div>
            <Link
              className='d-flex align-items-center gap-2'
              style={{
                backgroundColor: '#00C0EF',
                textDecoration: 'none',
                color: 'white',
                padding: '6px',
                borderRadius: '5px',
              }}
              to={'/stock-management/stoke-adjustment-add'}>
              <BiPlusMedical /> New Stock Management
            </Link>
          </div>

          <CardBody>
            <div className='row'>
              <div className='col-md-4 d-flex align-items-center '>
                <span>Show</span>
                <select
                  onChange={(e) => setSelectedValue(e.target.value)}
                  className='mx-1'>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                  <option value={500}>500</option>
                  <option value={allData}>AllData</option>
                </select>
                <span>entries</span>
              </div>
              <div className='col-md-4 d-flex align-items-center justify-content-end'></div>
              <div className='col-md-4 d-flex align-items-center gap-2'>
                <span>Search </span>{' '}
                <input
                  type='text'
                  placeholder='By Reference'
                  className='form-control'
                  value={searchValue}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div
              style={{
                backgroundColor: '#F2F2F2',
                padding: '10px',
                marginTop: '2px',
              }}>
              <Table
                bordered
                hover
                striped>
                <thead className='text-center'>
                  <tr>
                    <th>SL </th>
                    <th>Adjustment Date</th>
                    <th>Reference No </th>
                    <th>Created By</th>
                    <th>Action</th>
                  </tr>
                </thead>
                {/* {
								isLoading && (
									<LoadingBar color="red" style={{ marginTop: '10px' }}

										progress={progress}
										onLoaderFinished={() => setProgress(0)}
									/>
								)
							} */}
                <tbody className='text-center'>
                  {isLoading && (
                    <TableLoading
                      noRow={10}
                      noTd={5}
                    />
                  )}

                  {adjustment && (
                    <>
                      {adjustment
                        ?.slice()
                        .sort((a, b) => b.adjustment_id - a.adjustment_id)
                        .slice(0, selectedValue)
                        .map((singleAdjustment, index) => (
                          <tr key={singleAdjustment.id}>
                            <th scope='row'>{index + 1}</th>
                            <td>
                              {singleAdjustment.adjustment_date.slice(0, 10)}
                            </td>
                            <td>{singleAdjustment.reference_no}</td>
                            <td>{singleAdjustment.status}</td>

                            <td
                              className='position-relative '
                              style={{ width: '110px' }}>
                              <Dropdown
                                className='w-100 position-absolute start-0 '
                                style={{ top: '5px' }}>
                                <Dropdown.Toggle
                                  variant=''
                                  name='paymentMethod'
                                  id='payment'
                                  className='border-0 py-1 px-2 text-start fw-normal text-white c-pos-button7 border'
                                  style={{
                                    fontSize: '10px',
                                    backgroundColor: '#093d86',
                                  }}>
                                  Action
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                  style={{
                                    maxHeight: '27vh',
                                    boxShadow:
                                      '0px 0px 3px 2px rgb(197, 194, 194)',
                                  }}
                                  className=' overflow-auto rounded-2 h-auto  d-flex   flex-column p-1 gap-1 '>
                                  <Dropdown.Item className=' w-100 text-start text-dark  rounded-1 bg-black-hover p-0 ps-3 d-flex  align-items-center gap-1 '>
                                    <AiFillEye
                                      style={{
                                        color: 'white',
                                        backgroundColor: 'black',
                                      }}></AiFillEye>
                                    <Link
                                      to={`/stock-management/adjustment-list-view/${singleAdjustment?.adjustment_id}`}
                                      style={{
                                        fontSize: '14px',
                                        textDecoration: 'none',
                                      }}
                                      className='color-white-hover cs-text-decoration-none'>
                                      View Stock Management
                                    </Link>
                                  </Dropdown.Item>
                                  <Dropdown.Item className=' w-100 text-start text-dark  rounded-1 bg-black-hover p-0 ps-3 d-flex  align-items-center gap-1 '>
                                    <BiSolidEdit
                                      style={{
                                        color: 'white',
                                        backgroundColor: 'black',
                                      }}></BiSolidEdit>
                                    <Link
                                      to={`/stock-management/adjustment-list-edit/${singleAdjustment.adjustment_id}`}
                                      style={{
                                        fontSize: '14px',
                                        width: '100%',
                                        textDecoration: 'none',
                                      }}
                                      className='color-white-hover cs-text-decoration-none'>
                                      Edit
                                    </Link>
                                  </Dropdown.Item>
                                  <Dropdown.Item className=' w-100 text-start text-dark  rounded-1 bg-black-hover p-0 ps-3 d-flex  align-items-center gap-1 '>
                                    <AiFillDelete
                                      style={{
                                        color: 'white',
                                        backgroundColor: 'black',
                                      }}></AiFillDelete>
                                    <div
                                      onClick={() => {
                                        handleDelete(
                                          singleAdjustment.adjustment_id
                                        );
                                      }}
                                      style={{
                                        fontSize: '14px',
                                        width: '100%',
                                      }}
                                      className='color-white-hover cs-text-decoration-none'>
                                      Delete
                                    </div>
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        ))}
                    </>
                  )}
                </tbody>
              </Table>
            </div>

            <div>
              <nav className=' d-flex justify-content-end align-items-center px-2 col-12 text-black-50 fs-6 bg-white border-top-1'>
                <span className=' mx-2 my-0 flex-shrink-1 user-select-none'>
                  Rows per page:
                </span>
                <div className=' position-relative flex-shrink-0 mt-1 d-flex '>
                  <select
                    aria-label='Rows per page:'
                    className='sc-bXCLTC eVrcSZ cs-cursor-pointer user-select-none ps-2 pe-4  '
                    style={{
                      height: '25px',
                      maxWidth: '100%',
                      color: '#000000',
                      fontSize: 'inherit',
                      border: 'none',
                      background: 'transparent',
                      appearance: 'none',
                      direction: 'ltr',
                    }}>
                    <option
                      value={10}
                      selected>
                      10
                    </option>
                    <option value={15}>15</option>
                    <option value={20}>20</option>
                    <option value={25}>25</option>
                    <option value={30}>30</option>
                  </select>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    className='position-absolute top-0'
                    style={{ right: 0 }}>
                    <path d='M7 10l5 5 5-5z' />
                    <path
                      d='M0 0h24v24H0z'
                      fill='none'
                    />
                  </svg>
                </div>
                <span className='mx-4 user-select-none flex-shrink-1'>
                  1-2 of 2
                </span>
                <div className='sc-bmzYkS fimDOL d-flex align-items-center rounded-1 white-space-none'>
                  <button
                    id='pagination-first-page'
                    type='button'
                    aria-label='First Page'
                    aria-disabled='true'
                    disabled
                    className='position-relative d-block user-select-none border-0 rounded-1 cs-cursor-pointer'
                    style={btnStyle}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width={24}
                      height={24}
                      viewBox='0 0 24 24'
                      aria-hidden='true'
                      role='presentation'>
                      <path d='M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z' />
                      <path
                        fill='none'
                        d='M24 24H0V0h24v24z'
                      />
                    </svg>
                  </button>
                  <button
                    id='pagination-previous-page'
                    type='button'
                    aria-label='Previous Page'
                    aria-disabled='true'
                    disabled
                    className='position-relative d-block user-select-none border-0 rounded-1 cs-cursor-pointer'
                    style={btnStyle}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width={24}
                      height={24}
                      viewBox='0 0 24 24'
                      aria-hidden='true'
                      role='presentation'>
                      <path d='M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z' />
                      <path
                        d='M0 0h24v24H0z'
                        fill='none'
                      />
                    </svg>
                  </button>
                  <button
                    id='pagination-next-page'
                    type='button'
                    aria-label='Next Page'
                    aria-disabled='true'
                    disabled
                    className='position-relative d-block user-select-none border-0 rounded-1 cs-cursor-pointer'
                    style={btnStyle}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width={24}
                      height={24}
                      viewBox='0 0 24 24'
                      aria-hidden='true'
                      role='presentation'>
                      <path d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z' />
                      <path
                        d='M0 0h24v24H0z'
                        fill='none'
                      />
                    </svg>
                  </button>
                  <button
                    id='pagination-last-page'
                    type='button'
                    aria-label='Last Page'
                    aria-disabled='true'
                    disabled
                    className='position-relative d-block user-select-none border-0 rounded-1 cs-cursor-pointer'
                    style={btnStyle}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width={24}
                      height={24}
                      viewBox='0 0 24 24'
                      aria-hidden='true'
                      role='presentation'>
                      <path d='M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z' />
                      <path
                        fill='none'
                        d='M0 0h24v24H0V0z'
                      />
                    </svg>
                  </button>
                </div>
              </nav>
            </div>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default AdjustmentList;
