import moment from "moment";
import React, { forwardRef, useEffect, useState } from "react";
import useNumberFormatter from "../../../hooks/useNumberFormatter";
import amrShop from "../../../assets/images/dashboard/amr-shop.jpg";

const PosMultiplePaymentSlip = forwardRef((props, ref) => {
  const { salesSlipData } = props;
  const numericFormatter = useNumberFormatter();

  const {
    init_code,
    sales_code,
    customer_name,
    warehouse_name,
    warehouse_address,
    mobile,
    sales_items,
    sales_payments,
    discount_to_all_input,
    paid_amount,
    grand_total,
    phone,
    emp_id,
  } = salesSlipData || {};

  // time and date
  const now = moment();
  const formattedDate = now.format("DD-MM-YYYY");
  const formattedTime = now.format("hh:mm A");
  const grandTotalAmount = sales_items?.reduce(
    (acc, item) => acc + item?.item_total_cost,
    0
  );

  return (
    <>
      <div className="container px-2 bg-white" ref={ref}>
        <div className="text-center">
          <img src={amrShop} alt="logo" height="100" width="100" />
          <h3 className="fs-4 text-dark fw-700 m-0">{warehouse_name}</h3>
          <p className="text-black m-0">{warehouse_address}</p>
          <p className="text-black m-0">Phone: {mobile}</p>
          {/* Render other store details here */}
        </div>
        <div
          className="d-flex justify-content-between align-items-start py-2 mt-2"
          style={{
            borderBottom: "2px dashed black",
            borderTop: "2px dashed black",
          }}
        >
          <div className=" d-flex flex-column">
            <p className="p-0 m-0 text-dark fw-bold">Invoice:</p>
            <p className="p-0 m-0 text-dark fw-bold">Name:</p>
            {phone && <p className="p-0 m-0 text-dark fw-bold">Phone:</p>}
            {emp_id && (
              <p className="p-0 m-0 text-dark fw-bold">Customer Id:</p>
            )}
            <p className="p-0 m-0 text-dark fw-bold">Date:</p>
            <p className="p-0 m-0 text-dark fw-bold">Time:</p>
          </div>
          <div className="d-flex flex-column ">
            <p className="p-0 m-0 text-dark  fw-bold">
              #{init_code}
              {sales_code}
            </p>
            <p className="p-0 m-0 text-dark fw-bold">
              {customer_name || "Customer Name"}
            </p>
            {phone && (
              <p className="p-0 m-0 text-dark fw-bold">
                {phone || "Customer Phone"}
              </p>
            )}
            {emp_id && (
              <p className="p-0 m-0 text-dark fw-bold">
                {emp_id || "Customer Id"}
              </p>
            )}
            <p className="p-0 m-0 text-dark fw-bold">{formattedDate}</p>
            <p className="p-0 m-0 text-dark fw-bold">{formattedTime}</p>
          </div>
        </div>
        <table className="text-center table-bordered w-100 mt-3 mb-2">
          <thead>
            <tr>
              <th>#</th>
              <th>Description</th>
              <th>Quantity</th>
              <th>Rate</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {sales_items?.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item?.item_name}</td>
                <td>{numericFormatter(item?.sales_qty || 0)}</td>
                <td>{numericFormatter(item?.price_per_unit || 0)}</td>
                <td>{numericFormatter(item?.item_total_cost || 0)}</td>
              </tr>
            ))}
          </tbody>
          <tfoot className="text-end fw-bold">
            <tr className="">
              <td colSpan="4" className="pe-1">
                SubTotal:
              </td>
              <td className="text-center">
                {numericFormatter(grandTotalAmount || 0)}
              </td>
            </tr>
            <tr className="">
              <td colSpan="4" className="pe-1">
                Discount Amount:
              </td>
              <td className="text-center">
                {numericFormatter(discount_to_all_input || 0)}{" "}
                {(discount_to_all_input === "Percentage(%)" && "%") ||
                  (discount_to_all_input === "Fixed(৳)" && "৳")}
              </td>
            </tr>
            <tr className="">
              <td colSpan="4" className="pe-1">
                Grand Total:
              </td>
              <td className="text-center">
                {numericFormatter(grand_total || 0)}
              </td>
            </tr>
            <tr className="">
              <td colSpan="4" className="pe-1">
                Paid Amount:
              </td>
              <td className="text-center">
                {numericFormatter(paid_amount || 0)}
              </td>
            </tr>
            {parseFloat(paid_amount) < parseFloat(grand_total) && (
              <tr className="">
                <td colSpan="4" className="pe-1">
                  Due Amount:
                </td>
                <td className="text-center">
                  {numericFormatter(
                    parseFloat(grand_total || 0) - parseFloat(paid_amount || 0)
                  )}
                </td>
              </tr>
            )}
            {parseFloat(paid_amount) > parseFloat(grand_total) && (
              <tr className="">
                <td colSpan="4" className="pe-1">
                  Change Return Amount:
                </td>
                <td className="text-center">
                  {numericFormatter(
                    parseFloat(paid_amount || 0) - parseFloat(grand_total || 0)
                  )}
                </td>
              </tr>
            )}
            {/* Render other totals and payment details here */}
          </tfoot>
        </table>
        <div className="text-left">
          <p className="m-0 text-dark">
            <b>Note:</b> This is a sample invoice note.
          </p>
        </div>
        <div className="mt-2">
          <table className="text-center table-bordered w-100">
            <thead>
              <tr>
                <th>#</th>
                <th>Payment Type</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {sales_payments?.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item?.payment_type}</td>
                  <td>{item?.payment}</td>
                </tr>
              ))}
            </tbody>
            <tfoot className="text-end fw-bold">
              <tr className="">
                <td colSpan="2" className="pe-1">
                  Total:
                </td>
                <td className="text-center">
                  {numericFormatter(paid_amount || 0)}
                </td>
              </tr>
              {/* Render other totals and payment details here */}
            </tfoot>
          </table>
        </div>
        <div className="text-center mt-1">
          <p className="text-dark">Thank You For Taking Service From Us</p>
        </div>
      </div>
    </>
  );
});
export default PosMultiplePaymentSlip;
