import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../util/axiosInstance';

// fetch

export const fetchProductAction = createAsyncThunk(
  'products/fetch',
  async (products, { rejectWithValue, getState, dispatch }) => {
    //get user token
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    //http call
    try {
      const { data } = await axios.get(`/item/viewitems`, config);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

const productsSlice = createSlice({
  name: 'products',
  initialState: {
    productList: [],
  },
  extraReducers: (builder) => {
    builder
      // fetch
      .addCase(fetchProductAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchProductAction.fulfilled, (state, action) => {
        state.productList = action?.payload;
        state.loading = false;
        state.appErr = undefined;
        state.serverErr = undefined;
      })
      .addCase(fetchProductAction.rejected, (state, action) => {
        state.loading = false;
        state.appErr = action?.payload?.message;
        state.serverErr = action?.error?.message;
      });
  },
});

export default productsSlice.reducer;
