import React from 'react'

import TransferAccountDropdownSectionForCredit from './TransferAccountDropdownSectionForCredit'
import TransferAccountDropdownSectionForDebit from './TransferAccountDropdownSectionForDebit'

const TransferAccounts = ({ setDebitAcc, setCreditAcc, DebitAcc, creditAcc, }) => {
    return (
        <div className="d-flex col-7" >
            <div className="col-5 me-3 zn-1">

                <TransferAccountDropdownSectionForDebit
                    setQueryData={setDebitAcc}

                    borderEnd={"brand"}
                    inputType={"search"}
                    // setInputValue={setWareHouseSearch}
                    InitialDropdownValue={'select account'}

                    titleData={"Select debit account"}
                    placeHolderData={"Select debit account"}
                    dataField={"brand_name"}
                    apiLink='/accounts/viewaccounts'
                />
            </div>


            <div className="col-5 ms-3 ">
                <TransferAccountDropdownSectionForCredit
                    setQueryData={setCreditAcc}

                    borderEnd={"category"}
                    inputType={"search"}
                    // setInputValue={setWareHouseSearch}
                    InitialDropdownValue={'select account credit'}


                    titleData={"category"}

                    placeHolderData={"Enter category Name"}
                    dataField={"category_name"}
                    apiLink='/accounts/viewaccounts'
                />


            </div>

        </div>

    )
}

export default TransferAccounts