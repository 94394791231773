import React from 'react'
import StockReportDropdownSection from './StockReportDropdownSection'
import StockReportDropdownSectionForBrand from './StockReportDropdownSectionForBrand'
import StockReportDropdownSectionForCategory from './StockReportDropdownSectionForCategory'
import StockReportDropdownSectionForLots from './StockReportDropdownSectionForLots'

const StockDropscomponents = ({ setQueryData, queryData, isLotVisible }) => {
  return (
    <div className="d-flex" >
      <div className="col-5 me-3">
        <StockReportDropdownSection
          setQueryData={setQueryData}
          queryData={queryData}

          borderEnd={"warehouse"}
          inputType={"search"}
          // setInputValue={setWareHouseSearch}
          InitialDropdownValue={'select warehouse'}

          titleData={"Warehouse"}
          placeHolderData={"Enter Warehouse Name"}
          dataField={"warehouse_name"}
          apiLink='/branch/viewbranch'

        />
        <StockReportDropdownSectionForBrand
          setQueryData={setQueryData}
          queryData={queryData}
          borderEnd={"brand"}
          inputType={"search"}
          // setInputValue={setWareHouseSearch}
          InitialDropdownValue={'select Brand'}

          titleData={"brand"}
          placeHolderData={"Enter Brand Name"}
          dataField={"brand_name"}
          apiLink='/brand/viewbrands'
        />
      </div>


      <div className="col-5 ms-3 ">

        <StockReportDropdownSectionForCategory
          setQueryData={setQueryData}
          queryData={queryData}
          borderEnd={"category"}
          inputType={"search"}
          // setInputValue={setWareHouseSearch}
          InitialDropdownValue={'select category'}

          titleData={"category"}
          placeHolderData={"Enter category Name"}
          dataField={"category_name"}
          apiLink='/category/viewcategories'
        />
        {
          isLotVisible && <StockReportDropdownSectionForLots
            setQueryData={setQueryData}
            queryData={queryData}
            borderEnd={"lots"}
            inputType={"search"}
            // setInputValue={setWareHouseSearch}
            InitialDropdownValue={'select lots'}

            titleData={"lots"}
            placeHolderData={"Enter lots Name"}
            dataField={"lots_name"}
            apiLink='/lot/viewlots'


          />
        }

      </div>

    </div>

  )
}

export default StockDropscomponents