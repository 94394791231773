import React, { useEffect, useState } from 'react'
import SalesPaymentTable from './components/SalesPaymentTable'
import Breadcrumb from '../../../components/common/breadcrumb'
import DateRangePickerComponent from './components/DateRangePicker'
import CustomerNameSelectDropdown from './components/CustomerNameSelectDropdown'
import { addDays } from 'date-fns';

import { Button } from 'reactstrap'
import moment from 'moment'
import DeliveryListTable from './../../DeliveryManagement/Pathao/DeliveriesList/DeliveryListTable';
import useGetData from '../../../hooks/useGetData'
import PosDropdownSection from '../../SalesManagement/Pos/PosDropdownSection'

const SalesPaymentScreen = () => {

  const [paymentTypeData, refetchPayment, isLoadingPayment] = useGetData(
    "/paymenttype/viewpaymenttypes/",
    "paymenttypeData"
  );

  console.log({paymentTypeData})


  const [updatedPaymentTypeData, setUpdatedPaymentTypeData] = useState([]);

  // Define the custom customer data
  const customPaymentTypeData = {
    id: null,
    payment_type: "All Payment Type",
    status: null,
  };


  const [paymentTypeDropdownValue, setPaymentTypeTwoDropdownValue] = useState(
    "All Payment Type"
  );


  useEffect(() => {
    if (paymentTypeData) {
      setUpdatedPaymentTypeData([customPaymentTypeData, ...paymentTypeData]);
    }
  }, [paymentTypeData]);

  const paymentStatusData = [
    {
      id: 1,
      name: "All Payment Status",
    },
    {
      id: 2,
      name: "Paid",
    },
    {
      id: 3,
      name: "Partially Paid",
    },
    {
      id: 4,
      name: "Due",
    },
  ];
  const [paymentStatusDropdownValue, setPaymentStatusDropdownValue] =
    useState("All Payment Status");
  

  const [queryData, setQueryData] = useState({
    user_name: null,
    brand_name: '',
    category_name: '',
    lot_number: ""
  })
  const [stateM, setStateM] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: 'selection',
    },
  ]);

  const [ApiLink, setApiLink] = useState('')
  const startDate = moment(stateM[0].startDate).format().substring(0, 10)
  const endDate = moment(stateM[0].endDate).format().substring(0, 10)

  useEffect(() => {
      setApiLink(`/report/sales-payments-reports?startDate=${startDate}&endDate=${endDate}&paymentStatus=${paymentStatusDropdownValue}&paymentType=${paymentTypeDropdownValue}`)
      // setApiLink(`/report/sales-payments-reports?startDate=2023-01-01&endDate=2023-12-31&paymentStatus=due&paymentType=cash`)
  }, [endDate, startDate,paymentStatusDropdownValue,paymentTypeDropdownValue])

  console.log({ApiLink});
  return (
    <>
      <Breadcrumb
        title='Sales Payments'
        parent='reports Payments'
      />

      <div className='row pb-4'>
        <div className='col-md-6'>
          <div style={{ }}>
            <h5 className="text-start ">Payment Type</h5>
            <div
              style={{ width: "100%" }}
              className=" d-flex justify-content-between align-items-center border border rounded-1 "
              title="Warehouse"
            >
              {/* dropdown data */}
              {paymentTypeData && (
                <PosDropdownSection
                  inputType={"search"}
                  InitialDropdownValue={"All Payment Type"}
                  data={updatedPaymentTypeData || []}
                  titleData={"Payment Type"}
                  placeHolderData={"Enter Payment Type"}
                  setPaymentTypeTwoDropdownValue={
                    setPaymentTypeTwoDropdownValue
                  }
                ></PosDropdownSection>
              )}
            </div>
          </div>
        </div>

        <div className='col-md-6'>
          <div style={{}}>
            <h5 className="text-start ">Payment Status</h5>
            <div
              style={{ width: "100%" }}
              className="  d-flex justify-content-between align-items-center border rounded-1"
              title="Customer"
            >
              {paymentTypeData && paymentStatusData && (
                <PosDropdownSection
                  inputType={"search"}
                  InitialDropdownValue={"All Payment Status"}
                  data={paymentStatusData || []}
                  titleData={"Select Status"}
                  placeHolderData={"Enter Payment Status"}
                  setPaymentStatusDropdownValue={
                    setPaymentStatusDropdownValue
                  }
                ></PosDropdownSection>
              )}
            </div>
          </div>
        </div>

      </div>

      <div className='col-12 '>
        {/* <h3 className='pb-2 text-black'>Please Enter Valid Information</h3> */}
        <div className='d-flex'>
          {/* <CustomerNameSelectDropdown
              apiLink='/customer/viewcustomers'
              queryData={queryData}
              setQueryData={setQueryData}
              InitialDropdownValue='select customer'
            /> */}
          <DateRangePickerComponent
            setStateM={setStateM}
            stateM={stateM}
            queryData={queryData}
            setQueryData={setQueryData}
          />
        </div>
      </div>

      <div className=''>
        {/* <PayamentList /> */}
        <SalesPaymentTable queryLink={ApiLink} />
      </div>
    </>
  );
}

export default SalesPaymentScreen