import React from 'react';
import { Card, CardBody, CardHeader, Col, Media, Row } from 'reactstrap';
import { Chart } from "react-google-charts";
import { Briefcase, Calendar, CreditCard, ShoppingCart } from "react-feather";
import AccountingCardComponent from './AccountingCardComponent/AccountingCardComponent';
import useGetData from '../../../hooks/useGetData';

const AccountingCard = () => {

	const AccountingCards = [
		{
			"id": 17,
			"title": 'Gross Profit',
			"account_name": "EBL Bank Limited",
			"account_code": "code-gs1254",
			"balance": 20,
			"note": "ha ha ha",
			"created_by": null,
			"created_date": "2023-09-16T04:00:00.000Z",
			"created_time": "06:34:11 PM"
		},
	]

	const LineOptions = {
		hAxis: {
			textPosition: "none",
			baselineColor: "transparent",
			gridlineColor: "transparent",
		},
		vAxis: {
			textPosition: "none",
			baselineColor: "transparent",
			gridlineColor: "transparent",
		},
		colors: ["#ff8084"],
		legend: "none",
	};

	const LineOptions1 = {
		hAxis: {
			textPosition: "none",
			baselineColor: "transparent",
			gridlineColor: "transparent",
		},
		vAxis: {
			textPosition: "none",
			baselineColor: "transparent",
			gridlineColor: "transparent",
		},
		colors: ["#13c9ca"],
		chartArea: { left: 0, top: 0, width: "100%", height: "100%" },
		legend: "none",
	};

	const LineOptions2 = {
		hAxis: {
			textPosition: "none",
			baselineColor: "transparent",
			gridlineColor: "transparent",
		},
		vAxis: {
			textPosition: "none",
			baselineColor: "transparent",
			gridlineColor: "transparent",
		},
		colors: ["#f5ce8a"],
		chartArea: { left: 0, top: 0, width: "100%", height: "100%" },
		legend: "none",
	};
	const LineOptions3 = {
		hAxis: {
			textPosition: "none",
			baselineColor: "transparent",
			gridlineColor: "transparent",
		},
		vAxis: {
			textPosition: "none",
			baselineColor: "transparent",
			gridlineColor: "transparent",
		},
		colors: ["#a5a5a5"],
		chartArea: { left: 0, top: 0, width: "100%", height: "100%" },
		legend: "none",
	};

	const [todaySaleData, todaySaleDataRefetch] = useGetData('/dashboard/sales-summary', 'todaySaleData');
	const [weeklySaleData, weeklySaleDataRefetch] = useGetData('/dashboard/sales-summary-weekly', 'weeklySaleData');
	const [monthlySaleData, monthlySaleDataRefetch] = useGetData('/dashboard/sales-summary-monthly', 'monthlySaleData');

	const [netProfitData, netProfitDataRefetch] = useGetData('/dashboard/profit-summary', 'netProfitData');

	
	function formatNumber(num) {
		if (num >= 1000000000000) {
			return (num / 1000000000000).toFixed(1).replace('.0', '') + 'T';
		} else if (num >= 1000000000) {
			return (num / 1000000000).toFixed(1).replace('.0', '') + 'Cr';
		} else if (num >= 1000000) {
			return (num / 1000000).toFixed(1).replace('.0', '') + 'L';
		} else if (num >= 1000) {
			return (num / 1000).toFixed(1).replace('.0', '') + 'K';
		} else {
			return num.toString();
		}
	}
	const weeklyTotalAmount = MonthlyFormatNumber(parseFloat(weeklySaleData?.current_week_total|| 0));

	function MonthlyFormatNumber(num) {
		if (num >= 1000000000000) {
			return (num / 1000000000000).toFixed(1).replace('.0', '') + 'T';
		} else if (num >= 1000000000) {
			return (num / 1000000000).toFixed(1).replace('.0', '') + 'Cr';
		} else if (num >= 1000000) {
			return (num / 1000000).toFixed(1).replace('.0', '') + 'L';
		} else if (num >= 1000) {
			return (num / 1000).toFixed(1).replace('.0', '') + 'K';
		} else {
			return num.toString();
		}
	}

	const monthlyTotalAmount = MonthlyFormatNumber(parseFloat(monthlySaleData?.current_month_total|| 0));
	
	function netFormatNumber(num) {
		if (num >= 1000000000000) {
			return (num / 1000000000000).toFixed(1).replace('.0', '') + 'T';
		} else if (num >= 1000000000) {
			return (num / 1000000000).toFixed(1).replace('.0', '') + 'Cr';
		} else if (num >= 1000000) {
			return (num / 1000000).toFixed(1).replace('.0', '') + 'L';
		} else if (num >= 1000) {
			return (num / 1000).toFixed(1).replace('.0', '') + 'K';
		} else {
			return num.toString();
		}
	}

	const netTotalAmount = netFormatNumber(parseFloat(netProfitData?.current_month_profit|| 0));



	return (
		<>
			<AccountingCardComponent todaySaleData={todaySaleData}/>

			<Col xl="3 xl-50" md="6">
				<Card className=" order-graph sales-carousel">
					<CardHeader>
						<h6>This Weekly Sales</h6>
						<Row>
							<Col className="col-6">
								<div className="small-chartjs">
									<div
										className="flot-chart-placeholder"
										id="simple-line-chart-sparkline"
									>
										<Chart
											height={"60px"}
											chartType="LineChart"
											loader={<div>Loading Chart</div>}
											data={[
												["x", "time"],
												[0, 85],
												[1, 83],
												[2, 90],
												[3, 70],
												[4, 85],
												[5, 60],
												[6, 65],
												[7, 63],
												[8, 68],
												[9, 68],
												[10, 65],
												[11, 40],
												[12, 60],
												[13, 75],
												[14, 70],
												[15, 90],
											]}
											options={LineOptions1}
											legend_toggle
										/>
									</div>
								</div>
							</Col>
							<Col className="col-6">
								<div className="value-graph">
									<h3>
									{weeklySaleData?.growth_rate_percentage?.toFixed(2) || 0}%
									<span>
									{
												weeklySaleData?.growth_rate_percentage < 0 ? <i className="fa fa-angle-down font-primary"></i> : <i className="fa fa-angle-up font-primary"></i>
											}
									</span>
									</h3>
								</div>
							</Col>
						</Row>
					</CardHeader>
					<CardBody>
						<Media>
							<Media body>
								<span>Weekly Sales </span>
								<h2 className="mb-0">{weeklyTotalAmount || 0}</h2>
								<p>
									{weeklySaleData?.growth_rate_percentage?.toFixed(2) || 0}%
									<span>
									{
												weeklySaleData?.growth_rate_percentage < 0 ? <i className="fa fa-angle-down font-primary"></i> : <i className="fa fa-angle-up font-primary"></i>
											}
									</span>
								</p>
								<h5 className="f-w-600 f-16"> Weekly Total Sales</h5>
								<p>
								Weekly Current Sales Data
								</p>
							</Media>
							
						</Media>
					</CardBody>
				</Card>
			</Col>

			<Col xl="3 xl-50" md="6">
				<Card className="order-graph sales-carousel">
					<CardHeader>
						<h6>This Month Sales</h6>
						<Row>
							<Col className="col-6">
								<div className="small-chartjs">
									<div
										className="flot-chart-placeholder"
										id="simple-line-chart-sparkline-2"
									>
										<Chart
											height={"60px"}
											chartType="LineChart"
											loader={<div>Loading Chart</div>}
											data={[
												["x", "time"],
												[0, 85],
												[1, 83],
												[2, 90],
												[3, 70],
												[4, 85],
												[5, 60],
												[6, 65],
												[7, 63],
												[8, 68],
												[9, 68],
												[10, 65],
												[11, 40],
												[12, 60],
												[13, 75],
												[14, 70],
												[15, 90],
											]}
											options={LineOptions2}
											legend_toggle
										/>
									</div>
								</div>
							</Col>
							<Col className="col-6">
								<div className="value-graph">
									<h3 >
									{monthlySaleData?.growth_rate_percentage?.toFixed(2) || 0 }%
									<span>
									{
												monthlySaleData?.growth_rate_percentage < 0 ? <i className="fa fa-angle-down font-primary"></i> : <i className="fa fa-angle-up font-primary"></i>
											}
									</span>
									</h3>
								</div>
							</Col>
						</Row>
					</CardHeader>
					<CardBody>
						<Media>
					
							<Media body>
								<span> Month Sales</span>
								<h2 className="mb-0" style={{textTransform:'capitalize'}} >{monthlyTotalAmount || 0}</h2>
								<p >
									{monthlySaleData?.growth_rate_percentage?.toFixed(2) || 0 }%
									<span>
									{
												monthlySaleData?.growth_rate_percentage < 0 ? <i className="fa fa-angle-down font-primary"></i> : <i className="fa fa-angle-up font-primary"></i>
											}
									</span>
								</p>
								<h5 className="f-w-600 f-16">Monthly Total Sales</h5>
								<p>
								Monthly Current Sales Data
								</p>
							</Media>
							
						</Media>
					</CardBody>
				</Card>
			</Col>

			<Col xl="3 xl-50" md="6">
				<Card className="order-graph sales-carousel">
					<CardHeader>
						<h6>Net Profit</h6>
						<Row>
							<Col className="col-6">
								<div className="small-chartjs">
									<div
										className="flot-chart-placeholder"
										id="simple-line-chart-sparkline-1"
									>
										<Chart
											height={"60px"}
											chartType="LineChart"
											loader={<div>Loading Chart</div>}
											data={[
												["x", "time"],
												[0, 85],
												[1, 83],
												[2, 90],
												[3, 70],
												[4, 85],
												[5, 60],
												[6, 65],
												[7, 63],
												[8, 68],
												[9, 68],
												[10, 65],
												[11, 40],
												[12, 60],
												[13, 75],
												[14, 70],
												[15, 90],
											]}
											options={LineOptions3}
											legend_toggle
										/>
									</div>
								</div>
							</Col>
							<Col className="col-6">
								<div className="value-graph">
									<h3>
									{netProfitData?.growth_rate_percentage?.toFixed(2) || 0}
									<span>
									{
												netProfitData?.growth_rate_percentage < 0 ? <i className="fa fa-angle-down font-primary"></i> : <i className="fa fa-angle-up font-primary"></i>
											}
									</span>
									</h3>
								</div>
							</Col>
						</Row>
					</CardHeader>
					<CardBody>
						<Media>
							<Media body>
								<span>Net Profit</span>
								<h2 className="mb-0">{netTotalAmount || 0}</h2>
								<p>
								{netProfitData?.growth_rate_percentage?.toFixed(2) || 0}
								<span>
									{
												netProfitData?.growth_rate_percentage < 0 ? <i className="fa fa-angle-down font-primary"></i> : <i className="fa fa-angle-up font-primary"></i>
											}
									</span>
								</p>
								<h5 className="f-w-600 f-16">Net Profit of This Month</h5>
								<p>
								Monthly Net Profit Data
								</p>
							</Media>
							
						</Media>
					</CardBody>
				</Card>
			</Col>
		</>
	);
};

export default AccountingCard;