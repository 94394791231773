import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../util/axiosInstance';

// fetch

export const fetchSalesAction = createAsyncThunk(
  'sales/fetch',
  async (sales, { rejectWithValue, getState, dispatch }) => {
    //get user token
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    //http call
    try {
      const { data } = await axios.get(`/sales/sales_list`, config);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);
const initialState = {
  salesList: [],
};

const salesSlice = createSlice({
  name: 'sales',
  initialState,
  extraReducers: (builder) => {
    builder
      // fetch
      .addCase(fetchSalesAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchSalesAction.fulfilled, (state, action) => {
        state.salesList = action?.payload;
        state.loading = false;
        state.appErr = undefined;
        state.serverErr = undefined;
      })
      .addCase(fetchSalesAction.rejected, (state, action) => {
        state.loading = false;
        state.appErr = action?.payload?.message;
        state.serverErr = action?.error?.message;
      });
  },
});

export default salesSlice.reducer;
