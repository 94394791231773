import { Navigate, useLocation } from 'react-router-dom';

import { useSelector } from 'react-redux';

const PermissionRoute = ({ children, permissions }) => {
  const userPermissions = useSelector(state => state.user.userAuth?.permissions )

  const location = useLocation();

  if (userPermissions.some(permission => permissions.includes(permission) )) {
    return children;
  }
  return (
    <Navigate
      to='/dashboard'
      state={{ from: location }}
      replace></Navigate>
  );
};

export default PermissionRoute;
