import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Table } from "reactstrap";
import TableLoading from "../../common/loading/TableLoading";
import axios from "../../../util/axiosInstance";
import moment from "moment";

const RecentSales = () => {
  const [recentSales, setRecentSales] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  // Top Selling Product
  useEffect(() => {
    // Define the async function
    const fetchData = async () => {
      try {
        const response = await axios.get("/dashboard/last-10-sales/");
        setRecentSales(response.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
    // Invoke the async function
    fetchData();
  }, []);
  return (
    <>
      <Col xl='12 xl-100'>
        <Card className='height-equal'>
          <CardHeader>
            <h5>Recent Sales</h5>
          </CardHeader>
          <CardBody>
            <div className='user-status table-responsive products-table'>
              <Table
                bordered
                hover
                striped
                className='table table-bordernone mb-0'>
                <thead className='text-center'>
                  <tr>
                    <th scope='col'>DATE & TIME </th>

                    <th scope='col'>INVOICE NO</th>

                    <th scope='col'>CUSTOMER NAME</th>
                    <th scope='col'>PAYMENT STATUS</th>
                    <th scope='col'> TOTAL AMOUNT</th>
                    <th scope='col'>PAID AMOUNT</th>
                    <th scope='col'>DUE</th>
                  </tr>
                </thead>
                <tbody className='text-center'>
                  {loading && (
                    <>
                      {/* <LoadingBar color="red" style={{ marginTop: '-15px' }}
                                                progress={progress}
                                                onLoaderFinished={() => setProgress(0)}
                                            /> */}

                      <TableLoading
                        noTd={7}
                        noRow={6}
                      />
                    </>
                  )}

                  {!loading &&
                    recentSales
                      ?.slice(0, 5)
                      ?.sort((a, b) => b?.id - a?.id)
                      ?.map((sales, index) => (
                        <tr key={sales.id}>
                          <td
                            style={{ borderRadius: '5px', paddingLeft: '5px' }}>
                            {moment(sales?.sales_date).format('LLLL')}
                          </td>

                          <td
                            style={{ borderRadius: '5px', paddingLeft: '5px' }}>
                            {sales?.sales_code}
                          </td>

                          <td
                            style={{ borderRadius: '5px', paddingLeft: '5px' }}>
                            {sales?.customer_name}
                          </td>
                          {(sales?.payment_status === 'Paid' && (
                            <td
                              style={{
                                color: 'green',
                                borderRadius: '5px',
                                paddingLeft: '5px',
                              }}>
                              {sales?.payment_status}
                            </td>
                          )) ||
                            (sales?.payment_status === 'Partially Paid' && (
                              <td
                                style={{
                                  color: '#54A0FF',
                                  borderRadius: '5px',
                                  paddingLeft: '5px',
                                }}>
                                {sales?.payment_status}
                              </td>
                            )) ||
                            (sales?.payment_status === 'Due' && (
                              <td
                                style={{
                                  color: '#FF3B6A',
                                  borderRadius: '5px',
                                  paddingLeft: '5px',
                                }}>
                                {sales?.payment_status}
                              </td>
                            ))}
                          <td title='apogee pos'>{sales?.grand_total || 0}</td>
                          <td title='apogee pos'>{sales?.paid_amount || 0}</td>

                          <td title='apogee pos'>
                            {sales?.grand_total - sales?.paid_amount}
                          </td>
                        </tr>
                      ))}

                  {/* <tr>
          <td>SA_11122064</td>
          <td className="digits">RAKIBsss MOLLA</td>
          <td className="font-primary">RECEIVED </td>
          <td className="digits">$6523</td>
          <td className="digits">$523</td>
          <td className="digits">$6523</td>
          <td className="font-secondary">Paid </td>
         </tr> */}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default RecentSales;
