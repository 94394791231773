import React from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import PurchaseListInformationTableSection from "./PurchaseListInformationTableSection";
import PurchaseAmountSection from "./PurchaseAmountSection";
import useGetData from "../../../hooks/useGetData";
import { useEffect } from "react";

const PurchaseList = () => {
  
  // purchase table data
  const refetchInterval = 10000;
  const [purchaseData, refetchParches, isLoadingParches] = useGetData(
    "/purchase/view-purchase-records/",
    "purchaseData"
  );
  useEffect(() => {
    const refetchData = () => {
      refetchParches();
    };
    const intervalId = setInterval(refetchData, refetchInterval);
    return () => {
      clearInterval(intervalId);
    };
  }, [refetchParches]);
  return (
    <>
      <Breadcrumb title="Purchase List" parent="Purchase Management" />
      <main className=" w-100 px-2">
        <section>
          <PurchaseAmountSection
            purchaseData={purchaseData}
            refetchParches={refetchParches}
            isLoadingParches={isLoadingParches}
          ></PurchaseAmountSection>
        </section>
        <section
          className="bg-white rounded-2 mt-5 pb-5"
          style={{ boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)" }}
        >
          <PurchaseListInformationTableSection
            purchaseData={purchaseData}
            refetchParches={refetchParches}
            isLoadingParches={isLoadingParches}
          ></PurchaseListInformationTableSection>
        </section>
      </main>
    </>
  );
};

export default PurchaseList;
