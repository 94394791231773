import React, { useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";
import PosTotalDiscountModal from "../Modal/PosTotalDiscountModal";
import { CustomToast } from "../../../hooks/useCustomToast";

const PosButton = ({
  modalFunction,
  text1,
  text1Value,
  totalQuantity,
  text2,
  styleHover,
  bgStyle,
  icon,
  totalSubTotal,
  setDiscount,
  setDiscountToAllType,
  discountInputValue,
  setDiscountInputValue,
}) => {
  const [isAction, setIsAction] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  // const [discountInputValue, setDiscountInputValue] = useState("");
  const [discountDropdownValue, setDiscountDropdownValue] =
    useState("Percentage(%)");
  const [increaseDiscount, setIncreaseDiscount] = useState(false);
  // discount modal functionality
  const discountModalFunction = () => {
    if (totalSubTotal && totalSubTotal > 0) {
      setModalOpen(!modalOpen);
      setIncreaseDiscount(false);
    } else {
      CustomToast("error", "Total Amount Must be greater than 0");
    }
  };
  useEffect(() => {
    if (text1 === "Total Discount") {
      setIsAction(true);
    }
  }, [isAction, text1]);

  // handle discount calculation
  const handleSubmitDiscount = (e) => {
    e.preventDefault();
    handleDiscount();
    setModalOpen(!modalOpen);
    setIncreaseDiscount(true);
  };

  useEffect(() => {
    handleDiscount();
  }, [totalSubTotal, increaseDiscount]);

  // handle discount calculation
  const handleDiscount = () => {
    let discount;
    if (discountInputValue !== "") {
      if (discountDropdownValue === "Percentage(%)") {
        discount =
          (parseFloat(totalSubTotal && totalSubTotal) *
            parseFloat(discountInputValue)) /
          100;
      } else if (discountDropdownValue === "Fixed(৳)") {
        const fixedValue =
          parseFloat(totalSubTotal && totalSubTotal) -
          parseFloat(discountInputValue);
        discount = totalSubTotal && totalSubTotal - fixedValue;
      }
    }
    setDiscount && setDiscount(discount);
  };

  return (
    <div
      className=' rounded-2 col-12 col-sm-6 col-lg-3 my-3 mt-lg-0 '
      style={{ height: '120px' }}>
      <div className='m-2 bg-body-secondary py-3 text-center '>
        <div
          style={{ fontSize: '1.2rem' }}
          className=' text-black fw-bold mb-3'>
          {text1}:
          {isAction && (
            <FaEdit
              onClick={discountModalFunction}
              className='text-primary'
              style={{ cursor: 'pointer' }}></FaEdit>
          )}
          <h5 className='mt-1 text-black fs-4 fw-bold'>{text1Value}</h5>
        </div>
        {
          Number(totalQuantity)  ?  <div
          onClick={modalFunction}
          className={`rounded-3 mx-5 ${styleHover}`}
          style={{ backgroundColor: `${bgStyle}` }}>
          <span className='text-white py-1 d-flex justify-content-center align-items-center gap-2 fs-2'>
            {icon} {text2}
          </span>
        </div>:  <div
       
        className={`rounded-3 mx-5 not-allowed  `}
        style={{ backgroundColor: "gray" }}>
        <span className='text-white py-1 d-flex justify-content-center align-items-center gap-2 fs-2'>
          {icon} {text2}
        </span>
      </div>
        }
       
      </div>
      {/* total discount modal */}
      <PosTotalDiscountModal
        isOpen={modalOpen}
        toggle={discountModalFunction}
        setDiscountInputValue={setDiscountInputValue}
        discountDropdownValue={discountDropdownValue}
        setDiscountDropdownValue={setDiscountDropdownValue}
        handleSubmitDiscount={handleSubmitDiscount}
        setIncreaseDiscount={setIncreaseDiscount}
        setDiscountToAllType={setDiscountToAllType}></PosTotalDiscountModal>
    </div>
  );
};

export default PosButton;
