import React from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import SalesInvoice from "./SalesInvoice";
import ViewInvoiceInformationTableSection from "./ViewInvoiceInformationTableSection";
import { useParams } from "react-router";
import useGetData from "../../../hooks/useGetData";

const ViewSales = () => {
  const { id } = useParams();
  const [salesProduct, salesProductRefetch, salesProductLoading] = useGetData(
    `/sales/view-sales/${id}`,
    "salesProduct"
  );
  return (
    <>
      <Breadcrumb title="View Sales" parent="Sales Management" />
      <main className=" w-100 p-3 bg-white rounded-2">
        <SalesInvoice data={salesProduct}></SalesInvoice>
        <ViewInvoiceInformationTableSection
          id={id}
          data={salesProduct}
          isLoading={salesProductLoading}
        ></ViewInvoiceInformationTableSection>
      </main>
    </>
  );
};

export default ViewSales;
