
import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Card, Container, } from 'reactstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import BranchInputField from './BranchInputField';
import axios from '../../util/axiosInstance';
import { CustomToast } from '../../hooks/useCustomToast';

import useGetData from '../../hooks/useGetData';

const BranchListEdit = () => {
 const { id } = useParams();

 const [branchDataFromData, branchDataRefetch] = useGetData(`/branch/viewbranch/${id}`, 'branchDataFromData');
 const [wareHouseName, setWareHouseName] = useState('');
 const [wareHouseNameInitial, setWareHouseNameInitial] = useState('');

 const [wareHouseType, setWareHouseType] = useState('');
 const [wareHouseTypeInitial, setWareHouseTypeInitial] = useState('');

 const [address, setAddress] = useState('');
 const [addressInitial, setAddressInitial] = useState('');

 const [mobile, setMobile] = useState('');
 const [mobileInitial, setMobileInitial] = useState('');

 const [email, setEmail] = useState('');
 const [emailInitial, setEmailInitial] = useState('');

 const [image, setImage] = useState(null);

 const navigate = useNavigate()


 useEffect(() => {
  branchDataRefetch();
 }, [wareHouseNameInitial, wareHouseTypeInitial, addressInitial, mobileInitial, emailInitial])


 useEffect(() => {
  branchDataRefetch()
  setWareHouseNameInitial(branchDataFromData && branchDataFromData?.warehouse_name);
  setWareHouseTypeInitial(branchDataFromData?.warehouse_type);
  setAddressInitial(branchDataFromData?.warehouse_address);
  setMobileInitial(branchDataFromData?.mobile);
  setEmailInitial(branchDataFromData?.email);

 }, [branchDataFromData, wareHouseNameInitial, wareHouseTypeInitial, addressInitial, mobileInitial, emailInitial, branchDataRefetch])


 const date = new Date(Date.UTC(2023, 8, 15, 4, 0, 0)); // Months are 0-indexed in JavaScript
 const dateString = date.toISOString();
 // This should log: "2023-09-15T04:00:00.000Z"

 const handleWarehouseSubmit = (e) => {

  e.preventDefault();
  const form = e.target;
  const updateData = {
   "warehouse_name": wareHouseName || wareHouseNameInitial,
   "warehouse_type": wareHouseType || wareHouseTypeInitial,
   "warehouse_address": address || addressInitial,
   "mobile": mobile || mobileInitial,
   "email": email || emailInitial,
   "status": '1',
   "created_date": dateString
  }

  axios.put(`/branch/editbranch/${id}`, updateData)
   .then(response => {
    CustomToast('success', 'Branch Update Successfully')
    navigate('/branch-list');
   })
   .then(error => {
    if (error) {
     CustomToast('error', 'Something Wrong')
    }
   })
 }

 return (
  <Fragment>
   <Breadcrumb title="Add Branch " parent="Branch" />
   <Container fluid={true}>
    <Card className='p-2'>
     <form onSubmit={(handleWarehouseSubmit)}>

      {branchDataFromData && <BranchInputField data={branchDataFromData} InitialValue={wareHouseNameInitial} labelText={'Warehouse Name'} setWareHouseName={setWareHouseName} type={'text'} fieldId={'warehouseName'} requiredField={true}></BranchInputField>}

      <BranchInputField InitialValue={wareHouseTypeInitial} labelText={'Warehouse Type'} type={'text'} fieldId={'warehouseType'} setWareHouseType={setWareHouseType}></BranchInputField>

      <BranchInputField InitialValue={addressInitial} labelText={'Address'} type={'text'} fieldId={'address'} setAddress={setAddress}></BranchInputField>

      <BranchInputField InitialValue={mobileInitial} labelText={'Mobile'} type={'number'} fieldId={'mobile'} setMobile={setMobile}></BranchInputField>

      <BranchInputField InitialValue={emailInitial} labelText={'Email'} type={'email'} fieldId={'email'} setEmail={setEmail}></BranchInputField>

      {/* <BranchInputField labelText={'Image'} type={'file'} fieldId={'image'} setImage={setImage}></BranchInputField> */}

      <div className="row">
       <div className=' d-flex align-items-center justify-content-center mt-5 gap-5'>
        <input type="submit" value={'Update'} style={{ backgroundColor: '#00A65A', border: 'none', color: 'white', padding: '6px 28px', borderRadius: '5px' }} />
        <Link to={'/dashboard'} style={{ backgroundColor: '#F39C12', border: 'none', color: 'white', padding: '6px 28px', borderRadius: '5px', textDecoration: 'none' }}>Close</Link>
       </div>
      </div>
     </form>
    </Card>
   </Container>
  </Fragment>
 );
};

export default BranchListEdit;


