import React, { Fragment, useEffect, useState } from "react";
import UserPanel from "./user-panel";
import { Link } from "react-router-dom";
import { MENUITEMS } from "../../../constants/menu";

// image import
import logo from "../../../assets/images/dashboard/logo.png";
import { useSelector } from "react-redux";

const Sidebar = () => {
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const [menu, setMenu] = useState([]);
  const { userAuth } = useSelector((state) => state.user);
  const { permissions } = userAuth;

  const [isChange, setIsChange] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  const clearInput = () => {
    setSearchValue("");
  };

  useEffect(() => {
    const currentUrl = window.location.pathname;
    mainmenu.map((items) => {
      mainMenu.filter((Items) => {
        if (Items.path === currentUrl) setNavActive(Items);
        if (!Items.children) return false;
        Items.children.filter((subItems) => {
          if (subItems.path === currentUrl) setNavActive(subItems);
          if (!subItems.children) return false;
          subItems.children.filter((subSubItems) => {
            if (subSubItems.path === currentUrl) {
              setNavActive(subSubItems);
              return true;
            } else {
              return false;
            }
          });
          return subItems;
        });
        return Items;
      });
      return items;
    });
    return () => {
      setMainMenu(MENUITEMS);
    };
  }, [isChange]);

  const setNavActive = (item) => {
    setIsChange(!isChange);
    MENUITEMS.filter((menuItem) => {
      if (menuItem !== item) menuItem.active = false;
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true;
      if (menuItem.children) {
        menuItem.children.filter((submenuItems) => {
          if (submenuItems !== item) {
            submenuItems.active = false;
          }
          if (submenuItems.children) {
            submenuItems.children.map(
              (childItem) => (childItem.active = false)
            );
            if (submenuItems.children.includes(item)) {
              submenuItems.active = true;
              menuItem.active = true;
            }
          }
          return false;
        });
      }
      return false;
    });
    item.active = !item.active;
    setMainMenu(MENUITEMS);
  };

  const mainMenu = mainmenu?.map((menuItem, i) => (
    <li
      className={`${menuItem.active ? "active" : ""} ${
        menuItem.allowedKeys.some((itm) => permissions.includes(itm))
          ? ""
          : "d-none"
      }`}
      key={i}
    >
      {menuItem.sidebartitle ? (
        <div className="sidebar-title">{menuItem.sidebartitle}</div>
      ) : (
        ""
      )}
      {menuItem.type === "sub" ? (
        <a
          className={`sidebar-header ${
            menuItem.allowedKeys.some((itm) => permissions.includes(itm))
              ? ""
              : "d-none"
          }`}
          href="#javaScript"
          onClick={(event) => {
            event.preventDefault();
            return setNavActive(menuItem);
          }}
        >
          <menuItem.icon />
          <span>{menuItem.title}</span>
          <i className="fa fa-angle-right pull-right"></i>
        </a>
      ) : (
        ""
      )}
      {menuItem.type === "link" ? (
        <Link
          to={`${process.env.PUBLIC_URL}${menuItem.path}`}
          className={`sidebar-header ${menuItem.active ? "active" : ""} ${
            menuItem.allowedKeys.some((itm) => permissions.includes(itm))
              ? ""
              : "d-none"
          }`}
          onClick={() => setNavActive(menuItem)}
        >
          <menuItem.icon />
          <span>{menuItem.title}</span>
          {menuItem.children ? (
            <i className="fa fa-angle-right pull-right"></i>
          ) : (
            ""
          )}
        </Link>
      ) : (
        ""
      )}
      {menuItem.children ? (
        <ul
          className={`sidebar-submenu ${menuItem.active ? "menu-open " : ""} ${
            menuItem.allowedKeys.some((itm) => permissions.includes(itm))
              ? ""
              : "d-none"
          }`}
          style={
            menuItem?.active
              ? { opacity: 1, transition: "opacity 500ms ease-in" }
              : {}
          }
        >
          {menuItem?.children.map((childrenItem, index) => (
            <li
              key={index}
              className={`${
                childrenItem.children
                  ? childrenItem.active
                    ? "active"
                    : ""
                  : ""
              } ${
                childrenItem.allowedKeys?.some((itm) =>
                  permissions.includes(itm)
                )
                  ? ""
                  : "d-none"
              }`}
            >
              {childrenItem.type === "sub" ? (
                <a
                  className={`${
                    childrenItem.allowedKeys?.some((itm) =>
                      permissions.includes(itm)
                    )
                      ? ""
                      : "d-none"
                  }`}
                  href="#javaScript"
                  onClick={(event) => {
                    event.preventDefault();
                    return setNavActive(childrenItem);
                  }}
                >
                  <i className="fa fa-circle"></i>
                  {childrenItem.title}{" "}
                  <i className="fa fa-angle-right pull-right"></i>
                </a>
              ) : (
                ""
              )}

              {childrenItem.type === "link" ? (
                <Link
                  to={`${process.env.PUBLIC_URL}${childrenItem.path}`}
                  className={`${childrenItem.active ? "active" : ""} ${
                    childrenItem.allowedKeys?.some((itm) =>
                      permissions.includes(itm)
                    )
                      ? ""
                      : "d-none"
                  }`}
                  onClick={() => setNavActive(childrenItem)}
                >
                  <i className="fa fa-circle"></i>
                  {childrenItem.title}{" "}
                </Link>
              ) : (
                ""
              )}
              {childrenItem.children ? (
                <ul
                  className={`sidebar-submenu ${
                    childrenItem.active ? "menu-open" : "active"
                  }`}
                >
                  {childrenItem.children.map((childrenSubItem, key) => (
                    <li
                      className={`${
                        childrenSubItem.active ? "active text-white" : ""
                      } ${
                        childrenSubItem.allowedKeys?.some((itm) =>
                          permissions.includes(itm)
                        )
                          ? ""
                          : "d-none"
                      }`}
                      key={key}
                    >
                      {childrenSubItem.type === "link" ? (
                        <Link
                          to={`${process.env.PUBLIC_URL}${childrenSubItem.path}`}
                          className={`${
                            childrenSubItem.active ? "active" : ""
                          } ${
                            childrenSubItem.allowedKeys?.some((itm) =>
                              permissions.includes(itm)
                            )
                              ? ""
                              : "d-none"
                          }`}
                          onClick={() => setNavActive(childrenSubItem)}
                        >
                          <i className="fa fa-circle"></i>
                          {childrenSubItem.title}
                        </Link>
                      ) : (
                        ""
                      )}
                    </li>
                  ))}
                </ul>
              ) : (
                ""
              )}
            </li>
          ))}
        </ul>
      ) : (
        ""
      )}
    </li>
  ));

  useEffect(() => {
    handleFilterMenu();
  }, [searchValue]);

  const handleFilterMenu = () => {
    let filterMenu = mainmenu
      .map((menuItem) => {
        // Check if the menuItem's title includes the searchValue
        if (
          menuItem?.title?.toLowerCase().includes(searchValue.toLowerCase())
        ) {
          return menuItem; // Keep the menuItem in the filtered list
        }
        // If the menuItem has children, filter them too
        if (menuItem.children) {
          const filteredChildren = menuItem.children.filter((childItem) =>
            childItem?.title?.toLowerCase().includes(searchValue.toLowerCase())
          );
          // If there are filtered children, create a copy of the menuItem with those children
          if (filteredChildren.length > 0) {
            return { ...menuItem, children: filteredChildren };
          }
        }
        // If the menuItem or its children didn't match the search, return null
        return null;
      })
      .filter((menuItem) => menuItem !== null); // Remove null values

    setMenu(filterMenu);
  };

  return (
    <Fragment>
      <div className="page-sidebar d-none d-md-block">
        <div className="main-header-left d-none d-lg-block">
          <div className="logo-wrapper">
            <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
              <img className="blur-up lazyloaded img-fluid" src={logo} alt="" />
            </Link>
          </div>
        </div>
        <div
          className="sidebar custom-scrollbar"
          style={{ backgroundColor: "#0d0d44" }}
        >
          <div className="sidebar-menu  pb-0">
            <UserPanel
              handleInputChange={handleInputChange}
              clearInput={clearInput}
              searchValue={searchValue}
            />
          </div>
          <ul className="sidebar-menu pt-1">
            {menu.map((menuItem, i) => {
              return (
                <li
                  style={{
                    backgroundColor: menuItem.active ? "#13366a" : "",
                    color: menuItem.active ? "white" : "",
                    paddingLeft: menuItem.active ? "10px" : "",
                    borderRadius: menuItem.active ? "10px" : "",
                    marginTop: menuItem.active ? "5px" : "",
                  }}
                  className={`${menuItem.active ? "active" : ""} ${
                    menuItem.allowedKeys?.some((itm) =>
                      permissions.includes(itm)
                    )
                      ? ""
                      : "d-none"
                  }`}
                  key={i}
                >
                  {menuItem.sidebartitle ? (
                    <div className="sidebar-title">{menuItem.sidebartitle}</div>
                  ) : (
                    ""
                  )}
                  {menuItem.type === "sub" ? (
                    <a
                      //sub menu color apply
                      style={{ color: menuItem.type ? "white" : "" }}
                      className="sidebar-header "
                      href="#javaScript"
                      onClick={(event) => {
                        event.preventDefault();
                        return setNavActive(menuItem);
                      }}
                    >
                      {menuItem.icon && <menuItem.icon />}{" "}
                      {/* Render the icon if it exists */}
                      <span>{menuItem.title}</span>
                      <i className="fa fa-angle-right pull-right"></i>
                    </a>
                  ) : (
                    ""
                  )}
                  {menuItem.type === "link" ? (
                    <Link
                      style={{ color: menuItem.active ? "white" : "" }}
                      to={`${process.env.PUBLIC_URL}${menuItem.path}`}
                      className={`sidebar-header ${
                        menuItem.active ? "active" : ""
                      } ${
                        menuItem.allowedKeys?.some((itm) =>
                          permissions.includes(itm)
                        )
                          ? ""
                          : "d-none"
                      } `}
                      onClick={() => setNavActive(menuItem)}
                    >
                      {menuItem.icon && <menuItem.icon />}{" "}
                      {/* Render the icon if it exists */}
                      <span>{menuItem.title}</span>
                      {menuItem.children ? (
                        <i className="fa fa-angle-right pull-right"></i>
                      ) : (
                        ""
                      )}
                    </Link>
                  ) : (
                    ""
                  )}
                  {menuItem.children ? (
                    <ul
                      className={`sidebar-submenu ${
                        menuItem.active ? "menu-open" : ""
                      }`}
                      style={
                        menuItem.active
                          ? { opacity: 1, transition: "opacity 500ms ease-in" }
                          : {}
                      }
                    >
                      {menuItem.children.map((childrenItem, index) => (
                        <li
                          key={index}
                          className={`${
                            childrenItem.children
                              ? childrenItem.active
                                ? "active"
                                : ""
                              : ""
                          } ${
                            childrenItem.allowedKeys?.some((itm) =>
                              permissions.includes(itm)
                            )
                              ? ""
                              : "d-none"
                          }`}
                          style={{
                            color: childrenItem.active ? "white" : "",
                          }}
                        >
                          {childrenItem.type === "sub" ? (
                            <a
                              href="#javaScript"
                              onClick={(event) => {
                                event.preventDefault();
                                return setNavActive(childrenItem);
                              }}
                            >
                              <i className="fa fa-circle"></i>
                              {childrenItem.title}{" "}
                              <i className="fa fa-angle-right pull-right"></i>
                            </a>
                          ) : (
                            ""
                          )}

                          {childrenItem.type === "link" ? (
                            <Link
                              to={`${process.env.PUBLIC_URL}${childrenItem.path}`}
                              className={`${
                                childrenItem.active ? "active" : ""
                              } ${
                                childrenItem.allowedKeys?.some((itm) =>
                                  permissions.includes(itm)
                                )
                                  ? ""
                                  : "d-none"
                              }`}
                              onClick={() => setNavActive(childrenItem)}
                            >
                              <i className="fa fa-circle"></i>
                              {childrenItem.title}{" "}
                            </Link>
                          ) : (
                            ""
                          )}
                          {childrenItem.children ? (
                            <ul
                              className={`sidebar-submenu ${
                                childrenItem.active ? "menu-open" : "active"
                              }`}
                            >
                              {childrenItem.children.map(
                                (childrenSubItem, key) => (
                                  <li
                                    className={`${
                                      childrenSubItem.active ? "active" : ""
                                    } ${
                                      childrenItem.allowedKeys?.some((itm) =>
                                        permissions.includes(itm)
                                      )
                                        ? ""
                                        : "d-none"
                                    } `}
                                    key={key}

                                    // style={{
                                    //   color: childrenItem.active ? 'white !important' : '',
                                    // }}
                                  >
                                    {childrenSubItem.type === "link" ? (
                                      <Link
                                        to={`${process.env.PUBLIC_URL}${childrenSubItem.path}`}
                                        className={`${
                                          childrenSubItem.active
                                            ? "active text-white"
                                            : ""
                                        } ${
                                          childrenItem.allowedKeys?.some(
                                            (itm) => permissions.includes(itm)
                                          )
                                            ? ""
                                            : "d-none"
                                        }`}
                                        onClick={() =>
                                          setNavActive(childrenSubItem)
                                        }
                                      >
                                        <i className="fa fa-circle"></i>
                                        {childrenSubItem.title}
                                      </Link>
                                    ) : (
                                      ""
                                    )}
                                  </li>
                                )
                              )}
                            </ul>
                          ) : (
                            ""
                          )}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    ""
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </Fragment>
  );
};

export default Sidebar;
