import React, { useEffect } from "react";
import useGetData from "../../../hooks/useGetData";
import { useState } from "react";
import InputLoading from "../../../components/Loading/InputLoading";
import PosDropdownSection from "../Pos/PosDropdownSection";
import SortSection from "../../../components/DashboardComponents/SortSection";
import SalesListTableSection from "../SalesList/SalesListTableSection";
import InputLoadingTwo from "../../../components/Loading/InputLoadingTwo";

const SalesReturnPaymentInformationTableSection = ({
  salesReturnPaymentListData,
  salesReturnPaymentListDataIsLoading,
}) => {
  const salesTable = [
    {
      id: 10,
      name: "SL",
    },
    {
      id: 11,
      name: "Payment Date",
    },
    {
      id: 12,
      name: "Sales Code",
    },
    {
      id: 13,
      name: "Return Code",
    },
    {
      id: 14,
      name: "Payment Code",
    },
    {
      id: 15,
      name: "Customer Name",
    },
    {
      id: 16,
      name: "Payment",
    },
    {
      id: 17,
      name: "Payment Type",
    },
    {
      id: 18,
      name: "Payment Status",
    },
  ];

  const paymentStatusData = [
    {
      id: 1,
      name: "All Payment Status",
    },
    {
      id: 2,
      name: "Paid",
    },
    {
      id: 3,
      name: "Partially Paid",
    },
    {
      id: 4,
      name: "Due",
    },
  ];

  // get data from backend
  const [paymentTypeData, refetchPayment, isLoadingPayment] = useGetData(
    "/paymenttype/viewpaymenttypes/",
    "paymenttypeData"
  );
  const [updatedPaymentTypeData, setUpdatedPaymentTypeData] = useState([]);
  // Define the custom customer data
  const customPaymentTypeData = {
    id: null,
    payment_type: "All Payment Type",
    status: null,
  };
  useEffect(() => {
    if (paymentTypeData) {
      setUpdatedPaymentTypeData([customPaymentTypeData, ...paymentTypeData]);
    }
  }, [paymentTypeData]);

  const [data, setData] = useState([]);
  const [paymentStatusDropdownValue, setPaymentStatusDropdownValue] =
    useState("Select-Status");
  const [paymentTypeDropdownValue, setPaymentTypeTwoDropdownValue] = useState(
    "Select-Payment-Type"
  );
  const [searchValue, setSearchValue] = useState("");
  const [selectedValue, setSelectedValue] = useState(10);

  // apply filters on jobs
  const applyFilters = () => {
    if (salesReturnPaymentListData) {
      let filteredArray = [...salesReturnPaymentListData];
      // Apply payment Type filter
      if (
        paymentTypeDropdownValue !== "Select-Payment-Type" &&
        paymentTypeDropdownValue !== "All Payment Type"
      ) {
        filteredArray = filteredArray.filter(
          (item) =>
            item?.payment_type?.toLowerCase() ===
            paymentTypeDropdownValue?.toLowerCase()
        );
      }

      // Apply payments status filter
      if (
        paymentStatusDropdownValue !== "All Payment Status" &&
        paymentStatusDropdownValue !== "Select-Status"
      ) {
        filteredArray = filteredArray.filter(
          (item) =>
            item?.payment_status?.toLowerCase() ===
            paymentStatusDropdownValue?.toLowerCase()
        );
      }

      // Apply search filter
      if (searchValue.trim() !== "") {
        filteredArray = filteredArray.filter((item) =>
          item?.customer_name
            ?.toLowerCase()
            .includes(searchValue?.toLowerCase())
        );
      }
      setData(filteredArray);
    }
  };

  useEffect(() => {
    applyFilters();
  }, [
    paymentTypeDropdownValue,
    paymentStatusDropdownValue,
    salesReturnPaymentListData,
    searchValue,
    paymentTypeData,
  ]);

  return (
    <>
      {/* section-1 */}
      {!paymentTypeData ? (
        <>
          <InputLoadingTwo numSections={1} />
        </>
      ) : (
        <>
          <section className="d-flex justify-content-between align-items-center p-4">
            <div style={{ width: "48%" }}>
              <h5 className="text-start ">Payment Type</h5>
              <div
                style={{ width: "100%" }}
                className=" d-flex justify-content-between align-items-center border border rounded-1 "
                title="Warehouse"
              >
                {/* dropdown data */}
                {paymentTypeData && (
                  <PosDropdownSection
                    inputType={"search"}
                    InitialDropdownValue={"Select-Payment-Type"}
                    data={updatedPaymentTypeData || []}
                    titleData={"Payment Type"}
                    placeHolderData={"Enter Payment Type"}
                    setPaymentTypeTwoDropdownValue={
                      setPaymentTypeTwoDropdownValue
                    }
                  ></PosDropdownSection>
                )}
              </div>
            </div>
            <div style={{ width: "48%" }}>
              <h5 className="text-start ">Payment Status</h5>
              <div
                style={{ width: "100%" }}
                className="  d-flex justify-content-between align-items-center border rounded-1"
                title="Customer"
              >
                {paymentTypeData && paymentStatusData && (
                  <PosDropdownSection
                    inputType={"search"}
                    InitialDropdownValue={"Select-Status"}
                    data={paymentStatusData || []}
                    titleData={"Select Status"}
                    placeHolderData={"Enter Payment Status"}
                    setPaymentStatusDropdownValue={
                      setPaymentStatusDropdownValue
                    }
                  ></PosDropdownSection>
                )}
              </div>
            </div>
          </section>
        </>
      )}
      {/* section-2 */}
      <section className="my-4 px-4">
        <div className="row">
          <SortSection
            setSelectedValue={setSelectedValue}
            setSearchValue={setSearchValue}
            searchValue={searchValue}
          ></SortSection>
        </div>
      </section>
      {/* section-3 */}
      <section
        className="mx-4 p-2 border rounded-2 bg-body-secondary "
        style={{ height: "55vh", overflowY: "scroll", overflowX: "hidden" }}
      >
        <SalesListTableSection
          tableHeader={salesTable}
          showTable={"salesReturnPaymentList"}
          isLoading={salesReturnPaymentListDataIsLoading}
          tableData={
            selectedValue !== "allData" ? data?.slice(0, selectedValue) : data
          }
        ></SalesListTableSection>
      </section>
    </>
  );
};

export default SalesReturnPaymentInformationTableSection;
