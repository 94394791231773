import React from "react";
import amrShop from "../../../assets/images/dashboard/amr-shop.jpg";

const PurchaseInvoiceHeader = ({ data }) => {
  const { warehouse_name, warehouse_address, mobile } = data || {};
  return (
    <section
      className="d-flex justify-content-between border-bottom pb-3 border-black className
    "
    >
      {/* child-1 */}
      <div className="d-flex justify-content-between align-items-center col-6  gap-3">
        <div className=" col-4">
          <img src={amrShop} alt="" className="img-fluid" />
        </div>
        <div className="col-8">
          <h3 className="m-0 text-black fw-bold">{warehouse_name}</h3>
          <p
            className="m-0 text-black fw-bold
          "
          >
            {warehouse_address}
          </p>
          <p
            className="m-0 text-black fw-bold
          "
          >
            {mobile}
          </p>
        </div>
      </div>
      {/* child-2 */}
      <div className="col-6 d-flex justify-content-end align-items-start">
        <button className=" px-5 py-2 border-0 fs-6 fw-bold">
          Purchase Order
        </button>
      </div>
    </section>
  );
};

export default PurchaseInvoiceHeader;
