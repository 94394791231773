import React from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import SalesReturnAmountSection from "./SalesReturnAmountSection";
import SalesReturnListInformationTableSection from "./SalesReturnListInformationTableSection";
import useGetData from "../../../hooks/useGetData";
import { useEffect } from "react";

const SalesReturnList = () => {
  // sales return  list data get from backend
  const refetchInterval = 10000;

  const [
    salesReturnListData,
    salesReturnDataRefetch,
    salesReturnListIsLoading,
  ] = useGetData(`/salesreturn/view-all-sales-returns`, "salesReturnListData");

  useEffect(() => {
    const refetchData = () => {
      salesReturnDataRefetch();
    };
    const intervalId = setInterval(refetchData, refetchInterval);
    return () => {
      clearInterval(intervalId);
    };
  }, [salesReturnDataRefetch]);
  return (
    <>
      <Breadcrumb title="Sales Return List" parent="Sales Management" />
      <main className=" w-100 px-2">
        <SalesReturnAmountSection
          salesReturnListData={salesReturnListData}
          salesReturnDataRefetch={salesReturnDataRefetch}
          salesReturnListIsLoading={salesReturnListIsLoading}
        ></SalesReturnAmountSection>
        <section
          className="bg-white rounded-2 mt-5 pb-5"
          style={{ boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)" }}
        >
          <div>
            <SalesReturnListInformationTableSection
              salesReturnListData={salesReturnListData}
              salesReturnDataRefetch={salesReturnDataRefetch}
              salesReturnListIsLoading={salesReturnListIsLoading}
            ></SalesReturnListInformationTableSection>
          </div>
        </section>
      </main>
    </>
  );
};

export default SalesReturnList;
