import React, { Fragment, useState } from 'react';
import LoginTabset from './loginTabset';
import './auth.style.css';
import stats from '../../assets/images/dashboard/logo.png';

import axiosInstance from '../../util/axiosInstance';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import releaseIMG from '../../assets/images/dashboard/release.jpg';
import { useEffect } from 'react';
import { Dna } from 'react-loader-spinner';
import { loginUserAction } from '../../redux/features/auth/userSlices';
import { useDispatch, useSelector } from 'react-redux';
import loader from '../../assets/images/ezgif.com-gif-maker (1).gif';

const Login = () => {
  const [showNumberInput, setShowNumberInput] = useState(true);
  const [openOtpForm, setOpenOtpForm] = useState(false);
  // const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const [phone, setPhone] = useState('');

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user);

  const { loading, userAuth, userToken, appErr, serverErr } = user;

  const onSubmit = (data, e) => {
    e.preventDefault();

  };

  const loginHandler = (e) => {
    e.preventDefault();

    const queryString = !showNumberInput ? '/auth/sendotp' : '/auth/login';
    const form = e.target;
    // const phone = form.phone.value;
    const username = form.user.value;
    const password = form.password.value;
    const data = {
      username,
      password,
    };
    dispatch(loginUserAction(data));
  };
  useEffect(() => {
    if (userAuth) {
      //<Navigate to="/profile" replace={true} />
      navigate(`/dashboard`);
    }
  }, [userAuth, navigate]);

  const handleAnotherWayLogin = () => {
    setShowNumberInput(!showNumberInput);
  };
  const [showLoader, setShowLoader] = useState(true);
  useEffect(() => {
    // Simulate a delay to show the loader
    const loaderTimeout = setTimeout(() => {
      setShowLoader(false);
    }, 2000); // Adjust the time as needed

    // Clean up the timeout to prevent memory leaks
    return () => clearTimeout(loaderTimeout);
  }, []);

  return (
    <div>
      {showLoader ? (
        // Display loader while showLoader is true
        <div
          className='loader'
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '32vh',
            textAlign: 'center',
          }}>
          <img
            width='200px'
            src={loader}
            alt=''
          />
        </div>
      ) : (
        <section className='vh-100 bg-cccc'>
          <div className='css-selector vh-100'>
            <div className='container py-5 h-100'>
              <div className='row d-flex justify-content-center align-items-center h-100'>
                <div className='col col-xl-10'>
                  <div
                    className='card'
                    style={{ borderRadius: '1rem' }}>
                    <div className='row g-0'>
                      <div className='col-md-6 col-lg-7 d-none d-md-block'>
                        <img
                          src={releaseIMG}
                          alt='login form'
                          className='img-fluid'
                          style={{
                            borderRadius: '1rem 0 0 1rem',
                            height: '100%',
                          }}
                        />
                      </div>
                      <div className='col-md-6 col-lg-5 d-flex align-items-center'>
                        <div className='card-body p-4 p-lg-5 text-black'>
                          <form onSubmit={(e) => loginHandler(e)}>
                            <div className='d-flex align-items-center flex-column mb-3 pb-1'>
                              <img
                                src={stats}
                                alt='logo'
                                className='img'
                                accordion-icon='logo'
                              />
                              <p>
                                An Award Wining POS Software |{' '}
                                <strong>ZENITH</strong> V2.0
                              </p>
                              <span className='h1 fw-bold mb-0'></span>
                            </div>
                            <h5
                              className='text-center fw-bold fw-normal mb-1 pb-3'
                              style={{ letterSpacing: 1 }}>
                              LOGIN TO YOUR SOFTWARE
                            </h5>
                            <div className='form-outline mb-4'>
                              <input
                                placeholder='Email/Username'
                                name='user'
                                className='form-control form-control-lg'
                              />
                            </div>
                            <div className='form-outline mb-4'>
                              <input
                                placeholder='Password'
                                name='password'
                                type='password'
                                className='form-control form-control-lg'
                              />
                            </div>
                            {serverErr && (
                              <p className='text-center text-danger'>
                                Password and email do not match
                              </p>
                            )}
                            <div className='d-flex justify-content-center pt-1 mb-4 '>
                              <button
                                className='btn btn-dark btn-lg btn-block'
                                type='submit'>
                                Login
                              </button>
                            </div>
                            <p className='text-center small text-muted'>
                              If you forgot your password, please contact at{' '}
                              <br />
                              <img
                                src='https://uxwing.com/wp-content/themes/uxwing/download/flags-landmarks/bangladesh-flag-icon.png'
                                alt=''
                                width='20px'
                                height='15px'
                              />
                              <span> </span>
                              <img
                                src='https://upload.wikimedia.org/wikipedia/commons/f/f2/Argentina_Flag.png'
                                alt=''
                                width='20px'
                                height='15px'
                              />
                              <strong> +8801326243077</strong>
                            </p>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default Login;
