import React, { useState } from 'react'
import { AiOutlinePlus, AiOutlineMinus, AiFillDelete } from "react-icons/ai";


const ListItem = ({ item, setSelectItem, selectItem }) => {
    const [count, setCount] = useState(1);
    const handleDecrement = () => {

        setCount(x => x - 1);
        const itemList = selectItem.map(x => {

            if (item.id === x.id) {

                return { ...x, itemToPrint: count - 1 }
            } else {
                return x
            }
        })

        setSelectItem(itemList)
    }
    const handleIncrement = () => {
        setCount(x => x + 1)
        const itemList = selectItem.map(x => {

            if (item.id === x.id) {

                return { ...x, itemToPrint: count + 1 }
            } else {
                return x
            }

        })

        setSelectItem(itemList)
    }
    const handleDeleteProduct = (item) => {
        const itemList = selectItem.filter(x => x.id !== item.id)
        setSelectItem(itemList)
    }

    return (
        <tr>
            <td className="text-start ">
                {item?.item_name}
                {/* <input type="text" Value={item_name} onChange={(e) => {setItemName(e.target.value.trim() || item_name)}} style={{width:'40%'}} className="form-control"/> */}
            </td>


            {/* quantity increment  decrement  */}

            <td className="d-flex  justify-content-center align-items-center ">
                <span
                    style={{ width: "150px" }}
                    className="border rounded-1 d-flex justify-content-between align-items-center"
                >
                    <button
                        className="d-flex justify-content-center align-items-center border-end border-0 col-2   p-2"
                        onClick={handleDecrement}
                    >
                        <span>
                            <AiOutlineMinus className="text-danger fw-bold" />
                        </span>
                    </button>
                    <span className=" text-center px-2 border-0 d-inline-block col-8">
                        <input
                            type="number"
                            step={"any"}
                            placeholder="Quantity"
                            value={count >= 0 && count}
                            onChange={(e) => {
                                const inputValue = parseFloat(e.target.value);
                                if (!isNaN(inputValue) && inputValue >= 0) {
                                    setCount(inputValue);
                                } else {
                                    setCount(0);
                                }
                            }}
                            className="border-0  text-center  text-center w-100"
                        />
                    </span>
                    <button
                        className="d-flex justify-content-center align-items-center border-start border-0 p-2 col-2"
                        onClick={() => handleIncrement()}
                    >
                        <span>
                            <AiOutlinePlus className="text-success fw-bold" />
                        </span>
                    </button>
                </span>
            </td>

            <td>
                <span className="d-flex justify-content-center align-items-center  py-2">
                    <AiFillDelete
                        onClick={() => handleDeleteProduct(item)}
                        style={{ cursor: "pointer" }}
                        className="text-danger fs-5"
                    ></AiFillDelete>
                </span>
            </td>
        </tr>
    )
}

export default ListItem