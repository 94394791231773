import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userLoggedOut } from '../redux/features/auth/userSlices';

const useAuth = () => {
  const [userLogedIn, setUserLoggedin] = useState(false);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem('access-token');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logOut = () => {
    dispatch(userLoggedOut());
    navigate('/');
  };

  useEffect(() => {
    if (token) {
      setUserLoggedin(true); // Set to true if there's a token
    } else {
      setUserLoggedin(false); // Set to false if there's no token
    }

    setLoading(false);
  }, []);

  return { userLogedIn, loading, logOut };
};

export default useAuth;
