
import { Fragment, useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Container, } from "reactstrap";

import Breadcrumb from "../../../components/common/breadcrumb";

import { Table } from 'reactstrap';
import { Link } from "react-router-dom";
import { AiFillEdit, AiTwotoneDelete } from 'react-icons/ai';
import { BiPlusMedical } from "react-icons/bi";
import LoadingBar from 'react-top-loading-bar'
import axios from "../../../util/axiosInstance";
import useGetData from "../../../hooks/useGetData";
import TableLoading from "../../../components/common/loading/TableLoading";
import { CustomToast } from "../../../hooks/useCustomToast";
import Swal from "sweetalert2";

const CategoryList = () => {

	const btnStyle = {
		height: '40px',
		width: '40px',
		borderRadius: '50%',
		padding: '8px',
		background: 'transparent',
	};

	// const { data, isError, } = useGetAllCategoryListQuery();
	const [data, setData] = useState([]);
	// const [progress, setProgress] = useState(100)
	let allData = data?.length;

	const [searchValue, setSearchValue] = useState("");
	const [selectedValue, setSelectedValue] = useState(10);
	const [category, setCategory] = useState([]);

	const [isLoading, setIsLoading] = useState(false);

	const [categoryDatafromData, categoryDataRefetch] = useGetData('/category/viewcategories/', 'categoryDatafromData');



	useEffect(() => {
		setIsLoading(true);
		axios('/category/viewcategories/')
			.then(data => {
				setData(data.data);
				setIsLoading(false);  // Stop loading
			})
			.catch(error => {
				// setError(error);
				setIsLoading(false);  // Stop loading
			});

	}, [])

	const handleDelete = async (id) => {

		Swal.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					// Assuming your API endpoint looks like /api/items/:id
					const response = await axios.delete(`/category/deletecategory/${id}`);

					if (response) {
						// Filter out the deleted item based on its ID
						const updatedItems = data.filter(item => item.id !== id);
						setData(updatedItems);
						Swal.fire(
							'Deleted!',
							'Your file has been deleted.',
							'success'
						)
					} else {
						console.error("Failed to delete item:", response.data);
					}
				} catch (error) {
					console.error("Error deleting item:", error);
				}

			}
		})



	}


	useEffect(() => {
		handleFilter()
	}, [searchValue, data,]);

	// search
	const handleFilter = () => {
		if (data) {
			let filterCategory = [...data]
			if (searchValue !== '') {
				filterCategory = data?.filter((category) =>
					category.category_name.toLowerCase().includes(searchValue.toLowerCase())
				);
			}
			setCategory(filterCategory);
		}

	}
	const handleInputChange = (e) => {
		setSearchValue(e.target.value);

	};




	const handleChangeActiveStatus = async (id) => {
		// Find the item and its current status
		const item = data.find(d => d.id === id);
		if (!item) return;

		const newStatus = item.status === 1 ? 0 : 1;

		// Optimistically update state
		const updatedData = data.map(d =>
			d.id === id ? { ...d, status: newStatus } : d
		);
		setData(updatedData);

		try {
			// Assuming your API endpoint looks like `/api/items/:id`
			const response = await axios.put(`/category/togglestatus/${id}`, { status: newStatus });

			if (response) {
				CustomToast('success', 'Update Successfully')
			}
		} catch (error) {
			console.error("Error updating status:", error);
			// Revert back to original data if error occurred
			const revertedData = data.map(d =>
				d.id === id ? { ...d, status: item.status } : d
			);
			setData(revertedData);
			alert('Failed to update the status. Please try again.');
		}
	};



	return (
    <Fragment>
      <Breadcrumb
        title='Category List'
        parent='Product Management'
      />
      <Container fluid={true}>
        <Card>
          <CardHeader>
            <div className='d-flex justify-content-between'>
              <h5 className='text-center'> Category List </h5>
              <Link
                className='d-flex align-items-center gap-2'
                style={{
                  backgroundColor: '#00C0EF',
                  textDecoration: 'none',
                  color: 'white',
                  padding: '6px',
                  borderRadius: '5px',
                }}
                to={'/product-management/categoryList/add'}>
                {' '}
                <BiPlusMedical />
                Add Category
              </Link>
            </div>
          </CardHeader>
          <CardBody>
            <div className='row'>
              <div className='col-md-4 d-flex align-items-center '>
                <span>Show</span>
                <select
                  onChange={(e) => setSelectedValue(e.target.value)}
                  className='mx-1'>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                  <option value={500}>500</option>
                  <option value={allData}>AllData</option>
                </select>
                <span>entries</span>
              </div>
              <div className='col-md-4 d-flex align-items-center justify-content-end'>
                {/* <span style={{ backgroundColor: '#39CCCC', color: 'white', padding: '8px', borderRadius: '5px', borderRight: '1px solid white', cursor: 'pointer' }}>Copy</span> */}
              </div>
              <div className='col-md-4 d-flex align-items-center gap-2'>
                <span>Search </span>{' '}
                <input
                  type='text'
                  placeholder='By Category Name'
                  className='form-control'
                  value={searchValue}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div
              style={{
                backgroundColor: '#F2F2F2',
                padding: '10px',
                marginTop: '2px',
              }}>
              <Table
                bordered
                hover
                striped>
                <thead className='text-center'>
                  <tr>
                    <th>ID </th>
                    <th>Category Name</th>
                    <th>Description </th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                {/* {
								isLoading && (
									<LoadingBar color="red" style={{ marginTop: '10px' }}

										progress={progress}
										onLoaderFinished={() => setProgress(0)}
									/>
								)
							} */}
                <tbody className='text-center'>
                  {isLoading && (
                    <TableLoading
                      noRow={10}
                      noTd={5}
                    />
                  )}

                  {!isLoading && (
                    <>
                      {category
                        ?.slice()
                        .sort((a, b) => b.id - a.id)
                        .slice(0, selectedValue)
                        .map((category, index) => (
                          <tr key={category.id}>
                            <th scope='row'>{index + 1}</th>
                            <td>{category.category_name}</td>
                            <td>{category.description}</td>
                            <td>
                              {category?.status === 1 && (
                                <>
                                  {' '}
                                  <div className='form-check form-switch d-flex align-items-center justify-content-center'>
                                    <input
                                      className='form-check-input'
                                      style={{ cursor: 'pointer' }}
                                      type='checkbox'
                                      id='flexSwitchCheckChecked'
                                      onClick={(e) =>
                                        handleChangeActiveStatus(
                                          category?.id,
                                          1
                                        )
                                      }
                                      checked={category.status}
                                    />
                                  </div>
                                </>
                              )}
                              {category?.status === 0 && (
                                <>
                                  {' '}
                                  <div className='form-check form-switch d-flex align-items-center justify-content-center'>
                                    <input
                                      className='form-check-input'
                                      style={{ cursor: 'pointer' }}
                                      type='checkbox'
                                      id='flexSwitchCheckChecked'
                                      onClick={(e) =>
                                        handleChangeActiveStatus(
                                          category?.id,
                                          0
                                        )
                                      }
                                      checked={category.status}
                                    />
                                  </div>
                                </>
                              )}
                            </td>
                            <td>
                              <Link
                                className='fs-4 text-decoration-none'
                                to={`/product-management/categoryList/edit/${category.id}`}>
                                {' '}
                                <AiFillEdit />{' '}
                              </Link>

                              <Link
                                className='border-0 bg-transparent fs-4'
                                onClick={() => handleDelete(category.id)}>
                                {' '}
                                <AiTwotoneDelete />{' '}
                              </Link>
                            </td>
                          </tr>
                        ))}
                    </>
                  )}
                </tbody>
              </Table>
            </div>

            {/* <div>
							<nav className=' d-flex justify-content-end align-items-center px-2 col-12 text-black-50 fs-6 bg-white border-top-1'>
								<span className=' mx-2 my-0 flex-shrink-1 user-select-none'>
									Rows per page:
								</span>
								<div className=' position-relative flex-shrink-0 mt-1 d-flex '>
									<select
										aria-label='Rows per page:'
										className='sc-bXCLTC eVrcSZ cs-cursor-pointer user-select-none ps-2 pe-4  '
										style={{
											height: '25px',
											maxWidth: '100%',
											color: '#000000',
											fontSize: 'inherit',
											border: 'none',
											background: 'transparent',
											appearance: 'none',
											direction: 'ltr',
										}}>
										<option
											value={10}
											selected>
											10
										</option>
										<option value={15}>15</option>
										<option value={20}>20</option>
										<option value={25}>25</option>
										<option value={30}>30</option>
									</select>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width={24}
										height={24}
										viewBox='0 0 24 24'
										className='position-absolute top-0'
										style={{ right: 0 }}>
										<path d='M7 10l5 5 5-5z' />
										<path
											d='M0 0h24v24H0z'
											fill='none'
										/>
									</svg>
								</div>
								<span className='mx-4 user-select-none flex-shrink-1'>1-2 of 2</span>
								<div className='sc-bmzYkS fimDOL d-flex align-items-center rounded-1 white-space-none'>
									<button
										id='pagination-first-page'
										type='button'
										aria-label='First Page'
										aria-disabled='true'
										disabled
										className='position-relative d-block user-select-none border-0 rounded-1 cs-cursor-pointer'
										style={btnStyle}>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width={24}
											height={24}
											viewBox='0 0 24 24'
											aria-hidden='true'
											role='presentation'>
											<path d='M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z' />
											<path
												fill='none'
												d='M24 24H0V0h24v24z'
											/>
										</svg>
									</button>
									<button
										id='pagination-previous-page'
										type='button'
										aria-label='Previous Page'
										aria-disabled='true'
										disabled
										className='position-relative d-block user-select-none border-0 rounded-1 cs-cursor-pointer'
										style={btnStyle}>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width={24}
											height={24}
											viewBox='0 0 24 24'
											aria-hidden='true'
											role='presentation'>
											<path d='M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z' />
											<path
												d='M0 0h24v24H0z'
												fill='none'
											/>
										</svg>
									</button>
									<button
										id='pagination-next-page'
										type='button'
										aria-label='Next Page'
										aria-disabled='true'
										disabled
										className='position-relative d-block user-select-none border-0 rounded-1 cs-cursor-pointer'
										style={btnStyle}>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width={24}
											height={24}
											viewBox='0 0 24 24'
											aria-hidden='true'
											role='presentation'>
											<path d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z' />
											<path
												d='M0 0h24v24H0z'
												fill='none'
											/>
										</svg>
									</button>
									<button
										id='pagination-last-page'
										type='button'
										aria-label='Last Page'
										aria-disabled='true'
										disabled
										className='position-relative d-block user-select-none border-0 rounded-1 cs-cursor-pointer'
										style={btnStyle}>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width={24}
											height={24}
											viewBox='0 0 24 24'
											aria-hidden='true'
											role='presentation'>
											<path d='M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z' />
											<path
												fill='none'
												d='M0 0h24v24H0V0z'
											/>
										</svg>
									</button>
								</div>
							</nav>
						</div> */}
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
};

export default CategoryList;
