import React, { useRef, useState } from "react";
import SalesListTableSection from "./SalesListTableSection";
import { AiFillFileText } from "react-icons/ai";
import { MdOutlineAssignmentReturn } from "react-icons/md";
import useNumberFormatter from "../../../hooks/useNumberFormatter";
import { Link } from "react-router-dom";
import PosMultiplePaymentSlip from "../Pos/PosMultiplePaymentSlip";
import { useReactToPrint } from "react-to-print";

const ViewInvoiceInformationTableSection = ({ data, id, isLoading }) => {
  const numericFormatter = useNumberFormatter();

  const salesTable = [
    {
      id: 10,
      name: "SL",
    },
    {
      id: 11,
      name: "Item Name",
    },
    {
      id: 12,
      name: "Unit Price",
    },
    {
      id: 13,
      name: "Quantity",
    },
    {
      id: 14,
      name: "Price",
    },
    {
      id: 15,
      name: "Discount",
    },
    {
      id: 16,
      name: "Discount Amount",
    },
    {
      id: 17,
      name: "Unit Cost",
    },
    {
      id: 18,
      name: "Total Amount",
    },
  ];

  const paymentTable = [
    {
      id: 1,
      name: "SL",
    },
    {
      id: 2,
      name: "Date",
    },
    {
      id: 3,
      name: "Payment Type",
    },
    {
      id: 4,
      name: "Account",
    },
    {
      id: 5,
      name: "Payment Note",
    },
    {
      id: 6,
      name: "Payment",
    },
  ];
  const { sales_items, sales_payments, discount_to_all_input } = data || {};
  const [subTotal, setSubTotal] = useState(0);
  const grandTotal =
    parseFloat(discount_to_all_input || 0) + parseFloat(subTotal || 0);

  // pos invoice data

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <>
      <section className="bg-body-secondary rounded-2 mt-4 p-2">
        <SalesListTableSection
          tableHeader={salesTable}
          tableData={sales_items}
          showTable={"viewSales"}
          setSubTotal={setSubTotal}
          isLoading={isLoading}
        ></SalesListTableSection>
      </section>
      <section className="mt-4 ">
        <h4 style={{ color: "rgb(9, 61, 134)" }} className="fw-700 ">
          Payments Information:
        </h4>
        <div className="d-flex mt-1  justify-content-between align-items-center ">
          {/* main section-1 */}
          <div className="col-7">
            <section className="bg-body-secondary rounded-2 p-2">
              <SalesListTableSection
                tableHeader={paymentTable}
                tableData={sales_payments}
                showTable={"viewSalesPayment"}
                isLoading={isLoading}
              ></SalesListTableSection>
            </section>
          </div>
          {/* main section-2 */}
          <div className="col-3 d-flex align-items-start justify-content-between  px-3">
            <div className=" text-end  ">
              <p className="m-0 text-black fw-700 fs-7">Subtotal:</p>
              <p className="m-0 text-black fw-700 fs-7">Discount on All:</p>
              <p className="m-0 text-black fw-700 fs-7">Grand Total:</p>
            </div>
            <div className="text-end">
              <p className="m-0 text-black fw-700 fs-7">
                {(subTotal && numericFormatter(subTotal)) || 0}
              </p>
              <p className="m-0 text-black fw-700 fs-7">
                {numericFormatter(discount_to_all_input) || 0}
              </p>
              <p className="m-0 text-black fw-700 fs-7">
                {numericFormatter(grandTotal) || 0}
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* btn section */}
      <section className="d-flex justify-content-between align-items-start mt-4">
        <div className="d-flex gap-2">
          <Link
            to={`/sales-management/sales-return-item/${id}`}
            className="btn  bg-orange-hover gap-1 d-flex justify-content-center align-items-center"
          >
            <MdOutlineAssignmentReturn className="fs-5"></MdOutlineAssignmentReturn>{" "}
            Sales Return
          </Link>
          <button
            onClick={handlePrint}
            className="btn  bg-blue-hover gap-1 d-flex justify-content-center align-items-center"
          >
            <AiFillFileText className="fs-5"></AiFillFileText> POS Invoice
          </button>
        </div>
      </section>
      {/* handlePrint */}
      <div className="d-none">
        <PosMultiplePaymentSlip ref={componentRef} salesSlipData={data} />
      </div>
    </>
  );
};

export default ViewInvoiceInformationTableSection;
