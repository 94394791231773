import React from 'react';

const TransferListPagination = () => {
 
 const btnStyle = {
		height: '40px',
		width: '40px',
		borderRadius: '50%',
		padding: '8px',
		background: 'transparent',
};


 return (
  <>
   <div>
        <nav className=' d-flex justify-content-end align-items-center px-2 col-12 text-black-50 fs-6 bg-white border-top-1'>
          <span className=' mx-2 my-0 flex-shrink-1 user-select-none'>
            Rows per page:
          </span>
          <div className=' position-relative flex-shrink-0 mt-1 d-flex '>
            <select
              aria-label='Rows per page:'
              className='sc-bXCLTC eVrcSZ cs-cursor-pointer user-select-none ps-2 pe-4  '
              style={{
                height: '25px',
                maxWidth: '100%',
                color: '#000000',
                fontSize: 'inherit',
                border: 'none',
                background: 'transparent',
                appearance: 'none',
                direction: 'ltr',
              }}>
              <option
                value={10}
                selected>
                10
              </option>
              <option value={15}>15</option>
              <option value={20}>20</option>
              <option value={25}>25</option>
              <option value={30}>30</option>
            </select>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width={24}
              height={24}
              viewBox='0 0 24 24'
              className='position-absolute top-0'
              style={{ right: 0 }}>
              <path d='M7 10l5 5 5-5z' />
              <path
                d='M0 0h24v24H0z'
                fill='none'
              />
            </svg>
          </div>
          <span className='mx-4 user-select-none flex-shrink-1'>1-2 of 2</span>
          <div className='sc-bmzYkS fimDOL d-flex align-items-center rounded-1 white-space-none'>
            <button
              id='pagination-first-page'
              type='button'
              aria-label='First Page'
              aria-disabled='true'
              disabled
              className='position-relative d-block user-select-none border-0 rounded-1 cs-cursor-pointer'
              style={btnStyle}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width={24}
                height={24}
                viewBox='0 0 24 24'
                aria-hidden='true'
                role='presentation'>
                <path d='M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z' />
                <path
                  fill='none'
                  d='M24 24H0V0h24v24z'
                />
              </svg>
            </button>
            <button
              id='pagination-previous-page'
              type='button'
              aria-label='Previous Page'
              aria-disabled='true'
              disabled
              className='position-relative d-block user-select-none border-0 rounded-1 cs-cursor-pointer'
              style={btnStyle}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width={24}
                height={24}
                viewBox='0 0 24 24'
                aria-hidden='true'
                role='presentation'>
                <path d='M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z' />
                <path
                  d='M0 0h24v24H0z'
                  fill='none'
                />
              </svg>
            </button>
            <button
              id='pagination-next-page'
              type='button'
              aria-label='Next Page'
              aria-disabled='true'
              disabled
              className='position-relative d-block user-select-none border-0 rounded-1 cs-cursor-pointer'
              style={btnStyle}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width={24}
                height={24}
                viewBox='0 0 24 24'
                aria-hidden='true'
                role='presentation'>
                <path d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z' />
                <path
                  d='M0 0h24v24H0z'
                  fill='none'
                />
              </svg>
            </button>
            <button
              id='pagination-last-page'
              type='button'
              aria-label='Last Page'
              aria-disabled='true'
              disabled
              className='position-relative d-block user-select-none border-0 rounded-1 cs-cursor-pointer'
              style={btnStyle}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width={24}
                height={24}
                viewBox='0 0 24 24'
                aria-hidden='true'
                role='presentation'>
                <path d='M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z' />
                <path
                  fill='none'
                  d='M0 0h24v24H0V0z'
                />
              </svg>
            </button>
          </div>
        </nav>
      </div>
  </>
 );
};

export default TransferListPagination;