


import { FaBuilding } from "react-icons/fa";
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Card, Container, FormGroup, FormText, Label, Row, Table } from 'reactstrap';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Link } from 'react-router-dom';
import TableLoading from '../../../components/common/loading/TableLoading';
import Papa from 'papaparse';
import axios from '../../../util/axiosInstance';
import PosDropdownSection from "../../SalesManagement/Pos/PosDropdownSection";
import useGetData from "../../../hooks/useGetData";
import { CustomToast } from "../../../hooks/useCustomToast";
import { BsFiletypeCsv } from "react-icons/bs";
const ImportItems = () => {
  const [isLoading, setIsLoading] = useState(true);
  // get data from backend

  const [branchData, setBranchData] = useState([])

  // const [branchData, branchRefetch, isLoading] = useGetData(
  //   "/branch/viewbranch",
  //   "branch"
  // );


  useEffect(() => {
    axios.get('/branch/viewbranch')
      .then(result => {
        setBranchData(result?.data)
        setIsLoading(false)
      })
      .catch(error => { })
  }, [])

  const [inputData, setInputData] = useState([]);
  const [tableData, setTableData] = useState([])
  const fileInputRef = useRef(null);


  // const firstBranch = branchData && branchData[0]?.warehouse_name;
  // const fistBranchId = branchData && branchData[0]?.id;

  const [wareHouseDropdownValue, setWarehouseDropdownValue] = useState("");
  const [selectedItemData, setSelectedItemData] = useState([]);
  const [wareHouseDropdownId, setWarehouseDropdownId] = useState("");


  // csv submit 

  const handleWarehouseSubmit = async (e) => {
    if (inputData) {
      Papa.parse(inputData, {
        complete: async (result) => {
          // Modify each entry to include warehouse_id
          const items = result?.data?.map(entry => ({
            ...entry,
          }));
          items.pop()
          // Constructing the desired payload
          const payload = {
            warehouse_id: wareHouseDropdownId,
            items: items
          };
          setTableData(items)

          const chunkSize = 50;
          const chunkedData = [];

          for (let i = 0; i < tableData?.length; i += chunkSize) {
            const chunk = tableData?.slice(i, i + chunkSize);
            chunkedData?.push(chunk);
          }

          if (result?.data) {
            for (const chunk of chunkedData) {
              try {
                const res = await axios.post('/item/create-items', { warehouse_id: wareHouseDropdownId, items: chunk });
                CustomToast('success', 'Oh Yeah! You Did Amazing');
              } catch (error) {
                CustomToast('error', 'Oops! Bad Luck Try Again');
              }
            }
          }
        },
        header: true
      });
    }
  }




  // csv download 
  // const handleDownload = () => {
  //   const csvString = Papa.unparse(data);
  //   const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
  //   const url = URL.createObjectURL(blob);

  //   const link = document.createElement('a');
  //   link.href = url;
  //   link.setAttribute('download', 'download.csv');
  //   link.click();

  //   URL.revokeObjectURL(url);
  // };

  // useEffect(() => {
  //   setWarehouseDropdownValue(firstBranch);
  //   setWarehouseDropdownId(fistBranchId);
  // }, [firstBranch, fistBranchId]);

  const handleIconClick = () => {
    fileInputRef.current.click();  // trigger the click event of the hidden file input
  };

  const showCsv = () => {
    if (inputData?.size) {
      if (wareHouseDropdownId) {
        Papa.parse(inputData, {
          complete: (result) => {
            // Modify each entry to include warehouse_id
            const items = result?.data?.map(entry => ({
              ...entry,
            }));
            items.pop()
            // Constructing the desired payload
            setTableData(items)
          },
          header: true
        });
      } else {
        CustomToast('error', 'Select Branch');
      }
    } else {
      CustomToast('error', 'Please Select File')
    }
  };


  return (
    <Fragment>
      <Breadcrumb
        title='Import Items '
        parent='Product Management'
      />
      <Container fluid={true}>
        <Card className='p-2'>
          <div
            style={{ width: '50%' }}
            className=' d-flex justify-content-between align-items-center border border rounded-1 '
            title='Warehouse'>
            <span className=' px-2'>
              <FaBuilding className='text-danger'></FaBuilding>
            </span>
            {/* dropdown data */}
            {/* {firstBranch && ( */}
            <PosDropdownSection
              borderEnd={'warehouse'}
              inputType={'search'}
              InitialDropdownValue={'All Branches'}
              setWarehouseDropdownValue={setWarehouseDropdownValue || ''}
              data={branchData || []}
              titleData={'Warehouse'}
              placeHolderData={'Enter Branch Name'}
              dataField={'warehouse_name'}
              setSelectedItemData={setSelectedItemData || []}
              setWarehouseDropdownId={
                setWarehouseDropdownId
              }></PosDropdownSection>
            {/* )} */}
          </div>


          <div className='col-md-6 col-sm-12 '>
            <FormGroup>
              <Label
                for='exampleName'
                className='fw-bold mt-2'>
                Import Items <span className='text-danger'>*</span>
              </Label>
              <div className='my-2 d-flex gap-3 align-items-center'>
                <BsFiletypeCsv
                  style={{ fontSize: '30px', cursor: 'pointer' }}
                  onClick={handleIconClick}
                />
                {/* <i className="fa fa-camera"  ></i> */}
                <span className='name'>{inputData?.name}</span>
                <input
                  name="file"
                  type='file'
                  ref={fileInputRef}
                  style={{ display: 'none' }} // hide the input element
                  onChange={(e) => setInputData(e.target.files[0])}
                />
              </div>

              {/* <input required className="form-control"

                  type='file'
                  style={{ display: 'none' }}
                  onChange={(e) => setInputData(e.target.files[0])}
                /> */}
              <FormText></FormText>
              <span className='text-danger'>
                Note: File must be in CSV format.
              </span>
            </FormGroup>
          </div>

          <div className='row'>
            <div className=' d-flex align-items-center justify-content-center mt-5 gap-5'>
              <div
                onClick={showCsv}
                style={{
                  cursor: 'pointer',
                  backgroundColor: '#192655',
                  border: 'none',
                  color: 'white',
                  padding: '6px 28px',
                  borderRadius: '5px',
                }}
              >
                Show Csv
              </div>
              {tableData?.length ? <input
                type='submit'
                value={'import'}
                onClick={handleWarehouseSubmit}
                style={{
                  backgroundColor: '#00A65A',
                  border: 'none',
                  color: 'white',
                  padding: '6px 28px',
                  borderRadius: '5px',
                }}
              /> : ''}
              <Link
                to={'/dashboard'}
                style={{
                  backgroundColor: '#F39C12',
                  border: 'none',
                  color: 'white',
                  padding: '6px 28px',
                  borderRadius: '5px',
                  textDecoration: 'none',
                }}>
                Close
              </Link>
            </div>
          </div>


          <div className='mt-5 mb-2 d-flex align-items-center justify-content-between'>
            <span></span>
            <span
              // onClick={handleDownload}
              style={{
                backgroundColor: '#00C0EF',
                padding: '10px',
                borderRadius: '5px',
                color: 'white',
                cursor: 'pointer',
              }}>
              {' '}
              <a
                href='https://drive.google.com/uc?id=1FXAFuuzoxdlGbE6GqR4s-8-n9vPKQK41&export=download'
                style={{ textDecoration: 'none', color: 'white' }}>
                {' '}
                Download Example{' '}
              </a>{' '}
            </span>
          </div>

          <div
            style={{
              backgroundColor: '#F2F2F2',
              marginTop: '15px',
              borderRadius: '5px',
              padding: '10px',
            }}>
            {tableData?.length > 0 && (
              <div style={{ maxHeight: '400px', overflowY: 'auto', width:'100%', overflowX: 'auto' }}>
                <Table
                  className="w-100"
                  bordered
                  hover
                  striped>
                  <thead className='text-center'>
                    <tr>
                      {Object?.keys(tableData[0])?.map((key, i) => (
                        <th key={i}>{key}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className='text-center'
                  style={{ maxHeight: '400px',  overflowY: 'auto', overflowX: 'auto' }}
                  >
                    {isLoading && (
                      <>
                        <TableLoading
                          noTd={tableData[0]?.length}
                          noRow={10}
                        />
                      </>
                    )}
                    {/* {
                  !isLoading && (
                    data?.sort((a, b) => b?.id - a?.id).map((value, index) => (
                      <tr key={index}>
                        <td>{value}</td>
                      </tr>
                    ))
                  )
                } */}
                    {tableData?.map((row, index) => (
                      <tr key={index}>
                        {Object?.values(row)?.map((value) => (
                          <td key={value}>{value}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}
          </div>
        </Card>
      </Container>
    </Fragment>
  );
};

export default ImportItems;