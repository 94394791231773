import React from "react";

const NoDataFoundTr = ({ colSpanNumber }) => {
  return (
    <tr>
      <td
        className="text-center"
        colSpan={colSpanNumber}
        style={{
          margin: "10px",
          fontSize: "30px",
          color: "red",
          textTransform: "uppercase",
          fontWeight: "bolder",
        }}
      >
        You haven't created any Data!
      </td>
    </tr>
  );
};

export default NoDataFoundTr;
