import React, { Fragment, useState } from 'react'
import Breadcrumb from '../../../components/common/breadcrumb'
import { Button, Card, CardBody, CardHeader, Col } from 'reactstrap'
import { Link } from 'react-feather'


import TransferAccounts from '../MoneyTransfer/components/TransferAccounts'
import axios from '../../../util/axiosInstance'
import { useNavigate } from 'react-router-dom'

const NewMoneytransferScreen = () => {
    const [isCalenderOpen, setCalenderOpen] = useState(false)
    const [date, setDate] = useState(false)

    const [amount, setAmount] = useState(0);
    const [transferCode, setTransferCode] = useState('');
    const [DebitAcc, setDebitAcc] = useState(null);
    const [creditAcc, setCreditAcc] = useState(null);
    const navigate = useNavigate()




    const newMoneyTransferHandler = () => {
        const gdd = {
            "parent_id": 124,
            "account_name": creditAcc.account_name,
            "account_code": transferCode,
            "balance": parseFloat(amount),
            "note": "This is a sample account",
            "created_by": "user123",
            "created_date": "2023-09-13",
            "created_time": "14:30:00"
        }


        axios.post('/accounts/addaccount', gdd).then(res => {
            (res.status === 201 || res.status === 200) && navigate('/dashboard')

        })
    }


    return (
        <Fragment>
            <Breadcrumb
                title='Add transfer Account'
                parent='Account'
            />
            <Col sm='12'>
                <Card>
                    <CardHeader className='d-flex justify-content-between'>

                        <h5>Add new account</h5>
                        {/* <Link to='/accounts/money_transfer/add' className='bg-info py-2 text-decoration-none me-4 px-4 rounded-1'>+ New money Transfer </Link> */}
                    </CardHeader>


                    <CardBody>
                        <div>
                            <div className='d-flex py-4 py-2'>
                                {/* <SingleCalender setCalenderOpen={setCalenderOpen} isCalenderOpen={isCalenderOpen} setDate={setDate} date={date} /> */}
                                <input type='text' className='col-3 me-2' defaultValue={amount} onChange={(e) => setAmount(e.target.value)} placeholder='Transfer Amount * ' />
                                <input type='text' className='col-3' placeholder='Transfer Code * ' defaultValue={transferCode} onChange={(e) => setTransferCode(e.target.value)} />

                            </div>
                            <div className='d-flex py-4 py-2'>
                                <TransferAccounts setDebitAcc={setDebitAcc} setCreditAcc={setCreditAcc} DebitAcc={DebitAcc} creditAcc={creditAcc} />
                                <input type='text' className='col-3 ms-2' placeholder='Reference No ' />
                            </div>
                            <div className='d-flex col-10 mx-auto justify-content-center my-3'>
                                <Button className='btn-info mx-3' onClick={newMoneyTransferHandler}>save</Button>
                                <Button >cancel</Button>
                            </div>
                        </div>
                        {/* <div className='d-flex col-11'> */}
                        {/* <TransferAccounts setQueryData={setQueryData} />
                            <DateRangePickerSingle dateSelect={dateSelect} date={date} setCalenderOpen={setCalenderOpen} /> */}
                        {/* </div> */}

                        <div className='category-table order-table coupon-list-delete mt-4'>
                            {/* <DepositeListTable /> */}


                        </div>
                    </CardBody>
                </Card>
            </Col>

        </Fragment>
    )
}

export default NewMoneytransferScreen