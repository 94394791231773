import React from 'react';

const ShadowDiv = () => {
  return (
    <div
      style={{
        width: '90%',
        height: '15px',
        margin: '10px auto',
        background: '#f2f2f2',
      }}></div>
  );
};

export default ShadowDiv;
