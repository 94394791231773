import React, { forwardRef } from "react";
import PurchaseInvoiceHeader from "./PurchaseInvoiceHeader";
import PurchaseInvoiceTable from "./PurchaseInvoiceTable";
import PurchaseInvoiceFooter from "./PurchaseInvoiceFooter";
import PurchaseInvoiceBanner from "./PurchaseInvoiceBanner";

const PurchaseInvoice = forwardRef((props, ref) => {
  const { salesSlipData } = props;
console.log('majedul2',salesSlipData);
  return (
    <section className="bg-white p-3" ref={ref}>
      <PurchaseInvoiceHeader data={salesSlipData} />
      <PurchaseInvoiceBanner data={salesSlipData} />
      <PurchaseInvoiceTable data={salesSlipData} />
      <PurchaseInvoiceFooter data={salesSlipData} />
    </section>
  );
});

export default PurchaseInvoice;
