import React from "react";
import useNumberFormatter from "../../../../hooks/useNumberFormatter";
import { BsPrinterFill } from "react-icons/bs";

const ViewPaymentsTable = ({ product, sl }) => {
  const numericFormatter = useNumberFormatter();
  //   TODO:payment name change
  const { payment_date, payment, payment_type, payment_note } = product || {};
  return (
    <>
      <tr>
        <td>{sl + 1}</td>
        <td>{payment_date?.slice(0, 10) || "-"}</td>
        <td>{numericFormatter(payment) || 0}</td>
        <td>{payment_type || "-"}</td>
        <td>{"-"}</td>
        <td>{payment_note || "-"}</td>
        <td className="d-flex justify-content-center">
          <button title="Print" disabled className="btn btn-outline-primary btn-sm py-1 d-flex justify-content-center align-items-center">
            <BsPrinterFill className="fs-6 m-0"></BsPrinterFill>
          </button>
        </td>
      </tr>
    </>
  );
};

export default ViewPaymentsTable;
