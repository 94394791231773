import React from "react";
import { Table } from "reactstrap";
import TableComponents from "../../../components/DashboardComponents/TableComponents";
import useNumberFormatter from "../../../hooks/useNumberFormatter";
import TableLoading from "../../../components/common/loading/TableLoading";
import NoDataFoundTr from "../../../components/common/NoDataFoundTr";

const SalesListTableSection = ({
  tableHeader,
  tableData,
  showTable,
  setSubTotal,
  isLoading,
  tableStylePurchaseInvoice,
}) => {
  const numericFormatter = useNumberFormatter();
  // for sales table

  const totalPerUnitPrice = tableData?.reduce(
    (total, product) => total + product?.price_per_unit,
    0
  );
  const totalQuantity = tableData?.reduce(
    (total, product) => total + product?.sales_qty || product?.return_qty,
    0
  );
  const totalQuantityReturn = tableData?.reduce(
    (total, product) => total + product?.return_qty,
    0
  );
  const totalQuantityPurchase = tableData?.reduce(
    (total, product) => total + product?.purchase_qty,
    0
  );
  const totalItemTotalCost = tableData?.reduce(
    (total, product) => total + product?.item_total_cost,
    0
  );
  const totalDiscountAmount = tableData?.reduce(
    (total, product) => total + product?.item_discount_amt,
    0
  );
  const totalUnitTotalCost = tableData?.reduce(
    (total, product) => total + product?.item_unit_total_cost,
    0
  );
  const totalAmount = tableData?.reduce(
    (total, product) =>
      total + product?.item_total_cost || product?.unit_total_cost,
    0
  );
  const totalAmountReturn = tableData?.reduce(
    (total, product) => total + product?.unit_total_cost,
    0
  );
  const totalAmountPurchase = tableData?.reduce(
    (total, product) => total + product?.total_cost,
    0
  );
  const totalUnitCostPurchase = tableData?.reduce(
    (total, product) => total + product?.unit_total_cost,
    0
  );

  setSubTotal && setSubTotal(totalAmount);

  // for payment table
  const totalPayment = tableData?.reduce(
    (total, product) => total + product?.payment,
    0
  );
  return (
    <>
      <Table striped bordered className=" rounded-2 m-0">
        <thead>
          <tr>
            {tableHeader?.map((item, index) => (
              <th key={index} className="text-center">
                {item.name}
              </th>
            ))}
          </tr>
        </thead>
        {tableData?.length > 0 ? (
          <tbody className="text-center">
            {tableData?.map((product, index) => (
              <TableComponents
                key={index}
                product={product}
                sl={index}
                showTable={showTable}
              ></TableComponents>
            ))}
          </tbody>
        ) : isLoading ? (
          <TableLoading noRow={10} noTd={tableHeader?.length}></TableLoading>
        ) : (
          <NoDataFoundTr colSpanNumber={tableHeader?.length} />
        )}

        {/*for viewSales */}
        {tableData && showTable === "viewSales" && (
          <tfoot className="fw-700 text-center">
            <tr>
              <td colSpan={2}>Total</td>
              <td>{numericFormatter(totalPerUnitPrice) || 0}</td>
              <td>{numericFormatter(totalQuantity) || 0}</td>
              <td>{numericFormatter(totalItemTotalCost) || 0}</td>
              <td>{"-"}</td>
              <td>{numericFormatter(totalDiscountAmount) || 0}</td>
              <td>{numericFormatter(totalUnitTotalCost) || 0}</td>
              <td>{numericFormatter(totalAmount) || 0}</td>
            </tr>
          </tfoot>
        )}
        {/*for viewSales */}
        {tableData && showTable === "viewReturnSalesView" && (
          <tfoot className="fw-700 text-center">
            <tr>
              <td colSpan={3}>Total</td>
              <td>{numericFormatter(totalPerUnitPrice) || 0}</td>
              <td>{numericFormatter(totalQuantityReturn) || 0}</td>
              <td>{numericFormatter(totalAmountReturn) || 0}</td>
            </tr>
          </tfoot>
        )}
        {/*for viewPurchase */}
        {tableData && showTable === "viewPurchase" && (
          <tfoot className="fw-700 text-center">
            <tr>
              <td colSpan={3}>Total</td>
              <td>{numericFormatter(totalQuantityPurchase) || 0}</td>
              <td>{numericFormatter(totalPerUnitPrice) || 0}</td>
              <td>{numericFormatter(totalUnitCostPurchase) || 0}</td>
              <td>{numericFormatter(totalAmountPurchase) || 0}</td>
            </tr>
          </tfoot>
        )}

        {/* for view return item */}
        {tableData && showTable === "viewSalesPayment" && (
          <tfoot className="fw-700 text-center">
            <tr>
              <td colSpan={5}>Total</td>
              <td>{numericFormatter(totalPayment) || 0}</td>
            </tr>
          </tfoot>
        )}
      </Table>
    </>
  );
};

export default SalesListTableSection;
