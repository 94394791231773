import { Navigate, useLocation } from 'react-router-dom';

import useAuth from '../hooks/useAuth';

const PrivateRoute = ({ children }) => {
  const { userLogedIn, loading } = useAuth();
  const location = useLocation();
  if (loading) {
    return <progress className='progress col-12'></progress>;
  }
  if (userLogedIn) {
    return children;
  }
  return (
    <Navigate
      to='/login'
      state={{ from: location }}
      replace></Navigate>
  );
};

export default PrivateRoute;
