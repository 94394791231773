
import React, { useState } from "react";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const SupplierModal = ({ supplierToggleButtonClick, supplierModal, setSupplierModal }) => {
  
  const [supplierName, setSupplierName] = useState('')
  const [mobileNumber, setMobileNumber] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [supplierEmail, setSupplierEmail] = useState('');
  const [supplierGsnNumber, setSupplierGsnNumber] = useState('');
  const [taxNumber, setTaxNumber] = useState('');
  const [openingBalance, setOpeningBalance] = useState('');
  const [country, setCountry] = useState('Select Country');
  const [state, setState] = useState('Select State');
  const [city, setCity] = useState('');
  const [postCode, setPostCode]= useState('');
  const [address, setAddress] = useState('');

  const handleSaveData = ()=>{

  
    
    setSupplierModal(!supplierModal)
  }




  return (
    <Modal
      isOpen={supplierModal}
      supplierToggleButtonClick={supplierToggleButtonClick}
      size="lg"
      style={{ maxWidth: "50%" }}
    >
      <ModalHeader
        className="d-flex justify-content-center  align-items-center"
        style={{
          backgroundImage: "linear-gradient(to right, #4b0ab3, #090080)", // Gradient background
        }}
      >
        <p className="text-white w-100 m-0 fw-bold fs-5">New Supplier</p>
      </ModalHeader>
      <form >
      <ModalBody>
        <div className=" gap-2 d-flex justify-content-between align-items-center">
          <FormGroup style={{ width: "48%" }}>
            <Label htmlFor="customerName" className="mb-1 fw-bold">
              Supplier Name
            </Label>
            <input
              type="text"
              name="name"
              className="text-input cs-text-input border"
              id="customerName"
              placeholder=" "
              onChange={(e) => setSupplierName(e.target.value)}
            />
            
          </FormGroup>
          <FormGroup style={{ width: "48%" }}>
            <Label htmlFor="phoneNumber" className="mb-1 fw-bold">
              Mobile
            </Label>
            <input
              type="number"
              step='any'
              name="mobile_number"
              id="phoneNumber"
              className="text-input cs-text-input border"
              placeholder="+123456789"
              onChange={(e) => setMobileNumber(e.target.value)}
            />
            
          </FormGroup>
          <FormGroup style={{ width: "48%" }}>
            <Label htmlFor="phoneNumber" className="mb-1 fw-bold">
              Phone Number
            </Label>
            <input
              type="text"
              name="phone_number"
              id="phoneNumber"
              className="text-input cs-text-input border"
              placeholder=" phone number"
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            
          </FormGroup>
        </div>

        <div className=" gap-2 d-flex justify-content-between align-items-center">
          <FormGroup style={{ width: "48%" }}>
            <Label htmlFor="customerName" className="mb-1 fw-bold">
              Email
            </Label>
            <input
              type="text"
              name="email"
              className="text-input cs-text-input border"
              id="customerName"
              placeholder=" "
              onChange={(e) => setSupplierEmail(e.target.value)}
            />
            
          </FormGroup>
          <FormGroup style={{ width: "48%" }}>
            <Label htmlFor="Gsn Number" className="mb-1 fw-bold">
              GST Number
            </Label>
            <input
              type="text"

              name="gsn_number"
              id="gsn_number"
              className="text-input cs-text-input border"
              placeholder=" "
              onChange={(e) => setSupplierGsnNumber(e.target.value)}
            />
            
          </FormGroup>
          <FormGroup style={{ width: "48%" }}>
            <Label htmlFor="Tax Number" className="mb-1 fw-bold">
              TAX Number
            </Label>
            <input
              type="text"
              name="tax_number"
              id="tax_number"
              className="text-input cs-text-input border"
              placeholder=" "
              onChange={(e) => setTaxNumber(e.target.value)}
            />
            
          </FormGroup>
        </div>
        <div className=" gap-2 d-flex justify-content-between align-items-center">
          <FormGroup style={{ width: "48%" }}>
            <Label htmlFor="customerName" className="mb-1 fw-bold">
              Opening Balance
            </Label>
            <input
              type="number"
              step='any'
              name="opening_balance"
              className="text-input cs-text-input border"
              id="customerName"
              placeholder=" "
              onChange={(e) => setOpeningBalance(e.target.value)}
            />
           
          </FormGroup>
          <FormGroup style={{ width: "48%" }}>
            <Label htmlFor="country" className="mb-1 fw-bold">
              country
            </Label>


            <UncontrolledDropdown className=" w-100">
              <DropdownToggle
                className="border w-100"
                caret
                color="white"
                style={{ backgroundColor: '#FFFFFF' }}
              >
                {country }
              </DropdownToggle>
              <DropdownMenu className="text-center w-100">
                {/* {
                  allCountry?.map(countries => ( */}
                <DropdownItem
                  // key={countries.id}
                  // onClick={(e) => setCountry(country.name)}
                  onClick={(e) => setCountry('bangladesh')}
                >
                  {/* {countries?.name} */}
                  Bangladesh
                </DropdownItem>
                {/* ))
                } */}

              </DropdownMenu>
            </UncontrolledDropdown>



          </FormGroup>
          <FormGroup style={{ width: "48%" }}>
            <Label htmlFor="state" className="mb-1 fw-bold">
              State
            </Label>

            <UncontrolledDropdown className=" w-100">
              <DropdownToggle
                className="border w-100"
                caret
                color="white"
                style={{ backgroundColor: '#FFFFFF' }}
              >
                {state }
              </DropdownToggle>
              <DropdownMenu className="text-center w-100">
                {/* {
                  allCountry?.map(countries => ( */}
                <DropdownItem
                  // key={countries.id}
                  // onClick={(e) => setCountry(country.name)}
                  onClick={(e) => setState('Dhaka')}
                >
                  {/* {countries?.name} */}
                  Dhaka
                </DropdownItem>
                {/* ))
                } */}

              </DropdownMenu>
            </UncontrolledDropdown>

          </FormGroup>
        </div>
        <div className=" gap-2 d-flex justify-content-between align-items-center">
          <FormGroup style={{ width: "48%" }}>
            <Label htmlFor="city" className="mb-1 fw-bold">
              City
            </Label>
            <input
              type="text"
              name="city"
              className="text-input cs-text-input border"
              id="city"
              placeholder=" "
              onChange={(e)=>setCity(e.target.value)}
            />
          
          </FormGroup>
          <FormGroup style={{ width: "48%" }}>
            <Label htmlFor="postcode" className="mb-1 fw-bold">
              Postcode
            </Label>
            <input
              type="text"

              name="postcode"
              id="postcode"
              className="text-input cs-text-input border"
              placeholder=" "
              onChange={(e)=>setPostCode(e.target.value)}
            />
          
          </FormGroup>
          <FormGroup style={{ width: "48%" }}>
            <Label htmlFor="address" className="mb-1 fw-bold">
              Address
            </Label>
            <textarea
        
             onChange={(e)=>setAddress(e.target.value)}
             name="address" id="" cols="30"></textarea>
          </FormGroup>
        </div>

      </ModalBody>
      <div className="modal-footer border-0">
        <Button type="reset" onClick={supplierToggleButtonClick}>
          Cancel
        </Button>
        <Button
          style={{ backgroundColor: "green" }}
         
          type="submit"
          onClick={handleSaveData}
        >
          Save
        </Button>{" "}

      </div>
      </form>
    </Modal>
  );
};

export default SupplierModal;