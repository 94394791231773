import { useQuery } from "@tanstack/react-query";

import useAxiosSecure from "./useAxiosSecure";

const useAlertQty = () => {
  const [axiosSecure] = useAxiosSecure();

  const {
    refetch,
    data: alert = [],
    isLoading,
  } = useQuery({
    queryKey: ["alerts"],

    queryFn: async () => {
      const res = await axiosSecure(`/item/items-with-notifications`);
      return res.data;
    },
  });

  return [alert, refetch, isLoading];
};
export default useAlertQty;
