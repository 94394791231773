import React, { useState } from "react";
import { Maximize2 } from "react-feather";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/dashboard/logo.png";
import { FaListUl, FaHandPaper } from "react-icons/fa";
import { BsPeopleFill, BsCalculator } from "react-icons/bs";
import { TfiLayoutListThumbAlt } from "react-icons/tfi";
import { AiFillDashboard } from "react-icons/ai";
import man from "../../../assets/images/dashboard/man.png";
import { Button } from "reactstrap";
import useAuth from "../../../hooks/useAuth";

const HeaderTwo = () => {
  const { logOut } = useAuth();
  const [isShowLogout, setIsShowLogout] = useState(false);

  const goFull = () => {
    if (
      (document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  };
  return (
    <div className="d-flex justify-content-between align-items-center col-11 mx-auto py-3  ">
      <div className=" col-7 d-flex justify-content-between align-items-center">
        <div className="col-3">
          <Link to={"/dashboard"}>
            <img className="img-fluid" src={logo} alt="" />
          </Link>
        </div>
        <div className=" col-8 d-md-flex justify-content-between d-none ">
          <Link
            className="fw-bolder text-dark"
            style={{ textDecoration: "none" }}
            to={"/sales-management/sales-list"}
          >
            <FaListUl className="me-1" />
            Sales List
          </Link>
          <Link
            className="fw-bolder text-dark"
            style={{ textDecoration: "none" }}
            to={"/customer-list"}
          >
            <BsPeopleFill className="me-1" />
            Customers List
          </Link>
          <Link
            className="fw-bolder text-dark"
            style={{ textDecoration: "none" }}
            to={"/product-management/items-list"}
          >
            <TfiLayoutListThumbAlt className="me-1" />
            Items List
          </Link>
          <Link
            className="fw-bolder text-dark"
            style={{ textDecoration: "none" }}
            target="_blank"
            to={"/sales-management/pos"}
          >
            <BsCalculator className="me-1" />
            New Invoice
          </Link>
        </div>
      </div>
      <div className=" col-3 d-flex justify-content-between align-items-center">
        <div className="fw-bolder text-dark d-none  d-md-block ">
          {" "}
          <FaHandPaper className="me-1" /> Hold List
        </div>
        <div className=" d-none  d-md-block ">
          <a onClick={goFull} className="text-dark" href="#javaScript">
            <Maximize2 />
          </a>
        </div>
        <div>
          <Link
            className="fw-bolder text-dark"
            to={"/dashboard"}
            style={{ textDecoration: "none" }}
          >
            <AiFillDashboard className="me-1" />
            Dashboard
          </Link>
        </div>
        <div
          className="position-relative"
          onMouseOut={() => setIsShowLogout(false)}
          onMouseOver={() => setIsShowLogout(true)}
        >
          <div className="media align-items-center">
            <img
              className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded"
              src={man}
              alt="header-user"
            />
            <div className="dotted-animation">
              <span className="animate-circle"></span>
              <span className="main-circle"></span>
            </div>
          </div>
          {isShowLogout && (
            <>
              <ul
                style={{ marginLeft: "-80px" }}
                className="profile-dropdown p-3 rounded-3 d-flex flex-column  position-absolute bg-white gap-3 shadow-lg"
              >
                <li>
                  <Link
                    className="text-decoration-none"
                    to={`${process.env.PUBLIC_URL}/users-list`}
                  >
                    <i data-feather="user"></i>Edit Profile
                  </Link>
                </li>
                <li>
                  <Button
                    className="bg-navy"
                    onClick={logOut}
                    to={`${process.env.PUBLIC_URL}/`}
                  >
                    <i data-feather="log-out"></i>Logout
                  </Button>
                </li>
              </ul>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeaderTwo;
