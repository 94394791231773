import React, { useEffect, useState, forwardRef } from 'react';
import ListLabelWithBarcode from './ListLabelWithBarcode';
import axios from '../../../../util/axiosInstance';

const BarcodeLabel = forwardRef((props, ref) => {
  const { item } = props;
  const [branch, sertBranch] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('access-token');

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    axios.get('/branch/viewbranch/124', { headers }).then((x) => {
      sertBranch(x.data);
    });
  }, []);

  return (
    <div
      className='container '
      style={{ width: '2in', background: "#f8f8f9" , color: "black", paddingLeft: "0px", paddingRight: "0px", lineHeight: "1", }}
      ref={ref}>
      {Array.from({ length: item?.itemToPrint }, (_, index) => (
        // <ListLabelWithBarcode
        //   ref={ref}
        //   item={item}
        //   branch={branch}
        //   key={index}
        
        // />

        <div
          className='print-barcode d-flex justify-content-center align-items-center flex-column'
          style={{
            height: '1.5in',
            width: '2in',
            background: 'white',
            paddingTop: '5px',
            border: "1px dotted black",
            

          }}>
          <h3
            style={{
              fontSize: '15px',
              fontWeight: 'bold',
              color: 'black',
              textTransform: 'uppercase',
          
       
            }}>
            {branch.warehouse_name}
          </h3>
          <div className=''>
            <span
              style={{
                fontSize: '12px',
                fontWeight: 'bold',
                color: 'black',
                // padding: "2px",
                textTransform: "uppercase"
                // marginRight: '80px',
              }}>
              {' '}
              {item.item_name}
            </span>{' '}
            <p
              style={{ fontSize: '13px', color: 'black',  margin: '0px', }}>
              {' '}
              <span style={{ fontSize: '13px', fontWeight: 'bold', color: 'black' }}> Price: </span> {item.sales_price}
              <span
                style={{
                  fontSize: '10px',
                  fontWeight: '900',
                  color: 'black',
                  marginLeft: '-2px',
                }}>
                {' '}
                ৳
                <span
              style={{
                fontSize: '10px',
                fontWeight: 'bold',
                color: 'black',
               
              }}>
              {' '}
              Inc. Vat{' '}
            </span>
              </span>{' '}
            </p>
          </div>
          <img
            style={{ height: '25%', width: '150px' }}
            src={`https://fancymalabis.apodigi.com/item/generateBarcode/${item.id}`}
            alt='kjkl'
          />
          <p className='text-right'>
            
          </p>
        </div>
      ))}
    </div>
  );
});

export default BarcodeLabel;
