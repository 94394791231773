import React, { Fragment } from "react";
import { ShoppingBag, Download, AlertCircle, Bell } from "react-feather";
import { Link } from "react-router-dom";
import { Media } from "reactstrap";

const Notification = ({ alert }) => {
  return (
    <Fragment>
      <ul className="notification-dropdown onhover-show-div p-0">
        <li>Notification </li>
        {alert?.map((item) => (
          <Link
            to={"/product-management/alert-qty"}
            className="text-dark w-100 h-100 d-inline-block  py-1 px-4 text-start cs-text-decoration-none bg-gray-two-hover2"
            key={item.id}
          >
            <Media>
              <Media body>
                <h6 className="mt-0 d-flex gap-2 align-items-center">
                  <span>
                    <Bell />
                  </span>
                  {item?.item_name} is going to be out of stock
                </h6>
                <p className="mb-0">
                  only {item.stock}
                  {item?.unit_name} left
                </p>
              </Media>
            </Media>
          </Link>
        ))}
        <Link
          className="text-dark w-100 h-100 d-inline-block  py-3 px-4 text-start cs-text-decoration-none bg-gray-two-hover2"
          to={"/product-management/alert-qty"}
        >
          All notification
        </Link>
      </ul>
    </Fragment>
  );
};

export default Notification;
