import React from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import AdjustmentInvoice from './AdjustmentInvoice';
import { useParams } from "react-router";
import useGetData from '../../../hooks/useGetData';
import ViewAdjustmentInvoiceInformationTableSection from './ViewAdjustmentInvoiceInformationTableSection';


const AdjustmentListView = () => {

 const { id } = useParams();
  const [adjustmentProduct, adjustmentProductRefetch] = useGetData(
    `/adjustment/stock-adjustments/${id}`,
    "adjustmentProduct"
  );

 return (
  <>
   <Breadcrumb title="Invoice" parent="Stock Management" />
   <main className=" w-100 p-3 bg-white rounded-2">
    
    <AdjustmentInvoice data={adjustmentProduct}></AdjustmentInvoice>
    <ViewAdjustmentInvoiceInformationTableSection data={adjustmentProduct?.items}></ViewAdjustmentInvoiceInformationTableSection>
    
   </main>
  </>
 );
};

export default AdjustmentListView;