import React from "react";
import { FaBuilding } from "react-icons/fa";
import useGetData from "../../../hooks/useGetData";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { BiPlusMedical } from "react-icons/bi";
import PosDropdownSection from "../Pos/PosDropdownSection";
import SalesListTableSection from "../SalesList/SalesListTableSection";
import InputLoadingTwo from "../../../components/Loading/InputLoadingTwo";

const SalesReturnListInformationTableSection = ({
  salesReturnListData,
  salesReturnDataRefetch,
  salesReturnListIsLoading,
}) => {
  const salesTable = [
    {
      id: 10,
      name: "SL",
    },
    {
      id: 11,
      name: "Return Date",
    },
    {
      id: 12,
      name: "Sales Code",
    },
    {
      id: 13,
      name: "Return Code",
    },
    {
      id: 14,
      name: "Return Status",
    },
    {
      id: 15,
      name: "Customer Name",
    },
    {
      id: 16,
      name: "Total",
    },
    {
      id: 17,
      name: "Paid Amount",
    },
    {
      id: 18,
      name: "Payment Status",
    },
    {
      id: 19,
      name: "Action",
    },
  ];
  const [data, setData] = useState([]);

  // get data from backend
  const [branchData] = useGetData("/branch/viewbranch", "branch");
  const firstBranch = branchData && branchData[0]?.warehouse_name;
  const fistBranchId = branchData && branchData[0]?.id;
  const [wareHouseDropdownId, setWarehouseDropdownId] = useState("");
  const [wareHouseSearch, setWareHouseSearch] = useState("");
  const [wareHouseDropdownValue, setWarehouseDropdownValue] = useState("");
  const [selectedItemData, setSelectedItemData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [selectedValue, setSelectedValue] = useState(10);

  useEffect(() => {
    setWarehouseDropdownValue(firstBranch);
    setWarehouseDropdownId(fistBranchId);
  }, [firstBranch, fistBranchId]);

  // apply filters
  const applyFilters = () => {
    if (salesReturnListData) {
      let filteredArray = [...salesReturnListData];

      // Apply all branch filter
      if (wareHouseDropdownValue !== "All-Branch") {
        filteredArray = filteredArray.filter(
          (item) => item?.warehouse_id === wareHouseDropdownId
        );
      }

      // Apply search filter
      if (searchValue.trim() !== "") {
        filteredArray = filteredArray.filter((item) =>
          item?.customer_name
            ?.toLowerCase()
            .includes(searchValue?.toLowerCase())
        );
      }
      setData(filteredArray);
    }
  };

  useEffect(() => {
    applyFilters();
  }, [wareHouseDropdownId, salesReturnListData, searchValue]);
  return (
    <>
      {/* section-1 */}
      {!branchData ? (
        <>
          <InputLoadingTwo numSections={1} />
        </>
      ) : (
        <>
          <section className="d-flex justify-content-between align-items-center p-4 pb-0">
            <div
              style={{ width: "48%" }}
              className=" d-flex justify-content-between align-items-center border border rounded-1 "
              title="Warehouse"
            >
              <span className=" px-2">
                <FaBuilding className="text-danger"></FaBuilding>
              </span>
              {/* dropdown data */}
              {firstBranch && (
                <PosDropdownSection
                  borderEnd={"warehouse"}
                  inputType={"search"}
                  setInputValue={setWareHouseSearch}
                  InitialDropdownValue={"All Branches"}
                  setWarehouseDropdownValue={setWarehouseDropdownValue}
                  data={branchData}
                  titleData={"Warehouse"}
                  placeHolderData={"Enter Branch Name"}
                  dataField={"warehouse_name"}
                  setSelectedItemData={setSelectedItemData}
                  setWarehouseDropdownId={setWarehouseDropdownId}
                ></PosDropdownSection>
              )}
            </div>
            {/* div-2 */}
            <div
              style={{ width: "48%" }}
              className="d-flex  justify-content-end align-items-center "
            >
              <Link
                style={{
                  textDecoration: "none",
                  backgroundColor: "#093d86",
                }}
                to={"/sales-management/sales-list"}
                className="border rounded-2 text-white py-2 px-3 d-flex justify-content-center gap-1 align-items-center  fw-bold c-pos-button6  "
              >
                <BiPlusMedical className=""></BiPlusMedical> Create New
              </Link>
            </div>
          </section>
        </>
      )}

      {/* section-2 */}
      <section className="mt-5 mb-4 px-4">
        <div className="row">
          <div className="col-md-6 d-flex align-items-center">
            <span>Show</span>
            <select
              onChange={(e) => setSelectedValue(e.target.value)}
              className="mx-1"
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={500}>500</option>
              <option value={"allData"}>AllData</option>
            </select>
            <span>entries</span>
          </div>
          <div className="col-md-6 d-flex align-items-center justify-content-end">
            <div className="d-flex align-items-center gap-2 gap-2 w-50">
              <label htmlFor="search" className="fw-bold">
                Search:
              </label>
              <input
                type="text"
                id="search"
                className="cs-text-input border text-input"
                placeholder="Enter Customer Name"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
          </div>
        </div>
      </section>
      {/* section-3 */}
      <section
        className="mx-4 p-2 border rounded-2 bg-body-secondary "
        style={{ height: "437px", overflowY: "scroll", overflowX: "hidden" }}
      >
        <SalesListTableSection
          tableHeader={salesTable}
          showTable={"salesReturnList"}
          isLoading={salesReturnListIsLoading}
          tableData={
            selectedValue !== "allData" ? data?.slice(0, selectedValue) : data
          }
        ></SalesListTableSection>
      </section>
    </>
  );
};

export default SalesReturnListInformationTableSection;
