import React, { useState } from 'react'
import Breadcrumb from '../../components/common/breadcrumb'
import StockDropscomponents from './component/StockDropscomponents'
import { useNavigate } from 'react-router-dom'
import { Button } from 'reactstrap'
import StockListTable from './component/tables/stockTable/StockListTable'
import axios from '../../util/axiosInstance'

const StockReport = () => {
    const [isLotVisible, setLotVisible] = useState(false)
    const [isLoading, setLoading] = useState(true)
    const [dataToRander, setDataToRander] = useState(false)

    const navigate = useNavigate()
    const [queryData, setQueryData] = useState({
        warehouse_name: '',
        brand_name: '',
        category_name: '',
        lot_number: ""
    })



    const querySrr = !isLotVisible ? `/report/items?warehouse_name=${queryData.warehouse_name}&brand_name=${queryData.brand_name}&category_name=${queryData.category_name}` : `/report/filtered-items?warehouse_name=${queryData.warehouse_name}&brand_name=${queryData.brand_name}&category_name=${queryData.category_name}&lot_number=${queryData.lot_number}`

    const handleQueryCall = (link) => {
        axios.get(link).then(response => {
            setQueryData({
                warehouse_name: '',
                brand_name: '',
                category_name: '',
                lot_number: ""
            });
            setDataToRander(() => !link.includes('filtered-items') ? response.data.items : response.data)
            setLoading(false)
        })
    }


    const filterhandler = (filterBy) => {
        if (filterBy === 'lot') {
            setLotVisible(true)
            setLoading(true)

            handleQueryCall(`/report/filtered-items?warehouse_name=${queryData.warehouse_name}&brand_name=${queryData.brand_name}&category_name=${queryData.category_name}&lot_number=${queryData.lot_number}`)

            return
        }
        if (filterBy === 'brand') {
            setLoading(true)

            setLotVisible(false)

            return

        }
        if (filterBy === 'all') {
            setLotVisible(false)
            setLoading(true)
            handleQueryCall("/report/items")
        }
    }

    return (
        <>
            <Breadcrumb
                title='Stock Report'
                parent='reports'
            />
            {/* dropdown comp */}
            <StockDropscomponents setQueryData={setQueryData} queryData={queryData} isLotVisible={isLotVisible} />

            {/* btn conntainer */}
            <div className='btn-container d-flex col-12  justify-content-center my-3 '  >
                <Button onClick={() => handleQueryCall(querySrr)} > show </Button>
                <Button className="btn btn-info ms-3" onClick={() => navigate('/')}  > cancel </Button>
            </div>

            <div className='d-flex '>
                <h4 className='mx-1 rounded-1 bg-primary px-5 py-2 cs-cursor-pointer' onClick={() => filterhandler('all')} >All items</h4>
                <h4 className='mx-1 rounded-1 bg-primary px-5 py-2 cs-cursor-pointer' onClick={() => filterhandler('brand')} >Items by Brand </h4>
                {/* <h4 className='mx-1 rounded-1 bg-primary px-5 py-2 cs-cursor-pointer' onClick={() => filterhandler('lot')} >Items by Lot</h4> */}

            </div>
            {
                dataToRander.length > 0 && <StockListTable dataToRander={dataToRander} isLoading={isLoading} />
            }



        </>
    )
}

export default StockReport