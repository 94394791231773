import React, { Fragment, useState } from 'react';
import { Card, Container, FormGroup, Table } from 'reactstrap';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Link, useNavigate } from 'react-router-dom';
import { CustomToast } from '../../../hooks/useCustomToast';
import axios from '../../../util/axiosInstance';

const AddRoles = () => {
   const navigate = useNavigate();

   const [roleName, setRoleName] = useState('');
   const [description, setDescription] = useState('');
   const [permissions, setPermissions] = useState([
      {
         id: 1,
         module: 'Users',
         selectAll: false,
         specificPermissions: {
            Add: false,
            Edit: false,
            delete: false,
            view: false,
         },
      },
      {
         id: 2,
         module: 'Roles',
         selectAll: false,
         specificPermissions: {
            Add: false,
            Edit: false,
            delete: false,
            view: false,
         },
      },
      {
         id: 3,
         module: 'Tax',
         selectAll: false,
         specificPermissions: {
            Add: false,
            Edit: false,
            delete: false,
            view: false,
         },
      },
      {
         id: 4,
         module: 'Units',
         selectAll: false,
         specificPermissions: {
            Add: false,
            Edit: false,
            delete: false,
            view: false,
         },
      },
      {
         id: 5,
         module: 'Payment Types',
         selectAll: false,
         specificPermissions: {
            Add: false,
            Edit: false,
            delete: false,
            view: false,
         },
      },
      {
         id: 6,
         module: 'Warehouse',
         selectAll: false,
         specificPermissions: {
            Add: false,
            Edit: false,
            delete: false,
            view: false,
         },
      },
      {
         id: 7,
         module: 'Store(Own Store)',
         selectAll: false,
         specificPermissions: {
            edit: false,
         },
      },
      {
         id: 8,
         module: 'Dashboard',
         selectAll: false,
         specificPermissions: {
            'View Dashboard Data': false,
            'Financial Report box': false,
            'Sales Report graph': false,
            'Purchase And Sales Chart': false,
            'Recently Added Items List': false,
            'Stock Alert List': false,
            'Trending Items Chart': false,
            'Recent Sales Invoice List': false,
         },
      },
      {
         id: 9,
         module: 'Accounts',
         selectAll: false,
         specificPermissions: {
            'Add': false,
            'Edit': false,
            'Delete': false,
            'View': false,
            'Add Money Deposit': false,
            'Edit Money Deposit': false,
            'Delete Money Deposit': false,
            'View Money Deposit': false,
            'Add Money Transfer': false,
            'Edit Money Transfer': false,
            'Delete Money Transfer': false,
            'View Money Transfer': false,
            'Cash Transactions': false,
         },
      },
      {
         id: 10,
         module: 'Expense',
         selectAll: false,
         specificPermissions: {
            'Add': false,
            'Edit': false,
            'Delete': false,
            'View': false,
            'Category Add': false,
            'Category Edit': false,
            'Category Delete': false,
            'Category View': false,
            'Show all users Expenses': false,
         },
      },
      {
         id: 11,
         module: 'Items',
         selectAll: false,
         specificPermissions: {
            'Add': false,
            'Edit': false,
            'Delete': false,
            'View': false,
            'Category Add': false,
            'Category Edit': false,
            'Category Delete': false,
            'Category View': false,
            'Print Labels': false,
            'Import Items': false,
            'Import Services': false,
         },
      },
      {
         id: 12,
         module: 'Services',
         selectAll: false,
         specificPermissions: {
            'Add': false,
            'Edit': false,
            'Delete': false,
            'View': false,
         },
      },
      {
         id: 13,
         module: 'Stock Transfer',
         selectAll: false,
         specificPermissions: {
            'Add': false,
            'Edit': false,
            'Delete': false,
            'View': false,
         },
      },
      {
         id: 14,
         module: 'Stock Adjustment',
         selectAll: false,
         specificPermissions: {
            'Add': false,
            'Edit': false,
            'Delete': false,
            'View': false,
         },
      },
      {
         id: 15,
         module: 'Brand',
         selectAll: false,
         specificPermissions: {
            'Add': false,
            'Edit': false,
            'Delete': false,
            'View': false,
         },
      },
      {
         id: 16,
         module: 'Variant',
         selectAll: false,
         specificPermissions: {
            'Add': false,
            'Edit': false,
            'Delete': false,
            'View': false,
         },
      },
      {
         id: 17,
         module: 'Suppliers',
         selectAll: false,
         specificPermissions: {
            'Add': false,
            'Edit': false,
            'Delete': false,
            'View': false,
            'Import Suppliers': false,
         },
      },
      {
         id: 18,
         module: 'Customers',
         selectAll: false,
         specificPermissions: {
            'Add': false,
            'Edit': false,
            'Delete': false,
            'View': false,
            'Import Customers': false,
         },
      },
      {
         id: 19,
         module: 'Customers Advance Payments',
         selectAll: false,
         specificPermissions: {
            'Add': false,
            'Edit': false,
            'Delete': false,
            'View': false,
         },
      },
      {
         id: 20,
         module: 'Purchase',
         selectAll: false,
         specificPermissions: {
            'Add': false,
            'Edit': false,
            'Delete': false,
            'View': false,
            'Purchase_Payments_View': false,
            'Purchase_Payments_Add': false,
            'Purchase_Payments_Delete': false,
            'Show all users Purchase Invoices': false,
         },
      },
      {
         id: 21,
         module: 'Purchase Return',
         selectAll: false,
         specificPermissions: {
            'Add': false,
            'Edit': false,
            'Delete': false,
            'View': false,
            'Purchase_Return_Payments_View': false,
            'Purchase_Return_Payments_Add': false,
            'Purchase_Return_Payments_Delete': false,
            'Show all users Purchase Return Invoices': false,
         },
      },
      {
         id: 22,
         module: 'Sales (Include POS)',
         selectAll: false,
         specificPermissions: {
            'Add': false,
            'Edit': false,
            'Delete': false,
            'View': false,
            'Sales_Payments_View': false,
            'Sales_Payments_Add': false,
            'Sales_Payments_Delete': false,
            'Show all users Sales Invoices': false,
            'Show Item Purchase Price(While making invoice)': false,
         },
      },
      {
         id: 23,
         module: 'Discount Coupon',
         selectAll: false,
         specificPermissions: {
            'Add': false,
            'Edit': false,
            'Delete': false,
            'View': false,
         },
      },
      {
         id: 24,
         module: 'Customer Coupon',
         selectAll: false,
         specificPermissions: {
            'Add': false,
            'Edit': false,
            'Delete': false,
            'View': false,
         },
      },
      {
         id: 25,
         module: 'Quotation',
         selectAll: false,
         specificPermissions: {
            'Add': false,
            'Edit': false,
            'Delete': false,
            'View': false,
            'Show all users Quotations': false,
         },
      },
      {
         id: 26,
         module: 'Sales Return',
         selectAll: false,
         specificPermissions: {
            'Add': false,
            'Edit': false,
            'Delete': false,
            'View': false,
            'Sales_Return_Payments_View': false,
            'Sales_Return_Payments_Add': false,
            'Sales_Return_Payments_Delete': false,
            'Show all users Sales Return Invoices': false,
         },
      },
      {
         id: 27,
         module: 'SMS/WhatsApp',
         selectAll: false,
         specificPermissions: {
            'Message Settings': false,
            'Send Message': false,
            'Message Template Edit': false,
            'Message Template View': false,
            'Message API View': false,
            'Message API Edit': false,
         },
      },
      {
         id: 28,
         module: 'Reports',
         selectAll: false,
         specificPermissions: {
            'Delivery_Sheet_Report': false,
            'Load_Sheet_Report': false,
            'Customer_Orders_Report': false,
            'Sales_Tax_Report': false,
            'Purchase_Tax_Report': false,
            'Supplier_Items_Report': false,
            'Sales_Report': false,
            'Sales_Return_Report': false,
            'Seller_Points_Report': false,
            'Purchase_Report': false,
            'Purchase_Return_Report': false,
            'Expense_Report': false,
            'Profit_Report': false,
            'Stock_Report': false,
            'Sales_Item Report': false,
            'Return_Items Report': false,
            'Purchase_Payments Report': false,
            'Sales_Payments_Report': false,
            'GSTR-1_Report': false,
            'GSTR-2_Report': false,
            'Sales_GST_Report': false,
            'Purchase_GST_Report': false,
         },
      },
      {
         id: 29,
         module: 'Help Documentation Link',
         selectAll: false,
         specificPermissions: {
            'Show Link (This link is always public)': false,
         },
      },
      // Add more rows as needed
   ]);

   const handleSelectAllChange = (rowId) => {
      const updatedPermissions = permissions.map((permission) => {
         if (permission.id === rowId) {
            permission.selectAll = !permission.selectAll;
            for (const key in permission.specificPermissions) {
               permission.specificPermissions[key] = permission.selectAll;
            }
         }
         return permission;
      });
      setPermissions(updatedPermissions);
   };

   const handleSpecificPermissionChange = (rowId, permissionType) => {
      const updatedPermissions = permissions.map((item) => {
         if (item.id === rowId) {
            item.specificPermissions[permissionType] = !item.specificPermissions[permissionType];
         }
         return item;
      });
      setPermissions(updatedPermissions);
   };
   const userSubmit = (e) => {
      e.preventDefault();

      // Create arrays to store selected and unselected specific permissions
      const selectedPermissions = [];

      permissions.forEach((permission) => {
         for (const key in permission.specificPermissions) {
            if (permission.specificPermissions[key]) {
               selectedPermissions.push(`${permission.module}_${key}`);
            } else {
               
            }
         }
      });

      // Form data to send to the server
      const formData = {
         role_name: roleName,
         description: description,
         permissions: selectedPermissions,
      };


      // Send a POST request
      axios
         .post('/role/create-role', formData)
         .then((response) => {
            navigate('/roles-list');
            CustomToast('success', 'Data added successfully');
         })
         .catch((error) => {
            CustomToast('error', 'Error saving data.');
         });
   };
   return (
      <>
         <Fragment>
            <Breadcrumb title="Add Role" parent="Roles" />
            <Container fluid={true}>
               <Card className="p-2">
                  <form onSubmit={userSubmit}>
                     <div className="col-md-6 col-sm-12">
                        <FormGroup className="d-flex justify-content-center align-items-center w-100">
                           <label htmlFor="roleName" className="fw-bold text-center" style={{ width: '200px' }}>
                              Role Name<span className="text-danger">*</span>
                           </label>
                           <input
                              type="text"
                              onChange={(e) => setRoleName(e.target.value)}
                              value={roleName}
                              className="form-control"
                              name="roleName"
                              id="roleName"
                              required
                           />
                        </FormGroup>
                     </div>
                     <div className="col-md-6 col-sm-12">
                        <FormGroup className="d-flex justify-content-center align-items-center w-100">
                           <label htmlFor="description" className="fw-bold text-center" style={{ width: '200px' }}>
                              Description<span className="text-danger">*</span>
                           </label>
                           <textarea
                              className="form-control"
                              name="description"
                              id="description"
                              onChange={(e) => setDescription(e.target.value)}
                              value={description}
                              required
                           ></textarea>
                        </FormGroup>
                     </div>

                     <Table bordered className="text-center">
                        <thead>
                           <tr>
                              <th>#</th>
                              <th>Modules</th>
                              <th>Select All</th>
                              <th>
                                 <span className="text-start d-inline-block col-6 mx-auto">Specific Permissions</span>
                              </th>
                           </tr>
                        </thead>
                        <tbody>
                           {permissions.map((permission) => (
                              <tr key={permission.id}>
                                 <td>{permission.id}</td>
                                 <td>{permission.module}</td>
                                 <td>
                                    <div className="form-check">
                                       <label>
                                          <input
                                             type="checkbox"
                                             checked={permission.selectAll}
                                             onChange={() => handleSelectAllChange(permission.id)}
                                             className="form-check-input"
                                             style={{ width: '18px', height: '18px', marginRight: '5px', border: '1px solid black' }}
                                          />
                                          <div className="ms-2 mt-1"> Select All</div>
                                       </label>
                                    </div>
                                 </td>
                                 <td>
                                    {Object.keys(permission.specificPermissions).map((specificPermission) => (
                                       <div key={specificPermission} className="text-start col-6 mx-auto">
                                          <input
                                             type="checkbox"
                                             id={`${permission.id}${specificPermission}`}
                                             checked={permission.specificPermissions[specificPermission]}
                                             onChange={() => handleSpecificPermissionChange(permission.id, specificPermission)}
                                             className="form-check-input"
                                             style={{ width: '18px', height: '18px', border: '1px solid black' }}
                                          />
                                          <label htmlFor={`${permission.id}${specificPermission}`} className="form-check-label ms-2 my-1">
                                             {specificPermission}
                                          </label>
                                       </div>
                                    ))}
                                 </td>
                              </tr>
                           ))}
                        </tbody>
                     </Table>
                     <div className="col-md-12 d-flex justify-content-center align-items-center gap-5 mt-4">
                        <button
                           type="submit"
                           style={{ backgroundColor: '#00A65A', border: 'none', padding: '6px 60px', borderRadius: '5px', color: 'white' }}
                        >
                           Submit
                        </button>
                        <Link
                           to="/dashboard"
                           style={{ backgroundColor: '#F39C12', textDecoration: 'none', padding: '6px 60px', borderRadius: '5px', color: 'white' }}
                        >
                           Close
                        </Link>
                     </div>
                  </form>
               </Card>
            </Container>
         </Fragment>
      </>
   );
};

export default AddRoles;
