import React, { useEffect } from "react";

const PosItemNameDropdownSection = ({
  barCodeProducts,
  // setSelectedItemData,
  // selectedItemData,
  // setIsSelectedItemDivOpen,
  selectedDropdownMenu,
  handleItemClick,
}) => {


 

  return (
    <div
      ref={selectedDropdownMenu}
      className="position-absolute top-100 h-auto w-100 overflow-auto bg-white shadow-lg rounded-1 p-2"
      style={{ maxHeight: "20vh" }}
    >
      {barCodeProducts?.map((item) => (
        <div
          key={item.id}
          className="flex justify-between items-center bg-gray-two-hover py-0"
        >
          <h6
            onClick={() => handleItemClick(item)}
            className="text-sm text-dark cs-cursor-pointer"
          >
            <span className="fw-bold text-black">Product Name:</span>{" "}
            {item.item_name} Barcode:{item.custom_barcode} Stock:{item.stock}
          </h6>
        </div>
      ))}
    </div>
  );
};

export default PosItemNameDropdownSection;
