

import React, { useState } from "react";
// import SalesListTableSection from "./SalesListTableSection";
import { BiEdit } from "react-icons/bi";
import { AiFillFileText } from "react-icons/ai";
import { MdOutlineAssignmentReturn } from "react-icons/md";
import { BiSolidFilePdf } from "react-icons/bi";


import AdjustmentListTableSection from "./AdjustmentListTableSection";

const ViewAdjustmentInvoiceInformationTableSection = ({ data }) => {
    

  const salesTable = [
    {
      id: 10,
      name: "SL",
    },
    {
      id: 11,
      name: "Item Name",
    },
 
    {
      id: 13,
      name: "Quantity",
    },

  ];


  // const { sales_items,  } = data || {};

  return (
    <>
      <section className="bg-body-secondary rounded-2 mt-4 p-2">
        <AdjustmentListTableSection
          tableHeader={salesTable}
          tableData={data}
        ></AdjustmentListTableSection>
      </section>

      <section className="d-flex justify-content-center align-items-center mt-3">
       <div className="me-3">Note: </div>
       {/* <textarea  name="" id="" className="form-control"></textarea> */}
      </section>
      
      {/* btn section */}
      <section className="d-flex justify-content-between align-items-start mt-4">
        <div className="d-flex gap-2">
          {/* <button className="btn  bg-green-hover gap-1 d-flex justify-content-center align-items-center">
            <BiEdit className="fs-5"></BiEdit> Edit
          </button> */}
        </div>
      </section>
    </>
  );
};

export default ViewAdjustmentInvoiceInformationTableSection;