


import { FaBuilding } from "react-icons/fa";
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Card, Container, FormGroup, FormText, Label, Row, Table } from 'reactstrap';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Link } from 'react-router-dom';
import TableLoading from '../../../components/common/loading/TableLoading';
import Papa from 'papaparse';
import axios from '../../../util/axiosInstance';
import PosDropdownSection from "../../SalesManagement/Pos/PosDropdownSection";
import useGetData from "../../../hooks/useGetData";
import { CustomToast } from "../../../hooks/useCustomToast";
import { BsFiletypeCsv } from "react-icons/bs";
const ImportItems = () => {
  const [isLoading, setIsLoading] = useState(true);
  // get data from backend

  const [branchData, setBranchData] = useState([])

  // const [branchData, branchRefetch, isLoading] = useGetData(
  //   "/branch/viewbranch",
  //   "branch"
  // );


  useEffect(() => {
    axios.get('/branch/viewbranch')
      .then(result => {
        setBranchData(result.data)
        setIsLoading(false)
      })
  }, [])

  const [inputData, setInputData] = useState([]);
  const [tableData, setTableData] = useState([])
  const fileInputRef = useRef(null);


  // const firstBranch = branchData && branchData[0]?.warehouse_name;
  // const fistBranchId = branchData && branchData[0]?.id;

  const [wareHouseDropdownValue, setWarehouseDropdownValue] = useState("");
  const [selectedItemData, setSelectedItemData] = useState([]);
  const [wareHouseDropdownId, setWarehouseDropdownId] = useState("");





  // csv submit 

  const handleWarehouseSubmit = (e) => {
    e.preventDefault();

    if (inputData) {
      Papa.parse(inputData, {
        complete: (result) => {
          const items = result?.data?.map(entry => ({
            ...entry,
          }));
          items.pop()

          setTableData(items)
          const chunkedData = [];
          const chunkSize = 50;
          for (let i = 0; i < tableData.length; i += chunkSize) {
            const chunk = tableData.slice(i, i + chunkSize);
            chunkedData.push(chunk);
          }
          if (result?.data) {
            for (const chunk of chunkedData) {
              axios.post('/supplier/createsupplier', [...chunk])
                .then(res => {
                  CustomToast('success', 'Oh Yeah! You Did Amazing');
                })
                .catch(error => {
                  CustomToast('error', 'Oops! Bad Luck Try Again');
                });
            }

            // axios.post('/customer/createcustomer', items)
            //   .then(res => {
            //     CustomToast('success', 'import items successfully');
            //   })
            //   .catch(error => {
            //     CustomToast('error', 'import items error data');
            //   });
          }
        },
        header: true
      });
    }
  }


  // csv download 
  // const handleDownload = () => {
  //   const csvString = Papa.unparse(data);
  //   const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
  //   const url = URL.createObjectURL(blob);

  //   const link = document.createElement('a');
  //   link.href = url;
  //   link.setAttribute('download', 'download.csv');
  //   link.click();

  //   URL.revokeObjectURL(url);
  // };

  // useEffect(() => {
  //   setWarehouseDropdownValue(firstBranch);
  //   setWarehouseDropdownId(fistBranchId);
  // }, [firstBranch, fistBranchId]);

  const handleIconClick = () => {
    fileInputRef.current.click();  // trigger the click event of the hidden file input
  };

  const showCsv = () => {
    if (inputData.size) {
      Papa.parse(inputData, {
        complete: (result) => {
          // Modify each entry to include warehouse_id
          const items = result?.data?.map(entry => ({
            ...entry,
          }));
          items.pop()
          // Constructing the desired payload
          setTableData(items)
        },
        header: true
      });

    } else {
      CustomToast('error', 'Are You Crazy Checkout CSV File First')
    }
  };


  return (
    <Fragment>
      <Breadcrumb
        title='Import Suppliers '
        parent='Contact Management'
      />
      <Container fluid={true}>
        <Card className='p-2'>



          <div className='col-md-6 col-sm-12 '>
            <FormGroup>
              <Label
                for='exampleName'
                className='fw-bold mt-2'>
                Import Suppliers <span className='text-danger'>*</span>
              </Label>
              <div className='my-2 d-flex gap-3 align-items-center'>
                <BsFiletypeCsv
                  style={{ fontSize: '30px', cursor: 'pointer' }}
                  onClick={handleIconClick}
                />
                {/* <i className="fa fa-camera"  ></i> */}
                <span className='name'>{inputData?.name}</span>
                <input
                  name="file"
                  type='file'
                  ref={fileInputRef}
                  style={{ display: 'none' }} // hide the input element
                  onChange={(e) => setInputData(e.target.files[0])}
                />
              </div>

              <FormText></FormText>
              <span className='text-danger'>
                Note: File must be in CSV format.
              </span>
            </FormGroup>
          </div>

          <div className='row'>
            <div className=' d-flex align-items-center justify-content-center mt-5 gap-5'>
              <div
                onClick={showCsv}
                style={{
                  cursor: 'pointer',
                  backgroundColor: '#192655',
                  border: 'none',
                  color: 'white',
                  padding: '6px 28px',
                  borderRadius: '5px',
                }}
              >
                Show Csv
              </div>
              {tableData?.length ? <input
                type='submit'
                value={'import'}
                onClick={handleWarehouseSubmit}
                style={{
                  backgroundColor: '#00A65A',
                  border: 'none',
                  color: 'white',
                  padding: '6px 28px',
                  borderRadius: '5px',
                }}
              /> : ''}
              <Link
                to={'/dashboard'}
                style={{
                  backgroundColor: '#F39C12',
                  border: 'none',
                  color: 'white',
                  padding: '6px 28px',
                  borderRadius: '5px',
                  textDecoration: 'none',
                }}>
                Close
              </Link>
            </div>
          </div>


          <div className='mt-5 mb-2 d-flex align-items-center justify-content-between'>
            <span></span>
            <span
              // onClick={handleDownload}
              style={{
                backgroundColor: '#00C0EF',
                padding: '10px',
                borderRadius: '5px',
                color: 'white',
                cursor: 'pointer',
              }}>
              {' '}
              <a
                href='https://drive.google.com/uc?id=1ClrkzpgXEt0fiadnUn9Fr4NyL3tTFSft&export=download'
                style={{ textDecoration: 'none', color: 'white' }}>
                {' '}
                Download Example{' '}
              </a>{' '}
            </span>
          </div>

          <div
            style={{
              backgroundColor: '#F2F2F2',
              marginTop: '15px',
              borderRadius: '5px',
              padding: '10px',
            }}>
            {tableData?.length > 0 && (
              <div  style={{ maxHeight: '400px', overflowY: 'auto', width:'100%', overflowX: 'auto' }}>
                <Table
                  className="w-100"
                  bordered
                  hover
                  striped>
                  <thead className='text-center'>
                    <tr>
                      {Object?.keys(tableData[0])?.map((key, i) => (
                        <th key={i}>{key}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className='text-center' 
                  style={{ maxHeight: '400px',  overflowY: 'auto', overflowX: 'auto' }}
                  >
                    {isLoading && (
                      <>
                        <TableLoading
                          noTd={tableData[0]?.length}
                          noRow={10}
                        />
                      </>
                    )}
                    {/* {
                  !isLoading && (
                    data?.sort((a, b) => b?.id - a?.id).map((value, index) => (
                      <tr key={index}>
                        <td>{value}</td>
                      </tr>
                    ))
                  )
                } */}
                    {tableData?.map((row, index) => (
                      <tr key={index}>
                        {Object?.values(row)?.map((value) => (
                          <td key={value}>{value}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}
          </div>
        </Card>
      </Container>
    </Fragment>
  );
};

export default ImportItems;