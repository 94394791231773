import React from "react";
import YourComponent from "./City";

const BranchInformation = () => {
  return (
    <>
      <YourComponent></YourComponent>
    </>
  );
};

export default BranchInformation;
