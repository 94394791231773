import React,{ Fragment } from "react"
import { Route, Routes } from "react-router-dom"
import Login from "../components/auth/login"
import LayoutRoutes from "./LayoutRoutes"
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import OrderPlatform from '../Screen/OrderPlatform';

const Routers = () => {
  return (
    <Fragment>
      <Routes>
        {/* <Route
          exact
          path={`${process.env.PUBLIC_URL}/`}
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        /> */}
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/login`}
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/auth/login`}
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/`}
          element={<OrderPlatform />}
        />
        <Route
          path={`/*`}
          element={
            <PrivateRoute>
              <LayoutRoutes />
            </PrivateRoute>
          }
        />
      </Routes>
    </Fragment>
  );
};

export default Routers