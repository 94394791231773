import React from "react";

const PurchaseInvoiceTableBody = ({ item, index }) => {
  const {
    id,
    item_name,
    description,
    brand_model_origin,
    uom,
    purchase_qty,
    discount,
    tax,
    vat,
    price_per_unit,
    total_cost,
  } = item || {};
  return (
    <>
      <tr>
        <td>{index + 1}</td>
        <td>{item_name || "-"}</td>
        <td>{description || "-"}</td>
        <td>{brand_model_origin || "-"}</td>
        <td>{uom || "-"}</td>
        <td>{purchase_qty || "-"}</td>
        <td>{discount || "-"}</td>
        <td>{tax || "-"}</td>
        <td>{vat || "-"}</td>
        <td>{price_per_unit || "-"}</td>
        <td>{total_cost || "-"}</td>
      </tr>
    </>
  );
};

export default PurchaseInvoiceTableBody;
