import React from "react";
import useNumberFormatter from "../../../hooks/useNumberFormatter";
import { Dropdown } from "react-bootstrap";
import { AiFillDelete, AiFillEye } from "react-icons/ai";
import Swal from "sweetalert2";
import axiosInstance from "../../../util/axiosInstance";
import { CustomToast } from "../../../hooks/useCustomToast";
import { Link } from "react-router-dom";

const SalesReturnListTable = ({ product, sl }) => {
  const numericFormatter = useNumberFormatter();
  const {
    return_date,
    return_code,
    sales_code,
    return_status,
    customer_name,
    grand_total,
    paid_amount,
    payment_status,
    return_id: id,
  } = product || {};

  // Handle Delete By Id
  const handleDeleteItem = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axiosInstance.delete(
            `/salesreturn/delete-sales-return/${id}`
          );

          if (response) {
            CustomToast("success", "Delete Successfully");
          } else {
            console.error("Failed to delete item:", response.data);
          }
        } catch (error) {
          console.error("Error deleting item:", error);
        }

        CustomToast("success", "Your file has been deleted.");
      }
    });
  };
  return (
    <>
      <tr>
        <td>{sl + 1}</td>
        <td>{return_date?.slice(0, 10) || "-"}</td>
        <td>{sales_code || "-"}</td>
        <td>{return_code || "-"}</td>
        <td>{return_status || "-"}</td>
        <td>{customer_name || "-"}</td>
        <td>{numericFormatter(grand_total) || 0}</td>
        <td>{numericFormatter(paid_amount) || 0}</td>
        <td>{payment_status || "-"}</td>
        <td
          className="position-relative "
          // onClick={() => handleViewPayments(return_id)}
          style={{ width: "110px" }}
        >
          <Dropdown
            className="w-100 position-absolute start-0 "
            style={{ top: "5px" }}
          >
            <Dropdown.Toggle
              variant=""
              name="paymentMethod"
              id="payment"
              className="border-0 py-1 px-2 text-start fw-normal text-white c-pos-button7 border"
              style={{ fontSize: "10px", backgroundColor: "#093d86" }}
            >
              Action
            </Dropdown.Toggle>
            <Dropdown.Menu
              style={{
                maxHeight: "230px",
                boxShadow: "0px 0px 3px 2px rgb(197, 194, 194)",
              }}
              className=" overflow-auto rounded-2 h-auto  d-flex   flex-column px-1 py-2 gap-1 "
            >
              {/* View Sales------------------------------------------ */}
              <Link
                to={`/sales-management/view-sales-return/${id}`}
                className=" w-100 text-start text-dark  rounded-1 bg-black-hover p-0 ps-2 d-flex  align-items-center gap-1 cs-text-decoration-none"
              >
                <AiFillEye></AiFillEye>
                <option
                  style={{ fontSize: "14px" }}
                  className="color-white-hover "
                >
                  View Sales
                </option>
              </Link>
              {/* Delete------------------------------------------ */}
              <Dropdown.Item
                onClick={() => handleDeleteItem(id)}
                className=" w-100 text-start text-dark  rounded-1 bg-red-hover p-0 ps-2 d-flex  align-items-center gap-1 "
              >
                <AiFillDelete></AiFillDelete>
                <option
                  style={{ fontSize: "14px" }}
                  className="color-white-hover"
                >
                  Delete
                </option>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    </>
  );
};

export default SalesReturnListTable;
