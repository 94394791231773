import React, { useEffect, useState } from "react";
import { AiOutlinePlus, AiOutlineMinus, AiFillDelete } from "react-icons/ai";
// import posDiscountModal from "../ReportsManagement/Modal/PosDiscountModal";
import useNumberFormatter from "../../hooks/useNumberFormatter.js";
import Swal from "sweetalert2";

// import PurchaseDiscountModal from "./Modal/PurchaseDiscountModal";

const PurchaseTable = ({
  product,
  products,
  setSelectedItemData,
  setTotalSubTotal,
  setTotalQuantity,
  selectedItemData,
  setSalesPrice,
  // setLotNumber,
  setCustomBarcode,
  totalQuantity,
  grandTotal,
  salesPrice,
  customBarcode,
  salesItems,
  setSalesItems,
}) => {
  const { item_name, stock, sales_price, lot_number, id, sales_qty } =
    product || {};
  const [updatedStock, setUpdatedStock] = useState();
  const numericFormatter = useNumberFormatter();
  const [count, setCount] = useState(sales_qty || 1);
  const [price, setPrice] = useState(0);
  const [discount, setDiscount] = useState("");

  const [increaseDiscount, setIncreaseDiscount] = useState(false);
  const [discountInputValue, setDiscountInputValue] = useState("");
  const [discountDropdownValue, setDiscountDropdownValue] =
    useState("Percentage(%)");
  const [lotNumber, setLotNumber] = useState(lot_number);
  const [modalOpen, setModalOpen] = useState(false);
  const priceByQuantity = parseFloat(price) * parseFloat(count);
  const subTotal = priceByQuantity - (discount ? discount : 0);
  const disCountInTaka = priceByQuantity - subTotal;

  const [newAllProductTotalSubTotal, setNewAllProductTotalSubTotal] =
    useState(0);

  // modal function
  const toggleModal = () => {
    if (price !== "" && count !== 0 && price >= 0) {
      setModalOpen(!modalOpen);
      setIncreaseDiscount(false);
    } else if (price === "" && count === 0) {
      Swal.fire({
        // icon: "error",
        title: "Oops...",
        text: "Please Enter the Price & Count Must be greater than 0 ",
      });
    } else if (price < 0) {
      Swal.fire({
        // icon: "error",
        title: "Oops...",
        text: "Price Must be greater than 0",
      });
    } else if (price === "") {
      Swal.fire({
        // icon: "error",
        title: "Oops...",
        text: "Price is empty!! Please Enter the Price ",
      });
    } else if (count === 0) {
      Swal.fire({
        // icon: "error",
        title: "Oops...",
        text: `Count Must be greater than 0`,
      });
    }
  };

  // stock update
  useEffect(() => {
    setUpdatedStock(parseFloat(stock) + parseFloat(count ? count : 0));
  }, [stock, count]);

  // sales update
  useEffect(() => {
    setPrice(sales_price ? sales_price : 0);
  }, [sales_price]);

  // quantity increment decrement
  const handleIncrement = (e) => {
    e.preventDefault();
    if (parseFloat(count) >= 0) {
      setCount(parseFloat(count) + 1);
      setUpdatedStock(parseFloat(updatedStock) + 1);
    }
  };

  const handleDecrement = (e) => {
    e.preventDefault();
    if (parseFloat(count) > 0) {
      setCount(parseFloat(count) - 1);
      setUpdatedStock(parseFloat(updatedStock) - 1);
    }
  };

  useEffect(() => {
    if (increaseDiscount) {
      handleDiscount();
    }
  }, [
    discountInputValue,
    discountDropdownValue,
    priceByQuantity,
    increaseDiscount,
  ]);

  // handle discount calculation
  const handleDiscount = () => {
    let discount;
    if (discountInputValue !== "") {
      if (discountDropdownValue === "Percentage(%)") {
        discount =
          (parseFloat(priceByQuantity) * parseFloat(discountInputValue)) / 100;
      } else if (discountDropdownValue === "Fixed(৳)") {
        const fixedValue =
          parseFloat(priceByQuantity) - parseFloat(discountInputValue);
        discount = priceByQuantity - fixedValue;
      }
    }
    setDiscount(discount);
  };

  // Remove the current product from the products array
  const handleDeleteProduct = () => {
    const updatedProducts = products?.filter((p) => p?.id !== product?.id);
    setSelectedItemData(updatedProducts);

    // Calculate new total and subtotal
    const newTotalSubTotal = updatedProducts.reduce(
      (acc, product) =>
        acc + parseFloat(product?.subtotal ? product.subtotal : 0),
      0
    );
    const totalQuantity = updatedProducts.reduce(
      (acc, product) =>
        acc + parseFloat(product?.itemQuantity ? product.itemQuantity : 0),
      0
    );

    // removed from item list
    const updatedSalesItems = salesItems.filter(
      (item) => item?.item_id !== productsData?.item_id
    );

    // Update state with the new values
    setSelectedItemData(updatedProducts);
    setTotalSubTotal(newTotalSubTotal);
    setNewAllProductTotalSubTotal(newTotalSubTotal);
    setTotalQuantity(totalQuantity);
    setSalesItems([...updatedSalesItems]);
  };

  // handle total and subtotal calculation
  const handleTotalSubTotal = () => {
    let productSubTotal = priceByQuantity - (discount ? discount : 0);

    const updatedProducts = products.map((p) => {
      if (p?.id === id) {
        return { ...p, subtotal: productSubTotal, itemQuantity: count };
      }
      return p;
    });

    // Calculate new total and subtotal
    const newTotalSubTotal = updatedProducts.reduce(
      (acc, product) =>
        acc + parseFloat(product.subtotal ? product.subtotal : 0),
      0
    );
    // Calculate new quantity and subQuantity
    const totalQuantity = updatedProducts.reduce(
      (acc, product) =>
        acc + parseFloat(product.itemQuantity ? product.itemQuantity : 0),
      0
    );
    setTotalQuantity(totalQuantity);
    setSelectedItemData(updatedProducts);
    setTotalSubTotal(newTotalSubTotal);
  };
  useEffect(() => {
    handleTotalSubTotal(); // This calls the handleDiscount function
  }, [
    count,
    price,
    discount,
    discountInputValue,
    discountDropdownValue,
    increaseDiscount,
  ]);

  const productsData = {
    item_id: id,
    description: "dercription",
    purchase_qty: count,
    price_per_unit: price,
    discount_type: "not update",
    discount_input: "not update",
    discount_amt: "not update",
    unit_total_cost: subTotal,
    total_cost: subTotal,
    sales_price: salesPrice,
    purchase_price: price,
    lot_number: lotNumber,
    custom_barcode: customBarcode,
  };
  const handleAddMemo = () => {
    const nnnn = salesItems.filter(
      (item) => item.item_id !== productsData.item_id
    );

    setSalesItems([...nnnn, productsData]);
  };

  useEffect(() => {
    handleAddMemo();
  }, [
    count,
    price,
    lotNumber,
    salesPrice,
    subTotal,
    newAllProductTotalSubTotal,
  ]);

  return (
    <tr>
      <td className="text-start">{item_name || "-"}</td>
      <td className="text-start ">
        <input
          type="text"
          defaultValue={lot_number || null}
          placeholder="Lot Number "
          onChange={(e) => setLotNumber(e.target.value)}
          className="border rounded-1 py-2 bg-body-secondary text-input text-center"
        />
      </td>

      <td className="text-start ">
        <input
          type="text"
          onChange={(e) => setCustomBarcode(e.target.value)}
          defaultValue={lot_number || null}
          placeholder="Custom Barcode "
          className="border rounded-1 py-2 bg-body-secondary text-input text-center"
        />
      </td>
      <td className="text-center">{numericFormatter(updatedStock)}</td>
      {/* sales price  */}
      <td style={{ width: "50px" }}>
        <span className="">
          <input
            type="number"
            step={"any"}
            placeholder="Sales Price "
            onChange={(e) => setSalesPrice(e.target.value)}
            className="border rounded-1 py-2 bg-body-secondary text-input text-center"
          />
        </span>
      </td>
      {/* unit per cost  */}
      <td style={{ display: "none" }}>
        <span
          style={{ width: "40px" }}
          className="border rounded-1 bg-body-secondary w-100 py-2 d-block"
        >
          {numericFormatter(price)}
        </span>
      </td>

      {/* purchase price  */}
      <td style={{ width: "50px" }}>
        <span className="">
          <input
            type="number"
            step={"any"}
            placeholder="Enter Amount "
            value={price >= 0 ? price : 0}
            onChange={(e) => {
              const inputValue = parseFloat(e.target.value);
              if (!isNaN(inputValue) && inputValue >= 0) {
                setPrice(inputValue);
              } else {
                setPrice(0);
              }
            }}
            className="border rounded-1 py-2 bg-body-secondary text-input text-center"
          />
        </span>
      </td>

      {/* quantity increment  decrement  */}

      <td>
        <span
          style={{ width: "150px" }}
          className="border rounded-1 d-flex justify-content-between align-items-center"
        >
          <button
            className="d-flex justify-content-center align-items-center border-end border-0 col-2   p-2"
            onClick={handleDecrement}
          >
            <span>
              <AiOutlineMinus className="text-danger fw-bold" />
            </span>
          </button>
          <span className=" text-center  border-0 d-inline-block col-8">
            <input
              type="number"
              step={"any"}
              placeholder="Quantity"
              value={count >= 0 && count}
              onChange={(e) => {
                const inputValue = parseFloat(e.target.value);
                if (!isNaN(inputValue) && inputValue >= 0) {
                  setCount(inputValue);
                } else {
                  setCount(0);
                }
              }}
              className="border-0  text-center py-2 text-center w-100"
            />
          </span>
          <button
            className="d-flex justify-content-center align-items-center border-start border-0 py-2 col-2"
            onClick={handleIncrement}
          >
            <span>
              <AiOutlinePlus className="text-success fw-bold" />
            </span>
          </button>
        </span>
      </td>
      <td className="">
        <span
          style={{ width: "40px" }}
          className="border rounded-1 bg-body-secondary w-100 py-2 d-block"
        >
          {subTotal ? numericFormatter(subTotal) : 0}
        </span>
      </td>

      <td className="">
        <span className="d-flex justify-content-center align-items-center py-2">
          <AiFillDelete
            onClick={handleDeleteProduct}
            style={{ cursor: "pointer" }}
            className="text-danger fs-5"
          ></AiFillDelete>
        </span>
      </td>
    </tr>
  );
};

export default PurchaseTable;
