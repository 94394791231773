import React, { useEffect, useState } from 'react'
import SalesPaymentTable from './components/SalesPaymentTable'
import Breadcrumb from '../../../components/common/breadcrumb'
import DateRangePickerComponent from './components/DateRangePicker'
import CustomerNameSelectDropdown from './components/CustomerNameSelectDropdown'
import { addDays } from 'date-fns';

import { Button } from 'reactstrap'
import moment from 'moment'

const SalesPaymentScreen = () => {
    const [queryData, setQueryData] = useState({
        user_name: null,
        brand_name: '',
        category_name: '',
        lot_number: ""
    })
    const [stateM, setStateM] = useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 0),
            key: 'selection',
        },
    ]);

    const [ApiLink, setApiLink] = useState('')
    const startDate = moment(stateM[0].startDate).format().substring(0, 10)
    const endDate = moment(stateM[0].endDate).format().substring(0, 10)

    useEffect(() => {
        if (queryData.user_name) {
            setApiLink(`/report/invoices-created/${queryData.user_name}?startDate=${startDate}&endDate=${endDate}`)
        }
    }, [queryData.user_name, endDate, startDate])

    return (
      <>
        <Breadcrumb
          title='Customer Orders'
          parent='reports'
        />
        <div className='col-12 '>
          <h3 className=''>Please Enter Valid Information</h3>
          <div className='d-flex'>
            <CustomerNameSelectDropdown
              apiLink='/customer/viewcustomers'
              queryData={queryData}
              setQueryData={setQueryData}
              InitialDropdownValue='select Customer'
            />
            <DateRangePickerComponent
              setStateM={setStateM}
              stateM={stateM}
              queryData={queryData}
              setQueryData={setQueryData}
            />
          </div>
        </div>

        <div className=''>
          {/* <PayamentList /> */}
          <SalesPaymentTable queryLink={ApiLink} />
        </div>
      </>
    );
}

export default SalesPaymentScreen