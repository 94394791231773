import {
  Home,
  Box,
  DollarSign,
  Tag,
  Clipboard,
  Camera,
  AlignLeft,
  UserPlus,
  Users,
  Chrome,
  BarChart,
  Settings,
  Archive,
  LogIn,
  ShoppingCart,
  BarChart2,
  ArrowUpLeft,
  CreditCard,
  MessageSquare,
  ShoppingBag,
  PhoneIncoming,
  User,
} from "react-feather";

import { AiOutlineLineChart } from "react-icons/ai";
import { BsFillBarChartFill } from "react-icons/bs";
import { TbMoneybag } from "react-icons/tb";
import { BiGitMerge } from "react-icons/bi";
import { TbUsers } from "react-icons/tb";

export const MENUITEMS = [
  {
    path: "/dashboard",
    title: "Dashboard",
    icon: Home,
    type: "link",
    badgeType: "primary",
    active: false,
    allowed: false,
    allowedKeys: [
      "Dashboard_View Dashboard Data",
      'Dashboard_Financial Report box',
      'Dashboard_Sales Report graph',
      "Dashboard_Purchase And Sales Chart",
      "Dashboard_Recently Added Items List",
      "Dashboard_Stock Alert List",
      "Dashboard_Trending Items Chart",
      "Dashboard_Recent Sales Invoice List",
    ],
  },

  {
    title: "Sales Management",
    icon: ShoppingBag,
    type: "sub",
    active: false,
    allowed: false,
    allowedKeys: [
      "sales_add",
      "sales_edit",
      "sales_delete",
      "sales_view",
      "sales_payment_view",
      "sales_payment_add",
      "sales_payment_delete",
      "sales_return_add",
      "sales_return_edit",
      "sales_return_delete",
      "sales_return_view",
      "sales_return_payment_view",
      "sales_return_payment_add",
      "sales_return_payment_delete",
      "Sales (Include POS)_Add",
      "Sales (Include POS)_Edit",
      "Sales (Include POS)_Delete",
      "Sales (Include POS)_View",
      "Sales (Include POS)_Sales_Payments_View",
      "Sales (Include POS)_Sales_Payments_Add",
      "Sales (Include POS)_Sales_Payments_Delete",
      "Sales (Include POS)_Show all users Sales Invoices",
      "Sales (Include POS)_Show Item Purchase Price(While",
    ],
    children: [
      {
        path: "/sales-management/pos",
        title: "POS",
        type: "link",
        allowed: false,
        allowedKeys: [
          "sales_add",
          "sales_edit",
          "sales_delete",
          "sales_view",
          "sales_payment_view",
          "sales_payment_add",
          "sales_payment_delete",
          "sales_return_add",
          "sales_return_edit",
          "sales_return_delete",
          "sales_return_view",
          "sales_return_payment_view",
          "sales_return_payment_add",
          "sales_return_payment_delete",
          "Sales (Include POS)_Add",
          "Sales (Include POS)_Edit",
          "Sales (Include POS)_Delete",
          "Sales (Include POS)_View",
          "Sales (Include POS)_Sales_Payments_View",
          "Sales (Include POS)_Sales_Payments_Add",
          "Sales (Include POS)_Sales_Payments_Delete",
          "Sales (Include POS)_Show all users Sales Invoices",
          "Sales (Include POS)_Show Item Purchase Price(While",
        ],
      },
      // { path: '/coupons/list-coupons', title: 'Add Sale', type: 'link' },
      {
        path: "/sales-management/sales-list",
        title: "Sale List",
        type: "link",
        allowed: false,
        allowedKeys: ["Sales (Include POS)_View", "sales_view"],
      },
      {
        path: "/sales-management/sales-payment",
        title: "Sales Payments List",
        type: "link",
        allowed: false,
        allowedKeys: [
          "Sales (Include POS)_Sales_Payments_View",
          "sales_payment_view",
        ],
      },
      {
        path: "/sales-management/sales-return-list",
        title: "Sales Returns List",
        type: "link",
        allowed: false,
        allowedKeys: [
          "Sales (Include POS)_Sales_Payments_View",
          "Sales (Include POS)_View",
          "sales_return_view",
        ],
      },
      {
        path: "/sales-management/sales-return-payment",
        title: "Sales Returns Payments",
        type: "link",
        allowed: false,
        allowedKeys: [
          "Sales (Include POS)_Sales_Payments_View",
          "Sales (Include POS)_View",
          "sales_return_payment_view",
        ],
      },
      {
        path: "/delivery-management/delivery-list",
        title: "Delivery List",
        type: "link",
        allowed: false,
        allowedKeys: [
          "Sales (Include POS)_Add",
          "Sales (Include POS)_Edit",
          "Sales (Include POS)_Delete",
          "Sales (Include POS)_View",
          "Sales (Include POS)_Sales_Payments_View",
          "Sales (Include POS)_Sales_Payments_Add",
          "Sales (Include POS)_Sales_Payments_Delete",
          "Sales (Include POS)_Show all users Sales Invoices",
          "Sales (Include POS)_Show Item Purchase Price(While",
          "sales_add",
          "sales_edit",
          "sales_delete",
          "sales_view",
          "sales_payment_view",
          "sales_payment_add",
          "sales_payment_delete",
          "sales_return_add",
          "sales_return_edit",
          "sales_return_delete",
          "sales_return_view",
          "sales_return_payment_view",
          "sales_return_payment_add",
          "sales_return_payment_delete",
        ],
      },
    ],
  },
  {
    title: "Purchase Management",
    icon: Tag,
    type: "sub",
    active: false,
    allowed: false,
    allowedKeys: [
      "Purchase_Add",
      "Purchase_Edit",
      "Purchase_Delete",
      "Purchase_View",
      "Purchase_Purchase_Payments_View",
      "Purchase_Purchase_Payments_Add",
      "Purchase_Purchase_Payments_Delete",
      "Purchase_Show all users Purchase Invoices",
      "Purchase Return_Add",
      "Purchase Return_Edit",
      "Purchase Return_Delete",
      "Purchase Return_View",
      "Purchase Return_Purchase_Return_Payments_View",
      "Purchase Return_Purchase_Return_Payments_Add",
      "Purchase Return_Purchase_Return_Payments_Delete",
      "Purchase Return_Show all users Purchase Return Inv",
    ],
    children: [
      {
        path: "/purchase-management/new-purchase",
        title: "New Purchase",
        type: "link",
        allowed: false,
        allowedKeys: ["Purchase_Add"],
      },
      {
        path: "/purchase-management/purchase-list",
        title: "Purchase List",
        type: "link",
        allowed: false,
        allowedKeys: ["Purchase_View"],
      },
      {
        path: "/purchase-management/payment-slip",
        title: "payment slip",
        type: "link",
      },
    ],
  },
  {
    title: "Product Management",

    icon: ShoppingCart,
    type: "sub",
    active: false,
    allowed: false,
    allowedKeys: [
      "Items_Add",
      "Items_Edit",
      "Items_Delete",
      "Items_View",
      "Items_Category Add",
      "Items_Category Edit",
      "Items_Category Delete",
      "Items_Category View",
      "Items_Print Labels",
      "Items_Import Items",
      "Items_Import Services",
    ],
    children: [
      {
        path: "/product-management/add-item",
        title: "Add Item",
        type: "link",
        allowed: false,
        allowedKeys: ["Items_Add"],
      },

      {
        path: "/product-management/items-list",
        title: "Items List",
        type: "link",
        allowed: false,
        allowedKeys: ["Items_View"],
      },

      {
        path: "/product-management/categoryList",
        title: "Categories List",
        type: "link",
        allowed: false,
        allowedKeys: ["Items_Category View"],
      },
      {
        path: "/product-management/brand-list",
        title: "Brands List",
        type: "link",
        allowed: false,
        allowedKeys: ["Brand_View"],
      },
      {
        path: "/product-management/units-list",
        title: "Units List",
        type: "link",
        allowed: false,
        allowedKeys: ["Units_view"],
      },
      {
        path: "product-management/alert-qty",
        title: "Alert Quantity",
        type: "link",
        allowed: false,
        allowedKeys: ["Dashboard_Stock Alert List"],
      },
      {
        path: "/product-management/labels",
        title: "Print Labels",
        type: "link",
        allowed: false,
        allowedKeys: [" Items_Print Labels"],
      },
      {
        path: "/product-management/import-items",
        title: "Import Items ",
        type: "link",
        allowed: false,
        allowedKeys: ["Items_Add"],
      },
      // {
      //   path: '/product-management/import-services',
      //   title: 'Import Services ',
      //   type: 'link',
      // },
    ],
  },

  {
    title: "Stock Management",
    icon: AiOutlineLineChart,
    type: "sub",
    active: false,
    allowedKeys: [
      "Stock Transfer_Add",
      "Stock Transfer_Edit",
      "Stock Transfer_Delete",
      "Stock Transfer_View",
      "Stock Adjustment_Add",
      "Stock Adjustment_Edit",
      "Stock Adjustment_Delete",
      "Stock Adjustment_View",
    ],
    children: [
      {
        path: "/reports/stock",
        title: "Stock List",
        type: "link",
        allowed: false,
        allowedKeys: ["Stock Transfer_View", "Stock Adjustment_View"],
      },
      {
        path: "/stock-management/adjustment-list",
        title: "Adjustment List",
        type: "link",
        allowed: false,
        allowedKeys: [
          "Stock Adjustment_Add",
          "Stock Adjustment_Edit",
          "Stock Adjustment_Delete",
          "Stock Adjustment_View",
        ],
      },
      {
        path: "/stock-management-transfer-list",
        title: "Transfer List",
        type: "link",
        allowed: false,
        allowedKeys: ["Stock Transfer_Add", "Stock Transfer_View"],
      },
    ],
  },
  // {
  //   title: 'Coupon Management',
  //   icon: Tag,
  //   type: 'sub',
  //   active: false,
  //   children: [
  //     {
  //       path: '/coupons/list-coupons',
  //       title: 'Create Customer Coupon',
  //       type: 'link',
  //     },
  //     {
  //       path: '/coupons/create-coupons',
  //       title: 'Customer Coupons List',
  //       type: 'link',
  //     },
  //     { path: '/coupons/create-coupons', title: 'Create Coupon', type: 'link' },
  //     {
  //       path: '/coupons/create-coupons',
  //       title: 'Coupons Master',
  //       type: 'link',
  //     },
  //   ],
  // },

  {
    path: "/reports-management",
    title: "Reports Management",
    icon: BsFillBarChartFill,
    type: "link",
    badgeType: "primary",
    active: false,
    allowed: false,
    allowedKeys: [
      "Dashboard_View Dashboard Data",
      'Dashboard_Financial Report box',
      'Dashboard_Sales Report graph',
      "Dashboard_Purchase And Sales Chart",
      "Dashboard_Recently Added Items List",
      "Dashboard_Stock Alert List",
      "Dashboard_Trending Items Chart",
      "Dashboard_Recent Sales Invoice List",
    ],
  },

  {
    title: "Reports Management",
    icon: BsFillBarChartFill,
    type: "sub",
    active: false,
    allowed: false,
    allowedKeys: [
      "Reports_Delivery_Sheet_Report",
      "Reports_Load_Sheet_Report",
      "Reports_Customer_Orders_Report",
      "Reports_Sales_Tax_Report",
      "Reports_Purchase_Tax_Report",
      "Reports_Supplier_Items_Report",
      "Reports_Sales_Report",
      "Reports_Sales_Return_Report",
      "Reports_Seller_Points_Report",
      "Reports_Purchase_Report",
      "Reports_Purchase_Return_Report",
      "Reports_Expense_Report",
      "Reports_Profit_Report",
      "Reports_Stock_Report",
      "Reports_Sales_Item Report",
      "Reports_Return_Items Report",
      "Reports_Purchase_Payments Report",
      "Reports_Sales_Payments_Report",
      "Reports_GSTR-1_Report",
      "Reports_GSTR-2_Report",
      "Reports_Sales_GST_Report",
      "Reports_Purchase_GST_Report",
    ],
    children: [
      {
        path: "/reports/profit-loss",
        title: "Profit & Loss Report",
        type: "link",
        allowed: false,
        allowedKeys: [
          "Reports_Sales_Report",
          "Reports_Purchase_Report",
          "Reports_Profit_Report",
          "Reports_Sales_Payments_Report",
        ],
      },
      {
        path: "/reports/sales-and-payment",
        title: "Sales & Payment Report",
        type: "link",
        allowed: false,
        allowedKeys: [
          "Reports_Sales_Tax_Report",
          "Reports_Purchase_Tax_Report",
          "Reports_Sales_Report",
          "Reports_Sales_Return_Report",
          "Reports_Purchase_Report",
          "Reports_Purchase_Payments Report",
          "Reports_Sales_Payments_Report",
        ],
      },
      {
        path: "/reports/customer-ledger",
        title: "Customer Ledger",
        type: "link",
        allowed: false,
        allowedKeys: [
          "Reports_Sales_Tax_Report",
          "Reports_Purchase_Tax_Report",
          "Reports_Sales_Report",
          "Reports_Sales_Return_Report",
          "Reports_Purchase_Report",
          "Reports_Purchase_Payments Report",
          "Reports_Sales_Payments_Report",
        ],
      },
      {
        path: "/reports/customer-orders",
        title: "Customer Orders",
        type: "link",
        allowed: false,
        allowedKeys: ["Reports_Customer_Orders_Report"],
      },

      {
        path: "/reports/supplier-items",
        title: "Supplier Items Report",
        type: "link",
        allowed: false,
        allowedKeys: ["Reports_Supplier_Items_Report"],
      },
      {
        path: "/reports/sales",
        title: "Sales Report",
        type: "link",
        allowed: false,
        allowedKeys: ["Reports_Sales_Report"],
      },

      {
        path: "/reports/purchase",
        title: "Purchase Report",
        type: "link",
        allowed: false,
        allowedKeys: [
          "Reports_Delivery_Sheet_Report",
          "Reports_Purchase_Tax_Report",
          "Reports_Purchase_Report",
        ],
      },

      {
        path: "/reports/expense",
        title: "Expense Report",
        type: "link",
        allowed: false,
        allowedKeys: ["Reports_Expense_Report"],
      },
      {
        path: "/reports/stock",
        title: "Stock Report",
        type: "link",
        allowed: false,
        allowedKeys: ["Reports_Stock_Report"],
      },
      {
        path: "/reports/sales-items",
        title: "Sales Items Report",
        type: "link",
        allowed: false,
        allowedKeys: ["Reports_Sales_Item Report"],
      },

      {
        path: "/reports/sales-payment",
        title: "Sales Payment Report",
        type: "link",
        allowed: false,
        allowedKeys: ["Reports_Sales_Payments_Report"],
      },
      // {
      //   path: '/reports/transactional-report',
      //   title: 'Transactional Report',
      //   type: 'link',
      // },
      // {
      //   path: '/reports/purchase-item-report',
      //   title: 'Purchase Item Report',
      //   type: 'link',
      // },
    ],
  },
  
  {
    title: "Contact Management",
    icon: PhoneIncoming,
    type: "sub",
    active: false,
    allowed: false,
    allowedKeys: [
      "Customers_Add",
      "Customers_Edit",
      "Customers_Delete",
      "Customers_View",
      "Customers_Import Customers",
      "Customers Advance Payments_Add",
      "Customers Advance Payments_Edit",
      "Customers Advance Payments_Delete",
      "Customers Advance Payments_View",
      "Suppliers_Add",
      "Suppliers_Edit",
      "Suppliers_Delete",
      "Suppliers_View",
      "Suppliers_Import Suppliers",
    ],
    children: [
      {
        path: "/add-customers",
        title: "Add Customers",
        type: "link",
        allowed: false,
        allowedKeys: ["Customers_Add", "Customers_Import Customers"],
      },
      {
        path: "/customer-list",
        title: "Customers List",
        type: "link",
        allowed: false,
        allowedKeys: ["Customers_View"],
      },
      {
        path: "/add-supplier",
        title: "Add Supplier ",
        type: "link",
        allowed: false,
        allowedKeys: ["Suppliers_Add", "Suppliers_Import Suppliers"],
      },
      {
        path: "/supplier-list",
        title: "Suppliers List",
        type: "link",
        allowed: false,
        allowedKeys: ["Suppliers_View"],
      },
      {
        path: "/import-customers",
        title: "Import Customers",
        type: "link",
        allowed: false,
        allowedKeys: ["Customers_Add", "Customers_Import Customers"],
      },
      {
        path: "/import-suppliers",
        title: "Import Suppliers",
        type: "link",
        allowed: false,
        allowedKeys: [
          "Suppliers_Add",
          "Suppliers_Edit",
          "Suppliers_Delete",
          "Suppliers_View",
          "Suppliers_Import Suppliers",
        ],
      },
      {
        path: "/attendance-sheet",
        title: "Attendance Sheet",
        type: "link",
        allowed: false,
        allowedKeys: [
          "Suppliers_Add",
          "Suppliers_Edit",
          "Suppliers_Delete",
          "Suppliers_View",
          "Suppliers_Import Suppliers",
        ],
      },
    ],
  },

  {
    title: "Accounts",
    icon: User,
    type: "sub",
    active: false,
    allowed: false,
    allowedKeys: [
      "Accounts_Add",
      "Accounts_Edit",
      "Accounts_Delete",
      "Accounts_View",
      "Accounts_Add Money Deposit",
      "Accounts_Edit Money Deposit",
      "Accounts_Delete Money Deposit",
      "Accounts_View Money Deposit",
      "Accounts_Add Money Transfer",
      "Accounts_Edit Money Transfer",
      "Accounts_Delete Money Transfer",
      "Accounts_View Money Transfer",
      "Accounts_Cash Transactions",
    ],
    children: [
      {
        path: "/accounts/add",
        title: "Add Account",
        type: "link",
        allowed: false,
        allowedKeys: ["Accounts_Add"],
      },
      {
        path: "/accounts/list",
        title: " Accounts List",
        type: "link",
        allowed: false,
        allowedKeys: ["Accounts_View"],
      },
      {
        path: "/accounts/money_transfer",
        title: "Money Transfer List",
        type: "link",
        allowed: false,
        allowedKeys: ["Accounts_View Money Transfer"],
      },
      {
        path: "/accounts/money_deposit/add",
        title: "Add Deposit",
        type: "link",
        allowed: false,
        allowedKeys: ["Accounts_Add Money Deposit"],
      },
      {
        path: "/accounts/money_deposit",
        title: "Deposit List",
        type: "link",
        allowed: false,
        allowedKeys: ["Accounts_View Money Deposit"],
      },
      {
        path: "/accounts/balance-sheet",
        title: "Balance Sheet",
        type: "link",
        allowed: false,
        allowedKeys: ["Accounts_View", "Accounts_Cash Transactions"],
      },
      {
        path: "/accounts/trial-balance",
        title: "Trial Balance ",
        type: "link",
        allowed: false,
        allowedKeys: ["Accounts_View", "Accounts_Cash Transactions"],
      },
      {
        path: "/accounts/cash-flow",
        title: "Cash Flow ",
        type: "link",
        allowed: false,
        allowedKeys: ["Accounts_View", "Accounts_Cash Transactions"],
      },
      {
        path: "/accounts/general-ledger",
        title: "General Ledger ",
        type: "link",
        allowed: false,
        allowedKeys: ["Accounts_View", "Accounts_Cash Transactions"],
      },
    ],
  },
  {
    title: "Expenses",
    icon: TbMoneybag,
    type: "sub",
    active: false,
    allowed: false,
    allowedKeys: [
      "Accounts_Add",
      "Store(Own Store)_edit",
      "Expense_Add",
      "Expense_Edit",
      "Expense_Delete",
      "Expense_View",
      "Expense_Category Add",
      "Expense_Category Edit",
      "Expense_Category Delete",
      "Expense_Category View",
      "Expense_Show all users Expenses",
    ],
    children: [
      {
        path: "/expenses-list",
        title: "Expenses List",
        type: "link",
        allowed: false,
        allowedKeys: ["Accounts_Add", "Store(Own Store)_edit", "Expense_View"],
      },
      {
        path: "/expenses-categories-list",
        title: "Categories List",
        type: "link",
        allowed: false,
        allowedKeys: [
          "Accounts_Add",
          "Store(Own Store)_edit",
          "Expense_Category View",
        ],
      },
    ],
  },

  {
    title: "Branch",
    icon: BiGitMerge,
    type: "sub",
    active: false,
    allowed: false,
    allowedKeys: [
      "Warehouse_Add",
      "Warehouse_Edit",
      "Warehouse_delete",
      "Warehouse_view",
    ],
    children: [
      {
        path: "/branch-add",
        title: "Add Branch",
        type: "link",
        allowed: false,
        allowedKeys: ["Warehouse_Add"],
      },

      {
        path: "/branch-list",
        title: " Branch List",
        type: "link",
        allowed: false,
        allowedKeys: ["Warehouse_view"],
      },
    ],
  },

  {
    title: "Users",
    icon: TbUsers,
    type: "sub",
    active: false,
    allowed: false,
    allowedKeys: [
      "Users_Add",
      "Users_Edit",
      "Users_delete",
      "Users_view",
      "Roles_Add",
      "Roles_Edit",
      "Roles_delete",
      "Roles_view",
    ],
    children: [
      {
        path: "/users-list",
        title: "Users List",
        type: "link",
        allowed: false,
        allowedKeys: ["Users_view"],
      },
      {
        path: "/roles-list",
        title: " Roles List",
        type: "link",
        allowed: false,
        allowedKeys: ["Roles_view"],
      },
    ],
  },

  {
    title: "Settings",
    icon: Settings,
    type: "sub",
    active: false,
    allowed: false,
    allowedKeys: ["Store(Own Store)_edit", "SMS/WhatsApp_Message Settings"],
    children: [
      {
        path: "/AddDeliveryPartner/AddDelivery",
        title: "Add Delivery Partner",
        type: "link",
        allowed: false,
        allowedKeys: ["Store(Own Store)_edit", "SMS/WhatsApp_Message Settings"],
      },
      // { path: '/coupons/list-coupons', title: 'Site Settings', type: 'link' },
      // {
      //   path: '/coupons/list-coupons',
      //   title: 'SMS/WhatsApp API',
      //   type: 'link',
      // },
      // { path: '/coupons/list-coupons', title: 'Tax List', type: 'link' },
      // { path: '/coupons/list-coupons', title: 'Payment Types', type: 'link' },
      // { path: '/coupons/list-coupons', title: 'Change Password', type: 'link' },
      // { path: '/coupons/list-coupons', title: 'Database Backup', type: 'link' },
    ],
  },
];
