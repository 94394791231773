import React from "react";

const InputLoadingTwo = ({ numSections }) => {
  const generateSections = (num) => {
    const sections = [];
    for (let i = 0; i < num; i++) {
      sections.push(
        <div key={i} className="d-flex justify-content-between mb-4">
          <div
            className="bg-body-secondary rounded-1 px-5"
            style={{ width: "48%", height: "35px" }}
          ></div>
          <div
            className="bg-body-secondary rounded-1 px-5"
            style={{ width: "48%", height: "35px" }}
          >
            <div className="bg-body-secondary"></div>
          </div>
        </div>
      );
    }
    return sections;
  };

  return (
    <section className="p-4 w-100">{generateSections(numSections)}</section>
  );
};

export default InputLoadingTwo;
