import React, { Fragment, useRef, useState } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Table,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import BarcodeScanField from './components/BarcodeScanField';
import ListItem from './components/ListItem';
import BarcodeLabel from './components/BarcodeLabel';
import useClickOutside from '../../../hooks/useClickOutside';
import { useReactToPrint } from 'react-to-print';

const LabelsScreen = () => {
  const [selectItem, setSelectItem] = useState([]);
  const [show, setShow] = useState(false);


  const btnStyle = {
    height: '40px',
    width: '40px',
    borderRadius: '50%',
    padding: '8px',
    background: 'transparent',
  };

  const fatchQuery = () => {
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Fragment>
      <Breadcrumb
        title='labels'
        parent='Product Management'
      />
      <Container fluid={true}>
        <Card>
          <CardBody>
            <BarcodeScanField
              setSelectItem={setSelectItem}
              selectItem={selectItem}
            />
          </CardBody>
        </Card>
        <div>
          <Table className='my-3 rounded-2'>
            <thead>
              <tr className='text-center'>
                <th className='text-start'>Item Name</th>

                <th>Quantity</th>

                <th>Action</th>
              </tr>
            </thead>
            <tbody className='text-center'>
              {selectItem &&
                selectItem.map((item) => (
                  <ListItem
                    item={item}
                    setSelectItem={setSelectItem}
                    selectItem={selectItem}
                  />
                ))}
            </tbody>
          </Table>
        </div>
        <div>
          <div className='d-flex col-10 mx-auto justify-content-center my-3'>
            <Button
              className='btn-info mx-3 text-white'
              onClick={() => setShow(true)}>
              Show
            </Button>
            <Button onClick={() => setSelectItem([])}>cancel</Button>
          </div>
          {/*  */}
        </div>
        {show && (
          <>
            <Button onClick={handlePrint}>Print</Button>
            {selectItem.map((item) => (
              <BarcodeLabel
                ref={componentRef}
                item={item}
              />
            ))}
          </>
        )}
      </Container>
    </Fragment>
  );
};

export default LabelsScreen;
