import React, { useEffect, useRef, useState } from 'react'
import { FaBarcode, } from "react-icons/fa6";
import { useForm } from "react-hook-form";
import PosItemNameDropdownSection from '../../../SalesManagement/Pos/PosItemNameDropdownSection';
import useClickOutside from '../../../../hooks/useClickOutside';
import { CustomToast } from '../../../../hooks/useCustomToast';
import axios from '../../../../util/axiosInstance';

const BarcodeScanField = ({ setSelectItem, selectItem }) => {
    const [searchProductInput, setSearchProductInput] = useState("");


    const selectedDropdownMenu = useRef(null);
    const [isSelectedItemDivOpen, setIsSelectedItemDivOpen] = useState(false);
    useClickOutside(selectedDropdownMenu, () => setIsSelectedItemDivOpen(false));
    const [barCodeProducts, setBarCodeProducts] = useState([]);
    // set selected item data
    const handleItemClick = (item) => {
      // Check if the item is already in the selectedItemData array
      // setSelectItem([...selectItem, item])
      setSelectItem([...selectItem, { ...item, itemToPrint: 1 }]);
      setIsSelectedItemDivOpen(false);
    };
    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if (searchProductInput.length >= 3) {
            axios
                .get(
                    `/purchase/searchitem?query=${searchProductInput}`
                )
                .then((response) => {
                    setBarCodeProducts(response.data);
                    if (
                        response.data.some((x) => x.custom_barcode === searchProductInput)
                    ) {
                        reset();
                        const item = response.data.find(
                            (x) => x.custom_barcode === searchProductInput
                        );

                        return handleItemClick(item);
                    }
                    if (response.data.length > 0) {
                        reset();
                        setSearchProductInput("");
                        setIsSelectedItemDivOpen(true);
                    }
                });
        }
    }, [searchProductInput, axios]);



    return (
        <div
            style={{ width: "48%" }}
            className="d-flex border justify-content-between rounded-1 align-items-center"
        >
            <span className="border-end  p-2">
                <FaBarcode />
            </span>
            <div className="w-100 px-1 position-relative">
                <input
                    type="text"
                    className="w-100 border-0"
                    placeholder="Item Name/Barcode/Item Code"
                    title="Item Name/Barcode/Item Code"
                    name="barcodeInput"
                    {...register("barcodeInput")}
                    onChange={(e) => {
                        setSearchProductInput(e.target.value);
                    }}
                />
                {isSelectedItemDivOpen && (
                    <PosItemNameDropdownSection
                        selectedDropdownMenu={selectedDropdownMenu}
                        barCodeProducts={barCodeProducts}
                        handleItemClick={handleItemClick}
                    />
                )}
            </div>
        </div>
    )
}

export default BarcodeScanField