import React from "react";
import PosDiscountItems from "../../SalesManagement/Pos/PosDiscountItems";

const PosTotalDiscountModal = ({
  isOpen,
  toggle,
  setDiscountInputValue,
  discountDropdownValue,
  setDiscountDropdownValue,
  handleSubmitDiscount,
  setIncreaseDiscount,
  setDiscountToAllType,
}) => {
  // fixed this value for discountIndividual and totalDiscount
  const discountIndividual = false;
  const totalDiscount = true;
  return (
    <>
      <PosDiscountItems
        totalDiscount={totalDiscount}
        discountIndividual={discountIndividual}
        isOpen={isOpen}
        toggle={toggle}
        setDiscountInputValue={setDiscountInputValue}
        discountDropdownValue={discountDropdownValue}
        setDiscountDropdownValue={setDiscountDropdownValue}
        handleSubmitDiscount={handleSubmitDiscount}
        setIncreaseDiscount={setIncreaseDiscount}
        setDiscountToAllType={setDiscountToAllType}
      ></PosDiscountItems>
    </>
  );
};

export default PosTotalDiscountModal;
