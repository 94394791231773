import React, { useState, useEffect } from "react";
import Sidebar from "./common/sidebar_components/sidebar";
import RightSidebar from "./common/right-sidebar";
import Footer from "./common/footer";
import Header from "./common/header_components/header";
import { Outlet, useLocation } from "react-router-dom";
import HeaderTwo from "./common/headerCompontsTwo/HeaderTwo";
import loader from "../assets/images/ezgif.com-gif-maker (1).gif"

const App = (props) => {
  const location = useLocation();
  const isSalesManagementPOS = location.pathname.includes(
    "/sales-management/pos"
  );
  const isSalesItemUpdated = location.pathname.includes(
    `/sales-management/sales-item-updated:id`
  );
  const noHeaderFooter = isSalesManagementPOS || isSalesItemUpdated;
  const initialState = {
    ltr: true,
    divName: "RTL",
  };

  const [side, setSide] = useState(initialState);
  const [showLoader, setShowLoader] = useState(true);

  const ChangeRtl = (divName) => {
    if (divName === "RTL") {
      document.body.classList.add("rtl");
      setSide({ divName: "LTR" });
    } else {
      document.body.classList.remove("rtl");
      setSide({ divName: "RTL" });
    }
  };

  useEffect(() => {
    // Simulate a delay to show the loader
    const loaderTimeout = setTimeout(() => {
      setShowLoader(false);
    }, 2000); // Adjust the time as needed

    // Clean up the timeout to prevent memory leaks
    return () => clearTimeout(loaderTimeout);
  }, []);

  return (
    <div>
      {showLoader ? (
        // Display loader while showLoader is true
        <div
          className="loader"
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "32vh", // Adjust as needed for vertical positioning
            textAlign: "center", // Center content horizontally
          }}
        >
          <img width="200px" src={loader} alt='' />
        </div>
      ) : noHeaderFooter ? (
        <div className="page-body">
          <div className="bg-white">
            <HeaderTwo />
          </div>
          <Outlet />
          <Footer />
        </div>
      ) : (
        <div className="page-wrapper">
          <Header />
          <div className="page-body-wrapper">
            <Sidebar />
            <RightSidebar />
            <div className="page-body">
              <Outlet />
            </div>
            <Footer />
          </div>
        </div>
      )}
      <div
        className="btn-light custom-theme"
        onClick={() => ChangeRtl(side.divName)}
      >
        {side.divName}
      </div>
    </div>
  );
};
export default App;