
import { Fragment, useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Container, } from "reactstrap";
import { BiPlusMedical } from "react-icons/bi";
import Breadcrumb from "../../components/common/breadcrumb";
import { AiFillEdit, AiTwotoneDelete } from 'react-icons/ai';
import { Table } from 'reactstrap';
import { Link, useNavigate } from "react-router-dom";

import React, { useRef } from 'react'
import LoadingBar from 'react-top-loading-bar'

import TableLoading from "../../components/common/loading/TableLoading";

import { CustomToast } from "../../hooks/useCustomToast";
import axios from "../../util/axiosInstance";
import Swal from "sweetalert2";





const ExpensesList = () => {
    const btnStyle = {
        height: '40px',
        width: '40px',
        borderRadius: '50%',
        padding: '8px',
        background: 'transparent',
    };

    const navigate = useNavigate();

    const [dropdownValue, setDropdownValue] = useState(10);


    const [progress, setProgress] = useState(100)

    const [expensesList, setExpensesList] = useState([])//main data
    const showAllData = expensesList.length;

    const [expensesListSecondary, setExpensesListSecondary] = useState([])//rendering data
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const [error, setError] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        axios('/expense/view-expenses/')
            .then(data => {
                setExpensesList(data.data);
                setIsLoading(false);  // Stop loading
            })
            .catch(error => {
                setError(error);
                setIsLoading(false);  // Stop loading
            });

    }, [])

    useEffect(() => {
        handleFilter();
    }, [searchTerm, expensesList,])


    // handle  search filter function
    const handleFilter = () => {
        let filterData = [...expensesList]
        if (searchTerm !== "") {
            filterData = expensesList?.filter(item =>
                item.expense_for.toLowerCase().includes(searchTerm.toLowerCase())
            )
        }
        setExpensesListSecondary(filterData);
    }

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value)
    };

    const handleDelete = async (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    // Assuming your API endpoint looks like /api/items/:id
                    const response = await axios.delete(`/expense/delete-expense/${id}`);
                    if (response) {
                        // Filter out the deleted item based on its ID
                        const updatedItems = expensesList.filter(item => item.id !== id);
                        setExpensesList(updatedItems);
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        )
                        // CustomToast('success','Delete Successfully')
                    } else {
                        // console.error("Failed to delete item:", response.data);
                    }
                } catch (error) {
                    // console.error("Error deleting item:", error);
                }
            }
        })
    }

    return (
        <Fragment>
            <Breadcrumb title="Expense List " parent="Expense" />
            <Container fluid={true}>
                <Card>
                    <CardHeader>
                        <div className="d-flex justify-content-between">
                            <h5 className="text-center"> Expenses List </h5>
                            <Link className="d-flex align-items-center gap-2" style={{ backgroundColor: '#00C0EF', textDecoration: 'none', color: 'white', padding: '6px', borderRadius: '5px' }} to={'/expenses-list-add'}> <BiPlusMedical /> New Expense</Link>
                        </div>
                    </CardHeader>
                    <CardBody>

                        <div className="row">
                            <div className="col-md-4 d-flex align-items-center ">
                                <span >Show</span>
                                <select className="mx-1" onClick={(e) => setDropdownValue(e.target.value)}>
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                    <option value={500}>500</option>
                                    <option value={showAllData}>Show All</option>

                                </select>
                                <span>entries</span>
                            </div>
                            <div className="col-md-4 d-flex align-items-center justify-content-end">
                                {/* <span style={{ backgroundColor: '#39CCCC', color: 'white', padding: '8px', borderRadius: '5px', borderRight: '1px solid white', cursor: 'pointer' }}>Copy</span> */}

                            </div>
                            <div className="col-md-4 d-flex align-items-center gap-2">
                                <span>Search </span>

                                <input type="text" placeholder="By Expense For"
                                    value={searchTerm}
                                    onChange={handleSearchChange} className="form-control" />

                            </div>
                        </div>

                        <div style={{ backgroundColor: '#F2F2F2', marginTop: "3px", borderRadius: '5px', padding: '10px' }}>
                            <Table bordered
                                hover
                                striped>
                                <thead className='text-center'>
                                    <tr>
                                        <th>Date </th>
                                        <th>Category</th>
                                        <th>Expense Amount </th>
                                        <th>Account</th>
                                        <th>Payment Type</th>
                                        <th>Expense For</th>
                                        <th>Expense Note</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody className='text-center' >

                                    {
                                        isLoading && (<>
                                            {/* <LoadingBar color="red" style={{ marginTop: '-15px' }}
                                                progress={progress}
                                                onLoaderFinished={() => setProgress(0)}
                                            /> */}

                                            <TableLoading noTd={8} noRow={10} />
                                        </>
                                        )
                                    }

                                    {

                                        !isLoading && (
                                            expensesListSecondary?.slice().sort((a, b) => b.id - a.id).slice(0, dropdownValue).map((expenses, index) => (
                                                <tr key={expenses?.id}>

                                                    <th style={{ fontWeight: '400' }}>{expenses?.created_date.slice(0, 10)}</th>
                                                    <td>{expenses?.category_name}</td>
                                                    <td>{expenses?.expense_amt}</td>
                                                    <td>{expenses?.account_name}</td>
                                                    <td>{expenses?.payment_type}</td>
                                                    <td>{expenses?.expense_for}</td>
                                                    <td>{expenses?.note}</td>
                                                    <td>
                                                        <Link className='fs-4 text-decoration-none' to={`/expenses-list-edit/${expenses.id}`} > <AiFillEdit /></Link>

                                                        <button className='border-0 bg-transparent fs-4' onClick={() => handleDelete(expenses.id)} > <AiTwotoneDelete /></button>
                                                    </td>
                                                </tr>
                                            ))
                                        )
                                    }

                                </tbody>

                            </Table>
                        </div>

                        {/* <div>
                            <nav className=' d-flex justify-content-end align-items-center px-2 col-12 text-black-50 fs-6 bg-white border-top-1'>
                                <span className=' mx-2 my-0 flex-shrink-1 user-select-none'>
                                    Rows per page:
                                </span>
                                <div className=' position-relative flex-shrink-0 mt-1 d-flex '>
                                    <select
                                        aria-label='Rows per page:'
                                        className='sc-bXCLTC eVrcSZ cs-cursor-pointer user-select-none ps-2 pe-4  '
                                        style={{
                                            height: '25px',
                                            maxWidth: '100%',
                                            color: '#000000',
                                            fontSize: 'inherit',
                                            border: 'none',
                                            background: 'transparent',
                                            appearance: 'none',
                                            direction: 'ltr',
                                        }}>
                                        <option
                                            value={10}
                                            selected>
                                            10
                                        </option>
                                        <option value={15}>15</option>
                                        <option value={20}>20</option>
                                        <option value={25}>25</option>
                                        <option value={30}>30</option>
                                    </select>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width={24}
                                        height={24}
                                        viewBox='0 0 24 24'
                                        className='position-absolute top-0'
                                        style={{ right: 0 }}>
                                        <path d='M7 10l5 5 5-5z' />
                                        <path
                                            d='M0 0h24v24H0z'
                                            fill='none'
                                        />
                                    </svg>
                                </div>
                                <span className='mx-4 user-select-none flex-shrink-1'>1-2 of 2</span>
                                <div className='sc-bmzYkS fimDOL d-flex align-items-center rounded-1 white-space-none'>
                                    <button
                                        id='pagination-first-page'
                                        type='button'
                                        aria-label='First Page'
                                        aria-disabled='true'
                                        disabled
                                        className='position-relative d-block user-select-none border-0 rounded-1 cs-cursor-pointer'
                                        style={btnStyle}>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width={24}
                                            height={24}
                                            viewBox='0 0 24 24'
                                            aria-hidden='true'
                                            role='presentation'>
                                            <path d='M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z' />
                                            <path
                                                fill='none'
                                                d='M24 24H0V0h24v24z'
                                            />
                                        </svg>
                                    </button>
                                    <button
                                        id='pagination-previous-page'
                                        type='button'
                                        aria-label='Previous Page'
                                        aria-disabled='true'
                                        disabled
                                        className='position-relative d-block user-select-none border-0 rounded-1 cs-cursor-pointer'
                                        style={btnStyle}>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width={24}
                                            height={24}
                                            viewBox='0 0 24 24'
                                            aria-hidden='true'
                                            role='presentation'>
                                            <path d='M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z' />
                                            <path
                                                d='M0 0h24v24H0z'
                                                fill='none'
                                            />
                                        </svg>
                                    </button>
                                    <button
                                        id='pagination-next-page'
                                        type='button'
                                        aria-label='Next Page'
                                        aria-disabled='true'
                                        disabled
                                        className='position-relative d-block user-select-none border-0 rounded-1 cs-cursor-pointer'
                                        style={btnStyle}>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width={24}
                                            height={24}
                                            viewBox='0 0 24 24'
                                            aria-hidden='true'
                                            role='presentation'>
                                            <path d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z' />
                                            <path
                                                d='M0 0h24v24H0z'
                                                fill='none'
                                            />
                                        </svg>
                                    </button>
                                    <button
                                        id='pagination-last-page'
                                        type='button'
                                        aria-label='Last Page'
                                        aria-disabled='true'
                                        disabled
                                        className='position-relative d-block user-select-none border-0 rounded-1 cs-cursor-pointer'
                                        style={btnStyle}>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width={24}
                                            height={24}
                                            viewBox='0 0 24 24'
                                            aria-hidden='true'
                                            role='presentation'>
                                            <path d='M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z' />
                                            <path
                                                fill='none'
                                                d='M0 0h24v24H0V0z'
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </nav>
                        </div> */}


                    </CardBody>
                </Card>
            </Container>
        </Fragment>
    );
};

export default ExpensesList;
