import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Breadcrumb from '../common/breadcrumb';

function YourComponent() {
  const [formData, setFormData] = useState({
    clientId: '',
    clientSecret: '',
    userName: '',
    password: '',
    grantType:'passsword'
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    // Make an Axios POST request with the form data
    axios
      .post('https://fancymalabis.apodigi.com/pathao/add-env', formData)
      .then((response) => {
        // Handle success, e.g., redirect or show a success message
      })
      .catch((error) => {
        // Handle error, e.g., show an error message
        console.error('Error sending data', error);
      });
  };

  return (
    <div>
         <Breadcrumb title="Pathao Management " parent="Pathao" />
      <div className="mt-10" >
        <div className="col-md-6 col-sm-12" style={{marginBottom:"10px"}}>
          <div className='d-flex justify-content-center align-items-center w-100 '>
            <label htmlFor='clientId' className='fw-bold text-center' style={{ width: '200px' }}>
              Client Id<span className="text-danger">*</span>
            </label>
            <input
              type='text'
              className='form-control'
              name='clientId'
              value={formData.clientId}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="col-md-6 col-sm-12" style={{marginBottom:"10px"}}>
          <div className='d-flex justify-content-center align-items-center w-100 '>
            <label htmlFor='clientSecret' className='fw-bold text-center' style={{ width: '200px' }}>
              Client Secret<span className="text-danger">*</span>
            </label>
            <input
              type='text'
              className='form-control'
              name='clientSecret'
              value={formData.clientSecret}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="col-md-6 col-sm-12" style={{marginBottom:"10px"}}>
          <div className='d-flex justify-content-center align-items-center w-100 '>
            <label htmlFor='userName' className='fw-bold text-center' style={{ width: '200px' }}>
              User Name<span className="text-danger">*</span>
            </label>
            <input
              type='text'
              className='form-control'
              name='userName'
              value={formData.userName}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="col-md-6 col-sm-12">
          <div className='d-flex justify-content-center align-items-center w-100 '>
            <label htmlFor='password' className='fw-bold text-center' style={{ width: '200px' }}>
              Password<span className="text-danger">*</span>
            </label>
            <input
              type='password'
              className='form-control'
              name='password'
              value={formData.password}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>
      <div className="row mb-2" style={{ marginLeft: "200px" }}>
        <div className='d-flex align-items-center mt-3 gap-5'>
          <button
            onClick={handleSubmit}
            style={{ backgroundColor: '#00A65A', border: 'none', color: 'white', padding: '6px 28px', borderRadius: '5px' }}
          >
            Submit
          </button>
          <Link to={'/dashboard'} style={{ backgroundColor: '#F39C12', border: 'none', color: 'white', padding: '6px 28px', borderRadius: '5px', textDecoration: 'none' }}>
            Close
          </Link>
        </div>
      </div>
    </div>
  );
}

export default YourComponent;
