import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  Breadcrumb,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import axios from '../../../util/axiosInstance';
import { useNavigate, useParams } from 'react-router-dom';

const EditAccounts = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [token, setToken] = useState('');
  const [AccountDetail, setAccountDetail] = useState('');
  const { account_name, account_code, balance, note } = AccountDetail || {};
  useEffect(() => {
    const accessToken = localStorage.getItem('access-token');
    setToken(accessToken);
  }, []);
  const headers = useMemo(() => {
    return {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
  }, [token]);

  useEffect(() => {
    axios
      .get(`/accounts/viewaccount/${id}`, { headers })
      .then((response) => {
        setAccountDetail(response.data);
      })
      .catch((err) => {
      });
  }, [headers, id]);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const { account_name, account_code, balance, note } = data || {};

    const updateData = {
      ...AccountDetail,
      account_name,
      account_code,
      balance,
      note,
    };
    axios
      .put(`/accounts/editaccount/${id}`, updateData, { headers })
      .then((response) => {
        navigate('/accounts/list');
      })
      .catch((err) => {
      });
  };
  return (
    <Fragment>
      <Breadcrumb
        title='Edit Account'
        parent='Account'
      />
      <Container fluid={true}>
        <Card>
          <CardHeader>
            <h5>Update Account</h5>
          </CardHeader>
          <CardBody>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <div className='col-6'>
                  <FormGroup className='d-flex justify-content-between'>
                    <Label className='col-3'>account name :</Label>
                    <input
                      placeholder='account_name'
                      defaultValue={account_name}
                      type='text'
                      className='form-control'
                      {...register('account_name', { required: true })}
                    />
                    {/* errors will return when field validation fails  */}
                    {errors?.phone && (
                      <span className='pt-1 text-danger'>
                        This field is required
                      </span>
                    )}
                  </FormGroup>
                  <FormGroup className='d-flex justify-content-between'>
                    <Label className='col-3'>account Code :</Label>
                    <input
                      // placeholder='aaccount_code'
                      defaultValue={account_code}
                      type='text'
                      className='form-control'
                      {...register('account_code', { required: true })}
                    />
                    {/* errors will return when field validation fails  */}
                    {errors?.phone && (
                      <span className='pt-1 text-danger'>
                        This field is required
                      </span>
                    )}
                  </FormGroup>
                  <FormGroup className='d-flex justify-content-between'>
                    <Label className='col-3'>account Balance:</Label>
                    <input
                      placeholder='balance'
                      defaultValue={balance}
                      type='text'
                      className='form-control'
                      {...register('balance', { required: true })}
                    />
                    {/* errors will return when field validation fails  */}
                    {errors?.phone && (
                      <span className='pt-1 text-danger'>
                        This field is required
                      </span>
                    )}
                  </FormGroup>
                </div>
                <div className='col-6'>
                  <FormGroup>
                    <Label className='pb-3'>Note :</Label>
                    <textarea
                      placeholder='note'
                      defaultValue={note}
                      type='text'
                      className='form-control'
                      {...register('note', { required: true })}
                    />
                    {/* errors will return when field validation fails  */}
                    {errors?.phone && (
                      <span className='pt-1 text-danger'>
                        This field is required
                      </span>
                    )}
                  </FormGroup>
                </div>
              </Row>
              <div className='form-button'>
                <Button
                  // color='primary'
                  className='bg-navy border-0 mt-3'
                  type='submit'>
                  Update account
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
};

export default EditAccounts;
