import React, { Fragment } from 'react';
import { Button, Card, CardBody, CardHeader, Col } from 'reactstrap';
import Breadcrumb from '../../../components/common/breadcrumb';

const CashTransactions = () => {
  return (
    <Fragment>
      <Breadcrumb
        title='Cash Transectiond'
        parent='Account'
      />
      <Col sm='12'>
        <Card>
          <CardHeader className='d-flex justify-content-between'>
            <h5>Cash Transectiond</h5>
            {/* <Link to='/accounts/money_transfer/add' className='bg-info py-2 text-decoration-none me-4 px-4 rounded-1'>+ New money Transfer </Link> */}
          </CardHeader>

          <CardBody>

          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};

export default CashTransactions;
