import React, { Fragment } from "react";
import Breadcrumb from "./common/breadcrumb";


import { Chart } from "react-google-charts";

import { Bar, Line } from "react-chartjs-2";
import {
	lineOptions,
	buyOption,
	employeeData,
	employeeOptions,
} from "../constants/chartData";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	BarController,
	BarElement,
	ArcElement,
	Filler,
	RadialLinearScale
} from 'chart.js';


// image impoer
import user2 from "../assets/images/dashboard/user2.jpg";
import user1 from "../assets/images/dashboard/user1.jpg";
import man from "../assets/images/dashboard/man.png";
import user from "../assets/images/dashboard/user.png";
import designer from "../assets/images/dashboard/designer.jpg";
import {
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	Media,
	Row,
	Table,
} from "reactstrap";
import DashboardCard from "./DashboardSection/DashboardCard/DashboardCard";
import { Briefcase, Calendar, CreditCard, ShoppingCart } from "react-feather";
import LatestOrderTable from "./DashboardSection/LatestOrderTable/LatestOrderTable";
import BarChart from "./DashboardSection/BarChart/BarChart";
import AccountBalanceTable from "./DashboardSection/AccountBalanceTable/AccountBalanceTable";
import RecentCustomerAndDue from "./DashboardSection/RecentCustomerAndDue/RecentCustomerAndDue";
import AcountingCard from "./DashboardSection/AccountingCard/AccountingCard";
import RecentSales from "./DashboardSection/RecentSales/RecentSales";
import AlertQty from "./AlertQty";
import { useSelector } from "react-redux";


ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	BarController,
	BarElement,
	ArcElement,
	Filler,
	RadialLinearScale
);

const Dashboard = () => {
	const userPermissions = useSelector(state => state.user.userAuth?.permissions )


const permissions = [
	"Dashboard_View Dashboard Data",
	'Dashboard_Financial Report box',
	'Dashboard_Sales Report graph',
	"Dashboard_Purchase And Sales Chart",
	"Dashboard_Recently Added Items List",
	"Dashboard_Stock Alert List",
	"Dashboard_Trending Items Chart",
	"Dashboard_Recent Sales Invoice List",
  ]


	return (
		<Fragment>
			<Breadcrumb title="Dashboard" parent="Dashboard" />
			{userPermissions.some(permission => permissions.includes(permission) ) ? 

				<Container fluid={true}>
					<Row>
					{
						userPermissions.some(permission => permission === 'Dashboard_Financial Report box') ? 
						<DashboardCard /> : null

					}
					{
						userPermissions.some(permission => permission === 'Dashboard_Sales Report graph') ? 
						<AcountingCard />  : null
					}
						
					
					{
						/*
						*/
					}
						
						<Col xl="6 xl-100">
						{
							userPermissions.some(permission => permission === 'Dashboard_Financial Report box') ? 
							<AccountBalanceTable /> : null
	
						}
							
						</Col>
				
						<Col xl="6 xl-100">
						{
							userPermissions.some(permission => permission === 'Dashboard_Financial Report box') ? 
							<RecentCustomerAndDue /> : null
	
						}
							
						</Col>
	
						{/* buy sell cart  */}
	
						{/* <Col sm="12">
							<Card>
								<CardHeader>
									<h5>Buy / Sell </h5>
								</CardHeader>
								<CardBody className="sell-graph">
									<Line
										data={buyData}
										options={buyOption}
										width={700}
										height={350}
									/>
								</CardBody>
							</Card>
						</Col> */}
	
						{/* buy sell cart  */}
						{
							userPermissions.some(permission => permission === 'Dashboard_Sales Report graph') ? 
							<BarChart />  : null
						}
	
	
	
						{/* Top selling Product (2023)   */}
						{/* <Col xl="6 xl-50" md='6' sm="12">
							<Card>
								<CardBody>
									<div className="order-graph sm-order-space">
										<h5 style={{ fontSize: '20px', fontWeight: '500' }}>Top selling Product (2023)</h5>
										<div className="">
											<Chart
												chartType="PieChart"
												data={[
													["Task", "Hours per Day"],
													["Saint Lucia", 300],
													["Kenya", 50],
													["Liberia", 100],
												]}
												options={pieOptions}
												graph_id="PieChart"
												width={"100%"}
												height={"180px"}
												legend_toggle
											/>
										</div>
										<div className="order-graph-bottom sales-location">
											<Media>
												<div className="order-shape-primary"></div>
												<Media body>
													<h6 className="mb-0 me-0">
														Germany <span className="pull-right">25%</span>
													</h6>
												</Media>
											</Media>
											<Media>
												<div className="order-shape-secondary"></div>
												<Media body>
													<h6 className="mb-0 me-0">
														Brasil <span className="pull-right">10%</span>
													</h6>
												</Media>
											</Media>
											<Media>
												<div className="order-shape-danger"></div>
												<Media body>
													<h6 className="mb-0 me-0">
														United Kingdom
														<span className="pull-right">34%</span>
													</h6>
												</Media>
											</Media>
											<Media>
												<div className="order-shape-warning"></div>
												<Media body>
													<h6 className="mb-0 me-0">
														Australia<span className="pull-right">5%</span>
													</h6>
												</Media>
											</Media>
											<Media>
												<div className="order-shape-success"></div>
												<Media body>
													<h6 className="mb-0 me-0">
														Canada <span className="pull-right">25%</span>
													</h6>
												</Media>
											</Media>
										</div>
									</div>
								</CardBody>
							</Card>
						</Col> */}
	
						{/* Top Selling Products table */}
						<Col xl="6 xl-100">
						{
							userPermissions.some(permission => permission === "Dashboard_Recent Sales Invoice List" || "Dashboard_View Dashboard Data" ) ? <LatestOrderTable /> : null 
						}


							
						</Col>
						{
							userPermissions.some(permission => permission === "Dashboard_Trending Items Chart" || "Dashboard_View Dashboard Data" ) ? 
							<RecentSales/> : null
						}
	
	
						<Col xl="12 xl-100">
						{
							userPermissions.some(permission => permission === "Dashboard_Stock Alert List" || "Dashboard_View Dashboard Data"  ) ?
							<Card className="height-equal">
								<CardHeader>
									<h5>Stock Alert</h5>
								</CardHeader>
								<CardBody>
								 <div className="user-status table-responsive products-table">
									<AlertQty/>
								</div> 
								
									
								</CardBody>
							</Card>: null
								}
						</Col>
	
	
	{/* sales status revenue line chart  */}
						{/* <Col sm="12">
							<Card>
								<CardHeader>
									<h5>Sales Status</h5>
								</CardHeader>
								<CardBody>
									<Row>
										<Col xl="12 xl-100">
											<div className="order-graph xl-space">
												<h6>Revenue for last month</h6>
												<div className="ct-4 flot-chart-container">
													<Line type="area" data={employeeData} options={employeeOptions} />
												</div>
											</div>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col> */}
	
	
	
					</Row>
				</Container> : null

			}
		</Fragment>
	);
};

// javascript:void(0)

export default Dashboard;
