import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import useClickOutside from "../../../hooks/useClickOutside";
import { useRef } from "react";

const PosDropdownSection = ({
  borderEnd,
  setInputValueTwo,
  inputType,
  setInputValue,
  InitialDropdownValue,
  data,
  titleData,
  placeHolderData,
  setValueDropdown,
  setWarehouseDropdownValue, // Pass the setWarehouseDropdownValue prop
  setSelectedItemData,
  setWarehouseDropdownId,
  setFirstCustomerDropdownId,
  setDiscountToAllType,
  setCustomerDropdownSalesDue,
  setCustomerDropdownValue, // Pass the setCustomerDropdownValue prop
  setPaymentStatusDropdownValue,
  setPaymentTypeTwoDropdownValue,
  setAccountTypeTwoDropdownValue,
  fullWidth,
  setStoreId,
  setProductTypeId,
  setDeliveryTypeId,
  initialCustomerId,
  setSelectedCustomerId,
}) => {
  const [dropdownData, setDropdownData] = useState([]);
  const [dropdownValue, setDropdownValue] = useState(InitialDropdownValue);
  useEffect(() => {
    setDropdownValue(InitialDropdownValue);
  }, [InitialDropdownValue]);

  const [dropdownValueCustomers, setDropdownValueCustomers] =
    useState(InitialDropdownValue);
  const [dropdownValueThree, setDropdownValueThree] =
    useState(InitialDropdownValue);

  const [paymentTypeDropdownValue, setPaymentTypeDropdownValue] =
    useState(InitialDropdownValue);

  const [accountTypeDropdownValue, setAccountTypeDropdownValue] =
    useState(InitialDropdownValue);
  const [storeDropdownValue, setStoreDropdownValue] =
    useState(InitialDropdownValue);
  const [searchValue, setSearchValue] = useState("");

  // customer initial id
  const [initialCustomerDropdownValueId, setInitialCustomerDropdownValueId] =
    useState(initialCustomerId);

  useEffect(() => {
    setSelectedCustomerId &&
      setSelectedCustomerId(initialCustomerDropdownValueId);
  }, [initialCustomerDropdownValueId, setSelectedCustomerId]);

  const dropdownMenuOne = useRef(null);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  useClickOutside(dropdownMenuOne, () => setIsDropdownOpen(false));

  useEffect(() => {
    handleFilter();
  }, [searchValue, data]);

  // handle search
  const handleFilter = () => {
    let dropdown = [...data];
    if (searchValue !== "") {
      dropdown = dropdown?.filter((item) =>
        (
          item?.warehouse_name ||
          item?.customer_name ||
          item?.name ||
          item?.phone ||
          item?.account_name ||
          item?.store_name ||
          item?.payment_type
        )
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      );
    }
    setDropdownData(dropdown);
  };

  return (
    <>
      <Dropdown
        ref={dropdownMenuOne}
        className={`w-100   ${
          borderEnd &&
          ((borderEnd === "warehouse" && "border-start") ||
            (borderEnd === "customerCall" && "border-end border-start"))
        } `}
        show={isDropdownOpen}
        onToggle={() => setIsDropdownOpen((x) => !x)}
      >
        <Dropdown.Toggle
          title={titleData && titleData}
          id="dropdown-basic"
          className="border-0 w-100 text-start fw-normal"
          style={{ backgroundColor: "#ffffff" }}
          value={
            dropdownValue ||
            accountTypeDropdownValue ||
            dropdownValueCustomers ||
            dropdownValueThree ||
            storeDropdownValue ||
            paymentTypeDropdownValue
          }
        >
          {dropdownValue ||
            accountTypeDropdownValue ||
            dropdownValueCustomers ||
            dropdownValueThree ||
            storeDropdownValue ||
            paymentTypeDropdownValue}
        </Dropdown.Toggle>

        <Dropdown.Menu
          style={{
            maxHeight: "25vh",
            boxShadow: "0px 0px 30px 10px rgb(197, 194, 194)",
            width: `${
              (fullWidth && "100%") || (inputType === "number" ? "100%" : "60%")
            }`,
          }}
          className="overflow-auto rounded-2 h-auto d-flex mt-1 flex-column pt-1 p-2 gap-1"
        >
          {inputType === "search" && (
            <input
              type="text"
              style={{ fontSize: "15px" }}
              className="w-100 border text-dark rounded-1 mb-1 text-input px-2 bg-body-secondary text-input"
              placeholder={placeHolderData && placeHolderData}
              onChange={(e) => {
                setSearchValue && setSearchValue(e.target.value);
                setInputValue && setInputValue(e.target.value);
              }}
            />
          )}
          {inputType === "number" && (
            <input
              type="number"
              step={"any"}
              style={{ fontSize: "15px" }}
              required
              className="w-100 border text-dark rounded-1 text-input py-1 px-2 bg-body-secondary text-input"
              placeholder={placeHolderData && placeHolderData}
              onChange={(e) => {
                setSearchValue && setSearchValue(e.target.value);
                setInputValue && setInputValue(e.target.value);
                setInputValueTwo && setInputValueTwo(e.target.value);
              }}
            />
          )}

          {/* main dropdown section */}
          {inputType === "search" && dropdownData.length === 0 && (
            <p className="text-center text-danger fw-bold">No Data Found</p>
          )}
          {inputType &&
            (
              (inputType === "number" && data) ||
              (inputType === "search" && dropdownData)
            )?.map((item, index) => (
              <Dropdown.Item
                key={index}
                className="w-100 text-center text-dark border rounded-1 bg-black-hover p-0"
                onClick={() => {
                  setStoreId && setStoreId(item?.store_id);
                  setProductTypeId && setProductTypeId(item?.value);
                  setDeliveryTypeId && setDeliveryTypeId(item?.value);
                  setWarehouseDropdownId && setWarehouseDropdownId(item?.id);
                  setPaymentTypeTwoDropdownValue &&
                    setPaymentTypeTwoDropdownValue(item?.payment_type);
                  setAccountTypeTwoDropdownValue &&
                    setAccountTypeTwoDropdownValue(item?.account_name);
                  setPaymentStatusDropdownValue &&
                    setPaymentStatusDropdownValue(item?.name);
                  setStoreDropdownValue(item?.store_name);
                  setWarehouseDropdownValue &&
                    setWarehouseDropdownValue(
                      item?.warehouse_name || item?.store_name
                    );
                  setSelectedItemData && setSelectedItemData([]);
                  setDropdownValue(item?.warehouse_name) ||
                    setAccountTypeDropdownValue(item?.account_name) ||
                    setPaymentTypeDropdownValue(item?.payment_type) ||
                    setInitialCustomerDropdownValueId(item?.id) ||
                    setDropdownValueCustomers(
                      item?.customer_name &&
                        `${item?.customer_name}(${
                          item?.phone || "phone number"
                        })`
                    ) ||
                    setDropdownValueThree(item?.name);
                  setValueDropdown && setValueDropdown(item?.name);
                  setDiscountToAllType && setDiscountToAllType(item?.name);
                  setFirstCustomerDropdownId &&
                    setFirstCustomerDropdownId(item?.id);
                  setCustomerDropdownSalesDue &&
                    setCustomerDropdownSalesDue(item?.sales_due);
                  setCustomerDropdownValue &&
                    setCustomerDropdownValue(item?.customer_name);
                }}
              >
                <option
                  className="color-white-hover "
                  style={{ fontSize: "15px", wordWrap: "break-word" }}
                  value={
                    item?.payment_type ||
                    item?.account_name ||
                    item?.warehouse_name ||
                    item?.store_name ||
                    (item?.customer_name &&
                      `${item?.customer_name}(${
                        item?.phone || "phone number"
                      })`) ||
                    item?.name
                  }
                >
                  {item?.warehouse_name ||
                    item?.account_name ||
                    item?.payment_type ||
                    item?.store_name ||
                    (item?.customer_name &&
                      `${item?.customer_name}(${
                        item?.phone || "phone number"
                      })`) ||
                    item?.name}
                </option>
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default PosDropdownSection;
