import { Fragment, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from "reactstrap";

import Breadcrumb from "../../../components/common/breadcrumb";

import { Link, useNavigate, useParams } from "react-router-dom";

import { useForm } from "react-hook-form";
import axios from "../../../util/axiosInstance";

const BrandEdit = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [singleBrandList, setSingleBrandList] = useState([]);
  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`/brand/viewbrands/${id}`)
      .then((res) => setSingleBrandList(res.data))
      .catch((error) => {
        setError(error);
        setIsLoading(false); // Stop loading
      });
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const updatedData = {
      ...data,
      status: 1,
    };
    axios
      .put(`/brand/editbrand/${id}`, updatedData)
      .then((res) => {
        navigate("/product-management/brand-list");
      })
      .catch((err) => {});
  };

  return (
    <Fragment>
      <Breadcrumb title="Brand List Edit" parent="Product Management" />
      <Container fluid={true}>
        <Card>
          <CardHeader>
            <div>
              <h5 className="text-center"> Please Enter Valid Data</h5>
            </div>
          </CardHeader>
          <CardBody>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <FormGroup>
                <Label for="exampleName">Brand Name</Label>
                <input
                  Value={singleBrandList.brand_name}
                  className="form-control"
                  {...register("brand_name", { required: true })}
                />

                {errors.brand_name && (
                  <span className="text-danger">This field is required</span>
                )}
              </FormGroup>

              <FormGroup className="d-none">
                <Label for="exampleName">Brand Status</Label>
                <input
                  defaultValue={1}
                  className="form-control"
                  {...register("status", { required: false })}
                />
                {errors.status && (
                  <span className="text-danger">This field is required</span>
                )}
              </FormGroup>
              <FormGroup row>
                <Label for="exampleText">Description</Label>
                <Col>
                  <textarea
                    defaultValue={singleBrandList?.description}
                    {...register("description", { required: false })}
                    type="textarea"
                    className="form-control"
                  />
                </Col>
                {errors.description && (
                  <span className="text-danger">This field is required</span>
                )}
              </FormGroup>
              <div className="d-flex gap-5">
                <input
                  type="submit"
                  value={"Update"}
                  style={{
                    backgroundColor: "green",
                    border: "none",
                    padding: "12px 20px",
                    color: "white",
                    borderRadius: "5px",
                  }}
                />
                <Link
                  to={"/dashboard"}
                  style={{
                    backgroundColor: "red",
                    textDecoration: "none",
                    border: "none",
                    padding: "12px 20px",
                    color: "white",
                    borderRadius: "5px",
                  }}
                >
                  {" "}
                  Close{" "}
                </Link>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
};

export default BrandEdit;
