import React from "react";
import useNumberFormatter from "../../../hooks/useNumberFormatter";

const ViewSalesPaymentTable = ({ product, sl }) => {
  const numericFormatter = useNumberFormatter();

  const {
    id,
    payment_type,
    payment_note,
    payment,
    payment_date,
    account_name,
  } = product || {};
  return (
    <>
      <tr>
        <td>{sl + 1}</td>
        <td>{payment_date?.slice(0, 10) || "-"}</td>
        <td>{payment_type || "-"}</td>
        <td>{account_name || "-"}</td>
        <td>{payment_note || "-"}</td>
        <td>{numericFormatter(payment) || 0}</td>
      </tr>
    </>
  );
};

export default ViewSalesPaymentTable;
