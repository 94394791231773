import React from "react";
import { FaBuilding } from "react-icons/fa";
import useGetData from "../../../hooks/useGetData";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { BiPlusMedical } from "react-icons/bi";
import PosDropdownSection from "../../SalesManagement/Pos/PosDropdownSection";
import SalesListTableSection from "../../SalesManagement/SalesList/SalesListTableSection";

const PurchaseReturnListInformationTableSection = () => {
  const salesTable = [
    {
      id: 10,
      name: "SL",
    },
    {
      id: 11,
      name: "Date",
    },
    {
      id: 12,
      name: "Purchase Code",
    },
    {
      id: 13,
      name: "Return Code",
    },
    {
      id: 14,
      name: "Return Status",
    },
    {
      id: 15,
      name: "Reference No.",
    },
    {
      id: 16,
      name: "Supplier Name",
    },
    {
      id: 17,
      name: "Total",
    },
    {
      id: 18,
      name: "Paid Payment",
    },
    {
      id: 19,
      name: "Payment Status",
    },
    {
      id: 20,
      name: "Action",
    },
  ];

  // get data from backend
  const [branchData] = useGetData("/branch/viewbranch", "branch");
  const firstBranch = branchData && branchData[0]?.warehouse_name;
  const fistBranchId = branchData && branchData[0]?.id;
  const [wareHouseSearch, setWareHouseSearch] = useState("");
  const [wareHouseDropdownValue, setWarehouseDropdownValue] = useState("");
  const [selectedItemData, setSelectedItemData] = useState([]);
  const [wareHouseDropdownId, setWarehouseDropdownId] = useState("");

  useEffect(() => {
    setWarehouseDropdownValue(firstBranch);
    setWarehouseDropdownId(fistBranchId);
  }, [firstBranch, fistBranchId]);
  return (
    <>
      {/* section-1 */}
      <section className="d-flex justify-content-between align-items-center p-4 pb-0">
        <div
          style={{ width: "48%" }}
          className=" d-flex justify-content-between align-items-center border border rounded-1 "
          title="Warehouse"
        >
          <span className=" px-2">
            <FaBuilding className="text-danger"></FaBuilding>
          </span>
          {/* dropdown data */}
          {firstBranch && (
            <PosDropdownSection
              borderEnd={"warehouse"}
              inputType={"search"}
              setInputValue={setWareHouseSearch}
              InitialDropdownValue={"All Branches"}
              setWarehouseDropdownValue={setWarehouseDropdownValue}
              data={branchData}
              titleData={"Warehouse"}
              placeHolderData={"Enter Branch Name"}
              dataField={"warehouse_name"}
              setSelectedItemData={setSelectedItemData}
              setWarehouseDropdownId={setWarehouseDropdownId}
            ></PosDropdownSection>
          )}
        </div>
        {/* div-2 */}
        <div
          style={{ width: "48%" }}
          className="d-flex  justify-content-end align-items-center "
        >
          <Link
            style={{
              textDecoration: "none",
              backgroundColor: "#093d86",
            }}
            to={"/purchase-management/new-purchase"}
            className="border rounded-2 text-white py-2 px-3 d-flex justify-content-center gap-1 align-items-center  fw-bold c-pos-button6  "
          >
            <BiPlusMedical className=""></BiPlusMedical> Create New
          </Link>
        </div>
      </section>
      {/* section-2 */}
      <section className="mt-5 mb-4 px-4">
        <div className="row">
          <div className="col-md-6 d-flex align-items-center">
            <span>Show</span>
            <select
              // onChange={(e) => setSelectedValue(e.target.value)}
              className="mx-1"
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={500}>500</option>
              {/* <option value={allData}>AllData</option> */}
            </select>
            <span>entries</span>
          </div>
          <div className="col-md-6 d-flex align-items-center justify-content-end">
            <div className="d-flex align-items-center gap-2 gap-2 w-50">
              <label htmlFor="search" className="fw-bold">
                Search:
              </label>
              <input
                type="text"
                id="search"
                className="cs-text-input border text-input"
                placeholder="Enter Customer Name"
                // value={searchValue}
                // onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
      </section>
      <SalesListTableSection tableHeader={salesTable}></SalesListTableSection>
    </>
  );
};

export default PurchaseReturnListInformationTableSection;
