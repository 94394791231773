import React from 'react';

const PurchaseItemReport = () => {
 return (
  <div>
   PurchaseItemReport
  </div>
 );
};

export default PurchaseItemReport;