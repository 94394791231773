import React, { useEffect, useState } from 'react'
import { Button, Table } from 'reactstrap';
import TableLoading from '../../../../components/common/loading/TableLoading';
import { useNavigate } from 'react-router-dom';
import axios from '../../../../util/axiosInstance';
import moment from 'moment';
import NoDataFoundTr from '../../../../components/common/NoDataFoundTr';
import SalesPaymentListTable from '../../../SalesManagement/SalesPayment/SalesPaymentListTable';

const token = localStorage.getItem('access-token');
const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${token}`,
};

const SalesPaymentTable = ({ queryLink }) => {
  const [isLoading, setLoading] = useState(false);
  const [dataToRander, setDataToRander] = useState([]);

  console.log(dataToRander);
  const navigate = useNavigate();
  const btnStyle = {
    height: '40px',
    width: '40px',
    borderRadius: '50%',
    padding: '8px',
    background: 'transparent',
  };

  // useEffect(() => {
  // axios.get('/report/sales-by-customer/79?startDate=2023-01-01&endDate=2023-12-31')
  // /report/sales-by-customer/75?startDate=2023-10-07&endDate=2023-10-07

  // }, [queryLink])

  const fatchQuery = () => {
    setLoading(true);
    axios
      .get(queryLink)
      .then((response) => {
        setDataToRander(response?.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <div>
      <div className='d-flex col-10 mx-auto justify-content-center my-3'>
        
        <Button
          className='btn-info mx-3 text-white'
          onClick={fatchQuery}>
          SHOW
        </Button>

        {/* <Button onClick={navigate('/')}  >cancel</Button> */}
      </div>
      <Table
        bordered
        hover
        striped>
        <thead className='text-center'>
          <tr>
            <th>SL</th>
            <th>Payment Date</th>
            <th>Payment Code</th>
            <th>Sales Code</th>
            <th>Customer Name</th>
            <th>Customer Id</th>
            <th>Payment</th>
            <th>Account Name</th>
            <th>Payment Type</th>
            <th>Payment Status</th>
          </tr>
        </thead>
        <tbody className='text-center'>
          {dataToRander?.length === 0 && isLoading ? (
            <TableLoading
              noRow={9}
              noTd={8}
            />
          ) : dataToRander?.length === 0 && !isLoading ? (
            <NoDataFoundTr colSpanNumber={8} />
          ) : (
            dataToRander?.map((item, i) => {
              return (
                <SalesPaymentListTable  product={item} sl={i} ></SalesPaymentListTable>
                // <tr>
                //   <th scope='row'>{i + 1}</th>
                //   <td>{moment(item?.sales.sales_date).format('L')}</td>
                //   <td>{item?.sales.sales_code}</td>
                //   <td>{item?.sales.reference_no || '-'}</td>
                //   <th>Description</th>
                //   <td>
                //     {item?.salesItems?.reduce((x, y) => x + y?.sales_qty, 0)}
                //   </td>
                //   <td>{item?.sales.grand_total}</td>
                //   <td>{item?.sales.paid_amount}</td>
                //   {/* <td>{item.sales.grand_total}</td> */}
                // </tr>
              );
            })
          )}
          {/* {
                        dataToRander.length > 0 && (
                            <tr>
                                <th scope='row' colSpan={5}>Total </th>
                                <th>{dataToRander.reduce((x, y) => {
                                    return y.purchase_qty ? x + y.purchase_price * (y.purchase_qty) : x + y.purchase_price * (y.stock)
                                }, 0)} </th>
                            </tr>

                        )
                    } */}

          {/* <TableLoading /> */}
        </tbody>
      </Table>

      <div className='Toastify' />
    </div>
  );
};

export default SalesPaymentTable


/**
 customer_id
: 
79
discount_to_all_input
: 
0
discount_to_all_type
: 
"Percentage(%)"
grand_total
: 
8400
init_code
: 
"SL"
paid_amount
: 
84000
payment_status
: 
"Paid"
reference_no
: 
null
sales_code
: 
"20231003021601"
sales_date
: 
"2023-10-02T04:00:00.000Z"
sales_id
: 
124
sales_note
: 
null
sales_status
: 
"Final"
tot_discount_to_all_amt
: 
null
warehouse_id
: 
2


 

description
: 
null
discount_amt
: 
0
discount_input
: 
0
discount_type
: 
"Percentage(%)"
item_id
: 
116
lot_number
: 
"Select-Lot"
price_per_unit
: 
2100
purchase_price
: 
null
sales_qty
: 
4
total_cost
: 
8400
unit_total_cost
: 
null
 */