import React from "react";
import PosMultipleCash from "../../SalesManagement/Pos/PosMultipleCash";

const PosCashModal = ({
  customerDueLimit,
  isOpen,
  toggle,
  totalItems,
  total,
  discount,
  totalPayable,
  setCashDueBalanceData,
  setPayingBalanceData,
  setChangeReturnData,
  setAccountPaymentIdDropdown,
  handleCashPaymentDataSubmitSave,
  handleCashPaymentDataSubmit,
  handleMultiplePaymentDataSubmitSave,
  handleResetAllData,
  multipleData,
  cashData,
  paidValue,
  setPaidValue,
  checked,
  setChecked,
}) => {
  const multiplePayments = false,
    cashPayment = true;

  return (
    <>
      <PosMultipleCash 
      customerDueLimit = {customerDueLimit}
      checked={checked}
      setChecked={setChecked}
        isOpen={isOpen}
        toggle={toggle}
        multiplePayments={multiplePayments}
        cashPayment={cashPayment}
        totalItems={totalItems}
        total={total}
        discount={discount}
        totalPayable={totalPayable}
        setCashDueBalanceData={setCashDueBalanceData}
        setPayingBalanceData={setPayingBalanceData}
        setChangeReturnData={setChangeReturnData}
        setAccountPaymentIdDropdown={setAccountPaymentIdDropdown}
        handleCashPaymentDataSubmit={handleCashPaymentDataSubmit}
        handleCashPaymentDataSubmitSave={handleCashPaymentDataSubmitSave}
        handleMultiplePaymentDataSubmitSave = {handleMultiplePaymentDataSubmitSave}
        handleResetAllData={handleResetAllData}
        multipleData={multipleData}
        cashData={cashData}
        paidValue={paidValue}
        setPaidValue={setPaidValue}
      ></PosMultipleCash>
    </>
  );
};

export default PosCashModal;
