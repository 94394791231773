import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import PosMultipleAddPayment from "./PosMultipleAddPayment";
import PosPaymentsItems from "./PosPaymentsItems";
import useNumberFormatter from "../../../hooks/useNumberFormatter";
import PosMultipleCashPayment from "./PosMultipleCashPayment";

const PosMultipleCash = ({
  customerDueLimit,
  isOpen,
  toggle,
  multiplePayments,
  cashPayment,
  totalItems,
  total,
  discount,
  totalPayable,
  setCashDueBalanceData,
  setPayingBalanceData,
  setChangeReturnData,
  setAccountPaymentIdDropdown,
  setMultiplePayingBalanceData,
  setMultipleAccountPaymentIdDropdown,
  setMultiplePaymentType,
  setPaymentDropdownValueTwo,
  setAccountDropdownValueTwo,
  setMultiplePayingBalanceDataTwo,
  multiplePaymentCalculationData,
  setCashModalOpen,
  setMultipleModalOpen,
  handleMultiplePaymentDataSubmitSave,
  handleMultiplePaymentDataSubmit,
  handleCashPaymentDataSubmitSave,
  handleCashPaymentDataSubmit,
  paidValue,
  setPaidValue,
  checked,
  setChecked,
}) => {
  const {
    totalPayingAmountMultipleSection,
    changeReturnDataTwo,
    totalDueAmountMultipleSection,
    setTotalDueAmountMultipleSection,
    setTotalPayingAmountMultipleSection,
    setChangeReturnDataTwo,
  } = multiplePaymentCalculationData || {};

  const numericFormatter = useNumberFormatter();
  const [paymentDropdownValue, setPaymentDropdownValue] =
    useState("Payment Method");
  const [accountDropdownValue, setAccountDropdownValue] =
    useState("Select Account");

  const [anotherPaymentSections, setAnotherPaymentSections] = useState([]);
  const [sectionsId, setSectionsId] = useState(101);

  const [dueBalance, setDueBalance] = useState(0);
  const [changeReturn, setChangeReturn] = useState(0);
  const [showButton, setShowButton] = useState(true);

  // calculation due balance
  const calculationBalance = () => {
    let dueBalance;
    if (paidValue < totalPayable) {
      dueBalance = totalPayable - paidValue;
    } else {
      dueBalance = 0;
    }
    setDueBalance(dueBalance);
    setCashDueBalanceData && setCashDueBalanceData(dueBalance);
  };

  // calculation change return
  const calculationChangeReturn = () => {
    let changeReturn;
    if (paidValue > totalPayable) {
      changeReturn = paidValue - totalPayable;
    } else {
      changeReturn = 0;
    }
    setChangeReturn(changeReturn);
    setChangeReturnData && setChangeReturnData(changeReturn);
  };

  useEffect(() => {
    calculationBalance();
    calculationChangeReturn();
  }, [paidValue]);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const [isCashPayment, setIsCashPayment] = useState(false);
  const [isCashPaymentPrint, setIsCashPaymentPrint] = useState(false);
  const [isMultiplePayment, setIsMultiplePayment] = useState(false);
  const [isMultiplePaymentPrint, setIsMultiplePaymentPrint] = useState(false);

  const onSubmit = () => {
    isCashPayment && handleCashPaymentDataSubmitSave();
    isCashPaymentPrint && handleCashPaymentDataSubmit();
    isMultiplePayment && handleMultiplePaymentDataSubmitSave();
    isMultiplePaymentPrint && handleMultiplePaymentDataSubmit();
    checked && 
    reset();
    toggle();
  };

  // const handle multiple payment section created but click on add payment button then show the payment section
  const multiplePaymentSections = (
    <PosMultipleAddPayment
      multiplePayments={multiplePayments}
      cashPayment={cashPayment}
      sectionsId={sectionsId}
      closeButton={true}
      setAnotherPaymentSections={setAnotherPaymentSections}
      anotherPaymentSections={anotherPaymentSections}
      register={register}
      setPaidValue={setPaidValue}
      dueBalance={dueBalance}
      setPayingBalanceData={setPayingBalanceData}
      setMultiplePayingBalanceData={setMultiplePayingBalanceData}
      setMultipleAccountPaymentIdDropdown={setMultipleAccountPaymentIdDropdown}
      setPaymentDropdownValueTwo={setPaymentDropdownValueTwo}
      setAccountDropdownValueTwo={setAccountDropdownValueTwo}
      setMultiplePayingBalanceDataTwo={setMultiplePayingBalanceDataTwo}
      setShowButton={setShowButton}
    ></PosMultipleAddPayment>
  );
  const handleMultiplePaymentSection = () => {
    setAnotherPaymentSections([
      ...anotherPaymentSections,
      multiplePaymentSections,
    ]);
    setSectionsId(sectionsId + 1);
  };
  console.log('object',  numericFormatter(customerDueLimit), typeof( numericFormatter(customerDueLimit)) );

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        setAnotherPaymentSections([]);
        setShowButton(true);
        toggle();
      }}
      size="lg"
      style={{ maxWidth: "65%", minHeight: "80%" }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader
          className="d-flex justify-content-center  align-items-center"
          style={{
            backgroundImage: "linear-gradient(to right, #4b0ab3, #090080)", // Gradient background
          }}
        >
          {multiplePayments && (
            <p className="text-white w-100 m-0 fw-bold fs-5">
              Multiple Payments
            </p>
          )}
          {cashPayment && (
            <p className="text-white w-100 m-0  fw-bold fs-5">Cash Payment</p>
          )}
        </ModalHeader>
        <ModalBody className="d-flex justify-content-around py-4 px-2 align-items-start">
          {/* section-1 */}
          <div className="d-flex flex-column gap-4" style={{ width: "63%" }}>
            {/* fixed section */}
            <PosMultipleCashPayment
              setCashModalOpen={setCashModalOpen}
              setMultipleModalOpen={setMultipleModalOpen}
              multiplePayments={multiplePayments}
              cashPayment={cashPayment}
              paymentDropdownValue={paymentDropdownValue}
              register={register}
              setPaymentDropdownValue={setPaymentDropdownValue}
              accountDropdownValue={accountDropdownValue}
              setAccountDropdownValue={setAccountDropdownValue}
              setPaidValue={setPaidValue}
              totalPayable={totalPayable}
              setPayingBalanceData={setPayingBalanceData}
              setAccountPaymentIdDropdown={setAccountPaymentIdDropdown}
              setMultiplePayingBalanceData={setMultiplePayingBalanceData}
              setMultipleAccountPaymentIdDropdown={
                setMultipleAccountPaymentIdDropdown
              }
              setMultiplePaymentType={setMultiplePaymentType}
            ></PosMultipleCashPayment>
            {/* extra payment section */}
            {anotherPaymentSections?.slice(0, 1).map((item, i) => (
              <React.Fragment key={i}>{item}</React.Fragment>
            ))}
            {/* payment note button */}
            {showButton && multiplePayments && (
              <Button
                onClick={() => {
                  handleMultiplePaymentSection();
                  setShowButton(false);
                }}
                className={`w-100 border-0 mt-4 c-pos-button5 `}
                style={{
                  backgroundImage:
                    "linear-gradient(to right, #275874, #002980)",
                }}
              >
                Add Multiple Payment
              </Button>
            )}
          </div>
          {/* section-2 */}
          <div
            className="rounded-2 text-white"
            style={{
              width: "32%",
              backgroundColor: "#082247",
            }}
          >
            <div className="d-flex flex-column py-3 gap-1">
              <PosPaymentsItems
                title={"Total Items"}
                value={totalItems ? numericFormatter(totalItems) : 0}
              ></PosPaymentsItems>
              <PosPaymentsItems
                title={"Total"}
                value={`৳ ${total ? numericFormatter(total) : 0}`}
                bgColor={"#009107"}
              ></PosPaymentsItems>
              <PosPaymentsItems
                title={"Discount"}
                value={`৳ ${discount ? numericFormatter(discount) : 0}`}
              ></PosPaymentsItems>
              <PosPaymentsItems
                title={"Total Payable"}
                value={`৳ ${totalPayable ? numericFormatter(totalPayable) : 0}`}
                bgColor={"#c40000"}
              ></PosPaymentsItems>
              {/* multiplePayments payment */}
              {multiplePayments && (
                <>
                  <PosPaymentsItems
                    title={"Total Paying"}
                    value={`৳ ${
                      totalPayingAmountMultipleSection
                        ? numericFormatter(totalPayingAmountMultipleSection)
                        : 0
                    }`}
                  ></PosPaymentsItems>
                  <PosPaymentsItems
                    title={"Due"}
                    value={`৳ ${
                      totalDueAmountMultipleSection
                        ? numericFormatter(totalDueAmountMultipleSection)
                        : 0
                    }`}
                  ></PosPaymentsItems>

                  <PosPaymentsItems
                    title={"Change Return"}
                    value={`৳ ${
                      changeReturnDataTwo
                        ? numericFormatter(changeReturnDataTwo)
                        : 0
                    }`}
                    bgColor={"#cc6816"}
                  ></PosPaymentsItems>
                </>
              )}
              {/* cashPayment payments */}
              {cashPayment && (
                <>
                  <PosPaymentsItems
                    title={"Total Paying"}
                    value={`৳ ${paidValue ? numericFormatter(paidValue) : 0}`}
                  ></PosPaymentsItems>
                  <PosPaymentsItems
                    title={"Due"}
                    value={`৳ ${dueBalance ? numericFormatter(dueBalance) : 0}`}
                  ></PosPaymentsItems>

                  <PosPaymentsItems
                    title={"Change Return"}
                    value={`৳ ${
                      changeReturn ? numericFormatter(changeReturn) : 0
                    }`}
                    bgColor={"#cc6816"}
                  ></PosPaymentsItems>
                </>
              )}
            </div>
          </div>
        </ModalBody>
        {/* Multiple payment buttons */}
        <div className="text-center " > 
        <span className="fs-2 mx-auto text-white py-2 px-3 " style={{backgroundColor: "#082247"  }}>
        This Employee can buy by due {Number(numericFormatter(customerDueLimit|| 0))}
        </span>
         </div>


        {multiplePayments &&  (
          <div className="modal-footer justify-content-center  border-0">
          
            <Button
              style={{ backgroundColor: "green" }}
              type="submit"
              className="text-white"
              disabled ={Number(numericFormatter(customerDueLimit || 0)) ===0}
              onClick={() => {
                setIsMultiplePayment(true);
                setIsMultiplePaymentPrint(false);
                setIsCashPayment(false);
                setIsCashPaymentPrint(false);
                setCashDueBalanceData && setCashDueBalanceData(0);
                setPayingBalanceData && setPayingBalanceData(0);
                setChangeReturnData && setChangeReturnData(0);
                setAccountPaymentIdDropdown('');
                setPaidValue && setPaidValue(0)
              }}
            >
              Save
            </Button>
            <Button
              type="submit"
              style={{ backgroundColor: "green" }}
              color="green"
              className="text-white"
              disabled ={Number(numericFormatter(customerDueLimit|| 0)) < 0}
              onClick={() => {
                setIsMultiplePaymentPrint(true);
                setIsMultiplePayment(false);
                setIsCashPayment(false);
                setIsCashPaymentPrint(false);
                setCashDueBalanceData(0);
                setPayingBalanceData(0);
                setChangeReturnData(0);
                setAccountPaymentIdDropdown('');
                setPaidValue(0);
                setAccountDropdownValue("Cash")
                
              }}
            >
              Save & Print
            </Button>{" "}
            <Button
              className="text-white"
              style={{ backgroundColor: "red" }}
              color="red"
              onClick={() => {
                toggle();
                reset();
                setTotalDueAmountMultipleSection(0);
                setTotalPayingAmountMultipleSection(0);
                setChangeReturnDataTwo(0);
                setCashDueBalanceData(0);
                setPayingBalanceData(0);
                setChangeReturnData(0);
                setAccountPaymentIdDropdown('');
                setPaidValue(0);
                setAccountDropdownValue("Cash")
                
              }}
            >
              Cancel
            </Button>
          </div>
        )}
        {/* cash payment buttons */}
        {cashPayment && (
          <div className="modal-footer justify-content-center  border-0">
            <Button
              style={{ backgroundColor: "green" }}
              type="submit"
              className="text-white"
              disabled ={Number(numericFormatter(customerDueLimit|| 0)) - Number(numericFormatter(dueBalance|| 0))<= 0 }
              onClick={() => {
                setIsCashPayment(true);
                setIsCashPaymentPrint(false);
                setIsMultiplePayment(false);
                setIsMultiplePaymentPrint(false);
              }}
            >
              Save
            </Button>{" "}
            {/* print components */}
            <Button
              style={{ backgroundColor: "green" }}
              color="green"
              type="submit"
              className="text-white"
              // disabled ={Number(numericFormatter(customerDueLimit))===0 }
              disabled ={Number(numericFormatter(customerDueLimit || 0)) - Number(numericFormatter(dueBalance || 0)) <= 0 }
              onClick={() => {
                setIsCashPaymentPrint(true);
                setIsCashPayment(false);
                setIsMultiplePayment(false);
                setIsMultiplePaymentPrint(false);
              }}
            >
              Save & Print
            </Button>
            <Button
              className="text-white"
              style={{ backgroundColor: "red" }}
              color="red"

              onClick={() => {
                toggle();
                reset();
                setPaidValue(0);
                setDueBalance(0);
                setChangeReturn(0);
              }}
            >
              Cancel
            </Button>
          </div>
        )}
      </form>
    </Modal>
  );
};

export default PosMultipleCash;
