import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { FaCalendarAlt } from "react-icons/fa";
import { FaSackDollar } from "react-icons/fa6";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
} from "reactstrap";
import ViewPaymentsDetails from "./ViewPaymentsDetails";
import PosDropdownSection from "../../Pos/PosDropdownSection";
import useGetData from "../../../../hooks/useGetData";
import useNumberFormatter from "../../../../hooks/useNumberFormatter";
import { useEffect } from "react";
import useAxiosSecure from "../../../../hooks/useAxiosSecure";
import { CustomToast } from "../../../../hooks/useCustomToast";

const ReceivePaymentsModal = ({ isOpen, toggle, data }) => {
  const [axios] = useAxiosSecure();
  const numericFormatter = useNumberFormatter();
  const { sales_id, sales_code, paid_amount, grand_total, customer_id } =
    data || {};

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  // get data from backend
  const [paymentTypeData] = useGetData(
    "/paymenttype/viewpaymenttypes/",
    "paymenttypeData"
  );
  const [accountDropDownData] = useGetData(
    "/accounts/viewaccounts/",
    "accounts"
  );
  const [dateValue, setDateValue] = useState("");
  const [amountValue, setAmountValue] = useState(0);
  const [paymentTypeDropdownValue, setPaymentTypeTwoDropdownValue] = useState(
    "Select-Payment-Type"
  );
  const [accountTypeDropdownValue, setAccountTypeTwoDropdownValue] =
    useState("Select-Account");
  const [paymentNoteTwo, setPaymentNoteTwo] = useState("");
  const [updateChangeReturn, setUpdateChangeReturn] = useState("");
  const [accountDropdownId, setAccountDropdownId] = useState(null);
  useEffect(() => {
    const updatedPayment =
      numericFormatter(paid_amount || 0) + parseFloat(amountValue || 0);
    if (updatedPayment > grand_total) {
      setUpdateChangeReturn(updatedPayment - grand_total);
    }
  }, [amountValue, grand_total, paid_amount]);

  const newPaymentsData = {
    sales_id: sales_id,
    payment_code: sales_code,
    payment_date: dateValue || null,
    payment_type: paymentTypeDropdownValue || null,
    payment: numericFormatter(amountValue || 0),
    payment_note: paymentNoteTwo || null,
    change_return: numericFormatter(updateChangeReturn || 0),
    account_id: accountDropdownId,
    customer_id: customer_id,
  };
  const onSubmit = () => {
    axios
      .post(`/sales/add-payment/${sales_id}`, newPaymentsData)
      .then((res) => {
        if (res.status === 200 || res.status === 201 || res.status === 202) {
          CustomToast("success", "Payment Added Successfully");
          reset();
          toggle();
        }
      })
      .catch(() => {
        CustomToast("error", "Payment Added Failed");
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      style={{ maxWidth: "50%" }}
    >
      <ModalHeader
        className="d-flex justify-content-center  align-items-center"
        style={{
          backgroundImage: "linear-gradient(to right, #4b0ab3, #090080)", // Gradient background
        }}
      >
        <p className="text-white w-100 m-0 fw-bold fs-5">Make Payments</p>
      </ModalHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <ViewPaymentsDetails data={data}></ViewPaymentsDetails>
          {/* section one */}
          <section className="d-flex justify-content-between align-items-center mt-3">
            <div style={{ width: "48%" }}>
              <h5 className="text-start fw-500">Date</h5>
              <div
                style={{ width: "100%" }}
                className="d-flex border justify-content-between rounded-1 align-items-center"
              >
                <span className="border-end  p-2">
                  <FaCalendarAlt />
                </span>
                <div className="w-100 px-1 position-relative">
                  <input
                    type="date"
                    className="w-100 border-0"
                    name="dateInput"
                    onChange={(e) => {
                      setDateValue(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div style={{ width: "48%" }}>
              <h5 className="text-start fw-500">Amount</h5>
              <div
                style={{ width: "100%" }}
                className="d-flex border justify-content-between rounded-1 align-items-center"
              >
                <span className="border-end  p-2">
                  <FaSackDollar />
                </span>
                <div className="w-100 px-1 position-relative">
                  <input
                    type="number"
                    className="w-100 border-0"
                    placeholder="Enter Amount"
                    name="amountInput"
                    {...register("amountInput", { required: true })}
                    onChange={(e) => {
                      setAmountValue(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </section>
          {/* section two */}
          <section className="d-flex justify-content-between align-items-center my-3">
            <div style={{ width: "48%" }}>
              <h5 className="text-start fw-500">Payment Type</h5>
              <div
                style={{ width: "100%" }}
                className=" d-flex justify-content-between align-items-center border border rounded-1 "
                title="Warehouse"
              >
                {/* dropdown data */}
                {paymentTypeData && (
                  <PosDropdownSection
                    inputType={"search"}
                    InitialDropdownValue={"Select-Payment-Type"}
                    data={paymentTypeData || []}
                    titleData={"Payment Type"}
                    placeHolderData={"Enter Payment Type"}
                    setPaymentTypeTwoDropdownValue={
                      setPaymentTypeTwoDropdownValue
                    }
                  ></PosDropdownSection>
                )}
              </div>
            </div>
            <div style={{ width: "48%" }}>
              <h5 className="text-start fw-500">Account</h5>
              <div
                style={{ width: "100%" }}
                className="  d-flex justify-content-between align-items-center border rounded-1"
                title="Customer"
              >
                {accountDropDownData && (
                  <PosDropdownSection
                    fullWidth={true}
                    inputType={"search"}
                    InitialDropdownValue={"Select-Account"}
                    data={accountDropDownData || []}
                    titleData={"Select Account"}
                    placeHolderData={"Enter Account"}
                    setAccountTypeTwoDropdownValue={
                      setAccountTypeTwoDropdownValue
                    }
                    setWarehouseDropdownId={setAccountDropdownId}
                  ></PosDropdownSection>
                )}
              </div>
            </div>
          </section>
          {/* payment note */}
          <div className=" mt-3" style={{ width: "100%" }}>
            <FormGroup>
              <Label for="paymentNoteTwo" className="mb-1 ">
                Payment Note
              </Label>
              <textarea
                id="paymentNoteTwo"
                rows={2}
                placeholder="Enter Payment Note"
                className="cs-text-input  text-input"
                onChange={(e) => {
                  setPaymentNoteTwo(e.target.value);
                }}
              ></textarea>
            </FormGroup>
          </div>
        </ModalBody>
        <div className="modal-footer border-0">
          <Button color="secondary" type="reset" onClick={toggle}>
            Cancel
          </Button>
          <Button
            style={{ backgroundColor: "green" }}
            color="primary"
            type="submit"
            className="btn-add"
            // onClick={handleUpdatePayment}
          >
            Save
          </Button>{" "}
        </div>
      </form>
    </Modal>
  );
};

export default ReceivePaymentsModal;
