import React from "react";
import useGetData from "../../../hooks/useGetData";
import { useParams } from "react-router-dom";
import SalesReturnHome from "./SalesReturnHome";

const SalesReturnProducts = () => {
  const { id } = useParams();
  const [salesReturnProduct, salesReturnProductRefetch] = useGetData(
    `/sales/view-sales/${id}`,
    "salesProduct"
  );
  return (
    <>
      <SalesReturnHome
        salesReturnProduct={salesReturnProduct}
      ></SalesReturnHome>
    </>
  );
};

export default SalesReturnProducts;
