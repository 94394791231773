import React from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import PurchaseReturnAmountSection from "./PurchaseReturnAmountSection";
import PurchaseReturnListInformationTableSection from "./PurchaseReturnListInformationTableSection";

const PurchaseReturnList = () => {
  return (
    <>
      <Breadcrumb
        title='Purchase Return List'
        parent='Purchase Management'
      />
      <main
        className=' w-100 px-2 h-auto'
        style={{ maxHeight: '90vh' }}>
        <section>
          <PurchaseReturnAmountSection></PurchaseReturnAmountSection>
        </section>
        <section
          className='bg-white rounded-2 mt-5'
          style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
          <PurchaseReturnListInformationTableSection></PurchaseReturnListInformationTableSection>
        </section>
      </main>
    </>
  );
};

export default PurchaseReturnList;
