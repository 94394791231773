import React, { Fragment } from 'react';
import { Card, Container } from 'react-bootstrap';
import Breadcrumb from '../../../components/common/breadcrumb';

const StockList = () => {
 return (
  <>
   <Breadcrumb title="Stock  List" parent="Stock Management" />
   <Container fluid={true}>
    <Card>
     Stock List section
    </Card>
   </Container>
  </>
 );
};

export default StockList;