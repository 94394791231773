
import React, { Fragment, useState } from 'react';

import { BsFillBagCheckFill } from "react-icons/bs";
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md";
import {
 Card,
 CardBody,
 Container,
} from 'reactstrap';

import Breadcrumb from '../../../components/common/breadcrumb';
import { Link } from 'react-router-dom';

import { BsCartPlusFill } from "react-icons/bs";
import { TbMoneybag } from "react-icons/tb";
import { BsFillBarChartFill } from "react-icons/bs";
import './ReportsManagementIndex.css';
const ReportsManagementIndex = () => {

 const [isOpen, setIsOpen] = useState(false);

 const toggleAccordion = () => {
  setIsOpen(!isOpen);
 };


 // sales   
 const [salesReport, setReport] = useState(false);
 const handleSalesClick = () => {
  setReport(!salesReport)
 }
 
 // Accounting According  
 const [accounting, setAccounting] = useState(false);
 const handleAccordingClick = () => {
  setAccounting(!accounting)
 }

 // Inventory According  
 const [inventory, setInventory] = useState(false);
 const handleInventoryClick = () => {
  setInventory(!inventory)
 }

 // Purchase According  
 const [purchase, setPurchase] = useState(false);
 const handlePurchaseClick = () => {
  setPurchase(!purchase)
 }



 return (
  <Fragment>
   <Breadcrumb
    title='Reports'
    parent='Reports Management'
   />
   <Container fluid={true}>
    <Card>

     <CardBody>
      <div className='row overflow-hidden'>
       <div className="col-md-3">
        <div className='shadow-sm'>
         <div onClick={handleSalesClick} className=' d-flex gap-5 align-items-center justify-content-between' style={{ padding: '10px', backgroundColor: '#0D0D44', cursor: 'pointer' }}>
          <div className='d-flex gap-2 align-items-center text-white'>
           <BsFillBagCheckFill style={{ fontSize: '25px', }} />
           <span style={{ fontSize: '20px', fontWeight: '600', }}>SALES REPORTS </span>
          </div>
          <span>{salesReport ? <MdOutlineKeyboardArrowDown style={{ color: '#FFF', fontSize: '30px' }} /> : <MdOutlineKeyboardArrowUp style={{ color: '#FFF', fontSize: '30px' }} />}  </span>
         </div>


         {salesReport &&
          <>
           <div className='pt-3'  style={{ backgroundColor: '#E8ECF5', padding: '5px 20px', marginBottom: '-5px', transitionDuration: '10s' }}>
           <Link to={''} style={{textDecoration:'none'}} >
            <div className='h4 fw-bold' style={{ color: 'black' }}> Customer Sales Report</div>
            <div className='p' style={{ color: 'black', textAlign:"justify" }}>This report display all customer wise sales report</div>
            </Link>
            <hr />
           </div>

           <div className=''  style={{ backgroundColor: '#E8ECF5', padding: '5px 20px', marginBottom: '-5px', transitionDuration: '10s' }}>
           <Link to={'/reports/sales'} target='blank' style={{textDecoration:'none'}}> 
            <div className='h4 fw-bold' style={{ color: 'black' }}> Customer Wise Item Sales</div>
            <div className='p' style={{ color: 'black', textAlign:"justify" }}> This report displays all customer wise item sales report </div>
            <hr />
            </Link>
           </div>

           <div className=''  style={{ backgroundColor: '#E8ECF5', padding: '5px 20px', marginBottom: '-5px', transitionDuration: '10s' }}>
            <div className='h4 fw-bold' style={{ color: 'black' }}> Item Wise Sales </div>
            <div className='p' style={{ color: 'black', textAlign:"justify" }}>This report display all customer wise sales report</div>
            <hr />
           </div>

           <div className=''  style={{ backgroundColor: '#E8ECF5', padding: '5px 20px', marginBottom: '-5px', transitionDuration: '10s' }}>
           <Link to={'/reports/customer-ledger'} target='blank' style={{textDecoration:'none'}}> 
            <div className='h4 fw-bold' style={{ color: 'black' }}> Customer Ledger</div>
            <div className='p' style={{ color: 'black', textAlign:"justify" }}> This report displays a listing of all transactions for selected customer, within a specified date range. </div>
            <hr />
            </Link>
           </div>
           <div className=''  style={{ backgroundColor: '#E8ECF5', padding: '5px 20px', marginBottom: '-5px', transitionDuration: '10s' }}>
            <div className='h4 fw-bold' style={{ color: 'black' }}> User Wise Sales </div>
            <div className='p' style={{ color: 'black', textAlign:"justify" }}>  This report helps you to know which user brought how much sales  </div>
            <hr />
           </div>

          </>}
        </div>
       </div>

       <div className="col-md-3">
        <div className='shadow-sm'>
         <div onClick={handleAccordingClick} className=' d-flex gap-5 align-items-center justify-content-between' style={{ padding: '10px', backgroundColor: '#0D0D44', cursor: 'pointer' }}>
          <div className='d-flex gap-2 align-items-center text-white'>
           <BsFillBarChartFill style={{ fontSize: '25px', }} />
           <span style={{ fontSize: '20px', fontWeight: '600', }}>Accounting </span>
          </div>
          <span>{accounting ? <MdOutlineKeyboardArrowDown style={{ color: '#FFF', fontSize: '30px' }} /> : <MdOutlineKeyboardArrowUp style={{ color: '#FFF', fontSize: '30px' }} />}  </span>
         </div>


         {accounting &&
          <>
           <div className='pt-3' style={{ backgroundColor: '#E8ECF5', padding: '5px 20px', marginBottom: '-5px', transitionDuration: '10s' }}>
            <Link target='blank' to={'/reports/customer-ledger'} style={{textDecoration: 'none'}}> 
            <div className='h4 fw-bold' style={{ color: 'black' }}> General Ledger</div>
            <div className='p ' style={{ color: 'black', textAlign:"justify" }}>  Represents the record-keeping system for a company’s financial data, with debit and credit account records validated by a trial balance.  </div>
            <hr />
            </Link>
           </div>

           <div className='overflow-hidden 'style={{ backgroundColor: '#E8ECF5', padding: '5px 20px', marginBottom: '-5px', transitionDuration: '10s' }}>
            <div className='h4 fw-bold' style={{ color: 'black' }}> Account Balance</div>
            <div className='p' style={{ color: 'black' , textAlign:"justify"}}> an account balance is a report used to display company transaction against each accounting with a specified date range </div>
            <hr />
           </div>

           

            <div className='overflow-hidden' style={{ backgroundColor: '#E8ECF5', padding: '5px 20px', marginBottom: '-5px', transitionDuration: '10s' }}>
             <Link target='blank' to={'/reports/profit-loss'} style={{textDecoration:'none'}}>             <div className='h4 fw-bold' style={{ color: 'black' }}>  Profit And Loss </div>
             <div className='p' style={{ color: 'black' }}> This reports profit and loss within a date range</div>
             <hr />
             </Link>
 
            </div>

           <div className='overflow-hidden' style={{ backgroundColor: '#E8ECF5', padding: '5px 20px', marginBottom: '-5px', transitionDuration: '10s' }}>
            <div className='h4 fw-bold' style={{ color: 'black' }}> Balance Sheet </div>
            <div className='p' style={{ color: 'black' , textAlign:"justify"}}> This report shows all account balances within a date range</div>
            <hr />
           </div>
            <div className='overflow-hidden' style={{ backgroundColor: '#E8ECF5', padding: '5px 20px', marginBottom: '-5px', transitionDuration: '10s' }}>
             <div className='h4 fw-bold' style={{ color: 'black' }}>  Trial Balnce </div>
             <div className='p' style={{ color: 'black', textAlign:"justify" }}> List all the accounts holding either a debit balance or a credit balance contained in the leadger of a business within given range. </div>
             <hr />
            </div>
           <div className='overflow-hidden' style={{ backgroundColor: '#E8ECF5', padding: '5px 20px', marginBottom: '-5px', transitionDuration: '10s' }}>
            <div className='h4 fw-bold' style={{ color: 'black' }}> Balance Sheet </div>
            <div className='p' style={{ color: 'black' , textAlign:"justify"}}> This report shows all account balances within a date range</div>
            <hr />

           </div>

            <div className='overflow-hidden' style={{ backgroundColor: '#E8ECF5', padding: '5px 20px', marginBottom: '-5px', transitionDuration: '10s' }}>
             <div className='h4 fw-bold' style={{ color: 'black' }}>  Transaction History </div>
             <div className='p text-justify' style={{ color: 'black', textAlign:"justify" }}> Provides the detailed information in the form of general entry of each transation in the system </div>
             <hr />
            </div>
          </>
         }
        </div>
       </div>

       <div className="col-md-3">
        <div className='shadow-sm'>
         <div onClick={handleInventoryClick} className=' d-flex gap-5 align-items-center justify-content-between' style={{ padding: '10px', backgroundColor: '#0D0D44', cursor: 'pointer' }}>
          <div className='d-flex gap-2 align-items-center text-white'>
           <TbMoneybag style={{ fontSize: '25px', }} />
           <span style={{ fontSize: '20px', fontWeight: '600', }}>Inventory </span>
          </div>
          <span>{inventory ? <MdOutlineKeyboardArrowDown style={{ color: '#FFF', fontSize: '30px' }} /> : <MdOutlineKeyboardArrowUp style={{ color: '#FFF', fontSize: '30px' }} />}  </span>
         </div>


         {inventory &&
          <>
           <div className='pt-3' style={{ backgroundColor: '#E8ECF5', padding: '5px 20px', marginBottom: '-5px', transitionDuration: '10s' }}>
            <div className='h4 fw-bold' style={{ color: 'black' }}> Stock Ledger</div>
            <div className='p' style={{ color: 'black', textAlign:'justify' }}>   This report displays a listing of all transactions for selected item, within a specified date range.   </div>
            <hr />
           </div>

           <div className='overflow-hidden' style={{ backgroundColor: '#E8ECF5', padding: '5px 20px', marginBottom: '-5px', transitionDuration: '10s' }}>
           <Link to={'/reports/stock'} target='blank' style={{textDecoration:'none'}}>
            <div className='h4 fw-bold' style={{ color: 'black' }}> Item Stock Summery</div>
            <div className='p' style={{ color: 'black', textAlign:'justify'}}>  This report shows all item's stock availability and low stock point and can also filter  </div>
            <hr />
            </Link>
           </div>

           <div className='overflow-hidden' style={{ backgroundColor: '#E8ECF5', padding: '5px 20px', marginBottom: '-5px', transitionDuration: '10s' }}>
            <div className='h4 fw-bold' style={{ color: 'black' }}> Warehouse Stock Details </div>
            <div className='p' style={{ color: 'black', textAlign:'justify'}}>  This report displays item stock details in your warehouse </div>
            <hr />
           </div>
            <div className='overflow-hidden' style={{ backgroundColor: '#E8ECF5', padding: '5px 20px', marginBottom: '-5px', transitionDuration: '10s' }}>
             <div className='h4 fw-bold' style={{ color: 'black' }}>  Item Stock Location  </div>
             <div className='p' style={{ color: 'black' , textAlign:'justify'}}>  This report displays item stock location details in all bin and warehouse </div>
             <hr />
            </div>

           <div className='overflow-hidden' style={{ backgroundColor: '#E8ECF5', padding: '5px 20px', marginBottom: '-5px', transitionDuration: '10s' }}>
            <div className='h4 fw-bold' style={{ color: 'black' }}> Low Stock Details </div>
            <div className='p' style={{ color: 'black' , textAlign:'justify'}}>   It will lists all the products which are short in stock based on minimum stock levels defined against each product. </div>
            <hr />
           </div>

            <div className='overflow-hidden' style={{ backgroundColor: '#E8ECF5', padding: '5px 20px', marginBottom: '-5px', transitionDuration: '10s' }}>
             <div className='h4 fw-bold' style={{ color: 'black' }}>  Item Stock Valuation  </div>
             <div className='p' style={{ color: 'black', textAlign:'justify' }}>  This report helps user to verify average cost calculation and changes by the period of time.  </div>
             <hr />
            </div>

           <div className='overflow-hidden' style={{ backgroundColor: '#E8ECF5', padding: '5px 20px', marginBottom: '-5px', transitionDuration: '10s' }}>
            <div className='h4 fw-bold' style={{ color: 'black' }}> Stock Valuation Summary </div>
            <div className='p' style={{ color: 'black', textAlign:'justify' }}>   It will lists all the products which are short in stock based on minimum stock levels defined against each product. </div>
            <hr />
           </div>
            <div className='overflow-hidden' style={{ backgroundColor: '#E8ECF5', padding: '5px 20px', marginBottom: '-5px', transitionDuration: '10s' }}>
             <div className='h4 fw-bold' style={{ color: 'black' }}>  Warehouse Transaction History  </div>
             <div className='p' style={{ color: 'black' , textAlign:'justify'}}>   Allows you to gain helpful insights about your warehouse transaction history </div>
             <hr />
            </div>

           <div className='overflow-hidden' style={{ backgroundColor: '#E8ECF5', padding: '5px 20px', marginBottom: '-5px', transitionDuration: '10s' }}>
            <div className='h4 fw-bold' style={{ color: 'black' }}> Low Stock Details </div>
            <div className='p' style={{ color: 'black', textAlign:'justify' }}>   It will lists all the products which are short in stock based on minimum stock levels defined against each product. </div>
            <hr />
           </div>
            <div className='overflow-hidden' style={{ backgroundColor: '#E8ECF5', padding: '5px 20px', marginBottom: '-5px', transitionDuration: '10s' }}>
             <div className='h4 fw-bold' style={{ color: 'black' }}>  Warehouse Transaction History  </div>
             <div className='p' style={{ color: 'black', textAlign:'justify' }}>   Allows you to gain helpful insights about your warehouse transaction history </div>
             <hr />
            </div>
          </>
         }
        </div>
       </div>

       <div className="col-md-3">
        <div className='shadow-sm'>
         <div onClick={handlePurchaseClick} className=' d-flex gap-5 align-items-center justify-content-between' style={{ padding: '10px', backgroundColor: '#0D0D44', cursor: 'pointer' }}>
          <div className='d-flex gap-2 align-items-center text-white'>
           <BsCartPlusFill style={{ fontSize: '25px', }} />
           <span style={{ fontSize: '20px', fontWeight: '600', }}>Purchases </span>
          </div>
          <span>{purchase ? <MdOutlineKeyboardArrowDown style={{ color: '#FFF', fontSize: '30px' }} /> : <MdOutlineKeyboardArrowUp style={{ color: '#FFF', fontSize: '30px' }} />}  </span>
         </div>


         {purchase &&
          <>
           <div className='pt-3' style={{ backgroundColor: '#E8ECF5', padding: '5px 20px', marginBottom: '-5px', transitionDuration: '10s' }}>
            <Link to={'/reports/purchase'} target='blank' style={{textDecoration:'none'}}> 
            <div className='h4 fw-bold' style={{ color: 'black' }}> Supplier Purchases Report</div>
            <div className='p' style={{ color: 'black', textAlign:'justify'}}>This report display all Supplier wise purchases report</div>
            <hr />
            </Link>
           </div>

           <div className='' style={{ backgroundColor: '#E8ECF5', padding: '5px 20px', marginBottom: '-5px', transitionDuration: '10s' }}>
            <div className='h4 fw-bold' style={{ color: 'black' }}> Supplier Wise Item Purchases</div>
            <div className='p' style={{ color: 'black' , textAlign:'justify'}}> This report display all Supplier wise purchases report </div>
            <hr />
           </div>

           <div className='' style={{ backgroundColor: '#E8ECF5', padding: '5px 20px', marginBottom: '-5px', transitionDuration: '10s' }}>
            <div className='h4 fw-bold' style={{ color: 'black' }}> Supplier Ledger </div>
            <div className='p' style={{ color: 'black', textAlign:'justify' }}>  This report displays a listing of all transactions for selected supplier , within a specified Date range.  </div>
            <hr />
           </div>


          </>}
        </div>
       </div>

      </div>
     </CardBody>
    </Card>
   </Container>
  </Fragment>
 );
};

export default ReportsManagementIndex;
