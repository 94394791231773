
import { useEffect, useState, } from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, Table, UncontrolledDropdown, } from "reactstrap";

import { TbCurrencyTaka } from "react-icons/tb"

import { FaBarcode, } from "react-icons/fa6";

import useGetData from "../../../hooks/useGetData";

import axios from "../../../util/axiosInstance";

import ReactDatePicker from "react-datepicker";

import { Link, useNavigate } from "react-router-dom";

import useClickOutside from "../../../hooks/useClickOutside";

import { useRef } from "react";
import useNumberFormatter from "../../../hooks/useNumberFormatter";

import Swal from "sweetalert2";

import Breadcrumb from "../../../components/common/breadcrumb";
import TransferListUpdateWarehouseDropdown from "./TransferListUpdate/TransferListUpdateWarehouseDropdown";
import TransferListUpdateBarcodeDropdownSection from "./TransferListUpdate/TransferListUpdateBarcodeDropdownSection";
import TransferListUpdatePurchaseTable from "./TransferListUpdate/TransferListUpdatePurchaseTable";
import ToTransferListUpdateWarehouseDropdown from "./TransferListUpdate/ToTransferListUpdateWarehouseDropdown";


const UpdateTransferList = () => {
 const navigate = useNavigate();

 const numericFormatter = useNumberFormatter();

 function generateRandomSPNumber() {
  const randomNumber = Math.floor(Math.random() * 10000); // Generate a random number between 0 and 9999
  const formattedNumber = `SP${randomNumber.toString().padStart(4, "0")}`; // Format with "SP" prefix and leading zeros if needed
  return formattedNumber;
 }
 const randomSPNumber = generateRandomSPNumber();
 const [error, setError] = useState('')

 const selectedDropdownMenu = useRef(null);
 useClickOutside(selectedDropdownMenu, () => setIsSelectedItemDivOpen(false));
 const [isSelectedItemDivOpen, setIsSelectedItemDivOpen] = useState(false);
 const [accountTypeData, setAccountTypeData] = useState([])
 const [selectedItemData, setSelectedItemData] = useState([]); //search barcode and select then get selected data need to map in table 
 const [salesItems, setSalesItems] = useState([]);

 const { lot_number, item_id, custom_barcode } = selectedItemData

 // const [lotNumber, setLotNumber] = useState(lot_number);
 const [customBarcode, setCustomBarcode] = useState(custom_barcode);

 const [salesPrice, setSalesPrice] = useState();


 const [reference, setReference] = useState('');

 const [paymentNote, setPaymentNote] = useState('')




 const [branchData, setBranchData] = useState([]);

 const [totalSubTotal, setTotalSubTotal] = useState(0)


 const [totalQuantity, setTotalQuantity] = useState(0);



 const [startDate, setStartDate] = useState(new Date());
 const date = startDate;
 const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0 indexed
 const day = date.getDate().toString().padStart(2, '0');
 const year = date.getFullYear();
 const purchaseDate = `${month}/${day}/${year}`


 const [warehouseDropdownId, setWarehouseDropdownId] = useState('');




 const [searchBarcodeInput, setSearchBarcodeInput] = useState('');

 const [barCodeProducts, setBarCodeProducts] = useState([])


 // set selected item data
 const handleItemClick = (item) => {
  // Check if the item is already in the selectedItemData array
  const isDuplicate = selectedItemData.some(
   (selectedItem) => selectedItem.id === item.id
  );

  if (!isDuplicate) {
   setSelectedItemData([...selectedItemData, item]);
   setIsSelectedItemDivOpen(false);
  } else {
   setIsSelectedItemDivOpen(false);
   Swal.fire({
    title: "Oops...",
    text: "Product is already added. Please update the quantity!",
    timer: 1000,
   });
  }
 };


 // barcode products get from backend
 useEffect(() => {
  if (searchBarcodeInput.length >= 3) {
   axios
    .get(
     `/purchase/searchitem?query=${searchBarcodeInput}`
    )
    .then((response) => {
     setBarCodeProducts(response.data);
     if (response.data.length > 0) {
      setIsSelectedItemDivOpen(true);
     }
     if (
      response.data.some((x) => x.custom_barcode === searchBarcodeInput)
     ) {

      const item = response.data.find(
       (x) => x.custom_barcode === searchBarcodeInput
      );
      setSearchBarcodeInput("");

      return handleItemClick(item);
     }
    });
  }
 }, [searchBarcodeInput, axios]);



 useEffect(() => {
  axios.get('/branch/viewbranch')
   .then(res => setBranchData(res.data))
   .catch(error => {
   })
 }, [])



 const [supplierTypeData] = useGetData(
  '/supplier/viewsuppliers',
  'supplierdata'
 );


 const [paymentTypeData] = useGetData(
  '/paymenttype/viewpaymenttypes/',
  'paymenttype'
 );


 useEffect(() => {
  axios.get('/accounts/viewaccounts/').then(res => setAccountTypeData(res.data))
 }, [])


 const cashPaymentPostData = {
  warehouse_id: warehouseDropdownId,
  quantity: totalQuantity,
  payment_note: paymentNote,
  date: purchaseDate,
  sales_items: salesItems // this is array value 
 }



 const pay = (e) => {
  e.preventDefault();
  // axios
  //  .post("/purchase/add-purchase-with-items-and-payments", cashPaymentPostData)
  //  .then((response) => {
  //   if (response.status === 201) {
  //    navigate('/dashboard')
  //   }
  //  }).catch(err => {
  //   if (err) {
  //    setError('please give me all information')
  //   }
  //  });

 }



 return (
  <>
    <Breadcrumb title="Stock Transfer " parent="Stock Management" />
   <div className=" row">
    {
     error && (
      <h3 className="fw-bold text-capitalize text-center mb-3 text-danger"> {error}</h3>
     )
    }

    <div className="col-md-12">
     <div
      style={{ width: "48%" }}
      className=" d-flex justify-content-between align-items-center border border rounded-1 "
      title="Warehouse"
     >
      <span className="fw-bold w-50 px-5 ">
    From Warehouse <span className="text-danger">*</span>
      </span>
      {/* From dropdown data */}
      <TransferListUpdateWarehouseDropdown
       setWarehouseDropdownId={setWarehouseDropdownId}
       borderEnd={"warehouse"}
       inputType={"search"}
       // setInputValue={setWareHouseSearch}
       InitialDropdownValue={'select warehouse'}
       data={branchData}
       titleData={"Warehouse"}
       placeHolderData={"Enter Warehouse Name"}
       dataField={"warehouse_name"}
      ></TransferListUpdateWarehouseDropdown>
     </div>
    </div>

    {/* to warehouse  */}
    <div className="col-md-12 mt-3">
     <div
      style={{ width: "48%" }}
      className=" d-flex justify-content-between align-items-center border border rounded-1 "
      title="Warehouse"
     >
      <span className="fw-bold w-50 px-5 ">
    To Warehouse <span className="text-danger">*</span>
      </span>
      {/* From dropdown data */}
      <ToTransferListUpdateWarehouseDropdown
       setWarehouseDropdownId={setWarehouseDropdownId}
       borderEnd={"warehouse"}
       inputType={"search"}
       // setInputValue={setWareHouseSearch}
       InitialDropdownValue={'select warehouse'}
       data={branchData}
       titleData={"Warehouse"}
       placeHolderData={"Enter Warehouse Name"}
       dataField={"warehouse_name"}
      ></ToTransferListUpdateWarehouseDropdown>
     </div>
    </div>
   
   </div>

   <div className="row mt-3 ">

    <div className="col-md-6  gap-4">
     <div
      style={{ width: "100%", marginLeft:'40px' }}
      className=" d-flex align-items-center  rounded-1 "
     >
      <span className="fw-bold px-2">
      Transfer Date <span className="text-danger">*</span>
      </span>
      <ReactDatePicker
       showIcon
       selected={startDate}
       onChange={(date) => setStartDate(date)}
      />
     </div>
    </div>


    {/* <div className="col-md-6 mt-3 gap-4">
     <div className="mt-3 d-flex border rounded-2">
      <span className="fw-bold col-2 d-flex justify-content-center align-items-center border-end">Reference No</span>
      <input
       onChange={(e) => setReference(e.target.value)}
       name="reference"
       type="text" className="form-control border-0" />
     </div>
    </div> */}

   </div>

   {/* barcode start  */}
   <div className="col-md-12 d-flex justify-content-center align-items-center mt-3">
    <div
     style={{ width: "60%" }}
     className="d-flex border justify-content-center rounded-1 align-items-center"
    >
     <span className="border-end  p-2">
      <FaBarcode></FaBarcode>
     </span>
     <div className="w-100 px-1 position-relative">
      <input
       type="text"
       className="w-100 border-0"
       placeholder="Item Name/Barcode/Item Code"
       defaultValue={''}
       title="Item Name/Barcode/Item Code"
       onChange={(e) => {
        setSearchBarcodeInput(e.target.value);
       }}
      />
      {isSelectedItemDivOpen && (
       <TransferListUpdateBarcodeDropdownSection
        selectedDropdownMenu={selectedDropdownMenu}
        barCodeProducts={barCodeProducts}
        setSelectedItemData={setSelectedItemData}
        selectedItemData={selectedItemData}
        setIsSelectedItemDivOpen={setIsSelectedItemDivOpen}
        handleItemClick={handleItemClick}

       />
      )}
     </div>
    </div>
   </div>

   {/* pos table  */}
   <div className=" mt-5">
    <section className="px-4 ">
     <div
      className=" rounded-2 border px-2 overflow-auto "
      style={{
       height: "40vh",
       // backgroundColor: "#eff1f1",
       // overflowY:'scroll',
       // overflowX:'hidden'
      }}
     >
      <Table className="my-3 rounded-2">
       <thead>
        <tr className="text-center">
         <th className="text-start">Item Name</th>

         <th>Quantity</th>

         <th>Action</th>
        </tr>
       </thead>
       <tbody className="text-center">
        {selectedItemData?.map((product, index) => (
         <TransferListUpdatePurchaseTable
          key={index}
          product={product}
          products={selectedItemData}
          setTotalSubTotal={setTotalSubTotal}
          totalSubTotal={totalSubTotal}
          // setItemName={setItemName}

          setSelectedItemData={setSelectedItemData}
          setTotalQuantity={setTotalQuantity}
          setSalesPrice={setSalesPrice}
          salesPrice={salesPrice}
          // setLotNumber={setLotNumber}
          setCustomBarcode={setCustomBarcode}
          customBarcode={customBarcode}
          salesItems={salesItems}
          setSalesItems={setSalesItems}
         ></TransferListUpdatePurchaseTable>
        ))}
       </tbody>
      </Table>
     </div>

     <div className="row gap-5" style={{ marginTop: '30px', display: 'flex', }}>
      <div className="col-md-6" style={{ width: '38%' }}>
       <div className="form-group d-flex align-items-center justify-content-evenly gap-2">


       </div>
      </div>
      <div className="col-md-6  text-center" style={{ width: '48%', marginLeft: '100px' }} >
       <div className="d-flex  flex-column align-items-baseline justify-content-end" style={{ textAlign: 'left' }}>
        <h4 style={{ color: 'black', fontWeight: 'bold' }}> Total Quantity: <span> ৳ </span>{numericFormatter(totalQuantity) || 0}</h4>

       </div>
      </div>
     </div>


     <div className="mt-5" style={{ backgroundColor: '#F2F2F4', padding: '10px', borderRadius: '5px' }}>

      <div class="form-group col-md-12">
       <label className="fw-bold" for="exampleFormControlTextarea1">Note</label>
       <textarea class="form-control"
        onChange={(e) => setPaymentNote(e.target.value)}
        id="exampleFormControlTextarea1" rows="3"></textarea>
      </div>

     </div>

     <div className="col-md-12 d-flex justify-content-center align-items-center my-4 gap-5 ">
      <input onClick={pay} type="Submit" style={{ backgroundColor: '#00A65A', border: 'none', padding: '4px 30px', borderRadius: '5px', color: 'white' }} />
      <Link to={'/dashboard'} style={{ backgroundColor: '#F39C12', textDecoration: 'none', padding: '4px 30px', borderRadius: '5px', color: 'white' }}>Close</Link>
     </div>
    </section>

   </div>

  </>
 );
};

export default UpdateTransferList;