import React from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import SalesPaymentInformationTableSection from "./SalesPaymentInformationTableSection";

const SalesPayment = () => {
  return (
    <>
      <Breadcrumb title="Sales Payments List" parent="Sales Management" />
      <main className=" w-100 px-2">
        <section
          className="bg-white rounded-2 pb-4"
          style={{ boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)" }}
        >
          <SalesPaymentInformationTableSection></SalesPaymentInformationTableSection>
        </section>
      </main>
    </>
  );
};

export default SalesPayment;
