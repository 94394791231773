import React, { useEffect, useState } from 'react'
import { Button, Table } from 'reactstrap';
import TableLoading from '../../../../components/common/loading/TableLoading';
import { useNavigate } from 'react-router-dom';
import axios from '../../../../util/axiosInstance';
import moment from 'moment';

const token = localStorage.getItem('access-token');
const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
};


const SalesPaymentTable = ({ queryLink, wareHouseDropdownValue }) => {
    const [isLoading, setLoading] = useState(false)
    const [dataToRander, setDataToRander] = useState([])



    const navigate = useNavigate();
    const btnStyle = {
        height: '40px',
        width: '40px',
        borderRadius: '50%',
        padding: '8px',
        background: 'transparent',
    };

    useEffect(() => {
        // axios.get('/report/sales-by-customer/79?startDate=2023-01-01&endDate=2023-12-31')
        // /report/sales-by-customer/75?startDate=2023-10-07&endDate=2023-10-07
        setLoading(true)
        axios.get(queryLink)
            .then(response => {
                setDataToRander(response?.data)
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
            })


    }, [queryLink])

    const fatchQuery = () => {
        setLoading(true)
        axios.get(queryLink)
            .then(response => {
                setDataToRander(response?.data)
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
            })


    }



    return (
        <div>
            <div className='d-flex col-10 mx-auto justify-content-center my-3'>
                <Button className='btn-info mx-3 text-white' onClick={fatchQuery}>Show</Button>
                {/* <Button onClick={navigate('/')}  >cancel</Button> */}
            </div>
            <Table
                bordered
                hover
                striped>
                <thead className='text-center'>
                    <tr>
                        <th>#</th>

                        <th>payment_code</th>
                        <th>Invoice No.</th>
                        <th>Sales Date</th>

                        <th>Customer Name</th>
                        <th>payment amnt</th>
                        <th>payment Method</th>
                        <th>Payment Note</th>


                    </tr>
                </thead>
                <tbody className='text-center'>
                    {dataToRander?.length === 0 && isLoading ? (
                        <TableLoading
                            noRow={9}
                            noTd={8}
                        />
                    ) : (
                        dataToRander

                            ?.map((item, i) => {
                                // "payment_id": 583,

                                // "sales_code": "SL2",
                                // "payment_date": "2023-09-30T04:00:00.000Z",
                                // "payment_type": "CASH",
                                // "payment": 5000,
                                // "payment_status": "Paid",
                                // "payment_note": "tytyytyt",
                                // "created_time": "01:20:58 am",
                                // "created_date": "2023-09-30T04:00:00.000Z",
                                // "account_name": null,
                                // "customer_name": "Ashley"
                                return (
                                    <tr>
                                        <th scope='row'>{i + 1}</th>
                                        <th>{item.payment_code}</th>
                                        <th>{item.sales_code}</th>
                                        <td>{moment(item.payment_date).format('L')}</td>
                                        <th>{item.customer_name}</th>
                                        <th>{item.payment}</th>
                                        <th>{item.payment_type}</th>
                                        <th>{item.payment_note}</th>

                                    </tr>
                                )
                            })
                    )}
                    {/* {
                        dataToRander.length > 0 && (
                            <tr>
                                <th scope='row' colSpan={5}>Total </th>
                                <th>{dataToRander.reduce((x, y) => {
                                    return y.purchase_qty ? x + y.purchase_price * (y.purchase_qty) : x + y.purchase_price * (y.stock)
                                }, 0)} </th>
                            </tr>

                        )
                    } */}

                    {/* <TableLoading /> */}
                </tbody>
            </Table>




            <div className='Toastify' />
        </div>
    )
}

export default SalesPaymentTable


/**
 customer_id
: 
79
discount_to_all_input
: 
0
discount_to_all_type
: 
"Percentage(%)"
grand_total
: 
8400
init_code
: 
"SL"
paid_amount
: 
84000
payment_status
: 
"Paid"
reference_no
: 
null
sales_code
: 
"20231003021601"
sales_date
: 
"2023-10-02T04:00:00.000Z"
sales_id
: 
124
sales_note
: 
null
sales_status
: 
"Final"
tot_discount_to_all_amt
: 
null
warehouse_id
: 
2


 

description
: 
null
discount_amt
: 
0
discount_input
: 
0
discount_type
: 
"Percentage(%)"
item_id
: 
116
lot_number
: 
"Select-Lot"
price_per_unit
: 
2100
purchase_price
: 
null
sales_qty
: 
4
total_cost
: 
8400
unit_total_cost
: 
null
 */