import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Table } from 'reactstrap';
import axios from '../../../util/axiosInstance';
import TableLoading from '../../common/loading/TableLoading';


const LatestOrderTable = () => {

    const [topSellingProduct, setTopSellingProduct] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    
    // Top Selling Product 
    useEffect(() => {
        // Define the async function
        const fetchData = async () => {
            try {
                const response = await axios.get('/dashboard/sales-items/');
                setTopSellingProduct(response.data);
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        }
        // Invoke the async function
        fetchData();
    }, []);
    return (
      <Card>
        <CardHeader>
          <h5>Top Selling Products </h5>
        </CardHeader>
        <CardBody>
          <div className=''>
            <Table
              bordered
              hover
              striped
              style={{ width: '100%', borderRadius: '5px' }}>
              <thead
                className='text-center'
                style={{ backgroundColor: '#EFF3F7' }}>
                <tr className=''>
                  <th
                    style={{ padding: '6px' }}
                    scope='col'>
                    PRODUCT
                  </th>
                  <th
                    style={{ padding: '6px' }}
                    scope='col'>
                    QUANTITY
                  </th>

                  <th style={{ padding: '6px' }}>GRAND TOTAL</th>
                </tr>
              </thead>
              <tbody className='text-center'>
                {loading && (
                  <>
                    {/* <LoadingBar color="red" style={{ marginTop: '-15px' }}
                                                progress={progress}
                                                onLoaderFinished={() => setProgress(0)}
                                            /> */}

                    <TableLoading
                      noTd={3}
                      noRow={5}
                    />
                  </>
                )}
                {!loading &&
                  topSellingProduct
                    ?.slice(0, 7)
                    ?.sort((a, b) => b?.id - a?.id)
                    ?.map((product, index) => (
                      <tr key={product.id}>
                        <td style={{ borderRadius: '5px', paddingLeft: '5px' }}>
                          {product?.item_name}
                        </td>
                        <td
                          style={{
                            color: '#6571FF',
                            borderRadius: '5px',
                            paddingLeft: '5px',
                          }}>
                          {product?.sales_qty}
                        </td>
                        <td title='apogee pos'>{product?.total_cost || 0}</td>
                      </tr>
                    ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    );
};

export default LatestOrderTable;



