import React, { useEffect, useState } from 'react'
import SalesPaymentTable from './components/SalesPaymentTable'
import Breadcrumb from '../../../components/common/breadcrumb'
import DateRangePickerComponent from './components/DateRangePicker'
import { addDays } from 'date-fns';

import moment from 'moment'
import BranchNameSelectDropdown from './components/BranchNameSelectDropdown';
import SuplierNameSelectDropdown from './components/SuplierNameSelectDropdown';

const SupplierItemsScreen = () => {
    const [queryData, setQueryData] = useState({
        warehouse_name: "All-Branch",
        supplierName_name: 'All-Supplier',
        category_name: '',
        lot_number: ""
    })
    const [stateM, setStateM] = useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 0),
            key: 'selection',
        },
    ]);

    const [ApiLink, setApiLink] = useState('')
    const [pageNumber, setPageNumber] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [tatalPages, setTotalPage] = useState(1)

    // const startDate = moment(stateM[0].startDate).format().substring(0, 10)
    // const endDate = moment(stateM[0].endDate).format().substring(0, 10)


    useEffect(() => {

        setApiLink(`/report/view-purchases?page=${pageNumber}&pageSize=${pageSize}`)
    }, [pageNumber, pageSize])

    return (
      <>
        <Breadcrumb
          title='Supplier Items'
          parent='reports'
        />
        <div className='col-12 '>
          {/* <h3 className=''>Please Enter Valid Information</h3> */}
          <div className='d-flex '>
            <BranchNameSelectDropdown
              apiLink='/branch/viewbranch'
              queryData={queryData}
              setQueryData={setQueryData}
              InitialDropdownValue='All-Branch'
            />
            <SuplierNameSelectDropdown
              apiLink='/supplier/viewsuppliers'
              queryData={queryData}
              setQueryData={setQueryData}
              InitialDropdownValue='All-Supplier'
            />
            {/* <DateRangePickerComponent
                        setStateM={setStateM}
                        stateM={stateM}


                        queryData={queryData}
                        setQueryData={setQueryData}
                    /> */}
          </div>
        </div>

        <div className=''>
          {/* <PayamentList /> */}
          <SalesPaymentTable
            queryLink={ApiLink}
            wareHouseDropdownValue={queryData.warehouse_name}
            supplierNameDropdownValue={queryData.supplierName_name}
          />
        </div>
      </>
    );
}

export default SupplierItemsScreen